import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
  Pagination,
  PaginationItem,
  Stack,
  Button,
  useMediaQuery,
  Skeleton,
} from "@mui/material";
import EmptyCard from "components/EmptyCard";
import {
  cancelledOrdersEmptyImgUrl,
  cancelledOrdersEmptyMessage,
  emptyOrderBtnText,
  OrderEmptyDescription,
} from "configs/Constants";
import React, { useEffect, useState } from "react";
import BreadcrumbsSection from "../../components/Breadcrumbs";
import {
  backgroundColor01,
  blackColor,
  dashboardDescriptionColor,
  greyShade54,
  headingColor,
  orangeColor01,
} from "../../configs/styles/muiThemes";
import OrderCard from "../AllOrders/OrderCard";
import DasboardInfoCard from "../Dashboard/DasboardInfoCard";
import DashboardCard from "../Dashboard/DashboardCard";
import DashboardNavbar from "../Dashboard/DashboardNavbar";
// import OrderCard from "./OrderCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Loader from "components/Loader";
import { useNavigate } from "react-router-dom";
import SelectWithLabel from "components/Input/SelectWithLabel";
import { getBuyerAppLookupAPI } from "services/api_calls/orderdetails_api/OrderDetailsApi";
import OrderCardLoader from "components/skeletonLoaders/orders/OrderCardLoader";

function CanceledOrdersContent({
  accountDetails,
  orderData,
  cancelledOrderDetails,
  loading,
  businessId,
  ordersCountData,
  handleOrderPagination,
  pageNo,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const handlePagination = (event, value) => {
    console.log("OPEN_ORDER_PAGINATION", value);
    handleOrderPagination(value);
  };
  const navigate = useNavigate();

  const handleCategoryFilter = (active) => {
    active === "Open Orders"
      ? navigate(`/${sessionStorage.getItem("businessName")}/openOrders`)
      : active === "Completed Orders"
      ? navigate(`/${sessionStorage.getItem("businessName")}/completedOrders`)
      : active === "Canceled Orders"
      ? navigate(`/${sessionStorage.getItem("businessName")}/canceledOrders`)
      : navigate(`/${sessionStorage.getItem("businessName")}/allOrders`);
  };
  const categoryList = [
    { id: 0, label: "All Orders" },
    { id: 1, label: "Open Orders" },
    { id: 2, label: "Completed Orders" },
    { id: 3, label: "Canceled Orders" },
  ];
  const [bapNameList, setBapNameList] = useState(null);

  const getBapDisplayNameInfo = async () => {
    const response = await getBuyerAppLookupAPI();

    if (response?.details?.length > 0) {
      setBapNameList(response?.details || []);
    }
  };
  // Get ONDC Bap Display Name by Subscriber ID
  const getOndcBapNameBySubscriberId = (id) => {
    const data = bapNameList?.find((item) => item?.subscriberId === id);
    return data ? data?.displayName : "";
  };
  useEffect(() => {
    getBapDisplayNameInfo();
  }, []);

  return matches ? (
    <Box backgroundColor={backgroundColor01} pb={1}>
      <Box
        sx={{
          display: "flex",
          // boackground: "#fff",
          // padding: "20px 10px",
          // borderRadius: "12px",
          // border: "1px solid #e8e8f0",
          // marginBttom: "20px",
        }}
      >
        <Grid container columnSpacing={2}>
          <Grid item md={2}>
            <DashboardNavbar active="canceledOrders" businessId={businessId} />
          </Grid>
          <Grid item md={10}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2.0}
              // mt={{ md: 0, xs: 2.3 }}
              flexWrap="wrap"
              gap={1}
            >
              {/* <Box display={'flex'} justifyContent={'space-between'} > */}
              <Box display="flex" alignItems="center">
                <Typography
                  variant="h4"
                  component="h2"
                  fontSize={{ xs: 15, md: 15 }}
                  color={blackColor}
                  display="inline"
                >
                  Cancelled Orders{" "}
                </Typography>
                <Box
                  component="span"
                  color={greyShade54}
                  fontSize={18}
                  fontWeight={500}
                  mx={1}
                  textAlign="center"
                >
                  ·
                </Box>
                {orderData && ordersCountData ? (
                  <Typography
                    variant="subtitle1"
                    fontSize={{ xs: 14, md: 15 }}
                    color={dashboardDescriptionColor}
                    display="inline"
                  >
                    {/* ({orderDetails?.length}) */}
                    {ordersCountData?.details?.cancelOrder}
                  </Typography>
                ) : (
                  <Skeleton variant="rounded" width="25px" height="13px" />
                )}
              </Box>
              {/* </Box> */}

              {accountDetails?.account ? (
                <Typography
                  variant="subtitle1"
                  component="p"
                  fontSize="14px"
                  lineHeight="24px"
                  color={blackColor}
                  display="inline"
                >
                  Hello,{" "}
                  <Typography
                    variant="subtitle1"
                    component="p"
                    fontSize="14px"
                    fontWeight={600}
                    color={orangeColor01}
                    display="inline"
                  >
                    {accountDetails?.account?.name} !
                  </Typography>
                </Typography>
              ) : (
                <Skeleton variant="rounded" width="125px" height="13px" />
              )}
            </Box>

            {orderData && cancelledOrderDetails?.details?.length > 0 ? (
              <>
                {cancelledOrderDetails?.details?.map((order, index) => {
                  return (
                    <OrderCard
                      key={"allcancelledorders_" + index}
                      cartId={order.cartId}
                      orderId={order.orderId}
                      date={order.orderDate}
                      quantity={order.totalQuantity}
                      totalPrice={order.orderTotalCost}
                      orderStatus={order.orderStatus}
                      cartDate={order.cartDate}
                      orderData={order}
                      fromPage={{
                        label: "Canceled Orders",
                        to: "CanceledOrders",
                      }}
                      ondcOrderId={order?.ondcInfo?.bapOrderId}
                      ondcOrderType={getOndcBapNameBySubscriberId(
                        order?.ondcInfo?.bapId
                      )}
                    />
                  );
                })}

                {ordersCountData ? (
                  ordersCountData?.details?.cancelOrder > 20 ? (
                    <Stack
                      spacing={2}
                      my={4}
                      display={{ md: "flex", xs: "none" }}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Pagination
                        count={
                          ordersCountData
                            ? Math.ceil(
                                ordersCountData?.details?.cancelOrder / 20
                              )
                            : 0
                        }
                        page={pageNo}
                        onChange={handlePagination}
                        color="primary"
                        renderItem={(item) => (
                          <PaginationItem
                            components={{
                              next: NextBtn,
                              previous: PreviousBtn,
                            }}
                            {...item}
                          />
                        )}
                      />
                    </Stack>
                  ) : null
                ) : null}
              </>
            ) : orderData && cancelledOrderDetails?.details?.length == 0 ? (
              <EmptyCard
                message={cancelledOrdersEmptyMessage}
                description={OrderEmptyDescription}
                imageUrl={
                  "https://storage.googleapis.com/bodefaults/shopweb/orders/order-cancel.svg"
                }
                actionBtnText={emptyOrderBtnText}
                iconWidth={"65px"}
                iconHeignt={"70px"}
                imageboxpadding={"10px"}
              />
            ) : (
              <Box>
                <OrderCardLoader />
                <OrderCardLoader />
                <OrderCardLoader />
              </Box>
            )}

            {/* {cancelledOrderDetails?.details?.length == 0 ? (
              <EmptyCard
                message={cancelledOrdersEmptyMessage}
                description={OrderEmptyDescription}
                imageUrl={
                  "https://storage.googleapis.com/bodefaults/shopweb/orders/order-cancel.svg"
                }
                actionBtnText={emptyOrderBtnText}
                iconWidth={"65px"}
                iconHeignt={"70px"}
                imageboxpadding={"10px"}
              />
            ) : null} */}
          </Grid>
        </Grid>
      </Box>
    </Box>
  ) : (
    <Box mt={{ xs: 9, md: 2.5 }}>
      <DashboardNavbar active="canceledOrders" businessId={businessId} />

      <Container sx={{ maxWidth: "1300px !important" }}>
        <Box
          mt={{ md: "20px !important", xs: "10px" }}
          backgroundColor={backgroundColor01}
        >
          <Grid
            container
            rowSpacing={{ md: 2, xs: 0 }}
            columnSpacing={{ md: 5, xs: 0 }}
            // mt={{ md: 2, xs: 0 }}
          >
            <Grid item md={9} xs={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={1.0}
                // mt={{ md: 0, xs: 2.3 }}
                flexWrap="wrap"
                gap={1}
              >
                <Box>
                  <Typography
                    variant="h4"
                    component="h2"
                    fontSize={{ xs: 15, md: 17 }}
                    color={headingColor}
                    display="inline"
                  >
                    Cancelled Orders{" "}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    fontSize={{ xs: 14, md: 16 }}
                    color={dashboardDescriptionColor}
                    display="inline"
                  >
                    {/* ({cancelledOrderDetails?.details?.length}) */}(
                    {ordersCountData?.details
                      ? ordersCountData?.details?.cancelOrder
                      : "XX"}
                    )
                  </Typography>
                </Box>
                <Box
                  display={"flex"}
                  sx={{
                    position: "relative",
                    padding: "0px 0px 6px",
                    borderRadius: "50px",
                    border: "1px solid #E1E1EE",
                    fontSize: "13px",
                    color: "rgba(var(--theme-color), 1)",
                    backgroundColor: "rgb(255, 255, 255)",
                    height: "24px",
                    fontWeight: 500,
                  }}
                >
                  <SelectWithLabel
                    // label='Product Category'
                    value={"Canceled Orders"}
                    onValueChange={(value) => {
                      handleCategoryFilter(value);
                    }}
                    menuList={categoryList}
                    isDashboardInput
                  />
                </Box>
              </Box>

              {!orderData ? (
                <>
                  <Skeleton
                    variant="rounded"
                    width="auto"
                    height="238px"
                    sx={{ borderRadius: "8px", m: "15px 0 10px !important" }}
                  />

                  <Skeleton
                    variant="rounded"
                    width="auto"
                    height="238px"
                    sx={{ borderRadius: "8px", mb: "10px !important" }}
                  />
                </>
              ) : (
                <>
                  {cancelledOrderDetails?.details?.map((order, index) => {
                    return (
                      <OrderCard
                        key={"allcancelledorders_" + index}
                        cartId={order.cartId}
                        orderId={order.orderId}
                        date={order.orderDate}
                        quantity={order.totalQuantity}
                        totalPrice={order.orderTotalCost}
                        orderStatus={order.orderStatus}
                        cartDate={order.cartDate}
                        orderData={order}
                        fromPage={{
                          label: "Canceled Orders",
                          to: "CanceledOrders",
                        }}
                        ondcOrderId={order?.ondcInfo?.bapOrderId}
                        ondcOrderType={getOndcBapNameBySubscriberId(
                          order?.ondcInfo?.bapId
                        )}
                      />
                    );
                  })}

                  {ordersCountData ? (
                    ordersCountData?.details?.cancelOrder > 20 ? (
                      <Stack
                        spacing={2}
                        my={4}
                        display={{ md: "flex", xs: "none" }}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Pagination
                          count={
                            ordersCountData
                              ? Math.ceil(
                                  ordersCountData?.details?.cancelOrder / 20
                                )
                              : 0
                          }
                          page={pageNo}
                          onChange={handlePagination}
                          color="primary"
                          renderItem={(item) => (
                            <PaginationItem
                              components={{
                                next: NextBtn,
                                previous: PreviousBtn,
                              }}
                              {...item}
                            />
                          )}
                        />
                      </Stack>
                    ) : null
                  ) : null}

                  {cancelledOrderDetails?.details?.length == 0 ? (
                    <EmptyCard
                      message={cancelledOrdersEmptyMessage}
                      description={OrderEmptyDescription}
                      imageUrl={
                        "https://storage.googleapis.com/bodefaults/shopweb/orders/order-cancel.svg"
                      }
                      actionBtnText={emptyOrderBtnText}
                      imgpadding={"10px"}
                    />
                  ) : null}
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default CanceledOrdersContent;

const styles = {
  btnHover: {
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
};

const PreviousBtn = () => {
  return (
    <Button startIcon={<ArrowBackIcon />} sx={styles.btnHover}>
      Prev
    </Button>
  );
};

const NextBtn = () => {
  return (
    <Button endIcon={<ArrowForwardIcon />} sx={styles.btnHover}>
      Next
    </Button>
  );
};
