import * as React from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

import { Box, Typography, useMediaQuery } from "@mui/material";
import {
  CheckRounded,
  LocalMallRounded,
  LocalShippingRounded,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  blackColor,
  greenColor01,
  greyShade33,
  greyShade59,
  greyShade73,
  redColor01,
  stepperActiveColor,
  whiteColor,
} from "../configs/styles/muiThemes";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 13,
    left: -100,
    [theme.breakpoints.down("sm")]: {
      // Mobile version (sm breakpoint and below)
      left: -40, // Adjust for mobile version
    },
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: stepperActiveColor,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: stepperActiveColor,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    width: "110%",
    height: 1.5,
    border: 0,
    mr: "30px",
    backgroundColor: greyShade33,
    borderRadius: 1,
  },
}));
// const ColorlibConnector = styled(StepConnector)(({ theme }) => {
//   const isMobile = window.innerWidth <= theme.breakpoints.values.sm; // Check if the viewport is mobile-sized

//   return {
//     [&.${stepConnectorClasses.alternativeLabel}]: {
//       top: 13,
//       left: isMobile ? -30 : -60, // Dynamic left position based on viewport size
//     },
//     // Active state styling
//     [&.${stepConnectorClasses.active}]: {
//       [& .${stepConnectorClasses.line}]: {
//         backgroundColor: stepperActiveColor,
//       },
//     },
//     // Completed state styling
//     [&.${stepConnectorClasses.completed}]: {
//       [& .${stepConnectorClasses.line}]: {
//         backgroundColor: stepperActiveColor,
//       },
//     },
//     // Line styling
//     [& .${stepConnectorClasses.line}]: {
//       width: '110%',
//       height: 1.5,
//       border: 0,
//       backgroundColor: greyShade33,
//       borderRadius: 1,
//     },
//   };
// });

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: whiteColor,
  border: ownerState.active ? "" : `1.5px solid${greyShade33}`,
  zIndex: 1,
  color: whiteColor,
  width: 25,
  height: 25,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active &&
    ownerState.canceled && {
      backgroundColor: redColor01,
      color: whiteColor,
    }),
  ...(ownerState.active &&
    !ownerState.canceled && {
      backgroundColor: greenColor01,
      // boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
      color: whiteColor,
    }),
  ...(ownerState.completed && {
    backgroundColor: greenColor01,
    color: whiteColor,
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, index, canceled } = props;

  const icons = {
    1: (
      <Box>
        <LocalMallRounded
          sx={{
            color: completed || active ? whiteColor : greenColor01,
            fontSize: "20px",
          }}
        />
      </Box>
    ),
    2: (
      <Box>
        <LocalShippingRounded
          sx={{
            color: completed || active ? whiteColor : greenColor01,
            fontSize: "20px",
          }}
        />
      </Box>
    ),
    3: (
      <Box>
        <CheckRounded
          sx={{
            color: completed || active ? whiteColor : greenColor01,
            fontSize: "18px",
          }}
        />
      </Box>
    ),
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active, canceled }}
      className={className}
    >
      {active && canceled ? (
        <CloseIcon
          sx={{
            color: whiteColor,
            fontSize: "20px",
          }}
        />
      ) : active ? (
        <CheckRounded
          sx={{
            color: whiteColor,
            fontSize: "16px",
          }}
        />
      ) : completed ? (
        <CheckRounded
          sx={{
            color: whiteColor,
            fontSize: "16px",
          }}
        />
      ) : (
        <Typography
          variant="body1"
          fontSize={12}
          fontWeight={500}
          color={blackColor}
        >
          {index + 1}
        </Typography>
      )}
      {/* {icons[String(props.icon)]} */}
      {/* <Typography variant='body1' fontSize={14} fontWeight={500}>
        {completed ? icons[String(props.icon)] : index + 1}
      </Typography> */}
    </ColorlibStepIconRoot>
  );
}

function HorizontalStepper({ steps, activeStep, canceled }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Stack sx={{ width: "100%", mt: { md: 2, xs: 1 } }} mb={{ md: 4, xs: 1 }}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((item, index) => (
          <Step key={item.label} onClick={() => {}}>
            <StepLabel
              StepIconComponent={(props) =>
                // import CloseIcon from '@mui/icons-material/Close'
                ColorlibStepIcon({
                  ...props,
                  route: item.route,
                  index: index,
                  canceled,
                })
              }
            >
              <Typography
                variant="h5"
                component="p"
                fontSize={{ xs: "12px", md: "12px" }}
                fontWeight={500}
                color={
                  item.label === "Cancelled"
                    ? redColor01
                    : activeStep >= index
                    ? blackColor
                    : // : completed
                      // ? blackColor
                      greyShade59
                }
                width={{ md: "100%", xs: "100%" }}
                minWidth={{ md: "120px", xs: "auto" }}
                mt={-1}
              >
                {item.label}
              </Typography>

              {item.date && (
                <Typography
                  variant="h5"
                  component="p"
                  fontSize={{ xs: "10px", md: "11px" }}
                  fontWeight={500}
                  color={greyShade73}
                  lineHeight="14px"
                  mt={0.5}
                >
                  {item.date}
                  {item.time && matches && ` at ${item.time}`}
                </Typography>
              )}
              {item.time && !matches && (
                <Typography
                  variant="h5"
                  component="p"
                  fontSize={{ xs: "10px", md: "11px" }}
                  fontWeight={500}
                  color={greyShade73}
                  lineHeight="14px"
                  mt={0.3}
                >
                  {item.time}
                </Typography>
              )}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}

export default HorizontalStepper;
