import { StarRounded } from "@mui/icons-material";
import { Grid, Rating, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import {
  arrowBgColor,
  backgroundColor01,
  backgroundColor05,
  blackColor,
  headingColor,
  orangeColor01,
  progressBgColor,
  reviewBodyColor,
  secondaryColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import StarRoundedIcon from '@mui/icons-material/StarRounded';

function ReviewSummaryGraph(props) {
  return (
    <Box pl='8%' borderLeft={`1px dashed rgb(207 207 218)`} height='100%'>
      <Box sx={styles.header}>
        {/* <Rating
          readOnly
          icon={<StarRounded sx={{ color: "primary.main" }} />}
          emptyIcon={<StarRounded sx={{ color: arrowBgColor }} />}
          precision={0.1}
          value={props.reviewSummary?.average}
        /> */}
        <Rating
          readOnly
          icon={
            <StarRoundedIcon sx={{
              width: "18.5px",
              height: "18.5px",
              color: orangeColor01,
              ml:-0.4
            }} />

            // <Box
            //   component="img"
            //   src={`https://storage.googleapis.com/bodefaults/shopweb/reviews/star.svg`}
            //   alt={`Star`}
            //   sx={{
            //     width: "12.5px",
            //     height: "12.5px",
            //     display: "flex",
            //     lineHeight: 1.5,
            //   }}
            // />
          }
          emptyIcon={
            <StarRoundedIcon sx={{
              width: "18.5px",
              height: "18.5px",
              ml:-0.4
            }} />
            // <Box
            //   component="img"
            //   src={`https://storage.googleapis.com/bodefaults/shopweb/reviews/star-1.svg`}
            //   alt={`Star`}
            //   sx={{
            //     width: "12.5px",
            //     height: "12.5px",
            //     display: "flex",
            //     lineHeight: 1.5,
            //   }}
            // />
          }
          precision={0.1}
          value={props.reviewSummary?.average}
          sx={{ marginBottom: 1, gap: 1 }}
        />

        <Typography
          variant="h5"
          fontSize={14}
          color={headingColor}
          marginX={1}
          ml={2.5}
        >
          {props.reviewSummary?.average} out of 5
        </Typography>
        <Typography variant="body1" color={reviewBodyColor}>
          ({props.reviewSummary?.totalRated} Reviews)
        </Typography>
      </Box>

      {props.graphData.map((graph, index) => {
        return (
          <Box key={`rwview_summary_${index}`} mt={1.3}>
            <ProgressRow
              label={`${graph.reviewRating}`}
              progressValue={Math.round(graph.percent)}
            />
          </Box>
        );
      })}
    </Box>
  );
}

export default ReviewSummaryGraph;

const ProgressRow = ({ label, progressValue }) => {
  return (
    <Grid container display="flex" alignItems="center" spacing={2}>
      <Grid item>
        <Typography
          variant="body1"
          fontWeight={500}
          fontSize={13}
          color={blackColor}
        >
          {label} star
        </Typography>
      </Grid>

      <Grid item xs={8.85}>
        <Box sx={styles.progressBG}>
          <Box sx={[styles.progress, { width: `${progressValue}%` }]}>
            <Typography
              variant="body2"
              fontWeight={600}
              fontSize={11}
              color={whiteColor}
            >
              {progressValue > 0 ? `${progressValue}%` : ""}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const styles = {
  header: {
    display: "flex",
    alignItems: "center",
    mb:2
  },

  progressBG: {
    width: "100%",
    height: "14px",
    backgroundColor: backgroundColor05,
    borderRadius: "40px",
  },

  progress: {
    height: "14px",
    backgroundColor: blackColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "40px",
    transition: "width 6s",
  },
};
