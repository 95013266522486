
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { blackColor, greyColor6, greyShade42 } from "../../../configs/styles/muiThemes";

function ProductHeaderCard({ title, onManageClick, buttonText, hideBtn }) {
  return (
    <Box sx={styles.card} mb="14px">
      <Typography
        component="h2"
        fontSize={{ xs: 14, md: 14 }}
        color={blackColor}
        fontWeight={600}
        lineHeight={1.1}
      >
        {title}
      </Typography>

      {!hideBtn && (
        <Button
          sx={{
            fontSize: 11.5,
            fontWeight: 400,
            color: greyShade42,
            textDecoration: "underline",
            textTransform: "uppercase",
            p: "0px",
          }}
          onClick={onManageClick}
        >
          {buttonText || "View All"}
        </Button>
      )}
    </Box>
  );
}

export default ProductHeaderCard

const styles = {
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};
