import { Box, Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  blackColor,
  borderTextbox,
  greyColor3,
  greyColor9,
  greyShade36,
  lightOrange06,
  orangeColor01,
  textBtnColor,
  textBtnColorLight,
  whiteColor,
} from '../../configs/styles/muiThemes'
import ShareEarnHeader from '../ShareAndEarn/ShareEarnHeader'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import AffliatePaymentsContent from './AffliatePaymentsContent'
import GuestRegisterInstructions from './GuestRegisterInstructions'
import { getAffiliateValidityApi } from 'services/api_calls/affiliate/AffiliateApi'
import { getRefRatesByBusinessID } from 'redux/affiliate/AffiliateSlice'

function AffiliateProgramsContent({
  matches,
  fetchPendingPayments,
  fetchSettledPayments,
}) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { businessName } = useParams()

  const { rellerProfileData, businessAffiliateData, affiliateValidity } =
    useSelector((state) => state.affiliate)
  const { pendingPayments, settledPayments } = useSelector(
    (state) => state.affiliate
  )

  const businessId = sessionStorage.getItem('businessId')
  const userType = sessionStorage.getItem('userType')
  const [showRegisterInstr, setShowRegisterInstr] = useState(false)

  const handleBack = () => {
    navigate(`/${businessName}`)
  }

  const [commissionPercentage, setCommissionPercentage] = useState(0)

  const getAffiliatePercentage = async () => {
    const affiliateCommissionPer = affiliateValidity?.commissionPercentage || 0
    const businessCommissionPer =
      businessAffiliateData?.businessResellers?.[0]?.affiliatePercentage || 0

    if (affiliateCommissionPer > 0) {
      setCommissionPercentage(affiliateCommissionPer)
    } else {
      setCommissionPercentage(businessCommissionPer)
    }

    console.log(
      'getAffiliatePercentage_affiliateCommissionPer',
      affiliateCommissionPer
    )
    console.log(
      'getAffiliatePercentage_businessCommissionPer',
      businessCommissionPer
    )

    console.log('getAffiliatePercentage_VALID', affiliateValidity)
    console.log('getAffiliatePercentage_B_PERCENTAGE', businessAffiliateData)
  }

  useEffect(() => {
    getAffiliatePercentage()
  }, [businessAffiliateData, affiliateValidity])

  return (
    <Box backgroundColor={whiteColor} mt={1} >
      <ShareEarnHeader
        title="Brand Affiliate - Share & Earn"
        handleBackClick={handleBack}
        handleSettingClick={() => {}}
        handleSearchClick={() => {}}
        hideBackBtn
      />

      <Box p={2} pt={1}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
        >
          <Box>
            <Typography
              variant="h6"
              fontSize={14}
              fontWeight={600}
              lineHeight="18px"
              color={blackColor}
              mb="4px !important"
            >
              Share product links with followers
            </Typography>
            <Typography
              variant="body2"
              fontSize={13}
              fontWeight={500}
              lineHeight="20px"
              color={greyColor9}
            >
              Earn {commissionPercentage}% commission on each sale
            </Typography>
          </Box>

          <Typography
            variant="body2"
            fontSize={11}
            fontWeight={500}
            sx={styles.costBoText}
          >
            {rellerProfileData?.details?.resellerCode}
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography
            variant="body2"
            fontSize={13}
            fontWeight={600}
            lineHeight="24px"
            color={blackColor}
          >
            Simple 3 ways to Share
          </Typography>

          <Typography
            variant="body2"
            fontSize={13}
            fontWeight={500}
            lineHeight="25px"
            color={greyColor9}
            mt="8px !important"
            display={"flex"}
          >
            {/* Browse our catalog, go to the product details page and share using
            multiple social media options */}
            <Box
              sx={{
                width: "24px",
                height: "24px",
                display: "flex",
                placeItems: "center",
                alignSelf: "baseline",
                color: "#74748a",
                backgroundColor: "#f3f3f8",
                borderRadius: "6px",
                marginRight: "10px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              1
            </Box>
            Browse our catalog, Share products
          </Typography>
          <Typography
            variant="body2"
            fontSize={13}
            fontWeight={500}
            lineHeight="25px"
            color={greyColor9}
            mt="8px !important"
            display={"flex"}
          >
            {/* Browse our catalog, go to the product details page and share using
            multiple social media options */}
            <Box
              sx={{
                width: "24px",
                height: "24px",
                display: "flex",
                placeItems: "center",
                alignSelf: "baseline",
                color: "#74748a",
                backgroundColor: "#f3f3f8",
                borderRadius: "6px",
                marginRight: "10px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              2
            </Box>
            Search for a product & Share
          </Typography>
          <Typography
            variant="body2"
            fontSize={13}
            fontWeight={500}
            lineHeight="25px"
            color={greyColor9}
            mt="8px !important"
            display={"flex"}
          >
            {/* Browse our catalog, go to the product details page and share using
            multiple social media options */}
            <Box
              sx={{
                width: "24px",
                height: "24px",
                display: "flex",
                placeItems: "center",
                alignSelf: "baseline",
                color: "#74748a",
                backgroundColor: "#f3f3f8",
                borderRadius: "6px",
                marginRight: "10px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              3
            </Box>
            Share our store link
          </Typography>

          {/* <Button
            variant='contained'
            sx={styles.exploreBtn}
            onClick={() => {
              navigate(`/${businessName}/catalog`)
            }}
          >
            EXPLORE & SHARE - CLICK HERE
          </Button> */}
          <Box
            display="flex"
            alignItems="center"
            // justifyContent="center"
            mt={2}
          >
            <Button
              onClick={() => {
                navigate(`/${businessName}/catalog`);
              }}
              sx={{
                boxShadow: "none",
                height: "auto",
                lineHeight: "21px",
                fontWeight: 600,
                padding: "4px 20px",
                background: "#000",
                color: "#fff",
                borderRadius: "5px",
                border: "1px solid #000",
                fontSize: "12px",
                marginLeft: "10px",
                textTransform: "uppercase",
                "&:hover": {
                  backgroundColor: { xs: whiteColor },
                  color: blackColor,
                },
                // textDecoration: 'underline',
              }}
            >
              Share & Earn
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        sx={styles.commissionCard}
        onClick={() => {
          // navigate('/affiliatePayments')
        }}
        gap={2}
      >
        <Box
          component="img"
          src="https://storage.googleapis.com/bodefaults/shopweb/affiliates/commission-earnings.svg"
          alt="costbo"
          sx={{ width: "40px", height: "40px", objectFit: "contain" }}
        />
        <Box display="flex" flexDirection={"column"} gap="6px">
          <Typography
            variant="h6"
            fontSize={12.5}
            fontWeight={500}
            lineHeight="18px"
            color={blackColor}
          >
            Total Commission Earnings
          </Typography>
          <Typography
            variant="h6"
            fontSize={16}
            fontWeight={600}
            lineHeight="18px"
            color={blackColor}
          >
            ₹{" "}
            {pendingPayments && settledPayments
              ? Number(pendingPayments?.total + settledPayments?.total).toFixed(
                  2
                )
              : "0"}
          </Typography>
        </Box>
      </Box>
      {rellerProfileData &&
        !rellerProfileData?.details?.bankInfo?.accountNo && (
          <Box m="0 16px 10px">
            <Typography
              variant="body2"
              fontSize={12.5}
              fontWeight={500}
              lineHeight="22px"
              color={blackColor}
            >
              {userType === "guest" ? `Please ` : ""}
              {userType === "guest" && (
                <Typography
                  variant="body2"
                  component="a"
                  fontSize={12}
                  fontWeight={600}
                  color={orangeColor01}
                  sx={{
                    display: "inline",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowRegisterInstr(true);
                  }}
                >
                  Register
                </Typography>
              )}
              {userType === "guest" ? (
                ` your account and Update your Banking details`
              ) : (
                <>
                  Update your{" "}
                  <Typography
                    variant="body2"
                    component="a"
                    fontSize={12}
                    fontWeight={600}
                    color={orangeColor01}
                    sx={{
                      display: "inline",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate(`/${businessName}/affiliateBankDetails`);
                    }}
                  >
                    Banking details
                  </Typography>
                </>
              )}{" "}
              for direct settlement in your account
            </Typography>
          </Box>
        )}
      <AffliatePaymentsContent
        fetchPendingPayments={fetchPendingPayments}
        fetchSettledPayments={fetchSettledPayments}
        affiliateCommissionPercentage={commissionPercentage}
      />
      <GuestRegisterInstructions
        open={showRegisterInstr}
        setOpen={setShowRegisterInstr}
      />
    </Box>
  );
}

export default AffiliateProgramsContent

const styles = {
  costBoText: {
    color: orangeColor01,
    backgroundColor: lightOrange06,
    borderRadius: "5px",
    padding: "5px 10px",
    border: "1.5px dashed " + orangeColor01,
  },

  commissionCard: {
    alignItems: "center",
    // justifyContent: 'space-between',
    margin: "14px 16px 16px",
    borderRadius: "6px",
    cursor: "pointer",
    display: "flex",
    position: "relative",
    alignItems: "center",
    background: "#f3f3f8",
    marginTop: "5px",
    borderRadius: "10px",
    padding: "10px",
    border: "1px solid #e9e9f1",
  },

  exploreBtn: {
    fontSize: "13px",
    padding: "4px 12px",
    mt: 1.8,
  },
};
