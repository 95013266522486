import {
  BUSINESS_INFO_URL,
  BUY_AGAIN_PRODUCT,
  METRIC_URL,
} from "services/constant";
import { getApiInstance } from "../../ApiInstance";
import { getUniqueMetricProductIds } from "utils/utilities";
import { getVariantCountAPI } from "../subCategory/SubCategoryApis";

export const getBuyAgainData = async () => {
  const businessId = sessionStorage.getItem("businessId");
  const instance = getApiInstance();
  try {
    const response = await instance(BUY_AGAIN_PRODUCT, {
      headers: {
        businessId: businessId,
      },
      params: { pageNo: 1 },
    });
    console.log("BUY_DATA", response.data, response?.data?.details);

    if (response?.data?.details?.length > 0) {
      let products = response?.data?.details;

      const uniqueIds = [
        ...new Set(
          products
            ?.map((entry) => entry?.item.variantInfo?.masterProductVariantId)
            ?.filter((id) => id !== undefined && id !== null)
        ),
      ];

      // Convert to comma-separated string
      const result = uniqueIds;

      // const uniqueIds = getUniqueMetricProductIds(metricData).join(", ");
      console.log("BUY_AGAIN_UNIQUE_IDS", result);

      const countRes = await getVariantCountAPI(businessId, result);

      console.log("BUY_AGAIN_countRes", countRes);

      products.forEach((product) => {
        if (product?.item?.variantInfo?.masterProductVariantId) {
          // Find a matching item in the second array
          const match = countRes?.productVariantCount?.find(
            (e) =>
              e.masterProductVariantId ===
              product?.item?.variantInfo?.masterProductVariantId
          );

          // If a match is found, add the count to the first array's object
          if (match) {
            product.count = match.count;
          }
        }
      });

      console.log("BUY_AGAIN_PRODUCTS_DATA_AFTER_COUNT", products);

      return { details: products };
    }

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getMetricData = async () => {
  const businessId = sessionStorage.getItem("businessId");
  const instance = getApiInstance();
  try {
    const response = await instance(METRIC_URL, {
      headers: {
        businessId: businessId,
      },
    });
    console.log("METRIC_DATA", response.data);
    if (response?.data?.details?.length > 0) {
      let metricData = response?.data?.details?.[0];

      const uniqueIds = getUniqueMetricProductIds(metricData);
      console.log("METRIC_DATA_UNIQUE_IDS", uniqueIds);

      const countRes = await getVariantCountAPI(businessId, uniqueIds);

      console.log("METRIC_DATA_countRes", countRes);

      for (const key in metricData) {
        metricData[key].forEach((product) => {
          if (product.variantInfo?.masterProductVariantId) {
            // Find a matching item in the second array
            const match = countRes?.productVariantCount?.find(
              (e) =>
                e.masterProductVariantId ===
                product.variantInfo?.masterProductVariantId
            );

            // If a match is found, add the count to the first array's object
            if (match) {
              product.count = match.count;
            }
          }
        });
      }

      console.log("METRIC_DATA_AFTER_COUNT", metricData);

      return { details: [metricData] };
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getBusinessDataApi = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(`${BUSINESS_INFO_URL}?params=${data}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
