import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import { AddRounded, RemoveRounded } from '@mui/icons-material'
import {
  arrowBgColor,
  blackColor,
  greyShade51,
  greyShade53,
  headingColor,
  orangeColor01,
  whiteColor,
} from "../../configs/styles/muiThemes";

function QuantityPicker({ count, onIncrement, onDecrement, minQty, maxQty }) {
  return (
    <Stack>
      <Box sx={styles.quantityPicker}>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <Box
              sx={[
                styles.qtBtn,
                {
                  borderRight: "1px solid " + blackColor,
                  border: "",
                },
              ]}
              onClick={onDecrement}
            >
              <RemoveRounded
                sx={{
                  // color: count <= minQty ? arrowBgColor : headingColor,
                  color: whiteColor,
                  fontSize: "16px",
                }}
              />
            </Box>
          </Grid>

          {/* <Divider orientation='vertical' flexItem /> */}

          <Grid item>
            <Typography
              variant="body1"
              color={whiteColor}
              fontSize="12px"
              width="30px"
              fontWeight={600}
              textAlign="center"
            >
              {count}
            </Typography>
          </Grid>

          {/* <Divider orientation='vertical' flexItem sx={{backgroundColor: primaryColor}} /> */}

          <Grid item onClick={onIncrement}>
            <Box
              sx={[
                styles.qtBtn,
                {
                  borderLeft: "1px solid " + blackColor,
                  border: "",
                },
              ]}
            >
              <AddRounded
                sx={{
                  color: count >= maxQty ? whiteColor : whiteColor,
                  fontSize: "16px",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* <Grid container justifyContent="space-between">
        <Grid item>
          <Typography
            variant="body1"
            color={headingColor}
            fontSize="10px"
            fontWeight={500}
            textAlign="center"
          >
            Min {minQty}
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            variant="body1"
            color={headingColor}
            fontSize="10px"
            fontWeight={500}
            textAlign="center"
          >
            Max {maxQty}
          </Typography>
        </Grid>
      </Grid> */}
    </Stack>
    // <Box sx={styles.quantityPicker}>
    //   <IconButton
    //     sx={{
    //       ...styles.qtBtn,
    //       bgcolor: { xs: "transparent", md: greyShade53 },
    //       "&:hover": {
    //         bgcolor: { xs: "transparent", md: greyShade53 },
    //       },
    //     }}
    //     onClick={onDecrement}
    //   >
    //     <RemoveRounded
    //       sx={{
    //         color: { md: whiteColor, xs: whiteColor },
    //         fontSize: "18px",
    //       }}
    //     />
    //   </IconButton>

    //   <Typography
    //     variant="body1"
    //     color={{ md: headingColor, xs: whiteColor }}
    //     fontSize="12px"
    //     width="30px"
    //     fontWeight={600}
    //     textAlign="center"
    //   >
    //     {count}
    //   </Typography>

    //   <IconButton sx={styles.qtBtn} onClick={onIncrement}>
    //     <AddRounded
    //       sx={{
    //         color: { md: whiteColor, xs: whiteColor },
    //         fontSize: "18px",
    //       }}
    //     />
    //   </IconButton>
    // </Box>
    // <Stack>
    //   <Box sx={styles.quantityPicker}>
    //     <Grid container direction='row' alignItems='center'>
    //       <Grid item>
    //         <Box
    //           sx={[
    //             styles.qtBtn,
    //             {
    //               borderRight:
    //                 '1px solid ' + { md: orangeColor01, xs: whiteColor },
    //               border: '',
    //             },
    //           ]}
    //           onClick={onDecrement}
    //         >
    //           <RemoveRounded
    //             sx={{
    //               // color: count <= minQty ? arrowBgColor : headingColor,
    //               color: { md: headingColor, xs: whiteColor },
    //               fontSize: '18px',
    //             }}
    //           />
    //         </Box>
    //       </Grid>

    //       {/* <Divider orientation='vertical' flexItem /> */}

    //       <Grid item>
    //         <Typography
    //           variant='body1'
    //           color={{ md: headingColor, xs: whiteColor }}
    //           fontSize='12px'
    //           width='30px'
    //           fontWeight={600}
    //           textAlign='center'
    //         >
    //           {count}
    //         </Typography>
    //       </Grid>

    //       {/* <Divider orientation='vertical' flexItem sx={{backgroundColor: orangeColor01}} /> */}

    //       <Grid item onClick={onIncrement}>
    //         <Box
    //           sx={[
    //             styles.qtBtn,
    //             {
    //               borderLeft:
    //                 '1px solid ' + { md: orangeColor01, xs: whiteColor },
    //               border: '',
    //             },
    //           ]}
    //         >
    //           <AddRounded
    //             sx={{
    //               color:
    //                 count >= maxQty
    //                   ? arrowBgColor
    //                   : { md: headingColor, xs: whiteColor },
    //               fontSize: '18px',
    //             }}
    //           />
    //         </Box>
    //       </Grid>
    //     </Grid>
    //   </Box>
    //   {/* <Grid container justifyContent='space-between'>
    //     <Grid item>
    //       <Typography
    //         variant='body1'
    //         color={headingColor}
    //         fontSize='10px'
    //         fontWeight={500}
    //         textAlign='center'
    //       >
    //         Min {minQty}
    //       </Typography>
    //     </Grid>

    //     <Grid item>
    //       <Typography
    //         variant='body1'
    //         color={headingColor}
    //         fontSize='10px'
    //         fontWeight={500}
    //         textAlign='center'
    //       >
    //         Max {maxQty}
    //       </Typography>
    //     </Grid>
    //   </Grid> */}
    // </Stack>
  );
}

export default QuantityPicker

const styles = {
  // quantityPicker: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'space-between',
  //   width: { xs: '90px', md: '101px' },
  //   height: { xs: '28px', md: '33px' },
  //   backgroundColor: { md: whiteColor, xs: blackColor },
  //   border: { md: `1px solid ${greyShade51}`, xs: `1px solid ${blackColor}` },
  //   boxShadow: { xs: 'auto', md: '0px 2px 4px 0px rgb(0 0 0 / 4%)' },
  //   p: { xs: '1.6px 0', md: '0 4px' },
  //   mt: { xs: 1.5, md: 0 },
  //   overflow: 'hidden',
  //   borderRadius: { xs: '7px', md: '30px' },
  // },

  // qtBtn: {
  //   width: { xs: '28px', md: '27px' },
  //   height: { xs: '28px', md: '27px' },
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   cursor: 'pointer',
  //   bgcolor: { xs: 'transparent', md: blackColor },
  //   borderRadius: { xs: '0px', md: '20px' },

  //   '&:hover': {
  //     bgcolor: { xs: 'transparent', md: blackColor },
  //   },
  // },


  quantityPicker: {
    width:'75px',
    height: '28px',
    borderRadius: 1.5,
    backgroundColor: blackColor,
    border: '1px solid ' + blackColor,
    // boxShadow: '0 3px 4px rgb(0 0 0 / 15%)',
    overflow: 'hidden',
  },

  qtBtn: {
    width: '21px',
    height: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
}
