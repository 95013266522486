import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  checkBusinessReseller,
  checkDeliveryAvailability,
  delhiveryCostCalculation,
  delhiveryServiceability,
  delhiveryShipmentPrice,
  firstTimeDiscouunt,
  getAddressData,
  getBusinessContact,
  getCoordinates,
  getRewardPointsData,
  getStateCity,
  modifyAddress,
  validateCoupon,
  veiwActiveBusiness,
  getWebOrderPaymentId,
  sendOrderMessageApi,
  sendOrderNotificationApi,
  addPaymentProcessApi,
} from "services/api_calls/checkout/checkoutApi";

const initialState = {
  addressDetails: null,
  modifyAddressResponse: null,
  rewardpoints: null,
  loading: false,
  addressLoader: false,
  stateCityDetails: null,
  coordinatesData: null,
  valideCode: null,
  firstTimeDiscountData: null,
  checkResellerData: null,
  activeBusinessData: null,
  checkDeliveryAvailabilityData: null,
  delhiveryServiceabilityData: null,
  delhiveryCostCalculationData: null,
  delhiveryShipmentPriceData: null,
  businessContactData: null,
  paymentId: null,
  sendOrderMessageRes: null,
  sendOrderNotificationRes: null,
  addPaymentProcessRes: null,
  fintechStatus: null,
};

//Fetching Address details
export const getAddressDetails = createAsyncThunk(
  "getAddressDetails",
  async (thunkAPI) => {
    const res = await getAddressData();
    return res;
  }
);
//Modify Address details
export const modifyAddressDetails = createAsyncThunk(
  "modifyAddressDetails",
  async (params, thunkAPI) => {
    const res = await modifyAddress(params);
    return res;
  }
);
//Fetching Reward points details
export const getRewardPointsDetails = createAsyncThunk(
  "getRewardPointsDetails",
  async (thunkAPI) => {
    const res = await getRewardPointsData();
    return res;
  }
);
//Fetching State city details
export const getStateCityDetails = createAsyncThunk(
  "getStateCityDetails",
  async (params, thunkAPI) => {
    const res = await getStateCity(params);
    return res;
  }
);
export const getCoordinatesDetails = createAsyncThunk(
  "getCoordinatesDetails",
  async (params, thunkAPI) => {
    const res = await getCoordinates(params);
    return res;
  }
);
//Validate code details
export const validateCodeDetails = createAsyncThunk(
  "validateCodeDetails",
  async (params, thunkAPI) => {
    const res = await validateCoupon(params);
    return res;
  }
);
//Validate code details
export const firstTimeDiscountDetails = createAsyncThunk(
  "firstTimeDiscountDetails",
  async (params, thunkAPI) => {
    const res = await firstTimeDiscouunt(params);
    return res;
  }
);
//Check business reseller details
export const checkBusinessResellerDetails = createAsyncThunk(
  "checkBusinessResellerDetails",
  async (params, thunkAPI) => {
    const res = await checkBusinessReseller(params);
    return res;
  }
);
//View Active Business details
export const viewActiveBusinessDetails = createAsyncThunk(
  "viewActiveBusinessDetails",
  async (params, thunkAPI) => {
    const res = await veiwActiveBusiness(params);
    return res;
  }
);
//Check delivery Availability details
export const checkDeliveryAvailabilityDetails = createAsyncThunk(
  "checkDeliveryAvailabilityDetails",
  async (params, thunkAPI) => {
    const res = await checkDeliveryAvailability(params);
    return res;
  }
);
//delhiveryServiceability details
export const delhiveryServiceabilityDetails = createAsyncThunk(
  "delhiveryServiceabilityDetails",
  async (params, thunkAPI) => {
    const res = await delhiveryServiceability(params);
    return res;
  }
);
//delhiveryCostCalculation details
export const delhiveryCostCalculationDetails = createAsyncThunk(
  "delhiveryCostCalculationDetails",
  async (params, thunkAPI) => {
    const res = await delhiveryCostCalculation(params);
    return res;
  }
);
//delhiveryShipmentPrice details
export const delhiveryShipmentPriceDetails = createAsyncThunk(
  "delhiveryShipmentPriceDetails",
  async (params, thunkAPI) => {
    const res = await delhiveryShipmentPrice(params);
    return res;
  }
);

//getBusinessContact details
export const getBusinessContactDetails = createAsyncThunk(
  "getBusinessContactDetails",
  async (params, thunkAPI) => {
    const res = await getBusinessContact(params);
    return res;
  }
);

//updateWebOrderPayment
export const updateWebOrderPayment = createAsyncThunk(
  "updateWebOrderPayment",
  async (params) => {
    const res = await getWebOrderPaymentId(params);
    return res;
  }
);

//Send Order Message
export const sendOrderMessage = createAsyncThunk(
  "sendOrderMessage",
  async (params) => {
    const res = await sendOrderMessageApi(params);
    return res;
  }
);

//Send Order Notification
export const sendOrderNotification = createAsyncThunk(
  "sendOrderNotification",
  async (params) => {
    const res = await sendOrderNotificationApi(params);
    return res;
  }
);

//Add Payment Process
export const addPaymentProcess = createAsyncThunk(
  "addPaymentProcess",
  async (params) => {
    const res = await addPaymentProcessApi(params);
    return res;
  }
);

export const checkoutDetailsSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    clearRewardPointsData: (state) => {
      state.rewardpoints = null;
    },
    fintechStatusChange: (state, { payload }) => {
      state.fintechStatus = payload;
    },
  },
  extraReducers: {
    //address details
    [getAddressDetails.pending]: (state) => {
      state.addressLoader = true;
    },
    [getAddressDetails.fulfilled]: (state, { payload }) => {
      state.addressLoader = false;
      state.addressDetails = payload?.details;
    },
    [getAddressDetails.rejected]: (state) => {
      state.addressLoader = false;
    },

    //modify address details
    [modifyAddressDetails.pending]: (state) => {
      state.loading = true;
    },
    [modifyAddressDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.modifyAddressResponse = payload;
    },
    [modifyAddressDetails.rejected]: (state) => {
      state.loading = false;
    },

    //reward points details
    [getRewardPointsDetails.pending]: (state) => {
      state.loading = true;
    },
    [getRewardPointsDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.rewardpoints = payload;
    },
    [getRewardPointsDetails.rejected]: (state) => {
      state.loading = false;
    },

    //state city details
    [getStateCityDetails.pending]: (state) => {
      state.loading = true;
    },
    [getStateCityDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.stateCityDetails = payload;
    },
    [getStateCityDetails.rejected]: (state) => {
      state.loading = false;
    },
    //get Coordinates Details
    [getCoordinatesDetails.pending]: (state) => {
      state.loading = true;
    },
    [getCoordinatesDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.coordinatesData = payload;
    },
    [getCoordinatesDetails.rejected]: (state) => {
      state.loading = false;
    },
    //validateCodeDetails  details
    [validateCodeDetails.pending]: (state) => {
      state.loading = true;
    },
    [validateCodeDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.valideCode = payload;
    },
    [validateCodeDetails.rejected]: (state) => {
      state.loading = false;
    },
    //First Time  details
    [firstTimeDiscountDetails.pending]: (state) => {
      state.loading = true;
    },
    [firstTimeDiscountDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.firstTimeDiscountData = payload;
    },
    [firstTimeDiscountDetails.rejected]: (state) => {
      state.loading = false;
    },
    //checkBusinessResellerDetails
    [checkBusinessResellerDetails.pending]: (state) => {
      state.loading = true;
    },
    [checkBusinessResellerDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.checkResellerData = payload;
    },
    [checkBusinessResellerDetails.rejected]: (state) => {
      state.loading = false;
    },
    //view Active Business Details
    [viewActiveBusinessDetails.pending]: (state) => {
      state.loading = true;
    },
    [viewActiveBusinessDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.activeBusinessData = payload;
    },
    [viewActiveBusinessDetails.rejected]: (state) => {
      state.loading = false;
    },
    //check Delivery Availability Details
    [checkDeliveryAvailabilityDetails.pending]: (state) => {
      state.loading = true;
    },
    [checkDeliveryAvailabilityDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.checkDeliveryAvailabilityData = payload;
    },
    [checkDeliveryAvailabilityDetails.rejected]: (state) => {
      state.loading = false;
    },
    //delhiveryServiceabilityDetails
    [delhiveryServiceabilityDetails.pending]: (state) => {
      state.loading = true;
    },
    [delhiveryServiceabilityDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.delhiveryServiceabilityData = payload;
    },
    [delhiveryServiceabilityDetails.rejected]: (state) => {
      state.loading = false;
    },
    //delhiveryCostCalculationDetails
    [delhiveryCostCalculationDetails.pending]: (state) => {
      state.loading = true;
    },
    [delhiveryCostCalculationDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.delhiveryCostCalculationData = payload;
    },
    [delhiveryCostCalculationDetails.rejected]: (state) => {
      state.loading = false;
    },
    //delhiveryShipmentPriceDetails
    [delhiveryShipmentPriceDetails.pending]: (state) => {
      state.loading = true;
    },
    [delhiveryShipmentPriceDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.delhiveryShipmentPriceData = payload;
    },
    [delhiveryShipmentPriceDetails.rejected]: (state) => {
      state.loading = false;
    },
    //getBusinessContactDetails
    [getBusinessContactDetails.pending]: (state) => {
      state.loading = true;
    },
    [getBusinessContactDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.businessContactData = payload;
    },
    [getBusinessContactDetails.rejected]: (state) => {
      state.loading = false;
    },
    //updateWebOrderPayment
    [updateWebOrderPayment.pending]: (state) => {
      state.loading = true;
    },
    [updateWebOrderPayment.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.paymentId = payload;
    },
    [updateWebOrderPayment.rejected]: (state) => {
      state.loading = false;
    },
    //sendOrderMessage
    [sendOrderMessage.pending]: (state) => {
      state.loading = true;
    },
    [sendOrderMessage.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.sendOrderMessageRes = payload;
    },
    [sendOrderMessage.rejected]: (state) => {
      state.loading = false;
    },
    //sendOrderNotification
    [sendOrderNotification.pending]: (state) => {
      state.loading = true;
    },
    [sendOrderNotification.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.sendOrderNotificationRes = payload;
    },
    [sendOrderNotification.rejected]: (state) => {
      state.loading = false;
    },
    //addPaymentProcess
    [addPaymentProcess.pending]: (state) => {
      state.loading = true;
    },
    [addPaymentProcess.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.addPaymentProcessRes = payload;
    },
    [addPaymentProcess.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { clearRewardPointsData, fintechStatusChange } =
  checkoutDetailsSlice.actions;

export const checkoutDetailsReducer = checkoutDetailsSlice.reducer;
