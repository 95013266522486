import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Radio,
  Typography,
  useMediaQuery,
} from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import {
  backgroundColor,
  backgroundColor01,
  blackColor,
  borderLineColor,
  discountBgColor,
  errorTextColor,
  greenColor01,
  greenColor05,
  headingColor,
  offerTextColor,
  savingTextColor,
  whiteColor,
} from '../../../configs/styles/muiThemes'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import { useDispatch } from 'react-redux'
import { validateCodeDetails } from 'redux/checkout/CheckoutSlice'
import { discountCouponDataHelper } from 'utils/checkoutHelpers'
import {
  couponCard_CongratsMsg,
  couponCard_InvalidErrMsg,
  couponCard_SelectErrMsg,
  couponCard_SelectMsg,
} from 'configs/Constants'
import { textOneLines } from 'utils/styleUtility'
import { red } from '@mui/material/colors'

function CouponCard({
  isSelected,
  onSelect,
  dealsCouponsDetails,
  rewardPoints,
  cartDetails,
  commercialDetails,
  discountAmount,
  setDiscountAmount,
  setAppliedDiscountDetails,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const dispatch = useDispatch()
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)
  const [amount, setAmount] = useState(null)
  let businessFullName = sessionStorage.getItem('businessFullName')

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [text, SetText] = useState(couponCard_SelectMsg)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const [couponString, setCouponString] = useState('')

  let cartResellerDetails = {}

  const getCouponString = () => {
    let coupons = ``

    dealsCouponsDetails?.forEach((item) => {
      if (moment().isBefore(item?.codeValidityEnd)) {
        coupons =
          coupons + `${item.resellerCode} - ${item.discountPercentage}% OFF, `
      }
    })

    setCouponString(coupons)
  }

  useEffect(() => {
    getCouponString()
    return () => {}
  }, [dealsCouponsDetails])

  const handleClose = (event) => {
    setAnchorEl(null)
    if (!event.target.innerText) return
    SetText(event.target.innerText?.split(' ')[0])
  }

  useEffect(() => {
    if (!isSelected) {
      SetText(couponCard_SelectMsg)
      setIsError(false)
      setIsSuccess(false)
      setAmount(null)
      setDiscountAmount(0)
      setAppliedDiscountDetails(null)
    }
  }, [isSelected])

  const applyCoupon = () => {
    if (text === couponCard_SelectMsg || !text) {
      setIsError(true)
      setErrorMessage(couponCard_SelectErrMsg)
      return
    }
    dispatch(validateCodeDetails(text)).then((res) => {
      if (res?.payload?.reseller) {
        let discountAmt =
          cartDetails.orderTotalProductCost *
          (res.payload.reseller.discountPercentage / 100)
        setAmount(discountAmt.toFixed(2))
        setDiscountAmount(discountAmt.toFixed(2))
        setAppliedDiscountDetails(
          discountCouponDataHelper(res?.payload?.reseller, cartDetails)
        )

        console.log('cartResellerDetails', cartResellerDetails)
        setIsSuccess(true)
        setIsError(false)
      } else {
        setIsError(true)
        setErrorMessage(couponCard_InvalidErrMsg)
      }
      console.log('VALIDATE_RESPONSE', res)
    })
  }

  return (
    //   matches ? (
    //   <Box sx={styles.discountCard(isSelected)}>
    //     <Grid
    //       container
    //       direction='row'
    //       alignItems='center'
    //       justifyContent='space-between'
    //     >
    //       <Grid item>
    //         <Radio
    //           checked={isSelected}
    //           onChange={() => {
    //             onSelect()
    //           }}
    //           value='a'
    //           name='radio-buttons'
    //           inputProps={{ 'aria-label': 'A' }}
    //           size='small'
    //           sx={{ width: 35, height: 30 }}
    //         />
    //         <Typography
    //           variant='h6'
    //           component='p'
    //           fontSize={{ xs: 13.5, ms: 14 }}
    //           color={headingColor}
    //           display='inline'
    //         >
    //           Limited time offers
    //         </Typography>
    //       </Grid>

    //       <Grid item>
    //         <Box
    //           component='img'
    //           src='https://storage.googleapis.com/bodefaults/shopweb/discounts_coupon.svg'
    //           alt={businessFullName + ' CostBo'}
    //           width={{ xs: '48px', md: '46px' }}
    //           height={{ xs: '32px', md: '30px' }}
    //         />
    //       </Grid>
    //     </Grid>

    //     <Box mx={1.3} mt={{ xs: 1, md: 1.3 }} mb={{ xs: 0, md: 1.7 }}>
    //       <Grid container spacing={{ xs: 1, md: 2 }}>
    //         <Grid item xs={8.5}>
    //           <Box
    //             id='basic-button'
    //             aria-controls={open ? 'basic-menu' : undefined}
    //             aria-haspopup='true'
    //             aria-expanded={open ? 'true' : undefined}
    //             onClick={isSelected ? handleClick : () => {}}
    //             sx={styles.inputBox(isSelected, isSuccess)}
    //             fullWidth
    //             disabled={!isSelected}
    //             disableRipple
    //             display='flex'
    //             justifyContent='space-between'
    //             alignItems='center'
    //             // sx={styles.dropdownBtn}
    //             // endIcon={<KeyboardArrowDownOutlined />}
    //           >
    //             <Box>{text}</Box>

    //             <ArrowDropDownRoundedIcon sx={{ fontSize: '28px' }} />
    //           </Box>
    //           <Menu
    //             id='basic-menu'
    //             anchorEl={anchorEl}
    //             open={open}
    //             onClose={handleClose}
    //             MenuListProps={{
    //               'aria-labelledby': 'basic-button',
    //             }}
    //             variant='selectedMenu'
    //             PaperProps={{
    //               style: {
    //                 width: 250,
    //                 maxHeight: 250,
    //               },
    //             }}
    //           >
    //             {dealsCouponsDetails?.map((item, i) => {
    //               if (!moment().isBefore(item?.codeValidityEnd)) return null
    //               return (
    //                 <MenuItem onClick={handleClose} value={item.resellerCode}>
    //                   {item.resellerCode} - {item.discountPercentage}% OFF
    //                 </MenuItem>
    //               )
    //             })}
    //           </Menu>
    //         </Grid>
    //         <Grid item xs={3}>
    //           {isSelected && (
    //             <Button
    //               sx={styles.actionBtn}
    //               variant='contained'
    //               disableElevation
    //               onClick={() => applyCoupon()}
    //             >
    //               Apply
    //             </Button>
    //           )}
    //         </Grid>
    //       </Grid>
    //     </Box>

    //     {isSuccess ? (
    //       <Typography
    //         variant='subtitle1'
    //         component='p'
    //         fontSize={{ xs: '11px', md: '12px' }}
    //         fontWeight={500}
    //         color={savingTextColor}
    //         display='inline'
    //         mx={1.4}
    //       >
    //         {couponCard_CongratsMsg}
    //         {amount}
    //         {/* {JSON.stringify(cartResellerDetails)} */}
    //       </Typography>
    //     ) : (
    //       <Typography
    //         variant='subtitle1'
    //         component='p'
    //         fontSize={{ xs: '11px', md: '12px' }}
    //         fontWeight={500}
    //         color={blackColor}
    //         display='inline'
    //         mx={1.4}
    //         sx={textOneLines}
    //       >
    //         {couponString?.slice(0, couponString.length - 2)}
    //       </Typography>
    //     )}
    //     {/* Error and Success Message */}
    //     {isError ? (
    //       <Typography
    //         variant='subtitle1'
    //         component='p'
    //         fontSize={{ xs: '11px', md: '12px' }}
    //         fontWeight={500}
    //         color={errorTextColor}
    //         display='inline'
    //         mx={1.4}
    //       >
    //         {errorMessage}
    //       </Typography>
    //     ) : null}
    //   </Box>
    // ) : (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "auto",
      }}
    >
      {/* Background Image */}
      <Box
        component="img"
        src="https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/subtract.png" // Replace with your image path
        alt="Background"
        sx={{
          width: "100%",
          height: "auto",
          objectFit: "cover",
        }}
      />

      {/* Overlayed Code */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: { md: "90%", xs: "90%" },
          height: "100%",
          padding: 1,
        }}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          borderBottom={`1px dashed #d5d5e6`}
          pb={1}
        >
          <Grid item>
            <Radio
              checked={isSelected}
              onChange={onSelect}
              value="a"
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
              size="small"
              sx={{
                width: 35,
                height: 30,
                color: blackColor, // default color
                "&.Mui-checked": {
                  color: blackColor, // color when checked
                },
              }}
            />
            <Typography
              variant="h6"
              component="p"
              fontSize={{ xs: 12.5, md: 12.5 }}
              color={headingColor}
              display="inline"
            >
              Limited time offers
            </Typography>
          </Grid>

          <Grid item>
            <Box
              component="img"
              src="https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/limited-time-offer.svg"
              // src='https://storage.googleapis.com/bodefaults/shopweb/discounts_coupon.svg'
              alt={`${businessFullName} CostBo`}
              width={{ xs: "25px", md: "25px" }}
              height={{ xs: "25px", md: "25px" }}
            />
          </Grid>
        </Grid>

        <Box mx={1} mt={{ xs: 1, md: 1.3 }} mb={{ xs: 0, md: 1.7 }}>
          <Grid container spacing={{ xs: 1, md: 2 }}>
            <Grid item xs={9}>
              <Box
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={isSelected ? handleClick : () => {}}
                sx={styles.inputBox(isSelected, isSuccess)}
                fullWidth
                disabled={!isSelected}
                disableRipple
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>{text}</Box>

                <ArrowDropDownRoundedIcon sx={{ fontSize: "28px" }} />
              </Box>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                variant="selectedMenu"
                PaperProps={{
                  style: {
                    width: 250,
                    maxHeight: 250,
                  },
                }}
              >
                {dealsCouponsDetails?.map((item, i) => {
                  if (!moment().isBefore(item?.codeValidityEnd)) return null;
                  return (
                    <MenuItem
                      key={i}
                      onClick={handleClose}
                      value={item.resellerCode}
                    >
                      {item.resellerCode} - {item.discountPercentage}% OFF
                    </MenuItem>
                  );
                })}
              </Menu>
            </Grid>
            <Grid item xs={3}>
              {isSelected && (
                <Button
                  sx={styles.actionBtn}
                  variant="contained"
                  disableElevation
                  onClick={applyCoupon}
                >
                  Apply
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>

        {isSuccess ? (
          <Typography
            variant="subtitle1"
            component="p"
            fontSize={{ xs: "11px", md: "12px" }}
            fontWeight={500}
            color={{ md: greenColor05, xs: greenColor01 }}
            display="inline"
            mx={1.4}
            mt={1}
          >
            {couponCard_CongratsMsg}
            {amount}
          </Typography>
        ) : (
          <Typography
            variant="subtitle1"
            component="p"
            fontSize={{ xs: "11px", md: "12px" }}
            fontWeight={500}
            color={blackColor}
            display="inline"
            mx={1.4}
            sx={textOneLines}
            mt={1}
          >
            {couponString?.slice(0, couponString.length - 2)}
          </Typography>
        )}
        {isError ? (
          <Typography
            variant="subtitle1"
            component="p"
            fontSize={{ xs: "11px", md: "12px" }}
            fontWeight={500}
            color={errorTextColor}
            display="inline"
            mx={1.4}
          >
            {errorMessage}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
}

export default CouponCard

const styles = {
  discountCard: (isSelected) => ({
    backgroundColor: isSelected ? discountBgColor : whiteColor,
    borderRadius: "6px",
    border: { md: "1px solid" + borderLineColor, xs: "none" },
    px: 0.5,
    py: 1,
    height: { xs: "109px", md: "125px" },
  }),
  discountCardMobile: (isSelected) => ({
    width: "100%",
    backgroundColor: whiteColor,
    // backgroundColor: isSelected ? discountBgColor : whiteColor,
    borderRadius: "6px",
    border: { md: "1px solid" + borderLineColor, xs: "none" },
    px: 0.5,
    // py: 1,
    height: { xs: "118px", md: "125px" },
    pr: 2,
  }),

  inputBox: (isSelected, isSuccess) => ({
    height: { xs: "16px", md: "19.3px" },
    fontSize: { xs: "12px", md: "13px" },
    fontWeight: isSuccess ? 600 : 400,
    // color: savingTextColor,
    color: isSelected
      ? isSelected && isSuccess
        ? { md: greenColor05, xs: greenColor01 }
        : headingColor
      : offerTextColor,
    border: {
      xs: `1.5px dashed ${greenColor01}`,
      md: `1.5px dashed ${greenColor05}`,
    },
    backgroundColor: { md: whiteColor, xs: whiteColor },
    borderRadius: { xs: "4px", md: "6px" },
    py: 0.8,
    px: 1,
    "&:hover": {
      backgroundColor: { md: whiteColor, xs: whiteColor },
    },
  }),

  actionBtn: {
    height: { xs: "33px", md: "35px" },
    fontSize: { xs: "13px", md: "14px" },
    fontWeight: 600,
    color: { md: whiteColor, xs: whiteColor },
    backgroundColor: { md: greenColor05, xs: greenColor01 },
    px: { xs: "20px", md: "28px" },
    border: {
      md: "1px solid " + greenColor05,
      xs: "1px solid " + greenColor01,
    },
    borderRadius: { xs: "4px", md: "6px" },
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: { md: whiteColor, xs: whiteColor },
      color: { md: greenColor05, xs: greenColor01 },
    },
  },
};
