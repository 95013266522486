import { Box, Grid, Skeleton, Stack, useMediaQuery } from "@mui/material";
import { greyShade47, greyShade58 } from "configs/styles/muiThemes";
import React from "react";

function ProductByCategoryShimmer() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return matches ? (
    <Stack>
      <Box
        height="33px"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Skeleton
          variant="rectangle"
          width="20%"
          height={23}
          sx={{ borderRadius: "4px" }}
        />
        <Box display="flex" alignItems={"center"} gap={2}>
          <Skeleton
            variant="rectangle"
            width="120px"
            height={23}
            sx={{ borderRadius: "4px" }}
          />
          <Skeleton
            variant="rectangle"
            width="30px"
            height={30}
            sx={{ borderRadius: "4px" }}
          />
          <Skeleton
            variant="rectangle"
            width="30px"
            height={30}
            sx={{ borderRadius: "4px" }}
          />
        </Box>
      </Box>
      <Grid container columnSpacing="9.6px" rowSpacing={{ md: 2.5, xs: 0 }}>
        <Grid item xs={12} sm={3} md={2.4}>
          <Skeleton
            variant="rectangle"
            width="100%"
            height={242}
            sx={{ borderRadius: "10px" }}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={2.4}>
          <Skeleton
            variant="rectangle"
            width="100%"
            height={242}
            sx={{ borderRadius: "10px" }}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={2.4}>
          <Skeleton
            variant="rectangle"
            width="100%"
            height={242}
            sx={{ borderRadius: "10px" }}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={2.4}>
          <Skeleton
            variant="rectangle"
            width="100%"
            height={242}
            sx={{ borderRadius: "10px" }}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={2.4}>
          <Skeleton
            variant="rectangle"
            width="100%"
            height={242}
            sx={{ borderRadius: "10px" }}
          />
        </Grid>
      </Grid>
    </Stack>
  ) : (
    <Stack p="6px 15px">
      <Box display="flex" justifyContent="space-between" minHeight="54px">
        <Box display="flex">
          <Skeleton variant="rectangular" width="150px" height={25} />
          <Box
            component="span"
            color={greyShade58}
            fontSize={{ xs: "13px", md: "14px" }}
            fontWeight={400}
            mx={1}
            textAlign="center"
          >
            ·
          </Box>
          <Skeleton variant="rectangular" width="75px" height={25} />
        </Box>
        <Skeleton variant="rectangular" width="25px" height={25} />
      </Box>
      <ProductMobileLoaderCard first />
      <ProductMobileLoaderCard />
      <ProductMobileLoaderCard />
    </Stack>
  );
}

export default ProductByCategoryShimmer;

const ProductMobileLoaderCard = ({ first }) => {
  return (
    <Box
      sx={{
        width: "100%",
        // px: "15px",
        display: "flex",
        justifyContent: "space-between",
        gap: "20px",
        borderBottom: `1px solid ${greyShade47}`,
        pb: 3,
        pt: first ? 0 : 1.5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          gap: "6px",
          width: "65%",
        }}
      >
        <Stack>
          <Skeleton variant="text" width="100%" height={20} />
          <Skeleton variant="text" width="50%" height={20} />
        </Stack>
        <Skeleton variant="rectangular" width="30%" height={23} />
      </Box>
      <Skeleton
        variant="rectangular"
        width="110px"
        height={110}
        sx={{ borderRadius: "10px" }}
      />
    </Box>
  );
};
