import { Box, Button, Typography } from '@mui/material'
import {
  blackColor,
  errorTextColor,
  headingColor,
  loginPrimary,
  orangeColor01,
  secondaryColor,
  stepperIconColor,
  whiteColor,
} from 'configs/styles/muiThemes'
import { Link } from 'react-router-dom'
import {
  resetPassword_EnterPassword,
  resetPassword_SubTitle,
} from 'configs/Constants'
import AuthTextBox from 'components/AuthTextBox'
import PasswordRoundedIcon from '@mui/icons-material/PasswordRounded'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import EmailRoundedIcon from '@mui/icons-material/EmailRounded'
import { useState } from 'react'
import AuthPasswordBox from 'components/AuthPasswordBox'
import {
  inputError_EmailNotMatch,
  inputError_EmailUsedAlready,
  register_EnterEmail,
  register_EnterName,
  register_EnterNewPassword,
  register_ReEnterEmail,
  register_Title,
} from 'configs/Constants'
import {
  checkExistingUserGuest,
  getEregister,
  getLoginDetials,
  saveUserDetails,
  updateUserDetails,
} from 'redux/authRedux/authSlice'
import { useDispatch } from 'react-redux'
import {
  validateEmail,
  validateName,
  validatePassword,
  validatePasswordSave,
  validateRenterEmail,
} from 'utils/validationUtils'
import {
  getOwnRewards,
  upadateRewardProfile,
  userAuthGuestUser,
} from 'services/api_calls/auth/login_api'

function CreateAccountNew({ phone, token, onSuccess, userType }) {
  const [showPassword, setShowPassword] = useState(true)
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [reEnterEMail, setReEnterEMail] = useState('')

  const [nameErr, setNameErr] = useState('')
  const [emailErr, setEmailErr] = useState('')
  const [reEmailErr, setReEmailErr] = useState('')
  const [passwordErr, setPasswordErr] = useState('')

  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const [emailMsg, setEmailMsg] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)

  const symbol = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
  const numbers = /[0-9]/
  const capital = /^(?=.*[A-Z])/
  const length = /^(?=.{8,})/

  async function handleSubmit() {
    setLoading(true)
    setNameErr('')
    setPasswordErr('')
    setEmailErr('')
    setReEmailErr('')
    setErrorMsg(false)

    const nameError = validateName(name)
    const passError = validatePasswordSave(password)
    const mailErr = validateEmail(email)
    const reMailErr = validateRenterEmail(reEnterEMail, email)

    let error = false

    console.log('passError', passError)

    if (nameError.length > 0) {
      setNameErr(nameError)
      setLoading(false)
      error = true
    }
    if (mailErr.length > 0) {
      setEmailErr(mailErr)
      setLoading(false)
      error = true
    }
    if (reMailErr.length > 0) {
      setReEmailErr(reMailErr)
      setLoading(false)
      error = true
    }

    if (passError.length > 0) {
      setPasswordErr(passError)
      setLoading(false)
      error = true
    }

    if (error) {
      return
    }

    if (email.toLowerCase() === reEnterEMail.toLowerCase()) {
      setEmailMsg(false)
      // setIsPasswordError(false)
      setLoading(true)
      const newUser = userType === 'new-user' ? true : false
      console.log('fkfk', newUser)
      if (newUser) {
        regisTer({
          name,
          password,
          email,
          reEnterEMail,
          phone: '+91' + phone,
          token,
        })
      } else {
        guestRegister({
          name,
          password,
          email,
          reEnterEMail,
          phone: '+91' + phone,
          token,
        })
      }
    } else {
      setEmailMsg(true)
      setLoading(false)
    }
  }
  const checkUser = async (phoneNumber) => {
    if (userType === 'new-user') {
      return true
    } else {
      return false
    }
    // return dispatch(checkExistingUserGuest(phoneNumber)).then((response) => {
    //   console.log(response)
    //   if (response.payload) {
    //     if (response.payload.status === 200) {
    //       if (response.payload.data.na === false) {
    //         return true
    //       } else {
    //         return false
    //       }
    //     }
    //   }
    // })
  }

  const regisTer = async (values) => {
    console.log(values)
    let xmpprandomNumber = Math.floor(100000 + Math.random() * 900000)
    return dispatch(saveUserDetails({ ...values, xmpprandomNumber })).then(
      (response) => {
        console.log(response)
        if (response.payload) {
          if (response.payload.status === 200) {
            sessionStorage.setItem('profileId', response.payload.data.profileID)
            // let xmpprandomNumber = Math.floor(100000 + Math.random() * 900000)
            let profileIdRandomNo =
              response.payload.data.profileID + xmpprandomNumber
            var encodedprofileId = btoa(profileIdRandomNo)

            const eRegister = {
              ePhone: values.phone,
              eHost: 's.costbo.com',
              eUser: values.phone,
              ePassword: encodedprofileId,
            }
            // dispatch(
            //   getEregister({
            //     eRegister,
            //     profileId: response.payload.data.profileID,
            //   })
            // )
            const mobileNumber = values.phone.replace('+91', '')
            const loginData = {
              phone: mobileNumber,
              password: values.password,
            }
            const type = 'new'
            loginDetails(loginData, type)
          }
        } else {
          setErrorMsg(true)
          setLoading(false)
        }
      }
    )
  }
  const guestRegister = async (values) => {
    let xmpprandomNumber = Math.floor(100000 + Math.random() * 900000)
    console.log('guestRegister', values)
    let formdata = new FormData()
    formdata.append('grant_type', 'password')
    formdata.append('username', values.phone)
    formdata.append('password', values.token)
    userAuthGuestUser(formdata).then((res) => {
      console.log('USER_AUTH_RESPONSE', res.data.randomNo)
      // const updateValues = { ...values, id: res.data.randomNo }
      dispatch(
        updateUserDetails({
          values,
          id: res.data.randomNo,
          token: res.data.access_token,
          xmpprandomNumber: xmpprandomNumber,
        })
      ).then((response) => {
        console.log('xmpprandomNumber', xmpprandomNumber, response)
        if (response.payload) {
          if (response.payload.status === 200) {
            sessionStorage.setItem('profileId', res.data.randomNo)
            // let xmpprandomNumber = Math.floor(100000 + Math.random() * 900000)
            let profileIdRandomNo = res.data.randomNo + xmpprandomNumber
            var encodedprofileId = btoa(profileIdRandomNo)
            console.log('xmpprandomNumber1', xmpprandomNumber, encodedprofileId)

            // const eRegister = {
            //   ePhone: values.phone,
            //   eHost: 's.costbo.com',
            //   eUser: values.phone,
            //   ePassword: encodedprofileId,
            // }
            // dispatch(
            //   getEregister({
            //     eRegister,
            //     profileId: res.data.randomNo,
            //   })
            // )
            const mobileNumber = values.phone.replace('+91', '')
            const loginData = {
              phone: mobileNumber,
              password: values.password,
            }
            const type = 'old'
            loginDetails(loginData, type)
          }
        } else {
          setErrorMsg(true)
          setLoading(false)
        }
      })
    })
  }

  const loginDetails = async (loginData, type) => {
    return dispatch(getLoginDetials(loginData)).then((response) => {
      console.log(response)
      if (response.payload) {
        if (response.payload.status === 200) {
          if (type == 'new') {
            getOwnRewards('91' + phone).then((resp1) => {
              if (resp1?.data?.details) {
                upadateRewardProfile({
                  data: resp1?.data?.details,
                  phone: '91' + phone,
                }).then(() => {
                  setLoading(false)
                  // onLoginSuccess()
                })
              }
            })
          }
          setLoading(false)
          onSuccess()
        }
      } else {
        setErrorMsg(true)
        setLoading(false)
      }
    })
  }

  return (
    <Box px={2} pt={2}>
      <Typography
        variant="h5"
        component="h3"
        color={blackColor}
        fontSize={{ xs: 15 }}
        textAlign="center"
        lineHeight={1.5}
        pb={{ xs: 0, md: 2 }}
      >
        Register Detail
      </Typography>

      <AuthTextBox
        placeholder={register_EnterName}
        icon={<PersonRoundedIcon fontSize="small" />}
        isError={nameErr}
        errorMsg={nameErr}
        value={name}
        onChange={({ target }) => {
          if (nameErr) setNameErr("");
          setName(target.value);
        }}
        // onBlur= {handleAnyOtherChange}
        name="name"
        autoFocus={true}
      />
      <AuthTextBox
        placeholder={register_EnterEmail}
        icon={<EmailRoundedIcon fontSize="small" />}
        isError={emailErr}
        errorMsg={emailErr}
        value={email}
        onChange={({ target }) => {
          if (emailErr) setEmailErr("");
          setEmail(target.value);
        }}
        name="email"
      />
      <AuthTextBox
        placeholder={register_ReEnterEmail}
        value={reEnterEMail}
        onChange={({ target }) => {
          if (reEmailErr) setReEmailErr("");
          setReEnterEMail(target.value);
        }}
        name="reEnterEMail"
        icon={<EmailRoundedIcon fontSize="small" />}
        isError={reEmailErr}
        errorMsg={reEmailErr}
      />
      <AuthPasswordBox
        value={password}
        onChange={({ target }) => {
          if (passwordErr) setPasswordErr("");
          const value = target.value.replace(/[ ]/g, "");
          setPassword(value);
        }}
        name="password"
        placeholder={resetPassword_EnterPassword}
        isError={passwordErr}
        errorMsg={passwordErr}
        lower={length.test(password)}
        symbol={symbol.test(password.trim())}
        upper={capital.test(password)}
        numberFormat={numbers.test(password)}
        icon={
          <VisibilityRoundedIcon
            onClick={() => {
              setShowPassword(!showPassword);
            }}
            fontSize="small"
            sx={{ color: !showPassword ? loginPrimary : "" }}
          />
        }
        inputType={!showPassword ? "text" : "password"}
        isPasswordError={passwordErr}
        maxLength={25}
      />
      <Box sx={{ mb: 1, mt: 2 }}>
        <Typography
          variant="body1"
          component="p"
          color={stepperIconColor}
          fontSize={12}
          display="inline"
          lineHeight={1.5}
        >
          By continuing, you agree to our{" "}
          <Typography
            color={orangeColor01}
            display="inline"
            fontSize={12}
            fontWeight={500}
            component={Link}
            to={`/termsAndConditions`}
            lineHeight={1.5}
          >
            Terms of Use
          </Typography>{" "}
          &{" "}
          <Typography
            color={orangeColor01}
            display="inline"
            fontSize={12}
            fontWeight={500}
            component={Link}
            lineHeight={1.5}
            // target='_blank'
            to={`/privacyPolicy`}
          >
            Privacy Policy
          </Typography>
        </Typography>
      </Box>
      {emailMsg && (
        <Box>
          <Typography
            variant="subtitle1"
            component="p"
            fontSize={13}
            color={errorTextColor}
          >
            {inputError_EmailNotMatch}
          </Typography>
        </Box>
      )}
      {errorMsg && (
        <Box>
          <Typography
            variant="subtitle1"
            component="p"
            fontSize={13}
            color={errorTextColor}
          >
            {inputError_EmailUsedAlready}
          </Typography>
        </Box>
      )}
      <Button
        sx={{
          ...styles.actionBtn(),
          border: loading ? "none" : `1px solid ${blackColor}`,
        }}
        variant="contained"
        fullWidth
        disableElevation
        size="small"
        type="button"
        onClick={handleSubmit}
        disabled={loading}
      >
        Sign up
      </Button>
    </Box>
  );
}

export default CreateAccountNew

const styles = {
  actionBtn: (outline) => ({
    fontSize: "15px",
    fontWeight: 500,
    color: outline ? blackColor : whiteColor,
    p: "3px 20px",
    mt: "15px !important",
    border: "1px solid " + blackColor,
    backgroundColor: outline ? whiteColor : blackColor,
    borderRadius: "5px",
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: outline ? blackColor : whiteColor,
      color: outline ? whiteColor : blackColor,
    },
    letterSpacing: "0.5px",
  }),
};
