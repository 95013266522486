import { Box, Skeleton, Stack } from "@mui/material";
import React from "react";

function CategoryFilterLoader() {
  return (
    <Box mt={1.5}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Skeleton variant="text" width="100px" sx={{ fontSize: "2rem" }} />
        <Skeleton
          variant="rectangle"
          width="22px"
          height={22}
          sx={{ borderRadius: "4px" }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={2}
        ml={1}
      >
        <Skeleton variant="text" width="130px" sx={{ fontSize: "2rem" }} />
        <Skeleton
          variant="rectangle"
          width="30px"
          height={22}
          sx={{ borderRadius: "12px" }}
        />
      </Box>
      <Stack mt={1} ml={2} gap={1}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Skeleton variant="text" width="150px" sx={{ fontSize: "2rem" }} />
          <Skeleton
            variant="rectangle"
            width="30px"
            height={22}
            sx={{ borderRadius: "12px" }}
          />
        </Box>
        <Skeleton variant="text" width="120px" sx={{ fontSize: "2rem" }} />
        <Skeleton variant="text" width="120px" sx={{ fontSize: "2rem" }} />
        <Skeleton variant="text" width="120px" sx={{ fontSize: "2rem" }} />
        <Skeleton variant="text" width="120px" sx={{ fontSize: "2rem" }} />
        <Skeleton variant="text" width="120px" sx={{ fontSize: "2rem" }} />
        <Skeleton variant="text" width="120px" sx={{ fontSize: "2rem" }} />
        <Skeleton variant="text" width="120px" sx={{ fontSize: "2rem" }} />
        <Skeleton variant="text" width="120px" sx={{ fontSize: "2rem" }} />
        <Skeleton variant="text" width="120px" sx={{ fontSize: "2rem" }} />
      </Stack>
    </Box>
  );
}

export default CategoryFilterLoader;
