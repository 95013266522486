import { Box, Container } from "@mui/material";
import BusinessBanners from "components/BusinessBanners/BusinessBanners";
import {
  backgroundColor01,
  lightBlueColor01,
  whiteColor,
} from "configs/styles/muiThemes";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FeaturedCategories from "./Sections/FeaturedCategories";
import ProductMetricGroup from "./Sections/ProductMetricGroup";
import CategoryProducts from "./Sections/CategoryProducts";
import BrandInfoCard from "./Sections/BrandInfoCard";
import { getOverviewDetails } from "redux/overview/overviewSlice";
import Testimonials from "./Sections/Testimonials";
import ServicesAndContactInfo from "./Sections/ServicesAndContactInfo";
import QuickAction from "./Sections/QuickAction";
import { useNavigate } from "react-router-dom";
import DiscountsAndCoupons from "./Sections/DiscountsAndCoupons";
import ReturnInfo from "./Sections/ReturnInfo";
import SingleBannerBusinessInfo from "./Sections/SingleBannerBusinessInfo";
import HomeContentLoader from "components/skeletonLoaders/home/HomeContentLoader";

const HomeContentWeb = ({
  productsGroup,
  commercialDetails,
  dealsCouponsDetails,
  allReviews,
  reviewSummary,
  rewardpoints,
  buyAgainDetails,
  metricDetails,
  productLength,
  loader,
  isFinvervAvailable,
  finAvailValue,
  finPercentComplete,
  getFinvervLinesData,
  getFinboxLinesData,
  finboxAvlAmt,
  setShowLogin,
  finboxOverdueDetails,
  noCatalog,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const businessName = sessionStorage.getItem("businessName");
  const { overviewDetails, loading } = useSelector((state) => state.overview);

  const { businessDetails, social, total } = useSelector(
    (state) => state.header
  );

  const { isLoggedIn } = useSelector((state) => state.auth);

  const [dealFilteredArray, setDealFilteredArray] = useState([]);
  const [finvervModal, setFinvervModal] = useState({ show: false, url: "" });
  const [finvervModal1, setFinvervModal1] = useState({ show: false, url: "" });
  const { accountDetails } = useSelector((state) => state.dashboard);
  const [loginPopUp, setLoginPopUp] = useState(false);

  useEffect(() => {
    console.log("HOME_CONTENT_HITTT");
    if (metricDetails !== null && metricDetails[0].discounts) {
      if (metricDetails[0].discounts.length > 0) {
        let filterArray = metricDetails[0].discounts.filter((item) => {
          return item.percentageOff > 0;
        });
        setDealFilteredArray(filterArray);
      }
    }

    return () => {};
  }, [metricDetails]);

  useEffect(() => {
    if (overviewDetails) {
      dispatch(getOverviewDetails());
    }

    return () => {};
  }, []);

  return noCatalog ? (
    <Box sx={{ bgcolor: backgroundColor01 }}>
      {/* {businessDetails?.businessInfo?.[0]?.documentsURL?.length <= 2 ? (
        <SingleBannerBusinessInfo
          businessDetails={overviewDetails || null}
          setShowLogin={setShowLogin}
          reviewSummary={reviewSummary}
          social={social}
          total={total}
        />
      ) : ( */}
      <BusinessBanners
        businessBanners={businessDetails?.businessInfo?.[0]?.documentsURL || []}
        location={businessDetails?.businessInfo}
        businessDetails={overviewDetails || null}
        reviewSummary={reviewSummary}
        social={social}
        total={total}
      />
      {/* // )} */}
    </Box>
  ) : (
    <Box sx={{ bgcolor: backgroundColor01 }}>
      {/* {businessDetails?.businessInfo?.[0]?.documentsURL?.length <= 2 ? (
        <SingleBannerBusinessInfo
          businessDetails={overviewDetails || null}
          setShowLogin={setShowLogin}
          reviewSummary={reviewSummary}
          social={social}
          total={total}
        />
      ) : ( */}
      <BusinessBanners
        businessBanners={businessDetails?.businessInfo?.[0]?.documentsURL || []}
        businessDetails={overviewDetails || null}
        reviewSummary={reviewSummary}
        social={social}
        total={total}
      />
      {/* )} */}

      {metricDetails ? (
        <>
          <Container
            sx={{
              maxWidth: "1350px !important",
              p: "0px 15px 0px 15px !important",
            }}
          >
            <FeaturedCategories
              businessName={businessName}
              categories={productsGroup}
              businessDetails={overviewDetails || null}
            />
          </Container>
          <Container
            sx={{
              maxWidth: "1350px !important",
              p: "0px 15px !important",
            }}
          >
            {dealsCouponsDetails?.length > 0 && (
              <DiscountsAndCoupons
                // dealsCouponsDetails={[...dealsCouponsDetails, ...dealsCouponsDetails]}
                dealsCouponsDetails={dealsCouponsDetails}
              />
            )}
          </Container>
          <Container
            sx={{
              maxWidth: "1350px !important",
              p: "0px 15px 10px 15px !important",
              gap: "0!important'",
            }}
          >
            {isLoggedIn &&
            buyAgainDetails &&
            buyAgainDetails?.length > 0 &&
            buyAgainDetails !== undefined ? (
              <ProductMetricGroup
                title="Buy Again"
                products={buyAgainDetails}
                buyProduct
                imgUrl="https://storage.googleapis.com/bodefaults/shopweb/banner_buy_again.png"
                bgcolor="rgb(218, 224, 255)"
                buyAgain
                // onViewMore={() => {
                //   navigate(`/${businessName}/catalog`)
                // }}
              />
            ) : null}

            {metricDetails !== null && metricDetails[0].orders && (
              <>
                {metricDetails.length > 0 && productLength >= 7 ? (
                  metricDetails[0].orders.length > 0 ? (
                    <ProductMetricGroup
                      title="Trending Products"
                      imgUrl="https://storage.googleapis.com/bodefaults/shopweb/banner_trending_product.png"
                      bgcolor={lightBlueColor01}
                      products={metricDetails[0].orders}
                      onViewMore={() => {
                        navigate(`/${businessName}/catalog`);
                      }}
                    />
                  ) : null
                ) : null}
              </>
            )}

            <ReturnInfo commercialDetails={commercialDetails} />

            <QuickAction
              metricDetails={metricDetails}
              productLength={productLength}
            />

            {metricDetails !== null && (
              <Box>
                {metricDetails.length > 0 && productLength >= 10 ? (
                  dealFilteredArray.length > 0 ? (
                    <ProductMetricGroup
                      title="Top deals"
                      imgUrl="https://storage.googleapis.com/bodefaults/shopweb/banner_top_deals.png"
                      bgcolor="rgb(245, 231, 207)"
                      products={dealFilteredArray}
                      onViewMore={() => {
                        navigate(`/${businessName}/catalog`);
                      }}
                    />
                  ) : null
                ) : null}
              </Box>
            )}

            {productsGroup?.length > 0 && (
              <CategoryProducts
                title={productsGroup?.[0]?.subCatValue}
                productCount={productsGroup?.[0]?.count || 0}
                onViewMore={() => {
                  navigate(`/${businessName}/catalog`, {
                    state: { categorySideBar: productsGroup?.[0] },
                  });
                }}
                products={productsGroup?.[0]?.products || []}
              />
            )}

            {/* {businessDetails?.businessInfo?.[0]?.documentsURL?.length > 2 && ( */}
              <BrandInfoCard
                businessDetails={overviewDetails || null}
                setShowLogin={setShowLogin}
              />
            {/* )} */}
            {console.log("productsGroupproductsGroup", productsGroup)}
            {productsGroup?.length > 1 && (
              <Box mt="30px">
                <CategoryProducts
                  title={productsGroup?.[1]?.subCatValue}
                  productCount={productsGroup?.[1]?.count || 0}
                  onViewMore={() => {
                    navigate(`/${businessName}/catalog`, {
                      state: { categorySideBar: productsGroup?.[1] },
                    });
                  }}
                  products={productsGroup?.[1]?.products || []}
                />
              </Box>
            )}

            {productsGroup?.length > 2 && (
              <>
                {productsGroup?.slice(2)?.map((category, index) => {
                  return (
                    category?.products?.length >= 6 && (
                      <Box mt="30px" key={`category_section_${index}`}>
                        <CategoryProducts
                          title={category?.subCatValue}
                          productCount={category?.count || 0}
                          onViewMore={() => {
                            navigate(`/${businessName}/catalog`, {
                              state: { categorySideBar: category },
                            });
                          }}
                          products={category?.products || []}
                        />
                      </Box>
                    )
                  );
                })}
              </>
            )}

            <Testimonials allReviews={allReviews} />

            <ServicesAndContactInfo />
          </Container>
        </>
      ) : (
        <HomeContentLoader />
      )}
    </Box>
  );
};

export default HomeContentWeb;
