import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
  blackColor,
  catalogSubHeaderColor,
  productLabelColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import { textOneLines } from "utils/styleUtility";

function MainCategory({
  label,
  productCount,
  isSubCatagory,
  titleColor,
  subCat,
  onViewMore,
}) {
  return (
    <Box sx={{ cursor: "pointer", mb: isSubCatagory ? "0px" : "0px" }}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        py={0.5}
      >
        <Box
          width="80%"
          onClick={() => {
            onViewMore(subCat);
          }}
        >
          <Typography
            variant="body1"
            color={titleColor ? titleColor : productLabelColor}
            fontSize={isSubCatagory ? 13 : 13}
            fontWeight={isSubCatagory ? 600 : 600}
            sx={{ ...textOneLines }}
          >
            {label}
          </Typography>
        </Box>

        <Box
          sx={{
            fontSize: 11.5,
            fontWeight: 500,
            color: blackColor,
            bgcolor: whiteColor,
            lineHeight: "16px",
            borderRadius: "20px",
            p: "2px 9px",
            minWidth: "10px",
            my: "3px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {productCount}
        </Box>
      </Grid>
    </Box>
  );
}

export default MainCategory;
