import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import {
  backgroundColor,
  blackColor,
  borderCard01,
  borderTextbox,
  filterBgColor,
  greenColor03,
  iconInactiveColor,
  lightOrange02,
  lightPurple02,
  orangeColor01,
  orangeColor12,
  orangeColor13,
  whiteColor,
} from "configs/styles/muiThemes";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import Slider from "react-slick/lib/slider";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { textTwoLines } from "utils/styleUtility";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  ChevronLeft,
  ChevronRight,
  KeyboardArrowDownOutlined,
} from "@mui/icons-material";
import { getDiscountPercentage } from "utils/utilities";
const QuickAction = ({ metricDetails, productLength }) => {
  return (
    <Box
      my="30px"
      // sx={{
      //   "& > *": {
      //     // Applies to direct children
      //     margin: "0 !important",
      //     padding: "0 !important",
      //   },
      // }}
    >
      <Grid container spacing="30px" sx={{ display: "flex" }}>
        {metricDetails?.length > 0 && productLength >= 10 ? (
          metricDetails?.[0]?.newest?.length > 0 ? (
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
            >
              <ActionInfoCard
                title={`New in Our Store`}
                products={metricDetails?.[0]?.newest}
                onClick={() => {}}
                bgcolor={lightOrange02}
                sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
              />
            </Grid>
          ) : null
        ) : null}

        {metricDetails?.length > 0 && productLength >= 10 ? (
          metricDetails?.[0]?.lowestRate?.length > 0 ? (
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
            >
              <ActionInfoCard
                title={`Priced Just Right`}
                products={metricDetails?.[0]?.lowestRate}
                onClick={() => {}}
                bgcolor={lightPurple02}
                sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
              />
            </Grid>
          ) : null
        ) : null}
      </Grid>
    </Box>
  );
};

export default QuickAction;

const ActionInfoCard = ({ title, products, bgcolor }) => {
  const slider = useRef(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const navigate = useNavigate();
  const businessName = sessionStorage.getItem("businessName");

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1060,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };

  const onSlideNext = () => {
    if (currentSlideIndex < products?.length - 3) {
      slider.current.slickNext();
    }
  };

  const onSlidePrev = () => {
    if (currentSlideIndex > 0) {
      slider.current.slickPrev();
    }
  };

  return (
    <Box sx={{ ...styles.card, bgcolor: bgcolor }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          variant="h4"
          fontSize={16}
          fontWeight={600}
          lineHeight="30px"
          whiteSpace="pre-line"
        >
          {title}
        </Typography>

        {/* <Button
          variant='text'
          disableElevation
          endIcon={<ArrowForwardIcon />}
          onClick={() => {
            navigate(`/${businessName}/catalog`)
          }}
          sx={styles.viewBtn}
        >
          View More
        </Button> */}
        {products?.length > 3 && (
          <Grid item display={{ xs: "none", md: "block" }}>
            <Grid container spacing={1.5}>
              <Grid item>
                <Box
                  width="30px"
                  height="30px"
                  // backgroundColor={arrowBgColor}
                  backgroundColor={
                    currentSlideIndex > 0 ? filterBgColor : whiteColor
                  }
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius={2}
                  sx={{ cursor: "pointer" }}
                  // onClick={() => onPrev()}
                  onClick={onSlidePrev}
                  border={borderTextbox}
                >
                  <ChevronLeft
                    // fontSize={"medium"}
                    sx={{
                      fontSize: "22px",
                      color:
                        currentSlideIndex > 0 ? blackColor : iconInactiveColor,
                    }}
                  />
                </Box>
              </Grid>

              <Grid item>
                <Box
                  width="30px"
                  height="30px"
                  // backgroundColor={arrowBgColor}
                  backgroundColor={
                    currentSlideIndex < products?.length - 3
                      ? filterBgColor
                      : whiteColor
                  }
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius={2}
                  sx={{ cursor: "pointer" }}
                  // onClick={() => onNext()}
                  onClick={onSlideNext}
                  border={borderTextbox}
                >
                  <ChevronRight
                    sx={{
                      fontSize: "22px",
                      color:
                        currentSlideIndex < products?.length - 3
                          ? blackColor
                          : iconInactiveColor,
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>

      <Box mt="15px">
        {products?.length > 3 ? (
          <Box
            sx={{
              borderRadius: "20px",
            }}
            position="relative"
          >
            <Box overflow="hidden">
              <Slider
                ref={slider}
                {...settings}
                beforeChange={(oldIndex, newIndex) => {
                  setCurrentSlideIndex(newIndex);
                }}
              >
                {products?.map((item, index) => {
                  if (item?.variantInfo?.masterVariant) return null;

                  return (
                    <Box key={`${title}_${index}`}>
                      <ProductCardSmall
                        title={item?.productName}
                        imgUrl={item?.productPhoto?.[0]?.docURL}
                        oldPrice={item?.productOriginalPrice}
                        price={item?.productSalePrice}
                        discount={item?.percentageOff}
                        onClick={() => {
                          navigate(
                            `/${businessName}/singleProduct/${item?.id}`
                          );
                        }}
                        marginLeft={index != 0 ? "10px" : "0px"}
                        variantCount={item?.count}
                      />
                    </Box>
                  );
                })}
              </Slider>
            </Box>

            {/* {products?.length > 3 && (
              <>
                {currentSlideIndex > 0 && (
                  <IconButton
                    sx={{ ...styles.arrowIconBtn, left: "-15px" }}
                    onClick={onSlidePrev}
                  >
                    <KeyboardArrowLeftIcon color="inherit" />
                  </IconButton>
                )}

                {currentSlideIndex < products?.length - 3 && (
                  <IconButton
                    sx={{ ...styles.arrowIconBtn, right: "-15px" }}
                    onClick={onSlideNext}
                  >
                    <KeyboardArrowRightIcon color="inherit" />
                  </IconButton>
                )}
              </>
            )} */}
          </Box>
        ) : (
          <Grid container spacing="10px">
            {products?.slice(0, 3)?.map((item, index) => {
              if (item?.variantInfo?.masterVariant) return null;

              return (
                <Grid key={`${title}_${index}`} item md={4}>
                  <ProductCardSmall
                    title={item?.productName}
                    imgUrl={item?.productPhoto?.[0]?.docURL}
                    oldPrice={item?.productOriginalPrice}
                    price={item?.productSalePrice}
                    discount={item?.percentageOff}
                    onClick={() => {
                      navigate(`/${businessName}/singleProduct/${item?.id}`);
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

const ProductCardSmall = ({
  title,
  imgUrl,
  price,
  oldPrice,
  discount,
  onClick,
  marginLeft,
  variantCount,
}) => {
  return (
    <Box
      sx={{ ...styles.productCard, ml: marginLeft }}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      position={"relative"}
    >
      {oldPrice !== price && (
        <Box
          sx={{
            position: "absolute",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            gap: "4px",
            minWidth: "0px",
            background: "#10847e",
            color: "rgb(255, 255, 255)",
            borderRadius: "5px",
            lineHeight: "18px",
            padding: "1px 5px",
            fontWeight: 600,
            fontSize: "10px",
            textTransform: "capitalize",
            zIndex: 9,
            top: "10px",
            left: "10px",
          }}
        >
          {getDiscountPercentage(price, oldPrice)}%
        </Box>
      )}
      <Box
        component="img"
        src={imgUrl}
        sx={{
          width: "120px",
          height: "90px",
          objectFit: "contain",
          transform: "scale(1)",
          "&:hover": {
            transform: "scale(0.97)",
          },
        }}
        onClick={onClick}
      />
      <Box
        display={"flex"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
      >
        <Typography
          height="36px"
          fontSize={12}
          fontWeight={500}
          lineHeight="18px"
          color={blackColor}
          mt="12px !important"
          sx={textTwoLines}
          textAlign={"left"}
        >
          {title}
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems={"center"}
        justifyContent="space-between"
        mt="10px"
        width={"100%"}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          minHeight={"38px"}
        >
          <Typography
            fontSize={14}
            fontWeight={600}
            lineHeight={"20px"}
            color={blackColor}
          >
            ₹ {price}
          </Typography>
          {oldPrice != price && (
            <Typography
              fontSize={12}
              fontWeight={500}
              color="#adadc4"
              lineHeight={"18px"}
              sx={{ textDecoration: "line-through", display: "inline" }}
            >
              ₹ {oldPrice}
            </Typography>
          )}

          {/* {discount > 0 && (
              <Typography sx={styles.discountText}>{discount}% OFF</Typography>
            )} */}
        </Box>

        {variantCount ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: "30px",
              height: "30px",
              color: orangeColor01,
              fontSize: "12px",
              fontWeight: "600",
              cursor: "pointer",
              backgroundColor: orangeColor12,
              borderRadius: "50%",
              border: "1px solid" + orangeColor13,
            }}
          >
            +{Number(variantCount) - 1 || "X"}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

const styles = {
  card: {
    p: "20px",
    borderRadius: "15px",
  },

  viewBtn: {
    color: blackColor,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "20px",
    textTransform: "unset",
  },

  arrowIconBtn: {
    width: "32px",
    height: "38px",
    color: blackColor,
    bgcolor: whiteColor,
    p: 0.7,
    border: borderCard01,
    borderRadius: "7px",
    position: "absolute",
    top: "40%",
    boxShadow: "0 1px 5px rgb(202 202 214 / 55%)",
    "&:hover": {
      color: blackColor,
      bgcolor: whiteColor,
    },
  },

  productCard: {
    p: "15px",
    borderRight: `1px solid #EFEFEF`,
    bgcolor: whiteColor,
    borderRadius: "10px",
  },

  discountText: {
    fontSize: 13,
    fontWeight: 600,
    color: greenColor03,
    lineHeight: "10px",
    ml: "10px",
    display: "inline",
  },

  actionBtn: {
    width: "28px",
    height: "28px",
    color: blackColor,
    backgroundColor: whiteColor,
    border: `1px solid ${blackColor}`,

    // transition: 'all ease 0.3s',
    "&:hover": {
      color: whiteColor,
      backgroundColor: blackColor,
      // transform: 'scale(1.08)',
    },
  },
};
