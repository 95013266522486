import { keyframes } from '@emotion/react'

const phAnimation = keyframes`
  0% {
		transform: translate3d(-30%, 0, 0)
	}
	to {
		transform: translate3d(30%, 0, 0)
	}
`

export const skeletonBox = {
  boxAnim: {
    backgroundColor: '#fff',
    border: '1px solid #f1f1f1',
    borderRadius: '5px',
    overflow: 'hidden',
    position: 'relative',
    '&::before': {
      '-webkit-animation': `${phAnimation} .8s linear infinite`,
      animation: `${phAnimation} .8s linear infinite`,
      background:
        'linear-gradient(90deg, hsla(0, 0%, 100%, 0) 46%, hsla(0, 0%, 100%, .35) 50%, hsla(0, 0%, 100%, 0) 54%) 50% 50%',
      bottom: 0,
      content: '""',
      left: '50%',
      marginLeft: '-250%',
      pointerEvents: 'none',
      position: 'absolute',
      right: 0,
      top: 0,
      width: '500%',
      zIndex: 1,
    },
  },
}

export const textOneLines = {
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  overflow: 'hidden',
  '-webkit-line-clamp': '1',
  '-webkit-box-orient': 'vertical',
  lineBreak:'anywhere'
}
export const textTwoLines = {
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  overflow: 'hidden',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
  lineBreak:'anywhere'
}

export const scrollBox = {
  display: { xs: 'flex', md: 'none' },
  alignItems: 'center',

  overflowX: 'auto',
  overscrollBehaviorInline: 'contain',

  '&::-webkit-scrollbar': {
    display: 'none',
  },

  '-webkit-overflow-scrolling': 'touch',
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
}