import { Box, Typography } from '@mui/material'
import React from 'react'
import {
  backgroundColor,
  backgroundColor01,
  blackColor,
  breadcrumbBgColor,
  categoryLabelColor,
  dashboardDescriptionColor,
  greenColor01,
  greenColor06,
  headingColor,
  orderStepInactiveColor,
  savingTextColor,
  secondaryColor,
  whiteColor,
} from '../../configs/styles/muiThemes'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import { CloseRounded } from '@mui/icons-material'
import { green } from '@mui/material/colors'

function OrderStep({ completed, label, time, active, isCancelled, hideDate,hideLeft,hideRight }) {

  // Split the formatted date into two lines by breaking it at the comma
  const [monthDay, year] = time.split(', ') // e.g., "Jun 18" and "2024"

  // Combine both in a single variable with a newline
  const displayDate = monthDay ? `${monthDay},\n${year}`:""

  return (
    <Box>
      <Box sx={{ position: 'relative' }} py={3}>
        <Box
          sx={{
            height: '3px',
            width: label === 'Created' ? "0":'100%',
            backgroundColor: { md: breadcrumbBgColor, xs: breadcrumbBgColor },
          }}
        >
          {label === 'Created' ? (
            null
          ) : (
            <Box
              sx={{
                height: '3px',
                width: completed ? (active ? '50%' : '100%') : '0%',
                backgroundColor: { md: greenColor06, xs: greenColor01 },
              }}
            ></Box>
          )}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            backgroundColor: completed
              ? { md: secondaryColor, xs: greenColor01 }
              : breadcrumbBgColor,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {isCancelled ? (
            <CloseRounded sx={{ color: whiteColor, fontSize: '18px' }} />
          ) : (
            <CheckRoundedIcon
              sx={{ color: completed ? whiteColor : '#999', fontSize: '18px' }}
            />
          )}
        </Box>
      </Box>
      <Box>
        <Typography
          variant='subtitle1'
          fontSize={{ xs: 12, md: 14 }}
          color={completed ? headingColor : orderStepInactiveColor}
          textAlign='center'
        >
          {label}
        </Typography>
        {!hideDate ? (
          <Typography
            color={categoryLabelColor}
            textAlign='center'
            fontSize={{ xs: 11, md: 13 }}
            sx={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}
            lineHeight={'18px'}
          >
            {displayDate !== 'undefine' ? displayDate : ''}
          </Typography>
        ) : null}
      </Box>
    </Box>
    // <Box>
    //   <Box
    //     display={'flex'}
    //     justifyContent={'space-between'}
    //     alignItems={'center'}
    //   >
    //     <Box
    //       sx={{
    //         width: '30%',
    //         height: '3px',
    //         backgroundColor: blackColor,
    //       }}
    //     />
    //     <Box
    //       sx={{
    //         width: '35%',
    //         aspectRatio: `1/1`,
    //         backgroundColor: greenColor01,
    //         display: 'flex',
    //         alignItems: 'center',
    //         justifyContent: 'center',
    //         borderRadius: '50%',
    //       }}
    //     >
    //       5
    //     </Box>
    //     <Box
    //       sx={{
    //         width: '30%',
    //         height: '3px',
    //         backgroundColor: blackColor,
    //       }}
    //     />
    //   </Box>
    // </Box>
  )
}

export default OrderStep
