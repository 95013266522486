import { StarRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Rating,
  TextareaAutosize,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { addReview } from "../../../services/api_calls/review_api/reviewApi";
import moment from "moment";
import {
  arrowBgColor,
  backgroundColor,
  backgroundColor05,
  blackColor,
  borderColor,
  borderTextbox,
  cartBorderColor01,
  labelColor,
  orangeColor01,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import AlertDialog from "components/AlertDialog";
import { getProfileId, getUserDetails } from "utils/authHelpers";
import { allowOnlyEnglish } from "utils/utilities";
import StarRoundedIcon from '@mui/icons-material/StarRounded';

function AddReview(props) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [reviewRating, setReviewRating] = useState(0);
  const [reviewTitle, setReviewTitle] = useState("");
  const [message, setMessage] = useState("");
  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const handleTitleChange = (event) => {
    const finalValue = allowOnlyEnglish(event.target.value);
    setReviewTitle(finalValue);
  };

  const handleDescriptionChange = (event) => {
    const finalValue = allowOnlyEnglish(event.target.value);
    setMessage(finalValue);
  };

  const businessId = sessionStorage.getItem("businessId");
  const profileId = getProfileId();
  const userData = getUserDetails();

  const addReviewData = async () => {
    if (loading) return;
    console.log("REVIEW_TEST_HIT");

    if (reviewTitle.length > 200) {
      setErrorMsg("Title should not be more than 200 characters.");
      setErrorDialog(true);
      return;
    }

    if (message.length > 5000) {
      setErrorMsg("Description should not be more than 5000 characters.");
      setErrorDialog(true);
      return;
    }

    if (reviewTitle.length < 10 || message.length < 10) {
      setErrorMsg("Title and description should be of minimum 10 characters.");
      setErrorDialog(true);
      return;
    }

    if (reviewRating <= 0) {
      setErrorMsg("Your Rating should not be empty   ");
      setErrorDialog(true);
      return;
    }

    setLoading(true);

    if (reviewRating > 0 && reviewTitle.length > 10 && message.length > 10) {
      const data = {
        profileId: profileId,
        name: userData?.account?.name,
        businessId: businessId,
        productId: "",
        businessName: "",
        haveBought: true,
        eligibility: "",
        latitude: 0.0,
        longitude: 0.0,
        reviewRating,
        reviewDetails: [
          {
            reviewTitle,
            reviewRating,
            message,
            messageCreateDate: moment().format("MMM-DD-YYYY"),
          },
        ],
      };

      addReview(data)
        .then((res) => {
          props.getOwnReviewDetails();
          props.getSummary();

          setTimeout(() => {
            setLoading(false);
          }, 3000);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  return matches ? (
    <Box sx={props.styles}>
      <Box sx={{ m:'4px 0 8px 0', display: "flex", alignItems: "center" }}>
        <Typography variant="body1" sx={{...styles.label, fontSize:14}}>
          Your Rating :
        </Typography>

        <Rating
          icon={
            <StarRoundedIcon sx={{
              width: "18.5px",
              height: "18.5px",
              color: orangeColor01,
              ml:-0.2
            }} />
            // <Box
            //   component="img"
            //   // src={`https://storage.googleapis.com/bodefaults/shopweb/reviews/star.svg`}
            //   alt={`Star`}
            //   sx={{
            //     width: "12.5x",
            //     height: "12.5px",
            //     display: "flex",
            //     lineHeight: 1.5,
            //   }}
            // />
          }
          emptyIcon={
            <StarRoundedIcon sx={{
              width: "18.5px",
              height: "18.5px",
              ml:-0.2
            }} />
            // <Box
            //   component="img"
            //   src={`https://storage.googleapis.com/bodefaults/shopweb/reviews/star-1.svg`}
            //   alt={`Star`}
            //   sx={{
            //     width: "12.5x",
            //     height: "12.5px",
            //     display: "flex",
            //     lineHeight: 1.5,
            //   }}
            // />
          }
          precision={1}
          value={reviewRating}
          onChange={(event) => {
            setReviewRating(parseInt(event.target.value, 10));
          }}
          sx={{ ml: 1, gap: 1 }}
        />
      </Box>

      <Box sx={{ m: "8px 0 4px 0" }}>
        <Typography variant="body1" sx={styles.label}>
          Title{" "}
        </Typography>
        <Typography variant="body1" sx={{...styles.label,color: "#f00" }}>
          *
        </Typography>
      </Box>

      <TextField
        value={reviewTitle}
        variant="standard"
        sx={styles.inputBox(props.isUpdate ? "#fff" : whiteColor)}
        fullWidth
        InputProps={{
          disableUnderline: true,
        }}
        placeholder="(Min 10 characters, Max 200 characters)"
        textProps={{
          error: reviewTitle.length < 10 || reviewTitle.length > 200,
          helperText:
            reviewTitle.length < 10 || reviewTitle.length > 200
              ? "Min 10 characters, Max 200 characters"
              : "",
        }}
        onChange={handleTitleChange}
      />

      <Box sx={{ m: "8px 0 4px" }}>
        <Typography variant="body1" sx={styles.label}>
          Description{" "}
        </Typography>
        <Typography variant="body1" sx={{...styles.label,color: "#f00" }}>
          *
        </Typography>
      </Box>

      {/* <TextField
        value={message}
        variant='standard'
        sx={styles.inputBox(props.isUpdate ? '#fff' : '#f4f6fa')}
        fullWidth
        multiline
        InputProps={{
          disableUnderline: true,
          maxRows: 4,
          rows: 3,
        }}
        placeholder='(Min 10 chracters, Max: 5000 characters)'
        onChange={(event, newValue) => {
          handleDescriptionChange(event)
        }}
        // textProps={{

        //   onChange: (title) => {
        //     handleDescriptionChange(title)
        //   },
        // }}
      /> */}

      <TextareaAutosize
        minRows={2}
        aria-label="maximum height"
        style={styles.inputBox(props?.isUpdate ? whiteColor : whiteColor)}
        placeholder="(Min 10 chracters, Max 5000 characters)"
        value={message}
        onChange={(event, newValue) => {
          handleDescriptionChange(event);
        }}
      />

      <Box sx={styles.btnContainer}>
        <Button
          sx={[styles.submitBtn, { mr: 6 }]}
          variant="contained"
          disableElevation
          size="small"
          onClick={() => addReviewData()}
        >
          {loading ? (
            <CircularProgress sx={{ color: whiteColor, mr: 1 }} size={16} />
          ) : null}{" "}
          {props.isUpdate ? "Update" : "Submit Review"}
        </Button>
      </Box>

      <AlertDialog
        content={errorMsg}
        isOpen={errorDialog}
        onClose={() => {
          setErrorDialog(false);
        }}
      />
    </Box>
  ) : (
    <Box backgroundColor={whiteColor} borderRadius={"15px"}>
      <Box sx={{ p: "10px 12px", borderBottom: `1px solid ${borderColor}` }}>
        <Typography
          variant="body1"
          sx={{
            fontSize: "15px",
            color: blackColor,
            fontWeight: 500,
            display: "inline",
          }}
        >
          Create Review
        </Typography>
      </Box>
      <Box p={1} px={1.5}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="body1" sx={{ ...styles.label }}>
            Your Rating :
          </Typography>

          <Rating
            icon={
              <Box
                component="img"
                src={`https://storage.googleapis.com/bodefaults/shopweb/reviews/star.svg`}
                alt={`Star`}
                sx={{
                  width: "15x",
                  height: "15px",
                  display: "flex",
                  lineHeight: 1.5,
                }}
              />
            }
            emptyIcon={
              <Box
                component="img"
                src={`https://storage.googleapis.com/bodefaults/shopweb/reviews/star-1.svg`}
                alt={`Star`}
                sx={{
                  width: "15x",
                  height: "15px",
                  display: "flex",
                  lineHeight: 1.5,
                }}
              />
            }
            precision={0.1}
            value={reviewRating}
            onChange={(event) => {
              setReviewRating(parseInt(event.target.value, 10));
            }}
            sx={{ ml: 2 }}
          />
        </Box>
        <Box sx={{ my: 1 }}>
          <Typography variant="body1" sx={styles.label}>
            Review Title{" "}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "14px",
              color: "#f00",
              fontWeight: 600,
              display: "inline",
            }}
          >
            *
          </Typography>
        </Box>
        <TextField
          value={reviewTitle}
          variant="standard"
          sx={{
            ...styles.inputBox(props.isUpdate ? "#fff" : whiteColor),
            // ...styles.inputBox(props.isUpdate ? "#fff" : "#f4f6fa"),
          }}
          fullWidth
          InputProps={{
            disableUnderline: true,
          }}
          placeholder="(Min 10 characters, Max 200 characters)"
          textProps={{
            error: reviewTitle.length < 10 || reviewTitle.length > 200,
            helperText:
              reviewTitle.length < 10 || reviewTitle.length > 200
                ? "Min 10 characters, Max 200 characters"
                : "",
          }}
          onChange={handleTitleChange}
        />
        <Box
          sx={{
            my: 1,
            borderTop: {md:`1px solid ${borderColor}`,xs:"none"},
            //pt: 1,
          }}
        >
          <Typography variant="body1" sx={styles.label}>
            Description{" "}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "14px",
              color: "#f00",
              fontWeight: 600,
              display: "inline",
            }}
          >
            *
          </Typography>
        </Box>
        <TextareaAutosize
          minRows={3}
          aria-label="maximum height"
          style={styles.inputBox(
            props?.isUpdate ? whiteColor : backgroundColor
          )}
          placeholder="(Min 10 chracters, Max 5000 characters)"
          value={message}
          onChange={(event, newValue) => {
            handleDescriptionChange(event);
          }}
        />
        <Box
          sx={{
            ...styles.btnContainer,
            borderBottom: `1px solid ${borderColor}`,
            pt: {md:1,xs:0},
          }}
        >
          <Button
            sx={[styles.mobilesubmitBtn, { ml: 3 }]}
            variant="contained"
            disableElevation
            size="small"
            onClick={() => addReviewData()}
          >
            {loading ? (
              <CircularProgress sx={{ color: whiteColor, mr: 1 }} size={16} />
            ) : null}{" "}
            {props.isUpdate
              ? "Update"
              : matches
              ? "Submit Review"
              : "SUBMIT YOUR REVIEW"}
          </Button>
        </Box>
        <AlertDialog
          content={errorMsg}
          isOpen={errorDialog}
          onClose={() => {
            setErrorDialog(false);
          }}
        />
      </Box>
    </Box>
  );
}

export default AddReview;

const styles = {
  label: {
    fontSize: "13px",
    color: { md: blackColor, xs: blackColor },
    fontWeight: 500,
    display: "inline",
  },

  inputBox: (color) => ({
    border: `1px solid ${cartBorderColor01}`,
    borderRadius: "6px",
    fontSize: "13px",
    fontFamily: "Poppins",
    outline: "none",
    color: "#777",
    background: color,
    padding: "4px 10px",
    width: "90%",
  }),

  infolabel: {
    display: "block",
    fontSize: "13px",
    textAlign: "right",
    py: "8px",
    color: "#9e9fa3",
  },

  btnContainer: {
    display: "flex",
    justifyContent: { md: "flex-end", xs: "center" },
    mt: 0,
  },

  submitBtn: {
    background: "#ffffff",
    border: "1px solid #000",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "24px",
    padding: "4px 20px",
    color: "#000",
    borderRadius: "5px",
    textTransform: "uppercase",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    width: "24%",
    margin: "10px 0px 15px auto",
    "&:hover": {
      backgroundColor: blackColor,
      color: "#ffffff",
    },
  },
  mobilesubmitBtn: {
    fontSize: "14px",
    fontWeight: 500,
    color: whiteColor,
    p: "4px 20px",
    borderRadius: "6px",
    backgroundColor: blackColor,
    textTransform: "uppercase",
    "&:hover": {
      color: blackColor,
      backgroundColor: whiteColor,
    },
  },
};
