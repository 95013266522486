import { Box, Grid, Skeleton, Stack } from "@mui/material";
import ProductByCategoryShimmer from "components/ShimmerComponents/ProductByCategoryShimmer";
import React from "react";

function ProductSectionLoader() {
  return (
    <Stack gap={3} mt="15px">
      <ProductByCategoryShimmer />
      <ProductByCategoryShimmer />
      <ProductByCategoryShimmer />
    </Stack>
  );
}

export default ProductSectionLoader;
