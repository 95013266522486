import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Rating,
  Typography,
} from '@mui/material'
import {
  backgroundColor01,
  blackColor,
  borderCard01,
  borderTextbox,
  filterBgColor,
  iconInactiveColor,
  navLinkColor,
  whiteColor,
} from 'configs/styles/muiThemes'
import React, { useRef, useState } from 'react'
import { StarRounded } from '@mui/icons-material'
import Slider from 'react-slick/lib/slider'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { useSelector } from 'react-redux'
import moment from 'moment'
import {
  ChevronLeft,
  ChevronRight,
  KeyboardArrowDownOutlined,
} from "@mui/icons-material";
const REVIEWS = [
  {
    userImgUrl:
      'https://storage.googleapis.com/bodefaults/shopweb/reviews/avatar-review.png',
    review: `A collection of payment method credit card icon sets you can freely use
        on your own web and mobile eCommerce sites. A collection of payment
        method credit card icon sets you can freely use on your own web and
        mobile eCommerce sites.`,
    name: 'Leisie Alexander',
  },
  {
    userImgUrl:
      'https://storage.googleapis.com/bodefaults/shopweb/reviews/avatar-review.png',
    review: `A collection of payment method credit card icon sets you can freely use
        on your own web and mobile eCommerce sites. A collection of payment
        method credit card icon sets you can freely use on your own web and
        mobile eCommerce sites.`,
    name: 'Leisie Alexander',
  },
  {
    userImgUrl:
      'https://storage.googleapis.com/bodefaults/shopweb/reviews/avatar-review.png',
    review: `A collection of payment method credit card icon sets you can freely use
        on your own web and mobile eCommerce sites. A collection of payment
        method credit card icon sets you can freely use on your own web and
        mobile eCommerce sites.`,
    name: 'Leisie Alexander',
  },
  {
    userImgUrl:
      'https://storage.googleapis.com/bodefaults/shopweb/reviews/avatar-review.png',
    review: `A collection of payment method credit card icon sets you can freely use
        on your own web and mobile eCommerce sites. A collection of payment
        method credit card icon sets you can freely use on your own web and
        mobile eCommerce sites.`,
    name: 'Leisie Alexander',
  },
]

const Testimonials = () => {
  const slider = useRef(null)
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  const { allReviews, reviewSummary } = useSelector(
    (state) => state.reviewCustomer
  )

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1060,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  }

  const onSlideNext = () => {
    if (currentSlideIndex < allReviews?.allReview?.length - 3) {
      slider.current.slickNext()
    }
  }

  const onSlidePrev = () => {
    if (currentSlideIndex > 0) {
      slider.current.slickPrev()
    }
  }

  return (
    allReviews?.allReview?.length > 0 && (
      <Box sx={styles.card}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography
            fontSize={16}
            fontWeight={600}
            lineHeight="20px"
            color={blackColor}
            textAlign="center"
            mb="20px !important"
          >
            What Our Customer’s Say
          </Typography>
          {allReviews?.allReview?.length > 3 && (
            <Grid item display={{ xs: "none", md: "block" }}>
              <Grid container spacing={1.5}>
                <Grid item>
                  <Box
                    width="32px"
                    height="32px"
                    // backgroundColor={arrowBgColor}
                    backgroundColor={
                      currentSlideIndex > 0 ? filterBgColor : whiteColor
                    }
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius={2}
                    sx={{ cursor: "pointer" }}
                    // onClick={() => onPrev()}
                    onClick={onSlidePrev}
                    border={borderTextbox}
                  >
                    <ChevronLeft
                      sx={{
                        fontSize: "22px",
                        color:
                          currentSlideIndex > 0
                            ? navLinkColor
                            : iconInactiveColor,
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item>
                  <Box
                    width="32px"
                    height="32px"
                    // backgroundColor={arrowBgColor}
                    backgroundColor={
                      currentSlideIndex <
                      currentSlideIndex <
                      allReviews?.allReview?.length - 3
                        ? filterBgColor
                        : whiteColor
                    }
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius={2}
                    sx={{ cursor: "pointer" }}
                    // onClick={() => onNext()}
                    onClick={onSlideNext}
                    border={borderTextbox}
                  >
                    <ChevronRight
                      sx={{
                        fontSize: "22px",
                        color:
                          currentSlideIndex < allReviews?.allReview?.length - 3
                            ? navLinkColor
                            : iconInactiveColor,
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>

        <Box>
          {allReviews?.allReview?.length > 3 ? (
            <Box
              sx={{
                borderRadius: "20px",
              }}
              position="relative"
            >
              <Box overflow="hidden">
                <Slider
                  ref={slider}
                  {...settings}
                  beforeChange={(oldIndex, newIndex) => {
                    setCurrentSlideIndex(newIndex);
                  }}
                >
                  {allReviews?.allReview?.map((item, index) => (
                    <TestimonialCard
                      key={`review_${index}`}
                      userImgUrl={item?.profilePic}
                      title={item?.reviewTitle}
                      review={item?.message}
                      reviewRating={item?.reviewRating}
                      name={item.name}
                      date={item?.updateDate || null}
                    />
                  ))}
                </Slider>
              </Box>
{/* 
              {allReviews?.allReview?.length > 3 && (
                <>
                  {currentSlideIndex > 0 && (
                    <IconButton
                      sx={{ ...styles.arrowIconBtn, left: "-5px" }}
                      onClick={onSlidePrev}
                    >
                      <KeyboardArrowLeftIcon color="inherit" />
                    </IconButton>
                  )}

                  {currentSlideIndex < allReviews?.allReview?.length - 3 && (
                    <IconButton
                      sx={{ ...styles.arrowIconBtn, right: "-5px" }}
                      onClick={onSlideNext}
                    >
                      <KeyboardArrowRightIcon color="inherit" />
                    </IconButton>
                  )}
                </>
              )} */}
            </Box>
          ) : (
            <Grid container>
              {allReviews?.allReview?.slice(0, 3)?.map((item, index) => (
                <Grid key={`review_${index}`} item md={4}>
                  <TestimonialCard
                    userImgUrl={item?.profilePic}
                    title={item?.reviewTitle}
                    review={item?.message}
                    reviewRating={item?.reviewRating}
                    name={item.name}
                    date={item?.updateDate || null}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Box>
    )
  );
}

export default Testimonials

const TestimonialCard = ({ title, review, reviewRating, userImgUrl, name, date }) => {
  return (
    <Box mx={1.2}>
      <Box sx={{ bgcolor: backgroundColor01, borderRadius: "15px", p: "15px 20px" }}>
        <Rating
          readOnly
          icon={
            <Box
              component="img"
              src="https://storage.googleapis.com/bodefaults/shopweb/reviews/star.svg"
              sx={{
                width: "16x",
                height: "16px",
                mr: "3px",
              }}
            />
          }
          emptyIcon={null}
          precision={0.1}
          value={reviewRating}
          sx={{ mb: "10px !important" }}
        />

        <Typography
          fontSize={13}
          fontWeight={500}
          lineHeight="25px"
          color={blackColor}
          sx={{ height: "25px", overflow: "hidden", mb: "4px !important" }}
        >
          {title}
        </Typography>

        <Typography
          fontSize={12}
          fontWeight={400}
          lineHeight="20px"
          color={blackColor}
          sx={{ height: "60px", overflow: "hidden" }}
        >
          {review}
        </Typography>

        <Box display="flex" alignItems="center" gap={1.5}>
          <Avatar src={userImgUrl} />

          <Box>
            <Typography
              variant="h6"
              fontSize={14}
              fontWeight={600}
              lineHeight="18px"
            >
              {name}
            </Typography>

            <Typography
              variant="body1"
              fontSize={12}
              fontWeight={400}
              lineHeight="18px"
            >
              {date ? moment(date)?.format("Do MMM YYYY") : "-"}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const styles = {
  card: {
    borderRadius: '15px',
    bgcolor: whiteColor,
    p: '18px 15px',
    mt: '30px',
  },

  arrowIconBtn: {
    width: '38px',
    height: '38px',
    color: blackColor,
    bgcolor: whiteColor,
    p: 0.7,
    border: borderCard01,
    borderRadius: '7px',
    position: 'absolute',
    top: '40%',
    boxShadow: '0 1px 5px rgb(202 202 214 / 55%)',
    '&:hover': {
      color: blackColor,
      bgcolor: whiteColor,
    },
  },
}
