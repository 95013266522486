import {
  Box,
  Grid,
  ListItem,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  blackColor,
  borderCard02,
  greyShade59,
  greyShade60,
  headingColor,
  orangeColor01,
  topbarColor,
  whiteColor,
} from "configs/styles/muiThemes";
import { useState } from "react";
import { getTimeToShipUnitValue, getTimeToShipValue } from "utils/utilities";
import AdditionalInfoCard from "./AdditionalInfoCard";
import GeneralInformationCard from "./GeneralInformationCard";
import AlertModal from "components/AlertModal";
import DescriptionModal from "./DescriptionModal";

export default function DescriptionSpecificationTabs({
  value,
  handleChange,
  descriptionData,
  specificationData,
  refLink,
  ondcData,
  uom,
  dynamicSpecs,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [tabValue, setTabValue] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const stepList = [
    { id: 0, label: "Specification", mobileLabel: "Specification" },
    { id: 1, label: "Description", mobileLabel: "Description" },
  ];

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return matches ? (
    <Box width={"100%"}>
      <Box
        sx={{
          borderBottom: `1px solid #e8e8f0`,
          // borderColor: "divider",
          mb: { xs: "10px", md: "12px" },
        }}
      >
        <Tabs
          value={tabValue}
          onChange={(event, newValue) => handleTabChange(event, newValue)}
          aria-label="basic tabs example"
          textColor={blackColor}
          TabIndicatorProps={{
            style: {
              height: "3px",
              backgroundColor: blackColor,
            },
          }}
          sx={{
            minHeight: "35px !important",
            "& .MuiButtonBase-root": {
              minWidth: "auto !important",
            },

            "& .MuiTabs-flexContainer": {
              // justifyContent: "space-between",
            },
          }}
        >
          {stepList?.map((item, index) => {
            return (
              <Tab
                key={"filter_" + index}
                label={item.label?.toLowerCase()}
                // label={item.label}
                sx={{
                  fontSize: { xs: "11px", md: "14px" },
                  fontWeight: tabValue == index ? 500 : 500,
                  p: 0,
                  minHeight: "30px !important",
                  mr: { xs: "10px", md: "55px" },
                  color: item.id === tabValue ? blackColor : greyShade59,
                  textTransform: "capitalize",
                }}
              />
            );
          })}
        </Tabs>
      </Box>
      {tabValue === 1 && (
        <Box>
          <Typography variant="h5" fontSize={14} fontWeight={600}>
            Description
          </Typography>

          {descriptionData?.split("\n")?.map((item, i) => {
            return item.length > 0 ? (
              item?.charAt(0) === "*" ? (
                <ListItem sx={{ py: 0, ml: 0 }}>
                  <ListItemText
                    sx={{
                      display: "list-item",
                    }}
                    key={i}
                    primary={
                      <Typography
                        variant="body1"
                        fontSize={{ xs: 12.5, md: 13 }}
                        color={blackColor}
                        pt={{ xs: 1, md: "12px" }}
                        lineHeight="21px"
                        sx={{ textAlign: "justify" }}
                      >
                        {item?.substring(1)}
                      </Typography>
                    }
                  />
                </ListItem>
              ) : (
                <Typography
                  variant="body1"
                  fontSize={{ xs: 12.5, md: 13 }}
                  color={blackColor}
                  lineHeight="21px"
                  pt={{ xs: 1, md: "12px" }}
                  sx={{ textAlign: "justify" }}
                >
                  {item}
                </Typography>
              )
            ) : null;
          })}
        </Box>
      )}

      {tabValue === 0 && (
        <Box>
          <Typography
            variant="h5"
            fontSize={14}
            fontWeight={600}
            mb="8px !important"
          >
            Specification
          </Typography>
          <Box
            sx={
              {
                // bgcolor: whiteColor,
                // border: `1px solid ${greyShade60}`,
                // borderRadius: '10px',
                // p: '8px 10px',
              }
            }
          >
            <Box>
              <Grid container display="flex" alignItems="center">
                <Grid item xs={6} md={2}>
                  <Typography variant="body1" sx={styles.specText}>
                    Country of Origin
                  </Typography>
                </Grid>
                <Grid item xs>
                  <ListItem sx={{ py: 0 }}>
                    <ListItemText
                      sx={{
                        display: "list-item",
                        ...styles.specText,
                      }}
                      // key={index}
                      primary={
                        <Typography variant="body1" sx={styles.specText}>
                          India
                        </Typography>
                      }
                    />
                  </ListItem>
                  {/* <Typography
                    variant="body1"
                    sx={{ ...styles.specText, ml: 1.8 }}
                  >
                    India
                  </Typography> */}
                </Grid>
              </Grid>
            </Box>

            <Box>
              {specificationData?.map((spec, i) => {
                return (
                  <Grid container>
                    <Grid item xs={6} md={2}>
                      <Typography variant="body1" sx={styles.specText}>
                        {spec?.code}
                      </Typography>
                    </Grid>

                    <Grid item xs>
                      {spec?.value?.map((item, index) => {
                        return item.length > 0 ? (
                          <ListItem sx={{ py: 0 }}>
                            <ListItemText
                              sx={{
                                display: "list-item",
                                ...styles.specText,
                              }}
                              key={index}
                              primary={
                                <Typography
                                  variant="body1"
                                  sx={styles.specText}
                                >
                                  {item}
                                </Typography>
                              }
                            />
                          </ListItem>
                        ) : null;
                      })}
                    </Grid>
                  </Grid>
                );
              })}
              {refLink?.map((ref, i) => {
                return (
                  <Grid container>
                    <Grid item xs={4} md={2}>
                      <Typography variant="body1" sx={styles.specText}>
                        {ref?.code}
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        variant="body1"
                        sx={{
                          ...styles.specText,
                          cursor: "pointer",
                          "&:hover": { textDecoration: "underline" },
                        }}
                        onClick={() => {
                          window.open(ref?.link, "_blank");
                        }}
                      >
                        {ref?.link}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
              {ondcData && (
                <Grid container columnSpacing={4} alignItems="stretch" mt={1}>
                  <Grid item md={6} xs={12}>
                    <GeneralInformationCard
                      title="General Information"
                      data={[
                        {
                          label: "Product Code",
                          value: `${ondcData?.productCode?.key} - ${ondcData?.productCode?.value}`,
                        },
                        {
                          label: "Unit of Measure",
                          value: `${uom?.value} ${uom?.unit}`,
                        },
                        {
                          label: "Time to Ship",
                          value: `${getTimeToShipValue(
                            ondcData?.timeToShip?.charAt(
                              ondcData?.timeToShip?.length - 1
                            ),
                            ondcData?.timeToShip
                          )} ${
                            getTimeToShipUnitValue(
                              ondcData?.timeToShip?.charAt(
                                ondcData?.timeToShip?.length - 1
                              )
                            )?.label
                          }`,
                        },
                        {
                          label: "Cash on Delivery",
                          value: ondcData?.available_on_cod ? "Yes" : "No",
                        },
                        {
                          label: "Fragile",
                          value: ondcData?.fragile ? "Yes" : "No",
                        },
                        {
                          label: "Returnable",
                          value: ondcData?.returnTerms?.returnable
                            ? "Yes"
                            : "No",
                        },
                        {
                          label: "Return Period",
                          value: `${
                            ondcData?.returnTerms?.returnPeriod
                              ? ondcData?.returnTerms?.returnPeriod?.slice(
                                  1,
                                  -1
                                )
                              : ""
                          } Days`,
                        },
                        {
                          label: "Seller Pickup",
                          value: ondcData?.returnTerms?.seller_pickup_return,
                        },
                        {
                          label: "Cancellable",
                          value: ondcData?.cancelTerms?.cancellable
                            ? "Yes"
                            : "No",
                        },
                        {
                          label: "Cancellation Fee %",
                          value:
                            ondcData?.cancelTerms?.cancellationFeePercentage,
                        },
                        {
                          label: "Refund Eligible",
                          value: ondcData?.cancelTerms?.refundEligible
                            ? "Yes"
                            : "No",
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <AdditionalInfoCard
                      title="Additional Information"
                      dynamicSpecs={dynamicSpecs}
                    />
                  </Grid>
                </Grid>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  ) : (
    <Box p={{ md: 0, xs: 2 }} borderTop={borderCard02}>
      <DescriptionModal
        open={showMore}
        data={descriptionData}
        handleConfirm={() => {
          setShowMore(false);
        }}
        handleClose={() => {
          setShowMore(false);
        }}
      />
      <Box>
        <Typography
          variant="h5"
          color={blackColor}
          fontSize={14}
          fontWeight={600}
        >
          Description
        </Typography>
        {descriptionData
          ?.slice(0, 190)
          ?.split("\n")
          ?.map((item, i) => {
            return item.length > 0 ? (
              item?.charAt(0) === "*" ? (
                <ListItem sx={{ py: 0, ml: 3 }}>
                  <ListItemText
                    sx={{
                      display: "list-item",
                    }}
                    key={i}
                    primary={
                      <Typography
                        variant="body1"
                        fontSize={{ xs: 12.5, md: 13 }}
                        color={blackColor}
                        pt={{ xs: 1, md: 2 }}
                        lineHeight="21px"
                        sx={{ textAlign: "justify" }}
                      >
                        {item?.substring(1)}

                        {descriptionData?.slice(0, 190)?.split("\n")?.length ===
                          i + 1 && descriptionData?.length > 190 ? (
                          <Typography
                            component="span"
                            onClick={() => setShowMore(true)}
                            style={{
                              fontSize: "12px",
                              fontWeight: 500,
                              textDecoration: "underline",
                              color: orangeColor01,
                              pl: 1,
                            }}
                          >
                            {"  "}Show More
                          </Typography>
                        ) : null}
                      </Typography>
                    }
                  />
                </ListItem>
              ) : (
                <Typography
                  variant="body1"
                  fontSize={{ xs: 12.5, md: 13 }}
                  color={blackColor}
                  lineHeight="21px"
                  pt={{ xs: 1, md: 2 }}
                  sx={{ textAlign: "justify" }}
                >
                  {item}
                  {descriptionData?.slice(0, 190)?.split("\n")?.length ===
                    i + 1 && descriptionData?.length > 190 ? (
                    <Typography
                      component="span"
                      onClick={() => setShowMore(true)}
                      style={{
                        fontSize: "12px",
                        fontWeight: 500,
                        textDecoration: "underline",
                        color: orangeColor01,
                        pl: 1,
                      }}
                    >
                      {"  "}Show More
                    </Typography>
                  ) : null}
                </Typography>
              )
            ) : null;
          })}
      </Box>

      <Box>
        <Typography
          variant="h5"
          fontSize={15}
          fontWeight={600}
          pt={1.87}
          mb="8px !important"
        >
          Specification
        </Typography>
        <Box
          sx={{
            bgcolor: whiteColor,
            border: `1px solid ${greyShade60}`,
            borderRadius: "10px",
            p: "8px 10px",
          }}
        >
          <Box>
            <Grid container>
              <Grid item xs={6} md={6}>
                <Typography variant="body1" sx={styles.specText}>
                  Country of Origin
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography
                  variant="body1"
                  sx={{ ...styles.specText, ml: 1.8 }}
                >
                  India
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box>
            {specificationData?.map((spec, i) => {
              return (
                <Grid container mt={i == 0 ? 1 : 0}>
                  <Grid item xs={6} md={6}>
                    <Typography variant="body1" sx={styles.specText}>
                      {spec?.code}
                    </Typography>
                  </Grid>

                  <Grid item xs>
                    {spec?.value?.map((item, index) => {
                      return item.length > 0 ? (
                        <ListItem sx={{ py: 0 }}>
                          <ListItemText
                            sx={{
                              display: "list-item",
                              ...styles.specText,
                            }}
                            key={index}
                            primary={
                              <Typography variant="body1" sx={styles.specText}>
                                {item}
                              </Typography>
                            }
                          />
                        </ListItem>
                      ) : null;
                    })}
                  </Grid>
                </Grid>
              );
            })}
            {refLink?.map((ref, i) => {
              return (
                <Grid container>
                  <Grid item xs={6} md={6}>
                    <Typography variant="body1" sx={styles.specText}>
                      {ref?.code}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="body1"
                      sx={{
                        ...styles.specText,
                        cursor: "pointer",
                        "&:hover": { textDecoration: "underline" },
                      }}
                      onClick={() => {
                        window.open(ref?.link, "_blank");
                      }}
                    >
                      {ref?.link}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        </Box>

        {ondcData && (
          <Grid
            container
            columnSpacing={4}
            rowSpacing={1.5}
            alignItems="stretch"
            mt={1}
          >
            <Grid item md={6} xs={12}>
              <GeneralInformationCard
                title="General Information"
                data={[
                  {
                    label: "Product Code",
                    value: `${ondcData?.productCode?.key} - ${ondcData?.productCode?.value}`,
                  },
                  {
                    label: "Unit of Measure",
                    value: `${uom?.value} ${uom?.unit}`,
                  },
                  {
                    label: "Time to Ship",
                    value: `${getTimeToShipValue(
                      ondcData?.timeToShip?.charAt(
                        ondcData?.timeToShip?.length - 1
                      ),
                      ondcData?.timeToShip
                    )} ${
                      getTimeToShipUnitValue(
                        ondcData?.timeToShip?.charAt(
                          ondcData?.timeToShip?.length - 1
                        )
                      )?.label
                    }`,
                  },
                  {
                    label: "Cash on Delivery",
                    value: ondcData?.available_on_cod ? "Yes" : "No",
                  },
                  {
                    label: "Fragile",
                    value: ondcData?.fragile ? "Yes" : "No",
                  },
                  {
                    label: "Returnable",
                    value: ondcData?.returnTerms?.returnable ? "Yes" : "No",
                  },
                  {
                    label: "Return Period",
                    value: `${
                      ondcData?.returnTerms?.returnPeriod
                        ? ondcData?.returnTerms?.returnPeriod?.slice(1, -1)
                        : ""
                    } Days`,
                  },
                  {
                    label: "Seller Pickup",
                    value: ondcData?.returnTerms?.seller_pickup_return,
                  },
                  {
                    label: "Cancellable",
                    value: ondcData?.cancelTerms?.cancellable ? "Yes" : "No",
                  },
                  {
                    label: "Cancellation Fee %",
                    value: ondcData?.cancelTerms?.cancellationFeePercentage,
                  },
                  {
                    label: "Refund Eligible",
                    value: ondcData?.cancelTerms?.refundEligible ? "Yes" : "No",
                  },
                ]}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <AdditionalInfoCard
                title="Additional Information"
                dynamicSpecs={dynamicSpecs}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}

const styles = {
  specText: {
    fontSize: 13,
    fontWeight: 500,
    color: blackColor,
    lineHeight: "21px",
  },
};

const SpecItem = ({ label, value }) => {
  return (
    <Grid container>
      <Grid item md={6}>
        <Typography variant="body1" sx={styles.specText}>
          {label}
        </Typography>
      </Grid>
      <Grid item md={6}>
        <Typography
          variant="body1"
          sx={{ ...styles.specText, textTransform: "capitalize" }}
        >
          {value || "-"}
        </Typography>
      </Grid>
    </Grid>
  );
};
