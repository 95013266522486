import { Box, Container, Skeleton, useMediaQuery } from "@mui/material";
import { greenColor02, whiteColor } from "configs/styles/muiThemes";
import React from "react";

const HeaderLoader = ({ hideBanners }) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return matches ? (
    <Box
      height="126.5px"
      sx={{ bgcolor: whiteColor, position: "sticky", top: 0, zIndex: 20 }}
    >
      <Skeleton variant="rectangular" height="40px" />
      <Container sx={{ maxWidth: "1350px !important", px: "15px !important" }}>
        <Box
          height="86.5px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box width="42.5%" display="flex" alignItems="center" gap="20px">
            <Skeleton variant="text" width="49px" sx={{ fontSize: "2rem" }} />
            <Skeleton variant="text" width="60px" sx={{ fontSize: "2rem" }} />
            <Skeleton variant="text" width="118px" sx={{ fontSize: "2rem" }} />
            <Skeleton variant="text" width="75px" sx={{ fontSize: "2rem" }} />
            <Skeleton variant="text" width="60px" sx={{ fontSize: "2rem" }} />
          </Box>

          <Box
            width="15%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Skeleton variant="rounded" width="65px" height="65px" />
          </Box>

          <Box
            width="42.5%"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gap="12px"
          >
            <Skeleton
              variant="rounded"
              width="35px"
              height="35px"
              sx={{ borderRadius: "50%" }}
            />
            <Skeleton
              variant="rounded"
              width="35px"
              height="35px"
              sx={{ borderRadius: "50%" }}
            />
            <Skeleton
              variant="rounded"
              width="35px"
              height="35px"
              sx={{ borderRadius: "50%" }}
            />
            <Skeleton variant="text" width="75px" sx={{ fontSize: "2rem" }} />
          </Box>
        </Box>
      </Container>
    </Box>
  ) : (
    <Box sx={{ bgcolor: whiteColor }}>
      <Skeleton variant="rectangular" height="27px" />

      <Box
        height="65px"
        px="15px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Skeleton
          variant="rounded"
          width="26px"
          height="26px"
          sx={{ borderRadius: "5px" }}
        />
        <Skeleton
          variant="rounded"
          width="42px"
          height="42px"
          sx={{ borderRadius: "5px" }}
        />
        <Skeleton
          variant="rounded"
          width="36px"
          height="31px"
          sx={{ borderRadius: "15px" }}
        />
      </Box>

      {!hideBanners && (
        <>
          <Skeleton
            variant="rounded"
            width="auto"
            height="38px"
            sx={{ borderRadius: "5px", mx: "15px !important" }}
          />

          <Skeleton
            variant="rounded"
            width="auto"
            height="254px"
            sx={{ borderRadius: "10px", m: "25px 15px 0 15px !important" }}
          />
        </>
      )}
    </Box>
  );
};

export default HeaderLoader;
