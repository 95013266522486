import { Box, Container, Grid, Skeleton } from "@mui/material";
import { whiteColor } from "configs/styles/muiThemes";
import React from "react";

const OverviewLoader = () => {
  return (
    <Container sx={{ maxWidth: "1370px !important" }}>
      <Box>
        <Grid container spacing={3.5}>
          <Grid item md={8}>
            <Box>
               <Skeleton variant="rounded" width="90px" height='16px' sx={{ m:'8px 0 24px !important' }} />
               
               <Skeleton variant="rounded" width="auto" height='13px' sx={{ mb:'6px !important' }} />
               <Skeleton variant="rounded" width="auto" height='13px' sx={{ mb:'6px !important' }} />
               <Skeleton variant="rounded" width="auto" height='13px' sx={{ mb:'6px !important' }} />
               <Skeleton variant="rounded" width="300px" height='13px' sx={{ mb:'6px !important' }} />
               
               <Skeleton variant="rounded" width="auto" height='13px' sx={{ m:'25px 0 6px !important' }} />
               <Skeleton variant="rounded" width="auto" height='13px' sx={{ mb:'6px !important' }} />
               <Skeleton variant="rounded" width="auto" height='13px' sx={{ mb:'6px !important' }} />
               <Skeleton variant="rounded" width="600px" height='13px' sx={{ mb:'6px !important' }} />
               
               <Skeleton variant="rounded" width="auto" height='13px' sx={{ m:'25px 0 6px !important' }} />
               <Skeleton variant="rounded" width="auto" height='13px' sx={{ mb:'6px !important' }} />
               <Skeleton variant="rounded" width="auto" height='13px' sx={{ mb:'6px !important' }} />
               <Skeleton variant="rounded" width="600px" height='13px' sx={{ mb:'6px !important' }} />
               
               <Skeleton variant="rounded" width="auto" height='13px' sx={{ m:'25px 0 6px !important' }} />
               <Skeleton variant="rounded" width="auto" height='13px' sx={{ mb:'6px !important' }} />
               <Skeleton variant="rounded" width="auto" height='13px' sx={{ mb:'6px !important' }} />
               <Skeleton variant="rounded" width="600px" height='13px' sx={{ mb:'6px !important' }} />
               
               <Skeleton variant="rounded" width="auto" height='13px' sx={{ m:'25px 0 6px !important' }} />
               <Skeleton variant="rounded" width="auto" height='13px' sx={{ mb:'6px !important' }} />
               <Skeleton variant="rounded" width="auto" height='13px' sx={{ mb:'6px !important' }} />
               <Skeleton variant="rounded" width="600px" height='13px' sx={{ mb:'6px !important' }} />
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box>
                <Skeleton variant="rounded" width="120px" height='16px' sx={{ m:'15px 0 20px !important' }} />

                <Box sx={{...styles.infoCard, flexDirection:'column', gap:'15px', p:'15px 20px'}}>
                    <Skeleton variant="rounded" width="170px" height="13px" sx={{ mb:'0px !important' }} />
                    <Skeleton variant="rounded" width="130px" height="13px" sx={{ mb:'0px !important' }} />
                    <Skeleton variant="rounded" width="160px" height="13px" sx={{ mb:'0px !important' }} />
                    <Skeleton variant="rounded" width="180px" height="13px" sx={{ mb:'0px !important' }} />
                </Box>

                <Box sx={styles.infoCard}>
                    <Skeleton variant="rounded" width="40px" height="40px" sx={{ borderRadius:'50px'}} />

                    <Box>
                       <Skeleton variant="rounded" width="160px" height="13px" sx={{ mb:'10px !important' }} />
                       <Skeleton variant="rounded" width="300px" height="13px" />
                    </Box>
                </Box>

                <Box sx={styles.infoCard}>
                    <Skeleton variant="rounded" width="40px" height="40px" sx={{ borderRadius:'50px'}} />

                    <Box>
                       <Skeleton variant="rounded" width="160px" height="13px" sx={{ mb:'10px !important' }} />
                       <Skeleton variant="rounded" width="320px" height="13px" sx={{ mb:'8px !important' }} />
                       <Skeleton variant="rounded" width="220px" height="13px" />
                    </Box>
                </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default OverviewLoader;

const styles = {
    infoCard: {display:'flex', alignItems:'flex-start', gap:'18px', bgcolor: whiteColor, borderRadius:'10px', p:'12px 10px', mt:2.5}
}