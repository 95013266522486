import { Box, Radio, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import {
  backgroundColor,
  backgroundColor01,
  blackColor,
  borderLineColor,
  greenColor05,
  greyShade67,
  headingColor,
  minOrderTextColor,
  offerTextColor,
  savingTextColor,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import { blue } from "@mui/material/colors";

function DeliveryMethodCard({
  icon,
  title,
  subtitle,
  subtitle1,
  oldPrice,
  price,
  isSelect,
  onSelectMethod,
  additionalDiscount,
  isFreeShipping,
  error,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  let businessFullName = sessionStorage.getItem("businessFullName");

  return matches ? (
    <Box
      backgroundColor={{ xs: whiteColor, md: backgroundColor01 }}
      border={"1px solid " + borderLineColor}
      borderRadius="10px"
      display="flex"
      flexDirection={"column"}
      alignItems="center"
      justifyContent={"center"}
      textAlign="center"
      p="10px 12px 4px"
      position="relative"
      sx={{
        cursor: (price || price === 0) && price !== "" ? "cursor" : "",
        opacity: error ? 0.5 : 1,
      }}
      width={{ xs: "123px", md: "84%" }}
      // height={"140px"}
      onClick={() => {
        if ((price || price === 0) && price !== "" && !error) {
          onSelectMethod(price?.toFixed(2), subtitle);
        }
      }}
    >
      <Box
        sx={{
          width: "40px",
          height: "40px",
          backgroundColor: whiteColor,
          borderRadius: "50%",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mb: "5px",
        }}
      >
        <Box
          component="img"
          src={icon}
          alt={businessFullName + " CostBo"}
          width="30px"
          height="25px"
        />
      </Box>
      <Stack height="80px">
        <Typography
          variant="subtitle2"
          component="h5"
          fontSize="12.5px"
          color={headingColor}
        >
          {title}{" "}
        </Typography>

        <Typography fontSize="11px" lineHeight="18px" color={blackColor}>
          {subtitle1}
        </Typography>

        <Typography
          variant="subtitle1"
          component="p"
          fontSize="12px"
          lineHeight="22px"
          width="100%"
          fontWeight={400}
          color={blackColor}
        >
          {subtitle}
        </Typography>

        {error ? (
          title == "Rush" || title == "Express" ? (
            <Typography
              variant="subtitle1"
              component="p"
              fontSize="13px"
              lineHeight={"20px"}
              color={savingTextColor}
            >
              {oldPrice ? (
                <Typography
                  variant="subtitle1"
                  component="p"
                  fontSize="12px"
                  color={offerTextColor}
                  lineHeight={"20px"}
                  sx={{ textDecoration: "line-through" }}
                  display="inline"
                  mr={0.8}
                >
                  ₹{oldPrice?.toFixed(2)}
                </Typography>
              ) : null}
              ₹{price?.toFixed(2)}
            </Typography>
          ) : (
            <Typography
              variant="subtitle1"
              component="p"
              fontSize="13px"
              lineHeight="26px"
              color={minOrderTextColor}
            >
              Not Available
            </Typography>
          )
        ) : (price || price === 0) && price !== "" ? (
          <Typography
            variant="subtitle1"
            component="p"
            fontSize="12px"
            fontWeight={600}
            lineHeight={"20px"}
            color={greenColor05}
          >
            {oldPrice ? (
              <Typography
                variant="subtitle1"
                component="p"
                fontSize="12px"
                sx={{ textDecoration: "line-through" }}
                display="inline"
                color={greyShade67}
                fontWeight={500}
                lineHeight={"20px"}
                mr={0.8}
              >
                ₹{oldPrice?.toFixed(2)}
              </Typography>
            ) : null}
            ₹{price?.toFixed(2)}
          </Typography>
        ) : (
          <Typography
            variant="subtitle1"
            component="p"
            fontSize="14px"
            lineHeight={"20px"}
            color={savingTextColor}
          >
            Loading...
          </Typography>
        )}
      </Stack>
      <Box sx={styles.radioBtn}>
        <Radio
          checked={isSelect}
          // onChange={() => {
          //   onSelectMethod(price, subtitle)
          // }}

          value="a"
          size="small"
          name="radio-buttons"
          inputProps={{ "aria-label": "A" }}
          disabled={!((price || price === 0) && price !== "")}
          sx={{
            color: blackColor, // default color
            "&.Mui-checked": {
              color: blackColor, // color when checked
            },
          }}
        />
      </Box>
    </Box>
  ) : (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          background: "#f3f3f8",
          padding: "6px 0px 10px 0px",
          borderRadius: "10px",
          border: "1px solid #EAEAF1",
          position: "relative",
          mb: 1,
          cursor: (price || price === 0) && price !== "" ? "cursor" : "",
          opacity: error ? 0.5 : 1,
        }}
        onClick={() => {
          if ((price || price === 0) && price !== "" && !error) {
            onSelectMethod(price?.toFixed(2), subtitle);
          }
        }}
      >
        <Box display={"flex"} flexDirection={"row"} ml={1}>
          <Box
            sx={{
              width: "35px",
              height: "35px",
              display: "flex",
              marginRight: "10px",
              alignItems: "center",
              // position: 'relative',
              top: "0px",
              backgroundColor: whiteColor,
              borderRadius: "50%",
            }}
          >
            <Box
              component="img"
              src={icon}
              alt={businessFullName + " CostBo"}
              width="30px"
              height="30px"
            />

            {/* <Typography
            variant='subtitle1'
            component='p'
            fontSize='12px'
            lineHeight='26px'
            width='100%'
            color={minOrderTextColor}
          >
            {subtitle}
          </Typography> */}
          </Box>
          <Box display={"flex"} flexDirection={"column"}>
            <Box display={"flex"} flexDirection={"row"}>
              <Typography
                variant="subtitle2"
                component="h5"
                fontSize="12px"
                color={blackColor}
                mt={0.5}
              >
                {title}
              </Typography>

              <Typography
                variant="subtitle1"
                component="p"
                fontSize="11px"
                lineHeight="18px"
                color={{ md: offerTextColor, xs: blackColor }}
                mt={0.5}
                ml={0.5}
              >
                {subtitle1}
              </Typography>
            </Box>
            <Typography
              variant="subtitle1"
              component="p"
              fontSize="12px"
              lineHeight="26px"
              width="100%"
              color={minOrderTextColor}
              mt={-0.5}
            >
              {subtitle}
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.radioBtnRight} mr={1}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-end"}
          >
            <Radio
              checked={isSelect}
              // onChange={() => {
              // }}s
              // sx={{ml:3}}
              svalue="a"
              size="small"
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
              disabled={!((price || price === 0) && price !== "")}
              sx={{
                color: blackColor, // default color
                "&.Mui-checked": {
                  color: blackColor, // color when checked
                },
              }}
            />
            {error ? (
              title == "Rush" || title == "Express" ? (
                <Typography
                  variant="subtitle1"
                  component="p"
                  fontSize="13px"
                  color={{ md: savingTextColor, xs: "#06b581" }}
                >
                  {oldPrice ? (
                    <Typography
                      variant="subtitle1"
                      component="p"
                      fontSize="12px"
                      color={offerTextColor}
                      sx={{ textDecoration: "line-through" }}
                      display="inline"
                      mr={0.8}
                    >
                      ₹{oldPrice?.toFixed(2)}
                    </Typography>
                  ) : null}
                  ₹{price?.toFixed(2)}
                </Typography>
              ) : (
                <Typography
                  variant="subtitle1"
                  component="p"
                  fontSize="13px"
                  lineHeight="26px"
                  color={minOrderTextColor}
                >
                  Not Available
                </Typography>
              )
            ) : (price || price === 0) && price !== "" ? (
              <Typography
                variant="subtitle1"
                component="p"
                fontSize="13px"
                color={{ md: savingTextColor, xs: "#06b581" }}
              >
                {oldPrice ? (
                  <Typography
                    variant="subtitle1"
                    component="p"
                    fontSize="12px"
                    color={offerTextColor}
                    sx={{ textDecoration: "line-through" }}
                    display="inline"
                    mr={0.8}
                  >
                    ₹{oldPrice?.toFixed(2)}
                  </Typography>
                ) : null}
                ₹{price?.toFixed(2)}
              </Typography>
            ) : (
              <Typography
                variant="subtitle1"
                component="p"
                fontSize="14px"
                color={{ md: savingTextColor, xs: "#06b581" }}
              >
                Loading...
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default DeliveryMethodCard;

const styles = {
  radioBtn: {
    // backgroundColor: whiteColor ,
    position: "absolute",
    top: "0px",
    left: "0px",
  },
  radioBtnRight: {
    position: "absolute",
    top: "0px",
    right: "0px",
  },
};
