import { Box, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { getCartDetails } from 'redux/addToCart/AddToCartSlice'
import { getCommercialDetails } from 'redux/productDescription/ProductDescriptionSlice'
import { getBusinessIdDetails } from 'redux/rootSlice/RootSlice'
import { getUserName } from 'utils/authHelpers'
import AffiliateEnrollContent from './AffiliateEnroll/AffiliateEnrollContent'
import TopComponent from './TopComponent'
import { backgroundColor01 } from 'configs/styles/muiThemes'

function AffiliateEnroll() {
  const { state } = useLocation()
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const dispatch = useDispatch()

  const { businessName } = useParams()
  const businessIdData = sessionStorage.getItem('businessId')

  const { businessId } = useSelector((state) => state.rootSlice)
  const { commercialDetails } = useSelector((state) => state.product)
  const { cartDetails } = useSelector((state) => state.cart)

  const [isLoggedIn, setIsLoggedIn] = useState(
    getUserName().length === 0 ? false : true
  )

  const getBusinessData = () => {
    const localBusinessName = sessionStorage.getItem('businessName')
    if (businessName !== localBusinessName) {
      sessionStorage.setItem('cartData', 'null')
    }

    dispatch(getBusinessIdDetails(businessName))
    sessionStorage.setItem('businessName', businessName)
  }

  useEffect(() => {
    getBusinessData()
  }, [businessName])

  useEffect(() => {
    if (businessIdData !== null) {
      // dispatch(getDataForSideBar())
      // dispatch(getCategoryDataFinder())
      if (!commercialDetails) {
        dispatch(getCommercialDetails())
      }
      if (isLoggedIn) {
        if (!cartDetails) {
          dispatch(getCartDetails(businessId))
        }
      }
    }

    return () => {}
  }, [businessId])

  return (
    <Box backgroundColor={backgroundColor01}>
      {businessId !== null && commercialDetails ? (
        <TopComponent value='' disableBanners />
      ) : null}

      <Box sx={styles.wrapper}>
        <Box sx={styles.container}>
          <AffiliateEnrollContent
            formNum={state?.formNum}
            isReadOnly={state?.readOnly}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default AffiliateEnroll

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: { xs: '83px', md: '40px' },
  },

  container: {
    width: '400px',
    // padding: {xs:'50px 0 100px', md:'50px 0 0'},
    paddingBottom: { xs: '70px', md: '0' },
    overflow: 'hidden',
    position: 'relative',
    boxShadow: '0 0px 2px 0 rgb(148 150 159 / 24%)',
  },
}
