import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import { blackColor, borderColor, headingColor, whiteColor } from 'configs/styles/muiThemes'
import React from 'react'
import MapComponent from './MapComponent'
import BusinessInfoCard from './BusinessInfoCard'
import MapIcon from '@mui/icons-material/Map'
import PersonIcon from '@mui/icons-material/Person'
import InputPreview from 'components/InputPreview'

function BusinessInfoMobileCard({ data }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))

  return (
    <Box p={2} pb={8} backgroundColor={whiteColor}>
      <Typography
        variant='h2'
        fontSize={{ md: '19px', xs: '15px' }}
        fontWeight={600}
        align='left'
        color={blackColor}
        mb='10px !important'
      >
        Business Info
      </Typography>
      {data && (
        <>
          <Box mt={2}>
            <InfoCard
              iconUrl='https://storage.googleapis.com/bodefaults/shopweb/reviews/contact.svg'
              label='Contact Name'
              value={data?.ownerName}
            />
            <InfoCard
              iconUrl='https://storage.googleapis.com/bodefaults/shopweb/reviews/phone.svg'
              label='Phone Number'
              value={data?.phone}
            />
            <InfoCard
              iconUrl='https://storage.googleapis.com/bodefaults/shopweb/reviews/address.svg'
              label='Address'
              value={data?.address}
            />

            <Box>
              <Typography
                variant='h5'
                fontSize={15}
                paddingTop={0.3}
                paddingBottom={2.25}
                color={headingColor}
                align='left'
              >
                Location
              </Typography>

              <MapComponent
                latitude={data.loc ? data.loc.y : 12.9716}
                longitude={data.loc ? data.loc.x : 77.5946}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
}

export default BusinessInfoMobileCard

const InfoCard = ({ iconUrl, label, value }) => {
  return (
    <Box
      display={'flex'}
      gap={2}
      borderBottom={`1px solid ${borderColor}`}
      pb='15px'
      mb='15px'
    >
      <Box
        component='img'
        width={{ xs: '45px', md: '45px' }}
        heigth={{ xs: '45px', md: '45px' }}
        src={iconUrl}
        alt='CostBo'
      />

      <Box flex={1}>
        <InputPreview label={label} value={value} valueSize={13} hideBorder />
      </Box>
    </Box>
  )
}
