import { Box, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  blackColor,
  categoryLabelColor,
  categoryLabelSpanColor,
  greyShade59,
  headingColor,
  orangeColor01,
  topbarColor,
} from "../../configs/styles/muiThemes";
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import AffiliateShareModal from 'pages/Affiliates/AffiliateShareModal'
import SocialShareVertical from 'components/SocialShareVertical'
import IosShareIcon from '@mui/icons-material/IosShare'
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined'

export default function ProductDescriptionHeader({
  title,
  subCategory,
  subCategory2,
  copyLink,
  isCopied,
  handleChatClick,
  businessAffiliateData,
  rellerProfileData,
  productDetails,
  commercialDetails,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const [showShareOptions, setShowShareOptions] = useState(false)
  const [windowurl, setWindowurl] = useState('')

  useEffect(() => {
    const url = window.location.href
    setWindowurl(url)
  }, [])

  return (
    <Box position="relative" px={{ xs: 2, md: 0 }}>
      <Typography
        variant="h6"
        fontSize={{ xs: 12.5, md: 13 }}
        fontWeight={400}
        color={{ xs: greyShade59, md: greyShade59 }}
        display="inline"
        mr="3px !important"
      >
        Category:{`  `}
      </Typography>

      <Typography
        variant="h6"
        fontSize={{ xs: 12.5, md: 12.5 }}
        fontWeight={500}
        color={{ xs: greyShade59, md: greyShade59 }}
        display="inline"
      >
        {subCategory}
        {subCategory2 ? (
          <>
            <NavigateNextRoundedIcon sx={{ fontSize: "18px", mb: "-4px" }} />{" "}
            {subCategory2}
          </>
        ) : null}
      </Typography>

      <Typography
        variant="h4"
        color={{ md: headingColor, xs: blackColor }}
        mt={{ md: 0.5, xs: 0.5 }}
        lineHeight={1.5}
        fontSize={{ md: 14, xs: 14.5 }}
        fontWeight={{ xs: 500, md: 600 }}
      >
        {title}
      </Typography>
      {matches && (
        <Box display="flex" gap={1} mt={{ xs: 1, md: 1 }} alignItems="center">
          <Typography
            textAlign="center"
            fontSize={{ md: 12.5, xs: 13 }}
            fontWeight={400}
            sx={{
              color: greyShade59,
            }}
          >
            Share Product Links
          </Typography>
          {isCopied ? (
            <>
              <TaskAltIcon
                sx={{
                  color: orangeColor01,
                  fontSize: { md: 19, xs: 16 },
                }}
              />
              <Box
                component="span"
                sx={{
                  fontSize: 13,
                  fontWeight: 500,
                  color: orangeColor01,
                  ml: "-5px",
                  display: { md: "inline", xs: "none" },
                }}
              >
                Copied
              </Box>
            </>
          ) : (
            <IosShareIcon
              // onClick={copyLink}
              onClick={() => {
                setShowShareOptions(true);
              }}
              sx={{
                cursor: "pointer",
                fontSize: { md: 18, xs: 16 },
                mb: "1px",
              }}
            />
          )}

          {showShareOptions ? (
            <Box position="absolute" bottom="-50px" zIndex={10}>
              {commercialDetails?.info?.costboPowered?.affiliateEnabled &&
              businessAffiliateData?.businessResellers?.length > 0 &&
              rellerProfileData ? (
                <AffiliateShareModal
                  productDetails={productDetails}
                  commissionDetails={businessAffiliateData}
                  rellerProfileData={{ details: rellerProfileData }}
                  open={showShareOptions}
                  onClose={() => {
                    setShowShareOptions(false);
                  }}
                  isProductShare
                />
              ) : (
                <SocialShareVertical
                  // url={`${siteBaseURL}${businessName}`}
                  url={`${windowurl}`}
                  quote=""
                  back={() => {
                    setShowShareOptions(false);
                  }}
                  showCopy
                  onCopyClick={() => {
                    copyLink();
                    setShowShareOptions(false);
                  }}
                />
              )}
            </Box>
          ) : null}
        </Box>
      )}
      {matches && (
        <Box
          display="flex"
          gap={1}
          mt={{ xs: 1, md: 1 }}
          alignItems="center"
          sx={{ cursor: "pointer" }}
          onClick={handleChatClick}
        >
          <Typography
            // component='a'
            // href={

            // }
            target="_blank"
            textAlign="center"
            fontSize={{ md: 12.5, xs: 13 }}
            fontWeight={400}
            sx={{
              color: greyShade59,
            }}
          >
            Inquire about this product
          </Typography>

          <QuestionAnswerOutlinedIcon
            sx={{
              cursor: "pointer",
              fontSize: { md: 19, xs: 18 },
            }}
          />
        </Box>
      )}
    </Box>
  );
}
