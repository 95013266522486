import { Skeleton, Stack } from "@mui/material";
import React from "react";

function SingleProductSidebarShimmer() {
  return (
    <Stack gap={1.5}>
      <Skeleton
        variant="rectangle"
        width="70%"
        height={22}
        sx={{ borderRadius: "16px" }}
      />
      <Skeleton
        variant="rectangle"
        width="100%"
        height={100}
        sx={{ borderRadius: "8px" }}
      />
      <Skeleton
        variant="rectangle"
        width="100%"
        height={100}
        sx={{ borderRadius: "8px" }}
      />
      <Skeleton
        variant="rectangle"
        width="100%"
        height={100}
        sx={{ borderRadius: "8px" }}
      />
      <Skeleton
        variant="rectangle"
        width="100%"
        height={100}
        sx={{ borderRadius: "8px" }}
      />
    </Stack>
  );
}

export default SingleProductSidebarShimmer;
