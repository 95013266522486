import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import {
  blackColor,
  borderColor,
  headingColor,
  orangeColor01,
  progressBgColor,
  reviewBodyColor,
  secondaryColor,
  whiteColor,
} from 'configs/styles/muiThemes'
import React, { useEffect, useRef, useState } from 'react'
import MapComponent from './MapComponent'
import BusinessInfoCard from './BusinessInfoCard'
import MapIcon from '@mui/icons-material/Map'
import PersonIcon from '@mui/icons-material/Person'
import InputPreview from 'components/InputPreview'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUserName, removeSessionInfo } from 'utils/authHelpers'
import { getBusinessIdDetails } from 'redux/rootSlice/RootSlice'
import {
  getCategoryDataFinder,
  getDataForSideBar,
} from 'redux/subCatergory/subCategorySlice'
import { getCommercialDetails } from 'redux/productDescription/ProductDescriptionSlice'
import { getCartDetails } from 'redux/addToCart/AddToCartSlice'
import { getCoupons } from 'redux/dealsCoupons/dealsCouponsSlice'
import {
  getOwnReviewDetails,
  getReviewSummaryDetails,
} from 'redux/reviews/reviewsSlice'
import { getGraphData, summary } from 'utils/utilities'
import CustomerReview from 'pages/Review/CustomerReview'
import ReviewLoginAlert from 'pages/Review/ReviewLoginAlert'
import ModalCard from 'components/ModalCard'
import AddReview from 'pages/Review/ModelComponents/addReview'
import OwnReview from 'pages/Review/OwnReview'
import ReviewCard from 'pages/Review/ReviewCard'
import EditReview from 'pages/Review/ModelComponents/editReview'
import StarRoundedIcon from "@mui/icons-material/StarRounded";

function ReviewMobileCard({
  reviewSummary,
  ownReview,
  graphData,
  getOwnReviewDetails,
  getSummary,
  data,
  getAllReviewDetails,
  optionData,
  isLoggedIn,
  setShowLogin,
}) {
  const [openFullReview, setOpenFullReview] = useState(false)
  const [openAddModal, setOpenAddModal] = useState(false)
  const [openUpdateReview, setOpenUpdateReview] = useState(false)

  const selectedData = useRef(
    data ? (data.allReview !== null ? data.allReview[0] : undefined) : undefined
  )

  const textData = optionData

  // TO close full review
  const handleFullReviewClose = () => {
    setOpenFullReview(false)
  }

  const handlePagination = (event, value) => {
    getAllReviewDetails(textData, value)
  }

  return (
    <Box p="13px 16px 64px 16px" backgroundColor={whiteColor}>
      <Typography
        variant="h2"
        fontSize={{ md: "19px", xs: "15px" }}
        fontWeight={600}
        align="left"
        color={blackColor}
      >
        Review
      </Typography>

      <Box sx={styles.reviewBox}>
        <Box sx={styles.rating}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={1}
            alignItems={"center"}
          >
            <Typography fontSize={14} fontWeight={600} color={blackColor}>
              {reviewSummary?.average} out of 5
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                //flexWrap: 'wrap',
                //   gap: '10px', // space between images
              }}
            >
              {[...Array(Number(5))]?.map((_, index) => (
                <>
                  {/* <Box
                    component="img"
                    maxHeight={{ xs: "16px", md: "20px" }}
                    src={
                     ``
                    }
                    alt={`Star ${index + 1}`}
                    sx={{
                      width: "10x",
                      height: "10x",
                      display: "flex",
                      lineHeight: 1.5,
                      mt: 0.3,
                      ml: 0.3,
                    }}
                  /> */}
                  <StarRoundedIcon
                    sx={{
                      width: "18.5px",
                      height: "18.5px",
                      ml: -0.4,
                      color:orangeColor01
                    }}
                  />
                </>
              ))}
            </Box>
            <Typography fontSize={12} fontWeight={500} color={blackColor}>
              {reviewSummary?.totalRated} Reviews
            </Typography>
          </Box>
        </Box>

        <Box sx={styles.ratingSlide}>
          {graphData.map((graph, index) => {
            console.log("graph", graph);
            return (
              <ProgressRow
                label={`${graph.reviewRating}`}
                progressValue={Math.round(graph.percent)}
              />
            );
          })}
        </Box>
      </Box>

      <Box onClick={() => {}} sx={styles.addReviewBox}>
        {!isLoggedIn ? (
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={1}
            alignItems={"center"}
          >
            <ReviewLoginAlert onLoginClick={setShowLogin} />
          </Box>
        ) : (
          ownReview === null && (
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={1}
              alignItems={"center"}
            >
              <Typography
                fontSize={14}
                fontWeight={500}
                color={blackColor}
                onClick={() => {
                  setOpenAddModal(true);
                }}
              >
                + Write Your Review
              </Typography>
            </Box>
          )
        )}
        {ownReview === null ? null : (
          <OwnReview
            onEditClick={() => setOpenUpdateReview(true)}
            reviews={ownReview}
          />
        )}
        <EditReview
          ownReview={ownReview}
          open={openUpdateReview}
          getOwnReviewDetails={() => getOwnReviewDetails()}
          getSummary={() => getSummary()}
          onClose={() => setOpenUpdateReview(false)}
        />
      </Box>

      {selectedData.current && (
        <ModalCard handleClose={handleFullReviewClose} open={openFullReview}>
          <ReviewCard
            disableDate={true}
            showFullData={true}
            data={selectedData.current.data}
            connection={selectedData.current.connection}
            totalReviews={selectedData.current.totalReviews}
          />
        </ModalCard>
      )}
      <CustomerReview count={reviewSummary?.totalRated} />
      {openAddModal && (
        <ModalCard
          handleClose={() => setOpenAddModal(false)}
          open={openAddModal}
        >
          <Box
            sx={{
              right: 0,
              left: 0,
              borderTop:
                "var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color)",
              zIndex: 9999,
              bottom: "0px",
              backgroundColor: whiteColor,
              borderRadius: "10px",
            }}
          >
            <AddReview
              getOwnReviewDetails={() => getOwnReviewDetails()}
              getSummary={() => getSummary()}
            />
          </Box>
        </ModalCard>
      )}
    </Box>
  );
}

export default ReviewMobileCard

const ProgressRow = ({ label, progressValue }) => {
  return (
    <Box mt="5px">
      <Grid container display="flex" alignItems="center" spacing={1}>
        <Grid item xs={1.9}>
          <Box display={"flex"}>
            <Typography
              width="8.31px"
              fontSize={13}
              fontWeight={500}
              variant="body1"
              lineHeight="18px"
              color={blackColor}
              mr={0.7}
              textAlign="center"
            >
              {label}
            </Typography>

            {/* <Box
              component='img'
              src={`https://storage.googleapis.com/bodefaults/shopweb/reviews/star.svg`}
              alt={`Star`}
              sx={{
                width: '11x',
                height: '10px',
                display: 'flex',
                lineHeight: 1.5,
                mt: 0.3,
              }}
            /> */}
            <StarRoundedIcon
              sx={{
                width: "18.5px",
                height: "18.5px",
                ml: -0.4,
                color: orangeColor01,
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={8}>
          <Box sx={styles.progressBG}>
            <Box
              sx={[
                styles.progress,
                { width: `${progressValue}%`, background: blackColor },
              ]}
            ></Box>
          </Box>
        </Grid>
        <Grid item xs={2} >
          <Typography
            variant="body2"
            fontSize={13}
            lineHeight="18px"
            color={blackColor}
            textAlign={"end"}
          >
            {progressValue > 0 ? `${progressValue}%` : "0%"}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

const styles = {
  reviewBox: {
    borderTop: `0px solid rgba(var(--black), 0.09)`,
    borderBottom: `1px solid rgba(0,0,0,0.09)`,
    padding: '10px 0 15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addReviewBox: {
    borderBottom: `1px dashed rgba(0,0,0,0.09)`,
    padding: '10px 0 10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rating: {
    width: '40%',
    height: '100%',
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  ratingSlide: {
    width: '60%',
    paddingLeft: '15px',
    borderLeft: `1px dashed rgba(0,0,0,0.09)`,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    mt: 2,
  },

  progressBG: {
    // width: '100%',
    // height: '16px',
    // backgroundColor: progressBgColor,
    // borderRadius: '40px',
    width: '100%',
    height: '3px',
    borderRadius: '4px',
    backgroundColor: 'rgb(243, 243, 248)',
    display: 'flex',
    overflow: 'hidden',
  },

  progress: {
    // height: '16px',
    // backgroundColor: secondaryColor,
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    // borderRadius: '40px',
    transition: 'width 6s',
    // width: '80%',
    height: '3px',
    borderRadius: '4px',
    backgroundColor: 'rgb(243, 243, 248)',
    display: 'flex',
    overflow: 'hidden',
  },
}
