import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import BreadcrumbsSection from "../../components/Breadcrumbs";
import {
  backgroundColor,
  backgroundColor01,
  blackColor,
  borderLineColor,
  completedColor,
  delectIconInactiveColor,
  greenColor04,
  headingColor,
  lightGreen03,
  orangeColor01,
  savingTextColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import DeliveryAddress from "../Confirmation/DeliveryAddress";
import DeliveryMethod from "../Confirmation/DeliveryMethod";
import OrderStep from "../AllOrders/OrderStep";
import moment from "moment";
import PaymentType from "./PaymentType";
import TableProductCard from "components/TableProductCard";
import SpecialNotes from "pages/Confirmation/SpecialNotes";
import ETADetails from "./ETADetails";
import HorizontalStepper from "components/HorizontalStepper";
import ShoppingBasketRoundedIcon from "@mui/icons-material/ShoppingBasketRounded";
import DiscountHeader from "pages/DiscountDelivery/DiscountHeader";
import { useEffect, useState } from "react";
import Loader from "components/Loader";
import OrderDetailsLoader from "components/skeletonLoaders/orders/OrderDetailsLoader";
import OrderDetailsMobileLoader from "components/skeletonLoaders/orders/OrderDetailsMobileLoader";
import {
  formatStrTime,
  getCancelDateByStatus,
  getCancelledByText,
  getDifferentInTime,
  getOrderDeliveryTypeText,
} from "utils/utilities";
import CancelCard from "./CancelCard";

function OrderDetailsContent({
  data,
  orderStatus,
  cartDate,
  fromPage,
  loading,
  getOndcBapNameBySubscriberId,
  commercialDetails,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const steps = [
    {
      label: "Created",
      date: data?.cart?.orderCreatedOn
        ? moment(data?.cart?.orderCreatedOn).format("MMM DD, YYYY")
        : "",
      time: data?.cart?.orderCreatedOn
        ? moment(data?.cart?.orderCreatedOn).format("hh:mm A")
        : "",
    },
    {
      label:
        orderStatus == "bordercanceled" ||
        orderStatus === "cordercanceled" ||
        orderStatus === "lordercanceled"
          ? "Cancelled"
          : "Approved",
      date: data?.cart?.orderApprovedOn
        ? moment(data?.cart?.orderApprovedOn).format("MMM DD, YYYY")
        : "",
      time: data?.cart?.orderApprovedOn
        ? moment(data?.cart?.orderApprovedOn).format("hh:mm A")
        : "",
    },
    {
      label: "Shipped",
      date: data?.cart?.orderShippedOn
        ? moment(data?.cart?.orderShippedOn).format("MMM DD, YYYY")
        : "",
      time: data?.cart?.orderShippedOn
        ? moment(data?.cart?.orderShippedOn).format("hh:mm A")
        : "",
    },
    {
      label: "Delivered",
      date: data?.cart?.orderDeliveredOn
        ? moment(data?.cart?.orderDeliveredOn).format("MMM DD, YYYY")
        : "",
      time: data?.cart?.orderDeliveredOn
        ? moment(data?.cart?.orderDeliveredOn).format("hh:mm A")
        : "",
    },
  ];
  const isOrderCreated =
    orderStatus === "ordercreated" ||
    orderStatus === "orderapproved" ||
    orderStatus === "ordershipped" ||
    orderStatus === "orderdelivered";
  // || orderStatus == 'paycartcreated'

  const isOrderApproved =
    orderStatus === "orderapproved" ||
    orderStatus === "ordershipped" ||
    orderStatus === "orderdelivered";

  const isOrderShipped =
    orderStatus === "ordershipped" || orderStatus === "orderdelivered";

  const isOrderDelivered = orderStatus === "orderdelivered";
  const isCancelled = orderStatus === "bordercanceled";

  return matches ? (
    <Container
      sx={{ maxWidth: "1370px !important", mt: { xs: 3, md: "0 !important" } }}
      backgroundColor={backgroundColor01}
    >
      {/* <BreadcrumbsSection
        links={[
          { label: fromPage?.label, to: `/${fromPage?.to}` },
          { label: 'Order Details', to: `#` },
        ]}
      /> */}
      {!loading && data?.cart ? (
        <Grid container spacing={3} backgroundColor={backgroundColor01}>
          <Grid item xs={12} md={8}>
            <Box
              sx={styles.cartContainer}
              backgroundColor={{ md: whiteColor, xs: whiteColor }}
              mb={3}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb="14px"
                mt={1}
                flexWrap="wrap"
                gap={1}
              >
                <Typography
                  variant="h5"
                  fontSize={{ xs: 15, md: 16 }}
                  color={headingColor}
                >
                  Order Details
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontSize={{ xs: 13, md: 13 }}
                  fontWeight={500}
                  lineHeight="24px"
                  color={
                    orderStatus === "ordercreated"
                      ? "#fca549"
                      : orderStatus === "orderapproved"
                      ? "#c94b85"
                      : orderStatus === "orderdelivered"
                      ? completedColor
                      : orderStatus === "ordershipped"
                      ? "#c94b85"
                      : orderStatus == "bordercanceled" ||
                        orderStatus === "cordercanceled" ||
                        orderStatus === "lordercanceled"
                      ? "#E53935"
                      : "#fca549"
                  }
                >
                  {getOrderStatus(orderStatus)}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                py={{ xs: 0.5, md: 1 }}
              >
                <Typography
                  variant="h6"
                  color={headingColor}
                  textAlign="center"
                  fontSize={{ xs: 14, md: 16 }}
                  fontWeight={600}
                  display="inline"
                >
                  Thank you for your Order
                </Typography>
                <Typography
                  variant="h6"
                  color={headingColor}
                  fontWeight={500}
                  fontSize={{ xs: 14, md: 13 }}
                  textAlign="center"
                  display="inline"
                >
                  Date:{" "}
                  <Typography
                    variant="h6"
                    fontSize={{ xs: 14, md: 13 }}
                    color={headingColor}
                    textAlign="center"
                    display="inline"
                    fontWeight={600}
                  >
                    {data?.cart?.orderCreatedOn
                      ? moment(data?.cart?.orderCreatedOn).format(
                          "MMM DD, YYYY"
                        )
                      : cartDate
                      ? moment(cartDate).format("MMM DD, YYYY")
                      : ""}
                  </Typography>
                </Typography>
                <Typography
                  variant="h6"
                  color={headingColor}
                  textAlign="center"
                  fontSize={{ xs: 14, md: 13 }}
                  fontWeight={600}
                  display="inline"
                >
                  Order ID:{" "}
                  <Typography
                    variant="h6"
                    color={orangeColor01}
                    fontSize={{ xs: 14, md: 13 }}
                    textAlign="center"
                    ml={1}
                    display="inline"
                  >
                    #{data?.cart?.orderId}
                  </Typography>
                </Typography>
                {data?.cart?.ondcInfo?.bapOrderId && (
                  <Typography
                    variant="h6"
                    color={headingColor}
                    textAlign="center"
                    fontSize={{ xs: 14, md: 13 }}
                    fontWeight={600}
                    display="inline"
                  >
                    ONDC ORDER -{" "}
                    {data?.cart?.ondcInfo?.bapId
                      ? getOndcBapNameBySubscriberId(
                          data?.cart?.ondcInfo?.bapId
                        )
                      : "N/A"}{" "}
                    :{" "}
                    <Typography
                      variant="h6"
                      color={orangeColor01}
                      fontSize={{ xs: 14, md: 13 }}
                      textAlign="center"
                      mt={1.5}
                      ml={1}
                      display="inline"
                    >
                      #{data?.cart?.ondcInfo?.bapOrderId}
                    </Typography>
                  </Typography>
                )}
              </Box>

              <Box display="flex" justifyContent="center" px={{ md: 2, xs: 2 }}>
                <Box width={{ md: "90%", xs: "auto" }}>
                  <HorizontalStepper
                    steps={steps}
                    activeStep={
                      orderStatus == "ordercreated" ||
                      orderStatus == "paycartcreated"
                        ? 0
                        : orderStatus == "orderapproved" ||
                          orderStatus == "bordercanceled" ||
                          orderStatus === "cordercanceled" ||
                          orderStatus === "lordercanceled"
                        ? 1
                        : orderStatus == "ordershipped"
                        ? 2
                        : orderStatus == "orderdelivered"
                        ? 3
                        : orderStatus
                    }
                    canceled={
                      orderStatus == "bordercanceled" ||
                      orderStatus === "cordercanceled" ||
                      orderStatus === "lordercanceled"
                    }
                  />
                </Box>
              </Box>
            </Box>

            {orderStatus == "bordercanceled" ||
            orderStatus === "cordercanceled" ||
            orderStatus === "lordercanceled" ? (
              <CancelCard
                canceledBy={getCancelledByText(orderStatus)}
                cancelDate={getCancelDateByStatus(data?.cart)}
                reason={
                  data?.cart?.cancelInfo
                    ? data?.cart?.cancelInfo?.cancelReason
                    : data?.cart?.orderStatus === "bordercanceled"
                    ? data?.cart?.notesBusiness
                    : data?.cart?.notesConsumer
                }
              />
            ) : null}

            {data?.cart ? (
              data?.cart?.notesConsumer ? (
                <SpecialNotes note={data?.cart?.notesConsumer} />
              ) : null
            ) : null}

            <Box
              sx={{ ...styles.cartContainer, p: 0 }}
              backgroundColor={{ md: whiteColor, xs: whiteColor }}
              mb={2}
            >
              <Box display="flex" alignItems="center" p="14px 20px">
                <ShoppingBasketRoundedIcon
                  sx={{
                    margin: "-1px 10px 0px 0",
                    fontSize: { md: "20px", xs: "22px" },
                    color: blackColor,
                  }}
                />

                <Typography fontSize={15} fontWeight={600} color={blackColor}>
                  {
                    data?.cart?.productList?.reduce((accumulator, product) => {
                      return accumulator + product.currentQuantity;
                    }, 0) || 0
                    // Number(
                    //   cartDetails?.productList?.[0]?.currentQuantity +
                    //     cartDetails?.productList?.[1]?.currentQuantity
                    // )
                  }{" "}
                  Items in your cart
                </Typography>
              </Box>

              {/* <Grid container py={1} display={{ xs: 'none', md: 'inline-flex' }}>
              <Grid item xs={5}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                  ml={1.8}
                >
                  PRODUCT
                </Typography>
              </Grid>
              <Grid item xs={2.5}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                >
                  UNIT PRICE
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='14px'
                  color={delectIconInactiveColor}
                >
                  QUANTITY
                </Typography>
              </Grid>
              <Grid item xs={1.5}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                  ml={0.6}
                >
                  PRICE
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                >
                  SAVINGS
                </Typography>
              </Grid>
            </Grid> */}

              <Box p="0 20px">
                {data?.cart?.productList?.map((item, index) => {
                  const savings =
                    Number(item?.productOriginalPrice) *
                      Number(item?.currentQuantity) -
                    Number(item?.productSalePrice) *
                      Number(item?.currentQuantity);

                  return (
                    <TableProductCard
                      key={"cart_product_" + index}
                      image={item?.productPicURL}
                      productName={item?.productName}
                      oldUnitPrice={item?.productOriginalPrice}
                      unitprice={item?.productSalePrice}
                      discount={item?.percentageOff}
                      price={item?.productTotalPrice}
                      saving={savings}
                      quantity={item.currentQuantity}
                      isOutofStock={item.isOutOfStock}
                      onDeleteProduct={() => {}}
                      readOnly={true}
                    />
                  );
                })}
              </Box>
            </Box>
            <Box
              border={"1px solid " + borderLineColor}
              borderRadius={2.2}
              p={1.85}
              display={{ xs: "block", md: "none" }}
              backgroundColor={{ md: whiteColor, xs: whiteColor }}
            >
              <Typography
                variant="h5"
                component="h3"
                fontSize={{ xs: 14, md: 15 }}
                color={headingColor}
                pb={1.5}
                borderBottom={"1px solid " + borderLineColor}
                textAlign={{ md: "left", xs: "left" }}
              >
                Summary
              </Typography>

              <Box py={1}>
                <SummaryDataRow
                  label="Subtotal:"
                  value={`₹${data?.cart?.orderTotalProductCost?.toFixed(2)}`}
                />
                <SummaryDataRow
                  label="Total Quantity:"
                  value={data?.cart?.totalQuantity}
                />
                <SummaryDataRow label="Taxes:" value="Included" isValueGreen />
                <SummaryDataRow
                  label="Shipping & Handling:"
                  value={`₹${data?.cart?.shippingCost?.toFixed(2)}`}
                />

                {data?.cart?.codCost ? (
                  <SummaryDataRow
                    label="COD Cost:"
                    value={`₹${data?.cart?.codCost?.codCharges?.toFixed(2)}`}
                  />
                ) : null}

                <SummaryDataRow
                  label="Discount:"
                  value={`₹${data?.cart?.claimedPoint?.toFixed(2)}`}
                />
              </Box>

              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                pt={0.7}
                borderTop={"1px solid " + borderLineColor}
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    component="p"
                    fontSize={14}
                    color={headingColor}
                    display="inline"
                  >
                    Total
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography
                    variant="h5"
                    component="p"
                    color={orangeColor01}
                    display="inline"
                  >
                    ₹
                    {(
                      data?.cart?.orderTotalProductCost +
                      data?.cart?.shippingCost -
                      data?.cart?.claimedPoint
                    ).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography
              variant="h5"
              component="h3"
              fontSize={{ xs: 14, md: 14 }}
              color={headingColor}
              pb={1}
              textAlign={{ md: "left", xs: "left" }}
              display={{ xs: "none", md: "block" }}
            >
              Summary
            </Typography>
            <Box
              border={"1px solid " + borderLineColor}
              borderRadius="8px"
              p={"6px 12px"}
              display={{ xs: "none", md: "block" }}
              backgroundColor={whiteColor}
              mb={2}
            >
              <Box>
                <SummaryDataRow
                  label="Subtotal:"
                  value={`₹${data?.cart?.orderTotalProductCost?.toFixed(2)}`}
                />
                <SummaryDataRow
                  label="Total Quantity:"
                  value={data?.cart?.totalQuantity}
                />
                <SummaryDataRow label="Taxes:" value="Included" isValueGreen />
                <SummaryDataRow
                  label="Shipping & Handling:"
                  value={`₹${data?.cart?.shippingCost?.toFixed(2)}`}
                />

                {data?.cart?.codCost ? (
                  <SummaryDataRow
                    label="COD Cost:"
                    value={`₹${data?.cart?.codCost?.codCharges?.toFixed(2)}`}
                  />
                ) : null}

                <SummaryDataRow
                  label="Discount:"
                  value={`₹${data?.cart?.claimedPoint?.toFixed(2)}`}
                />
              </Box>

              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                p="10px 0 5px 0"
                mt="6px"
                borderTop={"1px solid " + borderLineColor}
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    component="p"
                    fontSize={{ md: 14, xs: 13 }}
                    color={{ md: blackColor, xs: blackColor }}
                    display="inline"
                  >
                    Total
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography
                    variant="h5"
                    component="p"
                    fontSize={{ md: 14, xs: 14 }}
                    color={{ md: greenColor04, xs: lightGreen03 }}
                    display="inline"
                  >
                    ₹{Number(data?.cart?.orderTotalCost).toFixed(2)}
                    {/* {(
                    data?.cart?.orderTotalProductCost +
                    data?.cart?.shippingCost -
                    data?.cart?.claimedPoint
                  ).toFixed(2)} */}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Typography
              variant="h5"
              component="h3"
              fontSize={{ xs: 14, md: 14 }}
              color={headingColor}
              pb={1}
              textAlign={{ md: "left", xs: "left" }}
              mt={2}
              display={{ xs: "none", md: "block" }}
            >
              Delivery Address
            </Typography>
            <DeliveryAddress
              // name={data?.cart?.customerAddress?.name}
              // type={data?.cart?.customerAddress?.addressType}
              // houseNo={data?.cart?.customerAddress?.houseNo}
              // address={data?.cart?.customerAddress?.addressLine1}
              // city={data?.cart?.customerAddress?.city}
              // state={data?.cart?.customerAddress?.state}
              // country={data?.cart?.customerAddress?.country}
              // contactNumber={data?.cart?.customerAddress?.phoneNo + JSON.stringify(data?.cart?.customerAddress)}
              address={data?.cart?.customerAddress}
            />
            <Typography
              variant="h5"
              component="h3"
              fontSize={{ xs: 14, md: 14 }}
              color={headingColor}
              pb={1}
              textAlign={{ md: "left", xs: "left" }}
              mt={2}
              display={{ xs: "none", md: "block" }}
            >
              Delivery Method
            </Typography>
            <DeliveryMethod
              delivery={getOrderDeliveryTypeText(
                data?.cart?.deliveryType?.type,
                data?.cart?.deliveryType?.shipType
              )}
            />
            {}
            {((data?.cart?.deliveryType?.type === "ondc" &&
              orderStatus &&
              orderStatus !== "ordercreated" &&
              orderStatus !== "bordercanceled" &&
              orderStatus !== "cordercanceled" &&
              orderStatus !== "lordercanceled") ||
              orderStatus === "orderdelivered") && (
              <>
                <Typography
                  variant="h5"
                  component="h3"
                  color={{ md: headingColor, xs: blackColor }}
                  fontSize={{ xs: 14, md: 14 }}
                  textAlign={{ md: "left", xs: "left" }}
                  display={{ xs: "none", md: "block" }}
                >
                  Delivery Details
                </Typography>
                <ETADetails
                  deliveryETA={data?.cart?.shipmentInfo?.etaDeliveryTime}
                  deliveryDate={data?.cart?.shipmentInfo?.etaDeliveryDate}
                  orderDeliveredOn={data?.cart?.orderDeliveredOn}
                  orderDeliveredIn={
                    data?.cart?.orderDeliveredOn && data?.cart?.orderCreatedOn
                      ? getDifferentInTime(
                          data?.cart?.orderDeliveredOn,
                          data?.cart?.orderCreatedOn
                        )
                      : null
                  }
                  orderStatus={orderStatus}
                />
              </>
            )}
            {/* ) : null} */}
            <Typography
              variant="h5"
              component="h3"
              color={{ md: headingColor, xs: blackColor }}
              fontSize={{ xs: 14, md: 14 }}
              textAlign={{ md: "left", xs: "left" }}
              display={{ xs: "none", md: "block" }}
              mt="14px !important"
            >
              Payment Type
            </Typography>
            <PaymentType
              paymentType={data?.cart?.paymentInfo?.paymentDetail?.paymentType}
            />

            {/* For Testing Only */}
            {/* <SummaryDataRow
            label='paymentType:'
            value={data?.cart?.paymentInfo?.paymentDetail?.paymentType}
          />
          <SummaryDataRow
            label='modeOfPayment:'
            value={data?.cart?.modeOfPayment}
          />
          <SummaryDataRow
            label='deliveryType.type:'
            value={data?.cart?.deliveryType?.type}
          />
          <SummaryDataRow
            label='deliveryType.shipType:'
            value={data?.cart?.deliveryType?.shipType}
          />
          <SummaryDataRow
            label='shipmentInfo.carrierName:'
            value={data?.cart?.shipmentInfo?.carrierName}
          />
          <SummaryDataRow
            label='discountDetails.discountType:'
            value={data?.cart?.discountDetails?.[0]?.discountType}
          />
          <SummaryDataRow
            label='discountDetails.discountAmount:'
            value={data?.cart?.discountDetails?.[0]?.discountAmount}
          />
          <SummaryDataRow
            label='deliveryDateRange:'
            value={data?.cart?.deliveryDateRange}
          /> */}
          </Grid>
        </Grid>
      ) : (
        <OrderDetailsLoader />
        // <Loader />
      )}
    </Container>
  ) : (
    <Container
      sx={{
        maxWidth: "1370px !important",
        mt: { xs: commercialDetails && data ? 12 : "-8px", md: "0 !important" },
      }}
      backgroundColor={backgroundColor01}
    >
      {!loading && commercialDetails && data?.cart ? (
        <Grid
          container
          columnSpacing={{ md: 3, xs: 0 }}
          rowSpacing={{ md: 3, xs: 0 }}
          backgroundColor={backgroundColor01}
        >
          <Grid item xs={12} md={8.25}>
            <Box
              sx={styles.cartContainer}
              backgroundColor={{ md: whiteColor, xs: whiteColor }}
              mb={3}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={1}
                mt={1}
                flexWrap="wrap"
                gap={1}
              >
                <Typography
                  variant="h5"
                  fontSize={{ xs: 15, md: 17 }}
                  color={headingColor}
                >
                  Order Details
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontSize={{ xs: 13, md: 14 }}
                  fontWeight={500}
                  lineHeight="24px"
                  color={
                    orderStatus === "ordercreated"
                      ? "#fca549"
                      : orderStatus === "orderapproved"
                      ? "#c94b85"
                      : orderStatus === "orderdelivered"
                      ? completedColor
                      : orderStatus === "ordershipped"
                      ? "#c94b85"
                      : orderStatus == "bordercanceled" ||
                        orderStatus === "cordercanceled" ||
                        orderStatus === "lordercanceled"
                      ? "#E53935"
                      : "#fca549"
                  }
                >
                  {getOrderStatus(orderStatus)}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  variant="h6"
                  color={headingColor}
                  textAlign="center"
                  fontSize={{ xs: 14, md: 17 }}
                  fontWeight={600}
                  display="inline"
                >
                  Thank you for your Order
                </Typography>
                <Typography
                  variant="h6"
                  color={headingColor}
                  fontWeight={500}
                  fontSize={{ xs: 14, md: 13 }}
                  textAlign="center"
                  display="inline"
                >
                  Date:{" "}
                  <Typography
                    variant="h6"
                    fontSize={{ xs: 14, md: 13 }}
                    color={headingColor}
                    textAlign="center"
                    display="inline"
                    fontWeight={600}
                  >
                    {data?.cart?.orderCreatedOn
                      ? moment(data?.cart?.orderCreatedOn).format(
                          "MMM DD, YYYY"
                        )
                      : cartDate
                      ? moment(cartDate).format("MMM DD, YYYY")
                      : ""}
                  </Typography>
                </Typography>
                <Typography
                  variant="h6"
                  color={headingColor}
                  textAlign="center"
                  fontSize={{ xs: 14, md: 14 }}
                  fontWeight={600}
                  display="inline"
                >
                  Order ID:{" "}
                  <Typography
                    variant="h6"
                    color={orangeColor01}
                    fontSize={{ xs: 14, md: 14 }}
                    textAlign="center"
                    mt={1.5}
                    display="inline"
                  >
                    #{data?.cart?.orderId}
                  </Typography>
                </Typography>
                {data?.cart?.ondcInfo?.bapOrderId && (
                  <Typography
                    variant="h6"
                    color={headingColor}
                    textAlign="center"
                    fontSize={{ xs: 14, md: 14 }}
                    fontWeight={600}
                    display="inline"
                  >
                    ONDC ORDER -{" "}
                    {data?.cart?.ondcInfo?.bapId
                      ? getOndcBapNameBySubscriberId(
                          data?.cart?.ondcInfo?.bapId
                        )
                      : "N/A"}{" "}
                    :{" "}
                    <Typography
                      variant="h6"
                      color={orangeColor01}
                      fontSize={{ xs: 14, md: 14 }}
                      textAlign="center"
                      mt={1.5}
                      display="inline"
                    >
                      #{data?.cart?.ondcInfo?.bapOrderId}
                    </Typography>
                  </Typography>
                )}
              </Box>

              <Box px={{ md: 27, xs: 0 }}>
                <HorizontalStepper
                  steps={steps}
                  activeStep={
                    orderStatus == "ordercreated" ||
                    orderStatus == "paycartcreated"
                      ? 0
                      : orderStatus == "orderapproved" ||
                        orderStatus == "bordercanceled" ||
                        orderStatus === "cordercanceled" ||
                        orderStatus === "lordercanceled"
                      ? 1
                      : orderStatus == "ordershipped"
                      ? 2
                      : orderStatus == "orderdelivered"
                      ? 3
                      : orderStatus
                  }
                  canceled={
                    orderStatus == "bordercanceled" ||
                    orderStatus === "cordercanceled" ||
                    orderStatus === "lordercanceled"
                  }
                />
              </Box>
            </Box>
            {orderStatus == "bordercanceled" ||
            orderStatus === "cordercanceled" ||
            orderStatus === "lordercanceled" ? (
              <CancelCard
                canceledBy={getCancelledByText(orderStatus)}
                cancelDate={getCancelDateByStatus(data?.cart)}
                reason={
                  data?.cart?.cancelInfo
                    ? data?.cart?.cancelInfo?.cancelReason
                    : data?.cart?.orderStatus === "bordercanceled"
                    ? data?.cart?.notesBusiness
                    : data?.cart?.notesConsumer
                }
              />
            ) : null}
            {data?.cart ? (
              data?.cart?.notesConsumer ? (
                <SpecialNotes note={data?.cart?.notesConsumer} />
              ) : null
            ) : null}

            <Box>
              <Typography
                variant="h5"
                component="h3"
                fontSize={{ xs: 14, md: 15 }}
                color={headingColor}
                pb={1.5}
                borderBottom={"1px solid " + borderLineColor}
                textAlign={{ md: "left", xs: "left" }}
              >
                Summary
              </Typography>
              <Box
                border={"1px solid " + borderLineColor}
                borderRadius={2.2}
                p={1.85}
                display={{ xs: "block", md: "none" }}
                backgroundColor={{ md: whiteColor, xs: whiteColor }}
              >
                <Box>
                  <SummaryDataRow
                    label="Subtotal:"
                    value={`₹${data?.cart?.orderTotalProductCost?.toFixed(2)}`}
                  />
                  <SummaryDataRow
                    label="Total Quantity:"
                    value={data?.cart?.totalQuantity}
                  />
                  <SummaryDataRow
                    label="Taxes:"
                    value="Included"
                    isValueGreen
                  />
                  <SummaryDataRow
                    label="Shipping & Handling:"
                    value={`₹${data?.cart?.shippingCost?.toFixed(2)}`}
                  />

                  {data?.cart?.codCost ? (
                    <SummaryDataRow
                      label="COD Cost:"
                      value={`₹${data?.cart?.codCost?.codCharges?.toFixed(2)}`}
                    />
                  ) : null}

                  <SummaryDataRow
                    label="Discount:"
                    value={`₹${data?.cart?.claimedPoint?.toFixed(2)}`}
                  />
                </Box>

                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  pt={0.7}
                  borderTop={"1px solid " + borderLineColor}
                >
                  <Grid item>
                    <Typography
                      variant="h5"
                      component="p"
                      fontSize={14}
                      color={headingColor}
                      display="inline"
                    >
                      Total
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography
                      variant="h5"
                      component="p"
                      color={greenColor04}
                      display="inline"
                      fontSize={14}
                    >
                      ₹
                      {(
                        data?.cart?.orderTotalProductCost +
                        data?.cart?.shippingCost -
                        data?.cart?.claimedPoint
                      ).toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box
              sx={styles.cartContainer}
              backgroundColor={{ md: whiteColor, xs: whiteColor }}
              mt={2}
            >
              <DiscountHeader
                icon={
                  <ShoppingBasketRoundedIcon
                    sx={{
                      margin: "0 6px -3px 0",
                      fontSize: { md: "18px", xs: "22px" },
                      // height:"15px",
                      //width:"22px"
                    }}
                  />
                }
                title={
                  matches
                    ? `${
                        data?.cart?.productList?.reduce(
                          (accumulator, product) => {
                            return accumulator + product.currentQuantity;
                          },
                          0
                        ) || 0
                        // Number(
                        //   cartDetails?.productList?.[0]?.currentQuantity +
                        //     cartDetails?.productList?.[1]?.currentQuantity
                        // )
                      }  Items in your cart`
                    : `
                  ${
                    data?.cart?.productList?.reduce((accumulator, product) => {
                      return accumulator + product.currentQuantity;
                    }, 0) || 0
                    // Number(
                    //   cartDetails?.productList?.[0]?.currentQuantity +
                    //     cartDetails?.productList?.[1]?.currentQuantity
                    // )
                  } 
                    Items in Cart`
                }
              />

              {/* <Grid container py={1} display={{ xs: 'none', md: 'inline-flex' }}>
              <Grid item xs={5}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                  ml={1.8}
                >
                  PRODUCT
                </Typography>
              </Grid>
              <Grid item xs={2.5}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                >
                  UNIT PRICE
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='14px'
                  color={delectIconInactiveColor}
                >
                  QUANTITY
                </Typography>
              </Grid>
              <Grid item xs={1.5}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                  ml={0.6}
                >
                  PRICE
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='13px'
                  color={delectIconInactiveColor}
                >
                  SAVINGS
                </Typography>
              </Grid>
            </Grid> */}

              {data?.cart?.productList?.map((item, index) => {
                const savings =
                  Number(item?.productOriginalPrice) *
                    Number(item?.currentQuantity) -
                  Number(item?.productSalePrice) *
                    Number(item?.currentQuantity);

                return (
                  <TableProductCard
                    key={"cart_product_" + index}
                    image={item?.productPicURL}
                    productName={item?.productName}
                    oldUnitPrice={item?.productOriginalPrice}
                    unitprice={item?.productSalePrice}
                    discount={item?.percentageOff}
                    price={item?.productTotalPrice}
                    saving={savings}
                    quantity={item.currentQuantity}
                    isOutofStock={item.isOutOfStock}
                    onDeleteProduct={() => {}}
                    readOnly={true}
                  />
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={12} md={3.75} mb={10}>
            <Typography
              variant="h5"
              component="h3"
              fontSize={{ xs: 14, md: 15 }}
              color={headingColor}
              pb={1.5}
              textAlign={{ md: "left", xs: "left" }}
              mt={2}
              display={{ xs: "none", md: "block" }}
            >
              Summary
            </Typography>
            <Box
              border={"1px solid " + borderLineColor}
              borderRadius={2.2}
              p={1.85}
              display={{ xs: "none", md: "block" }}
              backgroundColor={whiteColor}
              mb={2}
            >
              <Box py={1}>
                <SummaryDataRow
                  label="Subtotal:"
                  value={`₹${data?.cart?.orderTotalProductCost?.toFixed(2)}`}
                />
                <SummaryDataRow
                  label="Total Quantity:"
                  value={data?.cart?.totalQuantity}
                />
                <SummaryDataRow label="Taxes:" value="Included" isValueGreen />
                <SummaryDataRow
                  label="Shipping & Handling:"
                  value={`₹${data?.cart?.shippingCost?.toFixed(2)}`}
                />

                {data?.cart?.codCost ? (
                  <SummaryDataRow
                    label="COD Cost:"
                    value={`₹${data?.cart?.codCost?.codCharges?.toFixed(2)}`}
                  />
                ) : null}

                <SummaryDataRow
                  label="Discount:"
                  value={`₹${data?.cart?.claimedPoint?.toFixed(2)}`}
                />
              </Box>

              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                pt={0.7}
                borderTop={"1px solid " + borderLineColor}
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    component="p"
                    fontSize={{ md: 14, xs: 13 }}
                    color={{ md: blackColor, xs: blackColor }}
                    display="inline"
                  >
                    Total
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography
                    variant="h5"
                    component="p"
                    color={{ md: greenColor04, xs: lightGreen03 }}
                    display="inline"
                  >
                    ₹{Number(data?.cart?.orderTotalCost).toFixed(2)}
                    {/* {(
                    data?.cart?.orderTotalProductCost +
                    data?.cart?.shippingCost -
                    data?.cart?.claimedPoint
                  ).toFixed(2)} */}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Typography
              variant="h5"
              component="h3"
              fontSize={{ xs: 14, md: 15 }}
              color={headingColor}
              pb={0.5}
              textAlign={{ md: "left", xs: "left" }}
              mt={2}
              display={{ xs: "block", md: "block" }}
            >
              Delivery Address
            </Typography>
            <DeliveryAddress
              // name={data?.cart?.customerAddress?.name}
              // type={data?.cart?.customerAddress?.addressType}
              // houseNo={data?.cart?.customerAddress?.houseNo}
              // address={data?.cart?.customerAddress?.addressLine1}
              // city={data?.cart?.customerAddress?.city}
              // state={data?.cart?.customerAddress?.state}
              // country={data?.cart?.customerAddress?.country}
              // contactNumber={data?.cart?.customerAddress?.phoneNo + JSON.stringify(data?.cart?.customerAddress)}
              address={data?.cart?.customerAddress}
            />
            <Typography
              variant="h5"
              component="h3"
              fontSize={{ xs: 14, md: 15 }}
              color={headingColor}
              pb={0.5}
              textAlign={{ md: "left", xs: "left" }}
              mt={2}
              display={{ xs: "block", md: "block" }}
            >
              Delivery Method
            </Typography>
            <DeliveryMethod
              delivery={getOrderDeliveryTypeText(
                data?.cart?.deliveryType?.type,
                data?.cart?.deliveryType?.shipType
              )}
            />
            {((data?.cart?.deliveryType?.type === "ondc" &&
              orderStatus &&
              orderStatus !== "ordercreated" &&
              orderStatus !== "bordercanceled" &&
              orderStatus !== "cordercanceled" &&
              orderStatus !== "lordercanceled") ||
              orderStatus === "orderdelivered") && (
              <>
                <Typography
                  variant="h5"
                  component="h3"
                  color={{ md: headingColor, xs: blackColor }}
                  fontSize={{ xs: 14, md: 14 }}
                  textAlign={{ md: "left", xs: "left" }}
                  mt={1}
                >
                  Delivery Details
                </Typography>
                <ETADetails
                  deliveryETA={data?.cart?.shipmentInfo?.etaDeliveryTime}
                  deliveryDate={data?.cart?.shipmentInfo?.etaDeliveryDate}
                  orderDeliveredOn={data?.cart?.orderDeliveredOn}
                  orderDeliveredIn={
                    data?.cart?.orderDeliveredOn && data?.cart?.orderCreatedOn
                      ? getDifferentInTime(
                          data?.cart?.orderDeliveredOn,
                          data?.cart?.orderCreatedOn
                        )
                      : null
                  }
                  orderStatus={orderStatus}
                />
              </>
            )}
            {/* ) : null} */}
            {data?.cart?.paymentInfo?.paymentDetail?.paymentType && (
              <>
                {" "}
                <Typography
                  variant="h5"
                  component="h3"
                  color={{ md: headingColor, xs: blackColor }}
                  fontSize={{ xs: 14, md: 15 }}
                  textAlign={{ md: "left", xs: "left" }}
                  display={{ xs: "block", md: "block" }}
                  my={1}
                >
                  Payment Type
                </Typography>
                <PaymentType
                  paymentType={
                    data?.cart?.paymentInfo?.paymentDetail?.paymentType
                  }
                />
              </>
            )}

            {/* For Testing Only */}
            {/* <SummaryDataRow
            label='paymentType:'
            value={data?.cart?.paymentInfo?.paymentDetail?.paymentType}
          />
          <SummaryDataRow
            label='modeOfPayment:'
            value={data?.cart?.modeOfPayment}
          />
          <SummaryDataRow
            label='deliveryType.type:'
            value={data?.cart?.deliveryType?.type}
          />
          <SummaryDataRow
            label='deliveryType.shipType:'
            value={data?.cart?.deliveryType?.shipType}
          />
          <SummaryDataRow
            label='shipmentInfo.carrierName:'
            value={data?.cart?.shipmentInfo?.carrierName}
          />
          <SummaryDataRow
            label='discountDetails.discountType:'
            value={data?.cart?.discountDetails?.[0]?.discountType}
          />
          <SummaryDataRow
            label='discountDetails.discountAmount:'
            value={data?.cart?.discountDetails?.[0]?.discountAmount}
          />
          <SummaryDataRow
            label='deliveryDateRange:'
            value={data?.cart?.deliveryDateRange}
          /> */}
          </Grid>
        </Grid>
      ) : (
        <OrderDetailsMobileLoader />
      )}
    </Container>
  );
}

export default OrderDetailsContent;

const SummaryDataRow = ({ label, value, isValueGreen }) => {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      py={{ md: 0.2, xs: 0 }}
    >
      <Grid item>
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={{ md: "12.5px", xs: 12.5 }}
          color={blackColor}
          display="inline"
        >
          {label}
        </Typography>
      </Grid>

      <Grid item>
        <Typography
          variant="h6"
          component="p"
          fontSize={{ md: "12.5px", xs: 12.5 }}
          color={
            isValueGreen ? { md: greenColor04, xs: greenColor04 } : blackColor
          }
          display="inline"
        >
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};
const styles = {
  cartContainer: {
    // boxShadow: "0 4px 8px rgb(0 0 0 / 6%)",
    // px: 1.5,
    // pt: 0.5,
    // pb: 2,
    p: "4px 10px 16px 10px",
    borderRadius: "12px",
    //mt: 2,
    border: "1px solid" + borderLineColor,
  },
  actionBtn: (outline) => ({
    fontSize: "15px",
    fontWeight: 500,
    color: outline ? orangeColor01 : whiteColor,
    p: "7px 24px",
    mb: "10px !important",
    border: "1px solid " + orangeColor01,
    backgroundColor: outline ? whiteColor : orangeColor01,
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: outline ? orangeColor01 : whiteColor,
      color: outline ? whiteColor : orangeColor01,
    },
  }),
};

const getOrderStatus = (status) => {
  switch (status) {
    case "ordercreated":
      return "Created";

    case "orderapproved":
      return "Approved";

    case "ordershipped":
      return "Shipped";

    case "orderdelivered":
      return "Delivered";

    case "bordercanceled":
      return "Canceled";

    case "cordercanceled":
      return "Canceled";

    case "lordercanceled":
      return "Canceled";

    case "paycartcreated":
      return "Easy Cart Created";

    default:
      return "";
  }
};
