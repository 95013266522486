import { Box, Grid, Skeleton } from '@mui/material'
import React from 'react'

const CounponCardsMobileLoader = () => {
  return (
    <Box>
       <Skeleton variant="rounded" width="auto" height='10px' sx={{ m:'3px 0 15px !important' }} />

       <Skeleton variant="rounded" width="auto" height="140px" sx={{borderRadius:'7px'}} />

       <Skeleton variant="rounded" width="auto" height='9px' sx={{ m:'13px 0 0px !important' }} />
       <Skeleton variant="rounded" width="50%" height='9px' sx={{ m:'6px 0 15px !important' }} />
    </Box>
  )
}

export default CounponCardsMobileLoader