import { NavigateNextRounded } from '@mui/icons-material'
import {
  Box,
  Button,
  Typography,
  Breadcrumbs,
  useMediaQuery,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getActiveBusiness } from 'redux/header/headerSlice'
import {
  blackColor,
  borderCart,
  greenColor05,
  greyShade46,
  greyShade50,
  headingColor,
  orangeColor01,
  whiteColor,
} from "../../configs/styles/muiThemes";

function DiscountDeliveryBreadcrum({
  links,
  currentPage,
  setCurrentPage,
  itemCount,
  discount,
  totalPrice,
  onProceed,
  btn,
  isDisabled,
}) {
  const dispatch = useDispatch();
  const { businessDetails } = useSelector((state) => state.header);
  const businessName = sessionStorage.getItem("businessName");

  const [businessTitle, setBusinessTitle] = useState("");

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  useEffect(() => {
    const businessInfo = sessionStorage.getItem("businessInfo");
    if (!businessInfo) {
      dispatch(getActiveBusiness());
    } else {
      const data = JSON.parse(businessInfo);
      setBusinessTitle(data?.[0]?.businessName);
      console.log(data?.[0]?.businessName);
    }
  }, [businessDetails]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#f5f5f5",
          // display: { xs: 'none', md: 'flex' },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "39px",
        }}
        px={2}
        py="5px"
        borderRadius="6px"
        mb={{ xs: 1, md: 1.5 }}
      >
        <Breadcrumbs separator={<NavigateNextRounded fontSize="small" />}>
          <Typography
            key="1"
            color={headingColor}
            fontSize="14px"
            component={Link}
            to={`/${businessName}`}
          >
            {businessTitle.split(" ").slice(0, 2).join(" ")}
          </Typography>

          {matches
            ? links?.map((link, index) => {
                return (
                  <Typography
                    key="2"
                    // color={
                    //   index == links?.length - 1 ? orangeColor01 : headingColor
                    // }
                    color={headingColor}
                    fontSize="14px"
                    component={Link}
                    to={link?.disabled ? "" : `/${businessName}${link.to}`}
                  >
                    {link.label}
                  </Typography>
                );
              })
            : null}

          {matches ? (
            <Typography
              key="2"
              color={
                currentPage === "address-page"
                  ? headingColor
                  : { xs: orangeColor01, md: greyShade50 }
              }
              fontSize="14px"
              onClick={() => {
                setCurrentPage("discount");
              }}
              sx={{ cursor: currentPage === "address-page" ? "pointer" : "" }}
            >
              Discounts/Coupons
            </Typography>
          ) : null}

          {matches ? (
            currentPage === "address-page" ? (
              <Typography
                key="2"
                color={
                  currentPage === "address-page" ? orangeColor01 : headingColor
                }
                fontSize="14px"
              >
                Address & Delivery
              </Typography>
            ) : null
          ) : null}
        </Breadcrumbs>

        {/* <Button
          onClick={() => {}}
          sx={{
            textTransform: "uppercase",
            color: "primary",
            textDecorationLine: "underline",
            fontSize: "14px",
            fontWeight: 600,
          }}
          endIcon={<NavigateNextRounded />}
          component={Link}
          to={`/${businessName}/catalog`}
        >
          Shop More
        </Button> */}
        {itemCount > 0 ? (
          discount === "" ? (
            <Button
              onClick={() => {}}
              sx={{
                textTransform: "none",
                color: { md: orangeColor01, xs: blackColor },
                textDecorationLine: "underline",
                fontSize: { md: "14px", xs: 13 },
                fontWeight: { md: 600, xs: 500 },
                textTransform: "uppercase",
                p: "0",
                "&:hover": {
                  backgroundColor: whiteColor,
                  color: discount ? "#10847e" : orangeColor01,
                },
              }}
              endIcon={<NavigateNextRounded />}
              component={Link}
              to={`/${businessName}/catalog`}
            >
              Shop More
            </Button>
          ) : (
            <Box>
              <Typography
                variant="h6"
                color={greyShade46}
                fontSize="13px"
                fontWeight={400}
                display="inline"
                px={2}
                borderRight={borderCart}
              >
                {itemCount} items in cart
              </Typography>

              <Typography
                variant="h5"
                color={headingColor}
                fontSize="15px"
                fontWeight={600}
                display="inline"
                mx={2}
              >
                ₹ {Number(totalPrice).toFixed(2)}
              </Typography>

              {discount !== "address-page" && (
                <Button
                  component={!btn && Link}
                  to={
                    // discount === "discount"
                    //   ? `/${businessName}/discountDelivery`
                    //   : discount === "cart"
                    //   ? `/${businessName}/discountDelivery`
                    //   : `/${businessName}/Cart`
                    discount === "discount" || discount === "cart"
                      ? `/${businessName}/discountDelivery`
                      : `/${businessName}/Cart`
                  }
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    color: whiteColor,
                    p: "2.5px 20px",
                    border: discount
                      ? "2px solid " + greenColor05
                      : "2px solid " + orangeColor01,
                    bgcolor: discount ? greenColor05 : orangeColor01,
                    textTransform: "uppercase",
                    borderRadius: "5px",
                    "&:hover": {
                      backgroundColor: whiteColor,
                      color: discount ? greenColor05 : orangeColor01,
                    },
                  }}
                  variant="contained"
                  disableElevation
                  size="small"
                  disabled={isDisabled}
                  onClick={() => {
                    switch (discount) {
                      case "discount":
                        setCurrentPage("address-page");
                        break;
                      case "cart":
                        onProceed();
                        break;
                      default:
                        // You can handle any other default behavior here if needed
                        break;
                    }
                  }}
                >
                  {discount === "cart"
                    ? "Proceed To Checkout"
                    : discount === "discount"
                    ? " Continue Checkout"
                    : "GO TO CART"}
                </Button>
              )}
            </Box>
          )
        ) : null}
      </Box>
    </>
  );
}

export default DiscountDeliveryBreadcrum
