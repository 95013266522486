import { useState } from 'react'
import GoogleMapReact from 'google-map-react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import { GOOGLE_MAPS_API_KEY } from '../../configs/Constants'
import ModalCard from '../ModalCard'
import {
  blackColor,
  greenColor,
  greyColor8,
  headingColor,
  whiteColor,
  yellowColor,
} from '../../configs/styles/muiThemes'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { useEffect } from 'react'
import { fetchAddressFromLatLng } from '../../utils/geoLocationHelper'
import AlertDialog from 'components/AlertDialog'

const PickAddressFromMapModal = ({
  isOpen,
  setIsOpen,
  onConfirm,
  userLat,
  userLong,
}) => {
  // const userLat = localStorage.getItem("lat");
  // const userLong = localStorage.getItem("long");

  const [alertOpen, setAlertOpen] = useState(false)

  const [mapCenter, setMapCenter] = useState({
    lat: 13,
    lng: 77.5946,
  })
  const [userAddress, setUserAddress] = useState('')

  const handleMapChange = async ({ center }) => {
    console.log(center)
    setMapCenter(center)
    const addressInfo = await fetchAddressFromLatLng(center?.lat, center?.lng)
    setUserAddress(addressInfo)
  }

  const handleConfirmAddress = () => {
    setAlertOpen(true)
  }

  useEffect(() => {
    setMapCenter({
      lat: Number(userLat),
      lng: Number(userLong),
    })
    return () => {}
  }, [isOpen])

  return (
    <ModalCard
      open={isOpen}
      handleClose={() => {
        setIsOpen({
          show: false,
          userLat: '',
          userLong: '',
        })
      }}
      width='42%'
    >
      <Box sx={styles.card}>
        <Typography
          variant='h6'
          fontSize={{xs:15,md:16}}
          fontWeight={500}
          p={1.5}
          textAlign='center'
          lineHeight={"22px"}
        >
          {/* {JSON.stringify(mapCenter)} --- {userLat} ,{" "}
          {userLong} */}
          Please choose the exact location of the building from the map, for a
          smooth delivery
        </Typography>

        <Box sx={styles.addressBox}>
          <Typography
            variant='body2'
            fontSize={12}
            fontWeight={500}
            color={whiteColor}
          >
            {userAddress?.fullAddress}
          </Typography>
        </Box>

        <Box sx={{ width: '100%', height: '400px' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
            center={mapCenter}
            // defaultZoom={17}
            defaultZoom={18}
            onChange={handleMapChange}
          >
            <Marker lat={mapCenter.lat} lng={mapCenter.lng} />
          </GoogleMapReact>
        </Box>

        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          gap='20px'
          p='15px'
        >
          <Button
            sx={[styles.actionBtn, styles.cancelBtn]}
            onClick={() => {
              setIsOpen({
                show: false,
                userLat: '',
                userLong: '',
              })
            }}
          >
            CANCEL
          </Button>

          <Button
            sx={[styles.actionBtn, styles.proceedBtn]}
            onClick={handleConfirmAddress}
          >
            Confirm
          </Button>
        </Box>

        <AlertPopup
          content={'Is this the exact GPS location of your building ?'}
          isOpen={alertOpen}
          onClose={() => {
            setAlertOpen(false)
          }}
          onConfirm={() => {
            setAlertOpen(false)
            onConfirm(userAddress)
          }}
        />
      </Box>
    </ModalCard>
  )
}

const Marker = () => (
  <Box>
    <LocationOnIcon sx={{ fontSize: '36px', color: 'red' }} />
  </Box>
)

export default PickAddressFromMapModal

const AlertPopup = ({ content, onClose, onConfirm, isOpen }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          // padding: 1,
          // width: "100vw",
          textAlign: "center",
          borderRadius: "10px",
        },
      }}
    >
      <Box
        sx={{
          borderRadius: "15px 15px 0px 0px",
          width: "90%",
          padding: "10px 15px",
          textAlign: "center",
          background: "#f5f6f8",
          // display: "flex",
          // justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h2"
          textAlign="center"
          fontSize={16}
          color={headingColor}
          pb={{ md: 1.5, xs: 0.7 }}
        >
          Alert
        </Typography>
        {/* <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton> */}
      </Box>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            px: 2,
            color: blackColor,
            border: `1px solid ${blackColor}`,
            mr: "15px",
            textTransform: "uppercase",
            "&:hover": {
              backgroundColor: blackColor,
              color: whiteColor,
            },
          }}
        >
          NO
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          disableElevation
          sx={{
            px: 2,
            color: whiteColor,
            border: `1px solid ${blackColor}`,
            backgroundColor: blackColor,
            textTransform: "uppercase",
            "&:hover": {
              backgroundColor: whiteColor,
              color: blackColor,
            },
          }}
        >
          YES
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: "10px",
    overflow: "hidden",
  },

  addressBox: {
    backgroundColor: greenColor,
    p: "4px 15px",
    textAlign: "center",
  },

  actionBtn: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "22px",
    color: greyColor8,
    border: `1px solid ${greyColor8}`,
    p: "5px 55px",
    textTransform: "uppercase",
  },

  proceedBtn: {
    color: whiteColor,
    background: blackColor,
    border: `1px solid ${blackColor}`,
    textTransform: "uppercase",
    "&:hover": {
      color: blackColor,
      background: whiteColor,
    },
  },

  cancelBtn: {
    color: blackColor,
    background: whiteColor,
    p: "5px 55px",
    border: `1px solid ${blackColor}`,
    textTransform: "uppercase",
    "&:hover": {
      color: whiteColor,
      background: blackColor,
      // border: `1px solid ${greyColor8}`,
    },
  },
};
