import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import {
  backgroundColor01,
  headingColor,
  minOrderTextColor,
  whiteColor,
} from "configs/styles/muiThemes";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getCartDetails,
  getCartDetailsById,
  getCommercialDetails,
} from "redux/addToCart/AddToCartSlice";
import {
  checkBusinessResellerDetails,
  firstTimeDiscountDetails,
  getAddressDetails,
  getRewardPointsDetails,
  viewActiveBusinessDetails,
} from "redux/checkout/CheckoutSlice";
import { getAccountDetails } from "redux/dashboard/dashboardSlice";
import {
  getAllInstantDiscount,
  getCoupons,
} from "redux/dealsCoupons/dealsCouponsSlice";
import { getOverviewDetails } from "redux/overview/overviewSlice";
import { getUserName } from "utils/authHelpers";
import FooterMui from "../components/FooterMui";
import DiscountDeliveryBreadcrum from "./DiscountDelivery/DiscountDeliveryBreadcrum";
import DiscountDeliveryContent from "./DiscountDelivery/DiscountDeliveryContent";
import TopComponent from "./TopComponent";
import BreadcrumbsSection from "components/Breadcrumbs";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import BreadcrumbNew from "components/BreadcrumbNew";
import { getCatalogAllProducts } from "redux/subCatergory/subCategorySlice";

function DiscountDelivery() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const dispatch = useDispatch();
  const { businessName } = useParams();
  let navigation = useNavigate();
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(
    location?.state?.currentPage || "discount"
  );
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  };

  const {
    addressDetails,
    addressLoader,
    rewardpoints,
    firstTimeDiscountData,
    checkResellerData,
    activeBusinessData,
  } = useSelector((state) => state.checkout);
  const { accountDetails } = useSelector((state) => state.dashboard);
  const { dealsCouponsDetails, instantDiscount, couponsLoading } = useSelector(
    (state) => state.coupons
  );
  const {
    updateQty,
    checkOrderProducts,
    updateCartRes,
    updatePriceRes,
    localCartData,
  } = useSelector((state) => state.cartupdateadd);
  const { businessId } = useSelector((state) => state.rootSlice);
  const cartId = null;
  const { cartDetails, commercialDetails } = useSelector((state) => state.cart);
  const { overviewDetails } = useSelector((state) => state.overview);

  const isLoggedIn = getUserName().length === 0 ? false : true;

  const [totalQty, setTotalQty] = useState(0);
  const [cartProductList, setCartProductList] = useState(null);

  const getCartItemCount = () => {
    let count = 0;
    const cartProductData = JSON.parse(sessionStorage.getItem("cartData"));
    cartProductData?.productList?.forEach((item) => {
      count = count + item.currentQuantity;
    });

    // const cartProductData = JSON.parse(sessionStorage.getItem('cartData'))
    setTotalQty(count);
    setCartProductList(cartProductData);
  };
  useEffect(() => {
    getCartItemCount();
  }, [cartDetails, updateQty]);
  useEffect(() => {
    if (localCartData) {
      setCartProductList(localCartData);
      setTotalQty(localCartData?.totalQuantity);
    }
  }, [localCartData]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigation(`/${businessName}`);
    }
  }, []);

  useEffect(() => {
    dispatch(getAccountDetails());
    dispatch(getRewardPointsDetails());
    dispatch(getCoupons());
    dispatch(getCartDetails());
    dispatch(getCommercialDetails());
    dispatch(firstTimeDiscountDetails());
    dispatch(checkBusinessResellerDetails());
    dispatch(getAllInstantDiscount());
    dispatch(viewActiveBusinessDetails());
    dispatch(getOverviewDetails());
  }, []);

  useEffect(() => {
    if (activeBusinessData) {
      dispatch(getAddressDetails());
    }
  }, [activeBusinessData]);

  useEffect(() => {
    if (businessId?.validate) {
      console.log("Valid_BusinessID");
      if (cartId) {
        dispatch(getCartDetailsById(cartId));
      } else {
        if (isLoggedIn) {
          dispatch(getCartDetails(businessId));
        }
      }

      dispatch(getCommercialDetails());
      dispatch(getCatalogAllProducts());
    }
  }, [businessId]);

  return (
    <Box
      backgroundColor={{ md: backgroundColor01, xs: backgroundColor01 }}
      // width={"100vw"}
    >
      {/* {matches && (
        <Box
          sx={{
            // position: { md: 'sticky', xs: 'static' },
            position: "sticky",
            top: 0,
            width: "100%",
            zIndex: 10,
            backgroundColor: backgroundColor01,
            pb: 1.5,
          }}
        >
          {/* <Navbar value='' /> 
          <TopComponent value="" disableBanners />
          <Container
            sx={{
              maxWidth: "1370px !important",
              mt: { xs: 1.8, md: 2 },
            }}
          >
            <BreadcrumbNew
              links={[
                // { label: "Home", to: `/` },
                { label: "Catalog", to: `/catalog` },
                { label: "Cart", to: `/cart` },
                // currentPage === "discount"
                //   ? { label: "Discounts/Coupons", to: `/DiscountDelivery` }
                //   : ({ label: "Discounts/Coupons", to: `/DiscountDelivery` },
                //     { label: "Address & Delivery", to: `/DiscountDelivery` }),
              ]}
              itemCount={totalQty}
              totalPrice={
                cartProductList ? cartProductList?.orderTotalProductCost : 0
              }
              discount={currentPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
            {console.log(
              "cartDetails",
              cartDetails,
              currentPage,
              totalQty,
              cartProductList?.orderTotalProductCost
            )}

            {/* <CatalogHeader
                onFilterClick={() => {
                  setShowFilters(true)
                }}
                itemCount={totalQty}
                totalPrice={
                  cartProductList ? cartProductList.orderTotalProductCost : 0
                }
                category={() => {
                  if (
                    category?.length === 1 &&
                    category?.[0]?.subCat?.length === 1
                  ) {
                    handleProductCatClick(category?.[0]?.subCat?.[0])
                    return
                  }

                  setFilterProducts({ products: [], title: '' })
                  setFilteredSubCat([])
                  setViewByFilter('category')
                }}
                products={() => {
                  setFilterProducts({ products: [], title: '' })
                  setFilteredSubCat([])
                  setViewByFilter('all-products')
                }}
                viewByFilter={viewByFilter}
                handleViewByCategoryClick={handleViewByCategoryClick}
                handleViewAllProducts={handleViewAllProducts}
                // All Products Filter props
                displaySelection={sort}
                totalProduct={allProducts.totalProduct}
                onSort={handleAllProductSort}
              /> 
          </Container>
        </Box>
      )} */}

      <Box
        display={{ xs: "block", md: "none" }}
        sx={{
          position: "sticky !important",
          top: 0,
          width: "100%",
          zIndex: 10,
          backgroundColor: whiteColor,
          pt: 0.3,
        }}
      >
        {/* <DiscountHeaderBreadcrumbs
          DiscountHeaderBreadcrumbs
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        /> */}
        <MobileDiscountDeliveryHeaderBreadcrums
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Box>

      <DiscountDeliveryContent
        addressDetails={addressDetails}
        addressLoader={addressLoader}
        accountDetails={accountDetails}
        rewardpoints={rewardpoints}
        dealsCouponsDetails={dealsCouponsDetails}
        cartDetails={cartDetails}
        commercialDetails={commercialDetails}
        firstTimeDiscountData={firstTimeDiscountData}
        checkResellerData={checkResellerData}
        instantDiscounts={instantDiscount?.details}
        activeBusinessData={activeBusinessData}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        couponsLoading={couponsLoading}
        overviewDetails={overviewDetails}
        cartProductList={cartProductList}
      />
      {matches && (
        <Box
          component="a"
          onClick={scrollToTop}
          sx={{
            position: "fixed",
            width: "42px",
            height: "42px",
            color: "#fff",
            right: "10px",
            bottom: "30px",
            borderRadius: "80px",
            textAlign: "center",
            textDecoration: "none",
            overflow: "hidden",
            zIndex: 999,
            border: "0",
            transitionDuration: "0.2s",
            backgroundColor: "#E56B46",
            borderColor: "#E56B46",
            borderWidth: "1px",
            borderStyle: "solid",
            cursor: "pointer",
            "&:hover": {
              // backgroundColor: '#d65f3d', // Optional hover color
              backgroundColor: whiteColor,
              color: "#E56B46",
            },
          }}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <ArrowUpwardIcon
          // sx={{ color: whiteColor, fontSize: '20px', lineHeight: '40px','&:hover': {
          //   // backgroundColor: '#d65f3d', // Optional hover color
          //   backgroundColor: whiteColor,
          //   color: '#E56B46',
          // } }}
          />
        </Box>
      )}
      {matches && <FooterMui />}
    </Box>
  );
}

export default DiscountDelivery;

const DiscountHeaderBreadcrumbs = ({
  currentPage,
  setCurrentPage,
  itemCount,
  discount,
  totalPrice,
}) => {
  return (
    <Container
      sx={{ maxWidth: "1300px !important", mt: { xs: 0.5, md: 2.5 } }}
      backgroundColor={whiteColor}
    >
      <DiscountDeliveryBreadcrum
        links={[
          { label: "Catalog", to: `/catalog`, hidden: false },
          { label: "Cart", to: `/cart`, hidden: false },
        ]}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPrice={totalPrice}
        itemCount={itemCount}
        discount={discount}
        //        onProceed
        // btn
        // isDisabled
      />

      <Typography
        variant="subtitle1"
        display={"inline"}
        component="h5"
        fontSize={{ xs: 15, md: 19 }}
        fontWeight={600}
        color={headingColor}
        mt={0.3}
        ml={0.1}
        py={0.3}
      >
        {currentPage === "discount"
          ? "Discounts/Coupons"
          : "Address & Delivery"}{" "}
        <Typography
          display={"inline"}
          variant="subtitle1"
          component="h5"
          fontSize={{ xs: 13, md: 15 }}
          fontWeight={500}
          color={minOrderTextColor}
        >
          {currentPage === "discount" ? "(Optional)" : ""}
        </Typography>
      </Typography>
    </Container>
  );
};

const MobileDiscountDeliveryHeaderBreadcrums = ({
  currentPage,
  setCurrentPage,
}) => {
  return (
    <Container
      sx={{
        maxWidth: "1350px !important",
        mt: { xs: 0, md: 2.5 },
        p: 0,
      }}
    >
      <BreadcrumbsSection
        links={[
          { label: "Catalog", to: `/catalog` },
          { label: "Cart", to: `/cart` },
        ]}
        detail={
          currentPage === "address-page"
            ? "Address & Delivery"
            : "Discounts/Coupons"
        }
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </Container>
  );
};

