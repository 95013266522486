import { Box, Container, Skeleton } from '@mui/material'
import React from 'react'

const DashboardMobileLoader = () => {
  return (
    <Box>
        <Skeleton variant="rounded" width="auto" height='34px' sx={{ m:'0 0 24px !important' }} />
        
        <Container sx={{ maxWidth: "1370px !important", mb:8 }}>
           <Box>
                <Skeleton variant="rounded" width="100px" height='13px' sx={{ m:'8px 0 14px !important' }} />
                <Skeleton variant="rounded" width="100%" height='10px' sx={{ m:'8px 0 8px !important' }} />
                <Skeleton variant="rounded" width="100%" height='10px' sx={{ m:'8px 0 8px !important' }} />
                <Skeleton variant="rounded" width="30%" height='10px' sx={{ m:'8px 0 8px !important' }} />
            </Box>

            <Box display='flex' alignItems='center' gap={2}>
                <Skeleton variant="rounded" width="100%" height='60px' sx={{ m:'8px 0 14px !important' }} />
                <Skeleton variant="rounded" width="100%" height='60px' sx={{ m:'8px 0 14px !important' }} />
            </Box>

            <Skeleton variant="rounded" width="100%" height='60px' sx={{ m:'0px 0 14px !important' }} />
           
            <Skeleton variant="rounded" width="50%" height='14px' sx={{ m:'0 0 24px !important' }} />
            <Skeleton variant="rounded" width="100%" height='300px' sx={{ m:'0 0 24px !important' }} />
        </Container>
    </Box>
  )
}

export default DashboardMobileLoader