import React from 'react'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import { keyframes } from '@emotion/react'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Box } from '@mui/material'
import {  orangeColor01, whiteColor } from 'configs/styles/muiThemes'

function ScrollIndicator({ show }) {
  return (
    <Box sx={styles.iconContainer}>
      <Box sx={styles.arrowAnim}>
        <KeyboardArrowRightIcon
          sx={{ ...styles.arrowIcon, ...styles.arrowIconAnim('0s') }}
        />
        <KeyboardArrowRightIcon
          sx={{ ...styles.arrowIcon, ...styles.arrowIconAnim('0.66s') }}
        />
        <KeyboardArrowRightIcon
          sx={{ ...styles.arrowIcon, ...styles.arrowIconAnim('1.33s') }}
        />
      </Box>
    </Box>
  )
}

export default ScrollIndicator

const scrollIconAnim = keyframes`
0%,
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`

const styles = {
  iconContainer: {
    width: "45px",
    height: "45px",
    // backgroundColor: '#ffefeb',
    borderRadius: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  arrowAnim: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ml: "-20px",
  },

  arrowIcon: {
    fontSize: "30px",
    color: orangeColor01,
    mr: "-20px",
    opacity: 0,
  },

  arrowIconAnim: (delay) => ({
    "-webkit-animation": `${scrollIconAnim} 2s infinite ease-in-out`,
    animation: `${scrollIconAnim} 2s infinite ease-in-out`,
    "animation-delay": delay,
  }),
};
