import {
  Box,
  useMediaQuery,
} from '@mui/material'
import LandingPageFooter from './LandingPage/LandingPageFooter'
import HeaderSection from './LandingPage/HeaderSection'
import InfoSection from './LandingPage/InfoSection'
import CostBoInfluencerCard from './LandingPage/CostBoInfluencerCard'
import { useNavigate } from 'react-router-dom'
import { backgroundColor01, whiteColor } from 'configs/styles/muiThemes'
import HeroSection from './LandingPage/HeroSection'
import FooterMui from 'components/FooterMui'

function ShopCostBoHome() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))

  return (
    <Box backgroundColor={backgroundColor01}>
      <HeaderSection />
      <HeroSection />
      <Box backgroundColor={whiteColor}>
        <InfoSection />
        <CostBoInfluencerCard />
      </Box>

      {/* Footer */}
      {/* <LandingPageFooter /> */}
      {matches&&<FooterMui hidesTerms/>}
    </Box>
  )
}

export default ShopCostBoHome