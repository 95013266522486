import { Box, Typography, Divider, useMediaQuery } from '@mui/material'
import ModalCard from '../../../components/ModalCard'
import EditReviewForm from '../AddReviewForm'
import { blackColor, headingColor, whiteColor } from 'configs/styles/muiThemes'

/**
 * Edit Existing Personal Reviews dialog
 * @param ownReview
 * @param open
 * @param onClose
 * @param getOwnReviewDetails
 * @param getSummary
 *  @author Rupesh
 */
const EditReview = ({
  ownReview,
  open,
  getOwnReviewDetails,
  getSummary,
  onClose,
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const handleUpdateReviewClose = () => {
    getOwnReviewDetails()
    getSummary()
    onClose()
  }

  return matches ? (
    <Box sx={{ backgroundColor: whiteColor, borderRadius: '10px',mb:1 }}>
      <Typography
        variant='h4'
        fontSize={15}
        fontWeight={600}
        color={blackColor}
        textAlign='center'
      >
        Update your Review
      </Typography>

      <EditReviewForm
        isUpdate={true}
        ownReview={ownReview}
        onClose={() => onClose()}
        getOwnReviewDetails={() => getOwnReviewDetails()}
        getSummary={() => getSummary()}
      />
    </Box>
  ) : (
    <ModalCard handleClose={handleUpdateReviewClose} open={open}>
      <Box sx={{ backgroundColor: whiteColor, borderRadius: '10px' }}>
        <Typography
          variant='h4'
          color={headingColor}
          textAlign='center'
          paddingY={2}
        >
          Update your Review
        </Typography>
        <Divider />

        <EditReviewForm
          styles={{ p: 3 }}
          isUpdate={true}
          ownReview={ownReview}
          onClose={() => onClose()}
          getOwnReviewDetails={() => getOwnReviewDetails()}
          getSummary={() => getSummary()}
        />
      </Box>
    </ModalCard>
  )
}

export default EditReview
