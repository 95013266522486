import { Box, Button, Grid, Radio, TextField, Typography, useMediaQuery } from '@mui/material'
import { discountCard_CashbackAmtError, discountCard_CashbackAmtGreaterErr, discountCard_CongratsMsg, discountCard_RedeemAmtGreaterErr, discountCard_RedeemAmtGreaterOrderAmtErr } from 'configs/Constants'
import React, { useEffect, useState } from 'react'
import { cashbackDiscountDataHelper } from 'utils/checkoutHelpers'
import {
  borderLineColor,
  headingColor,
  savingTextColor,
  whiteColor,
  errorTextColor,
  discountBgColor,
  greenColor01,
  greenColor05,
  blackColor,
} from '../../../configs/styles/muiThemes'
import { allowOnlyEnglish } from 'utils/utilities'

function CashbackCard({
  isSelected,
  onSelect,
  rewardPoints,
  cartDetails,
  commercialDetails,
  discountAmount,
  setDiscountAmount,
  setAppliedDiscountDetails,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const [amount, setAmount] = useState()
  const [claimId, setClaimId] = useState('')
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  let businessFullName = sessionStorage.getItem('businessFullName')

  useEffect(() => {
    if (!isSelected) {
      setDiscountAmount(0)
      setAppliedDiscountDetails(null)
      setIsError(false)
      setIsSuccess(false)
      setErrorMessage('')
      setAmount('')
      setClaimId('')
    }
  }, [isSelected])

  const applyDiscount = () => {
    setIsSuccess(false)
    setIsError(false)
    if (!amount) {
      setIsError(true)
      setErrorMessage(discountCard_CashbackAmtError)
      return
    }
    if (amount < 0) {
      setIsError(true)
      setErrorMessage(discountCard_CashbackAmtGreaterErr)
      return
    }
    if (amount > rewardPoints?.details?.[0]?.availablePointValue) {
      setIsError(true)
      setErrorMessage(
        discountCard_RedeemAmtGreaterErr
      )
      return
    }
    if (amount > cartDetails?.orderTotalProductCost) {
      setIsError(true)
      setErrorMessage(discountCard_RedeemAmtGreaterOrderAmtErr)
      return
    }
    if (amount > commercialDetails?.info?.referralPointsMaxPerTxn) {
      setIsError(true)
      setErrorMessage(
        `Maximum applicable amount per order is ₹${commercialDetails?.info?.referralPointsMaxPerTxn}`
      )
      return
    }
    setIsError(false)
    setIsSuccess(true)
    setDiscountAmount(amount)
    setAppliedDiscountDetails(
      cashbackDiscountDataHelper(amount, cartDetails, rewardPoints)
    )
  }

  return (
    //   matches ? (
    //   <Box sx={styles.discountCard(isSelected)}>
    //     <Grid
    //       container
    //       direction='row'
    //       alignItems='center'
    //       justifyContent='space-between'
    //     >
    //       <Grid item>
    //         <Radio
    //           checked={isSelected}
    //           onChange={() => {
    //             onSelect()
    //           }}
    //           value='a'
    //           name='radio-buttons'
    //           inputProps={{ 'aria-label': 'A' }}
    //           size='small'
    //           sx={{ width: 35, height: 30 }}
    //         />
    //         <Typography
    //           variant='h6'
    //           component='p'
    //           fontSize={{ xs: 13.5, ms: 14 }}
    //           color={headingColor}
    //           display='inline'
    //         >
    //           Cashback{' '}
    //           <Typography
    //             variant='h6'
    //             component='p'
    //             fontSize={{ xs: 13.5, ms: 14 }}
    //             color={headingColor}
    //             display={{ md: 'inline', xs: 'none' }}
    //           >
    //             from Wallet
    //           </Typography>{' '}
    //           - ₹{rewardPoints?.details?.[0]?.availablePointValue?.toFixed(2)}
    //         </Typography>
    //       </Grid>

    //       <Grid item>
    //         <Box
    //           component='img'
    //           src='https://storage.googleapis.com/bodefaults/shopweb/discounts_wallet.svg'
    //           alt={businessFullName + ' CostBo'}
    //           width={{ xs: '48px', md: '46px' }}
    //           height={{ xs: '32px', md: '30px' }}
    //         />
    //       </Grid>
    //     </Grid>

    //     <Box mx={1.3} mt={{ xs: 1, md: 1.3 }} mb={{ xs: 0, md: 0.5 }}>
    //       <Grid container spacing={{ xs: 1, md: 2 }}>
    //         <Grid item xs={8}>
    //           <TextField
    //             value={amount}
    //             type='number'
    //             variant='standard'
    //             onChange={({ target }) => {
    //               if (isSuccess) {
    //                 setIsSuccess(false)
    //                 setDiscountAmount(0)
    //                 setAmount('')
    //               }

    //               const finalValue = allowOnlyEnglish(target.value)
    //               setAmount(finalValue)
    //             }}
    //             placeholder={`Max limit per order ₹${commercialDetails?.info?.referralPointsMaxPerTxn}`}
    //             sx={styles.inputBox(isError)}
    //             fullWidth
    //             disabled={!isSelected}
    //             InputProps={{
    //               disableUnderline: true,
    //               color: 'red',
    //               inputProps: { min: 0 },
    //             }}
    //           />
    //         </Grid>
    //         {isSelected && (
    //           <Grid item xs={3} ml={2}>
    //             <Button
    //               sx={styles.actionBtn}
    //               variant='contained'
    //               disableElevation
    //               onClick={() => applyDiscount()}
    //             >
    //               Apply
    //             </Button>
    //           </Grid>
    //         )}
    //       </Grid>
    //     </Box>

    //     {/* Error and Success Message */}
    //     {isError ? (
    //       <Typography
    //         variant='subtitle1'
    //         component='p'
    //         fontSize={{ xs: '10px', md: '12px' }}
    //         color={errorTextColor}
    //         mx={1.4}
    //         mt={{ xs: 0.2, md: 0 }}
    //         lineHeight='14px'
    //       >
    //         {errorMessage}
    //       </Typography>
    //     ) : null}
    //     {isSuccess ? (
    //       <Typography
    //         variant='subtitle1'
    //         component='p'
    //         fontSize={{ xs: '11px', md: '12px' }}
    //         color={savingTextColor}
    //         mx={1.4}
    //         mt={{ xs: 0.3, md: 0 }}
    //       >
    //         {discountCard_CongratsMsg}
    //         {amount}
    //       </Typography>
    //     ) : null}
    //   </Box>
    // ) : (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "auto",
      }}
    >
      {/* Background Image */}
      <Box
        component="img"
        src="https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/subtract.png" // Replace with your image path
        alt="Background"
        sx={{
          width: "100%",
          height: {md:"auto",xs:"107px"},
          objectFit: "cover",
        }}
      />

      {/* Overlayed Code */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "90%",
          height: "100%",
          padding: 1,
        }}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          borderBottom={`1px dashed #d5d5e6`}
          pb={1}
        >
          <Grid item>
            <Radio
              checked={isSelected}
              onChange={() => {
                onSelect();
              }}
              value="a"
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
              size="small"
              sx={{
                width: 35,
                height: 30,
                color: blackColor, // default color
                "&.Mui-checked": {
                  color: blackColor, // color when checked
                },
              }}
            />
            <Typography
              variant="h6"
              component="p"
              fontSize={{ xs: 13.5, md: 12.5 }}
              color={headingColor}
              display="inline"
            >
              Cashback{" "}
              <Typography
                variant="h6"
                component="p"
                fontSize={{ xs: 12.5, md: 12.5 }}
                color={headingColor}
                display={{ md: "inline", xs: "none" }}
              >
                from Wallet
              </Typography>{" "}
              - ₹{rewardPoints?.details?.[0]?.availablePointValue?.toFixed(2)}
            </Typography>
          </Grid>

          <Grid item>
            <Box
              component="img"
              src="https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/discount.svg"
              // src='https://storage.googleapis.com/bodefaults/shopweb/discounts_wallet.svg'
              alt={businessFullName + " CostBo"}
              width={{ xs: "25px", md: "25px" }}
              height={{ xs: "25px", md: "25px" }}
            />
          </Grid>
        </Grid>

        <Box mx={1.3} mt={{ xs: 1, md: 1.3 }} mb={{ xs: 0, md: 0.5 }}>
          <Grid container spacing={{ xs: 1, md: 2 }}>
            <Grid item xs={8.3}>
              <TextField
                value={amount}
                type="number"
                variant="standard"
                onChange={({ target }) => {
                  if (isSuccess) {
                    setIsSuccess(false);
                    setDiscountAmount(0);
                    setAmount("");
                  }

                  const finalValue = allowOnlyEnglish(target.value);
                  setAmount(finalValue);
                }}
                placeholder={`Max limit per order ₹${commercialDetails?.info?.referralPointsMaxPerTxn}`}
                sx={styles.inputBox(isError)}
                fullWidth
                disabled={!isSelected}
                InputProps={{
                  disableUnderline: true,
                  color: "red",
                  inputProps: { min: 0 },
                }}
              />
            </Grid>
            {isSelected && (
              <Grid item xs={3} ml={2}>
                <Button
                  sx={styles.actionBtn}
                  variant="contained"
                  disableElevation
                  onClick={() => applyDiscount()}
                >
                  Apply
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>

        {/* Error and Success Message */}
        {isError ? (
          <Typography
            variant="subtitle1"
            component="p"
            fontSize={{ xs: "10px", md: "12px" }}
            color={errorTextColor}
            mx={1.4}
            mt={{ xs: 0.2, md: 1 }}
            lineHeight="14px"
          >
            {errorMessage}
          </Typography>
        ) : null}
        {isSuccess ? (
          <Typography
            variant="subtitle1"
            component="p"
            fontSize={{ xs: "11px", md: "12px" }}
            color={{ md: greenColor05, xs: greenColor01 }}
            mx={1.4}
            mt={{ xs: 0.3, md: 0 }}
          >
            {discountCard_CongratsMsg}
            {amount}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
}

export default CashbackCard

const styles = {
  discountCard: (isSelected) => ({
    backgroundColor: isSelected ? discountBgColor : whiteColor,
    borderRadius: "6px",
    border: "1px solid" + borderLineColor,
    px: 0.5,
    py: 1,
    height: { xs: "112px", md: "125px" },
    pr: 2,
    minWidth: "100%",
  }),
  discountCardMobile: (isSelected) => ({
    width: "100%",
    backgroundColor: whiteColor,
    // backgroundColor: isSelected ? discountBgColor : whiteColor,
    borderRadius: "6px",
    border: { md: "1px solid" + borderLineColor, xs: "none" },
    px: 0.5,
    // py: 1,
    height: { xs: "118px", md: "125px" },
    pr: 2,
  }),

  inputBox: (isError) => ({
    height: { xs: "30px", md: "25px" },
    fontSize: { xs: "12px", md: "13px" },
    fontWeight: 400,
    color: { md: greenColor05, xs: greenColor01 },
    border: { xs: "1.5px dashed #299956", md: `1.5px dashed ${greenColor05}` },
    backgroundColor: { md: whiteColor, xs: "#f6fdfb" },
    borderRadius: { xs: "4px", md: "6px" },
    py: { xs: 0.2, md: 0.5 },
    px: 1,
    "&:hover": {
      backgroundColor: { md: whiteColor, xs: "#f6fdfb" },
    },
  }),

  actionBtn: {
    height: { xs: "33px", md: "35 .8px" },
    fontSize: { xs: "13px", md: "14px" },
    fontWeight: 600,
    color: { md: whiteColor, xs: whiteColor },
    backgroundColor: { md: greenColor05, xs: greenColor01 },
    px: { xs: "20px", md: "28px" },
    border: {
      md: "1px solid " + greenColor05,
      xs: "1px solid " + greenColor01,
    },
    borderRadius: { xs: "4px", md: "6px" },
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: { md: whiteColor, xs: whiteColor },
      color: { md: greenColor05, xs: greenColor01 },
    },
  },
};
