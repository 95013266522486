import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Rating,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { StarRounded } from "@mui/icons-material";
import moment from "moment";
import {
  arrowBgColor,
  backgroundColor02,
  blackColor,
  greyColor9,
  headingColor,
  orangeColor01,
  reviewBodyColor,
  reviewCardBorderColor,
  reviewSubColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import UserCharAvatar from "./UserCharAvatar";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import SingleStarRatingDisplay from "./SingleStarRatingDisplay";

// Card for review contnets display
function ReviewCard(props) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const contentLength = matches ? 130 : 90;

  // const [ishelpFul, setIsHelpFul] = useState(false)
  // const [report, setReport] = useState(false)

  // /**
  //  * To update helpful reviews
  //  * @param reviewId
  //  */
  // const isHelpfulClicked = (reviewId, profileId) => {
  //   // for ui update
  //   setIsHelpFul(true)

  //   helpFulReview(reviewId, profileId)
  //     .then((res) => {
  //       props.getAllReviewDetails()
  //     })
  //     .catch((err) => {
  //     })
  // }

  // /**
  //  * To update abused reviews
  //  * @param reviewId
  //  */
  // const reportAbuseClicked = (reviewId) => {
  //   // for ui update
  //   setReport(true)

  //   reportAbuse(reviewId)
  //     .then((res) => {
  //       props.getAllReviewDetails()
  //     })
  //     .catch((err) => {})
  // }

  return matches ? (
    <Box
      sx={{
        p: "15px",
        textAlign: "left",
        borderRadius: "10px",
        border: "1px solid " + reviewCardBorderColor,
        backgroundColor: whiteColor,
        height: "100%",
      }}
    >
      <Box display={"flex"} justifyContent={"space-between"} gap={1}>
        <Box display={"flex"} gap={1}>
          {props?.images?.[0]?.photoUrl ? (
            <Avatar
              sx={{
                width: { md: 40, xs: "38px" },
                height: { md: 40, xs: "38px" },
              }}
              alt="customer profile"
              src={
                props?.showFullData
                  ? props?.data?.profilePic
                  : props?.images?.[0]?.photoUrl
                  ? props?.images?.[0]?.photoUrl
                  : "https://storage.googleapis.com/bodefaults/shopweb/reviews/review-user.svg"
              }
            />
          ) : (
            <UserCharAvatar
              username={props.data.name}
              imgwidth="38px"
              imgheight="38px"
            />
          )}

          <Box>
            <Typography
              variant="body1"
              fontSize={13}
              fontWeight={600}
              color={blackColor}
              lineHeight="22px"
            >
              {props.data.name}
            </Typography>
            <Typography
              variant="body1"
              fontSize={11.5}
              fontWeight={400}
              lineHeight="16px"
              color={greyColor9}
            >
              {`${props.totalReviews} Reviews | ${props.connection} Connections`}
            </Typography>
          </Box>
        </Box>
        <Box display={"flex"} flexDirection={"column"} alignItems={"flex-end"}>
          <Typography
            variant="body1"
            fontSize={12}
            fontWeight={500}
            lineHeight="16px"
            color={greyColor9}
          >
            {moment(props.data.updateDate).format("MMM DD, YYYY")}
          </Typography>

          <Box display="flex" alignItems="flex-end" gap="3px" mt={0.3}>
            <Typography
              variant="body1"
              fontSize={12}
              fontWeight={600}
              lineHeight="16px"
              color={blackColor}
            >
              {props.data.reviewRating}{" "}
            </Typography>

            {/* <StarRoundedIcon
              sx={{
                width: "18.5px",
                height: "18.5px",
                color: orangeColor01,
              }}
            /> */}
            <SingleStarRatingDisplay rating={props.data.reviewRating} />
          </Box>

          {/* <Rating
            readOnly
            icon={
              <Box
                component="img"
                src={`https://storage.googleapis.com/bodefaults/shopweb/reviews/star.svg`}
                alt={`Star`}
                sx={{
                  width: "12x",
                  height: "12px",
                  display: "flex",
                  lineHeight: 1.5,
                  mr: "2px",
                }}
              />
            }
            emptyIcon={
              <Box
                component="img"
                src={`https://storage.googleapis.com/bodefaults/shopweb/reviews/star-1.svg`}
                alt={`Star`}
                sx={{
                  width: "12x",
                  height: "12px",
                  display: "flex",
                  lineHeight: 1.5,
                }}
              />
            }
            precision={0.1}
            value={props.data.reviewRating}
            sx={{ marginBottom: 1 }}
          /> */}
        </Box>
      </Box>

      <Box
        sx={{
          textAlign: "left",
          mt: "12px",
        }}
      >
        <Typography
          variant="h5"
          fontSize={13}
          fontWeight={500}
          lineHeight="20px"
          color={blackColor}
        >
          {props.data.reviewTitle}
        </Typography>

        <Typography
          variant="body1"
          color={greyColor9}
          sx={{
            height: props.isShownInModal ? "auto" : "40px",
            overflow: props.isShownInModal ? "none" : "hidden",
          }}
          fontSize={{ md: "12.5px", xs: "12px" }}
          lineHeight="19px"
          mt="5px !important"
        >
          {props.isShownInModal && props.showFullData ? (
            props.data.message
          ) : (
            <Typography
              variant="body1"
              color={greyColor9}
              sx={{
                height: props.isShownInModal ? "auto" : "40px",
                overflow: props.isShownInModal ? "none" : "hidden",
              }}
              fontSize={{ md: "12.5px", xs: "12px" }}
              lineHeight="19px"
            >
              {props.data.message.slice(0, contentLength)}{" "}
              {props.data.message.length > contentLength ? (
                <span
                  onClick={() =>
                    props.onClickMore({
                      data: props.data,
                      totalReviews: props.totalReviews,
                      connection: props.connection,
                    })
                  }
                  style={{
                    fontSize: "12px",
                    fontWeight: 500,
                    textDecoration: "underline",
                    color: orangeColor01,
                    pl: 1,
                  }}
                >
                  Click More
                </span>
              ) : null}
            </Typography>
          )}
        </Typography>
      </Box>
    </Box>
  ) : (
    // <Card
    //   sx={{
    //     textAlign: 'left',
    //     boxShadow: '0px 3px 7px rgb(0 0 0 / 8%)',
    //     borderRadius: '6px',
    //     border: '1px solid ' + reviewCardBorderColor,
    //     pb: 1,
    //   }}
    // >
    //   <CardHeader
    //     avatar={
    //       <Avatar
    //         sx={{ width: 50, height: 50 }}
    //         alt='customer profile'
    //         src={
    //           props.showFullData
    //             ? props.data.profilePic
    //             : props.images[0]
    //             ? props.images[0].photoUrl
    //             : 'https://storage.googleapis.com/bodefaults/shopweb/supportANDFooter/review-user.svg'
    //         }
    //       />
    //     }
    //     titleTypographyProps={cardStyles.headerTitle}
    //     title={props.data.name}
    //     subheaderTypographyProps={cardStyles.headerSub}
    //     subheader={`${props.totalReviews} Reviews | ${props.connection} Connections`}
    //     action={
    //       props.disableDate ? null : (
    //         <Typography variant='body1' color={reviewBodyColor}>
    //           {moment(props.data.updateDate).format('MMM DD, YYYY')}
    //         </Typography>
    //       )
    //     }
    //   />
    //   <CardContent
    //     sx={{
    //       textAlign: 'left',
    //       paddingTop: 0,
    //       paddingBottom: '0px !important',
    //     }}
    //   >
    //     <Rating
    //       readOnly
    //       icon={
    //         <StarRounded sx={{ color: 'primary.main', fontSize: '20px' }} />
    //       }
    //       emptyIcon={
    //         <StarRounded sx={{ color: arrowBgColor, fontSize: '20px' }} />
    //       }
    //       precision={0.1}
    //       value={props.data.reviewRating}
    //       sx={{ marginBottom: 1 }}
    //     />

    //     <Typography
    //       variant='h5'
    //       color={headingColor}
    //       mb='6px !important'
    //       fontSize={{ md: '15px', xs: '13px' }}
    //     >
    //       {props.data.reviewTitle}
    //     </Typography>

    //     <Typography
    //       variant='body1'
    //       color={reviewBodyColor}
    //       sx={{
    //         height: props.isShownInModal ? 'auto' : '55px',
    //         overflow: props.isShownInModal ? 'none' : 'hidden',
    //       }}
    //       fontSize={{ md: '13px', xs: '11px' }}
    //     >
    //       {props.isShownInModal && props.showFullData ? (
    //         props.data.message
    //       ) : (
    //         <Typography
    //           variant='body1'
    //           color={reviewBodyColor}
    //           sx={{
    //             height: props.isShownInModal ? 'auto' : '55px',
    //             overflow: props.isShownInModal ? 'none' : 'hidden',
    //           }}
    //           fontSize={{ md: '13px', xs: '11px' }}
    //         >
    //           {props.data.message.slice(0, contentLength)}

    //           {props.data.message.length > contentLength ? (
    //             <Button
    //               onClick={() =>
    //                 props.onClickMore({
    //                   data: props.data,
    //                   totalReviews: props.totalReviews,
    //                   connection: props.connection,
    //                 })
    //               }
    //               sx={{
    //                 textTransform: 'none',
    //                 textDecoration: 'underline',
    //                 fontSize: '13px',
    //               }}
    //             >
    //               Click More
    //             </Button>
    //           ) : null}
    //         </Typography>
    //       )}
    //     </Typography>
    //   </CardContent>

    //   {/* <CardActions
    //     sx={{
    //       mx: 1,
    //       borderTop: borderBackground,
    //     }}
    //   >
    //     <Button
    //       startIcon={<ThumbUpAlt sx={{ fontSize: '14px' }} />}
    //       sx={{
    //         ...cardStyles.actionBtn,
    //         color: ishelpFul ? orangeColor01 : reviewSubColor,
    //       }}
    //       variant='text'
    //       size='small'
    //       onClick={() => isHelpfulClicked(props.data.id, props.data.profileId)}
    //     >
    //       {props.data.isHelpfulCount} Helpful
    //     </Button>

    //     <Button
    //       startIcon={<Info sx={{ fontSize: '14px' }} />}
    //       sx={{
    //         ...cardStyles.actionBtn,
    //         color: report ? orangeColor01 : reviewSubColor,
    //       }}
    //       variant='text'
    //       size='small'
    //       onClick={() => reportAbuseClicked(props.data.id)}
    //     >
    //       {props.data.abuseCount} Report Abuse!
    //     </Button>
    //   </CardActions> */}
    // </Card>
    <Box
      sx={{
        p: "12px",
        textAlign: "left",
        borderRadius: "10px",
        border: "1px solid " + reviewCardBorderColor,
        backgroundColor: props.isShownInModal ? whiteColor :backgroundColor02,
      }}
    >
      <Box display={"flex"} justifyContent={"space-between"} gap={1}>
        <Box display={"flex"} gap={1}>
          {props?.images?.[0]?.photoUrl ? (
            <Avatar
              sx={{
                width: { md: 50, xs: "38px" },
                height: { md: 50, xs: "38px" },
              }}
              alt="customer profile"
              src={
                props?.showFullData
                  ? props?.data?.profilePic
                  : props?.images?.[0]?.photoUrl
                  ? props?.images?.[0]?.photoUrl
                  : "https://storage.googleapis.com/bodefaults/shopweb/reviews/review-user.svg"
              }
            />
          ) : (
            <UserCharAvatar
              username={props.data.name}
              imgwidth="38px"
              imgheight="38px"
            />
          )}

          <Box>
            <Typography
              variant="body1"
              fontSize={13}
              fontWeight={600}
              color={blackColor}
              lineHeight="22px"
            >
              {props.data.name}
            </Typography>
            <Typography
              variant="body1"
              fontSize={11.5}
              fontWeight={400}
              lineHeight="16px"
              color="rgb(111 111 113)"
            >
              {`${props.totalReviews} Reviews | ${props.connection} Connections`}
            </Typography>
          </Box>
        </Box>
        <Box display={"flex"} flexDirection={"column"} alignItems={"flex-end"}>
          <Typography
            variant="body1"
            fontSize={12}
            fontWeight={500}
            lineHeight="20px"
            color={blackColor}
          >
            {moment(props.data.updateDate).format("MMM DD, YYYY")}
          </Typography>
          <Box display="flex" alignItems="flex-end" gap="3px" mt={0.3}>
            <Typography
              variant="body1"
              fontSize={12}
              fontWeight={600}
              lineHeight="16px"
              color={blackColor}
            >
              {props.data.reviewRating}{" "}
            </Typography>

            {/* <StarRoundedIcon
              sx={{
                width: "18.5px",
                height: "18.5px",
                color: orangeColor01,
              }}
            /> */}
            <SingleStarRatingDisplay rating={props.data.reviewRating} />
          </Box>

          {/* <Rating
            readOnly
            icon={
              <StarRoundedIcon
                sx={{
                  width: "18.5px",
                  height: "18.5px",
                  ml: -0.4,
                  color: orangeColor01,
                }}
              />
              // <Box
              //   component="img"
              //   src={`https://storage.googleapis.com/bodefaults/shopweb/reviews/star.svg`}
              //   alt={`Star`}
              //   sx={{
              //     width: "15x",
              //     height: "15px",
              //     display: "flex",
              //     lineHeight: 1.5,
              //   }}
              // />
            }
            emptyIcon={
              <Box
                component="img"
                src={`https://storage.googleapis.com/bodefaults/shopweb/reviews/star-1.svg`}
                alt={`Star`}
                sx={{
                  width: "13x",
                  height: "13px",
                  display: "flex",
                  lineHeight: 1.5,
                  mt:0.3
                }}
              />
            }
            precision={0.1}
            value={props.data.reviewRating}
            sx={{ marginBottom: 1 }}
          /> */}
        </Box>
      </Box>

      <Box
        sx={{
          textAlign: "left",
          mt: "12px",
        }}
      >
        <Typography
          variant="h5"
          fontSize={13}
          fontWeight={500}
          lineHeight="20px"
          color={headingColor}
        >
          {props.data.reviewTitle}
        </Typography>

        <Typography
          variant="body1"
          color="rgb(115 115 115)"
          sx={{
            height: props.isShownInModal ? "auto" : "34px",
            overflow: props.isShownInModal ? "none" : "hidden",
          }}
          fontSize={{ md: "13px", xs: "12px" }}
          lineHeight="17px"
          mt="5px !important"
        >
          {props.isShownInModal && props.showFullData ? (
            props.data.message
          ) : (
            <Typography
              variant="body1"
              color={reviewBodyColor}
              sx={{
                height: props.isShownInModal ? "auto" : "34px",
                overflow: props.isShownInModal ? "none" : "hidden",
              }}
              fontSize={{ md: "13px", xs: "12px" }}
              lineHeight="17px"
            >
              {props.data.message.slice(0, contentLength)}{" "}
              {props.data.message.length > contentLength ? (
                <span
                  onClick={() =>
                    props.onClickMore({
                      data: props.data,
                      totalReviews: props.totalReviews,
                      connection: props.connection,
                    })
                  }
                  style={{
                    fontSize: "12px",
                    fontWeight: 500,
                    textDecoration: "underline",
                    color: orangeColor01,
                    pl: 1,
                  }}
                >
                  Click More
                </span>
              ) : null}
            </Typography>
          )}
        </Typography>
      </Box>
    </Box>
  );
}

export default ReviewCard;

const cardStyles = {
  headerTitle: {
    fontSize: { md: "15px", xs: "14px" },
    color: headingColor,
    fontWeight: 600,
  },

  headerSub: {
    fontSize: "13px",
    fontWeight: 400,
    color: reviewSubColor,
    marginTop: "4px",
  },

  actionBtn: {
    fontSize: "14px",
    fontWeight: 400,
    textTransform: "none",
    "&:hover": {
      backgroundColor: whiteColor,
    },
  },
};
