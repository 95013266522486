import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { noProductImgUrl } from "configs/Constants";
import { useEffect, useState } from "react";
import {
  blackColor,
  borderLineColor,
  categoryLabelColor,
  greenColor03,
  greenColor05,
  greyShade52,
  headingColor,
  offerTextColor,
  orangeColor01,
  savingTextColor,
  whiteColor,
} from "../configs/styles/muiThemes";
import { textOneLines, textTwoLines } from "utils/styleUtility";
import { getDiscountPercentage } from "utils/utilities";

function TableProductCard({
  image,
  productName,
  caterogy,
  oldUnitPrice,
  unitprice,
  discount,
  price,
  saving,
  quantity,
  isDiscount,
  isOutofStock,
  onDeleteProduct,
  readOnly,
  minQty,
  maxQty,
  cartData,
  cartId,
  product,
  orderProduct,
  onQtyUpdate,
  isPriceChange,
  isEcommerceDisabled,
  minQtyCheck,
  maxQtyCheck,
  validateCart,
  onRemoveProduct,
  onPriceUpdate,
  weightZeroError,
  errorMsg,
}) {
  //For Ui count update
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [qtyCount, setQtyCount] = useState(quantity);
  let businessFullName = sessionStorage.getItem("businessFullName");

  useEffect(() => {
    setQtyCount(quantity);
  }, [productName]);

  const getDeleteIconColor = () => {
    if (!validateCart) {
      return false;
    }

    return (
      isEcommerceDisabled ||
      isPriceChange ||
      minQtyCheck ||
      maxQtyCheck ||
      weightZeroError
    );
  };

  return matches ? (
    <>
      <Box
        py={1.8}
        borderTop={"1px solid" + borderLineColor}
        display={{ md: "block", xs: "none" }}
      >
        <Grid
          container
          alignItems={{ xs: "flex-start", md: "flex-start" }}
          spacing={{ md: 0, xs: 1 }}
          gap={1}
          sx={{ height: { md: "57px", xs: "68px" } }}
        >
          <Grid item md={1} xs={12}>
            <Box
            // component={Link}
            // to={`/${businessName}/singleProduct/${productId}`}
            >
              <Box
                component="img"
                src={image}
                alt={businessFullName + " CostBo"}
                backgroundColor={whiteColor}
                sx={styles.productImg}
                // onError={({ currentTarget }) => {
                //   if (!isImgFailed) {
                //     currentTarget.src = noProductImgUrl
                //     setIsImgFailed(true)
                //   } else {
                //     // Both Images are failing to Load
                //   }
                // }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = noProductImgUrl;
                }}
              />
            </Box>
          </Grid>
          <Grid item md={5.4} xs={12}>
            <Stack mt={0.5}>
              <Typography
                variant="h6"
                component="h4"
                fontSize={{ xs: 13, md: 13 }}
                fontWeight={500}
                lineHeight="24px"
                color={blackColor}
                // mt={-0.7}
              >
                {productName}
              </Typography>
              <Box display={"flex"} gap={1}>
                {oldUnitPrice != unitprice ? (
                  <Typography
                    color={greyShade52}
                    fontSize={13}
                    fontWeight={500}
                    lineHeight={"24px"}
                  >
                    MRP ₹ {Number(oldUnitPrice).toFixed(2)}
                  </Typography>
                ) : null}
                <Typography
                  color={blackColor}
                  fontSize={15}
                  fontWeight={600}
                  lineHeight={"24px"}
                >
                  ₹ {Number(unitprice).toFixed(2)}
                </Typography>
                {oldUnitPrice != unitprice ? (
                  <Typography
                    variant="subtitle2"
                    component="p"
                    color={greenColor03}
                    mt={0.3}
                    fontSize={13}
                  >
                    {getDiscountPercentage(unitprice, oldUnitPrice)}% OFF
                  </Typography>
                ) : null}
              </Box>

              {errorMsg && (
                <Box sx={styles.errorBox}>
                  <Typography
                    sx={{
                      color: blackColor,
                      fontWeight: 500,
                      fontSize: "11.5px",
                      lineHeight: "20px",
                      padding: "2px 8px",
                    }}
                  >
                    {errorMsg}
                  </Typography>
                </Box>
              )}
            </Stack>
          </Grid>

          <Grid item md={2}>
            <Box textAlign="center">
              <Typography
                fontSize={12}
                fontWeight={400}
                lineHeight="24px"
                color={greyShade52}
              >
                Total Price
              </Typography>

              <Typography
                fontSize={14}
                fontWeight={600}
                lineHeight="16px"
                color={blackColor}
              >
                ₹ {Number(Number(unitprice) * Number(quantity)).toFixed(2)}
              </Typography>
            </Box>
          </Grid>

          <Grid item md={2}>
            <Box textAlign="center">
              <Typography
                fontSize={12}
                fontWeight={400}
                lineHeight="24px"
                color={greyShade52}
              >
                Total Savings
              </Typography>

              <Typography
                fontSize={14}
                fontWeight={600}
                lineHeight="16px"
                color={greenColor05}
              >
                ₹ {Number(saving)?.toFixed(2)}
              </Typography>
            </Box>
          </Grid>

          <Grid item md={1} xs={5} alignItems={"center"}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              // alignItems={'center'}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <Typography
                fontSize={12}
                fontWeight={400}
                lineHeight="24px"
                color={greyShade52}
              >
                Quantity
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={600}
                lineHeight="16px"
                color={blackColor}
              >
                {quantity}
              </Typography>
            </Box>
            {/* <Box display={'flex'} gap={3}>
              <FormControl
                sx={{
                  width: '100%',
                  minHeight: '45px',
                  '& .css-hdw1oc': {
                    display: 'none',
                  },
                  '& .MuiOutlinedInput-notchedOutline, .css-1d3z3hw-MuiOutlinedInput-notchedOutline':
                    {
                      border: 'none',
                    },
                }}
              >
                <Select
                  // labelId='quantity-label'
                  labelId='demo-multiple-chip-label'
                  id='demo-multiple-chip'
                  value={`1${quantity}`}
                  onChange={handleChange}
                  // input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
                  // label={`Qty ${quantity}`}
                  sx={{
                    width: '90px',
                    border: '1px solid #b4c0d3',
                    borderRadius: '6px',
                    height: '36px',
                    // maxWidth: '135px',
                    padding: '5px',
                    fontSize: '13.5px',
                    fontWeight: '500',
                    color: '#30363c',
                    // marginRight: '30px',
                    // backgroundColor: '',
                  }}
                  // MenuProps={MenuProps}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        color: whiteColor,
                        backgroundColor: '#30363c',
                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                        width: 150,
                        '& .MuiMenuItem-root': {
                          '&:hover': {
                            backgroundColor: '#007bff', // Hover color for all menu items
                            color: '#fff', // Change text color on hover
                          },
                        },
                        '& .MuiMenuItem-root.Mui-selected': {
                          backgroundColor: '#007bff', // Background color for selected item
                          color: '#fff', // Ensure text color is white for selected item
                          '&:hover': {
                            backgroundColor: '#007bff', // Hover color for selected item
                            color: '#fff', // Keep text white on hover
                          },
                        },
                      },
                    },
                  }}
                >
                  <MenuItem disabled>{minQty} Min Quantity</MenuItem>
                  {[...Array(maxQty - minQty + 1)].map((_, index) => (
                    <MenuItem key={index + minQty} value={index + minQty}>
                      {index + minQty}
                      {quantity === index + minQty && ( // Show check icon if item is selected
                        <ListItemIcon>
                          <CheckIcon
                            sx={{ color: whiteColor, fontSize: '16px' }}
                          />
                        </ListItemIcon>
                      )}
                    </MenuItem>
                  ))}
                  <MenuItem disabled>{maxQty} Max Quantity</MenuItem>
                </Select>
              </FormControl>
              {!readOnly ? (
                <Box
                  component='img'
                  src={'https://storage.googleapis.com/bodefaults/shopweb/home/cart_delete.svg'}
                  width={{ xs: '20px', md: '25px' }}
                  height={{ xs: '20px', md: '25px' }}
                  alt={businessFullName + ' CostBo'}
                  // sx={{
                  //   color: getDeleteIconColor()
                  //     ? outOfStockTextColor
                  //     : delectIconInactiveColor,
                  // }}
                  onClick={onDeleteProduct}
                  sx={{ mt: 1 }}
                />
              ) : null}
            </Box> */}
          </Grid>
        </Grid>
      </Box>
      <Box
        py={0.8}
        borderTop={"1px solid" + borderLineColor}
        display={{ xs: "block", md: "none" }}
      >
        <Grid container alignItems="center">
          <Grid item xs={3}>
            <Box
              sx={{ width: "58px", height: "58px" }}
              alignItems={"center"}
              border={`1px solid ${borderLineColor}`}
              borderRadius={"10px"}
              display={"flex"}
              justifyContent={"center"}
            >
              <Box
                component="img"
                src={image}
                alt={businessFullName + " CostBo"}
                backgroundColor={whiteColor}
                sx={{
                  height: "46px",
                  width: "46px",
                  transition: "all 0.3s ease-in-out",
                  transform: "scale(1)",
                  "&:hover": {
                    transform: "scale(0.97)",
                  },
                }}
                // sx={{ ...styles.productImg, height: '46px', width: '46px' }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = noProductImgUrl;
                }}
              />
              {/* <Box
              component='img'
              src={image}
              width={{ xs: '26px', md: '100px' }}
              height={{ xs: '26px', md: '100px' }}
              alt={businessFullName + ' CostBo'}
              sx={{ padding: '20px' }}
            /> */}
            </Box>
          </Grid>
          <Grid item xs={8.5}>
            <Stack>
              <Typography
                variant="h6"
                component="h4"
                fontSize={{ xs: 13, md: 14 }}
                fontWeight={500}
                lineHeight="18px"
                color={headingColor}
                mt={0.7}
                sx={{ ...textTwoLines }}
              >
                {productName}
              </Typography>
              <Box
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
              >
                {matches ? (
                  <Box>
                    {oldUnitPrice !== unitprice ? (
                      <Typography
                        variant="subtitle1"
                        component="p"
                        fontSize={{ xs: 13, md: 13 }}
                        fontWeight={400}
                        color={offerTextColor}
                        display="inline"
                        sx={{ textDecorationLine: "line-through" }}
                      >
                        ₹{Number(oldUnitPrice).toFixed(2)}
                      </Typography>
                    ) : null}

                    <Typography
                      variant="subtitle1"
                      component="h4"
                      fontSize={{ xs: 13, md: 14 }}
                      color={headingColor}
                      display="inline"
                      ml={1}
                    >
                      ₹{Number(unitprice).toFixed(2)}
                    </Typography>

                    {oldUnitPrice !== unitprice ? (
                      <Typography
                        variant="subtitle2"
                        component="p"
                        color={orangeColor01}
                        mt={0.3}
                        ml={1}
                        // ml={{xs:0.7, md:0}}
                        // display={{ xs: 'inline', md: 'block' }}
                        display="inline"
                      >
                        {getDiscountPercentage(unitprice, oldUnitPrice)}% OFF
                      </Typography>
                    ) : null}
                  </Box>
                ) : (
                  <Box>
                    <Typography
                      variant="subtitle1"
                      component="h4"
                      fontSize={{ xs: 13, md: 14 }}
                      color={blackColor}
                      fontWeight={600}
                      display="inline"
                    >
                      ₹ {Number(unitprice).toFixed(2)}
                    </Typography>
                    {oldUnitPrice !== unitprice ? (
                      <Typography
                        variant="subtitle1"
                        component="p"
                        fontSize={{ xs: 13, md: 13 }}
                        fontWeight={400}
                        color={offerTextColor}
                        display="inline"
                        sx={{ textDecorationLine: "line-through" }}
                        ml={1}
                      >
                        ₹ {Number(oldUnitPrice).toFixed(2)}
                      </Typography>
                    ) : null}

                    {oldUnitPrice !== unitprice ? (
                      <Typography
                        variant="subtitle2"
                        component="p"
                        color={"#00d395"}
                        mt={0.3}
                        ml={1}
                        // ml={{xs:0.7, md:0}}
                        // display={{ xs: 'inline', md: 'block' }}
                        display="inline"
                      >
                        {getDiscountPercentage(unitprice, oldUnitPrice)}% OFF
                      </Typography>
                    ) : null}
                  </Box>
                )}
                <Box>
                  <Typography
                    variant="subtitle1"
                    fontSize="13px"
                    color={offerTextColor}
                    display={{ xs: "inline", md: "none" }}
                  >
                    Qty
                  </Typography>
                  <Typography
                    variant="body1"
                    color={headingColor}
                    fontSize="13px"
                    ml={{ md: 4, xs: 1 }}
                    // width="45px"
                    fontWeight={600}
                    display={"inline"}
                  >
                    {qtyCount}
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  ) : (
    <>
      <Box
        py={1.8}
        borderTop={"1px solid" + borderLineColor}
        display={{ md: "block", xs: "none" }}
      >
        <Grid
          container
          alignItems={{ xs: "flex-start", md: "center" }}
          spacing={{ md: 3, xs: 1 }}
        >
          <Grid item xs={12} md={readOnly ? 5 : 4.5}>
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item>
                <Box
                  component="img"
                  src={image}
                  alt={businessFullName + " CostBo"}
                  backgroundColor={whiteColor}
                  sx={styles.productImg}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = noProductImgUrl;
                  }}
                />
              </Grid>
              <Grid item xs>
                <Stack>
                  {/* <Typography
                  variant='subtitle1'
                  fontSize='13px'
                  color={categoryLabelColor}
                >
                  {JSON.stringify(validateCart)}
                </Typography> */}
                  <Typography
                    variant="h6"
                    component="h4"
                    fontSize={{ xs: 13, md: 13 }}
                    fontWeight={500}
                    lineHeight="24px"
                    color={headingColor}
                    mt={0.7}
                  >
                    {productName}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={5} md={2}>
            <Box>
              {oldUnitPrice !== unitprice ? (
                <Typography
                  variant="subtitle1"
                  component="p"
                  fontSize={{ xs: 13, md: 13 }}
                  fontWeight={400}
                  color={offerTextColor}
                  display="inline"
                  sx={{ textDecorationLine: "line-through" }}
                >
                  {/* ₹{Number(oldUnitPrice).toFixed(2)} */}₹{" "}
                  {Number(Number(unitprice) * Number(quantity)).toFixed(2)}
                </Typography>
              ) : null}

              <Typography
                variant="subtitle1"
                component="h4"
                fontSize={{ xs: 13, md: 15 }}
                color={headingColor}
                display="inline"
                ml={1}
              >
                ₹{Number(saving).toFixed(2)}
                {/* ₹{Number(unitprice).toFixed(2)} */}
              </Typography>

              {oldUnitPrice !== unitprice ? (
                <Typography
                  variant="subtitle2"
                  component="p"
                  color={orangeColor01}
                  mt={0.3}
                  // ml={{xs:0.7, md:0}}
                  // display={{ xs: 'inline', md: 'block' }}
                  display="block"
                >
                  {getDiscountPercentage(unitprice, oldUnitPrice)}% OFF
                </Typography>
              ) : null}
            </Box>
          </Grid>

          <Grid item xs={readOnly ? 12 : 5} md={2.3}>
            {readOnly ? (
              <>
                <Typography
                  variant="subtitle1"
                  fontSize="13px"
                  color={offerTextColor}
                  display={{ xs: "block", md: "none" }}
                >
                  Qty
                </Typography>
                <Typography
                  variant="body1"
                  color={headingColor}
                  fontSize="13px"
                  ml={{ md: 4, xs: 1 }}
                  // width="45px"
                  fontWeight={600}
                  display={"inline"}
                >
                  {qtyCount}
                </Typography>
              </>
            ) : null}
          </Grid>

          <Grid
            item
            xs={0}
            md={1.2}
            display={{ xs: "none", md: "inline-flex" }}
          >
            <Typography
              variant="subtitle1"
              component="h4"
              lineHeight="24px"
              fontSize={14}
              color={headingColor}
            >
              ₹{Number(price).toFixed(2)}
            </Typography>
          </Grid>

          <Grid item xs={2} md={readOnly ? 1 : 2}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mr={0.6}
            >
              <Typography
                variant="subtitle1"
                component="h4"
                lineHeight="24px"
                fontSize={14}
                color={savingTextColor}
                display={{ xs: "none", md: "inline" }}
              >
                ₹{Number(saving).toFixed(2)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        py={0.8}
        borderTop={"1px solid" + borderLineColor}
        display={{ xs: "block", md: "none" }}
      >
        <Grid container alignItems="center">
          <Grid item xs={3}>
            <Box
              sx={{ width: "58px", height: "58px" }}
              alignItems={"center"}
              border={`1px solid ${borderLineColor}`}
              borderRadius={"10px"}
              display={"flex"}
              justifyContent={"center"}
            >
              <Box
                component="img"
                src={image}
                alt={businessFullName + " CostBo"}
                backgroundColor={whiteColor}
                sx={{
                  height: "46px",
                  width: "46px",
                  transition: "all 0.3s ease-in-out",
                  transform: "scale(1)",
                  "&:hover": {
                    transform: "scale(0.97)",
                  },
                }}
                // sx={{ ...styles.productImg, height: '46px', width: '46px' }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = noProductImgUrl;
                }}
              />
              {/* <Box
              component='img'
              src={image}
              width={{ xs: '26px', md: '100px' }}
              height={{ xs: '26px', md: '100px' }}
              alt={businessFullName + ' CostBo'}
              sx={{ padding: '20px' }}
            /> */}
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Stack>
              <Typography
                variant="h6"
                component="h4"
                fontSize={{ xs: 13, md: 13 }}
                fontWeight={500}
                lineHeight="18px"
                color={headingColor}
                mt={0.7}
                sx={{ ...textOneLines }}
              >
                {productName}
              </Typography>
              <Box
                display="flex"
                justifyContent={"space-between"}
                flexWrap="wrap"
                flexDirection={{ xs: "column" }}
              >
                {matches ? (
                  <Box>
                    {oldUnitPrice != unitprice ? (
                      <Typography
                        variant="subtitle1"
                        component="p"
                        fontSize={{ xs: 13, md: 13 }}
                        fontWeight={400}
                        color={offerTextColor}
                        display="inline"
                        sx={{ textDecorationLine: "line-through" }}
                      >
                        ₹{Number(oldUnitPrice).toFixed(2)}
                      </Typography>
                    ) : null}

                    <Typography
                      variant="subtitle1"
                      component="h4"
                      fontSize={{ xs: 13, md: 15 }}
                      color={headingColor}
                      display="inline"
                      ml={1}
                    >
                      ₹{Number(unitprice).toFixed(2)}
                    </Typography>

                    {oldUnitPrice != unitprice ? (
                      <Typography
                        variant="subtitle2"
                        component="p"
                        color={orangeColor01}
                        fontSize={{ xs: 13, md: 13 }}
                        mt={0.3}
                        ml={1}
                        // ml={{xs:0.7, md:0}}
                        // display={{ xs: 'inline', md: 'block' }}
                        display="inline"
                      >
                        {getDiscountPercentage(unitprice, oldUnitPrice)}% OFF
                      </Typography>
                    ) : null}
                  </Box>
                ) : (
                  <Box>
                    <Typography
                      variant="subtitle1"
                      component="h4"
                      fontSize={{ xs: 13, md: 14 }}
                      color={blackColor}
                      fontWeight={600}
                      display="inline"
                    >
                      {/* ₹ {Number(unitprice).toFixed(2)} */}
                      {Number(Number(unitprice) * Number(quantity)).toFixed(2)}
                    </Typography>
                    {oldUnitPrice != unitprice ? (
                      <Typography
                        variant="subtitle1"
                        component="p"
                        fontSize={{ xs: 13, md: 13 }}
                        fontWeight={400}
                        color={offerTextColor}
                        display="inline"
                        sx={{ textDecorationLine: "line-through" }}
                        ml={1}
                      >
                        ₹ {Number(saving).toFixed(2)}
                      </Typography>
                    ) : null}

                    {oldUnitPrice != unitprice ? (
                      <Typography
                        variant="subtitle2"
                        component="p"
                        color={"#00d395"}
                        mt={0.3}
                        ml={1}
                        // ml={{xs:0.7, md:0}}
                        // display={{ xs: 'inline', md: 'block' }}
                        display="inline"
                      >
                        {getDiscountPercentage(unitprice, oldUnitPrice)}% OFF
                      </Typography>
                    ) : null}
                  </Box>
                )}
                <Box>
                  <Typography
                    variant="subtitle1"
                    fontSize="13px"
                    color={offerTextColor}
                    display={{ xs: "inline", md: "none" }}
                  >
                    Qty -
                  </Typography>
                  <Typography
                    variant="body1"
                    color={headingColor}
                    fontSize="13px"
                    ml={{ md: 4, xs: 1 }}
                    // width="45px"
                    fontWeight={600}
                    display={"inline"}
                  >
                    {qtyCount}
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default TableProductCard;

const styles = {
  productImg: {
    width: { md: "47px", xs: "90px" },
    height: { md: "50px", xs: "68px" },
    border: "1px solid" + borderLineColor,
    borderRadius: "10px",
    p: 0.5,
    objectFit: "contain",
    transition: "all 0.3s ease-in-out",
    transform: "scale(1)",
    "&:hover": {
      transform: "scale(0.97)",
    },
  },

  offTag: {
    display: "inline-block",
    backgroundColor: "primary.main",
    borderRadius: "0 6px 0 0",
    p: "4px 15px",
    borderRadius: "5px",
  },

  errorBox: {
    background: "#fff8e3",
    borderRadius: "4px",
    marginRight: "8px",
    marginTop: "8px",
    border: "1px solid #e6ebf4",
    width: "fit-content",
  },
};
