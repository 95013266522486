import { Box, InputAdornment, TextField, Typography } from '@mui/material'
import React from 'react'
import {
  blackColor,
  borderTextbox,
  errorTextColor,
  filterMobileColor,
  headingColor,
  labelColor,
  whiteColor,
} from '../configs/styles/muiThemes'
import { styled } from '@mui/material/styles'
import { allowOnlyEnglish } from 'utils/utilities'

const CustomDisableInput = styled(TextField)(() => ({
  '.MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000',
    color: '#000',
  },
}))
function TextboxWithLabel({
  label,
  placeholder,
  required,
  value,
  onValueChange,
  disabled,
  maxLength,
  isError,
  errorMsg,
  small,
  startIcon,
  margingright,
  widthtext
}) {
  return (
    <Box
      sx={{
        mb: { md: "10px !important", xs: "0 !important" },
        mr: margingright ? margingright : 3,
      }}
    >
      <Box sx={{ my: { md: 1, xs: 0.5 } }}>
        <Typography
          variant="subtitle1"
          fontSize={{ md: small ? "13px" : "15px", xs: "13px" }}
          sx={styles.label}
        >
          {label}{" "}
        </Typography>
        {required ? (
          <Typography variant="body1" sx={{ display: "inline", color: "#f00" }}>
            *
          </Typography>
        ) : null}
      </Box>
      <CustomDisableInput
        value={value}
        variant="standard"
        sx={styles.inputBox(whiteColor, small, widthtext)}
        fullWidth
        // maxLength={maxLength}
        onChange={({ target }) => {
          // if (target.value.length == 11) return false //limits to 10 digit entry
          const finalValue = allowOnlyEnglish(target.value);
          onValueChange(finalValue);
        }}
        placeholder={placeholder}
        InputProps={{
          startAdornment: startIcon ? (
            <InputAdornment position="start">{startIcon}</InputAdornment>
          ) : null,
          disableUnderline: true,
        }}
        inputProps={{ maxLength: maxLength }}
        disabled={disabled}
      />
      {isError ? (
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={13}
          color={errorTextColor}
        >
          {errorMsg}
        </Typography>
      ) : null}
    </Box>
  );
}

export default TextboxWithLabel

const styles = {
  label: {
    color: {md:filterMobileColor,xs:blackColor},
    display: 'inline',
  },

  inputBox: (color, small,widthtext) => ({
    border: borderTextbox,
    borderRadius: '6px',
    fontSize: '13px',
    color: '#777',
    background: color,
    padding: { md: small ? '4px 10px' : '8px 10px', xs:small? "8px 10px": '4px 10px' },
    width: widthtext?widthtext:"100%",
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'red !important',
    },
  }),

  infolabel: {
    display: 'block',
    fontSize: '13px',
    textAlign: 'right',
    py: '8px',
    color: '#9e9fa3',
  },

  btnContainer: { display: 'flex', justifyContent: 'flex-end', mt: 1 },

  submitBtn: {
    fontSize: '14px',
    fontWeight: 500,
    color: whiteColor,
    p: '7px 28px',
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: 'primary.main',
    },
  },
}
