import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCartDetailsById,
  getCommercialDetails,
} from 'redux/addToCart/AddToCartSlice'
import FooterMui from '../components/FooterMui'
import ConfirmationContent from './Confirmation/ConfirmationContent'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { backgroundColor01, headingColor, whiteColor } from 'configs/styles/muiThemes'
import { Box, Container, Typography, useMediaQuery } from '@mui/material'
import BreadcrumbsSection from 'components/Breadcrumbs'
import TopComponent from './TopComponent'
import Loader from 'components/Loader'

import { getUserName } from 'utils/authHelpers'
import PaycartConfirmationContent from './Confirmation/PaycartConfirmationContent'
import { getCartDataById } from 'services/api_calls/addToCart/AddToCartApi'
import { getPayCartApi } from 'services/api_calls/paycart/PaycartApi'

function PayCartConfirmation() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const location = useLocation()
  const [pageLoading, setPageLoading] = useState(false)
  const [paycartData, setPaycartData] = useState(null)
  const { businessName } = useParams()
  let navigation = useNavigate()

  const [cartDetails, setCartDetails] = useState(null)

  // const { cartDetails, commercialDetails } = useSelector((state) => state.cart)

  const dispatch = useDispatch()
  const isLoggedIn = getUserName().length === 0 ? false : true

  const onWhatsappClick = () => {
    const orderUrl = `https://shop.costbo.com/${businessName}/easycart/${location?.state?.cartId}`;

    const message = `Experience shopping made simple with '${sessionStorage.getItem('businessFullName')}' Easy Cart.%0A%0AOrder Details: ${orderUrl}    
    %0A%0AFor a smooth order placement, kindly proceed with the payment through the following link: ${paycartData?.short_url}    
    %0A%0AMake your purchase effortlessly with just a click!
    `;

    const phoneNumber = cartDetails?.customerAddress?.alternatePhoneNo
      ? cartDetails?.customerAddress?.alternatePhoneNo
      : cartDetails?.customerAddress?.phoneNo;

    window.open(
      `https://api.whatsapp.com/send/?phone=${phoneNumber}&text=${message}`,
      "_blank"
    );
  };

  useEffect(() => {
    if (!isLoggedIn || !location?.state?.cartId) {
      navigation(`/${businessName}`)
    }
  }, [])

  useEffect(async () => {
    dispatch(getCommercialDetails())
    setPaycartData(location?.state?.payCartData?.details || null)

    if (location?.state?.cartId) {
      // const cartRes = await getCartDataById(location?.state?.cartId)

      const businessId = sessionStorage.getItem('businessId')

      const cartRes = await getPayCartApi({
        cartId: location?.state?.cartId,
        businessId: businessId,
      })

      setCartDetails(cartRes?.cart || null)

      // setTimeout(() => {
      //   onWhatsappClick()
      // }, 1000);
    }
  }, [])
  return (
    <>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          width: "100%",
          zIndex: 10,
          backgroundColor: { xs: backgroundColor01, md: backgroundColor01 },
          pb: { xs: 0, md: 1.5 },
        }}
      >
        {/* <Navbar value='' /> */}
        <TopComponent value="" disableBanners />
        {pageLoading && <Loader />}
        {!pageLoading && (
          <>
            <Container
              sx={{ maxWidth: "1370px !important", mt: { xs: 2, md: 2.5 } }}
            >
              {matches && (
                <BreadcrumbsSection
                  links={[
                    { label: "Catalog", to: `/catalog` },
                    { label: "Cart", to: `/cart` },
                    { label: "Discount/Delivery", to: `#`, disabled: true },
                    { label: "Easy Cart Order", to: `/easycartConfirmation` },
                  ]}
                />
              )}

              <Typography
                variant="subtitle1"
                component="h5"
                fontSize={{ xs: 15, md: 16 }}
                fontWeight={600}
                color={headingColor}
                mt={{ md: 0.3, xs: 11 }}
                ml={0.1}
                py={{ xs: 1.5, md: 0 }}
              >
                Easy Cart Order
              </Typography>
            </Container>
          </>
        )}
      </Box>

      {!pageLoading && paycartData && (
        <Box
          mb={{ xs: 8, md: 0 }}
          backgroundColor={{ xs: backgroundColor01, md: backgroundColor01 }}
        >
          <PaycartConfirmationContent
            cartDetails={cartDetails}
            paycartData={paycartData}
            onWhatsappClick={onWhatsappClick}
          />
          {matches && <FooterMui />}
        </Box>
      )}
    </>
  );
}

export default PayCartConfirmation
