import { Box, Button, Container, Typography } from '@mui/material'
import {
  blackShade20,
  greyShade43,
  greyShade44,
  headingColor,
  orangeColor01,
} from 'configs/styles/muiThemes'
import React, { useEffect, useState } from 'react'
import PaycartDetailsContent from './PaycartDetails/PaycartDetailsContent'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { getPayCartApi } from 'services/api_calls/paycart/PaycartApi'
import Loader from 'components/Loader'
import ModalCard from 'components/ModalCard'
import TrackingPopupContent from './PaycartDetails/TrackingPopupContent'

const PaycartDetails = () => {
  const { businessName, paycartId } = useParams()

  const [businessInfo, setBusinessInfo] = useState(null)
  const [paycartInfo, setPaycartInfo] = useState(null)

  const [openTrackPopup, setOpenTrackPopup] = useState(false)

  const getBusinessData = async (busniessName) => {
    try {
      const businessIdData = await axios.get(
        `https://web.costbo.com/business-query-side/api/v1/business/checkCostboWebURL?costboWebURL=${busniessName}`
      )

      if (!businessIdData?.data?.validate) return null

      if (businessIdData) {
        if (businessIdData.data) {
          const businessData = await axios.get(
            `https://web.costbo.com/business-query-side/api/v1/business/getActiveBusiness/${businessIdData.data.businessId}`
          )

          return businessData.data
        }
      }
    } catch (e) {
      console.log(e)
      return null
    }
  }

  const getPayCartInfo = async () => {
    console.log('Paycart_Info:', { businessName, paycartId })

    const businessData = await getBusinessData(businessName)

    console.log('Paycart_Info_businessData:', businessData)

    if (businessData?.success) {
      setBusinessInfo(businessData)

      const paycartData = await getPayCartApi({
        cartId: paycartId,
        businessId: businessData?.businessId,
      })

      if (paycartData?.success) {
        setPaycartInfo(paycartData?.cart || null)
      }
    }
  }

  const openShopingSite = () => {
    window.open(` https://shop.costbo.com/${businessName}/catalog`, '_blank')
  }

  const handleTrackOrder = (data) => {
    setOpenTrackPopup(true)
  }

  useEffect(() => {
    if (businessName && paycartId) {
      getPayCartInfo()
    }
    return () => {}
  }, [paycartId])

  return (
    <Box sx={{ background: greyShade44 }}>
      {businessInfo && paycartInfo ? (
        <Container sx={{ maxWidth: "1300px !important" }}>
          <Box display="flex" alignItems="center" gap={2} py={4}>
            <Box
              component="img"
              src={businessInfo?.logoURL}
              alt={businessInfo?.businessName}
              sx={styles.logo}
            />

            <Box>
              <Typography
                variant="h2"
                fontSize={{ xs: 16, md: 18 }}
                fontWeight={600}
                color={blackShade20}
                mb="5px !important"
              >
                {businessInfo?.businessName}
              </Typography>

              <Typography
                variant="body1"
                fontSize={{ xs: 13, md: 14 }}
                fontWeight={500}
                color={orangeColor01}
                lineHeight="20px"
                sx={{
                  lineBreak: "anywhere",
                  cursor: "pointer",

                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
                onClick={openShopingSite}
              >
                https://shop.costbo.com/{businessName}
              </Typography>
            </Box>
          </Box>

          <Typography
            variant="h5"
            fontSize={16}
            fontWeight={600}
            color={headingColor}
            mb="10px !important"
          >
            Easy Cart Order
          </Typography>

          <PaycartDetailsContent
            businessName={businessName}
            paycartData={paycartInfo}
            onTrack={handleTrackOrder}
          />

          <Box p="5px 0 40px 0">
            <Typography variant="h5" sx={styles.infoText}>
              Use the same URL to check back on the Order status and Tracking
              the shipment
            </Typography>

            <Typography variant="h5" sx={{ ...styles.infoText, fontSize: 14 }}>
              Buy directly from 1000s of businesses. Please use the link{" "}
              {/* <Typography component="span" fontSize={14} fontWeight={600}>
                '{businessInfo?.businessName}'
              </Typography>
              . Please use the link{" "} */}
              <Typography
                component="span"
                sx={styles.actionlink}
                onClick={openShopingSite}
              >
                Here
              </Typography>
            </Typography>
          </Box>

          <ModalCard
            handleClose={() => {
              setOpenTrackPopup(false);
            }}
            open={openTrackPopup}
            width="30%"
          >
            <TrackingPopupContent
              cartData={paycartInfo}
              paycartId={paycartId}
              businessId={businessInfo?.businessId}
            />
          </ModalCard>
        </Container>
      ) : (
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </Box>
      )}
    </Box>
  );
}

export default PaycartDetails

const styles = {
  logo: {
    width: { xs: "60px", md: "70px" },
    height: { xs: "60px", md: "70px" },
    borderRadius: "12px",
    border: `1px solid ${greyShade43}`,
    transition: "all 0.3s ease-in-out",
    transform: "scale(1)",
    "&:hover": {
      transform: "scale(0.97)",
    },
  },

  infoText: {
    fontSize: 15,
    fontWeight: 500,
    color: blackShade20,
    textAlign: "center",
    lineHeight: "27px",
  },

  actionlink: {
    fontSize: 14,
    fontWeight: 500,
    color: orangeColor01,
    cursor: "pointer",

    "&:hover": {
      textDecoration: "underline",
    },
  },
};
