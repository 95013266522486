import { Box, Container, Grid, Skeleton } from '@mui/material'
import { backgroundColor01, whiteColor } from 'configs/styles/muiThemes'
import React from 'react'
import CartItemsLoaderMinimal from './CartItemsLoaderMinimal'

const ConfirmationPageLoader = () => {
  return (
    <Box bgcolor={backgroundColor01}>
      <Container
        sx={{
          maxWidth: "1350px !important",
          p: "0 10px 0 15px !important",
        }}
      >
        <Skeleton variant="rounded" width="auto" height="52px" sx={{m:'18px 0 15px !important', borderRadius:'10px'}} />

        <Grid container spacing={3}>
          <Grid item xs={12} md={8.25}>
            <Skeleton variant="rounded" width="120px" height="17px" sx={{mb:'15px !important'}}/>
          
             <Skeleton variant="rounded" width="auto" height="472px" sx={{mr:'5px !important'}}/>

             <Box sx={{p:'10px 15px', bgcolor: whiteColor, borderRadius:'8px', mt:3}}>

                <Skeleton variant="rounded" width="100px" height="16px" sx={{m:'12px 0 18px 0px !important'}} />
                
                <CartItemsLoaderMinimal />
             </Box>
          </Grid>

          <Grid item xs={12} md={3.75}>
            <Skeleton variant="rounded" width="100px" height="13px" sx={{mt:'10px !important'}} />
            <Skeleton variant="rounded" width="auto" height="185px" sx={{mt:'12px !important'}} />

            <Skeleton variant="rounded" width="100px" height="13px" sx={{mt:'18px !important'}}/>
            <Skeleton variant="rounded" width="auto" height="179px" sx={{mt:'12px !important'}} />

            <Skeleton variant="rounded" width="100px" height="13px" sx={{mt:'18px !important'}}/>
            <Skeleton variant="rounded" width="auto" height="58px" sx={{mt:'12px !important'}} />

            <Skeleton variant="rounded" width="100px" height="13px" sx={{mt:'18px !important'}}/>
            <Skeleton variant="rounded" width="auto" height="58px" sx={{mt:'12px !important'}} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default ConfirmationPageLoader