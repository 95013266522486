import { Box, Button, Container, Grid, Typography } from '@mui/material'
import {
  backgroundColor01,
  blackColor,
  influencerCardBGColor,
  tncColor,
  whiteColor,
} from 'configs/styles/muiThemes'
import React from 'react'

function CostBoInfluencerCard() {
  let businessFullName = sessionStorage.getItem('businessFullName')

  return (
    <Container sx={{ maxWidth: "1400px !important", py: 4 }} id="getCostbo">
      <Box sx={{ background: backgroundColor01, borderRadius: "24px" }}>
        <Grid container spacingX={3}>
          <Grid item xs={12} md={6.5}>
            <Box sx={{ px: 4, py: 8 }}>
              <Typography
                fontSize={{ md: 32, xs: 24 }}
                lineHeight="30px"
                fontWeight={600}
                component="h4"
                color={blackColor}
                textAlign={{ md: "start", xs: "center" }}
                mb={{ md: 3, xs: 1 }}
              >
                BECOME A COSTBO INFLUENCER
              </Typography>

              <Typography
                fontSize={{ md: 20, xs: 18 }}
                lineHeight="33px"
                fontWeight={600}
                component="h5"
                color={blackColor}
                textAlign={{ md: "start", xs: "center" }}
                mb={{ md: 3, xs: 1 }}
                mt={3.1}
              >
                Share & Earn additional money (5% to 20%) when you PROMOTE a
                brand!
              </Typography>
              <Typography
                fontSize={{ md: 16, xs: 14 }}
                lineHeight="24px"
                fontWeight={400}
                component="h5"
                color={tncColor}
                textAlign={{ md: "start", xs: "center" }}
                mb={{ md: 3, xs: 1 }}
                mt={2}
              >
                At CostBo you can become an influencer in less than 2 mins.
                Share your favorite brand with your friends and earn money when
                they purchase from the brand.
              </Typography>

              <Box
                display="flex"
                alignItems="center"
                justifyContent={{ xs: "center", md: "flex-start" }}
              >
                <Button
                  sx={styles.actionBtn}
                  variant="contained"
                  disableElevation
                  onClick={() => {}}
                  component="a"
                  href="https://buy.costbo.com/affiliates"
                >
                  KNOW MORE
                </Button>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={5.5} display="flex" justifyContent={"center"}>
            <Box>
              <Box
                component="img"
                src={`https://storage.googleapis.com/bodefaults/shopweb/home/mobile-app-shopping.png`}
                width="auto"
                height={{ md: "423px", xs: "80%" }}
                alt={businessFullName + " CostBo"}
                sx={{
                  pr: {
                    md: 7,
                    xs: 0,
                    transition: "all 0.3s ease-in-out",
                    transform: "scale(1)",
                    "&:hover": {
                      transform: "scale(0.97)",
                    },
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default CostBoInfluencerCard

const styles = {
  actionBtn: {
    fontSize: "15px",
    fontWeight: 600,
    color: whiteColor,
    p: "8px 25px",
    border: "1px solid " + blackColor,
    textTransform: "uppercase",
    borderRadius: "5px",
    backgroundColor: blackColor,
    marginTop: "24px",
    "&:hover": {
      backgroundColor: whiteColor,
      color: blackColor,
    },
  },
};
