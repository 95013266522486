import { Box, Container, Skeleton } from '@mui/material'
import React from 'react'

const OverviewMobileLoader = () => {
  return (
    <>
    <Skeleton variant="rounded" width="auto" height='34px' sx={{ m:'0 0 24px !important' }} />

    <Container sx={{ maxWidth: "1370px !important" }}>
      <Box>
            <Skeleton variant="rounded" width="90px" height='16px' sx={{ m:'8px 0 24px !important' }} />
            
            <Skeleton variant="rounded" width="auto" height='13px' sx={{ mb:'6px !important' }} />
            <Skeleton variant="rounded" width="auto" height='13px' sx={{ mb:'6px !important' }} />
            <Skeleton variant="rounded" width="auto" height='13px' sx={{ mb:'6px !important' }} />
            <Skeleton variant="rounded" width="300px" height='13px' sx={{ mb:'6px !important' }} />
            
            <Skeleton variant="rounded" width="auto" height='13px' sx={{ m:'25px 0 6px !important' }} />
            <Skeleton variant="rounded" width="auto" height='13px' sx={{ mb:'6px !important' }} />
            <Skeleton variant="rounded" width="auto" height='13px' sx={{ mb:'6px !important' }} />
            <Skeleton variant="rounded" width="600px" height='13px' sx={{ mb:'6px !important' }} />
            
            <Skeleton variant="rounded" width="auto" height='13px' sx={{ m:'25px 0 6px !important' }} />
            <Skeleton variant="rounded" width="auto" height='13px' sx={{ mb:'6px !important' }} />
            <Skeleton variant="rounded" width="auto" height='13px' sx={{ mb:'6px !important' }} />
            <Skeleton variant="rounded" width="600px" height='13px' sx={{ mb:'6px !important' }} />
            
            <Skeleton variant="rounded" width="auto" height='13px' sx={{ m:'25px 0 6px !important' }} />
            <Skeleton variant="rounded" width="auto" height='13px' sx={{ mb:'6px !important' }} />
            <Skeleton variant="rounded" width="auto" height='13px' sx={{ mb:'6px !important' }} />
            <Skeleton variant="rounded" width="600px" height='13px' sx={{ mb:'6px !important' }} />
            
            <Skeleton variant="rounded" width="auto" height='13px' sx={{ m:'25px 0 6px !important' }} />
            <Skeleton variant="rounded" width="auto" height='13px' sx={{ mb:'6px !important' }} />
            <Skeleton variant="rounded" width="auto" height='13px' sx={{ mb:'6px !important' }} />
            <Skeleton variant="rounded" width="600px" height='13px' sx={{ mb:'6px !important' }} />
        </Box>
    </Container>
</>
  )
}

export default OverviewMobileLoader