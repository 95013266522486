import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import {
  blackColor,
  headingColor,
  labelColor,
  whiteColor,
} from "../../configs/styles/muiThemes";

function ReviewSectionHeader(props) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [text, SetText] = useState(props.preselectedOption);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    SetText(event.target.innerText);
    props.onClickMore(
      event.target.innerText ? event.target.innerText : "Most Recent"
    );
  };

  return (
    <Box sx={styles.reviewHeader}>
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        
      >
        <Grid item>
          <Typography
            variant="h4"
            component="h2"
            fontSize={{ md: "15px", xs: "14px" }}
            color={{ md: headingColor, xs: blackColor }}
            align="left"
          >
            {matches
              ? "Customer Reviews"
              : `${
                  matches ? props?.data?.allReview?.length : props?.count
                } Reviews`}
          </Typography>
        </Grid>

          <Grid item sx={{ display: "flex", alignItems: "center" }}>
           {props?.isDataLoaded ? <>
           <Typography variant="body1" align="left" color={labelColor}>
              Sort by:
            </Typography>

            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={styles.dropdownBtn}
              endIcon={<KeyboardArrowDownOutlined />}
            >
              {text}
            </Button>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              variant="selectedMenu"
            >
              <MenuItem onClick={handleClose} value="Profile">
                Most Recent
              </MenuItem>
              <MenuItem onClick={handleClose} value="My account">
                Highest Rating
              </MenuItem>
              <MenuItem onClick={handleClose} value="Logout">
                Lowest Rating
              </MenuItem>
            </Menu>
            </> : <Box height='35px' />}
          </Grid>
      </Grid>
    </Box>
  );
}

export default ReviewSectionHeader;

const styles = {
  reviewHeader: {
    p: { xs: "16px 0 8px 0", md: "0px 0 5px" },
    // borderTop: { md: "1px solid #eff1f2", xs: 0 },
  },

  dropdownBtn: {
    fontSize: { xs: 13, md: 13 },
    fontWeight: 600,
    textTransform: "uppercase",
    color: headingColor,
    "&:hover": {
      backgroundColor: whiteColor,
    },
  },
};
