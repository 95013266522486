import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  blackColor,
  borderTextbox,
  greyColor6,
  greyShade04,
  greyShade07,
  greyShade58,
  orangeColor01,
  whiteColor,
} from 'configs/styles/muiThemes'
import React, { useState } from "react";

function InputPreview({
  label,
  value,
  infoText,
  labelSize,
  valueSize,
  styles,
  valueStyles,
  isReadMoreLess,
  hideBorder,
  hideLabel,
}) {
  const [showMore, setShowMore] = useState(false);
  const charLimit = 220;

  return (
    <Box sx={inputBox(hideBorder)}>
      {!hideLabel ? (
        <Typography
          variant='h3'
          fontSize={{ xs: 12, md: labelSize || 12.5 }}
          color={greyShade58}
          fontWeight={500}
          whiteSpace='wrap'
          mb={{
            xs: labelSize ? '0px !important' : '4px !important',
            md: labelSize ? '0px !important' : '5px !important',
          }}
          sx={{ ...styles }}
        >
          {label}{' '}
          <span style={{ fontSize: '12.5px', color: greyShade07 }}>
            {infoText}
          </span>
        </Typography>
      ) : (
        <Box sx={{ height: '22px' }} />
      )}

      <Typography
        variant='body1'
        fontSize={{ xs: valueSize || 12.5, md: valueSize || 13 }}
        color={blackColor}
        fontWeight={500}
        minHeight={{ md: '34px', xs: '24px' }}
        py={{ md: '4px', xs: '0' }}
        sx={{ textAlign: 'justify', ...valueStyles }}
        lineHeight={'20px'}
        whiteSpace='wrap'
      >
        {isReadMoreLess
          ? showMore
            ? value
            : value?.toString()?.slice(0, charLimit)
          : value || '-'}

        {isReadMoreLess && (
          <span>
            {value?.length > charLimit && (
              <Button
                onClick={() => {
                  setShowMore(!showMore)
                }}
                sx={readBtn}
                textTransform={"uppercase"}
              >
                {showMore ? 'Read less' : 'Read more'}
              </Button>
            )}
          </span>
        )}
      </Typography>
    </Box>
  )
}

export default InputPreview;

const inputBox = (hideBorder) => ({
  pb: { md: 0, xs: 0 },
  borderBottom: hideBorder ? "none" : borderTextbox,
  backgroundColor: whiteColor,
});

const readBtn = {
  fontSize: 12,
  fontWeight: 500,
  color: orangeColor01,
  textTransform: "unset",
  textDecoration: "underline",
  padding: "0px",
  ml: 0.5,
};
