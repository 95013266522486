import { Box, Grid, Typography } from '@mui/material'
import {
  blackColor,
  lightBlueColor01,
  lightOragne01,
  lightPurple01,
  whiteColor,
} from 'configs/styles/muiThemes'
import React from 'react'
import { useSelector } from 'react-redux'
import { CONTACT_INFO, SERVICE_INFO } from 'utils/data'

const ServicesAndContactInfo = () => {
  const { commercialDetails } = useSelector((state) => state.product)
  const { businessDetails } = useSelector((state) => state.header)

  const whatsAppNumber =
    commercialDetails?.info?.costboPowered?.whatsAppNumber?.replace('+', '')

  const emailAddress =
    commercialDetails?.info?.costboPowered?.supportEmail || 'support@costbo.com'

  const handleChatClick = () => {
    if (whatsAppNumber) {
      window.open(
        // `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=Hi+${businessDetails?.businessInfo?.[0]?.businessName}+team,+I+have+a+query+about+the+products+in+your+webstore+${window.location.href}`,
        `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=Hi+${businessDetails?.businessInfo?.[0]?.businessName}+team,+I+have+a+query+about+the+products+in+your+webstore`,
        '_blank'
      )
    } else {
      window.fcWidget.open()
      window.fcWidget.show()
    }
  }

  const handleContactClick = (index) => {
    if (index === 0) {
      handleChatClick()
    } else if (index === 1) {
      window.open(
        `https://mail.google.com/mail/u/0/?fs=1&to=${emailAddress}&tf=cm`,
        '_blank'
      )
    } else {
      handleChatClick()
    }
  }

  return (
    <Box mt='30px' mb={"20px"}>
      <Grid container spacing='30px'>
        {SERVICE_INFO.map((item, index) => (
          <Grid item xs={12} md={3} key={`service_${index}`}>
            <ServiceInfoCard
              icon={item.icon}
              title={item.title}
              subtitle={item.subtitle}
            />
          </Grid>
        ))}
      </Grid>

      {/* <Box mt='50px'>
        <Grid container spacing='30px'>
          {CONTACT_INFO.map((item, index) => (
            <Grid item xs={12} md={4} key={`contact_${index}`}>
              <ContactInfoCard
                icon={item.icon}
                label={item.label}
                bgcolor={item.bgcolor}
                onClick={() => handleContactClick(index)}
              />
            </Grid>
          ))}
        </Grid>
      </Box> */}
    </Box>
  )
}

export default ServicesAndContactInfo

const ServiceInfoCard = ({ icon, title, subtitle }) => {
  return (
    <Box sx={styles.card}>
      <Box component='img' src={icon} sx={{ width: '50px', mb: '10px' }} />

      <Typography
        fontSize={14}
        fontWeight={600}
        color={blackColor}
        lineHeight='20px'
        mb='3px !important'
      >
        {title}
      </Typography>

      <Typography
        fontSize={13}
        fontWeight={500}
        color={blackColor}
        lineHeight='18px'
      >
        {subtitle}
      </Typography>
    </Box>
  )
}

const ContactInfoCard = ({ label, icon, bgcolor,onClick }) => {
  return (
    <Box
      sx={{
        ...styles.contactInfoCard,
        bgcolor: bgcolor,
      }}
      onClick={onClick}
    >
      <Typography
        variant='h6'
        fontSize={18}
        fontWeight={600}
        color={blackColor}
        lineHeight='26px'
        whiteSpace='pre-line'
      >
        {label}
      </Typography>

      <Box component='img' src={icon} sx={{ height: '60px' }} />
    </Box>
  )
}

const styles = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    bgcolor: whiteColor,
    borderRadius: '15px',
    p: '25px',
  },

  contactInfoCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    bgcolor: whiteColor,
    borderRadius: '20px',
    p: '25px',
    cursor:'pointer',
    transition:'all ease 0.4s',
    "&:hover":{
      transform:'scale(1.05)'
    }
  },
}
