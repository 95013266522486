import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import {
  blackColor,
  blackShade8,
  whiteColor,
} from '../../configs/styles/muiThemes'

function HeroSection({ handleGetStarted, rellerProfileData, businessAffiliateData, affiCommissionPercentage }) {

  const businessResellerData = businessAffiliateData?.businessResellers?.[0]
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))

  return (
    <Box
      sx={{
        padding: '15px',
        marginBottom: '1px',
        backgroundImage:
          'radial-gradient(150px 150px at 95% 0%, rgb(252, 239, 236) 0%, rgba(253, 186, 43, 0) 100%), radial-gradient(150px 150px at 5% 0%, rgb(120 213 242 / 34%) 0%, rgb(251 239 237) 100%)',
        margin: '10px 10px',
        borderRadius: '12px',
      }}
      // display='flex'
      // alignItems='center'
      // p='12px'
      // sx={{
      //   backgroundImage: 'url(https://storage.googleapis.com/bodefaults/shopweb/affiliates-banner.png)',
      //   backgroundSize: '100% 100%',
      // }}
    >
      <Box>
        <Typography
          variant='h2'
          fontSize={16}
          fontWeight={700}
          lineHeight='24px'
          color={blackColor}
          sx={{ m: '10px 0 !important' }}
        >
          Direct Product Promotions, Higher commission than marketplaces
        </Typography>

        <Typography
          variant='body2'
          fontSize={13}
          fontWeight={400}
          lineHeight='18px'
          color={blackColor}
          sx={{ m: '8px 0 15px 0 !important' }}
        >
          {/* {businessResellerData?.affiliatePercentage}% commissions on every sale */}
          {affiCommissionPercentage}% commissions on every sale
        </Typography>

        <Button
          variant='contained'
          onClick={handleGetStarted}
          disableElevation
          sx={styles.getStartedBtn}
        >
          {rellerProfileData?.details
            ? 'SHARE & EARN - CLICK HERE'
            : 'GET STARTED - CLICK HERE'}
        </Button>
      </Box>

      {/* <Box
        component='img'
        src='https://storage.googleapis.com/bodefaults/shopweb/affiliates-banner-image.png'
        sx={{ width: '145px', height: 'auto' }}
      /> */}
    </Box>
    // <Box
    //   display='flex'
    //   alignItems='center'
    //   p='12px'
    //   sx={{
    //     backgroundImage:
    //       'url(https://storage.googleapis.com/bodefaults/shopweb/affiliates-banner.png)',
    //     backgroundSize: '100% 100%',
    //   }}
    // >
    //   <Box>
    //     <Typography
    //       variant='h2'
    //       fontSize={15}
    //       fontWeight={500}
    //       lineHeight='24px'
    //       color={blackColor}
    //       sx={{ m: '10px 0 !important' }}
    //     >
    //       Direct Product Promotions, Higher commission than marketplaces
    //     </Typography>

    //     <Typography
    //       variant='body2'
    //       fontSize={13}
    //       fontWeight={400}
    //       lineHeight='18px'
    //       color={blackColor}
    //       sx={{ m: '8px 0 15px 0 !important' }}
    //     >
    //       {/* {businessResellerData?.affiliatePercentage}% commissions on every sale */}
    //       {affiCommissionPercentage}% commissions on every sale
    //     </Typography>

    //     <Button
    //       variant='contained'
    //       onClick={handleGetStarted}
    //       disableElevation
    //       sx={styles.getStartedBtn}
    //     >
    //       {rellerProfileData?.details
    //         ? 'SHARE & EARN - CLICK HERE'
    //         : 'GET STARTED - CLICK HERE'}
    //     </Button>
    //   </Box>

    //   <Box
    //     component='img'
    //     src='https://storage.googleapis.com/bodefaults/shopweb/affiliates-banner-image.png'
    //     sx={{ width: '145px', height: 'auto' }}
    //   />
    // </Box>
      )
}

export default HeroSection

const styles = {
  getStartedBtn: {
    padding: '6px 17px',
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #000',
    color: '#000000',
    borderRadius: '50px',
    height: 'auto',
    fontSize: '12px',
    textTransform: 'uppercase',
    fontWeight: '600',
    lineHeight: '20px',

    '&:hover': {
      color: whiteColor,
      backgroundColor: blackColor,
      textDecoration: 'none',
    },
  },
}
