import { Box, Container, Grid, Skeleton, Stack } from "@mui/material";
import { backgroundColor01, borderColor, whiteColor } from "configs/styles/muiThemes";
import React from "react";

const ConfirmationPageMobileLoader = () => {
  return (
    <Box bgcolor={borderColor}>
      <Skeleton variant="rounded" width="auto" height="55px" />

      <Box mx={2} mt={2}>
        <Stack alignItems='center' bgcolor={whiteColor} p="24px 24px 10px 24px" borderRadius='10px' >
          <Skeleton variant="rounded" width="200px" height="13px" sx={{ m: "0px 0 13px !important" }}/>
          <Skeleton variant="rounded" width="90%" height="10px" sx={{ m: "0px 0 8px !important" }}/>
          <Skeleton variant="rounded" width="70%" height="10px" sx={{ m: "0px 0 15px !important" }}/>

          <Skeleton variant="rounded" width="230px" height="230px" sx={{ m: "15px 0 15px !important", borderRadius:'30px' }}/>

          <Skeleton variant="rounded" width="200px" height="13px" sx={{ m: "10px 0 13px !important" }}/>
          <Skeleton variant="rounded" width="90%" height="10px" sx={{ m: "0px 0 8px !important" }}/>
          <Skeleton variant="rounded" width="70%" height="10px" sx={{ m: "0px 0 8px !important" }}/>
          <Skeleton variant="rounded" width="80%" height="10px" sx={{ m: "0px 0 15px !important" }}/>
          <Skeleton variant="rounded" width="60%" height="38px" sx={{ m: "0px 0 15px !important" }}/>
        </Stack>

        <Skeleton
          variant="rounded"
          width="auto"
          height="300px"
          sx={{ m: "18px 0 15px !important", borderRadius: "10px" }}
        />
      </Box>
    </Box>
  );
};

export default ConfirmationPageMobileLoader;
