import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  backgroundColor01,
  bgColor15,
  blackColor,
  blackShade19,
  blueColor04,
  blueShade10,
  borderLineColor,
  breadcrumbBgColor,
  completedColor,
  greenColor05,
  greenColor06,
  greenColor07,
  greyColor9,
  greyShade58,
  greyShade72,
  headingColor,
  orangeColor01,
  orangeShade10,
  orderDateColor,
  perpleShade10,
  whiteColor,
} from "../../configs/styles/muiThemes";
import OrderStep from "./OrderStep";
import { LocalShippingRounded } from "@mui/icons-material";

import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { useEffect, useState } from "react";
import { getCartOrderDetails } from "services/api_calls/orderdetails_api/OrderDetailsApi";
import ModalCard from "components/ModalCard";
import TrackCard from "./TrackCard";
import { useDispatch, useSelector } from "react-redux";
import { getCartDetailsById } from "redux/addToCart/AddToCartSlice";
import {
  resetDelhiveryStatus,
  resetOrderDetails,
} from "redux/orderDetails/OrderDetailsSlice";
import {
  orders_OrderIDLabel,
  orders_Quantity,
  orders_TotalPriceLabel,
  orders_TrackLabel,
  orders_ViewLabel,
} from "configs/Constants";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import HorizontalStepper from "components/HorizontalStepper";
import HistoryIcon from "@mui/icons-material/History";
import UpdateIcon from "@mui/icons-material/Update";
import PersonIcon from "@mui/icons-material/Person";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import PinDropIcon from "@mui/icons-material/PinDrop";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import CancelCard from "pages/OrderDetails/CancelCard";
import { getCancelDateByStatus, getCancelledByText } from "utils/utilities";

function OrderCard({
  cartId,
  canceled,
  completed,
  orderId,
  date,
  quantity,
  totalPrice,
  orderStatus,
  cartDate,
  orderData,
  fromPage,
  ondcOrderId,
  ondcOrderType,
  invoiceDownloadLoader,
  downloadInvoice,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  // Steps Data
  const steps = [
    {
      label: "Created",
      date: orderData?.orderCreatedOn
        ? moment(orderData?.orderCreatedOn).format("MMM DD, YYYY")
        : "",
      time: orderData?.orderCreatedOn
        ? moment(orderData?.orderCreatedOn).format("hh:mm A")
        : "",
    },
    {
      label:
        orderStatus === "bordercanceled" ||
        orderStatus === "cordercanceled" ||
        orderStatus === "lordercanceled"
          ? "Cancelled"
          : "Approved",
      date: orderData?.orderApprovedOn
        ? moment(orderData?.orderApprovedOn).format("MMM DD, YYYY")
        : "",
      time: orderData?.orderApprovedOn
        ? moment(orderData?.orderApprovedOn).format("hh:mm A")
        : "",
    },
    {
      label: "Shipped",
      date: orderData?.orderShippedOn
        ? moment(orderData?.orderShippedOn).format("MMM DD, YYYY")
        : "",
      time: orderData?.orderShippedOn
        ? moment(orderData?.orderShippedOn).format("hh:mm A")
        : "",
    },
    {
      label: "Delivered",
      date: orderData?.orderDeliveredOn
        ? moment(orderData?.orderDeliveredOn).format("MMM DD, YYYY")
        : "",
      time: orderData?.orderDeliveredOn
        ? moment(orderData?.orderDeliveredOn).format("hh:mm A")
        : "",
    },
  ];
  const dispatch = useDispatch();
  const isOrderCreated =
    orderStatus == "ordercreated" ||
    orderStatus == "orderapproved" ||
    orderStatus == "ordershipped" ||
    orderStatus == "orderdelivered";
  // || orderStatus == 'paycartcreated'

  const isOrderApproved =
    orderStatus == "orderapproved" ||
    orderStatus == "ordershipped" ||
    orderStatus == "orderdelivered";

  const isOrderShipped =
    orderStatus == "ordershipped" || orderStatus == "orderdelivered";

  const isOrderDelivered = orderStatus == "orderdelivered";
  const isCancelled = orderStatus == "bordercanceled";

  const [cartData, setCartData] = useState(null);

  const businessName = sessionStorage.getItem("businessName");

  let navigate = useNavigate();

  const [isModal, setIsModal] = useState(false);

  const handleModalClose = () => {
    setIsModal(false);
    dispatch(resetOrderDetails());
    dispatch(resetDelhiveryStatus());
  };

  return matches ? (
    <Box
      sx={{
        background: whiteColor,
        // padding: '10px 10px',
        borderRadius: "12px",
        border: "1px solid #E1E1EE",
        // borderBottom: '14px',
        margin: "20px 0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          borderBottom: "1px solid #E1E1EE",
          // paddingBottom: '8px',
          padding: "8px 10px",
          marginBottom: "8px",
          width: "98%",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: whiteColor,
          borderRadius: "12px 12px 0px 0px",
          gap: "100px",
        }}
      >
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent="space-between"
          flex={1.3}
          gap={1}
        >
          <Box display={"flex"} alignItems="center">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "50px",
                height: "50px",
                bgcolor: whiteColor,
                borderRadius: "50px",
              }}
            >
              <Box
                component="img"
                sx={{ width: "50px", height: "50px" }}
                src="https://storage.googleapis.com/bodefaults/shopweb/orders/order-truck.svg"
                alt="CostBoDiscounts"
              />
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              ml={1}
              onClick={() => {
                navigate(`/${businessName}/OrderDetails/${cartId}`, {
                  state: { cartDate, fromPage },
                });
              }}
            >
              <Typography
                sx={{
                  fontSize: "13px",
                  lineHeight: "20px",
                  color: "#000",
                  fontWeight: 600,
                  marginTop: "0px",
                }}
              >
                {orders_OrderIDLabel}
                {orderId}
              </Typography>
              <Typography
                sx={{
                  fontSize: "11.5px",
                  lineHeight: "20px",
                  color: greyColor9,
                  fontWeight: 500,
                  marginTop: "0px",
                }}
              >
                {date
                  ? moment(date).format("MMM DD, YYYY")
                  : cartDate
                  ? moment(cartDate).format("MMM DD, YYYY")
                  : ""}
              </Typography>
            </Box>
          </Box>
          {ondcOrderId && (
            <Box>
              <Typography
                sx={{
                  fontSize: "12.5px",
                  lineHeight: "20px",
                  color: blackColor,
                  fontWeight: 600,
                }}
              >
                ONDC Order - {ondcOrderType}
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  lineHeight: "20px",
                  color: greenColor06,
                  fontWeight: 600,
                  marginTop: "0px",
                  textAlign: "center",
                }}
              >
                # {ondcOrderId}
              </Typography>
            </Box>
          )}
        </Box>

        <Box
          display={"flex"}
          alignItems="center"
          justifyContent="space-between"
          flex={1}
          gap={1}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "12.5px",
                lineHeight: "20px",
                color: "#9090a3",
                fontWeight: 400,
              }}
            >
              Quantity
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "18px",
                color: "#000",
                fontWeight: 600,
                marginTop: "0px",
                textAlign: "center",
              }}
            >
              {quantity}
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{
                fontSize: "12.5px",
                lineHeight: "20px",
                color: "#9090a3",
                fontWeight: 400,
              }}
            >
              Total Price
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "18px",
                color: "#000",
                fontWeight: 600,
                marginTop: "0px",
                textAlign: "center",
              }}
            >
              ₹ {totalPrice}
            </Typography>
          </Box>

          <Stack alignItems="flex-end" mr={2}>
            <Typography
              variant="subtitle1"
              fontSize={{ xs: 13, md: 12.5 }}
              fontWeight={500}
              lineHeight="24px"
              color={
                orderStatus === "ordercreated"
                  ? "#fca549"
                  : orderStatus === "orderapproved"
                  ? "#c94b85"
                  : orderStatus === "orderdelivered"
                  ? completedColor
                  : orderStatus === "ordershipped"
                  ? "#c94b85"
                  : orderStatus === "bordercanceled" ||
                    orderStatus === "cordercanceled" ||
                    orderStatus === "lordercanceled"
                  ? "#E53935"
                  : "#fca549"
              }
              mt={0.5}
            >
              {getOrderStatus(orderStatus)}
            </Typography>

            {orderStatus === "orderdelivered" && (
              <Typography
                color={greyColor9}
                fontSize={11.5}
                fontWeight={500}
                lineHeight="15px"
              >
                {orderData?.orderDeliveredOn
                  ? moment(orderData?.orderDeliveredOn)?.format("Do MMM, YYYY")
                  : "N/A"}
                {orderData?.orderDeliveredOn
                  ? ` at ${moment(orderData?.orderDeliveredOn)?.format(
                      "hh:mm A"
                    )}`
                  : ""}
              </Typography>
            )}
          </Stack>
        </Box>
      </Box>

      {(orderStatus == "orderapproved" || orderStatus == "ordershipped") &&
      orderData?.deliveryType?.type === "ondc" ? (
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #E1E1EE",
            // paddingBottom: '8px',
            padding: "8px 10px 16px",
            marginBottom: "8px",
            width: "98%",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: whiteColor,
          }}
        >
          <Stack gap={0.8}>
            {orderStatus == "orderapproved" && (
              <Box display="flex" gap="6px">
                <UpdateIcon sx={{ color: greyShade72, fontSize: 14 }} />

                <Typography
                  color={greyShade72}
                  fontSize={11.5}
                  fontWeight={500}
                  lineHeight="15px"
                >
                  Estimated Pickup:{" "}
                </Typography>
                <Typography
                  color={greenColor06}
                  fontSize={11.5}
                  fontWeight={600}
                  lineHeight="15px"
                >
                  {orderData?.shipmentInfo?.etaPickUpDate
                    ? moment(orderData?.shipmentInfo?.etaPickUpDate)?.format(
                        "Do MMM, YYYY"
                      )
                    : "N/A"}
                  {orderData?.shipmentInfo?.etaPickUpDate &&
                  orderData?.shipmentInfo?.etaPickUpTime
                    ? ` at ${moment(
                        orderData?.shipmentInfo?.etaPickUpTime
                      )?.format("hh:mm A")}`
                    : ""}
                </Typography>
              </Box>
            )}
            <Box display="flex" justifyContent="space-between" gap={2}>
              <IconInfoCard
                icon={<PersonIcon sx={{ color: blackColor, fontSize: 16 }} />}
                value={orderData?.shipmentInfo?.deliveryPersonName || "N/A"}
              />
              <IconInfoCard
                icon={
                  <CallRoundedIcon sx={{ color: blackColor, fontSize: 16 }} />
                }
                value={orderData?.shipmentInfo?.deliveryPersonPhone || "N/A"}
              />
            </Box>
          </Stack>

          {/* <TimeCard
            label="Approval Time:"
            value="3 hrs 15 mins"
            icon={<HistoryIcon sx={{ color: blueColor04, fontSize: 14 }} />}
            color={blueColor04}
          /> */}

          <TimeCard
            label="Estimated Delivery:"
            value={`${
              orderData?.shipmentInfo?.etaDeliveryDate
                ? moment(orderData?.shipmentInfo?.etaDeliveryDate)?.format(
                    "Do MMM, YYYY"
                  )
                : "N/A"
            }
                ${
                  orderData?.shipmentInfo?.etaDeliveryDate &&
                  orderData?.shipmentInfo?.etaDeliveryTime
                    ? `at ${moment(
                        orderData?.shipmentInfo?.etaDeliveryTime
                      )?.format("hh:mm A")}`
                    : ""
                }`}
            icon={<UpdateIcon sx={{ color: greenColor07, fontSize: 14 }} />}
            color={greenColor07}
          />
        </Box>
      ) : null}

      <Box>
        <Grid container>
          <Grid item md={9}>
            {orderStatus === "bordercanceled" ||
            orderStatus === "cordercanceled" ||
            orderStatus === "lordercanceled" ? (
              <CancelCard
                canceledBy={getCancelledByText(orderStatus)}
                cancelDate={getCancelDateByStatus(orderData)}
                reason={
                  orderData?.cancelInfo
                    ? orderData?.cancelInfo?.cancelReason
                    : orderData?.orderStatus === "bordercanceled"
                    ? orderData?.notesBusiness
                    : orderData?.notesConsumer
                }
                isCard
              />
            ) : (
              <HorizontalStepper
                steps={steps}
                activeStep={
                  orderStatus == "ordercreated" ||
                  orderStatus == "paycartcreated"
                    ? 0
                    : orderStatus == "orderapproved" ||
                      orderStatus == "bordercanceled" ||
                      orderStatus === "cordercanceled" ||
                      orderStatus === "lordercanceled"
                    ? 1
                    : orderStatus == "ordershipped"
                    ? 2
                    : orderStatus == "orderdelivered"
                    ? 3
                    : orderStatus
                }
                canceled={
                  orderStatus == "bordercanceled" ||
                  orderStatus === "cordercanceled" ||
                  orderStatus === "lordercanceled"
                }
              />
            )}
          </Grid>

          <Grid
            item
            md={3}
            xs={3}
            alignItems={"center"}
            justifyItems={"flex-end"}
          >
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              // alignItems={"center"}
              gap={1}
              pt={4}
              pr={3}
            >
              <IconButton
                sx={styles.actionIconBtn(blueShade10)}
                onClick={() => {
                  navigate(`/${businessName}/OrderDetails/${cartId}`, {
                    state: { cartDate, fromPage },
                  });
                }}
              >
                <VisibilityIcon sx={{ fontSize: "18px" }} />
              </IconButton>
              {orderStatus !== "bordercanceled" &&
                orderStatus !== "cordercanceled" &&
                orderStatus !== "lordercanceled" &&
                orderStatus !== "ordercreated" &&
                orderStatus !== "orderdelivered" &&
                orderData?.deliveryType?.type !== "business" && (
                  <IconButton
                    sx={styles.actionIconBtn(perpleShade10)}
                    onClick={() => setIsModal(true)}
                  >
                    <PinDropIcon sx={{ fontSize: "18px" }} />
                  </IconButton>
                )}

              {orderStatus !== "bordercanceled" &&
                orderStatus !== "cordercanceled" &&
                orderStatus !== "lordercanceled" && (
                  <Button
                    sx={styles.actionBtn(orangeShade10)}
                    onClick={() => {
                      downloadInvoice();
                    }}
                    startIcon={
                      invoiceDownloadLoader == orderData?.orderId ? (
                        <CircularProgress size={15} color="inherit" />
                      ) : (
                        <DownloadIcon sx={{ fontSize: "15px !important" }} />
                      )
                    }
                    disabled={invoiceDownloadLoader == orderData?.orderId}
                  >
                    Invoice
                  </Button>
                )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <ModalCard
        handleClose={handleModalClose}
        open={isModal}
        width="35%"
        height={"50%"}
      >
        <TrackCard
          orderId={orderId}
          orderStatus={getOrderStatus(orderStatus)}
          orderData={orderData}
          cartId={cartId}
          itemCount={orderData?.totalQuantity}
          totalCost={orderData?.orderTotalCost}
          deliveryType={orderData?.deliveryType?.type}
          ondcOrderType={ondcOrderType}
          ondcOrderId={ondcOrderId}
        />
      </ModalCard>
    </Box>
  ) : (
    <Box
      sx={{
        background: "#fff",
        padding: "10px 10px",
        borderRadius: "12px",
        border: "1px solid #E1E1EE",
        // borderBottom: '14px',
        margin: "10px 0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          borderBottom: "1px solid #E1E1EE",
          paddingBottom: "8px",
          marginBottom: "8px",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          // ml={1}
          onClick={() => {
            navigate(`/${businessName}/OrderDetails/${cartId}`, {
              state: { cartDate, fromPage },
            });
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              lineHeight: "20px",
              color: "#000",
              fontWeight: 600,
              marginTop: "0px",
            }}
          >
            {orders_OrderIDLabel}
            {orderId}
          </Typography>
          <Typography
            sx={{
              fontSize: "11px",
              lineHeight: "16px",
              color: greyShade58,
              fontWeight: 400,
              marginTop: "0px",
            }}
          >
            {date
              ? moment(date).format("MMM DD, YYYY")
              : cartDate
              ? moment(cartDate).format("MMM DD, YYYY")
              : ""}
          </Typography>
          {ondcOrderId && (
            <>
              <Typography
                sx={{
                  fontSize: "12px",
                  lineHeight: "20px",
                  color: "#000",
                  fontWeight: 600,
                  marginTop: "0px",
                }}
              >
                ONDC Order - {ondcOrderType}
              </Typography>
              <Typography
                sx={{
                  fontSize: "11px",
                  lineHeight: "20px",
                  color: greenColor06,
                  fontWeight: 600,
                  marginTop: "0px",
                }}
              >
                # {ondcOrderId}
              </Typography>
            </>
          )}
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            fontSize={{ xs: 12, md: 14 }}
            fontWeight={500}
            lineHeight="20px"
            textAlign="end"
            color={
              orderStatus === "ordercreated"
                ? "#fca549"
                : orderStatus === "orderapproved"
                ? "#c94b85"
                : orderStatus === "orderdelivered"
                ? completedColor
                : orderStatus === "ordershipped"
                ? "#c94b85"
                : orderStatus === "bordercanceled" ||
                  orderStatus === "cordercanceled" ||
                  orderStatus === "lordercanceled"
                ? "#E53935"
                : "#fca549"
            }
            // mt={0.5}
          >
            {getOrderStatus(orderStatus)}
          </Typography>
          {orderStatus === "orderdelivered" && (
            <Typography
              sx={{
                fontSize: "11px",
                lineHeight: "16px",
                color: greyShade58,
                fontWeight: 400,
                marginTop: "0px",
                textAlign: "end",
              }}
            >
              {orderData?.orderDeliveredOn
                ? moment(orderData?.orderDeliveredOn)?.format("Do MMM, YYYY")
                : "N/A"}
              {orderData?.orderDeliveredOn
                ? ` at ${moment(orderData?.orderDeliveredOn)?.format(
                    "hh:mm A"
                  )}`
                : ""}
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          borderBottom: "1px solid #E1E1EE",
          paddingBottom: "8px",
          marginBottom: "8px",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: "11px",
              lineHeight: "20px",
              color: greyShade58,
              fontWeight: 400,
              marginTop: "0px",
            }}
          >
            Quantity
          </Typography>
          <Typography
            sx={{
              fontSize: "13px",
              lineHeight: "20px",
              color: blackColor,
              fontWeight: 600,
              marginTop: "0px",
              textAlign: "center",
            }}
          >
            {quantity}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: "11px",
              lineHeight: "20px",
              color: greyShade58,
              fontWeight: 400,
              marginTop: "0px",
            }}
          >
            Total Price
          </Typography>
          <Typography
            sx={{
              fontSize: "13px",
              lineHeight: "20px",
              color: blackColor,
              fontWeight: 600,
              marginTop: "0px",
              textAlign: "center",
            }}
          >
            ₹ {totalPrice}
          </Typography>
        </Box>
      </Box>

      {(orderStatus == "orderapproved" || orderStatus == "ordershipped") &&
      orderData?.deliveryType?.type === "ondc" ? (
        <Stack
          sx={{
            borderBottom: "1px solid #E1E1EE",
            marginBottom: "8px",
            pb: "8px",
            gap: 0.6,
          }}
        >
          {orderStatus == "orderapproved" && (
            <Box display="flex" gap="6px">
              <UpdateIcon sx={{ color: greyShade72, fontSize: 14 }} />

              <Typography
                color={greyShade72}
                fontSize={{ md: 11.5, xs: 11 }}
                fontWeight={500}
                lineHeight="15px"
              >
                Estimated Pickup:{" "}
              </Typography>
              <Typography
                color={greenColor06}
                fontSize={{ md: 11.5, xs: 12 }}
                fontWeight={600}
                lineHeight="15px"
              >
                {orderData?.shipmentInfo?.etaPickUpDate
                  ? moment(orderData?.shipmentInfo?.etaPickUpDate)?.format(
                      "Do MMM, YYYY"
                    )
                  : "N/A"}
                {orderData?.shipmentInfo?.etaPickUpDate &&
                orderData?.shipmentInfo?.etaPickUpTime
                  ? ` at ${moment(
                      orderData?.shipmentInfo?.etaPickUpTime
                    )?.format("hh:mm A")}`
                  : ""}
              </Typography>
            </Box>
          )}
          <Box display="flex" justifyContent="space-between" gap={2} mb={0.4}>
            <IconInfoCard
              icon={<PersonIcon sx={{ color: blackColor, fontSize: 16 }} />}
              value={orderData?.shipmentInfo?.deliveryPersonName || "N/A"}
            />
            <IconInfoCard
              icon={
                <CallRoundedIcon sx={{ color: blackColor, fontSize: 16 }} />
              }
              value={orderData?.shipmentInfo?.deliveryPersonPhone || "N/A"}
            />
          </Box>
          <TimeCard
            label="Estimated Delivery:"
            value={`${
              orderData?.shipmentInfo?.etaDeliveryDate
                ? moment(orderData?.shipmentInfo?.etaDeliveryDate)?.format(
                    "Do MMM, YYYY"
                  )
                : "N/A"
            }
                ${
                  orderData?.shipmentInfo?.etaDeliveryDate &&
                  orderData?.shipmentInfo?.etaDeliveryTime
                    ? `at ${moment(
                        orderData?.shipmentInfo?.etaDeliveryTime
                      )?.format("hh:mm A")}`
                    : ""
                }`}
            icon={<UpdateIcon sx={{ color: greenColor07, fontSize: 14 }} />}
            color={greenColor07}
          />
        </Stack>
      ) : null}

      <Box sx={{ borderBottom: "1px solid #E1E1EE", marginBottom: "8px" }}>
        {orderStatus === "bordercanceled" ||
        orderStatus === "cordercanceled" ||
        orderStatus === "lordercanceled" ? (
          <CancelCard
            canceledBy={getCancelledByText(orderStatus)}
            cancelDate={getCancelDateByStatus(orderData)}
            reason={
              orderData?.cancelInfo
                ? orderData?.cancelInfo?.cancelReason
                : orderData?.orderStatus === "bordercanceled"
                ? orderData?.notesBusiness
                : orderData?.notesConsumer
            }
            isCard
          />
        ) : (
          <HorizontalStepper
            steps={steps}
            activeStep={
              orderStatus == "ordercreated" || orderStatus == "paycartcreated"
                ? 0
                : orderStatus == "orderapproved" ||
                  orderStatus == "bordercanceled" ||
                  orderStatus === "cordercanceled" ||
                  orderStatus === "lordercanceled"
                ? 1
                : orderStatus == "ordershipped"
                ? 2
                : orderStatus == "orderdelivered"
                ? 3
                : orderStatus
            }
            canceled={
              orderStatus == "bordercanceled" ||
              orderStatus === "cordercanceled" ||
              orderStatus === "lordercanceled"
            }
          />
        )}
      </Box>
      <Box display="flex" gap={1} justifyContent="flex-end">
        <IconButton
          sx={styles.actionIconBtn(blueShade10)}
          onClick={() => {
            navigate(`/${businessName}/OrderDetails/${cartId}`, {
              state: { cartDate, fromPage },
            });
          }}
        >
          <VisibilityIcon sx={{ fontSize: "18px" }} />
        </IconButton>
        {orderStatus !== "bordercanceled" &&
          orderStatus !== "cordercanceled" &&
          orderStatus !== "lordercanceled" &&
          orderStatus !== "ordercreated" &&
          orderStatus !== "orderdelivered" &&
          orderData?.deliveryType?.type !== "business" && (
            <IconButton
              sx={styles.actionIconBtn(perpleShade10)}
              onClick={() => setIsModal(true)}
            >
              <PinDropIcon sx={{ fontSize: "18px" }} />
            </IconButton>
          )}

        {orderStatus !== "bordercanceled" &&
          orderStatus !== "cordercanceled" &&
          orderStatus !== "lordercanceled" && (
            <Button
              sx={styles.actionBtn(orangeShade10)}
              onClick={() => {
                downloadInvoice();
              }}
              startIcon={
                invoiceDownloadLoader == orderData?.orderId ? (
                  <CircularProgress size={15} color="inherit" />
                ) : (
                  <DownloadIcon sx={{ fontSize: "15px !important" }} />
                )
              }
              disabled={invoiceDownloadLoader == orderData?.orderId}
            >
              Invoice
            </Button>
          )}
      </Box>
      <ModalCard handleClose={handleModalClose} open={isModal} width="30%">
        <TrackCard
          orderId={orderId}
          orderStatus={getOrderStatus(orderStatus)}
          orderData={orderData}
          cartId={cartId}
          itemCount={orderData?.totalQuantity}
          totalCost={orderData?.orderTotalCost}
          deliveryType={orderData?.deliveryType?.type}
          ondcOrderType={ondcOrderType}
          ondcOrderId={ondcOrderId}
        />
      </ModalCard>
    </Box>
  );
}

export default OrderCard;

const getOrderStatus = (status) => {
  switch (status) {
    case "ordercreated":
      return "Created";

    case "orderapproved":
      return "Approved";

    case "ordershipped":
      return "Shipped";

    case "orderdelivered":
      return "Completed";

    case "bordercanceled":
      return "Canceled";

    case "cordercanceled":
      return "Canceled";

    case "lordercanceled":
      return "Canceled";

    case "paycartcreated":
      return "Easy Cart Created";

    case "paycartcreated":
      return "Easy Cart Created";

    default:
      return "";
  }
};

const TimeCard = ({ label, value, icon, color }) => {
  return (
    <Box display="flex" gap="6px" pr={2}>
      {icon}
      {/* <Stack gap={0.5}> */}
      <Typography
        color={color}
        fontSize={11.5}
        fontWeight={500}
        lineHeight="15px"
      >
        {label}
      </Typography>
      <Typography
        color={color}
        fontSize={11.5}
        fontWeight={700}
        lineHeight="15px"
      >
        {value}
      </Typography>
      {/* </Stack> */}
    </Box>
  );
};

const IconInfoCard = ({ value, icon }) => {
  return (
    <Box display="flex" gap="8px">
      {icon}

      <Typography
        color={blackColor}
        fontSize={12}
        fontWeight={600}
        lineHeight="17px"
      >
        {value}
      </Typography>
    </Box>
  );
};

const styles = {
  actionIconBtn: (color) => ({
    padding: "4px 10px",
    fontSize: "12px",
    borderRadius: "50px",
    fontWeight: 600,
    color: blackShade19,
    backgroundColor: color,
    border: "1px solid " + color,
    whiteSpace: "nowrap",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: whiteColor,
    },
  }),
  actionBtn: (color, fontSize) => ({
    padding: "2px 15px",
    fontSize: fontSize || "12px",
    borderRadius: "50px",
    fontWeight: 600,
    color: blackShade19,
    backgroundColor: color,
    border: "1px solid " + color,
    whiteSpace: "nowrap",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: whiteColor,
    },
    "&.Mui-disabled": {
      border: "none",
    },
  }),

  legendDot: {
    width: "4px",
    height: "4px",
    backgroundColor: bgColor15,
    borderRadius: "50%",
  },
};
