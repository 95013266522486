import { Box, Container, Grid, Skeleton } from '@mui/material'
import { backgroundColor01, whiteColor } from 'configs/styles/muiThemes'
import React from 'react'
import CartItemsLoaderMinimal from '../cart/CartItemsLoaderMinimal'

const OrderDetailsLoader = () => {
  return (
    <Box bgcolor={backgroundColor01}>
      <Container
        sx={{
          maxWidth: "1350px !important",
          p: "0 10px 0 15px !important",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
          
             <Skeleton variant="rounded" width="auto" height="288px" sx={{mr:'5px !important'}}/>

             <Box sx={{p:'10px 15px', bgcolor: whiteColor, borderRadius:'8px', mt:3}}>

                <Skeleton variant="rounded" width="100px" height="16px" sx={{m:'12px 0 18px 0px !important'}} />
                
                <CartItemsLoaderMinimal />
             </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Skeleton variant="rounded" width="100px" height="13px" sx={{mt:'0px !important'}} />
            <Skeleton variant="rounded" width="auto" height="185px" sx={{mt:'12px !important'}} />

            <Skeleton variant="rounded" width="100px" height="13px" sx={{mt:'18px !important'}}/>
            <Skeleton variant="rounded" width="auto" height="179px" sx={{mt:'12px !important'}} />

            <Skeleton variant="rounded" width="100px" height="13px" sx={{mt:'18px !important'}}/>
            <Skeleton variant="rounded" width="auto" height="58px" sx={{mt:'12px !important'}} />

            <Skeleton variant="rounded" width="100px" height="13px" sx={{mt:'18px !important'}}/>
            <Skeleton variant="rounded" width="auto" height="58px" sx={{mt:'12px !important'}} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default OrderDetailsLoader