import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import {
  backgroundColor,
  blackColor,
  borderLineColor,
  couponColor,
  headingColor,
  whiteColor,
} from '../../configs/styles/muiThemes'
import { PaymentRounded } from '@mui/icons-material'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'

function PaymentType({ paymentType }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))

  return (
    <>
      <Box
        sx={{ backgroundColor: whiteColor }}
        border={"1px solid " + borderLineColor}
        borderRadius={2.2}
        p={{ xs: "10px 0px 0px 10px", md: "5px 0px 5px 10px" }}
        mb={{ xs: 3, md: 2 }}
        mt={{ xs: 1, md: 1 }}
      >
        <Box display={"flex"} alignItems={"center"}>
          {paymentType !== "cod-delhivery" ? (
            <PaymentRounded sx={{color: blackColor}} />
          ) : (
            <Box
              component="img"
              src="https://storage.googleapis.com/bodefaults/shopweb/cod-icon.jpeg"
              alt="cod icon"
              width="40px"
              height="40px"
              sx={{ objectFit: "contain",alignItems:"center " }}
            />
          )}
          <Typography
            variant="subtitle1"
            component="p"
            fontSize={{ xs: 13, md: 13 }}
            color={blackColor}
            sx={{ textTransform: "capitalize" }}
            // mb={2}
            ml={1}
            alignItems={"center"}
            textAlign={"center"}
            lineHeight={"38px"}
          >
            {/* Regular Delivery (2-5 days) {shipType} */}
            {paymentType === "cod-delhivery"
              ? "COD - Delhivery"
              : paymentType || "N/A"}
          </Typography>
        </Box>
        {/* {!matches && (
          <Box
            display={'flex'}
            borderBottom={'1px solid ' + borderLineColor}
            pb={1}
            mb={2}
          >
            <Typography
              variant='h5'
              component='h3'
              fontSize={{ xs: 14, md: 15 }}
              color={{ md: headingColor, xs: blackColor }}
              textAlign={{ md: 'right', xs: 'left' }}
              mb={1}
            >
              Payment Type
            </Typography>
          </Box>
        )} */}
        {/* <Grid container spacing={1}>
          <Grid item>
            {paymentType !== "cod-delhivery" ? (
              <PaymentRounded />
            ) : (
              <Box
                component="img"
                src="https://storage.googleapis.com/bodefaults/shopweb/cod-icon.jpeg"
                alt="cod icon"
                width="40px"
                height="40px"
                sx={{ objectFit: "contain" }}
              />
            )}
          </Grid>

          <Grid item>
            <Typography
              variant="h6"
              fontWeight={500}
              fontSize={13}
              component="p"
              color={blackColor}
              ml={1}
              textAlign={"center"}
            >
              {paymentType === "cod-delhivery"
                ? "COD - Delhivery"
                : paymentType || "N/A"}
            </Typography>
          </Grid>
        </Grid> */}
      </Box>
    </>
  );
}

export default PaymentType
