import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import {
  blackColor,
  borderCard04,
  greyShade49,
  whiteColor,
} from "configs/styles/muiThemes";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { useState } from "react";

function GeneralInformationCard({ title, data }) {
  const [showMore, setShowMore] = useState(false);
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return (
    <Stack
      sx={{
        overflow: "hidden",
        borderRadius: "8px",
        border: { xs: "none", md: borderCard04 },
        height: "100%",
        bgcolor: { xs: "transparent", md: whiteColor },
      }}
    >
      <Box
        sx={{
          bgcolor: { md: greyShade49, xs: "transparent" },
          p: { md: "10px 12px", xs: "0 10px 10px 0" },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          fontSize={13.5}
          fontWeight={600}
          color={blackColor}
          lineHeight="15px"
        >
          {title}
        </Typography>
        <Box
          sx={{
            // width: "20px",
            height: "20px",
            // bgcolor: "red",
            display: { xs: "flex", md: "none" },
          }}
        >
          {!showMore ? (
            <ExpandMoreRoundedIcon onClick={() => setShowMore(!showMore)} />
          ) : (
            <ExpandLessRoundedIcon onClick={() => setShowMore(!showMore)} />
          )}
        </Box>
      </Box>
      {(matches || showMore) && (
        <Stack
          p="12px"
          gap="6px"
          sx={{
            bgcolor: whiteColor,
            borderRadius: { md: "0", xs: "8px" },
            border: { md: "none", xs: borderCard04 },
          }}
        >
          {data?.map((item, i) => (
            <Box
              display="flex"
              gap={1}
              key={i}
              pb="8px"
              mb={i + 1 !== data?.length && "8px"}
              borderBottom={i + 1 !== data?.length && "1px solid" + greyShade49}
            >
              <Typography
                fontSize={13}
                fontWeight={400}
                color={blackColor}
                lineHeight="15px"
                sx={{ width: "40%" }}
              >
                {item?.label}
              </Typography>
              <Typography
                fontSize={13}
                fontWeight={500}
                color={blackColor}
                lineHeight="15px"
                sx={{ width: "60%", textTransform: "capitalize" }}
                textAlign={"end"}
              >
                {item?.value || "-"}
              </Typography>
            </Box>
          ))}
        </Stack>
      )}
    </Stack>
  );
}

export default GeneralInformationCard;
