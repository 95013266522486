import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material'
import AuthTextBox from 'components/AuthTextBox'
import {
  enterPasswordMsg,
  inputError_NotRegistered,
  inputError_NumberPasswordNotMatch,
} from 'configs/Constants'
import {
  blackColor,
  errorTextColor,
  headingColor,
  labelColor,
  loginPrimary,
  orangeColor01,
  secondaryColor,
  stepperIconColor,
  whiteColor,
} from 'configs/styles/muiThemes'
import React, { useState } from 'react'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import { Link } from 'react-router-dom'
import { decryptData, encryptData } from 'utils/authHelpers'
import { validatePassword } from 'utils/validationUtils'
import { getCode, getLoginDetials } from 'redux/authRedux/authSlice'
import { useDispatch } from 'react-redux'

function PasswordScreen({
  mobileNumber,
  onChangeNumber,
  onForgotClick,
  onLoginSuccess,
}) {
  const [showPassword, setShowPassword] = useState(true)
  const [password, setPassword] = useState(
    decryptData(localStorage.getItem('password') || '') || ''
  )
  const [isRememberMe, setIsRememberMe] = useState(
    localStorage.getItem('rememberme')
      ? JSON.parse(localStorage.getItem('rememberme'))
      : false
  )
  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)
  const [errorMsgValue, setErrorMsgValue] = useState('')
  const dispatch = useDispatch()

  const handleSubmit = () => {
    setLoading(true)
    if (isRememberMe) {
      if (password.length > 0) {
        localStorage.setItem('phone', mobileNumber)
        localStorage.setItem('password', encryptData(password))
        localStorage.setItem('rememberme', true)
      } else {
        localStorage.removeItem('phone')
        localStorage.removeItem('password')
        localStorage.removeItem('rememberme')
      }
    }
    const error = validatePassword(password)
    if (error?.length > 0) {
      setIsError(true)
      setLoading(false)
      return
    }
    dispatch(getLoginDetials({ phone: mobileNumber, password })).then(
      (response) => {
        console.log('LOGEGDIN', response)
        if (response.payload) {
          if (response.payload.status === 200) {
            console.log('LOGIN RESPONSE 200', response)
            onLoginSuccess()
          }
          if (response.payload.status === 400) {
            console.log('LOGIN RESPONSE 400', response)
            setErrorMsg(true)
            setErrorMsgValue(inputError_NumberPasswordNotMatch)
          }
          if (response.payload.status === 401) {
            console.log('LOGIN RESPONSE 401', response)
            setErrorMsgValue(inputError_NotRegistered)
            setErrorMsg(true)
          }
          setLoading(false)
        } else {
          setLoading(false)
          console.log('LOGIN RESPONSE', response)
          setErrorMsg(true)
        }
      }
    )
  }

  return (
    <Box px={2} pt={2}>
      <Typography
        // variant='h5'
        component="h3"
        color={headingColor}
        fontSize={{ xs: 14 }}
        fontWeight={500}
        textAlign="center"
        lineHeight={1.5}
        pb={{ xs: 0, md: 1 }}
      >
        Your Mobile Number
      </Typography>
      <Box
        display={"flex"}
        alignItems="center"
        justifyContent={"center"}
        gap={0.8}
      >
        <Typography
          // variant='h5'
          component="h3"
          color={headingColor}
          fontSize={{ xs: 15 }}
          fontWeight={600}
          textAlign="center"
          lineHeight={1.5}
          pb={{ xs: 0, md: 1}}
        >
          +91 {mobileNumber}
        </Typography>
        <Typography
          // variant='h5'
          component="h6"
          color={{ xs: orangeColor01, md: orangeColor01 }}
          fontSize={{ xs: 13 }}
          fontWeight={500}
          textAlign="center"
          lineHeight={1.5}
          sx={{
            textDecoration: "underline",
            "&:hover": { textDecoration: "none" },
            cursor: "pointer",
          }}
          pb={{ xs: 0, md: 1 }}
          onClick={onChangeNumber}
        >
          Change
        </Typography>
      </Box>
      <AuthTextBox
        value={password}
        onChange={(data) => {
          if (isError) setIsError(!isError);
          if (errorMsg) setErrorMsg(!errorMsg);
          setPassword(data.target.value);
        }}
        name="password"
        placeholder={enterPasswordMsg}
        isError={isError}
        errorMsg={"Please enter Password"}
        icon={
          <VisibilityRoundedIcon
            onClick={() => {
              setShowPassword(!showPassword);
            }}
            fontSize="small"
            sx={{ color: !showPassword ? { orangeColor01 } : "" }}
          />
        }
        inputType={!showPassword ? "text" : "password"}
        showAutoComplete
      />
      {errorMsg && (
        <Box>
          <Typography variant="subtitle1" fontSize={13} color={errorTextColor}>
            {errorMsgValue}
          </Typography>
        </Box>
      )}
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={{ xs: 0.5, md: 0.5 }}
      >
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={isRememberMe}
                color="primary"
                size="small"
                onChange={() => {
                  setIsRememberMe(!isRememberMe);
                }}
              />
            }
            label={
              <Typography
                variant="body1"
                color={blackColor}
                fontSize="13px"
                fontWeight={500}
              >
                Remember Me
              </Typography>
            }
          />
        </Grid>
        <Grid item>
          <Button
            sx={{
              textTransform: "uppercase",
              color: orangeColor01,
              fontWeight: 500,
              display: "block",
              p: 0,
              fontSize: 13,
            }}
            onClick={() => {
              dispatch(getCode({ phone: mobileNumber })).then((response) => {
                if (response.payload) {
                  if (response.payload.status === 200) {
                    if (response.payload.data.email.length > 0)
                      onForgotClick(response.payload.data.email);
                    //   onResetClick(response.payload.data.email, values.phone)
                    else setErrorMsg(true);
                  }
                } else {
                  //   setLoading(false)
                  setErrorMsg(true);
                }
              });
            }}
          >
            Forgot Password ?
          </Button>
        </Grid>
      </Grid>

      <Box>
        <Typography
          variant="body1"
          component="p"
          color={stepperIconColor}
          fontSize={12}
          display="inline"
          lineHeight={1.5}
        >
          By continuing, you agree to our{" "}
          <Typography
            color={orangeColor01}
            display="inline"
            fontSize={12}
            fontWeight={500}
            component={Link}
            lineHeight={1.5}
            // target='_blank'
            to={`/termsAndConditions`}
          >
            Terms of Use
          </Typography>{" "}
          &{" "}
          <Typography
            color={orangeColor01}
            display="inline"
            fontSize={12}
            fontWeight={500}
            component={Link}
            lineHeight={1.5}
            // target='_blank'
            to={`/privacyPolicy`}
          >
            Privacy Policy
          </Typography>
        </Typography>
      </Box>
      <Button
        sx={{
          ...styles.actionBtn(),
          border: loading ? "none" : `1px solid ${blackColor}`,
        }}
        variant="contained"
        fullWidth
        disableElevation
        size="small"
        type="button"
        onClick={handleSubmit}
        disabled={loading}
      >
        Login
      </Button>
    </Box>
  );
}

export default PasswordScreen

const styles = {
  actionBtn: (outline) => ({
    fontSize: "15px",
    fontWeight: 600,
    color: outline ? blackColor : whiteColor,
    p: "3px 20px",
    mt: "15px !important",
    border: "1px solid " + blackColor,
    backgroundColor: outline ? whiteColor : blackColor,
    borderRadius: "5px",
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: outline ? blackColor : whiteColor,
      color: outline ? whiteColor : blackColor,
    },
    letterSpacing: "0.5px",
  }),
};
