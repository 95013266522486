import {
  Box,
  Divider,
  Grid,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  bannerDesColor,
  blackColor,
  borderLineColor,
  greenColor01,
  greenColor05,
  greyShade49,
  greyShade64,
  headingColor,
  homeSubTextColor,
  orangeColor01,
  whiteColor,
} from "configs/styles/muiThemes";
import moment from "moment";

export default function ProductDescriptionSideBar({
  rewardPointPercent,
  minOrderAmount,
  immediateDiscount,
  shipmentArea,
  returnPolicy,
  notes,
  cashback,
  shipmentDistance,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  let businessFullName = sessionStorage.getItem("businessFullName");

  return matches ? (
    <>
      <Box borderRadius={2.2}>
        <Typography
          variant="subtitle1"
          fontSize="15px"
          fontWeight={600}
          color={blackColor}
        >
          Offers You Can't Resist
        </Typography>

        <Box
          sx={{ display: "flex", gap: "12px" }}
          flexDirection={"column"}
          pt={1}
          pr={0.5}
        >
          <Box sx={styles.mobileOfferCard}>
            <Box sx={styles.offerCardHeader}>Discount & Info</Box>
            <Box
              sx={{ padding: "6px 15px", overflowY: "auto", height: "3.5em" }}
            >
              {rewardPointPercent ? (
                <ListItem sx={{ py: 0, ...styles.offerCardText }}>
                  <ListItemText
                    sx={{
                      display: "list-item",
                      color: blackColor,
                    }}
                    primary={
                      <Typography variant="subtitle1" sx={styles.offerCardText}>
                        {rewardPointPercent}% Cashback on all orders.
                      </Typography>
                    }
                  />
                </ListItem>
              ) : null}

              <ListItem sx={{ py: 0 }}>
                <ListItemText
                  sx={{
                    display: "list-item",
                    ...styles.offerCardText,
                  }}
                  primary={
                    <Typography variant="subtitle1" sx={styles.offerCardText}>
                      ₹{minOrderAmount} Minimum purchase amount
                    </Typography>
                  }
                />
              </ListItem>

              {immediateDiscount ? (
                <ListItem sx={{ py: 0 }}>
                  <ListItemText
                    sx={{
                      display: "list-item",
                      ...styles.offerCardText,
                    }}
                    primary={
                      <Typography variant="subtitle1" sx={styles.offerCardText}>
                        Free shipping above ₹{immediateDiscount}
                      </Typography>
                    }
                  />
                </ListItem>
              ) : null}

              <ListItem sx={{ py: 0 }}>
                <ListItemText
                  sx={{
                    display: "list-item",
                    ...styles.offerCardText,
                  }}
                  primary={
                    <Typography variant="subtitle1" sx={styles.offerCardText}>
                      {shipmentDistance >= 3000
                        ? "All India shipment available"
                        : `Limited shipping - ${shipmentDistance} Km from Business warehouse`}
                    </Typography>
                  }
                />
              </ListItem>
            </Box>
          </Box>

          <Box sx={styles.mobileOfferCard}>
            <Box sx={styles.offerCardHeader}>Available Cashback</Box>

            <Box
              sx={{ padding: "6px 15px", overflowY: "scroll", height: "3.5em" }}
            >
              {cashback !== undefined && cashback?.details?.length > 0 ? (
                <Typography
                  variant="body2"
                  component="p"
                  sx={styles.offerCardText}
                  display="inline"
                >
                  ₹{cashback?.details?.[0].availablePointValue?.toFixed(2)}{" "}
                  {cashback?.details?.[0]?.expiryDate ? (
                    <Typography
                      variant="body2"
                      component="p"
                      sx={styles.offerCardText}
                      display="inline"
                    >
                      {/* expires on {rewardPoints?.details?.[0]?.expiryDate} */}
                      expires on{" "}
                      {moment(cashback?.details?.[0]?.expiryDate).format(
                        "MMMM DD YYYY"
                      )}
                    </Typography>
                  ) : null}
                </Typography>
              ) : (
                <Typography
                  component="p"
                  sx={styles.offerCardText}
                  display="inline"
                >
                  Cashback ₹0
                </Typography>
              )}
            </Box>
          </Box>

          {returnPolicy ? (
            <Box sx={styles.mobileOfferCard}>
              <Box sx={styles.offerCardHeader}>Return Policy</Box>
              <Box
                sx={{
                  padding: "6px 15px",
                  overflowY: "scroll",
                  height: "3.5em",
                }}
              >
                <Typography variant="subtitle1" sx={styles.offerCardText}>
                  {returnPolicy}
                </Typography>
              </Box>
            </Box>
          ) : null}
          {notes ? (
            <Box sx={styles.mobileOfferCard}>
              <Box sx={styles.offerCardHeader}>Special Info</Box>
              <Box
                sx={{
                  padding: "6px 15px",
                  overflowY: "scroll",
                  height: "3.5em",
                }}
              >
                <Typography variant="subtitle1" sx={styles.offerCardText}>
                  {notes}
                </Typography>
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
      {/* <Box
        border={'1px solid ' + borderLineColor}
        borderRadius={2.2}
        px={1.3}
        py={0.85}
        my={{ md: 3, xs: 0 }}
      >
        <Grid container spacing={1.2} py={1.6}>
          <Grid item>
            <Box
              component='img'
              src='https://storage.googleapis.com/bodefaults/shopweb/offer_discount_info.png'
              alt={businessFullName + ' CostBo'}
              width='40px'
              height='40px'
            />
          </Grid>

          <Grid item xs>
            <Typography
              variant='subtitle1'
              fontSize='15px'
              fontWeight={600}
              color={headingColor}
            >
              Discount & Info
            </Typography>
            <Box mt={0.5}>
              {rewardPointPercent ? (
                <ListItem sx={{ py: 0 }}>
                  <ListItemText
                    sx={{
                      display: 'list-item',
                    }}
                    primary={
                      <Typography
                        variant='subtitle1'
                        fontSize='14px'
                        color={bannerDesColor}
                        fontWeight={400}
                        lineHeight='26px'
                      >
                        {rewardPointPercent}% Cashback on all orders.
                      </Typography>
                    }
                  />
                </ListItem>
              ) : null}
              <ListItem sx={{ py: 0 }}>
                <ListItemText
                  sx={{
                    display: 'list-item',
                  }}
                  primary={
                    <Typography
                      variant='subtitle1'
                      fontSize='14px'
                      color={bannerDesColor}
                      fontWeight={400}
                      lineHeight='26px'
                    >
                      ₹{minOrderAmount} Minimum purchase amount
                    </Typography>
                  }
                />
              </ListItem>

              {immediateDiscount ? (
                <ListItem sx={{ py: 0 }}>
                  <ListItemText
                    sx={{
                      display: 'list-item',
                    }}
                    primary={
                      <Typography
                        variant='subtitle1'
                        fontSize='14px'
                        color={bannerDesColor}
                        fontWeight={400}
                        lineHeight='26px'
                      >
                        Free shipping above ₹{immediateDiscount}
                      </Typography>
                    }
                  />
                </ListItem>
              ) : null}

              <ListItem sx={{ py: 0 }}>
                <ListItemText
                  sx={{
                    display: 'list-item',
                  }}
                  primary={
                    <Typography
                      variant='subtitle1'
                      fontSize='14px'
                      color={bannerDesColor}
                      fontWeight={400}
                      lineHeight='26px'
                    >
                      {shipmentDistance >= 3000
                        ? 'All India shipment available'
                        : `Limited shipping - ${shipmentDistance} Km from Business warehouse`}
                    </Typography>
                  }
                />
              </ListItem>
            </Box>
          </Grid>
        </Grid>

        <Divider light />

        <Grid container spacing={1.2} py={1.6}>
          <Grid item>
            <Box
              component='img'
              src='https://storage.googleapis.com/bodefaults/shopweb/offer_available_cashback_product.png'
              alt={businessFullName + ' CostBo'}
              width='40px'
              height='40px'
            />
          </Grid>

          <Grid item xs>
            <Typography
              variant='subtitle1'
              fontSize='15px'
              fontWeight={600}
              color={headingColor}
            >
              Available Cashback
            </Typography>
            <Box mt={0.5}>
              {/* <Button sx={{ textDecoration: 'underline', px: 0 }}>
              Login To Check
            </Button> 
              {cashback !== undefined && cashback?.details?.length > 0 ? (
                <Typography
                  variant='body2'
                  component='p'
                  fontSize='15px'
                  fontWeight={500}
                  color={orangeColor01}
                  display='inline'
                >
                  ₹{cashback?.details?.[0].availablePointValue?.toFixed(2)}{' '}
                  {cashback?.details?.[0]?.expiryDate ? (
                    <Typography
                      variant='body2'
                      component='p'
                      fontSize='12px'
                      fontWeight={400}
                      color={homeSubTextColor}
                      display='inline'
                    >
                      {/* expires on {rewardPoints?.details?.[0]?.expiryDate} 
                      expires on{' '}
                      {moment(cashback?.details?.[0]?.expiryDate).format(
                        'MMMM DD YYYY'
                      )}
                    </Typography>
                  ) : null}
                </Typography>
              ) : (
                <Typography
                  variant='body2'
                  component='p'
                  fontSize='15px'
                  fontWeight={500}
                  color={homeSubTextColor}
                  display='inline'
                >
                  ₹0
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
        {returnPolicy ? (
          <>
            <Divider light />

            <Grid container spacing={1.2} py={1.6}>
              <Grid item>
                <Box
                  component='img'
                  src='https://storage.googleapis.com/bodefaults/shopweb/Intro-3.png'
                  alt={businessFullName + ' CostBo'}
                  width='40px'
                  height='40px'
                />
              </Grid>

              <Grid item xs>
                <Typography
                  variant='subtitle1'
                  fontSize='15px'
                  fontWeight={600}
                  color={headingColor}
                >
                  Return Policy
                </Typography>
                <Box mt={0.5}>
                  <Typography
                    variant='subtitle1'
                    fontSize='14px'
                    color={bannerDesColor}
                    fontWeight={400}
                    lineHeight='26px'
                  >
                    {returnPolicy}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </>
        ) : null}

        {notes ? (
          <>
            <Divider light />

            <Grid container spacing={1.2} py={1.6}>
              <Grid item>
                <Box
                  component='img'
                  src='https://storage.googleapis.com/bodefaults/shopweb/Intro-4.png'
                  alt={businessFullName + ' CostBo'}
                  width='40px'
                  height='40px'
                />
              </Grid>

              <Grid item xs>
                <Typography
                  variant='subtitle1'
                  fontSize='15px'
                  fontWeight={600}
                  color={headingColor}
                >
                  Special Info
                </Typography>
                <Box mt={0.5}>
                  <Typography
                    variant='subtitle1'
                    fontSize='14px'
                    color={bannerDesColor}
                    fontWeight={400}
                    lineHeight='26px'
                    sx={{ wordBreak: 'break-word' }}
                  >
                    {notes}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </>
        ) : null}
      </Box> */}
    </>
  ) : (
    <Box borderRadius={2.2} pl="15px">
      <Typography
        variant="subtitle1"
        fontSize="15px"
        fontWeight={600}
        color={blackColor}
      >
        Offers You Can't Resist
      </Typography>

      <Box
        sx={{ display: "flex", gap: "12px", overflowX: "scroll" }}
        pt={1}
        width={"95vw"}
      >
        <Box sx={styles.mobileOfferCard}>
          <Box sx={styles.offerCardHeader}>Discount & Info</Box>
          <Box
            sx={{ padding: "6px 15px", overflowY: "scroll", height: "3.5em" }}
          >
            {rewardPointPercent ? (
              <ListItem sx={{ py: 0, ...styles.offerCardText }}>
                <ListItemText
                  sx={{
                    display: "list-item",
                    color: blackColor,
                  }}
                  primary={
                    <Typography variant="subtitle1" sx={styles.offerCardText}>
                      {rewardPointPercent}% Cashback on all orders.
                    </Typography>
                  }
                />
              </ListItem>
            ) : null}

            <ListItem sx={{ py: 0 }}>
              <ListItemText
                sx={{
                  display: "list-item",
                  ...styles.offerCardText,
                }}
                primary={
                  <Typography variant="subtitle1" sx={styles.offerCardText}>
                    ₹{minOrderAmount} Minimum purchase amount
                  </Typography>
                }
              />
            </ListItem>

            {immediateDiscount ? (
              <ListItem sx={{ py: 0 }}>
                <ListItemText
                  sx={{
                    display: "list-item",
                    ...styles.offerCardText,
                  }}
                  primary={
                    <Typography variant="subtitle1" sx={styles.offerCardText}>
                      Free shipping above ₹{immediateDiscount}
                    </Typography>
                  }
                />
              </ListItem>
            ) : null}

            <ListItem sx={{ py: 0 }}>
              <ListItemText
                sx={{
                  display: "list-item",
                  ...styles.offerCardText,
                }}
                primary={
                  <Typography variant="subtitle1" sx={styles.offerCardText}>
                    {shipmentDistance >= 3000
                      ? "All India shipment available"
                      : `Limited shipping - ${shipmentDistance} Km from Business warehouse`}
                  </Typography>
                }
              />
            </ListItem>
          </Box>
        </Box>

        <Box sx={styles.mobileOfferCard}>
          <Box sx={styles.offerCardHeader}>Available Cashback</Box>

          <Box
            sx={{ padding: "6px 15px", overflowY: "scroll", height: "3.5em" }}
          >
            {cashback !== undefined && cashback?.details?.length > 0 ? (
              <Typography
                variant="body2"
                component="p"
                sx={styles.offerCardText}
                display="inline"
              >
                ₹{cashback?.details?.[0].availablePointValue?.toFixed(2)}{" "}
                {cashback?.details?.[0]?.expiryDate ? (
                  <Typography
                    variant="body2"
                    component="p"
                    sx={styles.offerCardText}
                    display="inline"
                  >
                    {/* expires on {rewardPoints?.details?.[0]?.expiryDate} */}
                    expires on{" "}
                    {moment(cashback?.details?.[0]?.expiryDate).format(
                      "MMMM DD YYYY"
                    )}
                  </Typography>
                ) : null}
              </Typography>
            ) : (
              <Typography
                component="p"
                sx={styles.offerCardText}
                display="inline"
              >
                Cashback ₹0
              </Typography>
            )}
          </Box>
        </Box>

        {returnPolicy ? (
          <Box sx={styles.mobileOfferCard}>
            <Box sx={styles.offerCardHeader}>Return Policy</Box>
            <Box
              sx={{ padding: "6px 15px", overflowY: "scroll", height: "3.5em" }}
            >
              <Typography variant="subtitle1" sx={styles.offerCardText}>
                {returnPolicy}
              </Typography>
            </Box>
          </Box>
        ) : null}
        {notes ? (
          <Box sx={styles.mobileOfferCard}>
            <Box sx={styles.offerCardHeader}>Special Info</Box>
            <Box
              sx={{ padding: "6px 15px", overflowY: "scroll", height: "3.5em" }}
            >
              <Typography variant="subtitle1" sx={styles.offerCardText}>
                {notes}
              </Typography>
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}

const styles = {
  mobileOfferCard: {
    marginBottom: "2px",
    backgroundColor: whiteColor,
    borderRadius: "8px",
    alignItems: "center",
    border: { md: `1px solid ${greyShade49}`, xs: "1px solid #00B293" },
    overflow: "hidden",
    width: "100%",
    flex: "0 0 86%",
    maxWidth: "100%",
  },

  offerCardHeader: {
    fontSize: "12.5px",
    color: { md: blackColor, xs: whiteColor },
    fontWeight: "600",
    lineHeight: "20px",
    background: { xs: greenColor01, md: greyShade49 },
    padding: "6px 10px",
    textAlign: { xs: "center", md: "left" },
  },

  offerCardText: {
    fontSize: 12,
    fontWeight: 500,
    color: blackColor,
    lineHeight: "20px",
  },
};
