import { Box, useMediaQuery } from '@mui/material'
import { useEffect, useState } from 'react'
import FooterMui from '../components/FooterMui'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import {
  getCommercialDetails,
  getProductDetails,
  getSimilarProductDetails,
} from 'redux/productDescription/ProductDescriptionSlice'
import ProductDescription from './SingleProduct/ProductDescription'
import { getCartDetails } from 'redux/addToCart/AddToCartSlice'
import { getRewardPointsDetails } from 'redux/checkout/CheckoutSlice'
import { getProfileId, getUserName } from 'utils/authHelpers'
import {
  addActivityWithLogin,
  addActivityWithOutLogin,
} from 'redux/addActivity/AddActivitySlice'
import {
  addLocalCart,
  addNewProductCart,
  createCart,
  removeProductLocalCart,
  updateCartDetails,
  updateCartProductQuantity,
  updateToLocalCart,
} from 'redux/cart/CartSlice'
import {
  createCartLoginHelper,
  removeCartProductLoginHelper,
} from 'utils/cartHelper'
import { getOutOfStock } from 'utils/productHelper'
import { getBusinessIdDetails } from 'redux/rootSlice/RootSlice'
import Loader from 'components/Loader'
import TopComponent from './TopComponent'
import { getBrowser, getOperatingSystem } from 'utils/utilities'
import { getIpAddress } from 'services/api_calls/header/HeaderApi'
import { getLocationDetails } from 'redux/header/headerSlice'
import { LATITUDE, LONGITUDE, noProductImgUrl } from 'configs/Constants'
import OfferPopupModal from 'components/OfferPopupModal/OfferPopupModal'
import EmptyCard from 'components/EmptyCard'
import { backgroundColor01, backgroundColor02 } from 'configs/styles/muiThemes'

export default function SingleProduct() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const { businessName, productId } = useParams()
  const [searchParams] = useSearchParams()
  const atag = searchParams?.get('atag')

  const dispatch = useDispatch()
  const {
    productDetails,
    similarProductDetails,
    commercialDetails,
    loader,
    recLoading,
  } = useSelector((state) => state.product)

  const { cartDetails } = useSelector((state) => state.cart)
  const { updateQty, addedProduct, createCartRes, updateCartIntRes } =
    useSelector((state) => state.cartupdateadd)
  const [cartProductList, setCartProductList] = useState(null)
  const [totalQty, setTotalQty] = useState(0)
  const [productQty, setProductQty] = useState(0)

  const [commerceInfoData, setCommerceInfoData] = useState(null)
  const [cashbackData, setCashbackData] = useState(null)

  const { rewardpoints } = useSelector((state) => state.checkout)
  const isLoggedIn = getUserName().length === 0 ? false : true

  const [isOutOfStock, setIsOutOfStock] = useState(false)
  const [qtyLoading, setQtyLoading] = useState(false)

  // const businessId = sessionStorage.getItem('businessId')
  const { businessId } = useSelector((state) => state.rootSlice)

  const [isProductValid, setIsProductValid] = useState(false)

  // const [lat, setLat] = useState(null)
  // const [lng, setLng] = useState(null)
  // const [accuracy, setAccuracy] = useState(null)
  // const [area, setArea] = useState(null)
  // const [status, setStatus] = useState(null)

  // const getLocation = () => {
  //   if (!navigator.geolocation) {
  //     setStatus('Geolocation is not supported by your browser')
  //   } else {
  //     setStatus('Locating...')
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         setStatus(null)
  //         setLat(position.coords.latitude)
  //         setLng(position.coords.longitude)
  //         setAccuracy(position.coords.accuracy)
  //         setArea(position.coords.speed)
  //       },
  //       () => {
  //         setStatus('Unable to retrieve your location')
  //       }
  //     )
  //   }
  // }
  // useEffect(() => {
  //   getLocation()
  // }, [])

  const getBusinessData = () => {
    const localBusinessName = sessionStorage.getItem('businessName')
    if (businessName !== localBusinessName) {
      sessionStorage.setItem('cartData', 'null')
    }

    dispatch(getBusinessIdDetails(businessName))
    sessionStorage.setItem('businessName', businessName)
  }

  useEffect(() => {
    getBusinessData()
  }, [businessName])

  useEffect(() => {
    const currentOS = getOperatingSystem(window)
    const currentBrowser = getBrowser(window)
    // const lat = 12.9634
    // const lng = 77.5855

    const lat = localStorage.getItem('lat') || LATITUDE
    const lng = localStorage.getItem('long') || LONGITUDE

    if (lat && lng) {
      if (isLoggedIn) {
        const profileId = getProfileId()
        if (businessId?.businessId) {
          dispatch(
            addActivityWithLogin({
              profileId,
              businessId: businessId?.businessId,
              activityName: 'viewActiveProduct',
              browserType: currentBrowser,
              deviceOs: currentOS,
              latitude: lat,
              longitude: lng,
              productId: productId,
              topicId: null,
              validActivity: 1,
            })
          )
        }
      } else {
        let bid = businessId?.businessId
        getIpAddress().then((res) => {
          if (bid) {
            dispatch(
              addActivityWithOutLogin({
                profileId: `WithoutLoginUser${res?.ip}${bid}`,
                businessId: businessId?.businessId,
                activityName: 'viewActiveProduct',
                browserType: currentBrowser,
                deviceOs: currentOS,
                latitude: lat,
                longitude: lng,
                productId: productId,
                topicId: null,
                validActivity: 1,
              })
            )
          }
          console.log('uglikcicujtgdcut', res)
        })
      }
    }

    // dispatch(getLocationDetails()).then((res) => {
    //   const lat = res?.payload?.lat
    //   const long = res?.payload?.lon
    //   if (isLoggedIn) {
    //     const profileId = getProfileId()
    //     if (businessId?.businessId) {
    //       dispatch(
    //         addActivityWithLogin({
    //           profileId,
    //           businessId: businessId?.businessId,
    //           activityName: 'viewActiveProduct',
    //           browserType: currentBrowser,
    //           deviceOs: currentOS,
    //           latitude: lat,
    //           longitude: long,
    //           productId: productId,
    //           topicId: null,
    //           validActivity: 1,
    //         })
    //       )
    //     }
    //   } else {
    //     let bid = businessId?.businessId
    //     getIpAddress().then((res) => {
    //       if (bid) {
    //         dispatch(
    //           addActivityWithOutLogin({
    //             profileId: `WithoutLoginUser${res?.ip}${bid}`,
    //             businessId: businessId?.businessId,
    //             activityName: 'viewActiveProduct',
    //             browserType: currentBrowser,
    //             deviceOs: currentOS,
    //             latitude: lat,
    //             longitude: long,
    //             productId: productId,
    //             topicId: null,
    //             validActivity: 1,
    //           })
    //         )
    //       }
    //       console.log('uglikcicujtgdcut', res)
    //     })
    //   }
    // })
  }, [businessId])

  const onAddProductToCart = () => {
    let gstTaxValue = 0

    if (productDetails?.product?.gstInfo != null) {
      gstTaxValue = productDetails?.product?.gstInfo?.gstPercentage.replace(
        /[&\/\\#,+()$~%.'":*?<>{}]/g,
        ''
      )
    } else {
      gstTaxValue = 0
    }

    let productAmtWithoutGST =
      (productDetails?.product?.productSalePrice * 100) /
      (100 + Number(gstTaxValue))

    let productTaxPerUnit =
      productDetails?.product?.productSalePrice - productAmtWithoutGST

    const addProductData = {
      cartId: cartDetails?.cartId,
      product: {
        productId: productDetails?.product?.id,
        productName: productDetails?.product?.productName,
        productPicURL: productDetails?.product?.productPhoto[0]?.docURL,
        topicId: '',
        productOriginalPrice: productDetails?.product?.productOriginalPrice,
        productSalePrice: productDetails?.product?.productSalePrice,
        originalQuantity: productDetails?.product?.minOrderQuantity,
        currentQuantity: productDetails?.product?.minOrderQuantity,
        currencySymbol: '₹',
        percentageOff: productDetails?.product?.percentageOff,
        quantityChangedFlag: false,
        priceChangedFlag: false,
        productTotalPrice:
          productDetails?.product?.minOrderQuantity *
          productDetails?.product?.productSalePrice,
        productTax: productTaxPerUnit.toFixed(2),
        productTaxRate: gstTaxValue,
        measurementInfo: productDetails?.product?.measurementInfo,
      },
      clientType: 'web',
    }

    if (isLoggedIn) {
      setQtyLoading(true)

      const cartData = JSON.parse(sessionStorage.getItem('cartData'))
      if (cartData) {
        dispatch(addNewProductCart(addProductData))
      } else {
        const postData = createCartLoginHelper(addProductData)
        dispatch(createCart(postData))
      }
    } else {
      // addtoLocalCart(data?.product)
      dispatch(addLocalCart(addProductData?.product)).then((res) => {
        res?.payload?.productList?.forEach((item) => {
          if (item.productId === productDetails?.product?.id) {
            setProductQty(item?.currentQuantity)
          }
        })
        setCartProductList(res?.payload)
        setTotalQty(res?.payload?.totalQuantity)
      })
    }
  }

  const removeProductFromCart = (data) => {
    if (isLoggedIn) {
      setQtyLoading(true)
      const postData = removeCartProductLoginHelper(data.productId)
      dispatch(updateCartDetails({ ...postData, id: cartDetails?.cartId }))
    } else {
      dispatch(removeProductLocalCart({ productId: data.productId })).then(
        (res) => {
          setProductQty(0)
          res?.payload?.productList?.forEach((item) => {
            if (item.productId === productDetails?.product?.id) {
              setProductQty(item?.currentQuantity)
            }
          })
          setCartProductList(res?.payload)
          setTotalQty(res?.payload?.totalQuantity)
        }
      )
    }
  }

  useEffect(() => {
    setProductQty(0) 
    if (businessId?.validate) {
      dispatch(getProductDetails(productId)).then((res) => {
        console.log('PROD_VALID__:', businessId)
        console.log('PROD_VALID__:', res)

        const productBusinessId = res?.payload?.product?.businessId || null
        const currentBusinessId = businessId?.businessId
        if (currentBusinessId === productBusinessId) {
          setIsProductValid(true)
        } else {
          setIsProductValid(false)
        }
      })
      dispatch(getCommercialDetails())
      if (!cartDetails) {
        dispatch(getCartDetails(businessId))
      }

      let count = 0
      const cartProductData = JSON.parse(sessionStorage.getItem('cartData'))
      cartProductData?.productList?.forEach((item) => {
        if (item.productId === productDetails?.product?.id) {
          setProductQty(item?.currentQuantity)
        }

        count = count + item.currentQuantity
      })
      setTotalQty(count)
      setCartProductList(cartProductData)
      setQtyLoading(false)
    }
  }, [productId, businessId])

  useEffect(() => {
    if (productDetails) {
      if (productDetails?.product?.id !== productId) return

      setIsOutOfStock(getOutOfStock(productDetails?.product))
      dispatch(
        getSimilarProductDetails({
          businessId: productDetails?.product?.businessId,
          subCat: productDetails?.product?.productSubCategory[0]?.code,
        })
      )

      const cartData = JSON.parse(sessionStorage.getItem('cartData'))
      cartData?.productList?.forEach((item) => {
        if (item.productId === productDetails?.product?.id) {
          setProductQty(item?.currentQuantity)
        }
      })

      setQtyLoading(false)
    }
  }, [productDetails])

  useEffect(() => {
    if (productDetails?.product?.id !== productId) return

    let count = 0
    const cartProductData = JSON.parse(sessionStorage.getItem('cartData'))
    cartProductData?.productList?.forEach((item) => {
      if (item.productId === productDetails?.product?.id) {
        setProductQty(item?.currentQuantity)
      }

      count = count + item.currentQuantity
    })
    setTotalQty(count)
    setCartProductList(cartProductData)
    setQtyLoading(false)
  }, [cartDetails, updateQty])

  useEffect(() => {
    if (productDetails?.product?.id !== productId) return

    let count = 0
    setProductQty(0)

    if (isLoggedIn) {
      dispatch(getCartDetails(businessId)).then((carRes) => {
        sessionStorage.setItem(
          'cartData',
          JSON.stringify(carRes?.payload?.detail)
        )
        carRes?.payload?.detail?.productList?.forEach((item) => {
          if (item.productId === productDetails?.product?.id) {
            setProductQty(item?.currentQuantity)
          }
          count = count + item.currentQuantity
        })
        setTotalQty(count)
        setCartProductList(carRes?.payload?.detail)
        setQtyLoading(false)
      })
    }
  }, [addedProduct, createCartRes, updateCartIntRes])

  useEffect(() => {
    const commerceInfo = JSON.parse(sessionStorage.getItem('commerceInfo'))
    const cashback = JSON.parse(sessionStorage.getItem('cashback'))

    if (!commerceInfo) {
      dispatch(getCommercialDetails())
    } else {
      setCommerceInfoData(commerceInfo)
    }
    if (!cashback) {
      dispatch(getRewardPointsDetails())
    } else {
      setCashbackData(cashback)
    }
  }, [rewardpoints, commercialDetails])

  return (
    <Box bgcolor={backgroundColor01}>
      {/* <Navbar value='singleProduct' /> */}

      {commercialDetails && (
        <TopComponent value='singleProduct' disableBanners />
      )}

      {loader && (
        <Box
          minHeight='20vh'
          size={25}
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <Loader />
        </Box>
      )}

      {!loader && !isProductValid && productDetails && commercialDetails && (
        <Box mt={12}>
          <EmptyCard
            message={'Product Not Found'}
            description={'Explore our products.'}
            imageUrl={noProductImgUrl}
            actionBtnText={'Go To Catalog'}
          />
        </Box>
      )}

      {isProductValid && productDetails && commercialDetails ? (
        <>
          {/* <TopComponent value='singleProduct' disableBanners /> */}
          <ProductDescription
            productDetails={productDetails}
            similarProductDetails={similarProductDetails}
            commercialDetails={commerceInfoData}
            recLoading={recLoading}
            productId={productId}
            cartProductList={cartProductList}
            totalQty={totalQty}
            productQty={productQty}
            cashback={cashbackData}
            // isOutOfStock={isOutOfStock}
            isOutOfStock={getOutOfStock(productDetails?.product)}
            onQtyUpdate={(quantity) => {
              if (qtyLoading) return

              if (isLoggedIn) {
                dispatch(
                  updateCartProductQuantity({
                    cartId: cartDetails?.cartId,
                    productId: productDetails?.product?.id,
                    quantity: quantity,
                  })
                )
                setQtyLoading(true)
              } else {
                dispatch(
                  updateToLocalCart({
                    cartId: cartDetails?.cartId,
                    productId: productDetails?.product?.id,
                    quantity: quantity,
                  })
                ).then((res) => {
                  res?.payload?.productList?.forEach((item) => {
                    if (item.productId === productDetails?.product?.id) {
                      setProductQty(item?.currentQuantity)
                    }
                  })
                  setCartProductList(res?.payload)
                  setTotalQty(res?.payload?.totalQuantity)
                })
              }
            }}
            onAddProduct={() => {
              if (qtyLoading) return
              onAddProductToCart()
            }}
            onRemoveProduct={() => {
              if (qtyLoading) return
              removeProductFromCart({
                productId: productDetails?.product?.id,
              })
            }}
          />
          
          {matches && <FooterMui />}
          <OfferPopupModal couponCode={atag} />
        </>
      ) : null}
    </Box>
  )
}
