import { Box, Chip, Grid, Typography, useMediaQuery } from "@mui/material";
import ProductCard from "./ProductCard";
import { getOutOfStock } from "utils/productHelper";
import {
  blackColor,
  greyShade55,
  headingColor,
  searchLabelColor,
  whiteColor,
} from "configs/styles/muiThemes";
import { useSelector } from "react-redux";
import EmptyCard from "components/EmptyCard";
import { searchEmptyImgUrl, searchEmptyMsg } from "configs/Constants";
import ProductMobileCard from "./ProductMobileCard";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

function SearchResult({
  searchProducts,
  allProducts,
  cartData,
  cartId,
  onQtyUpdate,
  onAddProduct,
  onRemoveProduct,
  clearSearch,
}) {
  const { searchQuery } = useSelector((state) => state.search);
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box pt={1}>
      <Box
        display="flex"
        alignItems={"center"}
        // flexDirection={searchQuery?.length > 50 ? "column" : "row"}
        flexWrap="wrap"
        gap="6px"
        m={{ xs: "0 20px", md: "0px 0 18px 0" }}
      >
        <Typography
          variant="h6"
          color={greyShade55}
          fontWeight={500}
          fontSize={{ xs: 13, md: 13 }}
          display="inline"
        >
          Search results for{" "}
          <Typography
            variant="h6"
            color={blackColor}
            fontSize={{ xs: 13, md: 14 }}
            display="inline"
          >
            “{searchQuery}”
          </Typography>{" "}
          - {searchProducts?.length} products
        </Typography>

        <Chip
          label={
            <Typography fontSize={12.5} mr={1}>
              {searchQuery}
            </Typography>
          }
          variant="outlined"
          onDelete={() => {
            clearSearch();
          }}
          deleteIcon={<CloseOutlinedIcon sx={{ color: "#000 !important" }} />}
          size="small"
          sx={{
            mr: 1.5,
            color: blackColor,
            bgcolor: whiteColor,
            borderColor: blackColor,
            p: "12px 12px !important",
          }}
        />
        {/* <Chip
          label={searchQuery}
          sx={{ fontSize: "12px", height: "28px" }}
          onDelete={() => {}}
        /> */}
      </Box>

      <Grid container columnSpacing={1} rowSpacing={2.5}>
        {allProducts?.length > 0
          ? searchProducts?.map((productData, index) => {
              // const productData = allProducts?.filter((data) => {
              //   return item.id === data.id;
              // });

              console.log("SEARCH_ITEM_DATA", productData);

              // if(!productData?.[0]) return null
              if (productData?.variantInfo?.masterVariant) return null;

              return (
                <Grid item xs={12} sm={3} md={2.4} key={index}>
                  {matches ? (
                    <ProductCard
                      img={productData?.productPhoto?.[0]?.docURL}
                      price={productData?.productSalePrice}
                      oldPrice={productData?.productOriginalPrice}
                      description={productData?.productName}
                      discount={productData?.percentageOff}
                      style={{ my: 1.5 }}
                      isOutOfStock={getOutOfStock(productData)}
                      isComingSoon={productData?.commerceEnabled === "NO"}
                      minQty={productData?.minOrderQuantity}
                      maxQty={productData?.maxOrderQuantity}
                      currencySymbol={
                        productData?.currencySymbol
                          ? "₹"
                          : productData?.currencySymbol
                      }
                      cartData={cartData}
                      product={productData}
                      cartId={cartId}
                      onQtyUpdate={(data) => {
                        onQtyUpdate(data);
                      }}
                      onAddProduct={(data) => {
                        onAddProduct(data);
                      }}
                      onRemoveProduct={onRemoveProduct}
                      subscribe={productData?.subscription?.status === "active"}
                      availability={
                        productData?.availability?.[0]?.toLowerCase() === "yes"
                      }
                      subscription={productData?.subscription}
                      variant={productData?.variantInfo?.masterProductVariantId}
                      variantCount={productData?.count}
                    />
                  ) : (
                    <ProductMobileCard
                      productid={productData?.id}
                      img={productData?.productPhoto?.[0]?.docURL}
                      price={productData?.productSalePrice}
                      oldPrice={productData?.productOriginalPrice}
                      description={productData?.productName}
                      discount={productData?.percentageOff}
                      style={{ my: 1.5 }}
                      isOutOfStock={getOutOfStock(productData)}
                      isComingSoon={productData?.commerceEnabled === "NO"}
                      minQty={productData?.minOrderQuantity}
                      maxQty={productData?.maxOrderQuantity}
                      currencySymbol={
                        productData?.currencySymbol
                          ? "₹"
                          : productData?.currencySymbol
                      }
                      cartData={cartData}
                      product={productData}
                      cartId={cartId}
                      onQtyUpdate={(data) => {
                        onQtyUpdate(data);
                      }}
                      onAddProduct={(data) => {
                        onAddProduct(data);
                      }}
                      onRemoveProduct={onRemoveProduct}
                      subscribe={productData?.subscription?.status === "active"}
                      availability={
                        productData?.availability?.[0]?.toLowerCase() === "yes"
                      }
                      subscription={productData?.subscription}
                      variant={productData?.variantInfo?.masterProductVariantId}
                      variantCount={productData?.count}
                    />
                  )}
                </Grid>
              );
            })
          : null}
      </Grid>
      {searchProducts?.length === 0 ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          mt={2}
        >
          <EmptyCard
            message={searchEmptyMsg + `"${searchQuery}"`}
            imageUrl={`https://storage.googleapis.com/bodefaults/shopweb/payments/search-card-empty.svg`}
            description={"Please refine your search"}
          />
        </Box>
      ) : null}
    </Box>
  );
}

export default SearchResult;
