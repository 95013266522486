import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import {
  blackColor,
  blueColor04,
  borderCard04,
  greyShade49,
  whiteColor,
} from "configs/styles/muiThemes";
import React, { useState } from "react";
import { findLabelBySelectedValue } from "./VariantProduct/VariantHelper";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

function AdditionalInfoCard({ title, dynamicSpecs }) {
  const [showMore, setShowMore] = useState(false);
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return (
    <Stack
      sx={{
        overflow: "hidden",
        borderRadius: "8px",
        border: { xs: "none", md: borderCard04 },
        height: "100%",
        bgcolor: { xs: "transparent", md: whiteColor },
      }}
    >
      <Box
        sx={{
          bgcolor: { md: greyShade49, xs: "transparent" },
          p: { md: "10px 12px", xs: "0 10px 10px 0" },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          fontSize={13.5}
          fontWeight={600}
          color={blackColor}
          lineHeight="15px"
        >
          {title}
        </Typography>
        <Box
          sx={{
            // width: "20px",
            height: "20px",
            // bgcolor: "red",
            display: { xs: "flex", md: "none" },
          }}
        >
          {!showMore ? (
            <ExpandMoreRoundedIcon onClick={() => setShowMore(!showMore)} />
          ) : (
            <ExpandLessRoundedIcon onClick={() => setShowMore(!showMore)} />
          )}
        </Box>
      </Box>
      {(matches || showMore) && (
        <Stack
          p="12px"
          gap="6px"
          sx={{
            bgcolor: whiteColor,
            borderRadius: { md: "0", xs: "8px" },
            border: { md: "none", xs: borderCard04 },
          }}
        >
          {dynamicSpecs?.map((item, i) => {
            if (item?.display === "N") return null;
            if (!item?.type) return null;
            if (item?.type === "default") {
              return (
                <DataTile
                  label={item?.displayName}
                  value={item?.values?.[0]
                    ?.toString()
                    ?.replace(/_/g, " ")
                    ?.replace(/,/g, ", ")}
                  i={i}
                  last={i + 1 == dynamicSpecs?.length}
                />
              );
            }
            if (item?.type === "radioButton") {
              return (
                <DataTile
                  label={item?.displayName}
                  value={findLabelBySelectedValue(item?.radioOptions)}
                  i={i}
                  last={i + 1 == dynamicSpecs?.length}
                />
              );
            }
            return (
              <DataTile
                label={item?.displayName}
                value={item?.inputValue
                  ?.toString()
                  ?.replace(/_/g, " ")
                  ?.replace(/,/g, ", ")}
                i={i}
                last={i + 1 == dynamicSpecs?.length}
              />
            );
          })}
        </Stack>
      )}
    </Stack>
  );
}

export default AdditionalInfoCard;

const DataTile = ({ label, value, i, last }) => {
  if (!value) return null;
  return (
    <Box
      display="flex"
      gap={1}
      key={i}
      pb="8px"
      mb={!last && "8px"}
      borderBottom={!last && "1px solid" + greyShade49}
    >
      <Typography
        fontSize={13}
        fontWeight={400}
        color={blackColor}
        lineHeight="15px"
        sx={{ width: "40%" }}
      >
        {label}
      </Typography>
      <Typography
        fontSize={13}
        fontWeight={500}
        color={label == "Size Chart" ? blueColor04 : blackColor}
        lineHeight="15px"
        onClick={() => {
          if (label == "Size Chart" && value) {
            window.open(value, "_blank");
          }
        }}
        sx={{
          width: "60%",
          textTransform: "capitalize",
          textDecoration: label == "Size Chart" ? "underline" : "",
          cursor: label == "Size Chart" ? "pointer" : "",
        }}
        textAlign={"end"}
      >
        {label == "Size Chart" ? "Chart Link" : value || "-"}
      </Typography>
    </Box>
  );
};
