import {
  Box,
  Divider,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import {
  blackColor,
  blueColor03,
  blueColor04,
  headingColor,
  orangeColor01,
  searchLabelColor,
  secondaryColor,
  tagTrackColor,
  topbarColor,
  whiteColor,
} from "configs/styles/muiThemes";
import React, { useEffect } from "react";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import TripOriginOutlinedIcon from "@mui/icons-material/TripOriginOutlined";
import { grey } from "@mui/material/colors";
import { Timeline } from "@mui/icons-material";
import { Button } from "react-bootstrap";
import VerticalTimeline from "./verticalTimeline";
import { useDispatch, useSelector } from "react-redux";
import {
  getDelhiveryStatus,
  getOrderDetails,
  getTelyportStatus,
} from "redux/orderDetails/OrderDetailsSlice";
import moment from "moment";

export default function TrackCard({
  orderId,
  orderStatus,
  orderData,
  cartId,
  itemCount,
  totalCost,
  deliveryType,
  ondcOrderType,
  ondcOrderId,
}) {
  const dispatch = useDispatch();
  const { dehiveryStatusData, telyportStatusData } = useSelector(
    (state) => state.orderdetails
  );
  const { cartOrderDetails, cartOrderLoading } = useSelector(
    (state) => state.orderdetails
  );

  useEffect(() => {
    if (cartId) {
      dispatch(getOrderDetails(cartId));
    }
  }, [cartId]);

  useEffect(() => {
    if (cartOrderDetails) {
      if (deliveryType == "delhivery" || deliveryType == "delhiveryb2b") {
        if (cartOrderDetails?.cart?.shipmentInfo?.carrierTrackId) {
          dispatch(
            getDelhiveryStatus(
              cartOrderDetails?.cart?.shipmentInfo?.carrierTrackId
            )
          );
        }
      }
      if (deliveryType?.toLowerCase() == "telyport") {
        if (cartOrderDetails?.cart?.shipmentInfo?.carrierTrackId) {
          dispatch(
            getTelyportStatus(
              cartOrderDetails?.cart?.shipmentInfo?.carrierTrackId
            )
          );
        }
      }
    }
  }, [cartOrderDetails]);

  useEffect(() => {
    console.log("telyportStatusData", telyportStatusData);
  }, [telyportStatusData]);

  return (
    <Box sx={{ borderRadius: "10px", overflow: "hidden" }}>
      <Box sx={{ p: "15px", background: blackColor }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography
              color={whiteColor}
              fontSize={15}
              fontWeight={600}
              lineHeight={1.5}
            >
              Order Id: #{orderId}
            </Typography>

            {(deliveryType == "ondc" ||
              cartOrderDetails?.cart?.deliveryType?.type == "ondc") && (
              <>
                <Typography
                  variant="h5"
                  fontSize={14}
                  fontWeight={500}
                  color={whiteColor}
                  pb="4px"
                  mt={0.6}
                >
                  ONDC Order Id : #{ondcOrderId}
                </Typography>

                <Typography
                  mt={0.6}
                  variant="h5"
                  fontSize={14}
                  fontWeight={500}
                  color={whiteColor}
                  pb="14px"
                >
                  ONDC Buyer App : {ondcOrderType}
                </Typography>
              </>
            )}

            <Typography
              color={whiteColor}
              fontSize={13}
              fontWeight={500}
              lineHeight={1.5}
              sx={{ my: "3px !important" }}
            >
              Tracking Id:{" "}
              {deliveryType == "delhivery" || deliveryType == "delhiveryb2b"
                ? dehiveryStatusData?.ShipmentData
                  ? dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.AWB
                  : cartOrderDetails?.cart?.shipmentInfo?.carrierTrackId
                : null}
              {deliveryType == "business" || deliveryType == "Business"
                ? cartOrderDetails?.cart?.shipmentInfo?.carrierTrackId ||
                  "Not Available"
                : null}
              {deliveryType?.toLowerCase() == "telyport" ||
              deliveryType?.toLowerCase() == "ondc" ||
              cartOrderDetails?.cart?.deliveryType?.type == "ondc"
                ? cartOrderDetails?.cart?.shipmentInfo?.carrierTrackId ||
                  "Not Available"
                : null}
            </Typography>
            <Typography
              color={whiteColor}
              fontSize={12}
              fontWeight={500}
              lineHeight={1.5}
            >
              {itemCount} Items | ₹{totalCost}
            </Typography>
          </Box>
          {dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.Status?.Status ||
          deliveryType == "business" ||
          deliveryType == "Business" ? (
            <Typography
              color={blackColor}
              sx={{
                px: 1,
                py: 0.3,
                background: whiteColor,
                borderRadius: 1,
                fontSize: "12px",
              }}
              mr={0.5}
              fontWeight={500}
            >
              {deliveryType == "delhivery" || deliveryType == "delhiveryb2b"
                ? dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.Status
                    ?.Status
                : null}
              {deliveryType == "business" || deliveryType == "Business"
                ? orderStatus
                : null}
              {deliveryType?.toLowerCase() == "telyport"
                ? telyportStatusData?.data?.orderStatus
                : null}
            </Typography>
          ) : null}
        </Box>
        <Box
          sx={{ my: 2, fontWeight: 400, borderTop: `${whiteColor} dashed 1px` }}
        ></Box>
        <Box>
          <Box display="flex" alignItems="center" mb={1}>
            <TripOriginOutlinedIcon
              sx={{ color: whiteColor, fontSize: 21, fontWeight: 600 }}
            />
            <Typography
              color={whiteColor}
              fontSize={12}
              fontWeight={500}
              ml={1}
              mb={0.6}
              lineHeight={1.5}
            >
              {cartOrderDetails?.cart?.businessAddress?.addressLine1}
              {cartOrderDetails?.cart?.businessAddress?.city
                ? `, ${cartOrderDetails?.cart?.customerAddress?.city}`
                : ""}
              {cartOrderDetails?.cart?.businessAddress?.state
                ? `, ${cartOrderDetails?.cart?.customerAddress?.state}`
                : ""}
              {cartOrderDetails?.cart?.businessAddress?.zip
                ? ` - ${cartOrderDetails?.cart?.customerAddress?.zip}`
                : ""}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <FmdGoodOutlinedIcon
              sx={{ color: whiteColor, fontSize: 21, fontWeight: 600 }}
            />
            <Typography
              color={whiteColor}
              fontSize={12}
              fontWeight={500}
              ml={1}
              lineHeight={1.5}
              mb={0.6}
            >
              {cartOrderDetails?.cart?.customerAddress?.addressLine1}{" "}
              {cartOrderDetails?.cart?.customerAddress?.city
                ? `, ${cartOrderDetails?.cart?.customerAddress?.city}`
                : ""}
              {cartOrderDetails?.cart?.customerAddress?.state
                ? `, ${cartOrderDetails?.cart?.customerAddress?.state}`
                : ""}
              {cartOrderDetails?.cart?.customerAddress?.zip
                ? ` - ${cartOrderDetails?.cart?.customerAddress?.zip}`
                : ""}
            </Typography>
          </Box>
        </Box>
        {/* <Divider color={whiteColor} sx={{my:1,fontWeight:400}}/> */}
      </Box>
      {cartOrderLoading ||
      ((deliveryType == "delhivery" || deliveryType == "delhiveryb2b") &&
        !dehiveryStatusData)
        ? ""
        : // <Box sx={{ background: whiteColor, p: 3, height: '100px' }}></Box>
          null}
      {(cartOrderDetails?.cart?.shipmentInfo == null ||
        dehiveryStatusData?.Error) &&
      !cartOrderLoading ? (
        <Box
          sx={{ background: whiteColor, p: 3, borderRadius: "0 0 10px 10px" }}
          justifyContent="center"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            component="img"
            src="https://storage.googleapis.com/bodefaults/shopweb/order_tracking.svg"
            sx={{
              width: "80px",
              height: "auto",
              mb: 2,
            }}
          />
          <Typography color={blackColor} fontSize={13} fontWeight={500}>
            {dehiveryStatusData?.Error
              ? "Tracking information not available for this order"
              : "Tracking Information not available yet"}
          </Typography>
        </Box>
      ) : null}
      {cartOrderDetails?.cart?.shipmentInfo != null &&
      (deliveryType == "delhivery" || deliveryType == "delhiveryb2b") &&
      dehiveryStatusData?.ShipmentData ? (
        <Box
          sx={{
            background: whiteColor,
            p: 2,
            pt: 1,
            height: "400px",
            overflowY: "auto",
          }}
        >
          <Typography
            fontSize={17}
            fontWeight={600}
            lineHeight={1.5}
            color={headingColor}
          >
            History
          </Typography>
          <VerticalTimeline
            data={dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.Scans}
          />
          {dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.Status?.Status ==
          "Delivered" ? (
            <>
              <Divider />
              <Box my={1}>
                <Typography
                  fontSize={13}
                  fontWeight={400}
                  color={searchLabelColor}
                >
                  {
                    dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.Status
                      ?.Status
                  }{" "}
                  on{" "}
                  {moment(
                    dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.Status
                      ?.StatusDateTime
                  ).format("MMM DD, YYYY")}
                </Typography>
                <Typography
                  fontSize={13}
                  fontWeight={400}
                  color={searchLabelColor}
                >
                  {
                    dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.Status
                      ?.Instructions
                  }
                </Typography>
                <Typography
                  fontSize={13}
                  fontWeight={400}
                  color={searchLabelColor}
                >
                  {
                    dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.Status
                      ?.StatusLocation
                  }
                </Typography>
              </Box>
            </>
          ) : null}
        </Box>
      ) : null}
      {cartOrderDetails?.cart?.shipmentInfo != null &&
      (deliveryType == "business" || deliveryType == "Business") ? (
        <Box
          sx={{
            background: whiteColor,
            p: 2,
            minHeight: "75px",
            overflowY: "auto",
          }}
        >
          {cartOrderDetails?.cart?.shipmentInfo?.carrierName ? (
            <Box mb={1}>
              <Typography
                fontSize={14.5}
                fontWeight={600}
                color={headingColor}
                display="inline"
              >
                Carrier Name:{" "}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                color={headingColor}
                display="inline"
              >
                {cartOrderDetails?.cart?.shipmentInfo?.carrierName}
              </Typography>
            </Box>
          ) : null}
          {cartOrderDetails?.cart?.shipmentInfo?.carrierURL ? (
            <Box mb={1}>
              <Typography
                fontSize={14.5}
                fontWeight={600}
                color={headingColor}
                display="inline"
              >
                Carrier Tracking:{" "}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                color={headingColor}
                display="inline"
              >
                {cartOrderDetails?.cart?.shipmentInfo?.carrierURL}
              </Typography>
            </Box>
          ) : null}
          {cartOrderDetails?.cart?.shipmentInfo?.deliveryMode ? (
            <Box mb={1}>
              <Typography
                fontSize={14.5}
                fontWeight={600}
                color={headingColor}
                display="inline"
              >
                Delivery Mode:{" "}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                color={headingColor}
                display="inline"
              >
                {cartOrderDetails?.cart?.shipmentInfo?.deliveryMode}
              </Typography>
            </Box>
          ) : null}
          {cartOrderDetails?.cart?.shipmentInfo?.deliveryPersonName ? (
            <Box mb={1}>
              <Typography
                fontSize={14.5}
                fontWeight={600}
                color={headingColor}
                display="inline"
              >
                Delivery Person Name:{" "}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                color={headingColor}
                display="inline"
              >
                {cartOrderDetails?.cart?.shipmentInfo?.deliveryPersonName}
              </Typography>
            </Box>
          ) : null}
          {cartOrderDetails?.cart?.shipmentInfo?.deliveryPersonPhone ? (
            <Box>
              <Typography
                fontSize={14.5}
                fontWeight={600}
                color={headingColor}
                display="inline"
              >
                Delivery Person Phone number:{" "}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                color={headingColor}
                display="inline"
              >
                {cartOrderDetails?.cart?.shipmentInfo?.deliveryPersonPhone}
              </Typography>
            </Box>
          ) : null}
        </Box>
      ) : null}
      {cartOrderDetails?.cart?.shipmentInfo != null &&
      deliveryType?.toLowerCase() == "telyport" ? (
        <Box
          sx={{
            background: whiteColor,
            p: 2,
            minHeight: "75px",
            overflowY: "auto",
          }}
        >
          {telyportStatusData?.data?.orderStatus ? (
            <>
              <Box mb={1}>
                <Typography sx={styles.labelText}>Order Status: </Typography>
                <Typography sx={styles.valueText}>
                  {telyportStatusData?.data?.orderStatus}
                </Typography>
              </Box>

              <Box mb={1}>
                <Typography sx={styles.labelText}>Tracking Number: </Typography>
                <Typography sx={styles.valueText}>
                  {telyportStatusData?.data?.trackableID}
                </Typography>
              </Box>

              <Box
                mb={1}
                onClick={() => {
                  window.open(telyportStatusData?.data?.trackingLink, "_blank");
                }}
              >
                <Typography sx={styles.labelText}>Tracking Link: </Typography>
                <Typography
                  sx={{
                    ...styles.valueText,
                    fontSize: 12,
                    color: orangeColor01,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {telyportStatusData?.data?.trackingLink}
                </Typography>
              </Box>

              <Box mb={1}>
                <Typography sx={styles.labelText}>Agent Name: </Typography>
                <Typography sx={styles.valueText}>
                  {telyportStatusData?.data?.agentDetails?.agent_info?.name}
                </Typography>
              </Box>

              <Box mb={1}>
                <Typography sx={styles.labelText}>Phone Number: </Typography>
                <Typography sx={styles.valueText}>
                  +91{" "}
                  {telyportStatusData?.data?.agentDetails?.agent_info?.phone}
                </Typography>
              </Box>
            </>
          ) : null}
        </Box>
      ) : null}

      {cartOrderDetails?.cart?.shipmentInfo != null &&
      (deliveryType?.toLowerCase() == "ondc" ||
        cartOrderDetails?.cart?.deliveryType?.type == "ondc") ? (
        <Box
          sx={{
            background: whiteColor,
            p: 2,
            minHeight: "75px",
            overflowY: "auto",
          }}
        >
          {cartOrderDetails?.cart?.shipmentInfo?.carrierName ? (
            <Box mb={1}>
              <Typography
                fontSize={14.5}
                fontWeight={600}
                color={blueColor03}
                display="inline"
              >
                Carrier Name :{" "}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                color={blueColor03}
                display="inline"
              >
                {cartOrderDetails?.cart?.shipmentInfo?.carrierName}
              </Typography>
            </Box>
          ) : null}

          {cartOrderDetails?.cart?.shipmentInfo?.carrierURL ? (
            <Box mb={1}>
              <Typography
                fontSize={14.5}
                fontWeight={600}
                color={blueColor03}
                display="inline"
              >
                Tracking URL:{" "}
              </Typography>
              <Typography
                component="a"
                href={cartOrderDetails?.cart?.shipmentInfo?.carrierURL}
                target="_blank"
                fontSize={13}
                fontWeight={500}
                color={blueColor04}
                sx={{
                  textDecoration: "underline",
                  "&:hover": {
                    color: blueColor04,
                    textDecoration: "underline",
                  },
                }}
                display="inline"
              >
                {cartOrderDetails?.cart?.shipmentInfo?.carrierURL}
              </Typography>
            </Box>
          ) : null}
          {cartOrderDetails?.cart?.shipmentInfo?.deliveryPersonName ? (
            <Box mb={1}>
              <Typography
                fontSize={14.5}
                fontWeight={600}
                color={blueColor03}
                display="inline"
              >
                Delivery Person Name :{" "}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                color={blueColor03}
                display="inline"
              >
                {cartOrderDetails?.cart?.shipmentInfo?.deliveryPersonName}
              </Typography>
            </Box>
          ) : null}

          {cartOrderDetails?.cart?.shipmentInfo?.deliveryPersonPhone &&
          cartOrderDetails?.cart?.shipmentInfo?.shipmentStatus !==
            "ORDERDELIVERED" ? (
            <Box>
              <Typography
                fontSize={14.5}
                fontWeight={600}
                color={blueColor03}
                display="inline"
              >
                Delivery Person Phone number :{" "}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                color={blueColor03}
                display="inline"
              >
                {cartOrderDetails?.cart?.shipmentInfo?.deliveryPersonPhone}
              </Typography>
            </Box>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
}

const styles = {
  labelText: {
    fontSize: 14.5,
    fontWeight: 600,
    color: headingColor,
    display: "inline",
  },

  valueText: {
    fontSize: 14,
    fontWeight: 500,
    color: headingColor,
    display: "inline",
  },
};
