import { StarRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  Rating,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import {
  arrowBgColor,
  blackColor,
  borderBackground,
  greyColor9,
  headingColor,
  orangeColor01,
  reviewBodyColor,
  reviewSubColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import EditIcon from '@mui/icons-material/Edit';

function OwnReview(props) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return matches ? (
    <Box>
      <Typography
        variant="subtitle1"
        fontSize={14}
        color={blackColor}
        fontWeight={600}
        align="left"
        mb="5px !important"
      >
        Your Review{" "}
        <Typography
          component="span"
          fontSize={13}
          color={greyColor9}
          fontWeight={400}
        >
          on {moment(props.reviews.messageCreateDate).format("MMM DD, YYYY")}
        </Typography>
      </Typography>

      <Rating
        readOnly
        icon={
          <StarRoundedIcon sx={{
            width: "18.5px",
            height: "18.5px",
            color: orangeColor01,
            ml:0.3
          }} />
          // <Box
          //   component="img"
          //   src={`https://storage.googleapis.com/bodefaults/shopweb/reviews/star.svg`}
          //   alt={`Star`}
          //   sx={{
          //     width: "15x",
          //     height: "15px",
          //     display: "flex",
          //     lineHeight: 1.5,
          //   }}
          // />
        }
        emptyIcon={
          <StarRoundedIcon sx={{
            width: "18.5px",
            height: "18.5px",
            ml:0.3
          }} />
          // <Box
          //   component="img"
          //   src={`https://storage.googleapis.com/bodefaults/shopweb/reviews/star-1.svg`}
          //   alt={`Star`}
          //   sx={{
          //     width: "15x",
          //     height: "15px",
          //     display: "flex",
          //     lineHeight: 1.5,
          //   }}
          // />
        }
        precision={0.1}
        value={props.reviews.reviewRating}
        sx={{ marginBottom: 1 }}
      />

      <Typography
        variant="h5"
        fontSize={{ md: "13.5px", xs: "13px" }}
        fontWeight={{xs: 500, md: 600}}
        color={headingColor}
        m={"4px 0 6px !important"}
      >
        {props.reviews.reviewTitle}
      </Typography>

      <Typography
        variant="body1"
        fontSize={{ md: "13px", xs: "11px" }}
        lineHeight='21px'
        color={greyColor9}
      >
        {props.reviews.message}
      </Typography>

      <Box sx={styles.editBtnContainer}>
        <Button
          sx={[styles.editBtnNew, { ml: 3 }]}
          variant="contained"
          disableElevation
          size="small"
          startIcon={<EditIcon sx={{fontSize:'15px !important'}} />}
          onClick={props.onEditClick}
        >
          EDIT REVIEW
        </Button>
      </Box>
    </Box>
  ) : (
    <Box>
      <Typography variant="subtitle1" color={blackColor} align="left" mb={1}>
        Review
      </Typography>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box>
          <Typography variant="subtitle1" color={blackColor} align="left">
            Your Review
          </Typography>
          <Typography
            variant="subtitle1"
            fontSize={12}
            color={reviewBodyColor}
            align="left"
          >
            ({props.reviews.name})
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1" color={blackColor}>
            on {moment(props.reviews.messageCreateDate).format("MMM DD, YYYY")}
          </Typography>
          <Rating
            readOnly
            icon={
              <Box
                component="img"
                src={`https://storage.googleapis.com/bodefaults/shopweb/reviews/star.svg`}
                alt={`Star`}
                sx={{
                  width: "15x",
                  height: "15px",
                  display: "flex",
                  lineHeight: 1.5,
                }}
              />
            }
            emptyIcon={
              <Box
                component="img"
                src={`https://storage.googleapis.com/bodefaults/shopweb/reviews/star-1.svg`}
                alt={`Star`}
                sx={{
                  width: "15x",
                  height: "15px",
                  display: "flex",
                  lineHeight: 1.5,
                }}
              />
            }
            precision={0.1}
            value={props.reviews.reviewRating}
            sx={{ marginBottom: 1 }}
          />
        </Box>
      </Box>

      <Typography
        variant="h5"
        fontSize={{ md: "15px", xs: "13px" }}
        color={headingColor}
        mb={"6px !important"}
      >
        {props.reviews.reviewTitle}
      </Typography>

      <Typography
        variant="body1"
        fontSize={{ md: "13px", xs: "11px" }}
        color={reviewBodyColor}
      >
        {props.reviews.message}
      </Typography>

      {/* <Box sx={styles.editBtnContainer}>
        <Button
          sx={[styles.editBtn, { ml: 3 }]}
          variant='contained'
          disableElevation
          size='small'
          onClick={props.onEditClick}
        >
          EDIT REVIEW
        </Button>
      </Box> */}
      <Box sx={styles.editBtnContainer}>
        <Button
          sx={[styles.editBtn, { ml: 3 }]}
          variant="contained"
          disableElevation
          size="small"
          onClick={props.onEditClick}
        >
          EDIT REVIEW
        </Button>
      </Box>
    </Box>
  );
}

export default OwnReview;

const styles = {
  actionContainer: {
    borderTop: borderBackground,
    pt: "10px",
    mt: "36px",
  },

  actionBtn: {
    fontSize: "15px",
    fontWeight: 400,
    textTransform: "uppercase",
    color: reviewSubColor,
    "&:hover": {
      backgroundColor: whiteColor,
    },
  },

  editBtnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    mt: 1,
    pt: 2,
    borderTop: { xs: borderBackground, md: "none" },
    textTransform: "uppercase",
  },

  editBtn: {
    fontSize: "14px",
    fontWeight: 500,
    color: whiteColor,
    backgroundColor: { xs: blackColor, md: blackColor },
    p: "7px 28px",
    borderRadius: "6px",
    textTransform: "uppercase",
    "&:hover": {
      color: { xs: blackColor, md: whiteColor },
      backgroundColor: { md: blackColor, xs: whiteColor },
    },
  },
  editBtnNew: {
    backgroundColor: "#ffffff",
    border: "1px solid #000000",
    float: "right",
    marginTop: "10px",
    fontSize: "12px",
    padding: "5px 20px",
    color: "#000",
    fontWeight: "600",
    borderRadius: "6px",
    textTransform: "uppercase",
    mr:{xs:0, md:5},
    "&:hover": {
      color: { xs: blackColor, md: whiteColor },
      backgroundColor: { md: blackColor, xs: whiteColor },
    },
  },
};
