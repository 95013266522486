import { Box, Grid, Skeleton, Stack, useMediaQuery } from "@mui/material";
import { whiteColor } from "configs/styles/muiThemes";
import React from "react";

function SingleProductShimmer() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return matches ? (
    <Stack spacing={{ md: 0, xs: 1.5 }}>
      <Grid container>
        <Grid item md={5.5} xs={12}>
          <Skeleton
            variant="rectangle"
            width="100%"
            height={350}
            sx={{ borderRadius: "10px" }}
          />
          <Grid container mt="20px" columnSpacing="8px">
            <Grid item md={3}>
              <Skeleton
                variant="rectangle"
                width="85px"
                height={85}
                sx={{ borderRadius: "8px" }}
              />
            </Grid>
            <Grid item md={3}>
              <Skeleton
                variant="rectangle"
                width="85px"
                height={85}
                sx={{ borderRadius: "8px" }}
              />
            </Grid>
            <Grid item md={3}>
              <Skeleton
                variant="rectangle"
                width="85px"
                height={85}
                sx={{ borderRadius: "8px" }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={5.6} xs={12} ml={{ md: 4, xs: 0 }}>
          <Stack gap={0.6}>
            <Skeleton variant="text" width="70%" />
            <Skeleton variant="text" width="90%" />
            <Skeleton variant="text" width="30%" />
            <Skeleton variant="text" width="40%" />
            <Stack my={3} gap={0.5}>
              <Skeleton variant="rectangle" width="30%" height={23} />
              <Skeleton variant="text" width="25%" />
            </Stack>

            <Skeleton
              variant="rectangle"
              width="23%"
              height={37}
              sx={{ borderRadius: "4px" }}
            />
          </Stack>
        </Grid>
      </Grid>
      <Stack>
        <Box
          display="flex"
          gap={5}
          mt="32px"
          borderBottom={"1px solid rgb(232, 232, 240)"}
        >
          <Skeleton variant="rectangle" width="10%" height={22} />
          <Skeleton variant="rectangle" width="10%" height={22} />
        </Box>
        <Stack gap={0.5} mt={2}>
          <Skeleton variant="rectangle" width="10%" height={22} />
          <Skeleton variant="text" width="80%" height={22} />
          <Skeleton variant="text" width="80%" height={22} />
          <Skeleton variant="text" width="50%" height={22} />
        </Stack>
      </Stack>
    </Stack>
  ) : (
    <Stack pt={11}>
      <Stack gap={0.6} px="16px" mb="12px">
        <Skeleton variant="text" width="70%" />
        <Skeleton variant="text" width="90%" />
      </Stack>
      <Stack p="8px 0 14px" gap="16px" bgcolor={whiteColor}>
        <Skeleton variant="rectangle" width="100%" height={250} />
        <Grid container direction="row" columnSpacing="16px" px="16px">
          <Grid item xs={3}>
            <Skeleton
              variant="rectangle"
              width="100%"
              height={70}
              sx={{ borderRadius: "10px" }}
            />
          </Grid>
          <Grid item xs={3}>
            <Skeleton
              variant="rectangle"
              width="100%"
              height={70}
              sx={{ borderRadius: "10px" }}
            />
          </Grid>
          <Grid item xs={3}>
            <Skeleton
              variant="rectangle"
              width="100%"
              height={70}
              sx={{ borderRadius: "10px" }}
            />
          </Grid>
          <Grid item xs={3}>
            <Skeleton
              variant="rectangle"
              width="100%"
              height={70}
              sx={{ borderRadius: "10px" }}
            />
          </Grid>
        </Grid>
      </Stack>
      <Box
        p="23px 15px 15px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack gap={0.5}>
          <Skeleton variant="rectangle" width="130px" height={23} />
          <Skeleton variant="rectangle" width="120px" height={23} />
        </Stack>
        <Skeleton
          variant="rectangle"
          width="100px"
          sx={{ borderRadius: "4px" }}
          height={36}
        />
      </Box>
    </Stack>
  );
}

export default SingleProductShimmer;
