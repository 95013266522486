import { Box, Divider, Typography, useMediaQuery } from "@mui/material";
import {
  AdditionalSaving_CashbackDescription,
  AdditionalSaving_CashbackTitle,
  FutureSaving_Description,
  FutureSaving_SubTitle,
  FutureSaving_Title,
} from "configs/Constants";
import React from "react";
import {
  backgroundColor,
  blackColor,
  borderLineColor,
  couponColor,
  greenColor04,
  greenColor05,
  headingColor,
  lightGreen03,
  mobilePrimaryColor,
  orangeColor01,
  savingTextColor,
  tabTextOrangeColor,
} from "../../configs/styles/muiThemes";

function AdditionalSavingCard({
  cashback,
  savingAmount,
  orderTotal,
  cashbackData,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  let businessFullName = sessionStorage.getItem("businessFullName");

  return matches ? (
    <Box
      // border={{ md: '1px solid ' + borderLineColor, xs: 'none' }}
      borderRadius="6px"
      my={{ md: "15px", xs: 1.5 }}
    >
      <Box
        sx={{
          cursor: "pointer",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          padding: "10px 15px",
          background:
            "linear-gradient(90.19deg, #d9f0e5 3.48%, #deecfc 103.06%)",
          border: "1px solid #bfeddd",
          mb: 1.5,
        }}
      >
        <Box
          component="img"
          maxHeight={{ xs: "25px", md: "32px" }}
          borderRadius={"8px"}
          src={
            "https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/cart-coupon.svg"
          }
          alt="CostBo"
          sx={{ width: "25px", height: "25px" }}
          mr={1}
          ml={{ xs: -3, md: 0 }}
        />
        <Typography
          variant="h6"
          component="h3"
          color={blackColor}
          textAlign="right"
          display="inline"
          lineHeight="25px"
          fontWeight={500}
          fontSize={"13px"}
        >
          Product{" "}
          <Typography
            display="inline"
            variant="h5"
            fontSize={"13px"}
            lineHeight="25px"
            color={blackColor}
            fontWeight={600}
          >
            Discounts ₹{Number(savingAmount).toFixed(2)}
          </Typography>{" "}
          on this order
        </Typography>
      </Box>

      {!matches && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pb={"6px"}
            // borderBottom={{md:'1px solid ' + borderLineColor,xs:"none"}}
          >
            <Typography
              variant="h5"
              fontSize={{ md: 15, xs: 14 }}
              color={headingColor}
            >
              Savings
            </Typography>
          </Box>

          <Box
            sx={{
              padding: "12px 12px",
              border: "1px solid #e2e5ec",
              borderRadius: "12px",
              background: "#fff",
            }}
          >
            <Box>
              <Typography
                variant="h6"
                component="h3"
                color={{ md: headingColor, xs: blackColor }}
                textAlign="right"
                display="inline"
                lineHeight="25px"
                fontSize={{ md: 14, xs: 13 }}
              >
                Product Discounts{" "}
                <Typography
                  display="inline"
                  variant="h5"
                  fontSize={{ md: 14, xs: 13 }}
                  lineHeight="25px"
                  color={{ md: savingTextColor, xs: lightGreen03 }}
                >
                  ₹{Number(savingAmount).toFixed(2)}
                </Typography>
              </Typography>
            </Box>
            {/* <Typography
            variant='h6'
            color={primaryColor}
            lineHeight='40px'
            textAlign='justify'
          >
            {cashback}
            {FutureSaving_SubTitle}
          </Typography>

          <Typography
            variant='body2'
            fontSize='13px'
            fontWeight={500}
            color={couponColor}
            textAlign='justify'
          >
            You will earn{' '}
            <Typography
              fontWeight={600}
              color={savingTextColor}
              display='inline'
            >
              ₹{(orderTotal * (cashback / 100))?.toFixed(2)}
            </Typography>{' '}
            for your next purchase.
          </Typography>
          <Typography
            variant='body2'
            fontSize='13px'
            fontWeight={500}
            color={couponColor}
            textAlign='justify'
            mt={1}
          >
            {FutureSaving_Description}
          </Typography> */}
            {cashbackData !== undefined && cashbackData?.details?.length > 0 ? (
              <Box
                py={{ md: 1.5, xs: 1 }}
                mt={1}
                // borderBottom={'1px solid ' + borderLineColor}
                // borderTop={'1px solid ' + borderLineColor}
              >
                <Typography
                  variant="h6"
                  component="h3"
                  fontSize={{ md: 13, xs: 12 }}
                  color={{ md: headingColor, xs: blackColor }}
                  textAlign="right"
                  display="inline"
                >
                  {AdditionalSaving_CashbackTitle}
                  {"  "}
                  {cashbackData !== undefined &&
                  cashbackData?.details?.length > 0 ? (
                    <Typography
                      display="inline"
                      variant="h5"
                      color={{ md: savingTextColor, xs: lightGreen03 }}
                      fontSize={{ md: 13, xs: 12 }}
                    >
                      ₹
                      {cashbackData?.details?.[0].availablePointValue?.toFixed(
                        2
                      )}{" "}
                    </Typography>
                  ) : null}
                </Typography>

                <Typography
                  variant="body2"
                  fontSize={{ md: 13, xs: 12 }}
                  fontWeight={500}
                  color={{ md: couponColor, xs: blackColor }}
                  textAlign="justify"
                  mt={0.5}
                >
                  {AdditionalSaving_CashbackDescription}
                </Typography>
              </Box>
            ) : null}
            {cashback ? (
              <>
                <Typography
                  variant="h6"
                  component="h3"
                  color={{ md: headingColor, xs: blackColor }}
                  textAlign="start"
                  pt="10px"
                  borderTop={"1px solid " + borderLineColor}
                  fontSize={{ md: 13, xs: 13 }}
                >
                  {FutureSaving_Title}
                </Typography>

                <Typography
                  variant="h6"
                  fontSize={"11px"}
                  color={{ md: orangeColor01, xs: tabTextOrangeColor }}
                  lineHeight="30px"
                  textAlign="justify"
                >
                  {cashback}
                  {FutureSaving_SubTitle}
                </Typography>

                <Typography
                  variant="body2"
                  fontSize={{ md: 13, xs: 12 }}
                  fontWeight={500}
                  color={{ md: couponColor, xs: blackColor }}
                  textAlign="justify"
                >
                  You will earn{" "}
                  <Typography
                    fontWeight={600}
                    color={{ md: savingTextColor, xs: lightGreen03 }}
                    fontSize={{ md: 13, xs: 12 }}
                    display="inline"
                  >
                    ₹{(orderTotal * (cashback / 100))?.toFixed(2)}
                  </Typography>{" "}
                  for your next purchase.
                </Typography>

                <Typography
                  variant="body2"
                  fontSize={{ md: 12.5, xs: 12 }}
                  fontWeight={500}
                  color={{ md: couponColor, xs: blackColor }}
                  textAlign="justify"
                  mt={1}
                >
                  {FutureSaving_Description}
                </Typography>
              </>
            ) : null}
          </Box>
        </>
      )}

      {(cashbackData !== undefined && cashbackData?.details?.length > 0) ||
      cashback ? (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pb={"6px"}
            // borderBottom={{md:'1px solid ' + borderLineColor,xs:"none"}}
          >
            <Typography
              variant="h5"
              fontSize={{ md: 15, xs: 14 }}
              color={headingColor}
            >
              Savings
            </Typography>
          </Box>

          <Box
            sx={{
              padding: "12px 12px",
              border: "1px solid #e2e5ec",
              borderRadius: "12px",
              background: "#fff",
            }}
          >
            {matches &&
            cashbackData !== undefined &&
            cashbackData?.details?.length > 0 ? (
              <Box pb={1}>
                <Typography
                  variant="h6"
                  component="h3"
                  fontSize={13}
                  color={blackColor}
                  textAlign="right"
                  display="inline"
                >
                  {AdditionalSaving_CashbackTitle}
                  {"  "}
                  {cashbackData !== undefined &&
                  cashbackData?.details?.length > 0 ? (
                    <Typography
                      display="inline"
                      variant="h5"
                      color={savingTextColor}
                      fontSize={13}
                    >
                      ₹
                      {cashbackData?.details?.[0].availablePointValue?.toFixed(
                        2
                      )}{" "}
                    </Typography>
                  ) : null}
                </Typography>

                <Typography
                  variant="body2"
                  fontSize="13px"
                  fontWeight={500}
                  color={couponColor}
                  textAlign="justify"
                  mt={1}
                >
                  {AdditionalSaving_CashbackDescription}
                </Typography>
              </Box>
            ) : null}

            {matches && cashback ? (
              <>
                <Typography
                  variant="h6"
                  component="h3"
                  color={blackColor}
                  textAlign="start"
                  pt="10px"
                >
                  {FutureSaving_Title}
                </Typography>

                <Typography
                  variant="h6"
                  fontSize={13}
                  color={{ md: orangeColor01, xs: mobilePrimaryColor }}
                  lineHeight="30px"
                  textAlign="justify"
                >
                  {cashback}
                  {FutureSaving_SubTitle}
                </Typography>

                <Typography
                  variant="body2"
                  fontSize="12.5px"
                  fontWeight={500}
                  color={blackColor}
                  textAlign="justify"
                >
                  You will earn{" "}
                  <Typography
                    fontWeight={600}
                    color={greenColor05}
                    display="inline"
                  >
                    ₹{(orderTotal * (cashback / 100))?.toFixed(2)}
                  </Typography>{" "}
                  for your next purchase.
                </Typography>

                <Typography
                  variant="body2"
                  fontSize="12.5px"
                  fontWeight={500}
                  color={blackColor}
                  textAlign="justify"
                  mt={1}
                >
                  {FutureSaving_Description}
                </Typography>
              </>
            ) : null}
          </Box>
        </>
      ) : null}
    </Box>
  ) : (
    <Box
      border={{ md: "1px solid " + borderLineColor, xs: "none" }}
      borderRadius="6px"
      p={{ md: 1.5, xs: 0 }}
      my={{ md: 3, xs: 1 }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pb={1.5}
        borderBottom={{ md: "1px solid " + borderLineColor, xs: "none" }}
      >
        {matches && (
          <Box
            component="img"
            src="https://storage.googleapis.com/bodefaults/shopweb/cart-saving.png"
            alt={businessFullName + " CostBo"}
            width="34px"
            height="34px"
            mr={1.5}
          />
        )}
        <Typography
          variant="h5"
          fontSize={{ md: 14, xs: 14 }}
          color={headingColor}
        >
          Savings
        </Typography>
      </Box>
      {!matches && (
        <Box
          sx={{
            padding: "6px 12px 6px 12px",
            border: "1px solid #e2e5ec",
            borderRadius: "12px",
            background: "#fff",
          }}
        >
          <Box mb={{ xs: savingAmount ? "5px" : 0, md: "none" }}>
            <Typography
              variant="h6"
              component="h3"
              color={{ md: headingColor, xs: blackColor }}
              textAlign="right"
              display="inline"
              lineHeight="25px"
              fontSize={{ md: 14, xs: 13 }}
            >
              Product Discounts{" "}
              <Typography
                display="inline"
                variant="h5"
                fontSize={{ md: 14, xs: 13 }}
                lineHeight="25px"
                color={{ md: savingTextColor, xs: lightGreen03 }}
              >
                ₹{Number(savingAmount).toFixed(2)}
              </Typography>
            </Typography>
          </Box>
          {/* <Typography
            variant='h6'
            color={orangeColor01}
            lineHeight='40px'
            textAlign='justify'
          >
            {cashback}
            {FutureSaving_SubTitle}
          </Typography>

          <Typography
            variant='body2'
            fontSize='13px'
            fontWeight={500}
            color={couponColor}
            textAlign='justify'
          >
            You will earn{' '}
            <Typography
              fontWeight={600}
              color={savingTextColor}
              display='inline'
            >
              ₹{(orderTotal * (cashback / 100))?.toFixed(2)}
            </Typography>{' '}
            for your next purchase.
          </Typography>
          <Typography
            variant='body2'
            fontSize='13px'
            fontWeight={500}
            color={couponColor}
            textAlign='justify'
            mt={1}
          >
            {FutureSaving_Description}
          </Typography> */}
          {cashbackData !== undefined && cashbackData?.details?.length > 0 ? (
            <Box
              py={{ md: 1.5, xs: 1 }}
              mt={1}
              borderTop={"1px solid " + borderLineColor}
            >
              <Typography
                variant="h6"
                component="h3"
                fontSize={{ md: 13, xs: 12 }}
                color={{ md: headingColor, xs: blackColor }}
                textAlign="right"
                display="inline"
              >
                {AdditionalSaving_CashbackTitle}
                {"  "}
                {cashbackData !== undefined &&
                cashbackData?.details?.length > 0 ? (
                  <Typography
                    display="inline"
                    variant="h5"
                    color={{ md: savingTextColor, xs: lightGreen03 }}
                    fontSize={{ md: 13, xs: 12 }}
                  >
                    ₹
                    {cashbackData?.details?.[0].availablePointValue?.toFixed(2)}{" "}
                  </Typography>
                ) : null}
              </Typography>

              <Typography
                variant="body2"
                fontSize={{ md: 13, xs: 12 }}
                fontWeight={500}
                color={{ md: couponColor, xs: blackColor }}
                textAlign="justify"
                mt={0.5}
              >
                {AdditionalSaving_CashbackDescription}
              </Typography>
            </Box>
          ) : null}
          {cashback ? (
            <>
              <Typography
                variant="h6"
                component="h3"
                color={{ md: headingColor, xs: blackColor }}
                textAlign="start"
                pt="6px"
                borderTop={"1px solid " + borderLineColor}
                fontSize={{ md: 14, xs: 13 }}
              >
                {FutureSaving_Title}
              </Typography>

              <Typography
                variant="h6"
                fontSize={"12px"}
                color={{ md: orangeColor01, xs: tabTextOrangeColor }}
                lineHeight="24px"
                textAlign="justify"
              >
                {cashback}
                {FutureSaving_SubTitle}
              </Typography>

              <Typography
                variant="body2"
                fontSize={{ md: 13, xs: 12 }}
                fontWeight={500}
                color={{ md: couponColor, xs: blackColor }}
                textAlign="justify"
              >
                You will earn{" "}
                <Typography
                  fontWeight={600}
                  color={{ md: savingTextColor, xs: lightGreen03 }}
                  fontSize={{ md: 13, xs: 12 }}
                  display="inline"
                >
                  ₹{(orderTotal * (cashback / 100))?.toFixed(2)}
                </Typography>{" "}
                for your next purchase.
              </Typography>

              <Typography
                variant="body2"
                fontSize={{ md: 13, xs: 12 }}
                fontWeight={500}
                color={{ md: couponColor, xs: blackColor }}
                textAlign="justify"
                mt={1}
              >
                {FutureSaving_Description}
              </Typography>
            </>
          ) : null}
        </Box>
      )}
      {matches && (
        <Box py={1.2}>
          <Typography
            variant="h6"
            component="h3"
            color={headingColor}
            textAlign="right"
            display="inline"
            lineHeight="25px"
          >
            Product Discounts{"  "}
            <Typography
              display="inline"
              variant="h5"
              fontSize={14}
              lineHeight="25px"
              color={savingTextColor}
            >
              ₹{Number(savingAmount).toFixed(2)}
            </Typography>
          </Typography>
        </Box>
      )}

      {matches &&
      cashbackData !== undefined &&
      cashbackData?.details?.length > 0 ? (
        <Box
          py={1.5}
          borderBottom={"1px solid " + borderLineColor}
          borderTop={"1px solid " + borderLineColor}
        >
          <Typography
            variant="h6"
            component="h3"
            fontSize={13}
            color={headingColor}
            textAlign="right"
            display="inline"
          >
            {AdditionalSaving_CashbackTitle}
            {"  "}
            {cashbackData !== undefined && cashbackData?.details?.length > 0 ? (
              <Typography
                display="inline"
                variant="h5"
                color={savingTextColor}
                fontSize={13}
              >
                ₹{cashbackData?.details?.[0].availablePointValue?.toFixed(2)}{" "}
              </Typography>
            ) : null}
          </Typography>

          <Typography
            variant="body2"
            fontSize="13px"
            fontWeight={500}
            color={couponColor}
            textAlign="justify"
            mt={1}
          >
            {AdditionalSaving_CashbackDescription}
          </Typography>
        </Box>
      ) : null}

      {matches && cashback ? (
        <>
          <Typography
            variant="h6"
            component="h3"
            color={headingColor}
            textAlign="start"
            pt="10px"
            borderTop={"1px solid " + borderLineColor}
          >
            {FutureSaving_Title}
          </Typography>

          <Typography
            variant="h6"
            color={orangeColor01}
            lineHeight="40px"
            textAlign="justify"
          >
            {cashback}
            {FutureSaving_SubTitle}
          </Typography>

          <Typography
            variant="body2"
            fontSize="13px"
            fontWeight={500}
            color={couponColor}
            textAlign="justify"
          >
            You will earn{" "}
            <Typography
              fontWeight={600}
              color={savingTextColor}
              display="inline"
            >
              ₹{(orderTotal * (cashback / 100))?.toFixed(2)}
            </Typography>{" "}
            for your next purchase.
          </Typography>

          <Typography
            variant="body2"
            fontSize="13px"
            fontWeight={500}
            color={couponColor}
            textAlign="justify"
            mt={1}
          >
            {FutureSaving_Description}
          </Typography>
        </>
      ) : null}
    </Box>
  );
}

export default AdditionalSavingCard;
