import { Box, FormGroup, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import MainCategory from "./MainCategory";
import CheckBoxLabel from "./CheckBoxLabel";
import {
  blackColor,
  labelColor,
  orangeColor01,
  productLabelColor,
} from "configs/styles/muiThemes";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CategoryFilterSideBar = ({
  category,
  filterProducts,
  filteredSubCat,
  handleAllProductClick,
  handleRootCategoryClick,
  handleProductCatClick,
  handleSubCatClick,
}) => {
  const [showCategory, setShowCategory] = useState(true);

  return (
    <Box mt={1.2} pr={2}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb="8px"
      >
        <Typography
          variant="body1"
          color={productLabelColor}
          fontSize="13px"
          fontWeight={600}
          sx={{ cursor: "pointer" }}
          onClick={handleAllProductClick}
        >
          All Products
        </Typography>

        <IconButton
          sx={{ width: "30px", height: "30px", color: blackColor, p: "10px" }}
          onClick={() => {
            setShowCategory(!showCategory);
          }}
        >
          {showCategory ? (
            <ExpandLessIcon color="inherit" sx={{ fontSize: "22px" }} />
          ) : (
            <ExpandMoreIcon color="inherit" sx={{ fontSize: "22px" }} />
          )}
        </IconButton>
      </Box>

      {showCategory &&
        category?.map((item, index) => {
          return (
            <Box>
              <MainCategory
                label={item.value}
                productCount={item?.productCount}
                titleColor={
                  item?.value === filterProducts?.title
                    ? orangeColor01
                    : productLabelColor
                }
                catCode={item}
                onViewMore={() => handleRootCategoryClick(item)}
              />
              {item.subCat.map((subcat, index) => {
                return (
                  <Box ml={1}>
                    <MainCategory
                      isSubCatagory
                      label={subcat.value}
                      productCount={subcat?.productCount}
                      titleColor={
                        subcat?.value === filterProducts?.title
                          ? orangeColor01
                          : productLabelColor
                      }
                      subCat={subcat}
                      onViewMore={(subCat) => {
                        handleProductCatClick(subCat);
                      }}
                    />
                    <Box mb={subcat?.subCat2?.length > 0 ? "10px" : 0}>
                      {subcat?.subCat2?.map((subcat2, index) => {
                        return (
                          <FormGroup>
                            <CheckBoxLabel
                              // label={subcat2.value}
                              label={
                                <Typography
                                  variant="body1"
                                  color={blackColor}
                                  fontSize="12px"
                                  fontWeight={400}
                                >
                                  {subcat2.value}
                                </Typography>
                              }
                              subCategory={subcat2}
                              checked={filteredSubCat.includes(subcat2)}
                              onValuesChanged={(catCode) => {
                                handleSubCatClick(catCode);
                              }}
                            />
                          </FormGroup>
                        );
                      })}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          );
        })}
    </Box>
  );
};

export default CategoryFilterSideBar;
