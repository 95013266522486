import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import {
  blackColor,
  borderLineColor,
  couponColor,
  headingColor,
  whiteColor,
} from '../../configs/styles/muiThemes'
import { LocalShipping } from '@mui/icons-material'

function DeliveryMethod({ shipType, delivery }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))

  return (
    <>
      <Box
        sx={{ backgroundColor: whiteColor }}
        border={"1px solid " + borderLineColor}
        borderRadius={2.2}
        p={{ xs: 1, md: 1 }}
        mb={{ xs: 1, md: 2 }}
        mt={{ xs: 1, md: 0 }}
        pb={0}
      >
        <Box display={"flex"} alignContent={"center"}>
          <Box
            component="img"
            src="https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/truck-delivery.svg"
            alt="cod icon"
            width="40px"
            height="40px"
            sx={{ objectFit: "contain" }}
          />
          <Typography
            variant="subtitle1"
            component="p"
            fontSize={{ xs: 13, md: 13 }}
            color={blackColor}
            sx={{ textTransform: "capitalize" }}
            // mb={2}
            ml={1}
            alignItems={"center"}
            textAlign={"center"}
            lineHeight={"38px"}
          >
            {/* Regular Delivery (2-5 days) {shipType} */}
            {delivery}
          </Typography>
        </Box> 
        {/* {!matches && (
          <Box
            display={'flex'}
            borderBottom={'1px solid ' + borderLineColor}
            pb={1}
          >
            <LocalShipping
              sx={{
                fontSize: { xs: '22px', md: '45px' },
                color: blackColor,
                mr: 1,
              }}
            />
            <Typography
              variant='h5'
              component='h3'
              fontSize={{ xs: 14, md: 15 }}
              color={headingColor}
              textAlign={{ md: 'right', xs: 'left' }}
              mb={1}
            >
              Delivery Method
            </Typography>
          </Box>
        )} */}

        {/* <Grid container rowSpacing={0} columnSpacing={0} alignItems="center"> */}
          {/* {!matches ? (
            <Grid item>
              <LocalShipping color={blackColor} />
            </Grid>
          ) : ( */}
          {/* <Grid item>
            <Box
              component="img"
              src="https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/truck-delivery.svg"
              alt="cod icon"
              width="40px"
              height="40px"
              sx={{ objectFit: "contain" }}
            />
          </Grid> */}
          {/* )} */}

          {/* <Grid item p={"0 !important"} ml={1}>
            <Typography
              variant="subtitle1"
              component="p"
              fontSize={{ xs: 13, md: 13 }}
              color={blackColor}
              sx={{ textTransform: "capitalize" }}
              // mb={2}
            >
              {/* Regular Delivery (2-5 days) {shipType} */}
              {/* {delivery} */}
            {/* </Typography> */}
          {/* </Grid> */} 
        {/* </Grid> */}
      </Box>
    </>
  );
}

export default DeliveryMethod
