import { Box, Container, Grid, Skeleton } from '@mui/material'
import { backgroundColor01, whiteColor } from 'configs/styles/muiThemes'
import React from 'react'

const CartMobileLoader = () => {
  return (
    <Box bgcolor={backgroundColor01}>
      <Container
        sx={{
          maxWidth: "1350px !important",
          p: "0 10px 0 15px !important",
        }}
      >

        <Box bgcolor={whiteColor} borderRadius='8px'>
            <CartItemSkeleton />
            <CartItemSkeleton />
        </Box>

        <Skeleton variant="rounded" width="100px" height="13px" sx={{mt:'18px !important'}}/>
        <Skeleton variant="rounded" width="auto" height="135px" sx={{mt:'12px !important'}} />
        <Skeleton variant="rounded" width="auto" height="58px" sx={{mt:'12px !important'}} />
        <Skeleton variant="rounded" width="auto" height="58px" sx={{mt:'12px !important'}} />
      </Container>
    </Box>
  )
}

const CartItemSkeleton = ()=>(
    <Box display='flex' alignItems='flex-start' gap={0.7} mr={1.4} mb={2}>
        <Skeleton variant="rounded" width="60px" height="60px" sx={{m:'8px 8px 0 10px !important'}}/>

        <Box flex={1} mt={1.2}>
           <Skeleton variant="rounded" width="auto" height="12px" sx={{mb:'8px !important'}}/>
           <Box display='flex' alignItems='center' gap={0.9}>
              <Skeleton variant="rounded" width="50px" height="12px" sx={{mb:'8px !important'}}/>
              <Skeleton variant="rounded" width="50px" height="12px" sx={{mb:'8px !important'}}/>
              <Skeleton variant="rounded" width="50px" height="12px" sx={{mb:'8px !important'}}/>
           </Box>

           <Box display='flex' alignItems='center' gap={7} mt={3.5} mb={2.5}>
               <Skeleton variant="rounded" width="92px" height="30px" />
               <Skeleton variant="rounded" width="33px" height="33px" sx={{borderRadius:'50%'}}/>
           </Box>
        </Box>
    </Box>
)

export default CartMobileLoader