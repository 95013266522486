import { Box, Grid, Skeleton, useMediaQuery } from "@mui/material";
import { bgColor, borderLineColor } from "configs/styles/muiThemes";
import React from "react";

function CatelogHeaderShimmer() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return matches ? (
    <Box
      sx={{
        minHeight: "58px",
        display: "flex",
        alignItems: "center",
      }}
      borderBottom={"1px solid " + borderLineColor}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        py={{ md: 1.5, xs: 0.5 }}
      >
        <Grid item>
          <Skeleton
            variant="rectangle"
            width="80px"
            height={22}
            sx={{ borderRadius: "4px" }}
          />
        </Grid>
        <Grid item xs={9.15} display="flex" gap={2}>
          <Skeleton
            variant="rectangle"
            width="142px"
            height={30}
            sx={{ borderRadius: "4px" }}
          />
          <Skeleton
            variant="rectangle"
            width="142px"
            height={30}
            sx={{ borderRadius: "4px" }}
          />
        </Grid>
      </Grid>
    </Box>
  ) : (
    <Box
      bgcolor={bgColor}
      sx={{
        minHeight: "44px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        px: "15px",
      }}
    >
      <Skeleton variant="rectangle" width="66px" height={20} />
      <Box display="flex" gap="12px" alignItems="center">
        <Skeleton variant="rectangle" width="20px" height={20} />
        <Skeleton variant="rectangle" width="20px" height={20} />
        <Skeleton variant="rectangle" width="28px" height={28} />
      </Box>
    </Box>
  );
}

export default CatelogHeaderShimmer;
