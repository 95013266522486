import { Box, Button, CircularProgress } from '@mui/material'
import React from 'react'
import SkipPreviousOutlinedIcon from '@mui/icons-material/SkipPreviousOutlined'
import SkipNextOutlinedIcon from '@mui/icons-material/SkipNextOutlined'
import styled from '@emotion/styled'
import {
  greyShade34,
  whiteColor,
  orangeColor01,
} from "../../configs/styles/muiThemes";

function FormActionButtons({
  onSave,
  onNext,
  onPrevious,
  hidePreviousBtn,
  hideNextBtn,
  hideSaveBtn,
  loading,
}) {
  return (
    <Box display='flex' alignItems='center' gap='20px'>
      {!hidePreviousBtn && (
        <StyledButton onClick={onPrevious}>
          <SkipPreviousOutlinedIcon />
        </StyledButton>
      )}

      <Box flex={1}>
        {!hideSaveBtn && (
          <StyledSaveButton onClick={onSave} fullWidth>
            {loading && <CircularProgress size={14} sx={{ color: greyShade34 }} />}
            ENROLL AS AFFILIATE
          </StyledSaveButton>
        )}
      </Box>

      {!hideNextBtn && (
        <StyledButton onClick={onNext}>
          <SkipNextOutlinedIcon />
        </StyledButton>
      )}
    </Box>
  )
}

export default FormActionButtons

const StyledSaveButton = styled(Button)({
  color: whiteColor,
  backgroundColor: orangeColor01,
  border: "1px solid " + orangeColor01,

  "&:hover": {
    color: orangeColor01,
    backgroundColor: whiteColor,
  },
});

const StyledButton = styled(Button)({
  color: whiteColor,
  backgroundColor: orangeColor01,
  border: "2px solid " + orangeColor01,

  "&:hover": {
    color: orangeColor01,
    backgroundColor: whiteColor,
  },
});
