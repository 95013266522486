import { Box, IconButton, Typography } from '@mui/material'
import React from 'react'
import {
  blackColor,
  greyShade38,
  greyShade39,
  greyShade57,
  whiteColor,
} from '../../configs/styles/muiThemes'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SettingsIcon from '@mui/icons-material/Settings'
// import SearchIcon from '@mui/icons-material/Search'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import { useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import { useSelector } from 'react-redux'

function ShareEarnHeader({
  title,
  handleBackClick,
  handleSearchClick,
  hideBackBtn,
  hideActiveBtn,
}) {
  const navigate = useNavigate()
  const { businessName } = useParams()
  const [showMenu, setShowMenu] = useState(false)

  const { isUserLogin } = useSelector((state) => state.auth)
  const { rellerProfileData } = useSelector((state) => state.affiliate)

  return (
    <Box
      sx={{
        width: { xs: '100%', md: '400px' },
        height: '47.5px',
        display: 'flex',
        alignItems: 'center',
        background: blackColor,
        // background:
        //   'linear-gradient(135deg, rgb(1, 164, 181), rgb(98, 201, 169))',
        // position: 'fixed',
        // top: {xs:'49px', md:'92px'},
        // zIndex: 10,
        position: 'relative',
      }}
    >
      {!hideBackBtn ? (
        <IconButton
          onClick={() => {
            if (showMenu) {
              setShowMenu(false)
            } else {
              handleBackClick()
            }
          }}
        >
          <ArrowBackIcon sx={{ fontSize: '26px', color: whiteColor }} />
        </IconButton>
      ) : (
        <Box sx={{ width: '42px', height: '42px' }} />
      )}

      <Typography
        variant='h6'
        fontSize={15}
        flex={1}
        textAlign={hideBackBtn ? 'left':"center"}
        color={whiteColor}
        ml={hideBackBtn  ? - 3:0}
      >
        {title}
      </Typography>

      <Box display='flex' alignItems='center'>
        {hideActiveBtn ? (
          <Box sx={{ width: '42px', height: '42px' }} />
        ) : isUserLogin ? (
          <>
            <IconButton
              onClick={() => {
                setShowMenu(!showMenu)
              }}
            >
              <SettingsIcon sx={{ fontSize: '24px', color: whiteColor }} />
            </IconButton>

            {/* <IconButton onClick={handleSearchClick}>
              <SearchIcon sx={{ fontSize: '26px', color: whiteColor }} />
            </IconButton> */}
          </>
        ) : (
          <Box sx={{ width: '42px', height: '42px' }} />
        )}
      </Box>

      {showMenu && (
        <AffiliateSideMenu
          navigate={(path, state) => {
            setShowMenu(false)
            navigate(path, state)
          }}
          businessName={businessName}
          disableAffiliateInfo={!rellerProfileData?.details}
          disableBankInfo={!rellerProfileData?.details?.bankInfo?.accountNo}
          showMenu={showMenu}
          setShowMenu={setShowMenu}
        />
      )}
    </Box>
  )
}

export default ShareEarnHeader

const AffiliateSideMenu = ({
  navigate,
  disableAffiliateInfo,
  disableBankInfo,
  businessName,
  showMenu,
  setShowMenu,
}) => {
  return (
    <Box sx={styles.menuContainer}>
      <Box sx={styles.menu}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            ml: '-6px',
          }}
        >
          {/* <IconButton
            onClick={() => {
              if (showMenu) {
                setShowMenu(false)
              }
            }}
            sx={{ p: '3px' }}
          >
            <ArrowBackIcon sx={{ fontSize: '22px', color: greyShade38 }} />
          </IconButton> */}

          <Typography
            variant='body1'
            fontSize={12.5}
            fontWeight={500}
            color={greyShade57}
          >
            Enrollment info
          </Typography>
        </Box>

        <SideMenuItem
          title='Affiliate Code'
          icon='https://storage.googleapis.com/bodefaults/shopweb/affiliate/affiliate-code.svg'
          onClick={() => {
            console.log('HITS')
            navigate(`/${businessName}/affiliateEnroll`, {
              state: { formNum: 0, readOnly: true },
            })
          }}
          disabled={disableAffiliateInfo}
        />
        <SideMenuItem
          title='Banking Details'
          icon='https://storage.googleapis.com/bodefaults/shopweb/affiliate/bank.svg'
          onClick={() => {
            navigate(`/${businessName}/affiliateBankDetails`, {
              state: { formNum: 1, readOnly: true },
            })
          }}
          disabled={disableBankInfo}
        />

        {/* <SideMenuItem
          title='Affiliate Code Edit'
          icon='https://storage.googleapis.com/bodefaults/shopweb/affiliate/affiliate-code.svg'
          // onClick={() => {
          //   console.log('HITS')
          //   navigate(`/${businessName}/affiliateEnroll`, {
          //     state: { formNum: 0, readOnly: true },
          //   })
          // }}
          disabled={disableAffiliateInfo}
        /> */}
        {/* <SideMenuItem
          title='Banking Details Edit'
          icon='https://storage.googleapis.com/bodefaults/shopweb/affiliate/bank.svg'
          // onClick={() => {
          //   navigate(`/${businessName}/affiliateBankDetails`, {
          //     state: { formNum: 1, readOnly: true },
          //   })
          // }}
          disabled={disableBankInfo}
        /> */}
        {/* <SideMenuItem
          title='Address Detail'
          icon=''
          onClick={() => {
            navigate('/affiliateEnroll', {
              state: { formNum: 2, readOnly: true },
            })
          }}
        />
        <SideMenuItem
          title='Social Media Handles'
          icon=''
          onClick={() => {
            navigate('/affiliateEnroll', {
              state: { formNum: 3, readOnly: true },
            })
          }}
        /> */}

        {/* <Typography
          variant='body1'
          fontSize={13}
          fontWeight={500}
          color={greyShade38}
          m='5px 0'
        >
          Settlement Info
        </Typography> */}

        {/* <SideMenuItem
          title='Pending'
          icon='https://storage.googleapis.com/bodefaults/shopweb/affiliate/pending.svg'
          onClick={() => {
            navigate('/affiliatePayments')
          }}
        />
        <SideMenuItem
          title='paid'
          icon='https://storage.googleapis.com/bodefaults/shopweb/affiliate/settled.svg'
          onClick={() => {
            navigate('/affiliatePayments')
          }}
        /> */}
        {/* <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            ml: '-6px',
          }}
        >
          {/* <IconButton
            onClick={() => {
              if (showMenu) {
                setShowMenu(false)
              }
            }}
            sx={{ p: '3px' }}
          >
            <ArrowBackIcon sx={{ fontSize: '22px', color: greyShade38 }} />
          </IconButton> 

          <Typography
            variant='body1'
            fontSize={12.5}
            fontWeight={500}
            color={greyShade57}
          >
            Settlement Info
          </Typography>
        </Box>
        <Box display={'flex'} justifyContent={'space-between'} mr={2}>
          <SideMenuItem
            title='Pending'
            icon='https://storage.googleapis.com/bodefaults/shopweb/supportANDFooter/pending.svg'
            hidearrow
          />
          <SideMenuItem
            title='Settled'
            icon='https://storage.googleapis.com/bodefaults/shopweb/supportANDFooter/settled.svg'
            hidearrow
          />
        </Box> */}

        <Typography
          variant='body1'
          fontSize={13}
          fontWeight={500}
          color={greyShade57}
        >
          About
        </Typography>

        <SideMenuItem
          title='Brand Affiliate Program'
          icon='https://storage.googleapis.com/bodefaults/shopweb/affiliate/brand-affiliate.svg'
          onClick={() => {
            navigate(`/${businessName}/affiliates`, {
              state: { disableAutoNav: true },
            })
          }}
        />
      </Box>
    </Box>
  )
}

const SideMenuItem = ({ title, icon, onClick, disabled, hidearrow }) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      p='10px 0'
      sx={{ cursor: 'pointer', opacity: disabled ? 0.3 : 1 }}
      onClick={() => {
        if (disabled) return
        onClick()
      }}
    >
      <Box display='flex' alignItems='center' gap='6px'>
        <Box
          component='img'
          src={icon}
          alt='costBo'
          sx={{ width: 22, heihgt: 22, objectFit: 'contain' }}
        />

        <Typography
          variant='body1'
          fontSize={14}
          fontWeight={600}
          color={blackColor}
        >
          {title}
        </Typography>
      </Box>

     { !hidearrow &&<ChevronRightOutlinedIcon fontSize='small' sx={{ color: greyShade39 }} />}
    </Box>
  )
}

const styles = {
  menuContainer: {
    width: { xs: '100%', md: '400px' },
    // height: { xs: '100vh', md: 'calc(100vh - 100px)' },
    height: '200vh',
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: 'rgba(0,0,0,0.2)',
    position: 'absolute',
    top: { xs: '51px', md: '51px' },
    bottom: { xs: '70px', md: '30px' },
    zIndex: 9,
  },

  menu: {
    width: '268px',
    height: '60%',
    backgroundColor: whiteColor,
    p: 2,
    pt:1,
  },
}
