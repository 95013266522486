import { Box, Typography, useMediaQuery } from '@mui/material'
import {
  blackColor,
  blackColor01,
  borderLineColor,
  couponColor,
  headingColor,
  whiteColor,
} from '../../configs/styles/muiThemes'
import PlaceIcon from '@mui/icons-material/Place'

function DeliveryAddress({ address, isPayCart }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))

  return (
    <>
      {matches ? (
        <Box
          sx={{ backgroundColor: whiteColor }}
          border={"1px solid " + borderLineColor}
          borderRadius='12px'
          p={{ xs: 1.85, md: "6px 10px" }}
          mb={{ xs: 3, md: 2 }}
        >
          <Typography
            variant="h6"
            fontSize={{ xs: 15, md: 13 }}
            color={blackColor}
            lineHeight="30px"
            textAlign="start"
          >
            {address?.name} {address?.type ? `(${address?.type})` : ""}
          </Typography>
          <Box display={"flex"}>
            <Typography
              variant="body2"
              fontSize={{ xs: 13, md: 12.5 }}
              fontWeight={500}
              color={blackColor01}
              // textAlign="start"
            >
              {address?.addressLine1}
              {address?.landMark ? `, ${address?.landMark}` : ""}
              {address?.state ? `, ${address?.state}`:""}, {address?.country}
              {address?.city ? `, ${address?.city}` : ""} - {address?.zip}
            </Typography>
          </Box>
          <Box
            display={"flex"}
            sx={{
              borderTop: "1px solid #EAEAF1",
              marginTop: "8px",
            }}
            flexDirection={"column"}
          >
            <Typography
              variant="body2"
              fontSize={{ xs: 13, md: 12.5 }}
              fontWeight={500}
              color={blackColor}
              textAlign="start"
              mt={1}
            >
              Ph:{" "}
              {isPayCart
                ? `+91 ${address?.alternatePhoneNo?.replace("+91", "")}`
                : address?.phoneNo}
            </Typography>
            {/* <Typography
              sx={{
                color: '#838390',
                fontWeight: 400,
                fontSize: '12.5px',
              }}
            >
              Phone :{' '}
              <Typography
                color={blackColor}
                fontSize={12.5}
                fontWeight={500}
                ml={1}
                display={"inline-block"}
              >
                {address?.phoneNo}{' '}
                {address?.alternatePhoneNo
                  ? address?.alternatePhoneNo
                    ? `, +91${address?.alternatePhoneNo}`
                    : ''
                  : ''}
              </Typography>
            </Typography> */}

            {address?.alternatePhoneNo && (
              <Box display={"flex"}>
                <Typography
                  sx={{
                    color: blackColor,
                    fontWeight: 400,

                    fontSize: "12.5px",
                  }}
                >
                  Recipient Ph: :
                </Typography>
                <Typography
                  color={blackColor}
                  fontSize={12.5}
                  fontWeight={500}
                  ml={1}
                >
                  {address?.alternatePhoneNo
                    ? address?.alternatePhoneNo
                      ? ` +91${address?.alternatePhoneNo}`
                      : ""
                    : ""}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{ backgroundColor: whiteColor }}
          border={"1px solid " + borderLineColor}
          borderRadius={2.2}
          p={1.85}
          mb={{ md: 3, xs: 2 }}
          mt={{ xs: 0, md: 3 }}
        >
          <Box
            display={"flex"}
            borderBottom={"1px solid " + borderLineColor}
            pb={1}
          >
            {!matches && (
              <PlaceIcon
                sx={{
                  fontSize: { xs: "22px", md: "45px" },
                  color: blackColor,
                  mr: 1,
                }}
              />
            )}
            <Typography
              variant="h5"
              component="h3"
              fontSize={{ xs: 14, md: 13 }}
              color={headingColor}
              textAlign={{ md: "right", xs: "left" }}
            >
              {isPayCart ? "Customer Address" : "Delivery Address"}
            </Typography>
          </Box>
          <Typography
            variant="h6"
            fontSize={{ xs: 15, md: 15 }}
            color={headingColor}
            lineHeight="42px"
            textAlign="start"
          >
            {address?.name} {address?.type ? `(${address?.type})` : ""}
          </Typography>

          <Typography
            variant="body2"
            fontSize={{ xs: 13, md: 14 }}
            fontWeight={500}
            color={couponColor}
            textAlign="start"
          >
            {address?.addressLine1}
          </Typography>
          <Typography
            variant="body2"
            fontSize={{ xs: 13, md: 14 }}
            fontWeight={500}
            color={couponColor}
            textAlign="start"
          >
            {address?.landMark}
          </Typography>
          <Typography
            variant="body2"
            fontSize={{ xs: 13, md: 14 }}
            fontWeight={500}
            color={couponColor}
            mt={0.6}
            textAlign="start"
          >
            {address?.city} - {address?.zip}
          </Typography>
          <Typography
            variant="body2"
            fontSize={{ xs: 13, md: 14 }}
            fontWeight={500}
            color={couponColor}
            textAlign="start"
          >
            {address?.state}, {address?.country}
          </Typography>

          <Box
            sx={{
              borderTop: "1px solid #EAEAF1",
              paddingTop: "10px",
              marginTop: "8px",
            }}
          >
            <Box display={"flex"}>
              <Typography
                sx={{
                  color: "#838390",
                  fontWeight: 400,

                  fontSize: "12.5px",
                }}
              >
                Phone :
              </Typography>
              <Typography
                color={blackColor}
                fontSize={12.5}
                fontWeight={500}
                ml={1}
              >
                {address?.phoneNo}
              </Typography>
            </Box>

            {address?.alternatePhoneNo && (
              <Box display={"flex"}>
                <Typography
                  sx={{
                    color: "#838390",
                    fontWeight: 400,

                    fontSize: "12.5px",
                  }}
                >
                  Recipient Ph: :
                </Typography>
                <Typography
                  color={blackColor}
                  fontSize={12.5}
                  fontWeight={500}
                  ml={1}
                >
                  {address?.alternatePhoneNo
                    ? address?.alternatePhoneNo
                      ? ` +91${address?.alternatePhoneNo}`
                      : ""
                    : ""}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}

export default DeliveryAddress
