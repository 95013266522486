import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCartDetailsById,
  getCommercialDetails,
} from "redux/addToCart/AddToCartSlice";
import FooterMui from "../components/FooterMui";
import ConfirmationContent from "./Confirmation/ConfirmationContent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  backgroundColor01,
  blackColor,
  headingColor,
  whiteColor,
} from "configs/styles/muiThemes";
import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import BreadcrumbsSection from "components/Breadcrumbs";
import TopComponent from "./TopComponent";
import Loader from "components/Loader";

import { getUserName } from "utils/authHelpers";
import ConfirmationPageLoader from "components/skeletonLoaders/cart/ConfirmationPageLoader";
import HeaderLoader from "components/skeletonLoaders/HeaderLoader";
import ConfirmationPageMobileLoader from "components/skeletonLoaders/cart/ConfirmationPageMobileLoader";

function Confirmation() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const location = useLocation();
  const [pageLoading, setPageLoading] = useState(true);
  const { businessName } = useParams();
  let navigation = useNavigate();

  const { cartDetails, commercialDetails } = useSelector((state) => state.cart);

  const dispatch = useDispatch();
  const isLoggedIn = getUserName().length === 0 ? false : true;

  useEffect(() => {
    if (!isLoggedIn || !location?.state?.cartId) {
      navigation(`/${businessName}`);
    }
  }, []);

  useEffect(() => {
    dispatch(getCommercialDetails());
    setTimeout(() => {
      if (location?.state?.cartId) {
        dispatch(getCartDetailsById(location?.state?.cartId))
          .then(() => {
            setPageLoading(false);
          })
          .catch((err) => {
            setPageLoading(false);
          });
      }
    }, 5000);
  }, []);
  return (
    <>
      <Box backgroundColor={backgroundColor01}>
        <Box
          sx={{
            position: "sticky",
            top: 0,
            width: "100%",
            zIndex: 10,
            backgroundColor: { xs: whiteColor, md: backgroundColor01 },
            pb: { md: 0.8, xs: 0 },
          }}
        >
          {/* <Navbar value='' /> */}
          {matches && (
            <Box minHeight="126.7px" bgcolor={whiteColor}>
              {commercialDetails ? (
                <TopComponent value="" disableBanners />
              ) : (
                <HeaderLoader />
              )}
            </Box>
          )}

          {pageLoading && (matches ? <ConfirmationPageLoader /> : <ConfirmationPageMobileLoader />)}

          {!pageLoading && (
            <>
              <Container
                sx={{
                  maxWidth: "1350px !important",
                  mt: { xs: 0, md: "15px" },
                  paddingX: "15px !important",
                }}
              >
                {matches ? (
                  <BreadcrumbsSection
                    links={[
                      { label: "Catalog", to: `/catalog` },
                      { label: "Cart", to: `#`, disabled: true },
                      { label: "Discount/Delivery", to: `#`, disabled: true },
                      { label: "Order Confirmation", to: `/confirmation` },
                    ]}
                  />
                ) : (
                  <BreadcrumbsSection
                    links={[
                      { label: "Catalog", to: `/catalog` },
                      { label: "Cart", to: `/cart` },
                      { label: "Discount/Delivery", to: `#`, disabled: true },
                      { label: "Order Confirmation", to: `/confirmation` },
                    ]}
                    detail={"Confirmation"}
                  />
                )}

                {matches && (
                  <Typography
                    variant="subtitle1"
                    component="h5"
                    fontSize={{ xs: 15, md: 16 }}
                    fontWeight={600}
                    color={{ md: headingColor, xs: blackColor }}
                    mt={"12px"}
                    ml={0.1}
                  >
                    Confirmation
                  </Typography>
                )}
              </Container>
            </>
          )}
        </Box>

        {/* <ConfirmationPageMobileLoader /> */}

        {!pageLoading && (
          <>
            <Container
              sx={{
                maxWidth: "1350px !important",
                paddingX: "15px !important",
              }}
            >
              <ConfirmationContent
                cartDetails={cartDetails}
                commercialDetails={commercialDetails}
              />
            </Container>
            {matches && <FooterMui />}
          </>
        )}
      </Box>
    </>
  );
}

export default Confirmation;
