import {
  Box,
  Button,
  Container,
  Dialog,
  FormGroup,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import {
  backgroundColor01,
  backgroundColor03,
  labelColor,
  orangeColor01,
  productLabelColor,
  whiteColor,
} from "../configs/styles/muiThemes";
import { useDispatch, useSelector } from "react-redux";
import {
  getCatalogAllProducts,
  getCategoryDataFinder,
  getDataForSideBar,
} from "redux/subCatergory/subCategorySlice";
import {
  getCartDetails,
  getCommercialDetails,
} from "redux/addToCart/AddToCartSlice";
// import FilteredCategory from './CatalogMui/FilteredCategory'
// import FilteredSubCategory from './CatalogMui/FilteredSubCategory'

import {
  addLocalCart,
  addNewProductCart,
  updateCartProductQuantity,
  updateToLocalCart,
  createCart,
  removeProductLocalCart,
} from "redux/cart/CartSlice";
import {
  createCartLoginHelper,
  removeCartProductLoginHelper,
} from "utils/cartHelper";
import { getUserName, removeSessionInfo } from "utils/authHelpers";
import SearchResult from "./CatalogMui/SearchResult";
import { updateCartDetails } from "redux/cart/CartSlice";
import { getBusinessIdDetails } from "redux/rootSlice/RootSlice";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import TopComponent from "./TopComponent";
import FooterMui from "./../components/FooterMui";
import AllCategoryProducts from "./CatalogMui/AllCategoryProducts";
import CatalogHeader from "./CatalogMui/CatalogHeader";
import CategoryGroup from "./CatalogMui/CategoryGroup";
import CheckBoxLabel from "./CatalogMui/CheckBoxLabel";
import MainCategory from "./CatalogMui/MainCategory";
import ProductFilterMobile from "./CatalogMui/ProductFilterMobile";
import CartDetailsBottonCard from "../components/CartDetailsBottonCard";
import FilteredCategory from "./CatalogMui/FilteredCategory";
import FilteredSubCategory from "./CatalogMui/FilteredSubCategory";
import Loader from "components/Loader";
import FullHeightLoader from "components/FullHeightLoader";
import OfferPopupModal from "components/OfferPopupModal/OfferPopupModal";
import BreadcrumbNew from "components/BreadcrumbNew";
import CategoryFilterSideBar from "./CatalogMui/CategoryFilterSideBar";
import { getUnicomInventoryApi } from "services/api_calls/subCategory/SubCategoryApis";
import { checkUnicomInventory } from "utils/catalogValidationHelper";
import { clearSearchData } from "redux/searchProduct/SearchProductSlice";
import CategoryFilterLoader from "components/skeletonLoaders/CategoryFilterLoader";
import ProductSectionLoader from "components/skeletonLoaders/ProductSectionLoader";
import { getActiveBusiness } from "redux/header/headerSlice";
import CatalogLoader from "components/skeletonLoaders/CatalogLoader";

// const AllCategoryProducts = React.lazy(() =>import ('./CatalogMui/AllCategoryProducts'))
// const CatalogHeader = React.lazy(() =>import ('./CatalogMui/CatalogHeader'))
// const CategoryGroup = React.lazy(() =>import ('./CatalogMui/CategoryGroup'))
// const CheckBoxLabel = React.lazy(() =>import ('./CatalogMui/CheckBoxLabel'))
// const MainCategory = React.lazy(() =>import ('./CatalogMui/MainCategory'))
// const ProductFilterMobile = React.lazy(() =>import ('./CatalogMui/ProductFilterMobile'))
// const CartDetailsBottonCard = React.lazy(() =>import ('../components/CartDetailsBottonCard'))
// const FilteredCategory = React.lazy(() =>import ('./CatalogMui/FilteredCategory'))
// const FilteredSubCategory = React.lazy(() =>import ('./CatalogMui/FilteredSubCategory'))

function CatalogMui(props) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const { businessName } = useParams();
  const [searchParams] = useSearchParams();
  const atag = searchParams?.get("atag");

  const businessIdData = sessionStorage.getItem("businessId");

  const [viewByFilter, setViewByFilter] = useState("category");
  const [showFilters, setShowFilters] = useState(false);
  const dispatch = useDispatch();

  const [sort, SetSort] = useState("Newest First");
  const [discountHightToLow, setDiscountHighTOLow] = useState(false);
  const [lowPrice, setLowPrice] = useState(false);
  const [highPrice, setHighPrice] = useState(false);
  const [business, setBusiness] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoggedIn, setIsLoggedIn] = useState(
    getUserName().length === 0 ? false : true
  );

  const [totalQty, setTotalQty] = useState(0);

  const { productsByCategory, allProducts, category, loader } = useSelector(
    (state) => state.subCategory
  );
  const { cartDetails } = useSelector((state) => state.cart);
  const {
    updateQty,
    addedProduct,
    localCartData,
    createCartRes,
    updateCartIntRes,
  } = useSelector((state) => state.cartupdateadd);

  const { profile, loginDetails } = useSelector((state) => state.auth);

  const { searchData, searchQuery } = useSelector((state) => state.search);
  const searchElementRef = useRef(null);

  const [cartProductList, setCartProductList] = useState(null);

  // const businessId = sessionStorage.getItem('businessId')
  const { businessId } = useSelector((state) => state.rootSlice);
  const { commercialDetails } = useSelector((state) => state.product);
  const { businessDetails } = useSelector((state) => state.header);

  const [variant, setVariant] = useState(false);
  const [filterProducts, setFilterProducts] = useState([]);
  const [filteredSubCat, setFilteredSubCat] = useState([]);

  const [pageLoader, setPageLoader] = useState(true);

  const location = useLocation();
  const [categorySideBar, setCategorySideBar] = useState(
    location?.state?.categorySideBar ? location?.state?.categorySideBar : null
  );
  // let categorySideBar = location?.state?.categorySideBar
  //   ? location?.state?.categorySideBar
  //   : null

  const [unicommerceLoader, setUnicommerceLoader] = useState(false);
  const [unicomInventoryInfo, setUnicomInventoryInfo] = useState(null);

  useEffect(() => {
    console.log("varantArray", allProducts);

    if (allProducts?.productList?.length > 0) {
      const varantArray = allProducts.productList.filter(
        (product) => product?.variantInfo?.masterVariant
      );
      console.log("varantArray", varantArray, allProducts);

      if (varantArray?.length > 0) setVariant(true);
    }
  }, [allProducts]);

  useEffect(() => {
    setCategorySideBar(
      location?.state?.categorySideBar ? location?.state?.categorySideBar : null
    );
  }, [location]);

  const getBusinessData = () => {
    const localBusinessName = sessionStorage.getItem("businessName");
    if (businessName !== localBusinessName) {
      console.log("BUSINESS_NAME_CHANGED");
      removeSessionInfo();
      // window.location.replace(`/${businessName}/catalog`)
      sessionStorage.setItem("cartData", "null");
    }

    dispatch(getBusinessIdDetails(businessName));
    sessionStorage.setItem("businessName", businessName);
  };

  const getProducts = () => {
    dispatch(
      getCatalogAllProducts({
        pageNo: pageNumber,
        discountHightToLow: discountHightToLow,
        priceLowoHig: lowPrice,
        highPrice: highPrice,
        business: business,
      })
    );
  };

  useEffect(() => {
    getBusinessData();
  }, [businessName]);

  useEffect(() => {
    // if (businessIdData !== null) {
    if (businessId !== null) {
      if (!businessDetails) {
        dispatch(getActiveBusiness());
      }
      dispatch(getDataForSideBar());
      dispatch(getCategoryDataFinder());
      if (!commercialDetails) {
        dispatch(getCommercialDetails());
      }
      if (isLoggedIn) {
        if (!cartDetails) {
          dispatch(getCartDetails(businessId));
        }
      }
      getProducts();
    }

    return () => {
      // dispatch(clearSearchData())
    };
  }, [businessId]);

  useEffect(() => {
    if (loginDetails) {
      dispatch(getCartDetails(businessId));
      setIsLoggedIn(true);
    }
  }, [loginDetails]);

  const getCartItemCount = () => {
    let count = 0;
    const cartProductData = JSON.parse(sessionStorage.getItem("cartData"));
    cartProductData?.productList?.forEach((item) => {
      count = count + item.currentQuantity;
    });
    setTotalQty(count);
    setCartProductList(cartProductData);
  };

  useEffect(() => {
    getCartItemCount();
  }, [cartDetails, updateQty, addedProduct, createCartRes]);

  const handleProductCatClick = (subCat) => {
    let filteredArray = allProducts?.productList?.filter((item, index) => {
      return item?.productSubCategory?.[0]?.code == subCat?.code;
    });

    setFilterProducts({ products: filteredArray, title: subCat?.value });

    setFilteredSubCat([]);
    setViewByFilter("filtered-products");
    scrollToTop();
  };

  const handleSubCatClick = (catCode) => {
    const isPresent = filteredSubCat.filter((item, index) => {
      return item.code === catCode.code;
    });
    if (isPresent.length > 0) {
      if (filteredSubCat.length === 1) {
        setFilterProducts({ products: [], title: "" });
        setFilteredSubCat([]);
        setViewByFilter("category");
        scrollToTop();
        return;
      }

      const newList = filteredSubCat.filter((item, index) => {
        return item.code !== catCode.code;
      });
      setFilteredSubCat(newList);
    } else {
      setFilteredSubCat([...filteredSubCat, catCode]);
    }

    setFilterProducts({ products: [], title: "" });
    setViewByFilter("filtered-subcat");
    scrollToTop();
  };

  const onRemoveSubCatFilter = (subcat) => {
    const filteredList = filteredSubCat.filter((item) => {
      return item.code !== subcat.code;
    });

    if (filteredSubCat.length === 1) {
      setFilterProducts({ products: [], title: "" });
      setFilteredSubCat([]);
      setViewByFilter("category");
      scrollToTop();

      return;
    }
    setFilteredSubCat(filteredList);
  };

  const onSideBarCatSelected = (subCat) => {
    console.log("CATALOG_MUI__:");
    let filteredArray = allProducts?.productList?.filter((item, index) => {
      return item?.productSubCategory?.[0]?.code == subCat?.subcatcode;
    });

    console.log("CATALOG_MUI__:", filteredArray);

    setFilterProducts({ products: filteredArray, title: subCat?.subCatValue });

    setFilteredSubCat([]);
    setViewByFilter("filtered-products");
    scrollToTop();
    setPageLoader(false);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  };

  const addtoLocalCart = (item) => {
    dispatch(addLocalCart(item));
  };

  const updateLocalCart = (item) => {
    dispatch(updateToLocalCart(item));
  };

  const handleViewByCategoryClick = () => {
    if (category?.length === 1 && category?.[0]?.subCat?.length === 1) {
      handleProductCatClick(category?.[0]?.subCat?.[0]);
      return;
    }

    setFilterProducts({ products: [], title: "" });
    setFilteredSubCat([]);
    setViewByFilter("category");
    scrollToTop();
  };

  const handleViewAllProducts = () => {
    setFilterProducts({ products: [], title: "" });
    setFilteredSubCat([]);
    setViewByFilter("all-products");
    scrollToTop();
  };

  useEffect(() => {
    if (localCartData) {
      setCartProductList(localCartData);
      setTotalQty(localCartData?.totalQuantity);
    }
  }, [localCartData]);

  useEffect(() => {
    if (categorySideBar && allProducts) {
      onSideBarCatSelected(categorySideBar);
    }
    return () => {
      setCategorySideBar(null);
    };
  }, [categorySideBar, allProducts]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getCartDetails(businessId)).then((carRes) => {
        console.log("SESSION_CART_DATA_Cat:", carRes?.payload?.detail);
        sessionStorage.setItem(
          "cartData",
          JSON.stringify(carRes?.payload?.detail || null)
        );
        setCartProductList(carRes?.payload?.detail);
      });
    }
  }, [addedProduct, createCartRes, updateCartIntRes]);

  const onAddProductToCart = (data) => {
    if (isLoggedIn) {
      addProductLoading(data?.product?.productId);
      const cartData = JSON.parse(sessionStorage.getItem("cartData"));
      if (cartData) {
        dispatch(addNewProductCart(data));
      } else {
        const postData = createCartLoginHelper(data);
        dispatch(createCart(postData));
      }
    } else {
      addtoLocalCart(data?.product);
    }
  };

  const removeProductFromCart = (data) => {
    if (isLoggedIn) {
      addProductLoading(data?.productId);
      const postData = removeCartProductLoginHelper(data.productId);
      dispatch(updateCartDetails({ ...postData, id: cartDetails?.cartId }));
    } else {
      dispatch(removeProductLocalCart({ productId: data.productId }));
    }
  };

  const addProductLoading = (productId) => {
    let isPresent = false;
    cartProductList?.productList?.forEach((item) => {
      if (item.productId == productId) {
        item.loading = true;
        isPresent = true;
      }
    });

    if (!isPresent) {
      cartProductList?.productList?.push({
        loading: true,
        productId: productId,
      });
    }
  };

  const handleAllProductSort = (optionData) => {
    if (optionData === "Discount - High To Low") {
      setDiscountHighTOLow(true);
      SetSort(optionData);
      // getProducts()
      setCategorySideBar(null);
      dispatch(
        getCatalogAllProducts({
          pageNo: pageNumber,
          discountHightToLow: true,
          priceLowoHig: false,
          highPrice: false,
          business: false,
        })
      );
    }
    if (optionData === "Price - Low To High") {
      SetSort(optionData);
      setLowPrice(true);
      setCategorySideBar(null);
      dispatch(
        getCatalogAllProducts({
          pageNo: pageNumber,
          discountHightToLow: false,
          priceLowoHig: true,
          highPrice: false,
          business: false,
        })
      );
    }
    if (optionData === "Price - High To Low") {
      SetSort(optionData);
      setHighPrice(true);
      setCategorySideBar(null);
      dispatch(
        getCatalogAllProducts({
          pageNo: pageNumber,
          discountHightToLow: false,
          priceLowoHig: false,
          highPrice: true,
          business: false,
        })
      );
    }
    if (optionData === "Newest First") {
      SetSort(optionData);
      setBusiness(false);
      setCategorySideBar(null);
      dispatch(
        getCatalogAllProducts({
          pageNo: pageNumber,
          discountHightToLow: false,
          priceLowoHig: false,
          highPrice: false,
          business: false,
        })
      );
    }
  };

  useEffect(() => {
    if (searchData) {
      setViewByFilter("search");
      scrollToTop();
      setPageLoader(false);
      // window.scrollTo(0, searchElementRef.current.offsetTop)
    }
  }, [searchData]);

  const [scrolled, setScrolled] = React.useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  // If business as only one product category then show all products
  useEffect(() => {
    if (
      category?.length > 0 &&
      allProducts?.productList?.length > 0 &&
      viewByFilter === "category"
    ) {
      if (category?.length === 1 && category?.[0]?.subCat?.length === 1) {
        // setViewByFilter('all-products')
        handleProductCatClick(category?.[0]?.subCat?.[0]);
        setPageLoader(false);
      } else {
        setPageLoader(false);
      }
    }
    setPageLoader(false);
    return () => {};
  }, [category, allProducts]);

  // Unicommerce Validation
  const getUnicommerceInventory = async () => {
    setUnicommerceLoader(true);
    const catalogPartnerInfo = commercialDetails?.info?.catalogPartnerInfo?.[0];

    if (catalogPartnerInfo?.status == "active") {
      console.log("FETCH__Catalog_Inventory:", {
        catalogPartnerInfo,
        allProducts,
      });
      const unicomProductList = allProducts?.productList || [];

      const skuList = unicomProductList?.map(
        (item) => item?.catalogPartnerItemInfo?.[0]?.skuCode || ""
      );
      // const skuList = ['1000100011100001', '1000100011100002', '1000100011100003', '1000100011100004', '1000100011100005', '1000100011100006']
      const facilityCode = catalogPartnerInfo?.warehouse?.facilityCode;

      const inventoryRes = await getUnicomInventoryApi({
        facilityCode,
        skuList,
      });

      if (inventoryRes) {
        console.log("FETCH__Catalog_Inventory__DONE:", inventoryRes);
        setUnicomInventoryInfo(inventoryRes?.inventorySnapshots || []);
      }
    }

    setUnicommerceLoader(false);
  };

  useEffect(() => {
    if (commercialDetails && allProducts?.productList?.length > 0) {
      getUnicommerceInventory();
    }
    return () => {};
  }, [commercialDetails, allProducts]);

  // useEffect(() => {
  //   if (!businessDetails) {
  //     console.log("BUSINESS_DETAILS__HIT");
      
  //     dispatch(getActiveBusiness());
  //   }
  // }, []);

  // To Clear search details and show products by category
  // useEffect(() => {
  //   if(!searchData && !searchQuery){
  //     console.log("CLEAR__SEARCH", {searchData, searchQuery});
  //     handleViewByCategoryClick()
  //   }

  //   return () => {}
  // }, [searchData, searchQuery])

  return (
    <>
      {/* businessIdData : {JSON.stringify(businessIdData !== null)} <br />
      commerce : {JSON.stringify(commercialDetails !== null)} <br />
      allProducts : {JSON.stringify(allProducts !== null)} <br />
      productsByCategory : {JSON.stringify(productsByCategory?.length > 0)}{' '}
      <br />
      category.length : {JSON.stringify(category?.length > 0)} <br />
      !pageLoader : {JSON.stringify(!pageLoader)} <br />
      {businessIdData !== null &&
      commercialDetails !== null &&
      allProducts !== null &&
      productsByCategory?.length > 0 &&
      category?.length > 0 &&
      !pageLoader ? (
        <b>DONE</b>
      ) : (
        <b>Loading...</b>
      )} */}
      {businessIdData !== null &&
      commercialDetails !== null &&
      allProducts !== null &&
      businessDetails ? (
        <>
          <Box
            sx={{
              position: "sticky",
              top: { md: 0, xs: 80 },
              width: "100%",
              zIndex: 100,
              backgroundColor: { xs: whiteColor, md: backgroundColor01 },
            }}
          >
            {matches ? (
              <TopComponent value={"catalog"} bannerHide disableBanners />
            ) : (
              <TopComponent value={"catalog"} bannerHide />
            )}

            <Container
              sx={{
                maxWidth: "1360px !important",
                p: { xs: "0px", md: "20px 15px 0px 15px !important" },
              }}
            >
              <BreadcrumbNew
                links={[
                  // { label: 'Home', to: `/` },
                  { label: "Catalog", to: `/catalog` },
                ]}
                itemCount={totalQty}
                totalPrice={
                  cartProductList ? cartProductList.orderTotalProductCost : 0
                }
              />

              <CatalogHeader
                onFilterClick={() => {
                  setShowFilters(true);
                }}
                itemCount={totalQty}
                totalPrice={
                  cartProductList ? cartProductList.orderTotalProductCost : 0
                }
                category={() => {
                  if (
                    category?.length === 1 &&
                    category?.[0]?.subCat?.length === 1
                  ) {
                    handleProductCatClick(category?.[0]?.subCat?.[0]);
                    return;
                  }

                  setFilterProducts({ products: [], title: "" });
                  setFilteredSubCat([]);
                  setViewByFilter("category");
                  scrollToTop();
                }}
                products={() => {
                  setFilterProducts({ products: [], title: "" });
                  setFilteredSubCat([]);
                  setViewByFilter("all-products");
                  scrollToTop();
                }}
                viewByFilter={viewByFilter}
                handleViewByCategoryClick={handleViewByCategoryClick}
                handleViewAllProducts={handleViewAllProducts}
                // All Products Filter props
                displaySelection={sort}
                totalProduct={allProducts?.totalProduct}
                onSort={handleAllProductSort}
              />
            </Container>
          </Box>
        </>
      ) : (
        <CatalogLoader />
      )}

      {businessIdData !== null && commercialDetails !== null ? (
        // allProducts !== null &&
        // productsByCategory?.length > 0 &&
        // !unicommerceLoader &&
        // // category?.length > 0 &&
        // !pageLoader
        <>
          <Box bgcolor={{ xs: whiteColor, md: backgroundColor01 }}>
            <Container
              sx={{
                maxWidth: "1350px !important",
                p: { xs: "0px", md: "0 10px 0 15px !important" },
              }}
            >
              {/* ---------Empty Catalog card--------- */}
              <Box>
                <Grid container spacing={3.5}>
                  <Grid
                    item
                    xs={0}
                    md={2.8}
                    display={{ xs: "none", md: "block" }}
                  >
                    <Dialog
                      fullScreen
                      display={{ md: "none", xs: "block" }}
                      open={showFilters}
                      scroll="paper"
                      onClose={() => {
                        setShowFilters(false);
                      }}
                    >
                      <ProductFilterMobile
                        onCloseClick={() => {
                          setShowFilters(false);
                        }}
                        onFilterClick={(value) => {
                          if (value.type === "all-products") {
                            setViewByFilter("all-products");
                            scrollToTop();
                          }
                          if (value.type === "filtered-subcat") {
                            if (value.filteredSubCat?.length > 0) {
                              setFilteredSubCat(value.filteredSubCat);
                              setViewByFilter("filtered-subcat");
                              scrollToTop();
                            } else {
                              let filteredArray =
                                allProducts?.productList?.filter(
                                  (item, index) => {
                                    return (
                                      item?.productSubCategory?.[0]?.code ==
                                      value?.filteredProduct?.code
                                    );
                                  }
                                );

                              setFilterProducts({
                                products: filteredArray,
                                title: value?.filteredProduct?.value,
                              });

                              setFilteredSubCat([]);
                              setViewByFilter("filtered-products");
                              scrollToTop();
                            }
                          }
                          if (value.type === "filtered-products") {
                            let filteredArray =
                              allProducts?.productList?.filter(
                                (product, index) => {
                                  return (
                                    product?.productSubCategory?.[0]?.pcCode ==
                                    value?.filteredProduct?.code
                                  );
                                }
                              );
                            setFilterProducts({
                              products: filteredArray,
                              title: value?.filteredProduct?.value,
                            });

                            setFilteredSubCat([]);
                            setViewByFilter("filtered-products");
                            scrollToTop();
                          }
                          if (value.type === "") {
                            setViewByFilter("category");
                            scrollToTop();
                          }
                          setShowFilters(false);
                        }}
                        category={category}
                      />
                    </Dialog>

                    {category?.length > 0 ? (
                      <>
                        <CategoryFilterSideBar
                          handleAllProductClick={() => {
                            setFilterProducts({ products: [], title: "" });
                            setFilteredSubCat([]);
                            setViewByFilter("all-products");
                            scrollToTop();
                          }}
                          category={category}
                          filterProducts={filterProducts}
                          filteredSubCat={filteredSubCat}
                          handleRootCategoryClick={(item) => {
                            let filteredArray =
                              allProducts?.productList?.filter(
                                (product, index) => {
                                  return (
                                    product?.productSubCategory?.[0]?.pcCode ===
                                    item?.code
                                  );
                                }
                              );

                            setFilterProducts({
                              products: filteredArray,
                              title: item?.value,
                            });

                            setFilteredSubCat([]);
                            setViewByFilter("filtered-products");
                            scrollToTop();
                          }}
                          handleProductCatClick={handleProductCatClick}
                          handleSubCatClick={handleSubCatClick}
                        />
                      </>
                    ) : (
                      <CategoryFilterLoader />
                    )}
                  </Grid>

                  {allProducts !== null &&
                  productsByCategory?.length > 0 &&
                  !unicommerceLoader &&
                  // category?.length > 0 &&
                  !pageLoader ? (
                    <Grid item xs={12} md={9.2} mb={{ xs: 15, md: 3 }}>
                      {viewByFilter === "search" ? (
                        <Box component="div" ref={searchElementRef}>
                          <SearchResult
                            searchProducts={searchData?.details}
                            cartData={cartProductList?.productList}
                            cartId={cartProductList?.cartId}
                            allProducts={allProducts?.productList}
                            onQtyUpdate={(data) => {
                              if (isLoggedIn) {
                                addProductLoading(data?.productId);
                                dispatch(updateCartProductQuantity(data));
                              } else {
                                updateLocalCart(data);
                              }
                            }}
                            onAddProduct={(data) => {
                              // addProductLoading(data?.product?.productId)
                              onAddProductToCart(data);
                            }}
                            onRemoveProduct={(data) => {
                              // addProductLoading(data?.productId)
                              removeProductFromCart(data);
                            }}
                            clearSearch={() => {
                              // dispatch(clearSearchData());
                              handleViewByCategoryClick();
                            }}
                          />
                        </Box>
                      ) : null}

                      {viewByFilter === "filtered-products" ? (
                        <FilteredCategory
                          selectedCategory={filterProducts}
                          isUnicommerce={
                            commercialDetails?.info?.catalogPartnerInfo?.[0]
                              ?.status == "active"
                          }
                          checkUnicomInventory={(item) =>
                            checkUnicomInventory(unicomInventoryInfo, item)
                          }
                          cartData={cartProductList?.productList}
                          cartId={cartProductList?.cartId}
                          onQtyUpdate={(data) => {
                            if (isLoggedIn) {
                              addProductLoading(data?.productId);
                              dispatch(updateCartProductQuantity(data));
                            } else {
                              updateLocalCart(data);
                            }
                          }}
                          onAddProduct={(data) => {
                            // addProductLoading(data?.product?.productId)
                            onAddProductToCart(data);
                          }}
                          onRemoveProduct={(data) => {
                            // addProductLoading(data?.productId)
                            removeProductFromCart(data);
                          }}
                        />
                      ) : null}

                      {viewByFilter === "filtered-subcat" ? (
                        <FilteredSubCategory
                          allProducts={allProducts}
                          isUnicommerce={
                            commercialDetails?.info?.catalogPartnerInfo?.[0]
                              ?.status == "active"
                          }
                          checkUnicomInventory={(item) =>
                            checkUnicomInventory(unicomInventoryInfo, item)
                          }
                          filteredSubCat={filteredSubCat}
                          onRemoveSubCatFilter={onRemoveSubCatFilter}
                          cartData={cartProductList?.productList}
                          cartId={cartProductList?.cartId}
                          onQtyUpdate={(data) => {
                            if (isLoggedIn) {
                              addProductLoading(data?.productId);
                              dispatch(updateCartProductQuantity(data));
                            } else {
                              updateLocalCart(data);
                            }
                          }}
                          onAddProduct={(data) => {
                            // addProductLoading(data?.product?.productId)
                            onAddProductToCart(data);
                          }}
                          onRemoveProduct={(data) => {
                            // addProductLoading(data?.productId)
                            removeProductFromCart(data);
                          }}
                        />
                      ) : null}

                      {viewByFilter === "category" ? (
                        <Box>
                          {/* {loader && <ProductSectionLoader />} */}
                          {/* <ProductSectionLoader /> */}

                          {loader === false &&
                          productsByCategory &&
                          productsByCategory.length > 0 ? (
                            <Box>
                              {productsByCategory.map((item, index) => {
                                return (
                                  <CategoryGroup
                                    key={`view_by_cat_group_${index}`}
                                    isUnicommerce={
                                      commercialDetails?.info
                                        ?.catalogPartnerInfo?.[0]?.status ==
                                      "active"
                                    }
                                    checkUnicomInventory={(item) =>
                                      checkUnicomInventory(
                                        unicomInventoryInfo,
                                        item
                                      )
                                    }
                                    index={index + 1}
                                    title={item.subCatValue}
                                    subCat={item}
                                    products={item.products}
                                    // count={
                                    //   item.products.filter(
                                    //     (product) =>
                                    //       !product?.variantInfo?.masterVariant
                                    //   ).length
                                    // }
                                    count={item.count}
                                    variant={
                                      item?.products?.filter(
                                        (product) =>
                                          product?.variantInfo?.masterVariant
                                      )?.length > 0
                                    }
                                    onViewMore={(catCode) => {
                                      handleProductCatClick({
                                        code: catCode,
                                        value: item?.subCatValue,
                                      });
                                    }}
                                    cartData={cartProductList?.productList}
                                    cartId={cartProductList?.cartId}
                                    onQtyUpdate={(data) => {
                                      if (isLoggedIn) {
                                        addProductLoading(data?.productId);
                                        dispatch(
                                          updateCartProductQuantity(data)
                                        );
                                      } else {
                                        updateLocalCart(data);
                                      }
                                    }}
                                    onAddProduct={(data) => {
                                      // addProductLoading(data?.product?.productId)
                                      onAddProductToCart(data);
                                    }}
                                    onRemoveProduct={(data) => {
                                      // addProductLoading(data?.productId)
                                      removeProductFromCart(data);
                                    }}
                                    // showInGrid={
                                    //   category?.length === 1 &&
                                    //   category?.[0]?.subCat?.length === 1
                                    // }
                                  />
                                );
                              })}
                            </Box>
                          ) : (
                            <ProductSectionLoader />
                          )}
                        </Box>
                      ) : null}

                      {viewByFilter === "all-products" ? (
                        <Box>
                          {/* {loader && <ProductSectionLoader />} */}
                          {loader === false &&
                          allProducts &&
                          allProducts.productList &&
                          allProducts.productList.length > 0 ? (
                            <AllCategoryProducts
                              producList={allProducts.productList}
                              isUnicommerce={
                                commercialDetails?.info?.catalogPartnerInfo?.[0]
                                  ?.status == "active"
                              }
                              checkUnicomInventory={(item) =>
                                checkUnicomInventory(unicomInventoryInfo, item)
                              }
                              // totalCount={
                              //  allProducts.productList.filter(
                              //           (product) =>
                              //             !product?.variantInfo?.masterVariant
                              //         ).length
                              // }
                              totalCount={allProducts.totalProduct}
                              pageNo={allProducts.pageNo}
                              onClickNextPage={(page) => {
                                setPageNumber(page);
                                dispatch(
                                  getCatalogAllProducts({
                                    pageNo: page,
                                    discountHightToLow: discountHightToLow,
                                    priceLowoHig: lowPrice,
                                    highPrice: highPrice,
                                    business: business,
                                  })
                                );
                              }}
                              cartData={cartProductList}
                              onQtyUpdate={(data) => {
                                if (isLoggedIn) {
                                  addProductLoading(data?.productId);
                                  dispatch(updateCartProductQuantity(data));
                                } else {
                                  updateLocalCart(data);
                                }
                              }}
                              onAddProduct={(data) => {
                                // addProductLoading(data?.product?.productId)
                                onAddProductToCart(data);
                              }}
                              onRemoveProduct={(data) => {
                                // addProductLoading(data?.productId)
                                removeProductFromCart(data);
                              }}
                              // onClickSort={(optionData) => {
                              //   if (optionData === 'Discount - High To Low') {
                              //     setDiscountHighTOLow(true)
                              //     SetSort(optionData)
                              //     // getProducts()
                              //     setCategorySideBar(null)
                              //     dispatch(
                              //       getCatalogAllProducts({
                              //         pageNo: pageNumber,
                              //         discountHightToLow: true,
                              //         priceLowoHig: false,
                              //         highPrice: false,
                              //         business: false,
                              //       })
                              //     )
                              //   }
                              //   if (optionData === 'Price - Low To High') {
                              //     SetSort(optionData)
                              //     setLowPrice(true)
                              //     setCategorySideBar(null)
                              //     dispatch(
                              //       getCatalogAllProducts({
                              //         pageNo: pageNumber,
                              //         discountHightToLow: false,
                              //         priceLowoHig: true,
                              //         highPrice: false,
                              //         business: false,
                              //       })
                              //     )
                              //   }
                              //   if (optionData === 'Price - High To Low') {
                              //     SetSort(optionData)
                              //     setHighPrice(true)
                              //     setCategorySideBar(null)
                              //     dispatch(
                              //       getCatalogAllProducts({
                              //         pageNo: pageNumber,
                              //         discountHightToLow: false,
                              //         priceLowoHig: false,
                              //         highPrice: true,
                              //         business: false,
                              //       })
                              //     )
                              //   }
                              //   if (optionData === 'Newest First') {
                              //     SetSort(optionData)
                              //     setBusiness(false)
                              //     setCategorySideBar(null)
                              //     dispatch(
                              //       getCatalogAllProducts({
                              //         pageNo: pageNumber,
                              //         discountHightToLow: false,
                              //         priceLowoHig: false,
                              //         highPrice: false,
                              //         business: false,
                              //       })
                              //     )
                              //   }
                              // }}
                              optionData={sort}
                            />
                          ) : (
                            <ProductSectionLoader />
                          )}
                        </Box>
                      ) : null}
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={9.2} mb={{ xs: 15, md: 3 }}>
                      <ProductSectionLoader />
                    </Grid>
                  )}
                </Grid>
              </Box>

              {totalQty > 0 ? (
                <CartDetailsBottonCard
                  amount={
                    cartProductList ? cartProductList.orderTotalProductCost : 0
                  }
                  cartProductList={cartProductList}
                  itemCount={totalQty}
                  gapdown={6}
                />
              ) : null}
            </Container>

            {loader === false &&
              productsByCategory &&
              productsByCategory.length > 0 && (
                <>
                  {matches && <FooterMui />}
                  <OfferPopupModal couponCode={atag} />
                </>
              )}
          </Box>
        </>
      ) : null}
    </>
  );
}

export default CatalogMui;

const styles = {
  optionBtn: {
    fontSize: "13px",
    fontWeight: 500,
    color: "primary",
    p: "3px 15px",
    textTransform: "none",
    borderRadius: "5px",
    border: "1px solid " + orangeColor01,
    "&:hover": {
      backgroundColor: orangeColor01,
      color: "white",
    },
  },
};
