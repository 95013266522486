import { Box, Grid, Typography } from "@mui/material";
import {
  blackColor,
  borderLineColor,
  couponColor,
  headingColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import { Info } from "@mui/icons-material";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";

function SpecialNotes({ note }) {
  return (
    <Box
      sx={{
        bgcolor: whiteColor,
        border: `1px solid ${borderLineColor}`,
        borderRadius: 2.2,
        p: "12px",
        m: "0px 0 24px",
      }}
    >
      <Typography
        variant="h5"
        component="h3"
        color={{ md: headingColor, xs: blackColor }}
        fontSize={{ xs: 14, md: 16 }}
        textAlign={{ md: "left", xs: "left" }}
        display={{ xs: "block", md: "block" }}
      >
        Special Instructions
      </Typography>
      <Typography
        fontSize={{ xs: 13, md: 13 }}
        fontWeight={400}
        color={blackColor}
        mt={1.5}
      >
        {note}
      </Typography>
      {/* <Box
          display={"flex"}
          borderBottom={"1px solid " + borderLineColor}
          pb={1}
        >
          <TipsAndUpdatesIcon
            sx={{
              fontSize: { xs: "22px", md: "20px" },
              color: blackColor,
              mr: 1,
            }}
          />
          <Typography
            variant="h5"
            component="h3"
            fontSize={{ xs: 14, md: 15 }}
            color={{ md: headingColor, xs: blackColor }}
            textAlign={{ md: "right", xs: "left" }}
            mb={1}
          >
            Special Instructions
          </Typography>
        </Box> */}

      {/* <Grid container spacing={1} py={1.8} alignItems="center">
          <Grid item>
            <Info />
          </Grid>

          <Grid item>
            <Typography
              variant="subtitle1"
              component="p"
              fontSize={{ xs: 14, md: 15 }}
              color={couponColor}
              sx={{ textTransform: "capitalize" }}
            >
              {note}
            </Typography>
          </Grid>
        </Grid> */}
    </Box>
  );
}

export default SpecialNotes;
