import {
  Box,
  Button,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import ModalCard from "components/ModalCard";
import { blackColor, greyShade41, whiteColor } from "configs/styles/muiThemes";
import React from "react";

function DescriptionModal({ open, data, handleClose, handleConfirm }) {
  return (
    <ModalCard handleClose={handleClose} open={open} width="30%">
      <Stack
        sx={{
          backgroundColor: whiteColor,
          borderRadius: "15px",
          overflow: "hidden",
        }}
      >
        <Box sx={{ backgroundColor: greyShade41, p: "10px 15px" }}>
          <Typography
            fontSize={17}
            fontWeight={600}
            color={blackColor}
            lineHeight="28px"
            textAlign={"center"}
          >
            Description
          </Typography>
        </Box>
        <Stack
          gap="5px"
          sx={{ p: "15px", maxHeight: "50vh", overflowY: "auto" }}
        >
          {data?.split("\n")?.map((item, i) => {
            return item.length > 0 ? (
              item?.charAt(0) === "*" ? (
                <ListItem sx={{ py: 0, ml: 3 }}>
                  <ListItemText
                    sx={{
                      display: "list-item",
                    }}
                    key={i}
                    primary={
                      <Typography
                        variant="body1"
                        fontSize={{ xs: 12.5, md: 13 }}
                        color={blackColor}
                        pt={{ xs: 1, md: 2 }}
                        lineHeight="21px"
                        sx={{ textAlign: "justify" }}
                      >
                        {item?.substring(1)}
                      </Typography>
                    }
                  />
                </ListItem>
              ) : (
                <Typography
                  variant="body1"
                  fontSize={{ xs: 12.5, md: 13 }}
                  color={blackColor}
                  lineHeight="21px"
                  pt={{ xs: 1, md: 2 }}
                  sx={{ textAlign: "justify" }}
                >
                  {item}
                </Typography>
              )
            ) : null;
          })}
        </Stack>
        <Box
          sx={{
            backgroundColor: greyShade41,
            p: "10px 15px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleConfirm}
            sx={{
              p: "5px 20px",
              backgroundColor: blackColor,
              color: whiteColor,
              fontSize: 12,
              fontWeight: 600,
              height: "auto",
              borderRadius: "5px",
              border: "1px solid" + blackColor,
              "&:hover": {
                color: blackColor,
                backgroundColor: whiteColor,
              },
            }}
          >
            Ok
          </Button>
        </Box>
      </Stack>
    </ModalCard>
  );
}

export default DescriptionModal;
