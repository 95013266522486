import { Box, Container, Skeleton, Stack } from "@mui/material";
import { backgroundColor01, whiteColor } from "configs/styles/muiThemes";
import React from "react";

const BannerLoader = () => {
  return (
    <Box bgcolor={backgroundColor01}>
      <Box sx={styles.bannerBox}>
        <Skeleton variant="rounded" width="460px" height="402px" sx={{borderRadius:'15px'}} />
        <Skeleton variant="rounded" width="490px" height="451px" sx={{borderRadius:'15px'}} />
        <Skeleton variant="rounded" width="460px" height="402px" sx={{borderRadius:'15px'}} />

        <Box sx={styles.businessInfoCard}>
           <Skeleton variant="text" width="88px" sx={{ fontSize: "2rem", mb:'5px !important' }} />
           <Skeleton variant="rounded" width="249px" height="23px" sx={{mb:'8px !important'}} />
           <Skeleton variant="text" width="152px" />
        </Box>
      </Box>

      <Container sx={{ maxWidth: "1350px !important", px: "15px !important", mt:5}}>
        <Stack sx={{ alignItems:'center', bgcolor: whiteColor, borderRadius:'15px', p:'18px' }}>
            <Skeleton variant="text" width="150px" />

            <Box display='flex' alignItems='center' gap='60px' mt={2.3}>
              <Skeleton variant="rounded" width="65px" height="65px" sx={{borderRadius:'50%'}} />
              <Skeleton variant="rounded" width="65px" height="65px" sx={{borderRadius:'50%'}} />
              <Skeleton variant="rounded" width="65px" height="65px" sx={{borderRadius:'50%'}} />
              <Skeleton variant="rounded" width="65px" height="65px" sx={{borderRadius:'50%'}} />
              <Skeleton variant="rounded" width="65px" height="65px" sx={{borderRadius:'50%'}} />
              <Skeleton variant="rounded" width="65px" height="65px" sx={{borderRadius:'50%'}} />
            </Box>

            <Skeleton variant="rounded" width="250px" height='14px' sx={{mt:'22px !important'}} />
        </Stack>
      </Container>
    </Box>
  );
};

export default BannerLoader;

const styles = {
    bannerBox:{
        display:'flex', 
        alignItems:'center', 
        justifyContent:'space-between',
        p:"24px 4px 24px 4px",
        position: 'relative'
    },

    businessInfoCard: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "50%",
        bgcolor: whiteColor,
        p: "15px",
        borderRadius: "15px",
        position: "absolute",
        left: "calc(50% - 25vw)",
        bottom: "-10px",
        m: "0px auto",
        zIndex: 1,
        textAlign: "center",
        boxShadow: "0 1px 8px rgba(87, 87, 101, 0.07)",
      },
}
