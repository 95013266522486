import {
  Button,
  Container,
  Grid,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import BreadcrumbsSection from "../../components/Breadcrumbs";
import { automobileProducts } from "../../utils/demoData";
import ProductCard from "../CatalogMui/ProductCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  blackColor,
  headingColor,
  searchLabelColor,
} from "../../configs/styles/muiThemes";

function SearchComponent() {
  return (
    <Container sx={{ maxWidth: "1300px !important", mt: 3.5 }}>
      <BreadcrumbsSection />

      <Typography
        variant="h6"
        color={searchLabelColor}
        fontWeight={500}
        fontSize={16}
        display="inline"
        pl={1}
      >
        Showing 87 results for{" "}
        <Typography
          variant="h6"
          color={headingColor}
          fontSize={16}
          display="inline"
        >
          “Michel”
        </Typography>
      </Typography>
      <Grid container columnSpacing={1} rowSpacing={2.5} pt={3.5} pl={1}>
        {automobileProducts.map((item, index) => {
          return (
            <Grid item xs={6} sm={4} md={2.4}>
              <ProductCard
                img={item.img}
                price={item.price}
                oldPrice={item.mrp}
                description={item.description}
                discount={item.discount}
                isOutOfStock={item.isOutOfStock}
                isComingSoon={item.isComingSoon}
                disableAddtoCart
              />
            </Grid>
          );
        })}
      </Grid>

      <Stack
        spacing={2}
        my={4}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Pagination
          count={10}
          renderItem={(item) => (
            <PaginationItem
              components={{
                next: NextBtn,
                previous: PreviousBtn,
              }}
              {...item}
            />
          )}
          sx={{color:blackColor}}
        />
      </Stack>
    </Container>
  );
}

export default SearchComponent;

const PreviousBtn = () => {
  return (
    <Button startIcon={<ArrowBackIcon />} sx={styles.btnHover}>
      Prev
    </Button>
  );
};

const NextBtn = () => {
  return (
    <Button endIcon={<ArrowForwardIcon />} sx={styles.btnHover}>
      Next
    </Button>
  );
};

const styles = {
  btnHover: {
    color: blackColor,
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
};
