import { Box, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import InfoCardSection from "./InfoCardSection";
import DiscountSection from "./DiscountSection";
import TopReviewsSection from "./TopReviewsSection";
import HomeProductGroup from "./HomeProductGroup";
import DiscountOfferSection from "./DiscountOfferSection";
import HomeMetricGroup from "./HomeMetricGroup";
import Skeleton from "react-loading-skeleton";
import TryBeforeBuyCard from "./TryBeforeBuyCard";
import FinvervBanner from "./FinvervBanner";
import FinvervAvailableBanner from "./FinvervAvailableBanner";
import FinvervNotRegistered from "./FinvervNotRegistered";
import { getPhoneNumber, getProfileId } from "utils/authHelpers";
import {
  finboxPartnerPush,
  getFinboxEligibility,
  getFinboxUrl,
  getFinvervUrl,
  registeFinboxUser,
  registeFinvervUser,
} from "services/api_calls/checkout/checkoutApi";
import ModalCard from "components/ModalCard";
import {
  applyBg,
  approvedBg,
  blackColor,
  closedBg,
  dueBg,
  incompleteBg,
  overDueBg,
  reApplyBg,
  rejectedBg,
  whiteColor,
} from "configs/styles/muiThemes";
import FinvervPending from "./FinvervPending";
import FinvervRejected from "./FinvervRejected";
import { useSelector } from "react-redux";
import FinboxBanner from "./FinboxBanner";
import {
  applyImg,
  approvedImg,
  dueImg,
  finboxActiveStatusmsg,
  finboxApplyStatusmsg2,
  finboxActiveStatusTitle,
  finboxApplyStatusmsg,
  finboxApplyStatusTitle,
  finboxApprovedStatusmsg,
  finboxApprovedStatusTitle,
  finboxDueStatusmsg,
  finboxDueStatusTitle,
  finboxIncompleteStatusmsg,
  finboxIncompleteStatusTitle,
  finboxOverDueStatusmsg,
  finboxOverDueStatusTitle,
  finboxRejectedStatusmsg,
  finboxRejectedStatusTitle,
  incompleteImg,
  overDueImg,
  rejectedImg,
  finboxReApplyStatusTitle,
  finboxReApplyStatusmsg,
  finboxClosedStatusTitle,
  reApplyImg,
} from "configs/Constants";
import LoginAlertCard from "components/LoginAlertCard";
import ModalCard1 from "components/ModalCard1";
import BuyAgainGroup from "./BuyAgainGroup";
import HomeMetricSingleGroup from "./HomeMetricSingleGroup";

function HomeContent({
  productsGroup,
  commercialDetails,
  dealsCouponsDetails,
  allReviews,
  reviewSummary,
  rewardpoints,
  buyAgainDetails,
  metricDetails,
  productLength,
  loader,
  isFinvervAvailable,
  finAvailValue,
  finPercentComplete,
  getFinvervLinesData,
  getFinboxLinesData,
  finboxAvlAmt,
  setShowLogin,
  finboxOverdueDetails,
}) {
  const { isLoggedIn } = useSelector((state) => state.auth);

  const [dealFilteredArray, setDealFilteredArray] = useState([]);
  const [finvervModal, setFinvervModal] = useState({ show: false, url: "" });
  const [finvervModal1, setFinvervModal1] = useState({ show: false, url: "" });
  const { accountDetails } = useSelector((state) => state.dashboard);
  const [loginPopUp, setLoginPopUp] = useState(false);

  useEffect(() => {
    console.log("HOME_CONTENT_HITTT");
    if (metricDetails !== null && metricDetails[0].discounts) {
      if (metricDetails[0].discounts.length > 0) {
        let filterArray = metricDetails[0].discounts.filter((item) => {
          return item.percentageOff > 0;
        });
        setDealFilteredArray(filterArray);
      }
    }

    return () => {};
  }, [metricDetails]);

  return (
    <Box mb={{ xs: "100px", md: 0 }}>
      {/* Finbox Banners */}
      {isLoggedIn && isFinvervAvailable === "notRegisteredFinbox" ? (
        <FinboxBanner
          img={applyImg}
          bgColor={applyBg}
          title={finboxApplyStatusTitle}
          msg={finboxApplyStatusmsg}
          msg2={finboxApplyStatusmsg2}
          btnName="Apply Here"
          onClick={() => {
            const phoneNumber = getPhoneNumber();
            const customerID = getProfileId();
            const name = accountDetails?.account?.name;
            const email = accountDetails?.account?.email;
            console.log("Fintech", phoneNumber?.replace("+91", ""));
            registeFinboxUser({
              customerID,
              mobile: phoneNumber?.replace("+91", ""),
            }).then((res) => {
              finboxPartnerPush({ customerID, name, email }).then(() => {
                getFinboxEligibility().then(() => {
                  getFinboxUrl({
                    redirectURL: window.location.href,
                  }).then((resp) => {
                    console.log("resprespresp", resp);
                    if (resp?.data?.url) {
                      setFinvervModal({
                        show: true,
                        url: resp?.data?.url,
                      });
                    }
                  });
                });
              });
            });
          }}
        />
      ) : null}
      {isLoggedIn && isFinvervAvailable === "APPLY" ? (
        <FinboxBanner
          img={applyImg}
          bgColor={applyBg}
          title={finboxApplyStatusTitle}
          msg={finboxApplyStatusmsg}
          msg2={finboxApplyStatusmsg2}
          btnName="Apply Here"
          onClick={() => {
            getFinboxUrl({
              redirectURL: window.location.href,
            }).then((resp) => {
              console.log("resprespresp", resp);
              if (resp?.data?.url) {
                setFinvervModal({
                  show: true,
                  url: resp?.data?.url,
                });
              }
            });
            // const phoneNumber = getPhoneNumber()
            // const customerID = getProfileId()
            // const name = accountDetails?.account?.name
            // const email = accountDetails?.account?.email
            // console.log('Fintech', phoneNumber?.replace('+91', ''))
            // registeFinboxUser({
            //   customerID,
            //   mobile: phoneNumber?.replace('+91', ''),
            // }).then((res) => {
            //   finboxPartnerPush({ customerID, name, email }).then(() => {
            //     getFinboxEligibility().then(() => {
            //       getFinboxUrl({
            //         redirectURL: window.location.href,
            //       }).then((resp) => {
            //         console.log('resprespresp', resp)
            //         if (resp?.data?.url) {
            //           setFinvervModal({
            //             show: true,
            //             url: resp?.data?.url,
            //           })
            //         }
            //       })
            //     })
            //   })
            // })
          }}
        />
      ) : null}

      {!isLoggedIn && commercialDetails?.info?.fintech?.active ? (
        <FinboxBanner
          img={applyImg}
          bgColor={applyBg}
          title={finboxApplyStatusTitle}
          msg={finboxApplyStatusmsg}
          msg2={finboxApplyStatusmsg2}
          btnName="Apply Here"
          onClick={() => {
            setLoginPopUp(true);
          }}
        />
      ) : null}

      {isLoggedIn && isFinvervAvailable === "APPROVED" ? (
        <FinboxBanner
          img={approvedImg}
          bgColor={approvedBg}
          title={finboxApprovedStatusTitle}
          msg={finboxApprovedStatusmsg}
          btnName="Continue Here"
          onClick={() => {
            getFinboxUrl({
              redirectURL: window.location.href,
            }).then((resp) => {
              console.log("resprespresp", resp);
              if (resp?.data?.url) {
                setFinvervModal({
                  show: true,
                  url: resp?.data?.url,
                });
              }
            });
          }}
        />
      ) : null}
      {isLoggedIn && isFinvervAvailable === "INCOMPLETE" ? (
        <FinboxBanner
          img={incompleteImg}
          bgColor={incompleteBg}
          title={finboxIncompleteStatusTitle}
          msg={finboxIncompleteStatusmsg}
          onClick={() => {
            getFinboxUrl({
              redirectURL: window.location.href,
            }).then((resp) => {
              console.log("resprespresp", resp);
              if (resp?.data?.url) {
                setFinvervModal({
                  show: true,
                  url: resp?.data?.url,
                });
              }
            });
          }}
          btnName="Continue Here"
        />
      ) : null}
      {isLoggedIn && isFinvervAvailable === "REJECTED" ? (
        <FinboxBanner
          img={rejectedImg}
          bgColor={rejectedBg}
          title={finboxRejectedStatusTitle}
          msg={finboxRejectedStatusmsg}
          btnName="Click Here for details"
          onClick={() => {
            getFinboxUrl({
              redirectURL: window.location.href,
            }).then((resp) => {
              console.log("resprespresp", resp);
              if (resp?.data?.url) {
                setFinvervModal({
                  show: true,
                  url: resp?.data?.url,
                });
              }
            });
          }}
        />
      ) : null}
      {isLoggedIn && isFinvervAvailable === "ACTIVE" ? (
        <FinboxBanner
          img={applyImg}
          bgColor={applyBg}
          title={finboxActiveStatusTitle}
          btnName="Click Here for details"
          onClick={() => {
            getFinboxUrl({
              redirectURL: window.location.href,
            }).then((resp) => {
              console.log("resprespresp", resp);
              if (resp?.data?.url) {
                setFinvervModal({
                  show: true,
                  url: resp?.data?.url,
                });
              }
            });
          }}
          avlAmt={finboxAvlAmt}
          minAmt={commercialDetails?.info?.fintech?.partners?.[0]?.creditLimit}
        />
      ) : null}
      {isLoggedIn && isFinvervAvailable === "DUE" ? (
        <FinboxBanner
          img={dueImg}
          bgColor={dueBg}
          title={finboxDueStatusTitle}
          msg={finboxDueStatusmsg}
          btnName="Click Here for details"
          onClick={() => {
            getFinboxUrl({
              redirectURL: window.location.href,
            }).then((resp) => {
              console.log("resprespresp", resp);
              if (resp?.data?.url) {
                setFinvervModal({
                  show: true,
                  url: resp?.data?.url,
                });
              }
            });
          }}
        />
      ) : null}
      {isLoggedIn && isFinvervAvailable === "OVERDUE" ? (
        <FinboxBanner
          img={overDueImg}
          bgColor={overDueBg}
          title={finboxOverDueStatusTitle}
          msg={finboxOverDueStatusmsg}
          btnName="Click Here for details"
          overdueAmt={finboxOverdueDetails?.amt}
          date={finboxOverdueDetails?.date}
          onClick={() => {
            getFinboxUrl({
              redirectURL: window.location.href,
            }).then((resp) => {
              console.log("resprespresp", resp);
              if (resp?.data?.url) {
                setFinvervModal({
                  show: true,
                  url: resp?.data?.url,
                });
              }
            });
          }}
        />
      ) : null}

      {/* Finverv Banners */}
      {isLoggedIn && isFinvervAvailable == "notCompleted" ? (
        <FinboxBanner
          img={incompleteImg}
          bgColor={incompleteBg}
          title={finboxIncompleteStatusTitle}
          msg={finboxIncompleteStatusmsg}
          btnName="Continue Here"
          // finPercentComplete={finPercentComplete}
          onClick={() => {
            getFinvervUrl().then((resp) => {
              if (resp?.redirect_url) {
                setFinvervModal1({
                  show: true,
                  url: resp?.redirect_url,
                });
              }
            });
          }}
        />
      ) : null}

      {isLoggedIn && isFinvervAvailable == "rejected" ? (
        <FinboxBanner
          img={rejectedImg}
          bgColor={rejectedBg}
          title={finboxRejectedStatusTitle}
          msg={finboxRejectedStatusmsg}
          btnName="Click Here for details"
          onClick={() => {
            getFinvervUrl().then((resp) => {
              if (resp?.redirect_url) {
                setFinvervModal1({
                  show: true,
                  url: resp?.redirect_url,
                });
              }
            });
          }}
        />
      ) : null}
      {isLoggedIn && isFinvervAvailable == "balanceAvailable" ? (
        <FinboxBanner
          img={applyImg}
          bgColor={applyBg}
          title={finboxActiveStatusTitle}
          btnName="Click Here for details"
          avlAmt={finAvailValue?.available_amount}
          minAmt={commercialDetails?.info?.fintech?.partners?.[0]?.creditLimit}
          onClick={() => {
            getFinvervUrl().then((resp) => {
              if (resp?.redirect_url) {
                setFinvervModal1({
                  show: true,
                  url: resp?.redirect_url,
                });
              }
            });
          }}
        />
      ) : null}
      {isLoggedIn && isFinvervAvailable == "notRegistered" ? (
        <FinboxBanner
          img={applyImg}
          bgColor={applyBg}
          title={finboxApplyStatusTitle}
          msg={finboxApplyStatusmsg}
          msg2={finboxApplyStatusmsg2}
          btnName="Apply Here"
          onClick={() => {
            const phoneNumber = getPhoneNumber();
            console.log("Fintech", phoneNumber?.replace("+91", ""));
            registeFinvervUser({
              mobile: phoneNumber?.replace("+91", ""),
            }).then((res) => {
              getFinvervUrl().then((resp) => {
                if (resp?.redirect_url) {
                  setFinvervModal1({
                    show: true,
                    url: resp?.redirect_url,
                  });
                }
              });
            });
          }}
        />
      ) : null}

      <Container
        sx={{ maxWidth: "1300px !important", pl: "0px !important", pb: 0 }}
      >
        {/* {commercialDetails?.info?.rewardPointPercent ||
        commercialDetails?.info?.orderInfoDetails?.[0]?.minOrderAmount ||
        commercialDetails?.info?.immediateDiscount ||
        commercialDetails?.info?.shipmentDistance >= 3000 ? (
          <DiscountOfferSection
            rewardPointPercent={commercialDetails?.info?.rewardPointPercent}
            minOrderAmount={
              commercialDetails?.info?.orderInfoDetails?.[0]?.minOrderAmount
            }
            immediateDiscount={commercialDetails?.info?.immediateDiscount}
            shipmentDistance={commercialDetails?.info?.shipmentDistance}
          />
        ) : null} */}
        {/* Buy Again Section */}

        {/* {isLoggedIn &&
        buyAgainDetails &&
        buyAgainDetails?.length > 0 &&
        buyAgainDetails !== undefined ? (
          <BuyAgainGroup
            title='Buy Again'
            products={buyAgainDetails}
            type='buy-again'
            caption='Thanks for your purchase!'
            buyProduct
          />
        ) : null} */}
        <Box mt={buyAgainDetails?.length > 0 ? 2 : 0}>
          {isLoggedIn &&
          buyAgainDetails &&
          buyAgainDetails?.length > 0 &&
          buyAgainDetails !== undefined ? (
            <HomeMetricSingleGroup
              title="Buy Again"
              products={buyAgainDetails?.map((item) => item?.item)}
              type="buy-again"
              caption="Thanks for your purchase!"
              buyProduct
            />
          ) : null}
        </Box>
      </Container>

      {/* Discout Coupons Section */}
      {dealsCouponsDetails?.length > 0 ? (
        <DiscountSection dealsCouponsDetails={dealsCouponsDetails} />
      ) : null}

      {metricDetails !== null && metricDetails[0].orders && (
        <Box mt={buyAgainDetails?.length > 0 ? 0 : 1}>
          {metricDetails.length > 0 && productLength >= 7 ? (
            metricDetails[0].orders.length > 0 ? (
              <HomeMetricSingleGroup
                title="Trending Products"
                products={metricDetails[0].orders}
                type="trending"
                caption="Top rated!"
              />
            ) : null
          ) : null}
        </Box>
      )}

      <Container sx={{ maxWidth: "1300px !important" }}>
        {metricDetails !== null && (
          <Box>
            {metricDetails?.length > 0 && productLength >= 7 ? (
              metricDetails?.[0].offers?.length > 0 ? (
                <TryBeforeBuyCard products={metricDetails?.[0].offers} />
              ) : null
            ) : null}
          </Box>
        )}
      </Container>

      {/* Top Reviews Section */}
      {/* {allReviews &&
        allReviews?.allReview &&
        allReviews?.allReview?.length > 1 && (
          <TopReviewsSection
            allReviews={allReviews}
            reviewSummary={reviewSummary}
          />
        )} */}

      <Container sx={{ maxWidth: "1300px !important" }}>
        {/* {metricDetails === null && dealFilteredArray.length === 0 && (
          <Skeleton width={500} height={500} enableAnimation={true}></Skeleton>
        )} */}

        {metricDetails !== null && (
          <Box>
            {metricDetails.length > 0 && productLength >= 10 ? (
              dealFilteredArray.length > 0 ? (
                <Box mt={0.5}>
                  <HomeMetricGroup
                    title="Top deals"
                    products={dealFilteredArray}
                    type="top-deals"
                    caption="Best discounts for you!"
                  />
                </Box>
              ) : null
            ) : null}
          </Box>
        )}

        {metricDetails !== null && (
          <Box>
            {commercialDetails?.info?.returnPolicy ||
            commercialDetails?.info?.notes ||
            rewardpoints?.details?.length > 0 ? (
              <InfoCardSection
                commercialDetails={commercialDetails}
                data={rewardpoints}
              />
            ) : (
              <Box mt={2} />
            )}
          </Box>
        )}
      </Container>

      {metricDetails !== null && (
        <Box>
          {metricDetails?.length > 0 && productLength >= 10 ? (
            metricDetails?.[0].newest?.length > 0 ? (
              <Box mt={2}>
                <HomeMetricSingleGroup
                  title="New in our store"
                  products={metricDetails?.[0].newest}
                  type="new-store"
                  caption="Our newest launches!"
                />
              </Box>
            ) : null
          ) : null}
        </Box>
      )}

      <Container sx={{ maxWidth: "1300px !important" }}>
        {metricDetails !== null && (
          <Box>
            {metricDetails?.length > 0 && productLength >= 10 ? (
              metricDetails?.[0].lowestRate?.length > 0 ? (
                <HomeMetricGroup
                  title="Priced just right"
                  products={metricDetails?.[0].lowestRate}
                  type="price-right"
                  caption="Products below ₹200!"
                />
              ) : null
            ) : null}
          </Box>
        )}
      </Container>

      {productLength > 0 && (
        <Box
          sx={{
            display: "flex",
            "-webkit-box-pack": "justify",
            "-ms-flex-pack": "justify",
            "justify-content": "space-between",
            // 'margin-bottom': '12px',
            "align-items": "center",
            backgroundColor: "#F3F3F8",
            padding: "5px 20px",
            mb:1
          }}
        >
          <Typography sx={{ color: blackColor, fontSize: 17, fontWeight: 600 }}>
            Catalog
          </Typography>
        </Box>
      )}

      {productsGroup.map((item, index) => {
        if (productLength <= 7) {
          return (
            <HomeProductGroup
              title={item.subCatValue}
              products={item.products}
              count={item.count}
              item={item}
              onlyProduct
              key={index}
              mod={index + 1}
            />
          );
        }

        return null;
      })}

      <Box>
        {productsGroup.map((item, index) => {
          if (productLength > 7) {
            if (item?.products?.length < 4) return null;
            return (
              <HomeProductGroup
                title={item.subCatValue}
                products={item.products}
                count={item.count}
                item={item}
                onlyProduct
                key={index}
                mod={index + 1}
              />
            );
          }

          return null;
        })}
      </Box>

      {/* Finbox Modal */}
      <ModalCard1
        handleClose={() => {
          getFinboxLinesData();
          setFinvervModal({ show: false, url: "" });
        }}
        open={finvervModal.show}
        // width='30%'
        height="80vh"
      >
        <Box
          width="100%"
          minHeight="80vh"
          sx={{ borderRadius: "12px", backgroundColor: whiteColor }}
        >
          <iframe
            src={finvervModal.url.slice(0, -5) + "true"}
            style={{
              border: "none",
              borderRadius: "12px",
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
      </ModalCard1>

      {/* Finverv Modal */}
      <ModalCard1
        handleClose={() => {
          getFinvervLinesData();
          setFinvervModal1({ show: false, url: "" });
        }}
        open={finvervModal1.show}
        // width='30%'
        height="80vh"
      >
        <Box
          width="100%"
          minHeight="80vh"
          sx={{ borderRadius: "12px", backgroundColor: whiteColor }}
        >
          <iframe
            src={finvervModal1.url}
            style={{
              border: "none",
              borderRadius: "12px",
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
      </ModalCard1>

      <ModalCard
        open={loginPopUp}
        handleClose={() => {
          setLoginPopUp(false);
        }}
        width="23%"
      >
        <LoginAlertCard
          handleClose={() => {
            setLoginPopUp(false);
          }}
          onLoginClick={() => {
            setLoginPopUp(false);
            setShowLogin();
          }}
        />
      </ModalCard>
    </Box>
  );
}

export default HomeContent;
