import { LocalShipping } from "@mui/icons-material";
import { Box, Grid, Skeleton, Typography, useMediaQuery } from "@mui/material";
import {
  blackColor,
  borderLineColor,
  discountBgColor,
  headingColor,
  minOrderTextColor,
  outOfStockTextColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import DeliveryMethodCard from "./DiscountCards/DeliveryMethodCard";
import DiscountHeader from "./DiscountHeader";

function DeliveryMethodSection({
  deliveryMethod,
  onDeliveryMethodChange,
  totalCartWeightKg,
  costboPremierDeliverETA,
  seletedAddress,
  businessDelivery,
  additionalDiscount,
  isFreeShipping,
  costboPremier,
  costboPremierHeavy,
  costboPremierDeliverETAHeavy,
  telyportStandardDelivery,
  telyportRushDelivery,
  telyportExpressDelivery,
  errorMessage,
  deliveryMethodLoader,
  ondcDelivery,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  let businessFullName = sessionStorage.getItem("businessFullName");

  return (
    <Box>
      <DiscountHeader
        icon={
          !matches && (
            <LocalShipping
              sx={{
                fontSize: { xs: "22px", md: "20px" },
                color: { md: headingColor, xs: blackColor },
                mb: "-5px",
                mr: 1,
              }}
            />
          )
        }
        topmargin={"15px"}
        title="Delivery Method"
      />

      <Box
        backgroundColor={{ md: whiteColor, xs: whiteColor }}
        border={{ md: "1px solid " + borderLineColor, xs: "none" }}
        borderTop={{ xs: "1px solid " + borderLineColor, md: "none" }}
        px={{ md: 1.5, xs: 0 }}
        py={{ xs: 1, md: 1.5 }}
        borderRadius={{ xs: 0, md: 1.8 }}
        mt="6px"
      >
        <Grid container alignItems="center" spacing={1.7}>
          {/* {matches && (
            <Grid item>
              <Box
                component='img'
                src='https://storage.googleapis.com/bodefaults/shopweb/package.svg'
                alt={businessFullName + ' CostBo'}
                width={{ xs: '40px', md: '35px' }}
                height={{ xs: '58px', md: '35px' }}
              />
            </Grid>
          )} */}

          <Grid item>
            <Typography
              variant="subtitle1"
              component="h5"
              fontSize={{ md: 14, xs: 13 }}
              fontWeight={600}
              color={{ md: headingColor, xs: blackColor }}
            >
              Standard Package
            </Typography>

            <Typography
              variant="subtitle1"
              component="h5"
              fontSize={{ md: "13px", xs: 11.5 }}
              color={minOrderTextColor}
              fontWeight={400}
            >
              Cart Weight {totalCartWeightKg.toFixed(2)} kg
            </Typography>
          </Grid>
        </Grid>

        {deliveryMethodLoader ? (
          <Grid container pt={1.5} spacing={{ md: 2, xs: 0.75 }}>
            {[1, 2, 3, 4, 5]?.map((item) => (
              <Grid item xs={12} sm={4} md={2.4} key={`skeleton_${item}`}>
                <Skeleton
                  variant="rounded"
                  height={!matches ? "62px" : "141px"}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <>
            {matches && seletedAddress ? (
              <Grid container pt={1.5} spacing={{ md: 2, xs: 0.75 }}>
                {telyportRushDelivery?.show ? (
                  <Grid item xs={12} sm={4} md={2.4}>
                    <DeliveryMethodCard
                      icon={
                        "https://storage.googleapis.com/bodefaults/shopweb/delivery_rush.svg"
                      }
                      title="Rush"
                      subtitle={telyportRushDelivery?.deliveryETA}
                      subtitle1="(Tracking Available)"
                      error={telyportRushDelivery?.error}
                      // isFreeShipping={isFreeShipping}
                      // additionalDiscount={additionalDiscount}
                      // oldPrice={item.oldPrice}
                      // price={businessDelivery?.totalAmount}
                      oldPrice={telyportRushDelivery?.originalAmount}
                      price={telyportRushDelivery?.totalAmount}
                      isSelect={deliveryMethod === "rush"}
                      onSelectMethod={(price, subtitle) => {
                        onDeliveryMethodChange({
                          type: "rush",
                          amount: price,
                          eta: subtitle,
                          discountType: telyportRushDelivery.type,
                          originalAmount: telyportRushDelivery?.originalAmount,
                          shipmentTax: telyportRushDelivery?.shipmentTax,
                        });
                      }}
                    />
                  </Grid>
                ) : null}
                {telyportExpressDelivery?.show ? (
                  <Grid item xs={12} sm={4} md={2.4}>
                    <DeliveryMethodCard
                      icon="https://storage.googleapis.com/bodefaults/shopweb/delivery_express.svg"
                      title="Express"
                      subtitle={telyportExpressDelivery?.deliveryETA}
                      subtitle1="(Tracking Available)"
                      error={telyportExpressDelivery?.error}
                      // isFreeShipping={isFreeShipping}
                      // additionalDiscount={additionalDiscount}
                      // oldPrice={item.oldPrice}
                      // price={businessDelivery?.totalAmount}
                      oldPrice={telyportExpressDelivery?.originalAmount}
                      price={telyportExpressDelivery?.totalAmount}
                      isSelect={deliveryMethod === "express"}
                      onSelectMethod={(price, subtitle) => {
                        onDeliveryMethodChange({
                          type: "express",
                          amount: price,
                          eta: subtitle,
                          discountType: telyportExpressDelivery.type,
                          originalAmount:
                            telyportExpressDelivery?.originalAmount,
                          shipmentTax: telyportExpressDelivery?.shipmentTax,
                        });
                      }}
                    />
                  </Grid>
                ) : null}
                {telyportStandardDelivery?.show ? (
                  <Grid item xs={12} sm={4} md={2.4}>
                    <DeliveryMethodCard
                      icon="https://storage.googleapis.com/bodefaults/shopweb/delivery_standard.svg"
                      title="Next day"
                      subtitle={telyportStandardDelivery?.deliveryETA}
                      subtitle1="(Tracking Available)"
                      error={telyportStandardDelivery?.error}
                      // isFreeShipping={isFreeShipping}
                      // additionalDiscount={additionalDiscount}
                      // oldPrice={item.oldPrice}
                      // price={businessDelivery?.totalAmount}
                      oldPrice={telyportStandardDelivery?.originalAmount}
                      price={telyportStandardDelivery?.totalAmount}
                      isSelect={deliveryMethod === "standard"}
                      onSelectMethod={(price, subtitle) => {
                        onDeliveryMethodChange({
                          type: "standard",
                          amount: price,
                          eta: subtitle,
                          discountType: telyportStandardDelivery?.type,
                          originalAmount:
                            telyportStandardDelivery?.originalAmount,
                          shipmentTax: telyportStandardDelivery?.shipmentTax,
                        });
                      }}
                    />
                  </Grid>
                ) : null}
                {costboPremier?.show ? (
                  <Grid item xs={12} sm={4} md={2.4}>
                    <DeliveryMethodCard
                      icon="https://storage.googleapis.com/bodefaults/shopweb/delivery_costbo_premier.svg"
                      title="Premier"
                      subtitle1="(Tracking Available)"
                      subtitle={costboPremierDeliverETA}
                      error={costboPremier?.error}
                      // isFreeShipping={isFreeShipping}
                      oldPrice={costboPremier?.originalAmount}
                      price={costboPremier?.totalAmount}
                      isSelect={deliveryMethod === "costbo-premier"}
                      onSelectMethod={(price, subtitle) => {
                        onDeliveryMethodChange({
                          type: "costbo-premier",
                          amount: price,
                          eta: subtitle,
                          discountType: costboPremier.type,
                          originalAmount: costboPremier?.originalAmount,
                          shipmentTax: costboPremier?.shipmentTax,
                        });
                      }}
                    />
                  </Grid>
                ) : null}
                {costboPremierHeavy?.show ? (
                  <Grid item xs={12} sm={4} md={2.4}>
                    <DeliveryMethodCard
                      icon="https://storage.googleapis.com/bodefaults/shopweb/delivery_costbo_premier.svg"
                      title="Heavy"
                      subtitle={costboPremierDeliverETAHeavy}
                      subtitle1="(Tracking Available)"
                      oldPrice={costboPremierHeavy?.originalAmount}
                      error={costboPremierHeavy?.error}
                      price={costboPremierHeavy?.totalAmount}
                      isSelect={deliveryMethod === "costbo-heavy"}
                      onSelectMethod={(price, subtitle) => {
                        onDeliveryMethodChange({
                          type: "costbo-heavy",
                          amount: price,
                          eta: subtitle,
                          discountType: costboPremierHeavy.type,
                          originalAmount: costboPremierHeavy?.originalAmount,
                          shipmentTax: costboPremierHeavy?.shipmentTax,
                        });
                      }}
                    />
                  </Grid>
                ) : null}
                {businessDelivery?.show ? (
                  <Grid item xs={12} sm={4} md={2.4}>
                    <DeliveryMethodCard
                      icon="https://storage.googleapis.com/bodefaults/shopweb/delivery_regular_delivery.svg"
                      title="Standard"
                      subtitle={businessDelivery?.deliveryETA}
                      error={businessDelivery?.error}
                      // isFreeShipping={isFreeShipping}
                      // additionalDiscount={additionalDiscount}
                      // oldPrice={item.oldPrice}
                      // price={businessDelivery?.totalAmount}
                      oldPrice={businessDelivery?.originalAmount}
                      price={businessDelivery?.totalAmount}
                      isSelect={deliveryMethod === "regular-delivery"}
                      onSelectMethod={(price, subtitle) => {
                        onDeliveryMethodChange({
                          type: "regular-delivery",
                          amount: price,
                          eta: subtitle,
                          discountType: businessDelivery.type,
                          originalAmount: businessDelivery?.originalAmount,
                        });
                      }}
                    />
                  </Grid>
                ) : null}
                {ondcDelivery?.show ? (
                  <Grid item xs={12} sm={4} md={2.4}>
                    <DeliveryMethodCard
                      icon="https://storage.googleapis.com/bodefaults/shopweb/ondc-logistics.svg"
                      title={ondcDelivery?.name}
                      subtitle1="(Tracking Available)"
                      subtitle={"ETA~ " + ondcDelivery?.deliveryETA}
                      error={ondcDelivery?.error}
                      oldPrice={ondcDelivery?.originalAmount}
                      price={ondcDelivery?.totalAmount}
                      isSelect={deliveryMethod === "ondc-delivery"}
                      onSelectMethod={(price, subtitle) => {
                        onDeliveryMethodChange({
                          type: "ondc-delivery",
                          amount: price,
                          eta: subtitle,
                          discountType: ondcDelivery.type,
                          originalAmount: ondcDelivery?.originalAmount,
                        });
                      }}
                    />
                  </Grid>
                ) : null}
                {businessDelivery?.show ||
                costboPremierHeavy?.show ||
                businessDelivery?.show ||
                ondcDelivery?.show ? null : (
                  <Typography
                    variant="subtitle1"
                    component="p"
                    fontSize="13px"
                    color={outOfStockTextColor}
                    display="inline"
                    mt={1.6}
                    px={2}
                  >
                    {errorMessage}
                  </Typography>
                )}

                {/* {deliveryMethods.map((item, index) => {
              return (
                <Grid item xs={6} sm={4} md={2}>
                  <DeliveryMethodCard
                    icon={item.icon}
                    title={item.title}
                    subtitle={item.subtitle}
                    oldPrice={item.oldPrice}
                    price={item.price}
                    isSelect={deliveryMethod == item.value}
                    onSelectMethod={() => {
                      onDeliveryMethodChange(item.value)
                    }}
                  />
                </Grid>
              )
            })} */}
              </Grid>
            ) : null}
            {!matches && seletedAddress ? (
              <Box
                sx={{
                  // display: 'flex',
                  // overflowX: 'auto', // Enable horizontal scrolling
                  // padding: '10px 0',
                  gap: "15px", // Gap between coupon cards
                  // '&::-webkit-scrollbar': {
                  //   height: '8px',
                  // },
                  // '&::-webkit-scrollbar-thumb': {
                  //   backgroundColor: '#ccc',
                  //   borderRadius: '10px',
                  // },
                  mt: 1,
                }}
              >
                {telyportRushDelivery?.show ? (
                  <DeliveryMethodCard
                    icon={
                      "https://storage.googleapis.com/bodefaults/shopweb/delivery_rush.svg"
                    }
                    title="Rush"
                    subtitle={telyportRushDelivery?.deliveryETA}
                    subtitle1="(Tracking Available)"
                    error={telyportRushDelivery?.error}
                    // isFreeShipping={isFreeShipping}
                    // additionalDiscount={additionalDiscount}
                    // oldPrice={item.oldPrice}
                    // price={businessDelivery?.totalAmount}
                    oldPrice={telyportRushDelivery?.originalAmount}
                    price={telyportRushDelivery?.totalAmount}
                    isSelect={deliveryMethod === "rush"}
                    onSelectMethod={(price, subtitle) => {
                      onDeliveryMethodChange({
                        type: "rush",
                        amount: price,
                        eta: subtitle,
                        discountType: telyportRushDelivery.type,
                        originalAmount: telyportRushDelivery?.originalAmount,
                        shipmentTax: telyportRushDelivery?.shipmentTax,
                      });
                    }}
                  />
                ) : null}
                {telyportExpressDelivery?.show ? (
                  <DeliveryMethodCard
                    icon="https://storage.googleapis.com/bodefaults/shopweb/delivery_express.svg"
                    title="Express"
                    subtitle={telyportExpressDelivery?.deliveryETA}
                    subtitle1="(Tracking Available)"
                    error={telyportExpressDelivery?.error}
                    // isFreeShipping={isFreeShipping}
                    // additionalDiscount={additionalDiscount}
                    // oldPrice={item.oldPrice}
                    // price={businessDelivery?.totalAmount}
                    oldPrice={telyportExpressDelivery?.originalAmount}
                    price={telyportExpressDelivery?.totalAmount}
                    isSelect={deliveryMethod === "express"}
                    onSelectMethod={(price, subtitle) => {
                      onDeliveryMethodChange({
                        type: "express",
                        amount: price,
                        eta: subtitle,
                        discountType: telyportExpressDelivery.type,
                        originalAmount: telyportExpressDelivery?.originalAmount,
                        shipmentTax: telyportExpressDelivery?.shipmentTax,
                      });
                    }}
                  />
                ) : null}
                {telyportStandardDelivery?.show ? (
                  <DeliveryMethodCard
                    icon="https://storage.googleapis.com/bodefaults/shopweb/delivery_standard.svg"
                    title="Next day"
                    subtitle={telyportStandardDelivery?.deliveryETA}
                    subtitle1="(Tracking Available)"
                    error={telyportStandardDelivery?.error}
                    // isFreeShipping={isFreeShipping}
                    // additionalDiscount={additionalDiscount}
                    // oldPrice={item.oldPrice}
                    // price={businessDelivery?.totalAmount}
                    oldPrice={telyportStandardDelivery?.originalAmount}
                    price={telyportStandardDelivery?.totalAmount}
                    isSelect={deliveryMethod === "standard"}
                    onSelectMethod={(price, subtitle) => {
                      onDeliveryMethodChange({
                        type: "standard",
                        amount: price,
                        eta: subtitle,
                        discountType: telyportStandardDelivery?.type,
                        originalAmount:
                          telyportStandardDelivery?.originalAmount,
                        shipmentTax: telyportStandardDelivery?.shipmentTax,
                      });
                    }}
                  />
                ) : null}
                {costboPremier?.show ? (
                  <DeliveryMethodCard
                    icon="https://storage.googleapis.com/bodefaults/shopweb/delivery_costbo_premier.svg"
                    title="Premier"
                    subtitle1="(Tracking Available)"
                    subtitle={costboPremierDeliverETA}
                    error={costboPremier?.error}
                    // isFreeShipping={isFreeShipping}
                    oldPrice={costboPremier?.originalAmount}
                    price={costboPremier?.totalAmount}
                    isSelect={deliveryMethod === "costbo-premier"}
                    onSelectMethod={(price, subtitle) => {
                      onDeliveryMethodChange({
                        type: "costbo-premier",
                        amount: price,
                        eta: subtitle,
                        discountType: costboPremier.type,
                        originalAmount: costboPremier?.originalAmount,
                        shipmentTax: costboPremier?.shipmentTax,
                      });
                    }}
                  />
                ) : null}
                {costboPremierHeavy?.show ? (
                  <DeliveryMethodCard
                    icon="https://storage.googleapis.com/bodefaults/shopweb/delivery_costbo_premier.svg"
                    title="Heavy"
                    subtitle={costboPremierDeliverETAHeavy}
                    subtitle1="(Tracking Available)"
                    oldPrice={costboPremierHeavy?.originalAmount}
                    error={costboPremierHeavy?.error}
                    price={costboPremierHeavy?.totalAmount}
                    isSelect={deliveryMethod === "costbo-heavy"}
                    onSelectMethod={(price, subtitle) => {
                      onDeliveryMethodChange({
                        type: "costbo-heavy",
                        amount: price,
                        eta: subtitle,
                        discountType: costboPremierHeavy.type,
                        originalAmount: costboPremierHeavy?.originalAmount,
                        shipmentTax: costboPremierHeavy?.shipmentTax,
                      });
                    }}
                  />
                ) : null}
                {businessDelivery?.show ? (
                  <DeliveryMethodCard
                    icon="https://storage.googleapis.com/bodefaults/shopweb/delivery_regular_delivery.svg"
                    title="Standard"
                    subtitle={businessDelivery?.deliveryETA}
                    error={businessDelivery?.error}
                    // isFreeShipping={isFreeShipping}
                    // additionalDiscount={additionalDiscount}
                    // oldPrice={item.oldPrice}
                    // price={businessDelivery?.totalAmount}
                    oldPrice={businessDelivery?.originalAmount}
                    price={businessDelivery?.totalAmount}
                    isSelect={deliveryMethod === "regular-delivery"}
                    onSelectMethod={(price, subtitle) => {
                      onDeliveryMethodChange({
                        type: "regular-delivery",
                        amount: price,
                        eta: subtitle,
                        discountType: businessDelivery.type,
                        originalAmount: businessDelivery?.originalAmount,
                      });
                    }}
                  />
                ) : null}
                {ondcDelivery?.show ? (
                  <Grid item xs={12} sm={4} md={2.4}>
                    <DeliveryMethodCard
                      icon="https://storage.googleapis.com/bodefaults/shopweb/ondc-logistics.svg"
                      title={ondcDelivery?.name}
                      subtitle1="(Tracking Available)"
                      subtitle={"ETA~ " + ondcDelivery?.deliveryETA}
                      error={ondcDelivery?.error}
                      oldPrice={ondcDelivery?.originalAmount}
                      price={ondcDelivery?.totalAmount}
                      isSelect={deliveryMethod === "ondc-delivery"}
                      onSelectMethod={(price, subtitle) => {
                        onDeliveryMethodChange({
                          type: "ondc-delivery",
                          amount: price,
                          eta: subtitle,
                          discountType: ondcDelivery.type,
                          originalAmount: ondcDelivery?.originalAmount,
                        });
                      }}
                    />
                  </Grid>
                ) : null}

                {businessDelivery?.show ||
                costboPremierHeavy?.show ||
                businessDelivery?.show ||
                ondcDelivery?.show ||
                !errorMessage ? null : (
                  <Typography
                    variant="subtitle1"
                    component="p"
                    fontSize="13px"
                    color={outOfStockTextColor}
                    display="inline"
                    mt={1.6}
                    px={2}
                  >
                    {errorMessage}
                  </Typography>
                )}
              </Box>
            ) : null}
          </>
        )}
      </Box>
    </Box>
  );
}

export default DeliveryMethodSection;
