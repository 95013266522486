import { Box, Container, Grid, Skeleton, Stack } from '@mui/material'
import { backgroundColor01, redColor01, whiteColor } from 'configs/styles/muiThemes'
import React from 'react'

const OrderDetailsMobileLoader = () => {
  return (
    <Box bgcolor={backgroundColor01}>
        <Box sx={{p:'15px 15px', bgcolor: whiteColor, borderRadius:'8px', mt:3}}>
            <Box display='flex' alignItems='center' justifyContent='space-between'>
                <Skeleton variant="rounded" width="100px" height="14px" sx={{m:'0px !important'}} />
                <Skeleton variant="rounded" width="60px" height="14px" sx={{m:'0px !important'}} />
            </Box>

            <Stack alignItems='center' >
                <Skeleton variant="rounded" width="176px" height="14px" sx={{mt:'15px !important'}} />
                <Skeleton variant="rounded" width="129px" height="14px" sx={{mt:'13px !important'}} />
                <Skeleton variant="rounded" width="179px" height="14px" sx={{mt:'12px !important'}} />
                <Skeleton variant="rounded" width="264px" height="14px" sx={{mt:'12px !important'}} />
                <Skeleton variant="rounded" width="85%" height="68px" sx={{mt:'18px !important'}} />
            </Stack>
        </Box>

        <Skeleton variant="rounded" width="100%" height="241px" sx={{mt:'18px !important', borderRadius:'8px'}} />

        <Skeleton variant="rounded" width="100px" height="14px" sx={{mt:'22px !important'}} />
        <Skeleton variant="rounded" width="100%" height="241px" sx={{mt:'14px !important', borderRadius:'8px'}} />
    </Box>
  )
}

export default OrderDetailsMobileLoader