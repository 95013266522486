import { Box, Typography } from '@mui/material'
import { commingSoonMsg } from 'configs/Constants'
import { backgroundColor01, blackColor, headingColor, whiteColor } from 'configs/styles/muiThemes'
import React from 'react'

function CommingSoonCard() {
  let businessFullName = sessionStorage.getItem('businessFullName')

  return (
    <Box
      m={{md:8,xs:2}}
      mt={{ md: 5, xs:17.8 }}
      // width={"100%"}
      backgroundColor={{xs:whiteColor, md:backgroundColor01}}
      pt={{xs:2,md:0}}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        backgroundColor={{xs: whiteColor, md:backgroundColor01}}
        borderRadius={"10px"}
      >
        <Box
          component="img"
          src="https://storage.googleapis.com/bodefaults/shopweb/home/shop-empty.svg"
          width={"128px"}
          height="auto"
          sx={{ mt: 3 }}
          alt={businessFullName + " CostBo"}
        />
        <Typography
          variant="subtitle2"
          fontSize={15}
          color={blackColor}
          pt={1}
          pb={2}
          sx={{ width: { xs: "90%", md: "40%" } }}
          textAlign="center"
        >
          {commingSoonMsg}
        </Typography>
      </Box>
    </Box>
  );
}

export default CommingSoonCard
