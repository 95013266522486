import { NavigateNextRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Typography,
  Breadcrumbs,
  useMediaQuery,
} from "@mui/material";
import { breadcrum_ShopMore } from "configs/Constants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getActiveBusiness } from "redux/header/headerSlice";
import {
  blackColor,
  greyShade49,
  greyShade50,
  headingColor,
  orangeColor01,
  sidebarHeadingColor,
  whiteColor,
} from "../configs/styles/muiThemes";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

function BreadcrumbsSection({ links, detail, currentPage, setCurrentPage }) {
  const dispatch = useDispatch();
  const { businessDetails } = useSelector((state) => state.header);
  const businessName = sessionStorage.getItem("businessName");

  const [businessTitle, setBusinessTitle] = useState("");
  const words = [];
  let currentWord = "";

  if(businessTitle){
    for (let char of businessTitle) {
      if (char === "-" || char === " ") {
        if (currentWord) {
          words.push(currentWord);
          currentWord = "";
          if (words.length === 3) break; // Stop after collecting 3 words
        }
      } else {
        currentWord += char;
      }
    }
  }

  // Push the last word if necessary
  if (currentWord && words.length < 3) {
    words.push(currentWord);
  }

  const nameDetails = words.join(" ");
  console.log(nameDetails);

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  useEffect(() => {
    const businessInfo = sessionStorage.getItem("businessInfo");
    if (!businessInfo) {
      dispatch(getActiveBusiness());
    } else {
      const data = JSON.parse(businessInfo);
      setBusinessTitle(data?.[0]?.businessName);
    }
  }, [businessDetails]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: { md: whiteColor, xs: whiteColor },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "40px",
          border: { md: `1px solid ${greyShade49}`, xs: "none" },
        }}
        px={{ md: "15px", xs: 2 }}
        py={{ md: "5px", xs: 0.94 }}
        borderRadius={{ xs: 0, md: "10px" }}
        mb={{ xs: 0, md: 1.5 }}
      >
        {detail ? (
          <>
            <Box
              display={"flex"}
              gap={1}
              alignItems={"center"}
              component={Link}
              to={
                detail === "Add New Address"
                  ? `/${businessName}/DiscountDelivery`
                  : currentPage === "discount"
                  ? `/${businessName}/cart`
                  : currentPage === "address-page"
                  ? `/${businessName}/DiscountDelivery`
                  : detail === "Cart Details"
                  ? `/${businessName}/Catalog`
                  : ""
              }
              onClick={() => {
                if (currentPage === "address-page") setCurrentPage("discount");
              }}
            >
              <Box
                component={Link}
                to={
                  detail === "Add New Address"
                    ? `/${businessName}/DiscountDelivery`
                    : currentPage === "discount"
                    ? `/${businessName}/cart`
                    : currentPage === "address-page"
                    ? `/${businessName}/DiscountDelivery`
                    : detail === "Cart Details"
                    ? `/${businessName}/Catalog`
                    : `/${businessName}/Catalog`
                }
              >
                {/* {matches ? (
                  <KeyboardBackspaceIcon
                    sx={{ fontSize: 24, color: blackColor, mt: 0.6 }}
                  />
                ) : ( */}
                <Box
                  component="img"
                  maxHeight={{ xs: "24px", md: "24px" }}
                  borderRadius={"8px"}
                  src={
                    "https://storage.googleapis.com/bodefaults/shopweb/home/arrow-left.svg"
                  }
                  alt="CostBo"
                  width={"24px"}
                  height={"24px"}
                  sx={{ mt: 0.8 }}
                />
                {/* )} */}
              </Box>
              <Typography
                key="1"
                color={blackColor}
                fontWeight={600}
                fontSize="15px"
                component={Link}
                to={
                  detail === "Add New Address"
                    ? `/${businessName}/DiscountDelivery`
                    : currentPage === "discount"
                    ? `/${businessName}/cart`
                    : currentPage === "address-page"
                    ? `/${businessName}/DiscountDelivery`
                    : detail === "Cart Details"
                    ? `/${businessName}/Catalog`
                    : ""
                }
                // lineHeight={'20px'}
                textAlign={"center"}
                // ml={"-10px"}
              >
                {detail}
                {/* {businessTitle?.split(' ').slice(0, 2).join(' ')} */}
              </Typography>
            </Box>
          </>
        ) : (
          <Breadcrumbs separator={<NavigateNextRounded fontSize="small" />}>
            <Typography
              key="1"
              color={blackColor}
              fontSize={{ md: "13", xs: "13px" }}
              component={Link}
              to={`/${businessName}`}
              fontWeight={500}
            >
              {/* {businessTitle?.split(' ').slice(0, 2).join(' ')} */}
              {businessTitle?.length > 30
                ? businessTitle?.slice(0, 30) + "..."
                : businessTitle}
              {/* {nameDetails} */}
              {/* {businessTitle?.split(` `).slice(0, 3).join(" ")} */}
            </Typography>

            {matches
              ? links?.map((link, index) => {
                  return (
                    <Typography
                      key="2"
                      color={
                        index == links?.length - 1
                          ? { xs: orangeColor01, md: greyShade50 }
                          : blackColor
                      }
                      fontSize={{ md: "13", xs: "13px" }}
                      component={index == links?.length - 1 ? "p" : Link}
                      to={link?.disabled ? "" : `/${businessName}${link.to}`}
                      fontWeight={500}
                    >
                      {link.label}
                    </Typography>
                  );
                })
              : links?.map((link, index) => {
                  return (
                    <Typography
                      key="2"
                      color={
                        index == links?.length - 1
                          ? { xs: greyShade50, md: greyShade50 }
                          : blackColor
                      }
                      fontSize={{ md: "13", xs: "13px" }}
                      component={index == links?.length - 1 ? "p" : Link}
                      to={link?.disabled ? "" : `/${businessName}${link.to}`}
                      fontWeight={500}
                    >
                      {link.label}
                    </Typography>
                  );
                })}
          </Breadcrumbs>
        )}

        <Button
          onClick={() => {}}
          sx={{
            textTransform: "uppercase",
            color: { md: orangeColor01, xs: blackColor },
            textDecorationLine: "underline",
            textTransform:'unset',
            fontSize: { md: "14px", xs: 13 },
            fontWeight: { md: 600, xs: 500 },
            padding: 0,
          }}
          endIcon={matches && <NavigateNextRounded />}
          component={Link}
          to={`/${businessName}/catalog`}

        >
          {matches ? breadcrum_ShopMore : "Shop More"}
        </Button>
      </Box>

      {/* <Box mb={3} sx={{ display: { xs: 'block', md: 'none' } }}>
        <Typography
          variant='subtitle2'
          component='h4'
          fontSize='16px'
          color={headingColor}
          ml={1}
          textAlign='center'
        >
          Michelin Auto Accessories Online Store India
        </Typography>

        <Button
          onClick={() => {}}
          fullWidth
          sx={{
            textTransform: 'none',
            color: 'primary',
            textDecorationLine: 'underline',
            fontSize: '15px',
          }}
        >
          Shop More
        </Button>
      </Box> */}
    </>
  );
}

export default BreadcrumbsSection;
