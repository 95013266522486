import { Box, Button, Stack, Typography } from "@mui/material";
import {
  blackColor,
  greyShade002,
  headingColor,
  primaryColor,
  quatityIconColor,
  whiteColor,
} from "configs/styles/muiThemes";
import React from "react";
import { formatString } from "./VariantProduct/VariantHelper";

function ProductVariantData({
  variants,
  onVariantClick,
  activeVariant2,
  selectedVariant,
}) {
  return (
    <Stack gap={1} mt={{ xs: 2, md: 1 }} px={{ md: 0, xs: 2 }}>
      {variants?.map((variant, index) => (
        <Stack gap={1}>
          <Typography
            variant="h4"
            color={headingColor}
            lineHeight={1.6}
            fontSize={14}
            sx={{ textTransform: "capitalize" }}
          >
            {variant?.type == "uom"
              ? "Unit of measure"
              : formatString(variant?.type)}
          </Typography>
          <Box sx={{ display: "flex", gap: 1.5, flexWrap: "wrap" }}>
            {variant?.values?.map((value) =>
              variant?.type == "colour" ? (
                <Button
                  sx={{
                    p: "9px 15px",
                    display: "flex",
                    alignItems: "center",
                    gap: 1.5,
                    borderRadius: "6px",
                    backgroundColor: whiteColor,
                    opacity:
                      index == 1
                        ? activeVariant2?.includes(value?.value)
                          ? 1
                          : 0.2
                        : 1,
                    cursor:
                      index == 1
                        ? activeVariant2?.includes(value?.value)
                          ? "pointer"
                          : "not-allowed"
                        : "pointer",

                    border: "1px solid",

                    borderColor:
                      index === 0
                        ? selectedVariant?.variant1 === value?.value
                          ? primaryColor
                          : greyShade002
                        : selectedVariant?.variant2 === value?.value
                        ? primaryColor
                        : greyShade002,
                  }}
                  disabled={
                    index == 1 && !activeVariant2?.includes(value?.value)
                  }
                  title={value}
                  onClick={() =>
                    index == 1 && !activeVariant2?.includes(value?.value)
                      ? null
                      : onVariantClick(value?.value, index, variant?.type)
                  }
                >
                  <Box
                    sx={{
                      bgcolor: value?.value,
                      width: "22px",
                      height: "22px",
                      borderRadius: "7px",
                    }}
                  />
                  <Typography
                    color={blackColor}
                    fontSize={12.2}
                    lineHeight={"15px"}
                    fontWeight={500}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {value?.name}
                  </Typography>
                </Button>
              ) : (
                <Button
                  sx={{
                    ...styles.variantCard,
                    borderColor:
                      index === 0
                        ? selectedVariant?.variant1 === value
                          ? primaryColor
                          : greyShade002
                        : selectedVariant?.variant2 === value
                        ? primaryColor
                        : greyShade002,
                    cursor:
                      index == 1
                        ? activeVariant2?.includes(value)
                          ? "pointer"
                          : "not-allowed"
                        : "pointer",
                  }}
                  onClick={() =>
                    index == 1 && !activeVariant2?.includes(value)
                      ? null
                      : onVariantClick(value, index, variant?.type)
                  }
                  disabled={index == 1 && !activeVariant2?.includes(value)}
                >
                  <Typography
                    color={blackColor}
                    fontSize={12.2}
                    lineHeight={"15px"}
                    fontWeight={500}
                  >
                    {value}
                  </Typography>
                </Button>
              )
            )}
          </Box>
        </Stack>
      ))}
    </Stack>
  );
}

export default ProductVariantData;

const styles = {
  variantCard: {
    border: "1px solid" + greyShade002,
    p: "9px 15px",
    borderRadius: "6px",
    display: "flex",
    gap: 1,
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: whiteColor,
  },
};
