import {
  Button,
  Container,
  Grid,
  Typography,
  Stack,
  Pagination,
  PaginationItem,
  Box,
  Divider,
  useMediaQuery,
  Skeleton,
} from "@mui/material";
import { useState, useRef } from "react";
import ReviewCard from "./ReviewCard";
import ReviewSectionHeader from "./ReviewSectionHeader";
import ModalCard from "../../components/ModalCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { blackColor, headingColor } from "../../configs/styles/muiThemes";
import EmptyCard from "../../components/EmptyCard";
import { reviewEmptyMessage, reviewEmptyImgUrl } from "../../configs/Constants";
import Loader from "components/Loader";
import ReviewCardLoader from "components/skeletonLoaders/ReviewCardLoader";

const CustomerReviewSubComponent = ({
  data,
  reviewCount,
  connectionCount,
  profileImages,
  count,
  getAllReviewDetails,
  optionData,
  loading,
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [openFullReview, setOpenFullReview] = useState(false);
  const selectedData = useRef(
    data ? (data.allReview !== null ? data.allReview[0] : undefined) : undefined
  );

  const textData = optionData;

  // TO close full review
  const handleFullReviewClose = () => {
    setOpenFullReview(false);
  };

  const handlePagination = (event, value) => {
    getAllReviewDetails(textData, value);
  };

  return (
    <Box minHeight={{xs:'80px', md:'300px'}} >
      {selectedData.current && (
        <ModalCard handleClose={handleFullReviewClose} open={openFullReview}>
          <ReviewCard
            disableDate={true}
            showFullData={true}
            isShownInModal
            data={selectedData.current.data}
            connection={selectedData.current.connection}
            totalReviews={selectedData.current.totalReviews}
          />
        </ModalCard>
      )}

        <ReviewSectionHeader
          count={count}
          data={data}
          isDataLoaded={data && data.allReview !== null && data.allReview.length > 0 }
          preselectedOption={textData}
          onClickMore={(option) => {
            getAllReviewDetails(option);
          }}
        />

      {data && data.allReview.length == 0 && (
        <Box mt={{xs:-2.5, md:1}}>
          <EmptyCard
            message={reviewEmptyMessage}
            imageUrl={
              "https://storage.googleapis.com/bodefaults/shopweb/payments/customer-review.svg"
            }
            iconWidth="68px"
            imgpadding={matches ? "20px" :"0 0 3px 0"}
          />
        </Box>
      )}

      {loading && (
        <>
          {matches ? (
            <ReviewCardLoader />
          ) : (
            <Box>
              <Skeleton variant="rounded" width='auto' height='137px' />
              <Skeleton variant="rounded" width='auto' height='137px' />
            </Box>
          )}
        </>
      )}

      {data &&
        data.allReview !== null &&
        data.allReview.length > 0 &&
        connectionCount &&
        connectionCount.length > 0 &&
        reviewCount &&
        reviewCount.length > 0 &&
        profileImages && (
          <Box>
            <Grid
              container
              spacing={{ xs: 1.5, md: 2.5 }}
              sx={{ display: "flex" }}
            >
              {data.allReview.map((reviewData, index) => {
                const connection = connectionCount.filter(
                  (item) => item.profileId === reviewData.profileId
                );
                const totalReviews = reviewCount.filter(
                  (item) => item.profileId === reviewData.profileId
                );

                const reviewCountData =
                  totalReviews && totalReviews.length > 0
                    ? totalReviews[0].totalReview
                    : 0;
                const connectionCountData =
                  connection && connection.length > 0
                    ? connection[0].connections
                    : 0;
                const image = profileImages.filter(
                  (item) => item.profileId === reviewData.profileId
                );
                return (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      // alignSelf: "stretch",
                      flexGrow: 1,
                    }}
                  >
                    <ReviewCard
                      onClickMore={(fullData) => {
                        selectedData.current = fullData;
                        setOpenFullReview(true);
                        console.log("Hello hit:", fullData);
                      }}
                      data={reviewData}
                      disableDate={false}
                      showFullData={openFullReview}
                      images={image}
                      connection={connectionCountData}
                      totalReviews={reviewCountData}
                      getAllReviewDetails={() => getAllReviewDetails()}
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                      }}
                      // isHelpfulClicked={(reviewId,profileId)=>{dispatch(helpFullReviewPost(reviewId,profileId))}}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        )}

      {/* <InfiniteScroll
          dataLength={data.allReviews.length || 0}
          next={getDiscountData}
          hasMore={
            Math.round(
              Number(discountList?.totalCount) / Number(discountList?.pageSize)
            ) >= Number(discountList?.pageNo)
          }
          loader={
            <Box>
              <ThreeDotLoader />
            </Box>
          }
        >
          <Grid container spacing={2}>
            {data.allReview.map((reviewData, index) => {
              const connection = connectionCount.filter(
                (item) => item.profileId === reviewData.profileId
              )
              const totalReviews = reviewCount.filter(
                (item) => item.profileId === reviewData.profileId
              )

              const reviewCountData =
                totalReviews && totalReviews.length > 0
                  ? totalReviews[0].totalReview
                  : 0
              const connectionCountData =
                connection && connection.length > 0
                  ? connection[0].connections
                  : 0
              const image = profileImages.filter(
                (item) => item.profileId === reviewData.profileId
              )
              return (
                <Grid item xs={12} md={6}>
                  <ReviewCard
                    onClickMore={(fullData) => {
                      selectedData.current = fullData
                      setOpenFullReview(true)
                      console.log('Hello hit:', fullData)
                    }}
                    data={reviewData}
                    disableDate={false}
                    showFullData={openFullReview}
                    images={image}
                    connection={connectionCountData}
                    totalReviews={reviewCountData}
                    getAllReviewDetails={() => getAllReviewDetails()}
                    // isHelpfulClicked={(reviewId,profileId)=>{dispatch(helpFullReviewPost(reviewId,profileId))}}
                  />
                </Grid>
              )
            })}
          </Grid>
        </InfiniteScroll> */}

      {/* ---Pagination--- */}
      {data &&
      data.allReview !== null &&
      data.allReview.length > 0 &&
      data?.totalReview / data?.size > 1 ? (
        <Stack
          spacing={2}
          mt={4}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Pagination
            // count={Math.round(props.totalCount / 40)}
            // color='primary'
            count={data ? Math.ceil(data?.totalReview / 10) : 0}
            page={data ? data?.pageNo : 0}
            renderItem={(item) => (
              <PaginationItem
                components={{
                  next: NextBtn,
                  previous: PreviousBtn,
                }}
                {...item}
              />
            )}
            onChange={handlePagination}
            sx={{ color: blackColor }}
          />
        </Stack>
      ) : null}
      {/* ---Pagination End--- */}
    </Box>
  );
};

export default CustomerReviewSubComponent;

const PreviousBtn = () => {
  return (
    <Button startIcon={<ArrowBackIcon />} sx={styles.btnHover}>
      Prev
    </Button>
  );
};

const NextBtn = () => {
  return (
    <Button endIcon={<ArrowForwardIcon />} sx={styles.btnHover}>
      Next
    </Button>
  );
};

const styles = {
  btnHover: {
    color: blackColor,
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
};
