import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getResellerProfileData } from 'redux/affiliate/AffiliateSlice'
import { updateResellerBankDetailsAPI } from 'services/api_calls/affiliate/AffiliateApi'
// import { updateResellerBankDetailsAPI } from '../../services/api_calls/affiliate/AffiliateApi'
import ShareEarnHeader from '../ShareAndEarn/ShareEarnHeader'
import AffiliateBankDetailsForm from './AffiliateBankDetailsForm'
import { whiteColor } from 'configs/styles/muiThemes'

function AffiliateBankDetailsContent({ isReadOnly }) {
  const navigate = useNavigate()
  const { businessName } = useParams()
  const dispatch = useDispatch()

  const { rellerProfileData } = useSelector((state) => state.affiliate)

  const [loading, setLoading] = useState(false)

  // Banking
  const [bankDetails, setBankDetails] = useState({
    accountNo: '',
    ifscCode: '',
    bankName: '',
    accountType: 'Choose Account Type',
    panNumber: '',
    errors: {
      accountNo: '',
      ifscCode: '',
      bankName: '',
      accountType: '',
      panNumber: '',
    },
  })

  useEffect(() => {
    setBankDetails({
      accountNo: isReadOnly
        ? rellerProfileData?.details?.bankInfo?.accountNo
        : '',
      ifscCode: isReadOnly ? rellerProfileData?.details?.bankInfo?.ifsc : '',
      bankName: isReadOnly
        ? rellerProfileData?.details?.bankInfo?.bankName
        : '',
      accountType: isReadOnly
        ? rellerProfileData?.details?.bankInfo?.accountType
        : 'Choose Account Type',
      panNumber: isReadOnly ? rellerProfileData?.details?.panNo : '',
      errors: {},
    })
    return () => {}
  }, [isReadOnly, rellerProfileData])

  const handleValueChange = (state, setState, name, value) => {
    setState({
      ...state,
      [name]: value,
    })
  }

  // Validating Banking Form and Update
  const validateBankingForm = async () => {
    let isError = false
    const errors = {}

    if (!bankDetails?.accountNo) {
      errors.accountNo = 'Please enter account number'
      isError = true
    }

    if (!bankDetails?.ifscCode || bankDetails?.ifscCode?.length < 11) {
      errors.ifscCode = 'Please enter IFSC code'
      isError = true
    }

    if (!bankDetails?.bankName) {
      errors.bankName = 'Please enter bank name'
      isError = true
    }

    if (bankDetails?.accountType == 'Choose Account Type') {
      errors.accountType = 'Please choose account type'
      isError = true
    }

    const panRegex = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;

    if (!bankDetails?.panNumber || !panRegex.test(bankDetails?.panNumber)) {
      errors.panNumber = 'Please enter a valid pan number'
      isError = true
    }

    if (isError) {
      setBankDetails({ ...bankDetails, errors })
      return
    }

    isError = false
    setBankDetails({ ...bankDetails, errors: {} })

    const response = await updateResellerBankDetailsAPI({
      bankDetails,
      key: rellerProfileData?.details?.id,
    })

    if (response) {
      // console.log(response);
      dispatch(getResellerProfileData())
      navigate(`/${businessName}/affiliateHome`)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  return (
    <Box backgroundColor={whiteColor} mt={1}>
      <ShareEarnHeader
        title='Banking Details'
        hideActiveBtn={rellerProfileData?.details?.bankInfo?.accountNo ? false : true}
        handleBackClick={() => {
          if (rellerProfileData?.details) {
            navigate(`/${businessName}/affiliateHome`)
          } else {
            navigate(`/${businessName}/affiliates`)
          }
        }}
        handleSettingClick={() => {}}
        handleSearchClick={() => {}}
      />

      <AffiliateBankDetailsForm
        bankDetails={bankDetails}
        onValueChange={(name, value) => {
          handleValueChange(bankDetails, setBankDetails, name, value)
        }}
        // setCurrentPage={setCurrentPage}
        handleNext={validateBankingForm}
        isReadOnly={isReadOnly}
      />
    </Box>
  )
}

export default AffiliateBankDetailsContent
