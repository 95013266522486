import React from "react";
import {
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box } from "@mui/material";
import {
  facebookBGColor,
  linkedInBGColor,
  telegramBGColor,
  twitterBGColor,
  whatsappBGColor,
  whiteColor,
  greyColor3,
} from "configs/styles/muiThemes";

function SocialShareVertical({ url, quote, back, showCopy, onCopyClick }) {
  return (
    <Box sx={styles.shareCard}>
      {showCopy && (
        <Box sx={styles.iconStyle(whiteColor)} onClick={() => onCopyClick()}>
          <ContentCopyIcon sx={{ color: greyColor3 }} />
        </Box>
      )}

      <WhatsappShareButton
        url={url}
        quote={quote}
        hashtag=""
        onClick={() => back()}
      >
        <Box sx={styles.iconStyle(whatsappBGColor)}>
          <WhatsAppIcon />
        </Box>
      </WhatsappShareButton>

      <FacebookShareButton
        url={url}
        quote={quote}
        hashtag=""
        onClick={() => back()}
      >
        <Box sx={styles.iconStyle(facebookBGColor)}>
          <FacebookOutlinedIcon />
        </Box>
      </FacebookShareButton>

      <LinkedinShareButton
        url={url}
        quote={quote}
        hashtag=""
        onClick={() => back()}
      >
        <Box sx={styles.iconStyle(linkedInBGColor)}>
          <LinkedInIcon />
        </Box>
      </LinkedinShareButton>

      <TwitterShareButton
        url={url}
        quote={quote}
        hashtag=""
        onClick={() => back()}
      >
        <Box sx={styles.iconStyle(twitterBGColor)}>
          <TwitterIcon />
        </Box>
      </TwitterShareButton>

      <TelegramShareButton
        url={url}
        quote={quote}
        hashtag=""
        onClick={() => back()}
      >
        <Box sx={styles.iconStyle(telegramBGColor)}>
          <TelegramIcon />
        </Box>
      </TelegramShareButton>
    </Box>
  );
}

export default SocialShareVertical;

const styles = {
  shareCard: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    backgroundColor: whiteColor,
    padding: "8px 12px",
    borderRadius: "15px",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
  },

  iconStyle: (bgColor) => ({
    width: "30px",
    height: "30px",
    backgroundColor: bgColor,
    borderRadius: "7px",
    fontSize: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: whiteColor,
    marginBottom: "4px",
    cursor: "pointer",
  }),
};
