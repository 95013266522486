import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getActiveBusiness,
  getBusinessReviewCount,
  getSocialActivityOfBusiness,
} from '../../redux/header/headerSlice'
import { getUserName } from 'utils/authHelpers'
import { getRewardPointsDetails } from 'redux/checkout/CheckoutSlice'
import Navbar from '../../components/layout/navbar'
import { getOverviewDetails } from 'redux/overview/overviewSlice'
import { getReviewSummaryDetails } from 'redux/reviews/reviewsSlice'
import { updateIsLogin } from 'redux/authRedux/authSlice'
import {
  getAffiliateValidity,
  getRefRatesByBusinessID,
  getResellerProfileData,
} from 'redux/affiliate/AffiliateSlice'

const Header = ({
  value,
  showLoginForm,
}) => {
  const dispatch = useDispatch()
  const { businessDetails, social, total } = useSelector(
    (state) => state.header
  )
  const { reviewSummary } = useSelector((state) => state.review)
  const { overviewDetails } = useSelector((state) => state.overview)
  const { commercialDetails } = useSelector((state) => state.cart)

  const { isUserLogin, loginDetails } = useSelector((state) => state.auth)
  const { rewardpoints } = useSelector((state) => state.checkout)
  const { businessId } = useSelector((state) => state.rootSlice)
  const businessInfo = JSON.parse(sessionStorage.getItem('businessInfo'))

  const { businessAffiliateData, affiliateValidity } = useSelector(
    (state) => state.affiliate
  )

  const [isLoggedIn, setIsLoggedIn] = useState(
    getUserName().length === 0 ? false : true
  )
  const [showLogin, setShowLogin] = useState(false)

  useEffect(() => {
    if (!businessDetails) {
      dispatch(getActiveBusiness())
    }
    if (!overviewDetails) {
      dispatch(getOverviewDetails())
    }
  }, [])

  useEffect(() => {
    if (businessId) {
      if (!social) {
        dispatch(getSocialActivityOfBusiness())
      }
      if (!total) {
        dispatch(getBusinessReviewCount())
      }
      if (reviewSummary?.length === 0) {
        dispatch(getReviewSummaryDetails())
      }

      // Check business Affiliate enrolled
      if (!businessAffiliateData) {
        dispatch(getRefRatesByBusinessID(businessId?.businessId))
      }
    }
  }, [businessId])

  useEffect(() => {
    if (loginDetails) {
      setIsLoggedIn(true)
      dispatch(getRewardPointsDetails())
      dispatch(getSocialActivityOfBusiness())
      dispatch(updateIsLogin())
    } else {
      dispatch(updateIsLogin())
    }
  }, [loginDetails])

  useEffect(() => {
    if (isUserLogin) {
      dispatch(getResellerProfileData())
      if (!affiliateValidity) {
        dispatch(getAffiliateValidity(businessId?.businessId))
      }
    }
    return () => {}
  }, [isUserLogin])

  return (
    <>
      {businessInfo && (
        <Navbar
          value={value}
          logoUrl={businessInfo?.[0]?.logoURL}
          showLoginForm={showLoginForm || showLogin}
          // rewardpoints={rewardpoints}
          followStatus={social?.status}
        />
      )}
    </>
  )
}
export default Header
