import { Box, Typography } from "@mui/material";
import {
  blackColor,
  greenColor05,
  greyShade70,
  greyShade71,
  lightGreen04,
  whiteColor,
} from "configs/styles/muiThemes";
import React, { useEffect, useState } from "react";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";

const SelectedBranchCardMinimal = ({
  branchInfo,
  getAddress,
  distances,
  handleMouseEnter,
  handleMouseLeave
}) => {
  const currentBusinessId = sessionStorage.getItem("businessId");
  const [activeBusiness, setActiveBusiness] = useState(null);

  const getSelectedBusiness = () => {
    const selectedBusiness = branchInfo?.find(
      (branch) => branch?.branchId == currentBusinessId
    );

    if (selectedBusiness) {
      setActiveBusiness({
        ...selectedBusiness,
        distanceIndex: branchInfo.findIndex(
          (branch) => branch?.branchId == currentBusinessId
        ),
      });
    }
  };

  useEffect(() => {
    if (branchInfo?.length > 0) {
      getSelectedBusiness();
    }

    return () => {};
  }, [branchInfo]);

  return (
    <Box
      sx={{
        ...styles.card,
      }}
      onMouseEnter={() => handleMouseEnter(activeBusiness)}
      onMouseLeave={handleMouseLeave}
    >
      <Box p="4px 10px" display="flex" alignItems="center" gap="10px" width={{xs:'auto', md:"280px"}}>
        <Box component="img" src={activeBusiness?.logo} sx={styles.logoImg} />

        <Box>
          <Typography
            fontSize={12.5}
            fontWeight={600}
            color={blackColor}
            lineHeight={{xs:'22px', md:"24px"}}
            mb={{xs:'0px !important', md:"2px !important"}}
          >
            {activeBusiness?.businessName}
          </Typography>

          <Box display="flex" alignItems={{xs:'flex-start', md:"center"}} gap="4px" ml={-0.25}>
            <PlaceOutlinedIcon
              sx={{ fontSize: "14px", color: greyShade71, mt: -0.06, display:{xs:'none', md:'block'} }}
            />
            <Typography
              fontSize={11.5}
              fontWeight={400}
              color={greyShade71}
              lineHeight={{xs:'16px', md:"13px"}}
            >
              {getAddress(activeBusiness?.businessArea || [])}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={styles.distanceInfoBox}>
        <PlaceOutlinedIcon sx={{ fontSize: "16px", color: greenColor05 }} />
        <Typography
          fontSize={10.5}
          fontWeight={600}
          color={greenColor05}
          lineHeight="13px"
          mt="4px !important"
        >
          {distances?.[activeBusiness?.distanceIndex] || "-"} KM
        </Typography>
      </Box>
    </Box>
  );
};

export default SelectedBranchCardMinimal;

const styles = {
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: `1px solid rgba(0,0,0,0.1)`,
    bgcolor: whiteColor,
    borderRadius: "7px",
    overflow: "hidden",
    mr: {xs:0 ,md:2},
    cursor: "pointer",

    // "&:hover": {
    //   border: `1px solid ${greenColor05}`,
    // },
  },

  logoImg: {
    width: "40px",
    height: "40px",
    borderRadius: "4px",
    border: `1px solid ${greyShade70}`,
  },

  distanceInfoBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    bgcolor: {xs: whiteColor ,md:lightGreen04},
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "8px 0px 0px 8px",
    height: "58px",
    width: "83px",
  },
};
