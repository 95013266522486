export const dealsEmptyMessage =
  'No Active Deals/Coupons. Please check back later!'
export const dealsEmptyImgUrl =
  "https://storage.googleapis.com/bodefaults/shopweb/home/discount-empty.svg";
export const reviewEmptyMessage = 'No customer reviews yet'
export const reviewEmptyImgUrl =
  'https://storage.googleapis.com/bodefaults/shopweb/Empty/customer_review_empty.svg'
export const catalogEmptyMessage =
  'We are still building our store, check back later for exciting product range'
export const catalogEmptyImgUrl = 'https://storage.googleapis.com/bodefaults/shopweb/shopping-basket.png'

export const selectDeliveryAddressMsg = 'Please select a Delivery Address'
export const selectDeliveryMethodMsg = 'Please select a Delivery Method'
export const transactionErrorMsg =
  "Transaction can't be processed, Please split into multiple orders of less than ₹500000 each"
export const orderAmtLimitErrorMsg = `Order cannot be processed. Please split into multiple orders of less than ₹500000 each`

export const updateLatLongMsg = `Please update the current location in the selected address`

export const GOOGLE_MAPS_API_KEY = "AIzaSyAzzu692ouH2AXFzesPIVLfHeO8RmhN44Q";

export const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1250,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 750,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
      },
    },
  ],
}

export const allOrdersEmptyMessage = 'It’s time to place your first order!'
export const OrderEmptyDescription = 'Explore our amazing range of products'
export const allOrdersEmptyImgUrl = 'https://storage.googleapis.com/bodefaults/shopweb/openempty.png'

export const openOrdersEmptyMessage = 'No open orders!'
export const openOrdersEmptyImgUrl = 'https://storage.googleapis.com/bodefaults/shopweb/openempty.png'

export const completedOrdersEmptyMessage = 'No completed orders!'
export const completedOrdersEmptyImgUrl =
  'https://storage.googleapis.com/bodefaults/shopweb/Empty/order_completed.svg'

export const cancelledOrdersEmptyMessage = 'No cancelled orders!'
export const cancelledOrdersEmptyImgUrl =
  'https://storage.googleapis.com/bodefaults/shopweb/Empty/order_cancel.svg'

export const otherOrdersEmptyMessage = 'No other orders!'
export const otherOrdersEmptyImgUrl = 'https://storage.googleapis.com/bodefaults/shopweb/shopping-basket.png'

export const emptyOrderBtnText = 'Continue Shopping'

export const cardEmptyImgUrl =
  'https://storage.googleapis.com/bodefaults/shopweb/Empty/cart_empty.svg'
export const cardEmptyMsg = 'Your shopping cart is empty'
export const cardEmptyDescriptionMsg = 'Let’s get it rolling'
export const cardEmptyBtnText = 'EXPLORE OUR PRODUCTS'

export const cardEmptyGuestImgUrl =
  'https://storage.googleapis.com/bodefaults/shopweb/Empty/cart_empty_guest.svg'
export const cardEmptyGuestMsg = 'Missing Cart items?'
export const cardEmptyGuestDescriptionMsg =
  'Login to see previously added items'
export const cardEmptyGuestBtnText = 'LOGIN/SIGNUP'

export const searchEmptyImgUrl = 'https://storage.googleapis.com/bodefaults/shopweb/Product_No_Image.svg'
export const searchEmptyMsg = 'No products matching '

export const noProductImgUrl = 'https://storage.googleapis.com/bodefaults/shopweb/no-product-image.svg'
// export const noProductImgUrl = 'https://storage.googleapis.com/bodefaults/shopweb/Product_No_Image.svg'

export const productPriceChangeMsg =
  'Product price has been updated. Please accept or remove from cart. Updated price ₹'
export const productNotAvailabelMsg =
  'Product is currently not available. Please remove from the cart'
export const OnlineOrderSuspendedMsg =
  'Online ordering has been temporarily suspended. Please check back later'

export const reviewLoginAlertImgUrl =
  'https://storage.googleapis.com/bodefaults/shopweb/reviews/user_review.svg'
export const reviewLoginMsg = 'Please login to write/update your review'
export const topBarMsg = 'Buy online directly from us! Enjoy great savings!'
export const businessClosedMsg = 'This business/store is temporarily closed.'

export const onlineOrderClosedMsg =
  'Online ordering is temporarily paused. Please check back later'
export const commingSoonMsg = `We are currently adding exciting range of products and discounts to serve you better. Please check back later.`

export const orderLimitAmount = 500000

export const siteBaseURL = 'https://shop.costbo.com/'

export const contactLabel = 'Contact'
export const costBoEmailText = 'Email Us'
export const costBoContactNumberText = 'Contact Us'
export const goToCartText = 'GO TO CART'

export const inputLabel_OneUpperChar = 'One Upper case character'
export const inputLabel_OneSpecialChar = 'One Special character'
export const inputLabel_MinChar = '8 Characters minimum'
export const inputLabel_OneNum = 'One Number'

export const enterPhoneNumberMsg = 'Enter Phone Number'
export const enterPasswordMsg = 'Enter Password'
export const continueHasGuestText = 'CONTINUE AS GUEST'
export const alreadyHaveAccountMsg = 'Already have an account?'
export const notReceivedCodeMsg = 'Not received your code?'

export const inputError_AlreadyRegistered =
  'You have already registered CostBo account. Please sign in'
export const inputError_PasswordRequirementNotMet =
  'Password requirements not met'
export const inputError_NumberPasswordNotMatch =
  'Mobile number and password do not match. Please try again'
export const inputError_NotRegistered =
  'Not a registered Mobile number. Please register in CostBo'
export const inputError_OTPIncorrent = 'Incorrect OTP ReEnter'
export const inputError_EmailNotMatch =
  'Email and Re-enter email does not match'
export const inputError_EmailUsedAlready = 'Email address already in use'
export const inputError_EnterValidKey = 'Please enter a valid Reset key'
export const inputError_EnterValidPhone = 'Please enter a valid Phone Number'

export const navBar_numberRegisteredMsg =
  'Your phone number is already registered. Please login'
export const navBar_BuyOnlineMsg =
  'Buy online directly from us! Enjoy great savings!'
export const navBar_Menu = 'Menu'
export const navBar_MenuHome = 'Home'
export const navBar_MenuCatalog = 'Catalog'
export const navBar_MenuDealsCounpons = 'Deals/Coupons'
export const navBar_MenuCounpons = 'Coupons'
export const navBar_MenuOverview = 'Overview'
export const navBar_MenuReview = 'Review'
export const navBar_MenuLogout = 'Logout'
export const navBar_MenuLoginSignup = 'Login/Signup'

export const banner_Welcome = 'Welcome To'
export const banner_Reviews = 'reviews'
export const banner_Followers = 'followers'
export const banner_Views = 'views'
export const banner_Cashback = 'Available Cashback'
export const banner_LoginText = 'Login to Check'

export const sideBar_MainMenu = 'Main Menu'
export const sideBar_CategoryHeading = 'Shop By Category'
export const sideBar_Signout = 'Logout'

export const breadcrum_ShopMore = 'SHOP MORE'

export const footer_PoweredBy = 'Secure E-Commerce powered by'
export const footer_AdvantagesHeading =
  'Advantages of ordering from Webstores powered by CostBo'
export const footer_Advantage1 =
  'Buy online directly from us, Lower prices every time!'
export const footer_Advantage2 =
  'Enjoy Loyalty points & Coupons for more savings!'
export const footer_Advantage3 =
  'Safe, hassle-free shopping & Secure payment gateways.'
export const footer_Advantage4 =
  'Guaranteed door-step delivery with shipment tracking.'
export const footer_PrivacyPolicy = 'Privacy & Policy'
export const footer_TermsLabel = 'Terms Of Use'
export const footer_CopyrightText = '© 2024 All Copyrights Reserved by CostBo'

export const InfoCard_DiscountInfo =
  'Available discounts, if any, can be applied after checkout'
export const InfoCard_DeliveryChargeInfo =
  'Actual delivery charges will be computed based on shipping address after checkout.'

export const orders_AllOrder = 'All Order'
export const orders_TotalOrder = 'Total Orders'
export const orders_PendingOrders = 'Pending Orders'
export const orders_AvailableCashback = 'Available Cashback'
export const orders_OrderIDLabel = 'ORDER #'
export const orders_Quantity = 'Quantity: '
export const orders_TotalPriceLabel = 'Total Price: Rs '
export const orders_ViewLabel = 'VIEW DETAILS'
export const orders_TrackLabel = 'Track'

export const authModal_Heading1 = 'This website is powered by'
export const authModal_Heading2 =
  'Verified, Secured, One Login for multiple online stores'

export const forgotPassword_Title = 'Forgot your password?'
export const forgotPassword_SubTitle =
  'Enter your Mobile number and we’ll help you reset your password.'

export const otpPage_Title = 'Two-Step Verification'
export const otpPage_SubTitle =
  'Please enter the OTP (one time password) to verify your account. A Code has been sent to'

export const register_Title = 'Register Details'
export const register_EnterName = 'Enter Name'
export const register_EnterEmail = 'Enter Email'
export const register_ReEnterEmail = 'Re-Enter Email'
export const register_EnterNewPassword = 'Enter New Password'

export const resetPassword_Title = 'Reset Password'
export const resetPassword_SubTitle = 'Password reset key has been sent to'
export const resetPassword_EnterKey = 'Reset Key from your Email (5 digits)'
export const resetPassword_EnterPassword =
  'Enter New Password (Min 8 chars Max 25 chars)'

export const AdditionalSaving_CashbackTitle = 'Available Cashback'
export const AdditionalSaving_CashbackDescription =
  'You will be able to claim this amount during checkout'

export const FutureSaving_Title = 'Future Savings'
export const FutureSaving_SubTitle = '% Cashback on this Purchase!'
export const FutureSaving_Description =
  'This amount will be added to your wallet when your order is placed. (Not applicable with Coupon Codes)'

export const ProductFilter_Info = 'Select a Category and click APPLY'

export const orderConfirmation_Info =
  'An email confirmation has been sent to your registered Email Id: '

export const dealsDiscounts_Title = 'Awesome Discounts, Just a click away'
export const dealsDiscounts_SubTitle =
  'Use coupon codes at checkout, Save More!'
export const dealsDiscounts_ShareMgs =
  'Share the savings with friends and family'

export const dealsDiscounts_ApplyStep1 =
  'Add products to your cart and checkout'
export const dealsDiscounts_ApplyStep2 = 'Go to Discount/Delivery page'
export const dealsDiscounts_ApplyStep3 =
  'Under ’Available Discounts’ section enter the coupon code relevant to your order'
export const dealsDiscounts_ApplyStep4 = 'Click Apply'
export const dealsDiscounts_ApplyStep5 =
  'Discount Amount will be applied towards the Order Total'
export const dealsDiscounts_ApplyStep6 = 'Shipping charges are excluded'
export const dealsDiscounts_ApplyStep7 = 'Only 1 discount applicable per order'

export const discountCard_CashbackAmtError = 'Please enter the cashback amount'
export const discountCard_CashbackAmtGreaterErr =
  'Please enter the cashback amount greaterthan ₹0'
export const discountCard_RedeemAmtGreaterErr =
  'Redeemed amount should not exceed the available cashback'
export const discountCard_RedeemAmtGreaterOrderAmtErr =
  'Redeemed cashback cannot exceed Order Amount'
export const discountCard_CongratsMsg = 'Congrats! Cashback applied ₹'

export const couponCard_SelectMsg = 'Select Coupon Code'
export const couponCard_SelectErrMsg = 'Please Select a coupon code'
export const couponCard_InvalidErrMsg = 'Invalid Coupon Code'
export const couponCard_CongratsMsg = 'Congrats! Offer applied ₹'

export const firstTimeBuyerCard_MinOrderAmtErr =
  'Minimum order amount should be ₹'
export const firstTimeBuyerCard_DiscountExceedOrderAmtErr =
  'Discount cannot exceed Order Amount'

export const instantCashCard_SelectErr = 'Please select instant discount'
export const instantCashCard_MinAmtErr = 'Minimum order amount should be ₹'
export const instantCashCard_DiscountExceedErr =
  'Discount cannot exceed Order Amount'

export const resellerCard_EmptyCouponErr = 'Please enter a Coupon code'
export const resellerCard_InvalidCouponErr = 'Invalid Coupon code'
export const resellerCard_CouponExpiredErr = 'The coupon has expired'

export const availabelDiscount_Info =
  'Click on radio button to choose a specific Discount Type'
export const availabelDiscount_AlertInfo =
  '*Minumum purchase amount excludes shipping cost, 1 discount applicable per order'

export const addressCard_DeleteAddressInfo =
  'Are you sure, you want to delete this address?'

export const addressForm_EnterName = 'Enter Name'
export const addressForm_NameError = 'Please Enter Name'

export const addressForm_EnterMail = 'Enter mail'
export const addressForm_MailError = 'Please Enter Valid Email'

export const addressForm_EnterNumber = 'Contact Number'
export const addressForm_NumberError = 'Enter Contact Number'

export const addressForm_AlternateNumber = 'Recipient Number'
export const addressForm_AlternateNumberError = 'Enter Recipient Number'

export const addressForm_Pincode = 'Enter Pincode'
export const addressForm_PincodeError = 'This pincode is not serviceable'

export const addressForm_HouseNo = 'House/Flat No, Street, Area'
export const addressForm_HouseNoError = 'Please Enter Address'

export const addressForm_Landmark = 'Ex: Behiend Regal Cinema'

export const addressForm_CityNameError = 'Enter City Name'

export const addressForm_SelectAddressType = 'Please Select a type'

export const LATITUDE = '12.9634'
export const LONGITUDE = '77.5855'

export const loginBgImgUrl =
  'https://storage.googleapis.com/bodefaults/web-common-asset/login/login-bg.jpg'

export const finboxApplyStatusTitle = 'Hassle free working capital in minutes'
export const finboxApplyStatusmsg = 'No interest, upto 1 month*.'
export const finboxApplyStatusmsg2 =
  'GST/PAN, Aadhar needed, One time verification, Upto 10L revolving line of credit'
export const finboxIncompleteStatusTitle = 'Complete your credit application'
export const finboxIncompleteStatusmsg =
  'You are just few steps away from receiving working capital'
export const finboxApprovedStatusTitle =
  'Your credit application has been approved'
export const finboxApprovedStatusmsg =
  'Just complete last few steps to avail the funds'
export const finboxRejectedStatusTitle =
  'Sorry, your credit application has been rejected'
export const finboxRejectedStatusmsg =
  'We can’t give you a credit right now. Please apply again after 3 months'
export const finboxActiveStatusTitle =
  'Buy hassle free with your available credit line'
export const finboxActiveStatusmsg = 'Check the details of your active loan'
export const finboxDueStatusTitle = 'Your credit repayment is due soon'
export const finboxDueStatusmsg = 'Pay now to avoid additional interest'
export const finboxOverDueStatusTitle = 'Your credit repayment is over due'
export const finboxOverDueStatusmsg =
  'Pay immediately to avoid late fees and credit deactivation'
export const finboxReApplyStatusTitle =
  'Re apply for Hassle free working capital in minutes'
export const finboxReApplyStatusmsg = 'No interest, upto 1 month*'
export const finboxClosedStatusTitle =
  'Your credit line has been temporarily inactivated.'

export const applyImg = 'https://storage.googleapis.com/bodefaults/shopweb/apply.svg'
export const approvedImg = 'https://storage.googleapis.com/bodefaults/shopweb/approved.svg'
export const incompleteImg = 'https://storage.googleapis.com/bodefaults/shopweb/incomplete.svg'
export const rejectedImg = 'https://storage.googleapis.com/bodefaults/shopweb/rejected.png'
export const dueImg = 'https://storage.googleapis.com/bodefaults/shopweb/due.svg'
export const overDueImg = 'https://storage.googleapis.com/bodefaults/shopweb/overdue.svg'
export const reApplyImg = 'https://storage.googleapis.com/bodefaults/shopweb/overdue.svg'

export const applyMsg = 'Hassle free working capital in minutes'
export const approvedMsg = 'Complete your credit application'
export const incompleteMsg = 'Complete your credit application'
export const rejectedMsg = 'Your credit application has been rejected'
export const activeMsg = 'Buy hassle free with your available credit line'
export const dueMsg = 'Your credit repayment is due soon'
export const overDueMsg = 'Your credit repayment is overdue'

export const unicomInventoryErrMsg = "Some items in your cart have been updated. Please review your cart for more details."
export const cartMergeAlert = 'Previously saved items have been added to the cart. Please review'