import {
  lightBlueColor01,
  lightOragne01,
  lightPurple01,
} from 'configs/styles/muiThemes'

export const SERVICE_INFO = [
  {
    icon: 'https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/icon-1.svg',
    title: 'Buy Online Directly From Us,',
    subtitle: 'Lower Prices Every Time!',
  },
  {
    icon: 'https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/icon-2.svg',
    title: 'Enjoy Loyalty Points & Coupons',
    subtitle: 'For More Savings!',
  },
  {
    icon: 'https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/icon-3.svg',
    title: 'Safe, Hassle-Free Shopping &',
    subtitle: 'Secure Payment Gateways',
  },
  {
    icon: 'https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/icon-4.svg',
    title: 'Guaranteed Door-Step Delivery',
    subtitle: 'With Shipment Tracking',
  },
]

export const CONTACT_INFO = [
  {
    label: `24x7 \nSupport Chat`,
    icon: 'https://storage.googleapis.com/bodefaults/shopweb/supportANDFooter/support.svg',
    bgcolor: lightPurple01,
  },
  {
    label: `Email \nUs`,
    icon: 'https://storage.googleapis.com/bodefaults/shopweb/supportANDFooter/email.svg',
    bgcolor: lightBlueColor01,
  },
  {
    label: `Contact \nUs`,
    icon: 'https://storage.googleapis.com/bodefaults/shopweb/supportANDFooter/phone.svg',
    bgcolor: lightOragne01,
  },
]

export const FOOTER_SOCIAL_ICONS = [
  { iconUrl: 'https://storage.googleapis.com/bodefaults/shopweb/supportANDFooter/facebook.svg' },
  { iconUrl: 'https://storage.googleapis.com/bodefaults/shopweb/supportANDFooter/twitter.svg' },
  { iconUrl: 'https://storage.googleapis.com/bodefaults/shopweb/supportANDFooter/linkedin.svg' },
  { iconUrl: 'https://storage.googleapis.com/bodefaults/shopweb/supportANDFooter/pinterest.svg' },
]

export const FOOTER_MENU = [
  { menuLink:'Privacy & Policy', link:'/privacyPolicy' },
  { menuLink:'Terms Of Use', link:'/termsAndConditions' },
  { menuLink:'Returns Policy', link:'/return-policy' },
  { menuLink:'Cancellation Policy', link:'/cancellation-policy#cancellationPolicy' },
]
