import { Box, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";
import { blackColor, couponColor, whiteColor } from "../../configs/styles/muiThemes";

function MainProductCard({ products }) {
  if (products.length === 0) return null;

  return (
    <Box sx={styles.mainProductCard}>
      {products.map((item, index) => {
        return (
          <ListItem sx={{ py: 0,color:blackColor }}>
            <ListItemText
              sx={{
                display: "list-item",
              }}
              key={index + "main_product"}
              primary={
                <Typography variant="subtitle1" fontSize={{xs:12.5, md:12.5}} color={blackColor}>
                  {item.productName}
                </Typography>
              }
            />
          </ListItem>
        );
      })}
    </Box>
  );
}

export default MainProductCard;

const styles = {
  mainProductCard: {
    backgroundColor: whiteColor,
    // boxShadow: {md:"0px 2px 8px rgb(94 94 94 / 16%)",xs:0},
    padding: {md:"8px 15px",xs:0},
    borderRadius: {md:"12px",xs:0},
        border: {md:`1px solid #e6ebf4`}
  },
};
