import {
  Box,
  Button,
  Grid,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useRef, useState } from "react";
import CategoryGroupHeader from "./CategoryGroupHeader";
import ProductCard from "./ProductCard";
import { automobileProducts } from "../../utils/demoData";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { getOutOfStock } from "utils/productHelper";
import Slider from "react-slick/lib/slider";
import ProductMobileCard from "./ProductMobileCard";

function FilteredCategory({
  selectedCategory,
  cartData,
  cartId,
  onQtyUpdate,
  onAddProduct,
  onRemoveProduct,
  isUnicommerce,
  checkUnicomInventory,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box>
      <CategoryGroupHeader
        title={selectedCategory?.title}
        totalProduct={selectedCategory?.products?.length}
        noAction
      />
      <Grid
        container
        columnSpacing={{ md: 1, xs: 1 }}
        rowSpacing={{ md: 2.5, xs: 1 }}
        pt={1.5}
        pl={{ xs: 1, md: 0 }}
        pr={{ xs: 0, md: 1 }}
        mb={{ xs: 10, md: 0 }}
      >
        {selectedCategory?.products?.map((item, index) => {
          if (item?.variantInfo?.masterVariant) return null;

          return (
            <Grid item xs={12} sm={3} md={2.4}>
              {matches ? (
                <ProductCard
                  img={item?.productPhoto?.[0]?.docURL}
                  price={item.productSalePrice}
                  oldPrice={item.productOriginalPrice}
                  description={item.productName}
                  discount={item.percentageOff}
                  style={{ my: 1.5 }}
                  // isOutOfStock={getOutOfStock(item)}
                  isOutOfStock={
                    isUnicommerce
                      ? checkUnicomInventory(item)
                      : getOutOfStock(item)
                  }
                  isComingSoon={item.commerceEnabled === "NO"}
                  minQty={item.minOrderQuantity}
                  maxQty={item.maxOrderQuantity}
                  currencySymbol={
                    item.currencySymbol ? "₹" : item.currencySymbol
                  }
                  cartData={cartData}
                  product={item}
                  cartId={cartId}
                  onQtyUpdate={(data) => {
                    onQtyUpdate(data);
                  }}
                  onAddProduct={(data) => {
                    onAddProduct(data);
                  }}
                  onRemoveProduct={onRemoveProduct}
                  subscribe={item?.subscription?.status === "active"}
                  availability={
                    item?.availability?.[0]?.toLowerCase() === "yes"
                  }
                  subscription={item?.subscription}
                  variant={item?.variantInfo?.masterProductVariantId}
                  variantCount={item?.count}
                />
              ) : (
                <ProductMobileCard
                  productid={item?.id}
                  img={item?.productPhoto?.[0]?.docURL}
                  price={item.productSalePrice}
                  oldPrice={item.productOriginalPrice}
                  description={item.productName}
                  discount={item.percentageOff}
                  style={{ my: 1.5 }}
                  // isOutOfStock={getOutOfStock(item)}
                  isOutOfStock={
                    isUnicommerce
                      ? checkUnicomInventory(item)
                      : getOutOfStock(item)
                  }
                  isComingSoon={item.commerceEnabled === "NO"}
                  minQty={item.minOrderQuantity}
                  maxQty={item.maxOrderQuantity}
                  currencySymbol={
                    item.currencySymbol ? "₹" : item.currencySymbol
                  }
                  cartData={cartData}
                  product={item}
                  cartId={cartId}
                  onQtyUpdate={(data) => {
                    onQtyUpdate(data);
                  }}
                  onAddProduct={(data) => {
                    onAddProduct(data);
                  }}
                  onRemoveProduct={onRemoveProduct}
                  subscribe={item?.subscription?.status === "active"}
                  availability={
                    item?.availability?.[0]?.toLowerCase() === "yes"
                  }
                  subscription={item?.subscription}
                  variant={item?.variantInfo?.masterProductVariantId}
                  variantCount={item?.count}
                />
              )}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

export default FilteredCategory;
