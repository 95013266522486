import { Box, Button, Container, Typography } from '@mui/material'
import { blackColor, whiteColor } from 'configs/styles/muiThemes'
import React from 'react'

function HeroSection() {
  return (
    <Box
      sx={{
        backgroundSize: "100% 100%",
        backgroundColor: "#f3f3f8",
        paddingTop: { md: "130px", xs: 0 },
        marginBottom: "30px",
      }}
    >
      <Container maxWidth={"1350px"}>
        <Box
          sx={{
            display: "flex",
            msFlexWrap: "wrap", // IE specific prefix
            flexWrap: "wrap",
            flexDirection: { xs: "column", md: "row" },
            marginRight: { md: "35px", xs: 0 },
            marginLeft: { md: "35px", xs: 0 },
            pb: "30px",
          }}
        >
          <Box
            sx={{
              flex: { md: "0 0 41.666667%", xs: 1 },
              maxWidth: { md: "41.666667%", xs: 1 },
            }}
            display={"flex"}
            flexDirection={"column"}
            // alignItems={{md:"flex-start",xs:"center"}}
          >
            <Typography
              sx={{
                lineHeight: { md: "50px", xs: "30px" },
                fontSize: { md: "37px", xs: "22px" },
                marginTop: { md: "80px", xs: "90px" },
                color: "#000",
                fontWeight: 600,
                marginBottom: "10px",
                textAlign: { xs: "center", md: "left" },

                // paddingRight: { md: "0px", xs: 0 },
              }}
            >
              Enjoy a whole new shopping experience
            </Typography>
            <Typography
              sx={{
                lineHeight: { md: "34px", xs: "23px" },
                fontSize: { md: "20px", xs: "14px" },
                color: "#000",
                fontWeight: 400,
                marginBottom: "30px",
                // paddingRight: { md: "33%", xs: 0 },
                marginTop: "10px",
                pb: "25px",
                textAlign: { xs: "center", md: "left" },
              }}
            >
              India's leading Direct to Consumer (D2C) network for Brands &
              Stores
            </Typography>
            <Button
              sx={{
                backgroundColor: blackColor,
                border: "1px solid #000000",
                marginTop: "0px",
                fontSize: "15px",
                padding: "8px 25px",
                borderRadius: "9px",
                color: "#fff",
                fontWeight: 500,
                textTransform: "uppercase",
                width: { xs: "100%", md: "40%" },
                "&:hover": {
                  backgroundColor: whiteColor,
                  color: blackColor,
                },
              }}
              component="a"
              href={`https://buy.costbo.com/`}
            >
              Get Started Today
            </Button>
          </Box>
          <Box
            sx={{
              flex: { md: "0 0 58.333333%", xs: "auto" },
              maxWidth: { md: "58.333333%", xs: 1 },
            }}
            display={{ xs: "none", md: "block" }}
          >
            <Box
              sx={{
                padding: "20px 0px 0px 15px", // Padding: top, right, bottom, left
                position: "relative",
                width: "100%",
              }}
            >
              <Box
                component="img"
                src={
                  "https://storage.googleapis.com/bodefaults/shopweb/main-banner.svg"
                  // 'https://storage.googleapis.com/bodefaults/shopweb/home/hero-landing.png'
                }
                // src={'https://storage.googleapis.com/bodefaults/shopweb/logo_v3.png'}
                alt={"banner CostBo"}
                width={{ xs: "100%", md: "98%" }}
                height="auto"
                display="block"
                sx={{
                  borderRadius: "30px",
                  transition: "all 0.3s ease-in-out",
                  transform: "scale(1)",
                  "&:hover": {
                    transform: "scale(0.97)",
                  },
                }}
                // component='a'
                //  href={`https://buy.costbo.com/`}
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default HeroSection