import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { blackColor, whiteColor } from "configs/styles/muiThemes";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

function AlertDialog({ content, onClose, isOpen }) {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          // padding: 2,
          width: "60vw",
          textAlign: "center",
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "15px",
          fontWeight: "600",
          p: "10px",
        }}
      >
        Alert
        <IconButton onClick={onClose} size="small">
          <CloseIcon sx={{ fontSize: 20 }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ fontSize: "1rem", marginBottom: 1 }}
        >
          {content}
        </DialogContentText>
        {/* <Typography variant="h5" sx={{ fontWeight: 'bold', margin: '8px 0' }}>
          ₹ {amount}
        </Typography> */}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", gap: 2 }}>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            px: 4,
            color: blackColor,
            borderColor: blackColor,
            "&:hover": {
              backgroundColor: blackColor,
              color: whiteColor,
            },
          }}
        >
          OK
        </Button>
        {/* <Button
          onClick={onApply}
          variant="contained"
          sx={{
            px: 4,
            color: whiteColor,
            backgroundColor: blackColor,
            "&:hover": {
              backgroundColor: whiteColor,
              color: blackColor,
            },
          }}
        >
          APPLY
        </Button> */}
      </DialogActions>
    </Dialog>

    // <Dialog
    //   open={isOpen}
    //   onClose={onClose}
    //   aria-labelledby="alert-dialog-title"
    //   aria-describedby="alert-dialog-description"
    // >
    //   <DialogTitle id="alert-dialog-title">Alert</DialogTitle>
    //   <DialogContent>
    //     <DialogContentText id="alert-dialog-description">
    //       {content}
    //     </DialogContentText>
    //   </DialogContent>
    //   <DialogActions>
    //     <Button
    //       onClick={onClose}
    //       variant="contained"
    //       sx={{
    //         px: 2,
    //         color: whiteColor,
    //         border: `1px solid ${blackColor}`,
    //         backgroundColor: blackColor,
    //         "&:hover": {
    //           backgroundColor: whiteColor,
    //           color: blackColor,
    //         },
    //       }}
    //     >
    //       OK
    //     </Button>
    //   </DialogActions>
    // </Dialog>
  );
}

export default AlertDialog;
