import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import FreeShippingContentCard from "components/FreeShippingContentCard";
import { OnlineOrderSuspendedMsg } from "configs/Constants";
import { useEffect, useState } from "react";
import { getUserName } from "utils/authHelpers";
import { checkEcommerceDiable } from "utils/cartHelper";
import BreadcrumbsSection from "../../components/Breadcrumbs";
import InfoCard from "../../components/InfoCard";
import {
  backgroundColor,
  backgroundColor01,
  blackColor,
  borderLineColor,
  delectIconInactiveColor,
  greenColor04,
  greenColor05,
  headingColor,
  lightGreen03,
  orangeColor01,
  savingTextColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import AdditionalSavingCard from "./AdditionalSavingCard";
import CartBottomTab from "./CartBottomTab";
import CartProductCard from "./CartProductCard";
import CartStepper from "./CartStepper";
import FooterMui from "components/FooterMui";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

function CartContent({
  cartDetails,
  productOfferCheckList,
  checkOrderProducts,
  commercialDetails,
  onQtyUpdate,
  cashbackData,
  onProceed,
  validateCart,
  onRemoveProduct,
  onPriceUpdate,
  commerceDisabled,
  errorCount,
  handleErrorCount,
  allProducts,
  isUnicommerce,
  inventoryInfo,
  unicommerceLoader,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  let totalQty = 0;
  let totalSavings = 0;

  const businessId = sessionStorage.getItem("businessId");
  const businessName = sessionStorage.getItem("businessName");

  const isBusinessClosed = commercialDetails?.info?.hyperlocalStore
    ? !commercialDetails?.info?.hyperlocalStore?.storeOpenStatus
    : false;

  const onProceedToCart = () => {
    onProceed();
  };

  const [isLoggedIn, setIsLoggedIn] = useState(
    getUserName().length === 0 ? false : true
  );
  const [itemCount, setItemCount] = useState(0);

  useEffect(() => {
    let count = 0;
    cartDetails?.productList?.forEach((item, index) => {
      count += item?.currentQuantity;
    });
    setItemCount(count);
  }, [cartDetails]);

  errorCount.current = 0;

  return matches ? (
    <Container
      sx={{
        maxWidth: "1350px !important",
        // mt: { xs: 1.8, md: 3.5 }
        mb: { xs: 10, md: 0 },
        p: { xs: "0px", md: "0 10px 0 15px !important" },
      }}
    >
      {/* <BreadcrumbsSection
        links={[
          { label: 'Catalog', to: `/catalog` },
          { label: 'Cart', to: `#` },
        ]}
      />
      <Container sx={{ maxWidth: '900px !important', display:{xs:'none', md:'block'} }}>
        <CartStepper
          steps={[
            {
              label: 'Cart',
              route: `/${businessName}/cart`,
            },
            {
              label: 'Discount/Delivery',
              route: `#`,
            },
            {
              label: 'Confirmation',
              route: `#`,
            },
          ]}
          activeStep={0}
        />
      </Container> */}
      <CartBottomTab
        totalQty={itemCount}
        amount={cartDetails?.orderTotalProductCost}
        btnText="CHECKOUT"
        products={cartDetails?.productList}
        onBtnClicked={() => {
          onProceedToCart();
        }}
        isDisabled={isBusinessClosed || unicommerceLoader}
        freeAlert={
          (
            (cartDetails?.orderTotalProductCost?.toFixed(2) * 100) /
            commercialDetails?.info?.immediateDiscount
          ).toFixed(2) > 75 && commercialDetails?.info?.immediateDiscount != 0
        }
        freeAmt={
          commercialDetails?.info?.immediateDiscount -
          cartDetails?.orderTotalProductCost?.toFixed(2)
        }
        // freeAlert={
        //   true
        // }
        // freeAmt={500}
      />
      {console.log("checkOrderProducts", checkOrderProducts)}

      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} md={7.953}>
          <Box sx={styles.cartContainer} mb={2}>
            <Box
              // width={'100%'}
              padding={"10px 20px"}
              borderBottom={`1px solid ${borderLineColor}`}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography
                variant="subtitle1"
                component="h4"
                fontSize="15px"
                color={blackColor}
                fontWeight={600}
                lineHeight={2.267}
              >
                {itemCount} Items in your Cart
              </Typography>

              {validateCart ? (
                <Box sx={styles.alertInfoBox}>
                  <Box
                    component="img"
                    src={`https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/issue-icon.svg`}
                    alt={" CostBo"}
                    backgroundColor={whiteColor}
                    width={"16px"}
                    height={"20px"}
                  />
                  <Typography sx={styles.alertInfoText}>
                    Some items in your cart have been modified
                  </Typography>
                </Box>
              ) : null}
            </Box>
            {cartDetails &&
              // checkOrderProducts &&
              cartDetails?.productList?.map((item, index) => {
                console.log(`itemitemitemitem${index}`, item);
                const savings =
                  Number(item?.productOriginalPrice) *
                    Number(item?.currentQuantity) -
                  Number(item?.productSalePrice) *
                    Number(item?.currentQuantity);
                totalQty = totalQty + item.currentQuantity;
                totalSavings = totalSavings + savings;

                const orderProduct = checkOrderProducts?.filter(
                  (product, index) => {
                    return item.productId == product.id;
                  }
                );

                console.log(
                  "CART_ORDER_FILTER",
                  orderProduct,
                  item,
                  item?.productSalePrice,
                  orderProduct?.[0]?.productSalePrice,
                  item?.productOriginalPrice,
                  orderProduct?.[0]?.productOriginalPrice
                );

                const isMinQtyError =
                  item?.currentQuantity < orderProduct?.[0]?.minOrderQuantity;

                const isMaxQtyError =
                  item?.currentQuantity > orderProduct?.[0]?.maxOrderQuantity;

                const isPriceError =
                  item?.productSalePrice?.toFixed(2) !=
                    orderProduct?.[0]?.productSalePrice ||
                  item?.productOriginalPrice?.toFixed(2) !=
                    orderProduct?.[0]?.productOriginalPrice;

                const isEcommerceError = checkEcommerceDiable(
                  orderProduct?.[0],
                  item,
                  isUnicommerce,
                  inventoryInfo
                );

                const productWeight = item?.measurementInfo
                  ? item?.measurementInfo?.weight
                  : 0;
                const isWeightZero = productWeight == 0;

                const offerOrderList = productOfferCheckList?.filter(
                  (offerOrderProduct, index) => {
                    return offerOrderProduct?.productId == item?.productId;
                  }
                );

                const isOfferOrderPresent =
                  offerOrderList?.[0]?.offerOrderPresent;

                if (
                  isMinQtyError ||
                  isMaxQtyError ||
                  isPriceError ||
                  isEcommerceError ||
                  isWeightZero ||
                  isOfferOrderPresent
                ) {
                  if (orderProduct?.length > 0) {
                    handleErrorCount();
                  }
                }

                let minQtyGuest = 0;
                let maxQtyGuest = 0;

                allProducts?.productList?.forEach((element) => {
                  if (element.id == item.productId) {
                    minQtyGuest = element?.minOrderQuantity;
                    maxQtyGuest = element?.maxOrderQuantity;
                    return;
                  }
                });

                return (
                  <CartProductCard
                    key={"cart_product_" + index}
                    image={item?.productPicURL}
                    productName={item?.productName}
                    oldUnitPrice={item?.productOriginalPrice}
                    unitprice={item?.productSalePrice}
                    discount={item?.percentageOff}
                    price={item?.productTotalPrice}
                    productId={item?.productId}
                    saving={savings}
                    quantity={item.currentQuantity}
                    isOutofStock={item.isOutOfStock}
                    onDeleteProduct={() => {
                      onRemoveProduct(cartDetails, orderProduct?.[0], item);
                      errorCount.current = 0;
                    }}
                    onPriceUpdate={() => {
                      onPriceUpdate(cartDetails, orderProduct?.[0], item);
                      errorCount.current = 0;
                    }}
                    minQty={
                      isLoggedIn
                        ? orderProduct?.length > 0
                          ? orderProduct?.[0]?.minOrderQuantity
                          : 0
                        : minQtyGuest
                    }
                    maxQty={
                      isLoggedIn
                        ? orderProduct?.length > 0
                          ? orderProduct?.[0]?.maxOrderQuantity
                          : 0
                        : maxQtyGuest
                    }
                    cartData={cartDetails?.productList}
                    cartId={cartDetails?.cartId}
                    product={item}
                    orderProduct={orderProduct?.[0]}
                    onQtyUpdate={onQtyUpdate}
                    // Price Change Check
                    isPriceChange={isPriceError}
                    // Ecommerce Enable check
                    isEcommerceDisabled={isEcommerceError}
                    // Min Order Quantity Check
                    minQtyCheck={isMinQtyError}
                    // Max Order Quantity Check
                    maxQtyCheck={isMaxQtyError}
                    // Weight Zero Check
                    weightZeroError={isWeightZero}
                    // Offer Order Check
                    isOfferOrderPresent={isOfferOrderPresent}
                    validateCart={validateCart}
                  />
                );
              })}
          </Box>
        </Grid>

        <Grid item xs={12} md={3.95} ml={1}>
          {/* {<Button
            // component={Link}
            // to='/DiscountDelivery'
            sx={styles.checkoutBtn(isBusinessClosed)}
            variant='contained'
            disableElevation
            fullWidth
            onClick={() => {
              onProceedToCart()
            }}
            disabled={isBusinessClosed}
          >
            Proceed To Checkout
          </Button>} */}
          <Box sx={{ display: { md: "block", xs: "none" } }}>
            {(
              (cartDetails?.orderTotalProductCost?.toFixed(2) * 100) /
              commercialDetails?.info?.immediateDiscount
            ).toFixed(2) > 75 &&
            commercialDetails?.info?.immediateDiscount != 0 ? (
              <FreeShippingContentCard
                amt={
                  commercialDetails?.info?.immediateDiscount -
                  cartDetails?.orderTotalProductCost?.toFixed(2)
                }
              />
            ) : null}
          </Box>

          {commerceDisabled ? (
            <Typography
              variant="subtitle1"
              fontSize="11px"
              color={orangeColor01}
              sx={{ pt: 1.5 }}
            >
              {OnlineOrderSuspendedMsg}
            </Typography>
          ) : null}

          <Box
            // border={{ md: '1px solid ' + borderLineColor, xs: 'none' }}
            borderRadius="6px"
            // p={1.5}
            mt={{ md: 2, xs: -0.5 }}
          >
            <Typography
              variant="h5"
              component="h3"
              fontSize={{ xs: 14, md: 15 }}
              color={{ blackColor }}
              // borderBottom={{ md: '1px solid ' + borderLineColor, xs: 'none' }}
              textAlign={{ md: "left", xs: `left` }}
            >
              Summary
            </Typography>

            {matches ? (
              <>
                <Box
                  pt={1}
                  sx={{
                    padding: "10px",
                    backgroundColor: whiteColor,
                    borderRadius: "10px",
                    marginTop: "7px",
                    border: "1px solid #e5e5ed",
                  }}
                >
                  <SummaryDataRow
                    label="Subtotal:"
                    value={`₹${cartDetails?.orderTotalProductCost?.toFixed(2)}`}
                  />
                  <SummaryDataRow label="Total Quantity:" value={totalQty} />
                  <SummaryDataRow
                    label="Taxes:"
                    value="Included"
                    isValueGreen
                  />
                  <SummaryDataRow label="Shipping & Handling:" value="-" />
                  {/* <SummaryDataRow label='Discount:' value='-' /> */}
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    pt={0.7}
                    mt={1}
                    borderTop={"1px solid " + borderLineColor}
                  >
                    <Grid item>
                      <Typography
                        variant="h5"
                        component="p"
                        fontSize={14}
                        color={headingColor}
                        display="inline"
                      >
                        Total
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography
                        variant="h5"
                        component="p"
                        fontSize={14}
                        color={greenColor04}
                        display="inline"
                      >
                        ₹{cartDetails?.orderTotalProductCost?.toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  padding: "10px",
                  backgroundColor: whiteColor,
                  borderRadius: "10px",
                  marginTop: "7px",
                  border: "1px solid #e5e5ed",
                }}
              >
                <SummaryDataRow
                  label="Subtotal:"
                  value={`₹ ${cartDetails?.orderTotalProductCost?.toFixed(2)}`}
                />
                <SummaryDataRow label="Total Quantity:" value={totalQty} />
                <SummaryDataRow label="Taxes:" value="Included" isValueGreen />
                <SummaryDataRow label="Shipping & Handling:" value="-" />
                <SummaryDataRow label="Discount:" value="-" />
                <Box border={`1px solid ${backgroundColor}`} mt={1} />
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  pt={0.7}
                >
                  <Grid item>
                    <Typography
                      variant="h5"
                      component="p"
                      fontSize={{ md: 14, xs: 13 }}
                      color={{ md: headingColor, xs: blackColor }}
                      display="inline"
                    >
                      Total
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography
                      variant="h5"
                      component="p"
                      fontSize={14}
                      color={{ md: orangeColor01, xs: blackColor }}
                      display="inline"
                    >
                      ₹{cartDetails?.orderTotalProductCost?.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>

          {/* <TotalSavingCard savingAmount={totalSavings} /> */}

          <AdditionalSavingCard
            cashback={commercialDetails?.info?.rewardPointPercent}
            savingAmount={totalSavings}
            orderTotal={cartDetails?.orderTotalProductCost}
            cashbackData={cashbackData}
          />

          <InfoCard />

          {/* <Button
            // component={Link}
            // to='/DiscountDelivery'
            sx={styles.checkoutBtn}
            variant='contained'
            disableElevation
            fullWidth
            onClick={() => {
              onProceedToCart()
            }}
            disabled={isBusinessClosed}
          >
            Proceed To Checkout
          </Button> */}

          {commerceDisabled ? (
            <Typography
              variant="subtitle1"
              fontSize="11px"
              color={orangeColor01}
              sx={{ pt: 1.5 }}
            >
              {OnlineOrderSuspendedMsg}
            </Typography>
          ) : null}
          <Box
            sx={{
              position: "sticky",
              right: 0,
              bottom: 0,
              backgroundColor: "#fff",
              boxShadow: "0 3px 9px rgba(0, 0, 0, 0.08)",
              width: "100%",
              borderRadius: "10px 10px 0px 0px",
              border: "1px solid #dcdce5",
            }}
          >
            <Box
              sx={{
                padding: "6px 16px",
                minHeight: "64px",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start", // Equivalent to -webkit-align-items
                  flexDirection: "column", // Equivalent to flex-direction: column
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    display: "flex", // Flexbox display
                    alignItems: "center", // Align items vertically center
                    justifyContent: "space-between", // Space between items
                    flexDirection: "row", // Horizontal flex direction
                    fontSize: "12px", // Font size
                    lineHeight: "14px", // Line height
                    fontWeight: 400, // Font weight
                    cursor: "pointer", // Cursor pointer on hover
                    position: "relative", // Relative position
                    paddingRight: "25px",
                    color: blackColor,
                  }}
                >
                  {" "}
                  {totalQty} items in cart
                </Typography>
                <Typography
                  sx={{
                    color: "#10847e",
                    fontSize: "18px",
                    lineHeight: "36px",
                    fontWeight: 600,
                  }}
                >
                  {" "}
                  ₹{cartDetails?.orderTotalProductCost?.toFixed(2)}
                </Typography>
              </Box>
              <Button
                sx={{
                  boxShadow: "none", // No box shadow
                  height: "auto", // Auto height
                  lineHeight: "24px", // Line height
                  fontWeight: 600, // Bold text
                  padding: "4px 20px", // Padding around the button
                  backgroundColor: "#10847e", // Background color
                  color: "#fff", // Text color (white)
                  borderRadius: "5px", // Rounded corners
                  border: "1px solid #10847e", // Border with the same color as background
                  fontSize: "12px", // Font size
                  textTransform: "uppercase",
                  "&:hover": {
                    // Optional: styling for hover state
                    backgroundColor: whiteColor,
                    color: greenColor05,
                    boxShadow: "none",
                  },
                  cursor: "pointer",
                }}
                disableElevation
                onClick={() => {
                  if (isBusinessClosed || unicommerceLoader) return;
                  onProceedToCart();
                }}
                disabled={isBusinessClosed || unicommerceLoader}
                mr={3}
              >
                Proceed To Checkout
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  ) : (
    <Container
      sx={{
        maxWidth: "1300px !important",
        // mt: { xs: 1.8, md: 3.5 }
        mb: { xs: 10, md: 0 },
        bgcolor: backgroundColor01,
      }}
    >
      {/* <BreadcrumbsSection
        links={[
          { label: 'Catalog', to: `/catalog` },
          { label: 'Cart', to: `#` },
        ]}
      />
      <Container sx={{ maxWidth: '900px !important', display:{xs:'none', md:'block'} }}>
        <CartStepper
          steps={[
            {
              label: 'Cart',
              route: `/${businessName}/cart`,
            },
            {
              label: 'Discount/Delivery',
              route: `#`,
            },
            {
              label: 'Confirmation',
              route: `#`,
            },
          ]}
          activeStep={0}
        />
      </Container> */}
      <CartBottomTab
        totalQty={itemCount}
        amount={cartDetails?.orderTotalProductCost}
        btnText="CHECKOUT"
        products={cartDetails?.productList}
        onBtnClicked={() => {
          onProceedToCart();
        }}
        isDisabled={isBusinessClosed}
        freeAlert={
          (
            (cartDetails?.orderTotalProductCost?.toFixed(2) * 100) /
            commercialDetails?.info?.immediateDiscount
          ).toFixed(2) > 75 && commercialDetails?.info?.immediateDiscount != 0
        }
        freeAmt={
          commercialDetails?.info?.immediateDiscount -
          cartDetails?.orderTotalProductCost?.toFixed(2)
        }
        // freeAlert={
        //   true
        // }
        // freeAmt={500}
      />

      <Grid container spacing={{ xs: 2, md: 3.5 }}>
        <Grid item xs={12} md={9}>
          <Box sx={styles.cartContainer}>
            <Grid
              container
              py={1}
              display={{ xs: "none", md: "inline-flex" }}
              spacing={{ md: 3 }}
            >
              <Grid item xs={4.5}>
                <Typography
                  variant="subtitle1"
                  component="h4"
                  fontSize="13px"
                  color={delectIconInactiveColor}
                  ml={1.8}
                >
                  PRODUCT
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant="subtitle1"
                  component="h4"
                  fontSize="13px"
                  color={delectIconInactiveColor}
                >
                  UNIT PRICE
                </Typography>
              </Grid>
              <Grid item xs={2.3}>
                <Typography
                  variant="subtitle1"
                  component="h4"
                  fontSize="13px"
                  color={delectIconInactiveColor}
                >
                  QUANTITY
                </Typography>
              </Grid>
              <Grid item xs={1.2}>
                <Typography
                  variant="subtitle1"
                  component="h4"
                  fontSize="13px"
                  color={delectIconInactiveColor}
                  ml={0.6}
                >
                  PRICE
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant="subtitle1"
                  component="h4"
                  fontSize="13px"
                  color={delectIconInactiveColor}
                >
                  SAVINGS
                </Typography>
              </Grid>
            </Grid>

            {cartDetails &&
              // checkOrderProducts &&
              cartDetails?.productList?.map((item, index) => {
                console.log(`itemitemitemitem${index}`, item);
                const savings =
                  Number(item?.productOriginalPrice) *
                    Number(item?.currentQuantity) -
                  Number(item?.productSalePrice) *
                    Number(item?.currentQuantity);
                totalQty = totalQty + item.currentQuantity;
                totalSavings = totalSavings + savings;

                const orderProduct = checkOrderProducts?.filter(
                  (product, index) => {
                    return item.productId == product.id;
                  }
                );

                console.log(
                  "CART_ORDER_FILTER",
                  orderProduct,
                  item,
                  item?.productSalePrice,
                  orderProduct?.[0]?.productSalePrice,
                  item?.productOriginalPrice,
                  orderProduct?.[0]?.productOriginalPrice
                );

                const isMinQtyError =
                  item?.currentQuantity < orderProduct?.[0]?.minOrderQuantity;

                const isMaxQtyError =
                  item?.currentQuantity > orderProduct?.[0]?.maxOrderQuantity;

                const isPriceError =
                  item?.productSalePrice?.toFixed(2) !=
                    orderProduct?.[0]?.productSalePrice ||
                  item?.productOriginalPrice?.toFixed(2) !=
                    orderProduct?.[0]?.productOriginalPrice;

                const isEcommerceError = checkEcommerceDiable(
                  orderProduct?.[0],
                  item,
                  isUnicommerce,
                  inventoryInfo
                );

                const productWeight = item?.measurementInfo
                  ? item?.measurementInfo?.weight
                  : 0;
                const isWeightZero = productWeight == 0;

                const offerOrderList = productOfferCheckList?.filter(
                  (offerOrderProduct, index) => {
                    return offerOrderProduct?.productId == item?.productId;
                  }
                );

                const isOfferOrderPresent =
                  offerOrderList?.[0]?.offerOrderPresent;

                if (
                  isMinQtyError ||
                  isMaxQtyError ||
                  isPriceError ||
                  isEcommerceError ||
                  isWeightZero ||
                  isOfferOrderPresent
                ) {
                  if (orderProduct?.length > 0) {
                    handleErrorCount();
                  }
                }

                let minQtyGuest = 0;
                let maxQtyGuest = 0;

                allProducts?.productList?.forEach((element) => {
                  if (element.id == item.productId) {
                    minQtyGuest = element?.minOrderQuantity;
                    maxQtyGuest = element?.maxOrderQuantity;
                    return;
                  }
                });

                return (
                  <CartProductCard
                    key={"cart_product_" + index}
                    image={item?.productPicURL}
                    productName={item?.productName}
                    oldUnitPrice={item?.productOriginalPrice}
                    unitprice={item?.productSalePrice}
                    discount={item?.percentageOff}
                    price={item?.productTotalPrice}
                    productId={item?.productId}
                    saving={savings}
                    quantity={item.currentQuantity}
                    isOutofStock={item.isOutOfStock}
                    onDeleteProduct={() => {
                      onRemoveProduct(cartDetails, orderProduct?.[0], item);
                      errorCount.current = 0;
                    }}
                    onPriceUpdate={() => {
                      onPriceUpdate(cartDetails, orderProduct?.[0], item);
                      errorCount.current = 0;
                    }}
                    minQty={
                      isLoggedIn
                        ? orderProduct.length > 0
                          ? orderProduct?.[0]?.minOrderQuantity
                          : 0
                        : minQtyGuest
                    }
                    maxQty={
                      isLoggedIn
                        ? orderProduct.length > 0
                          ? orderProduct?.[0]?.maxOrderQuantity
                          : 0
                        : maxQtyGuest
                    }
                    cartData={cartDetails?.productList}
                    cartId={cartDetails?.cartId}
                    product={item}
                    orderProduct={orderProduct?.[0]}
                    onQtyUpdate={onQtyUpdate}
                    // Price Change Check
                    isPriceChange={isPriceError}
                    // Ecommerce Enable check
                    isEcommerceDisabled={isEcommerceError}
                    // Min Order Quantity Check
                    minQtyCheck={isMinQtyError}
                    // Max Order Quantity Check
                    maxQtyCheck={isMaxQtyError}
                    // Weight Zero Check
                    weightZeroError={isWeightZero}
                    // Offer Order Check
                    isOfferOrderPresent={isOfferOrderPresent}
                    validateCart={validateCart}
                  />
                );
              })}
          </Box>
        </Grid>

        <Grid item xs={12} md={3} mb={{ xs: 18, md: 0 }}>
          <Button
            // component={Link}
            // to='/DiscountDelivery'
            sx={styles.checkoutBtn(isBusinessClosed)}
            variant="contained"
            disableElevation
            fullWidth
            onClick={() => {
              onProceedToCart();
            }}
            disabled={isBusinessClosed || unicommerceLoader}
          >
            Proceed To Checkout
          </Button>
          <Box sx={{ display: { md: "block", xs: "none" } }}>
            {(
              (cartDetails?.orderTotalProductCost?.toFixed(2) * 100) /
              commercialDetails?.info?.immediateDiscount
            ).toFixed(2) > 75 &&
            commercialDetails?.info?.immediateDiscount != 0 ? (
              <FreeShippingContentCard
                amt={
                  commercialDetails?.info?.immediateDiscount -
                  cartDetails?.orderTotalProductCost?.toFixed(2)
                }
              />
            ) : null}
          </Box>

          {commerceDisabled ? (
            <Typography
              variant="subtitle1"
              fontSize="11px"
              color={orangeColor01}
              sx={{ pt: 1.5 }}
            >
              {OnlineOrderSuspendedMsg}
            </Typography>
          ) : null}

          <Box
            border={{ md: "1px solid " + borderLineColor, xs: "none" }}
            borderRadius="6px"
            // p={1.5}
            mt={{ md: 3, xs: -0.8 }}
          >
            <Typography
              variant="h5"
              component="h3"
              fontSize={{ xs: 14, md: 15 }}
              color={headingColor}
              borderBottom={{ md: "1px solid " + borderLineColor, xs: "none" }}
              textAlign={{ md: "right", xs: `left` }}
            >
              Summary
            </Typography>

            {matches ? (
              <>
                <Box pt={1}>
                  <SummaryDataRow
                    label="Subtotal:"
                    value={`₹${cartDetails?.orderTotalProductCost?.toFixed(2)}`}
                  />
                  <SummaryDataRow label="Total Quantity:" value={totalQty} />
                  <SummaryDataRow
                    label="Taxes:"
                    value="Included"
                    isValueGreen
                  />
                  <SummaryDataRow label="Shipping & Handling:" value="-" />
                  <SummaryDataRow label="Discount:" value="-" />
                </Box>

                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  pt={0.7}
                  borderTop={"1px solid " + borderLineColor}
                >
                  <Grid item>
                    <Typography
                      variant="h5"
                      component="p"
                      fontSize={14}
                      color={headingColor}
                      display="inline"
                    >
                      Total
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography
                      variant="h5"
                      component="p"
                      fontSize={14}
                      color={orangeColor01}
                      display="inline"
                    >
                      ₹{cartDetails?.orderTotalProductCost?.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Box
                sx={{
                  padding: "10px",
                  backgroundColor: whiteColor,
                  borderRadius: "10px",
                  marginTop: "7px",
                  border: "1px solid #e5e5ed",
                }}
              >
                <SummaryDataRow
                  label="Subtotal:"
                  value={`₹ ${cartDetails?.orderTotalProductCost?.toFixed(2)}`}
                />
                <SummaryDataRow label="Total Quantity:" value={totalQty} />
                <SummaryDataRow label="Taxes:" value="Included" isValueGreen />
                <SummaryDataRow label="Shipping & Handling:" value="-" />
                <SummaryDataRow label="Discount:" value="-" />
                <Box border={`1px solid ${backgroundColor}`} mt={1} />
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  pt={0.7}
                >
                  <Grid item>
                    <Typography
                      variant="h5"
                      component="p"
                      fontSize={{ md: 14, xs: 13 }}
                      color={{ md: headingColor, xs: blackColor }}
                      display="inline"
                    >
                      Total
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography
                      variant="h5"
                      component="p"
                      fontSize={14}
                      color={{ md: orangeColor01, xs: blackColor }}
                      display="inline"
                    >
                      ₹{cartDetails?.orderTotalProductCost?.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>

          {/* <TotalSavingCard savingAmount={totalSavings} /> */}

          <AdditionalSavingCard
            cashback={commercialDetails?.info?.rewardPointPercent}
            savingAmount={totalSavings}
            orderTotal={cartDetails?.orderTotalProductCost}
            cashbackData={cashbackData}
          />

          <InfoCard
            freeAlert={
              (
                (cartDetails?.orderTotalProductCost?.toFixed(2) * 100) /
                commercialDetails?.info?.immediateDiscount
              ).toFixed(2) > 75 &&
              commercialDetails?.info?.immediateDiscount != 0
            }
          />

          <Button
            // component={Link}
            // to='/DiscountDelivery'
            sx={styles.checkoutBtn}
            variant="contained"
            disableElevation
            fullWidth
            onClick={() => {
              onProceedToCart();
            }}
            disabled={isBusinessClosed || unicommerceLoader}
          >
            Proceed To Checkout
          </Button>

          {commerceDisabled ? (
            <Typography
              variant="subtitle1"
              fontSize="11px"
              color={orangeColor01}
              sx={{ pt: 1.5 }}
            >
              {OnlineOrderSuspendedMsg}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    </Container>
  );
}

export default CartContent;

const SummaryDataRow = ({ label, labelSize, value, isValueGreen }) => {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      py={{ md: 0.2, xs: 0 }}
    >
      <Grid item>
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={labelSize ? labelSize : { md: "12.5px", xs: 12 }}
          color={{ ms: blackColor, xs: blackColor }}
          display="inline"
        >
          {label}
        </Typography>
      </Grid>

      <Grid item>
        <Typography
          variant="h6"
          component="p"
          fontSize={{ md: "12.5px", xs: 12 }}
          color={
            isValueGreen ? { md: greenColor04, xs: lightGreen03 } : blackColor
          }
          display="inline"
        >
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

const styles = {
  cartContainer: {
    // boxShadow: "0 4px 8px rgb(0 0 0 / 6%)",
    // px: 1.2,
    borderRadius: { md: "10px", xs: "10px" },
    border: "1px solid" + borderLineColor,
    backgroundColor: { xs: whiteColor },
  },

  checkoutBtn: (disabled) => ({
    fontSize: "14px",
    fontWeight: 600,
    color: whiteColor,
    display: { xs: "none !important", md: "inline-flex !important" },
    border: disabled
      ? "0px solid " + orangeColor01
      : "2px solid " + orangeColor01,
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: whiteColor,
      color: "primary.main",
    },
  }),

  alertInfoBox: {
    border: "1px solid #cc4c4e",
    borderRadius: "8px",
    padding: "6px 8px",
    margin: "0px 0px",
    display: "flex",
    alignItems: "center",
    gap: 1,
  },

  alertInfoText: {
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: "16px",
    color: "#cc4c4e",
    position: "relative",
    paddingLeft: "7px",
  },
};
