import { Box, Skeleton } from '@mui/material'
import React from 'react'

const ShareCouponInfoLoader = () => {
  return (
    <Box>
        <Skeleton variant="rounded" width="290px" height='14px' sx={{ m:'64px 0 10px !important' }} />

        <Box display="flex" alignItems="center" gap="8px">
          <Skeleton variant="rounded" width="30px" height="30px" sx={{borderRadius:'8px'}} />
          <Skeleton variant="rounded" width="30px" height="30px" sx={{borderRadius:'8px'}} />
          <Skeleton variant="rounded" width="30px" height="30px" sx={{borderRadius:'8px'}} />
          <Skeleton variant="rounded" width="30px" height="30px" sx={{borderRadius:'8px'}} />
          <Skeleton variant="rounded" width="30px" height="30px" sx={{borderRadius:'8px'}} />
        </Box>

        <Skeleton variant="rounded" width="200x" height='14px' sx={{ m:'30px 0 15px !important' }} />

        <Skeleton variant="rounded" width="auto" height="324px" sx={{borderRadius:'12px'}} />
    </Box>
  )
}

export default ShareCouponInfoLoader