import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import {
  dealsDiscounts_ApplyStep1,
  dealsDiscounts_ApplyStep2,
  dealsDiscounts_ApplyStep3,
  dealsDiscounts_ApplyStep4,
  dealsDiscounts_ApplyStep5,
  dealsDiscounts_ApplyStep6,
  dealsDiscounts_ApplyStep7,
} from "configs/Constants";
import React from "react";
import {
  backgroundColor,
  blackColor,
  borderColor,
  couponColor,
  headingColor,
} from "../../configs/styles/muiThemes";
import CircleIcon from "@mui/icons-material/FiberManualRecord";

export default function DiscountStepCard() {
  const discountSteps = [
    dealsDiscounts_ApplyStep1,
    dealsDiscounts_ApplyStep2,
    dealsDiscounts_ApplyStep3,
    dealsDiscounts_ApplyStep4,
    dealsDiscounts_ApplyStep5,
    dealsDiscounts_ApplyStep6,
    dealsDiscounts_ApplyStep7,
  ];
  return (
    <Paper sx={styles.card}>
      <Typography
        variant="subtitle1"
        fontSize={13.5}
        fontWeight={500}
        color={blackColor}
        pb={1}
        mt={1}
      >
        How to apply a discount code ?
      </Typography>
      {/* <List sx={styles.list}>
        {discountSteps.map((item, i) => {
          return (
            <ListItem
              sx={{ display: 'list-item', p: 0, ...styles.item, my: 1 }}
            >
              <Typography variant='body1' color={couponColor}>
                {item}
              </Typography>
            </ListItem>
          )
        })}
      </List> */}
      <Box
        sx={{
          border: "1px solid #ebebf1",
          borderRadius: "12px",
          padding: "5px 6px",
          backgroundColor: "#fafafa",
          width: "400px",
        }}
      >
        <List>
          {discountSteps.map((step, index) => (
            <ListItem
              key={index}
              sx={{
                position: "relative",
                paddingLeft: "24px",
                fontSize: 12.5,
                color: blackColor,
                fontWeight: 400,
                pt: 0,
                "&::before": {
                  content: '""',
                  position: "absolute",
                  left: "12px",
                  top: "10px",
                  height: "100%",
                  width: "0",
                  border: "1.5px solid #dbdbea",
                  zIndex: -1,
                  // To ensure the line ends at the last item halfway
                  bottom: index === discountSteps.length - 1 ? "50%" : 0,
                },
                "&::after": {
                  content: '""',
                  position: "absolute",
                  left: "12px",
                  top: "20px",
                  height: index === discountSteps.length - 1 ? "0" : "100%", // Extend the line except for the last item
                  width: "3px",
                  background: "#dbdbea",
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: "24px", ml: -2.65 }}>
                <CircleIcon sx={{ fontSize: "20px", color: "#ebebf1" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    sx={{ fontSize: 12.5, color: blackColor, fontWeight: 400 }}
                  >
                    {step}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Paper>
  );
}

const styles = {
  card: {
    borderRadius: "6px",
    // padding: "15px",
    background: backgroundColor,
    boxShadow: 0,
    border: 1,
    borderColor: borderColor,
  },

  list: { listStyleType: "disc", ml: "20px" },
  item: {
    listStyle: "outside",
    lineHeight: "25px",
    marginBottom: "100px",
  },
};
