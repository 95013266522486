import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material'
import { inputError_OTPIncorrent } from 'configs/Constants'
import {
  borderOTPTextbox,
  errorTextColor,
  headingColor,
  labelColor,
  orangeColor01,
  secondaryColor,
  stepperIconColor,
  whiteColor,
} from 'configs/styles/muiThemes'
import firebase from '../../firebase'
import React, { useState } from 'react'
import OtpInput from 'react-otp-input'

function OtpCard({ phoneNumber, onSuccess }) {
  const [otp, setOtp] = useState('')
  const [loading, setLoading] = useState(false)
  const [agree, setAgree] = useState(false)
  const [otpErr, setOtpErr] = useState(false)
  const [agreeErr, setAgreeErr] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)

  const handleChange = (otp) => {
    if (otpErr) setOtpErr(false)
    setOtp(otp)
  }

  const handleSubmit = () => {
    setLoading(true)
    let err = false
    if (otp.length != 6) {
      setOtpErr(true)
      err = true
    }
    if (!agree) {
      setAgreeErr(true)
      err = true
    }

    if (err) {
      setLoading(false)
      return
    }

    window.confirmationResult
      .confirm(otp)
      .then((result) => {
        const user = result.user
        console.log(JSON.stringify(user))
        console.log(result)
        let x = firebase
          .auth()
          .currentUser.getIdToken(true)
          .then(function (idToken) {
            onSuccess(idToken)
            // console.log('Otp verified')
            //   onRegisterClick(idToken, guest)
            // if (type === 'guest') {
            //   checkUser(mobileNumber, idToken)
            // } else {
            //   onCreateAccount(idToken)
            // }
            // return idToken
          })

          .catch(function (error) {
            setLoading(false)
          })

        // ...
      })
      .catch((error) => {
        setErrorMsg(true)
        setLoading(false)
      })
  }
  return (
    <Box sx={styles.card}>
      <Typography
        textAlign={"center"}
        color={headingColor}
        variant="h5"
        fontWeight={500}
        fontSize={14}
        lineHeight={"28px"}
      >
        Enter the otp send your mobile number
      </Typography>
      <Typography
        textAlign={"center"}
        color={headingColor}
        variant="h6"
        fontWeight={600}
        fontSize={15}
        lineHeight={"28px"}
      >
        {phoneNumber}
      </Typography>
      <Box sx={{ px: { md: 4, xs: 1 }, my: 3.5 }}>
        <OtpInput
          value={otp}
          onChange={handleChange}
          name="otp"
          required
          isInputNum={true}
          numInputs={6}
          shouldAutoFocus={true}
          inputStyle={{
            borderBottom: borderOTPTextbox,
            width: "30px",
            color: headingColor,
          }}
          containerStyle={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        />
      </Box>
      {otpErr && (
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={13}
          color={errorTextColor}
        >
          Please Enter the 6 digits OTP
        </Typography>
      )}
      <Box mt={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={agree}
              color="primary"
              size="small"
              onChange={() => {
                if (agreeErr) setAgreeErr(false);
                setAgree(!agree);
              }}
            />
          }
          label={
            <Typography
              variant="body1"
              color={stepperIconColor}
              fontSize="12px"
              fontWeight={400}
              sx={{ display: "inline" }}
              lineHeight={1.5}
            >
              By continuing, you agree to our{" "}
              <Typography
                variant="body1"
                color={orangeColor01}
                fontSize="12px"
                fontWeight={500}
                sx={{ display: "inline" }}
                lineHeight={1.5}
              >
                Terms of Use
              </Typography>{" "}
              and{" "}
              <Typography
                variant="body1"
                color={orangeColor01}
                fontSize="12px"
                fontWeight={500}
                sx={{ display: "inline" }}
                lineHeight={1.5}
              >
                Privacy Policy
              </Typography>
            </Typography>
          }
        />
      </Box>
      {agreeErr && (
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={13}
          color={errorTextColor}
        >
          Please agree before continuing
        </Typography>
      )}
      {errorMsg && (
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={13}
          color={errorTextColor}
        >
          {inputError_OTPIncorrent}
        </Typography>
      )}
      <Button
        sx={{
          ...styles.actionBtn,
          border: loading ? "none" : `1px solid ${secondaryColor}`,
          // border: loading ? 'none' : `1px solid ${loginPrimary}`,
        }}
        variant="contained"
        fullWidth
        disableElevation
        size="small"
        type="button"
        onClick={handleSubmit}
        disabled={loading}
      >
        Verify Otp
      </Button>
    </Box>
  );
}

export default OtpCard

const styles = {
  card: {
    backgroundColor: whiteColor,
    p: "30px 18px",
    borderRadius: "15px",
  },
  actionBtn: {
    fontSize: "15px",
    fontWeight: 500,
    color: whiteColor,
    p: "5px 20px",
    mt: "15px !important",
    border: "1px solid " + secondaryColor,
    backgroundColor: secondaryColor,
    borderRadius: "5px",
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: whiteColor,
      color: secondaryColor,
    },
    letterSpacing: "0.5px",
  },
};
