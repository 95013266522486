import { BUYER_APP_LOOKUP, GET_DELHIVERY_STATUS, GET_PAYCART_DELHIVERY_STATUS, GET_PAYCART_TELYPORT_STATUS, GET_TELYPORT_STATUS } from 'services/constant'
import { getProfileId } from 'utils/authHelpers'
import { getApiInstance } from '../../ApiInstance'

export const getCartOrderDetails = async (cartid) => {
  const profileId = getProfileId()
  const instance = getApiInstance()
  try {
    const response = await instance(
      `cart-query-side/api/v2/cart/consumer/getCart`,
      {
        headers: {
          cartid: cartid,
          profileId: profileId,
        },
      }
    )
    // console.log('GET_ALL_CART_ORDER_DETAILS:', response.data)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const getDelhiveryStatusDetails = async (cartid) => {
  const profileId = getProfileId()
  const instance = getApiInstance()
  try {
    const response = await instance(
      `${GET_DELHIVERY_STATUS}?trackId=${cartid}`,
      {
        headers: {
          profileId: profileId,
        },
      }
    )
    console.log('GET_ALL_CART_ORDER_DETAILS:', response.data)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const getPayCartDelhiveryStatusDetails = async (cartid) => {
  const profileId = getProfileId()
  const instance = getApiInstance()
  try {
    const response = await instance(
      `${GET_PAYCART_DELHIVERY_STATUS}?trackId=${cartid}`,
      {
        headers: {
          profileId: profileId,
        },
      }
    )
    console.log('GET_PAYCART_DELHIVERY_STATUS:', response.data)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const getTelyportStatusDetails = async (orderId) => {
  const profileId = getProfileId()
  const instance = getApiInstance()
  try {
    const response = await instance(`${GET_TELYPORT_STATUS}`, {
      headers: {
        telyPortOrderId: orderId,
      },
    })
    console.log('GET_TELYPORT_STATUS:', response.data)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const getPayCartTelyportStatusDetails = async (orderId) => {
  const profileId = getProfileId()
  const instance = getApiInstance()
  try {
    const response = await instance(`${GET_PAYCART_TELYPORT_STATUS}`, {
      headers: {
        telyPortOrderId: orderId,
      },
    })
    console.log('GET_TELYPORT_STATUS:', response.data)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const getBuyerAppLookupAPI = async () => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(BUYER_APP_LOOKUP);

    console.log("BUYER_APP_LOOKUP", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};