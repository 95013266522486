import { Box, Skeleton, Stack } from '@mui/material'
import { whiteColor } from 'configs/styles/muiThemes'
import React from 'react'

const CartItemsLoaderMinimal = () => {
  return (
    <Box sx={{bgcolor: whiteColor, p:'0px'}}>
        <CartItemSkeleton sx={{mt:2}} />
        <CartItemSkeleton sx={{mt:5}} />
        <CartItemSkeleton sx={{mt:5}} />
        <CartItemSkeleton sx={{mt:5}} />
    </Box>
  )
}

export default CartItemsLoaderMinimal

const CartItemSkeleton = ({sx})=>(
    <Box sx={{display:'flex', alignItems:'center', gap:'20px', ...sx}}>
        <Skeleton variant="rounded" width="57px" height="60px" sx={{borderRadius:'12px'}} />

        <Box flex={1}>
            <Skeleton variant="rounded" width="230px" height="12px" sx={{ mb:'12px !important' }} />
            <Skeleton variant="rounded" width="140px" height="12px"  />
        </Box>

        <Box display='flex' alignItems='center' gap='35px'>
            <Stack alignItems='center' mr={1.5}>
                <Skeleton variant="rounded" width="80px" height="10px" sx={{ mb:'8px !important' }} />
                <Skeleton variant="rounded" width="60px" height="10px" sx={{ mb:'8px !important' }} />
            </Stack>

            <Stack alignItems='center' ml={1}>
                <Skeleton variant="rounded" width="90px" height="10px" sx={{ mb:'8px !important' }} />
                <Skeleton variant="rounded" width="60px" height="10px" sx={{ mb:'8px !important' }} />
            </Stack>

            <Stack alignItems='center' mr={2}>
                <Skeleton variant="rounded" width="70px" height="10px" sx={{ mb:'8px !important' }} />
                <Skeleton variant="rounded" width="30px" height="10px" sx={{ mb:'8px !important' }} />
            </Stack>
        </Box>
    </Box>
)