import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Mail, WhatsappOutlined, Logout, Dashboard } from "@mui/icons-material";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import {
  productLabelColor,
  sidebarHeadingColor,
  topbarColor,
  whiteColor,
  offerTextColor,
  trackColor,
  whatsappIconColor,
  greyShade32,
  navHeaderColor,
  blackColor,
  greyShade003,
} from "../../configs/styles/muiThemes";
import { useDispatch, useSelector } from "react-redux";
import { getDataForSideBar } from "../../redux/subCatergory/subCategorySlice";

import { Link, useNavigate } from "react-router-dom";
import { getAccountDetails } from "redux/dashboard/dashboardSlice";
import { geProfilePhoto, getPhoneNumber, getUserName } from "utils/authHelpers";
import {
  navBar_MenuCounpons,
  navBar_MenuLoginSignup,
  navBar_MenuOverview,
  navBar_MenuReview,
  sideBar_MainMenu,
  navBar_MenuHome,
  navBar_MenuCatalog,
  sideBar_CategoryHeading,
  contactLabel,
  costBoEmailText,
  costBoContactNumberText,
  sideBar_Signout,
} from "configs/Constants";
import ProfileCharAvatar from "components/ProfileCharAvatar";
import styled from "@emotion/styled";

export default function Sidebar({
  onCloseClick,
  isLoggedIn,
  onLoginClick,
  onSubCatSelected,
  onLogOut,
  productCount,
  isBusinessClosed,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const dispatch = useDispatch();

  const { commercialDetails } = useSelector((state) => state.product);
  const { businessDetails } = useSelector((state) => state.header);
  const businessName = sessionStorage.getItem("businessName");
  const { accountDetails } = useSelector((state) => state.dashboard);
  const { productsByCategory, loading } = useSelector(
    (state) => state.subCategory
  );

  const whatsAppNumber =
    commercialDetails?.info?.costboPowered?.whatsAppNumber?.replace("+", "");

  const emailAddress =
    commercialDetails?.info?.costboPowered?.supportEmail ||
    "support@costbo.com";

  const handleChatClick = () => {
    if (whatsAppNumber) {
      window.open(
        // `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=Hi+${businessDetails?.businessInfo?.[0]?.businessName}+team,+I+have+a+query+about+the+products+in+your+webstore+${window.location.href}`,
        `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=Hi+${businessDetails?.businessInfo?.[0]?.businessName}+team,+I+have+a+query+about+the+products+in+your+webstore`,
        "_blank"
      );
    } else {
      window.fcWidget.open();
      window.fcWidget.show();
    }
  };

  useEffect(() => {
    dispatch(getDataForSideBar());
    if (!accountDetails && isLoggedIn) {
      dispatch(getAccountDetails());
    }
  }, []);

  let navigate = useNavigate();

  return (
    <Box
      sx={{
        width: "260px",
        backgroundColor: greyShade003,
        height: "100vh !important",
      }}
    >
      <IconButton
        sx={{
          position: "absolute",
          top: "10px",
          left: "227px",
          zIndex: "9999 !important",
        }}
        onClick={() => onCloseClick()}
      >
        <CloseRoundedIcon sx={{ color: "#fff", fontSize: "18px" }} />
      </IconButton>

      <Box>
        <Grid
          container
          padding={{ md: 1, xs: 1 }}
          // sx={{ background: topbarColor }}
          sx={{ background: blackColor }}
          alignItems="center"
          borderRadius={{ md: "none", xs: "0 0 19px 19px" }}
        >
          <Grid item pl={1}>
            {isLoggedIn ? (
              geProfilePhoto() === "" ? (
                <ProfileCharAvatar
                  bgcolor={whiteColor}
                  imgwidth={"33px"}
                  imgheight={"33px"}
                  username={getUserName()}
                />
              ) : (
                <Avatar
                  alt="User"
                  sx={{ width: "33px", height: "33px" }}
                  src={geProfilePhoto()}
                />
              )
            ) : (
              <Avatar
                alt="User"
                sx={{ width: "33px", height: "33px" }}
                src={geProfilePhoto()}
              />
            )}
          </Grid>
          <Grid item pl={1.5}>
            <Stack>
              <Typography
                variant="h6"
                fontSize={13}
                fontWeight={600}
                color={whiteColor}
                lineHeight={1.7}
              >
                {isLoggedIn ? getUserName() : "Welcome!"}
              </Typography>

              {isLoggedIn ? (
                <Typography
                  variant="body2"
                  fontSize={12}
                  fontWeight={400}
                  color={whiteColor}
                  lineHeight={1.5}
                >
                  {getPhoneNumber()}
                </Typography>
              ) : (
                <Typography
                  variant="body2"
                  color={whiteColor}
                  sx={{ cursor: "pointer" }}
                  onClick={onLoginClick}
                >
                  {navBar_MenuLoginSignup}
                </Typography>
              )}
            </Stack>
          </Grid>
        </Grid>
        <Box paddingX={1.5} paddingY={0.5}>
          <Typography
            variant="h6"
            fontSize={"12.5px"}
            color={{ md: "#a0a0b2", xs: "#a0a0b2" }}
            fontWeight={400}
            pl={"2px"}
          >
            Menu
          </Typography>
          <List component="nav" sx={{ p: "0 !important", mb: 1 }}>
            <ListItem
              sx={{ p: "0 !important", pt: 0 }}
              component={Link}
              to={isLoggedIn ? `/${businessName}/Dashboard` : "#"}
              onClick={() => {
                // onCloseClick()
                onLoginClick();
              }}
            >
              <ListItemIcon sx={{ minWidth: "24px" }}>
                {matches ? (
                  <Box
                    component="img"
                    src={
                      "https://storage.googleapis.com/bodefaults/shopweb/orders/dashboard.svg"
                    }
                    alt="CostBo"
                    fontSize={"20px"}
                    //  ml={0.3}
                  />
                ) : (
                  // <Dashboard
                  //   sx={{
                  //     fontSize: '20px',
                  //     color: isLoggedIn ? productLabelColor : offerTextColor,
                  //   }}
                  // />
                  <Box
                    component="img"
                    src={
                      "https://storage.googleapis.com/bodefaults/shopweb/orders/dashboard.svg"
                    }
                    alt="CostBo"
                    fontSize={"20px"}
                    // ml={0.3}
                  />
                )}
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={styles.listText(isLoggedIn)}
                primary="Dashboard"
              />
            </ListItem>

            <ListItem
              sx={{ p: "0 !important", pt: 0 }}
              component={Link}
              to={isLoggedIn ? `/${businessName}/AllOrders` : "#"}
              onClick={() => {
                onCloseClick();
              }}
            >
              <ListItemIcon sx={{ minWidth: "24px" }}>
                {matches ? (
                  <Box
                    component="img"
                    src={
                      "https://storage.googleapis.com/bodefaults/shopweb/orders/order.svg"
                    }
                    alt="CostBo"
                    fontSize={"20px"}
                    // ml={0.3}
                  />
                ) : (
                  // <ShoppingCartIcon
                  //   sx={{
                  //     fontSize: '20px',
                  //     color: isLoggedIn
                  //       ? { md: productLabelColor, xs: blackColor }
                  //       : { md: offerTextColor, xs: blackColor },
                  //   }}
                  // />
                  <Box
                    component="img"
                    src={
                      "https://storage.googleapis.com/bodefaults/shopweb/orders/order.svg"
                    }
                    alt="CostBo"
                    fontSize={"20px"}
                    // ml={0.3}
                  />
                )}
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={styles.listText(isLoggedIn)}
                primary="My Orders"
              />
            </ListItem>
          </List>
          <Divider />
        </Box>
      </Box>
      <Box
        sx={{ height: "74vh", overflowY: "auto", pb: { xs: 22, md: 0 } }}
        // paddingY={1}
      >
        {productCount == 0 || isBusinessClosed ? null : (
          <Box paddingX={"12px"} pb={0.5}>
            <Typography
              variant="h6"
              fontSize={"12.5px"}
              color={{ md: "#a0a0b2", xs: "#a0a0b2" }}
              pt={0.5}
              fontWeight={400}
              pl={"2px"}
            >
              {sideBar_CategoryHeading}
            </Typography>
            {loading === false &&
              productsByCategory &&
              productsByCategory.length > 0 && (
                <Box pb={1}>
                  <List
                    component="nav"
                    sx={{ p: `0 !important`, cursor: "pointer" }}
                  >
                    {productsByCategory.map((item, index) => {
                      return (
                        <ListItem
                          sx={{ p: `0 !important`, cursor: "pointer" }}
                          onClick={() => {
                            // onSubCatSelected(item)
                            navigate(`/${businessName}/catalog`, {
                              state: { categorySideBar: item },
                            });
                            onCloseClick();
                          }}
                        >
                          <ListItemText
                            primaryTypographyProps={styles.listTextStyle}
                            primary={item.subCatValue}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </Box>
              )}

            <Divider />
          </Box>
        )}

        <Box paddingX={"12px"}>
          <Typography
            variant="h6"
            fontSize={"12.5px"}
            color={{ md: "#a0a0b2", xs: "#a0a0b2" }}
            pt={0.5}
            pb={1}
            fontWeight={400}
            pl={"2px"}
          >
            {matches ? contactLabel : "Supports"}
          </Typography>
          <List component="nav" sx={{ p: `0 !important` }}>
            <Box
              display="flex"
              sx={{ p: `0 !important` }}
              component="a"
              href={`https://mail.google.com/mail/u/0/?fs=1&to=${emailAddress}&tf=cm`}
              target={"_blank"}
              onClick={() => {
                onCloseClick();
              }}
            >
              <Box
                component="img"
                src={
                  "https://storage.googleapis.com/bodefaults/shopweb/supportANDFooter/email-mobile.svg"
                }
                alt="CostBo"
                fontSize={"20px"}
                ml={0.3}
                mr={1.5}
              />

              <Typography sx={styles.listTextStyle}>
                {costBoEmailText}
              </Typography>
            </Box>
            <Box
              display="flex"
              sx={{ p: 0, pt: 1, cursor: "pointer" }}
              component="a"
              // href='https://api.whatsapp.com/send?phone=918088422611'
              target={"_blank"}
              onClick={() => {
                handleChatClick();
                // onCloseClick()
              }}
            >
              <Box
                component="img"
                src={
                  "https://storage.googleapis.com/bodefaults/shopweb/supportANDFooter/contact-us.svg"
                }
                alt="CostBo"
                fontSize={"20px"}
                mr={1.5}
              />

              <Typography sx={styles.listTextStyle}>
                {costBoContactNumberText}
              </Typography>
            </Box>
          </List>
        </Box>
        <Box paddingX={"12px"} mt={2}>
          <Divider />
        </Box>
        <Box
          paddingX={"12px"}
          paddingY={0.5}
          display={{ md: "none", xs: "block" }}
        >
          <Typography
            variant="h6"
            fontSize={"12.5px"}
            color={{ md: sidebarHeadingColor, xs: "#a0a0b2" }}
            pt={0.5}
          >
            Information
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              // p: '0 0 0 5px',
              mb: 1.5,
            }}
          >
            <PageLink
              linkText="Terms & Condtions"
              onClick={() => {
                navigate("/termsAndConditions");
              }}
            />
            <PageLink
              linkText="Privacy & Policy"
              onClick={() => {
                navigate("/privacyPolicy");
              }}
            />
            <PageLink
              linkText="Return Policy"
              onClick={() => {
                navigate("/return-policy");
              }}
            />
            <PageLink
              linkText="Cancellation Policy"
              onClick={() => {
                navigate("/cancellation-policy");
              }}
            />
          </Box>

          {isLoggedIn && (
            <Box>
              <Divider />
              <Box paddingY={0.5}>
                <List
                  component="nav"
                  disablePadding
                  onClick={onLogOut}
                  sx={{ cursor: "pointer" }}
                >
                  <ListItem sx={{ p: 0 }}>
                    <ListItemIcon sx={{ minWidth: "30px" }}>
                      <Logout
                        sx={{ fontSize: "20px", color: productLabelColor }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={styles.listTextStyle}
                      primary={sideBar_Signout}
                    />
                  </ListItem>
                </List>
              </Box>
            </Box>
          )}
          <Divider />
          <Typography
            mt={1.5}
            // textAlign="center"
            fontSize={12.5}
            color={blackColor}
            fontWeight={500}
          >
            Secure E-Commerce powered by
          </Typography>
          <Box
            sx={{
              display: "flex",
              mt: 1,
              gap: 2.5,
            }}
          >
            <Box
              component="img"
              src={`https://storage.googleapis.com/bodefaults/shopweb/logo-ondc.svg`}
              width="75px"
              height="auto"
              alt={"ONDC"}
              mt="3px"
              mr="6px"
            />
            <Box
              component="img"
              src={`https://storage.googleapis.com/bodefaults/shopweb/costbo-logo.svg`}
              alt={" CostBo"}
              width="110px"
              height="auto"
              display="block"
            />
          </Box>

          {/* <List component='nav'>
            <Box
              display='flex'
              sx={{ p: 0 }}
              component='a'
              href='https://mail.google.com/mail/u/0/?fs=1&to=support@costbo.com&tf=cm'
              target={'_blank'}
              onClick={() => {
                onCloseClick()
              }}
            >
              <Mail sx={{ fontSize: '20px', color: trackColor, mr: 1 }} />
              <Typography sx={styles.listTextStyle}>
                {costBoEmailText}
              </Typography>
            </Box>
            <Box
              display='flex'
              sx={{ p: 0, pt: 1.7 }}
              component='a'
              href='https://api.whatsapp.com/send?phone=918088422611'
              target={'_blank'}
              onClick={() => {
                onCloseClick()
              }}
            >
              <WhatsappOutlined
                sx={{ fontSize: '20px', color: whatsappIconColor, mr: 1 }}
              />
              <Typography sx={styles.listTextStyle}>
                {costBoContactNumberText}
              </Typography>
            </Box>
          </List> */}
        </Box>
      </Box>
    </Box>
  );
}

const styles = {
  listText: (isLoggedIn) => ({
    fontSize: "13px",
    fontWeight: { md: 600, xs: 600 },
    color: isLoggedIn
      ? { md: blackColor, xs: blackColor }
      : { md: offerTextColor, xs: offerTextColor },
    display: "block",
  }),
  listTextStyle: {
    fontSize: "13px",
    fontWeight: { md: 600, xs: 600 },
    color: { md: blackColor, xs: blackColor },
    display: "block",
  },
};

const PageLink = ({ linkText, onClick }) => {
  return (
    <StyledLink
      variant="body1"
      fontSize={12}
      fontWeight={{ md: 400, xs: 500 }}
      lineHeight="25px"
      color={{ md: greyShade32, xs: blackColor }}
      sx={{ cursor: "pointer", whiteSpace: "nowrap" }}
      onClick={onClick}
    >
      {linkText}
    </StyledLink>
  );
};
const StyledLink = styled(Typography)({
  position: "relative",
  margin: "0 0 0 15px",

  "&:hover": {
    textDecoration: "underline",
  },

  "&:before": {
    content: '" "',
    width: "3px",
    height: "3px",
    borderRadius: "5px",
    backgroundColor: greyShade32 + "80",
    position: "absolute",
    top: "10px",
    left: "-8px",
  },
});
