import React from 'react'
import AddressCard from './AddressCards/AddressCard'
import { Box, Container } from '@mui/material'
import { backgroundColor01, whiteColor } from 'configs/styles/muiThemes'
import AddressForm from './AddressCards/AddressForm'
import BreadcrumbsSection from 'components/Breadcrumbs'
import { useLocation } from 'react-router-dom'

function AddAddress({
  title,
  accountDetails,
  addressDetails,
  handleAddAddressClose,
  handleUpdateAddressClose,
  editAddress,
  setSeletedAddress,
  setErrorMessage,
}) {
  // const location = useLocation();
  return (
    <Box
      backgroundColor={{ md: whiteColor, xs: backgroundColor01 }}
      width={"100vw"}
    >
      <Box
        display={{ xs: "block", md: "none" }}
        sx={{
          position: "sticky !important",
          top: 0,
          width: "100%",
          zIndex: 10,
          backgroundColor: whiteColor,
          pt: 0.3,
        }}
      >
        {/* <DiscountHeaderBreadcrumbs
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        /> */}
        <MobileAddressHeaderBreadcrums
        //   currentPage={currentPage}
        //   setCurrentPage={setCurrentPage}
        />
        <AddressForm
          title="Add Address"
          accountDetails={accountDetails}
          addressDetails={addressDetails}
          handleAddAddressClose={handleAddAddressClose}
          setSeletedAddress={setSeletedAddress}
          setErrorMessage={setErrorMessage}
        />
      </Box>
    </Box>
  );
}

export default AddAddress

const MobileAddressHeaderBreadcrums = ({
  currentPage,
  setCurrentPage,
}) => {
  return (
    <Container
      sx={{
        maxWidth: '1375px !important',
        mt: { xs: 0, md: 2.5 },
        // width: {md:'100%'}
        p:0
      }}
    >
      <BreadcrumbsSection
        links={[
          { label: 'Catalog', to: `/catalog` },
          { label: 'Cart', to: `/cart` },
        ]}
        detail={'Add New Address'
        //   currentPage === 'address-page'
        //     ? 'Add Address'
        //     : ''
        }
        currentPage={currentPage}
      />
    </Container>
  )
}