import { Box, Skeleton, Typography } from "@mui/material";
import {
  backgroundColor01,
  blackColor,
  borderbackgroundColor03,
  dashboardInfoColor,
  navHeaderColor,
  stepperInactiveColor,
} from "../../configs/styles/muiThemes";

function DasboardInfoCard({ title, label, value }) {
  return (
    <Box border={`1px solid ${borderbackgroundColor03}`} borderRadius={1.5}>
      <Typography
        color={blackColor}
        py={0.625}
        px={1.25}
        fontWeight={500}
        mb="3px !important"
        fontSize={13}
        sx={{ backgroundColor: backgroundColor01 }}
        borderRadius={1}
      >
        {title}
      </Typography>
      <Box px={1.5} mb={1}>
        {value ? (
          <Typography
            variant="subtitle1"
            component="p"
            fontSize="13px"
            lineHeight="24px"
            color={blackColor}
            display="inline"
          >
            {label}
            {/* {": "} */}
            <Typography
              variant="subtitle1"
              component="p"
              fontSize="13px"
              fontWeight={500}
              color={blackColor}
              display="inline"
            >
              {value}
            </Typography>
          </Typography>
        ) : (
          <Skeleton
            variant="rounded"
            width="50px"
            height="12px"
            sx={{ my: "8px !important" }}
          />
        )}
      </Box>
    </Box>
  );
}

export default DasboardInfoCard;
