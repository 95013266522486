import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import {
  backgroundColor01,
  blackColor,
  businessBodyColor,
  greyColor9,
  headingColor,
  secondaryColor,
  stepperInactiveColor,
  whiteColor,
} from '../../configs/styles/muiThemes'

function BusinessInfoCard(props) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))

  return (
    <Box sx={styles.card} mt={2}>
      <Grid container direction='row' spacing={2}>
        <Grid item mt={0.5}>
          <Box sx={styles.iconCircle}>{props.icon}</Box>
        </Grid>

        <Grid item xs>
          <Box>
            <Typography
              variant='h5'
              align='left'
              fontSize='13.5px'
              color={blackColor}
              fontWeight={600}
              marginBottom='4px !important'
            >
              {props.title}
            </Typography>
            {!props.address ? (
              <Typography
                variant='body1'
                color={greyColor9}
                fontSize={12.5}
                fontWeight={400}
                lineHeight='18px'
              >
                {props.description}
              </Typography>
            ) : null}
            {props.address  ? (
              <Typography
                variant='body1'
                color={greyColor9}
                fontSize={12.5}
                fontWeight={400}
                lineHeight='18px'
                // mt={1}
                // sx={{
                //   borderTop: '1px solid #e9eaed',
                //   p: '10px 5px',
                // }}
              >
                {props.description}
              </Typography>
            ) : null}
          </Box>
        </Grid>
      </Grid>
      
    </Box>
  )
}

export default BusinessInfoCard

const styles = {
  card: {
    borderRadius: '10px',
    padding: '12px 10px',
    background: whiteColor,
   border: `1px solid #e6ebf4`,
    marginBottom: '15px',

  },

  iconCircle: {
    width: 40,
    height: 40,
    fontSize: '17px',
    borderRadius: '50%',
    backgroundColor: backgroundColor01,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: whiteColor,
  },
}
