import {
  Avatar,
  Badge,
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React from 'react'
import {
  backgroundColor,
  badgeBackgroundColor,
  blackColor,
  borderCart,
  borderProduct,
  headingColor,
  orangeColor01,
  whiteColor,
} from '../configs/styles/muiThemes'
import { Link } from 'react-router-dom'
import Slider from 'react-slick/lib/slider'
import { goToCartText } from 'configs/Constants'
import { Row } from 'react-bootstrap'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

function CartDetailsBottonCard({
  itemCount,
  amount,
  onGoToCartClicked,
  cartProductList,
  gapdown,
  bottomPadding
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const businessName = sessionStorage.getItem('businessName')
  const cartListSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  }

  if (cartProductList?.productList?.length == 0) return <></>

  return (
    <Box
      backgroundColor={{ md: whiteColor, xs: 'none' }}
      p={{ md: '8px 0', xs: 0 }}
      sx={styles.cartBottomCard}
    >
      {matches ? (
        <Grid
          container
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          {/* <Box sx={{ width: '50%' }}>
          <Slider {...cartListSettings}>
            {cartProductList?.productList?.map((item, index) => {
              return (
                <ProductCount
                  src={item.productPicURL}
                  alt={item.productName}
                  count={item.currentQuantity}
                  key={'cart_product_' + index}
                  mx={0.5}
                />
              )
            })}
          </Slider>
        </Box> */}

          <Grid item>
            <Typography
              variant='h6'
              color={headingColor}
              fontSize='13px'
              fontWeight={500}
              display='inline'
              px={2}
              borderRight={borderCart}
            >
              {itemCount} items in cart
            </Typography>

            <Typography
              variant='h5'
              color={headingColor}
              fontSize='15px'
              fontWeight={600}
              display='inline'
              mx={2}
            >
              ₹ {Number(amount).toFixed(2)}
            </Typography>
          </Grid>

          <Grid item>
            <Button
              sx={styles.gotoCardBtn}
              variant='contained'
              disableElevation
              size='small'
              // onClick={() => {onGoToCartClicked()}}
              component={Link}
              to={`/${businessName}/Cart`}
            >
              {goToCartText}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <>
          <Box sx={{ ...styles.mobileInfoCard, mb: gapdown ? gapdown : 0, bottom: bottomPadding || '15px', }}>
            <Box display={'flex'} flexDirection={{ md: 'row', xs: 'column' }}>
              <Typography
                variant='h6'
                // color={{ md: headingColor, xs: '#9ba3aa' }}
                color={{ md: headingColor, xs: whiteColor }}
                fontSize={{ md: '13px', xs: 11.5 }}
                fontWeight={400}
                borderRight={{ md: borderCart, xs: 'none' }}
                lineHeight='18px'
              >
                {itemCount} items in cart
              </Typography>
              <Typography
                variant='h5'
                color={{ md: headingColor, xs: whiteColor }}
                fontSize={{ md: '15px', xs: 14 }}
                fontWeight={600}
                display='inline'
                lineHeight='24px'
              >
                ₹ {Number(amount).toFixed(2)}
              </Typography>
            </Box>

            <Box display={'flex'} component={Link} to={`/${businessName}/Cart`}>
              <Button
                sx={styles.gotoCardBtn}
                variant='contained'
                disableElevation
                size='small'
                // onClick={() => {onGoToCartClicked()}}
                component={Link}
                to={`/${businessName}/Cart`}
              >
                {goToCartText}
              </Button>
              <Box
                sx={{
                  width: '20px',
                  height: '20px',
                  backgroundColor: '#fff',
                  color: '#000',
                  borderRadius: '50px',
                  fontSize: '27px',
                  padding: '7px',
                  // marginLeft: '6px',
                  display: 'flex',
                  alignItems: 'center',
                }}
                component={Link}
                to={`/${businessName}/Cart`}
              >
                <NavigateNextIcon fontSize='27px' sx={{ ml: -0.4 }} />
              </Box>
            </Box>
          </Box>
          {/* </Box> */}
          {/* </Box> */}
        </>
      )}
    </Box>
  )
}

export default CartDetailsBottonCard

const ProductCount = ({ src, alt, count }) => {
  let businessFullName = sessionStorage.getItem('businessFullName')

  return (
    <Box py={0.3} px={0.6}>
      <Badge
        sx={styles.badge}
        overlap='circular'
        badgeContent={count}
        // sx={{ m: 2 }}
      >
        <Box
          component='img'
          src={src}
          alt={businessFullName + ' CostBo'}
          backgroundColor={whiteColor}
          sx={styles.itemCard}
        />
      </Badge>
    </Box>
  )
}

const styles = {
  cartBottomCard: {
    width: "100vw",
    display: { xs: "block", md: "none" },
    borderBottom: "1.5px solid #f4f6fa",
    position: "fixed",
    bottom: "1px",
    left: 0,
    zIndex: 10,
  },

  gotoCardBtn: {
    fontSize: { md: "12px", xs: 12.5 },
    fontWeight: { md: 500, xs: 600 },
    color: whiteColor,
    backgroundColor: { xs: blackColor, md: orangeColor01 },
    p: { md: "3px 15px", xs: "6px 15px" },
    border: "2px solid " + { md: orangeColor01, xs: blackColor },
    textTransform: "uppercase",
    borderRadius: "6px",
    m: { md: "0 10px 0 0 !important", xs: "0" },
    "&:hover": {
      backgroundColor: { xs: whiteColor },
      color: blackColor,
    },
  },

  itemCard: {
    width: "45px",
    height: "45px",
    border: borderProduct,
    borderRadius: "10px",
    p: 0.5,
  },

  badge: {
    "& .MuiBadge-badge": {
      color: whiteColor,
      backgroundColor: badgeBackgroundColor,
    },
  },

  mobileInfoCard: {
    position: "fixed",
    width: "85%",

    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 2,
    maxWidth: "600px",
    height: "auto",
    display: "flex",
    alignItems: "center",
    padding: "6px 7px 6px 24px",
    backgroundColor: "rgb(0, 0, 0)",
    // boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
    borderRadius: "50px",
    justifyContent: "space-between",
  },
};
