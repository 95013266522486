import { Box, Container, Stack, useMediaQuery } from "@mui/material";
import React from "react";
import HeaderLoader from "./HeaderLoader";
import BreadcrumbLoader from "./BreadcrumbLoader";
import { bgColor } from "configs/styles/muiThemes";
import CatelogHeaderShimmer from "components/ShimmerComponents/CatelogHeaderShimmer";
import CatalogBodyShimmer from "components/ShimmerComponents/CatalogBodyShimmer";

function CatalogLoader() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return matches ? (
    <Box bgcolor={bgColor}>
      <HeaderLoader hideBanners />
      <BreadcrumbLoader />
      <Container
        sx={{
          maxWidth: "1375px !important",
          mt: 1.5,
        }}
      >
        <CatelogHeaderShimmer />
        <CatalogBodyShimmer />
      </Container>
    </Box>
  ) : (
    <Stack>
      <HeaderLoader hideBanners />
      <CatelogHeaderShimmer />
      <CatalogBodyShimmer />
    </Stack>
  );
}

export default CatalogLoader;
