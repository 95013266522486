import { Box, Typography } from '@mui/material'
import {
  headingColor,
  backgroundColor,
  sidebarHeadingColor,
} from 'configs/styles/muiThemes'

function FinboxStatusCard({ img, msg, btnName, onBtnClick }) {
  return (
    <Box
      sx={{
        background: backgroundColor,
        p: '4px',
        // boxShadow: '0 1px 4px rgb(0 0 0 / 8%)',
      }}
      display={'flex'}
      alignItems='center'
      justifyContent={{ md: 'center', xs: 'start' }}
    >
      <Box
        display={'flex'}
        alignItems='center'
        justifyContent={{ md: 'center', xs: 'start' }}
        gap={1.5}
      >
        <Box component={'img'} src={img} width='45px' />
        <Box
          display={'flex'}
          alignItems={{ md: 'center', xs: 'start' }}
          justifyContent={'center'}
          flexDirection={{ md: 'row', xs: 'column' }}
          gap={{ md: 1, xs: 0 }}
        >
          <Typography
            color={headingColor}
            variant='h5'
            fontWeight={500}
            fontSize={{ md: 14, xs: 12.5 }}
          >
            {msg}
          </Typography>
          <Typography
            color={sidebarHeadingColor}
            component='a'
            fontSize={12}
            onClick={onBtnClick}
            sx={{
              cursor: 'pointer',
              textDecoration: 'underline',
              ml: { md: 1, xs: 0 },
            }}
          >
            {btnName}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default FinboxStatusCard
