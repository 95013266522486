import { getApiInstance } from "services/ApiInstance";
import { UNICOM_CREATE_SALE_ORDER, UNICOM_VERIFY_SALE_ORDER } from "services/constant";

export const createUnicomSaleOrderApi = async (postData) => {
  const businessId = sessionStorage.getItem("businessId");
  try {
    const instance = getApiInstance({
      header: { businessId },
    });

    const response = await instance.post(
      UNICOM_CREATE_SALE_ORDER + `?catalogPartner=unicommerce`,
      postData
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const verifyUnicommerceSaleOrder = async (data) => {
    const businessId = sessionStorage.getItem("businessId");
    try {
      const params = new URLSearchParams();
        params.append("catalogPartner", "unicommerce");
        params.append("saleOrderCode", data?.orderId);
  
      const instance = getApiInstance({
        header: {
          Facility: data?.facilityCode,
          businessId: businessId,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
  
      const response = await instance.post(UNICOM_VERIFY_SALE_ORDER, params);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };