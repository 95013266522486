import { Box, Typography } from '@mui/material'
import moment from 'moment/moment'
import React from 'react'
import {
  backgroundColor01,
  blackColor,
  greyColor3,
  greyColor9,
  greyShade36,
  socialTrendBorder,
} from '../../configs/styles/muiThemes'

function PaymentCard({
  logo,
  businessName,
  amountEarned,
  transactionAmount,
  commissionPercentage,
  commissionAmount,
  settledDate,
}) {
  return (
    <Box sx={styles.card}>
      <Box sx={styles.cardHeader}>
        <Box display='flex' alignItems='center' gap='8px'>
          <Box
            component='img'
            src={logo}
            alt='costbo'
            sx={styles.businessLogo}
          />
          <Typography
            variant='h6'
            fontSize={15}
            fontWeight={600}
            lineHeight='18px'
            color={blackColor}
          >
            {businessName}
          </Typography>
        </Box>

        {/* <Typography
          variant='body2'
          fontSize={14}
          fontWeight={500}
          color={greyColor9}
        >
          Commission Earned
        </Typography> */}

        <Typography
          variant='body1'
          fontSize={15}
          fontWeight={600}
          lineHeight='18px'
          color={blackColor}
        >
          ₹{Number(amountEarned).toFixed(2)}
        </Typography>
      </Box>

      <Box sx={styles.cardFooter}>
        <InfoText
          label='Txn Amount'
          value={`₹${Number(transactionAmount).toFixed(2)}`}
        />
        <InfoText
          label='Txn Date'
          value={moment(settledDate).format('DD-MM-YYYY')}
        />
        <InfoText label='Commission' value={`${commissionPercentage}%`} />
      </Box>
    </Box>
  )
}

export default PaymentCard

const InfoText = ({ label, value }) => {
  return (
    <Box>
      <Typography
        variant='body2'
        fontSize={11}
        fontWeight={400}
        lineHeight='12px'
        color={blackColor}
      >
        {label}
      </Typography>

      <Typography
        variant='body2'
        fontSize={12}
        fontWeight={600}
        lineHeight='14px'
        color={blackColor}
        mt='7px !important'
      >
        {value}
      </Typography>
    </Box>
  )
}

const styles = {
  card: {
    borderRadius: '8px',
    border: socialTrendBorder,
    overflow: 'hidden',
    // marginBottom: 2,
  },

  businessLogo: {
    width: '42px',
    height: '42px',
    borderRadius: '10px',
    border: socialTrendBorder,
  },

  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 12px 8px',
  },

  cardFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 15px',
    backgroundColor: backgroundColor01,
  },
}
