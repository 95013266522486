import { Box, Typography } from '@mui/material'
import { blackColor, whiteColor } from 'configs/styles/muiThemes'
import React from 'react'

function UserCharAvatar({ username, imgwidth, imgheight }) {
  const getInitials = (str) => {
    if(!str) return 'Gu'

    const words = str.trim().split(' ')

    if (words.length === 1) {
      // Return first two characters for a single word
      return words[0].slice(0, 2)
    } else {
      // Return first character of each of the first two words
      return words[0][0] + words[1][0]
    }
  }

  return (
    <Box
      width={imgwidth ? imgwidth : '40px'}
      height={imgheight ? imgheight : '40px'}
      borderRadius='50%'
      backgroundColor='#B4B4CA'
      display='flex'
      alignItems='center'
      justifyContent='center'
    >
      <Typography
        variant='boby1'
        fontSize={{ xs: 14, md: 13 }}
        fontWeight={400}
        color={whiteColor}
      >
        {getInitials(username)}
      </Typography>
    </Box>
  )
}

export default UserCharAvatar
