import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  getAllActiveProducts,
  getAllProducts,
  getCategoryDetails,
  getProductsBySubCat,
  getSubCategoryData,
} from '../../services/api_calls/subCategory/SubCategoryApis'

const initialState = {
  productsByCategory: undefined || {},
  allProducts: undefined || {},
  category: [] | [],
  loading: false,

  //Loaders
  dataForSideBarLoading:false
}

//Fetching overview details
export const getDataForSideBar = createAsyncThunk(
  'getDataForSideBar',
  async (param, thunkAPI) => {
    let res = {}
    if (param) {
      res = await getSubCategoryData(param)
    } else {
      res = await getSubCategoryData(param)
      sessionStorage.setItem('data', JSON.stringify(res))
    }

    return res
  }
)

//Fetching overview details
export const getDataBySubCategory = createAsyncThunk(
  'getDataBySubCategory',
  async (param, thunkAPI) => {
    const res = await getProductsBySubCat(param)
    return res
  }
)

export const getCatalogAllProducts = createAsyncThunk(
  'getCatalogAllProducts',
  async (param, thunkAPI) => {
    const res = await getAllActiveProducts(param)
    // const res = await getAllProducts(param)
    console.log(res)
    return res
  }
)

export const getAllProductsWithCartCount = createAsyncThunk(
  'getAllProductsWithCartCount',
  async (param, thunkAPI) => {
    let products = param ? param?.products : null
    const cartData = param ? param?.cartData : []

    let productsWithCount = []

    products?.productList?.forEach((item) => {
      cartData?.forEach((product) => {
        if (product?.productId === item?.id) {
          // console.log('ALL_PRODUCT_CART_COUNT', product?.productId)
          // console.log('ALL_PRODUCT_CART_COUNT2', item?.id)
          productsWithCount.push({
            ...item,
            cartCount: product?.currentQuantity,
          })
        }
      })

      const isPresent = productsWithCount.filter((filterProduct) => {
        return filterProduct?.id === item?.id
      })

      if (isPresent?.length === 0) {
        productsWithCount.push(item)
      }
    })

    console.log('ALL_PRODUCT_WITH_COUNT:', productsWithCount)
    return { ...products, productList: productsWithCount }
  }
)

export const getCategoryDataFinder = createAsyncThunk(
  'getCategoryDataFinder',
  async (thunkAPI) => {
    const res = await getCategoryDetails()
    console.log('CATEGORY_TREE', res)
    return res
  }
)

export const subCategorySlice = createSlice({
  name: 'subCategory',
  initialState,
  reducers: {},
  extraReducers: {
    [getDataForSideBar.pending]: (state) => {
      state.dataForSideBarLoading = true
      state.loader = true
    },
    [getDataForSideBar.fulfilled]: (state, { payload }) => {
      state.dataForSideBarLoading = false
      state.loader = false
      state.productsByCategory = payload
    },
    [getDataForSideBar.rejected]: (state) => {
      state.dataForSideBarLoading = false
      state.loader = false
    },

    [getDataBySubCategory.pending]: (state) => {
      state.loader = true
    },
    [getDataBySubCategory.fulfilled]: (state, { payload }) => {
      state.loader = false
      state.productsByCategory = payload
    },
    [getDataBySubCategory.rejected]: (state) => {
      state.loading = false
    },

    [getCatalogAllProducts.pending]: (state) => {
      state.loader = true
    },
    [getCatalogAllProducts.fulfilled]: (state, { payload }) => {
      state.loader = false
      state.allProducts = payload
    },
    [getCatalogAllProducts.rejected]: (state) => {
      state.loader = false
    },

    [getCategoryDataFinder.pending]: (state) => {
      state.loader = true
    },
    [getCategoryDataFinder.fulfilled]: (state, { payload }) => {
      state.loader = false
      state.category = payload
    },
    [getCategoryDataFinder.rejected]: (state) => {
      state.loader = false
    },

    //get all products with count
    [getAllProductsWithCartCount.pending]: (state) => {
      state.loader = true
    },
    [getAllProductsWithCartCount.fulfilled]: (state, { payload }) => {
      state.loader = false
      state.allProducts = payload
    },
    [getAllProductsWithCartCount.rejected]: (state) => {
      state.loader = false
    },
  },
})

export const subCategoryReducer = subCategorySlice.reducer
