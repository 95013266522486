import { Box, Typography } from "@mui/material";
import React from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  blackColor,
  facebookBGColor,
  linkedInBGColor,
  telegramBGColor,
  twitterBGColor,
  whatsappBGColor,
  whiteColor,
} from "../../configs/styles/muiThemes";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";

function AffiliateShareMenu({
  url,
  quote,
  onClick,
  hideLabel,
  whatsappShareRef,
  faceBookShareRef,
  twitterShareRef,
  linkedInShareRef,
  telegramShareRef,
  isSpaceBetween,
}) {
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={{ xs: "space-between", md: "space-between" }}
        gap={{ md: isSpaceBetween ? "28px" : "15px", xs: "10px" }}
        width={"100%"}
      >
        <SocialIconItem
          icon={<WhatsAppIcon sx={{ fontSize: { xs: 18, md: 22 } }} />}
          label="WhatsApp"
          hideLabel={hideLabel}
          onClick={() => onClick("whatsapp")}
          color={whatsappBGColor}
        />

        <SocialIconItem
          icon={<FacebookOutlinedIcon sx={{ fontSize: { xs: 18, md: 22 } }} />}
          label="Facebook"
          hideLabel={hideLabel}
          onClick={() => onClick("facebook")}
          color={facebookBGColor}
        />

        <SocialIconItem
          icon={<LinkedInIcon sx={{ fontSize: { xs: 18, md: 22 } }} />}
          label="LinkedIn"
          hideLabel={hideLabel}
          onClick={() => onClick("linkedIn")}
          color={linkedInBGColor}
        />

        <SocialIconItem
          icon={<TwitterIcon sx={{ fontSize: { xs: 18, md: 22 } }} />}
          label="Twitter"
          hideLabel={hideLabel}
          onClick={() => onClick("twitter")}
          color={twitterBGColor}
        />

        <SocialIconItem
          icon={<TelegramIcon sx={{ fontSize: { xs: 18, md: 22 } }} />}
          label="Telegram"
          hideLabel={hideLabel}
          onClick={() => onClick("telegram")}
          color={telegramBGColor}
        />
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={2}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <WhatsappShareButton
            url={url}
            title={`${quote}\n`}
            ref={whatsappShareRef}
          ></WhatsappShareButton>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="center">
          <FacebookShareButton
            url={url}
            quote="hello"
            hashtag=""
            ref={faceBookShareRef}
          ></FacebookShareButton>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="center">
          <LinkedinShareButton
            url={url}
            title={quote}
            ref={linkedInShareRef}
          ></LinkedinShareButton>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="center">
          <TwitterShareButton
            url={`${quote}\n${url}`}
            ref={twitterShareRef}
          ></TwitterShareButton>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="center">
          <TelegramShareButton
            url={`${quote}\n${url}`}
            ref={telegramShareRef}
          ></TelegramShareButton>
        </Box>
      </Box>
    </>
  );
}

export default AffiliateShareMenu;

const SocialIconItem = ({ icon, label, color, onClick, hideLabel }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{ cursor: "pointer" }}
    >
      <Box sx={styles.iconStyle(color)} onClick={onClick}>
        {icon}
      </Box>

      {!hideLabel && (
        <Typography
          variant="body2"
          fontSize={{ md: 13, xs: 11 }}
          fontWeight={500}
          textAlign="center"
          color={blackColor}
        >
          {label}
        </Typography>
      )}
    </Box>
  );
};

const styles = {
  iconStyle: (bgColor) => ({
    width: { md: "36px", xs: "28px" },
    height: { md: "36px", xs: "28px" },
    backgroundColor: bgColor,
    borderRadius: "7px",
    fontSize: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: whiteColor,
    marginBottom: "4px",
  }),
};
