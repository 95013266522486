import React, { useEffect, useRef, useState } from "react";
import {
  addUOMToAttributeList,
  extractNameCodePairs,
  extractNames,
  filterMatchingCodes,
  getNameByCode,
  getValueByCode,
  getValueByPath,
} from "./VariantHelper";
import { getViewChildProductDetailsAPI } from "services/api_calls/subCategory/SubCategoryApis";
import { useDispatch, useSelector } from "react-redux";
import { Box, Divider, Grid, Stack, useMediaQuery } from "@mui/material";
import VariantProductContent from "./VariantProductContent";
import { getUserName } from "utils/authHelpers";
import ProductGalleryModal from "../ProductGalleryModal";
import ProductVariantData from "../ProductVariantData";
import ProductDescriptionBody from "../ProductDescriptionBody";
import DescriptionSpecificationTabs from "../DescriptionSpecificationTabs";
import { getVariantArray } from "./VariantHelper";
import { getActiveProductVariants } from "./VariantHelper";
import { getAvailableVariants } from "./VariantHelper";
import { getProductByVariants } from "./VariantHelper";
import ModalCard from "components/ModalCard";
import ProductImages from "../ProductImages";
import ProductDescriptionHeader from "../ProductDescriptionHeader";
import {
  createCartLoginHelper,
  removeCartProductLoginHelper,
} from "utils/cartHelper";
import {
  addLocalCart,
  addNewProductCart,
  createCart,
  removeProductLocalCart,
  updateCartDetails,
  updateCartProductQuantity,
  updateToLocalCart,
} from "redux/cart/CartSlice";
import { getCartDetails } from "redux/addToCart/AddToCartSlice";
import { getProductDetails } from "redux/productDescription/ProductDescriptionSlice";
import { getProductData } from "services/api_calls/productDescription/ProductDescriptionApi";
import Loader from "components/Loader";
import SingleProductShimmer from "components/ShimmerComponents/SingleProductShimmer";
import ProductDescriptionSideBar from "../ProductDescriptionSideBar";
import SingleProductSidebarShimmer from "components/ShimmerComponents/SingleProductSidebarShimmer";
import {
  addInfluencerShareAPI,
  addResellerOnShareAPI,
  getAffiliateValidityApi,
  getShortLink,
} from "services/api_calls/affiliate/AffiliateApi";
import { getOperatingSystem } from "utils/utilities";

function VariantProduct({
  productId,
  activeProductId,
  cashbackData,
  recommendedProducts,
  specification,
  fullSpecification,
}) {
  const [childProducts, setChildProducts] = useState([]);
  const [activeProduct, setActiveProduct] = useState(null);
  const [activeImg, setActiveImg] = useState(null);
  const [variants, setVariants] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState({});
  const [activeVariantOptions, setActiveVariantOptions] = useState([]);
  const [cartProductList, setCartProductList] = useState(null);
  const [productQty, setProductQty] = useState(0);
  const [totalQty, setTotalQty] = useState(0);
  const [qtyLoading, setQtyLoading] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [productDetails, setProductDetails] = useState(null);

  const [ondcSpecData, setOndcSpecData] = useState(null);

  const [value, setValue] = useState(0);
  // const [loader, setLoader] = useState(true);

  // Affiliate Social Share
  const [shortShareLink, setShortShareLink] = useState("");
  const [isAffiliateExist, setIsAffiliateExist] = useState(false);

  const dispatch = useDispatch();
  const { commercialDetails } = useSelector((state) => state.product);
  const { cartDetails } = useSelector((state) => state.cart);
  const { updateQty, addedProduct, createCartRes, updateCartIntRes } =
    useSelector((state) => state.cartupdateadd);
  const { businessId } = useSelector((state) => state.rootSlice);

  const isLoggedIn = getUserName().length > 0;

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const { businessDetails } = useSelector((state) => state.header);
  const { rellerProfileData, businessAffiliateData } = useSelector(
    (state) => state.affiliate
  );

  const whatsappShareRef = useRef(null);
  const faceBookShareRef = useRef(null);
  const twitterShareRef = useRef(null);
  const linkedInShareRef = useRef(null);
  const telegramShareRef = useRef(null);

  const businessName = sessionStorage.getItem("businessName");

  useEffect(() => {
    const fetchMasterProduct = async () => {
      const data = await getProductData(productId);
      setProductDetails(data);
    };

    fetchMasterProduct();
  }, [productId]);

  useEffect(() => {
    console.log("VARIANT_CHECK", productDetails, productId);

    if (productDetails?.product?.id !== productId) return;

    const fetchChildProducts = async () => {
      const childProductsData = await getViewChildProductDetailsAPI(productId);
      setChildProducts(addUOMToAttributeList(childProductsData?.details));
    };

    if (productDetails) fetchChildProducts();
  }, [productDetails, productId]);

  useEffect(() => {
    if (productDetails?.product?.id !== productId) return;

    if (childProducts.length > 0) {
      const mainProduct = childProducts.find(
        (product) => product.id === activeProductId
      );
      setActiveProduct(mainProduct);
      if (specification && fullSpecification) {
        const colourObj = fullSpecification?.details?.find(
          (item) => item?.code === "colour"
        );

        const variantList = getVariantArray(
          productDetails?.product,
          childProducts,
          colourObj
        );

        const activeVariant = getActiveProductVariants(
          mainProduct,
          variantList
        );

        setActiveVariantOptions(
          getAvailableVariants(productDetails?.product, childProducts, {
            [variantList?.[0]?.type]: activeVariant?.variant1,
          })
        );

        console.log(
          "mapByCodemapByCodemapByCode",
          activeVariant,
          getAvailableVariants(productDetails?.product, childProducts, {
            [variantList?.[0]?.type]: activeVariant?.variant1,
          })
        );
        setSelectedVariant(activeVariant);
        setVariants(variantList);
      }
    }
  }, [childProducts, productDetails, specification, fullSpecification]);

  useEffect(() => {
    if (activeProduct) {
      if (specification && fullSpecification) {
        const mapByCode = fullSpecification?.details?.reduce((acc, obj) => {
          acc[obj.code] = obj;
          return acc;
        }, {});

        const array = specification?.details?.[0]?.attributes?.map((obj1) => {
          // Check if there is a matching object in the second array based on code
          if (mapByCode[obj1?.value]) {
            // Merge properties from the second array object to the first array object
            if (mapByCode[obj1?.value]?.dbObjectName === "list") {
              if (mapByCode[obj1?.value]?.type === "codeValue") {
                const colorList = extractNameCodePairs(
                  mapByCode[obj1?.value]?.values
                );
                const colorCode = getValueByCode(
                  activeProduct?.ondc?.attributeList,
                  mapByCode[obj1?.value]?.code
                );
                const colourName = getNameByCode(colorList, colorCode);
                return {
                  ...obj1,
                  required: obj1?.code,
                  ...mapByCode[obj1?.value],
                  inputValue: colourName || "",
                  options: extractNames(mapByCode[obj1?.value]?.values),
                  nameCodes: extractNameCodePairs(
                    mapByCode[obj1?.value]?.values
                  ),
                };
              }
              return {
                ...obj1,
                required: obj1?.code,
                ...mapByCode[obj1?.value],
                inputValue:
                  getValueByCode(
                    activeProduct?.ondc?.attributeList,
                    mapByCode[obj1?.value]?.code
                  ) || "",
                // mapByCode[obj1?.value]?.type === "default"
                //   ? mapByCode[obj1?.value]?.values?.[0]
                //   : "",
              };
            }
            if (mapByCode[obj1?.value]?.type === "codeValue") {
              return {
                ...obj1,
                required: obj1?.code,
                ...mapByCode[obj1?.value],
                inputValue: "",
                options: extractNames(mapByCode[obj1?.value]?.values),
                nameCodes: extractNameCodePairs(mapByCode[obj1?.value]?.values),
              };
            }
            if (mapByCode[obj1?.value]?.type === "radioButton") {
              // const options = mapByCode[obj1?.value]?.values?.map((jsonString) =>
              //   JSON.parse(jsonString)
              // );
              const options = mapByCode[obj1?.value]?.radioOptions;
              const radioOptions = options?.map((option) => {
                return {
                  ...option,
                  value: getValueByPath(activeProduct, option?.key),
                };
              });

              return {
                ...obj1,
                required: obj1?.code,
                ...mapByCode[obj1?.value],
                radioOptions,
              };
            }
            return {
              ...obj1,
              required: obj1?.code,
              ...mapByCode[obj1?.value],
              inputValue:
                getValueByPath(
                  activeProduct,
                  mapByCode[obj1?.value]?.dbObjectName
                ) || "",
            };
          } else {
            return obj1;
          }
        });

        const dynamicSpecs = filterMatchingCodes(
          activeProduct?.variantInfo,
          array
        );

        console.log("Ondc_Spec_Data:", array, mapByCode);

        setOndcSpecData(dynamicSpecs);
      }
      setActiveImg(activeProduct?.productPhoto?.[0]?.docURL);

      setProductQty(0);
      let count = 0;
      const cartData = JSON.parse(sessionStorage.getItem("cartData"));
      cartData?.productList?.forEach((item) => {
        if (item.productId === activeProduct.id)
          setProductQty(item.currentQuantity);
        count += item.currentQuantity;
      });
      setTotalQty(count);
      setQtyLoading(false);
    }
  }, [activeProduct, updateQty, cartDetails, specification, fullSpecification]);

  useEffect(() => {
    if (productDetails?.product?.id !== productId) return;

    let count = 0;
    setProductQty(0);

    if (isLoggedIn) {
      dispatch(getCartDetails(businessId)).then((carRes) => {
        sessionStorage.setItem(
          "cartData",
          JSON.stringify(carRes?.payload?.detail)
        );
        carRes?.payload?.detail?.productList?.forEach((item) => {
          if (item.productId === productDetails?.product?.id) {
            setProductQty(item?.currentQuantity);
          }
          count = count + item.currentQuantity;
        });
        setTotalQty(count);
        setCartProductList(carRes?.payload?.detail);
        setQtyLoading(false);
      });
    }
  }, [addedProduct, createCartRes, updateCartIntRes]);

  // Initiate Chat
  const handleChatClick = () => {
    console.log("HITTTT");
    const whatsAppNumber =
      commercialDetails?.info?.costboPowered?.whatsAppNumber?.replace("+", "");

    if (whatsAppNumber) {
      window.open(
        `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=Hi+${businessDetails?.businessInfo?.[0]?.businessName}+team,+I+have+a+query+about+the+products+in+your+webstore+${window.location.href}`,
        "_blank"
      );
    } else {
      window.fcWidget.open();
      window.fcWidget.show();
    }
  };

  const handleVariantClick = (data, index, type) => {
    console.log(data, type, index);
    let result = { [type]: data };
    if (index === 0) {
      setSelectedVariant({ variant1: data, variant2: null });
      if (variants.length > 1) {
        setActiveVariantOptions(
          getAvailableVariants(productDetails?.product, childProducts, result)
        );
      } else {
        setActiveProduct(
          getProductByVariants(productDetails?.product, childProducts, result)
        );
      }
    } else {
      setSelectedVariant((prev) => ({ ...prev, variant2: data }));
      setActiveProduct(
        getProductByVariants(productDetails?.product, childProducts, {
          ...result,
          [variants[0]?.type]: selectedVariant.variant1,
        })
      );
    }
  };

  const onAddToCart = (product) => {
    console.log("Hit 6");
    const gstTaxValue = product?.gstInfo
      ? product.gstInfo.gstPercentage.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
      : 0;
    const productAmtWithoutGST =
      (product.productSalePrice * 100) / (100 + Number(gstTaxValue));
    const productTaxPerUnit = product.productSalePrice - productAmtWithoutGST;

    const addProductData = {
      cartId: cartDetails?.cartId,
      product: {
        productId: product.id,
        productName: product.productName,
        productPicURL: product.productPhoto[0].docURL,
        productOriginalPrice: product.productOriginalPrice,
        productSalePrice: product.productSalePrice,
        originalQuantity: product.minOrderQuantity,
        currentQuantity: product.minOrderQuantity,
        currencySymbol: "₹",
        percentageOff: product.percentageOff,
        productTotalPrice: product.minOrderQuantity * product.productSalePrice,
        productTax: productTaxPerUnit.toFixed(2),
        productTaxRate: gstTaxValue,
        measurementInfo: product.measurementInfo,
        variantInfo: product?.variantInfo,
      },
      clientType: "web",
    };

    setQtyLoading(true);

    if (isLoggedIn) {
      const cartData = JSON.parse(sessionStorage.getItem("cartData"));
      if (cartData) {
        dispatch(addNewProductCart(addProductData));
      } else {
        dispatch(createCart(createCartLoginHelper(addProductData)));
      }
    } else {
      dispatch(addLocalCart(addProductData.product)).then((res) => {
        res.payload.productList.forEach((item) => {
          if (item.productId === product.id)
            setProductQty(item.currentQuantity);
        });
        setQtyLoading(false);
        setCartProductList(res.payload);
        setTotalQty(res.payload.totalQuantity);
      });
    }
  };

  const removeFromCart = (product) => {
    console.log("Hit 7");
    setQtyLoading(true);
    if (isLoggedIn) {
      dispatch(
        updateCartDetails({
          ...removeCartProductLoginHelper(product.id),
          id: cartDetails?.cartId,
        })
      );
    } else {
      dispatch(removeProductLocalCart({ productId: product.id })).then(
        (res) => {
          setProductQty(0);
          res.payload.productList.forEach((item) => {
            if (item.productId === activeProduct.id)
              setProductQty(item.currentQuantity);
          });
          setCartProductList(res.payload);
          setTotalQty(res.payload.totalQuantity);
          setQtyLoading(false);
        }
      );
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // generate short share link
  const generateShortShareLink = async () => {
    const businessInfo = JSON.parse(sessionStorage.getItem("businessInfo"));

    const businessData = {
      name: businessInfo?.[0]?.businessName,
      costboWebURL: businessName,
    };

    const res = await getShortLink({
      businessData,
      // productDetails: productDetails,
      rellerProfileData: rellerProfileData?.details,
    });

    if (res?.shortURL) {
      setShortShareLink(res?.shortURL);
      return res?.shortURL;
    } else {
      const link = `https://shop.costbo.com/${businessName}?atag=${rellerProfileData?.details?.resellerCode}`;
      setShortShareLink(link);
      return link;
    }
  };

  // Open Affiliate Share Modal
  const openShareModal = (shareMedium) => {
    if (shareMedium === "whatsapp") {
      whatsappShareRef.current.click();
    } else if (shareMedium === "facebook") {
      faceBookShareRef.current.click();
    } else if (shareMedium === "linkedIn") {
      linkedInShareRef.current.click();
    } else if (shareMedium === "twitter") {
      twitterShareRef.current.click();
    } else if (shareMedium === "telegram") {
      telegramShareRef.current.click();
    }
  };

  const handleAffiliateShare = async (shareMedium, isCopy) => {
    const businessInfo = JSON.parse(sessionStorage.getItem("businessInfo"));
    const businessData = businessInfo?.[0];
    const commissionData = businessAffiliateData?.businessResellers?.[0];
    const commerceInfo = JSON.parse(sessionStorage.getItem("commerceInfo"));

    // POST API Data
    const commissionPostData = {
      commissionPercentage: commissionData?.affiliatePercentage,
      discountPercentage: commissionData?.consumerPercentage,
    };

    const businessPostData = {
      id: businessData?.id,
      name: businessData?.businessName,
      logoURL: businessData?.logoURL,
      costboWebURL: businessName,
      businessCategories: businessData?.businessCategories,
      documentsURL: businessData?.documentsURL,
      mainProducts: businessData?.mainProducts,
      businessArea: commerceInfo?.info?.businessArea,
      description: "",
    };

    const productPostData = {
      ...activeProduct,
      productId: activeProduct?.id,
      name: activeProduct?.productName,
    };

    let shortLink = shortShareLink || "";

    if (!shortShareLink) {
      shortLink = await generateShortShareLink();
    }

    if (isCopy) {
      navigator.clipboard.writeText(shortLink);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    } else {
      setTimeout(() => {
        openShareModal(shareMedium);
      }, 1000);
    }

    if (!isAffiliateExist) {
      const isAffiliateValid = await getAffiliateValidityApi(businessData?.id);

      if (isAffiliateValid?.affiliateexist === false) {
        const response = await addResellerOnShareAPI({
          productDetails: productPostData,
          commissionDetails: commissionPostData,
          rellerProfileData: rellerProfileData?.details,
          businessData: businessPostData,
        });

        if (response) {
          setIsAffiliateExist(true);
        }
      } else {
        setIsAffiliateExist(true);
      }
    }

    const deviceName = getOperatingSystem(window);
    const shareResponse = await addInfluencerShareAPI({
      productDetails: productPostData,
      commissionDetails: commissionPostData,
      businessData: businessPostData,
      deviceName,
      shareMedium,
    });
  };

  return (
    <Box>
      <Grid container direction="row" mt={0} spacing={{ md: 0, xs: 0 }}>
        <Grid item md={9.45} xs={12}>
          <Box>
            <ModalCard
              handleClose={() => setIsModal(false)}
              open={isModal}
              width="70%"
            >
              <ProductGalleryModal
                title={activeProduct?.productName}
                productImages={activeProduct?.productPhoto}
                activeImg={activeImg || activeProduct?.productPhoto[0]?.docURL}
                onActiveClick={(data) => setActiveImg(data)}
                magnify
              />
            </ModalCard>

            {activeProduct ? (
              <Stack spacing={{ md: 0, xs: 1.5 }} pt={{ xs: 11, md: 0 }}>
                <Grid container>
                  <Grid item md={5.5} xs={12}>
                    {!matches && (
                      <Box mb={2}>
                        <ProductDescriptionHeader
                          title={activeProduct?.productName}
                          copyLink={handleCopyLink}
                          isCopied={isCopied}
                          subCategory={
                            activeProduct?.productSubCategory[0]?.value
                          }
                          subCategory2={
                            activeProduct?.productSubCategory2[0]?.value
                          }
                          handleChatClick={handleChatClick}
                          // Affiliate Share
                          commercialDetails={commercialDetails}
                          businessAffiliateData={businessAffiliateData}
                          rellerProfileData={rellerProfileData?.details}
                          productDetails={activeProduct}
                        />
                      </Box>
                    )}
                    <ProductImages
                      onImageClick={() => setIsModal(true)}
                      productImages={activeProduct?.productPhoto}
                      activeImg={
                        activeImg || activeProduct?.productPhoto?.[0]?.docURL
                      }
                      onActiveClick={(data) => setActiveImg(data)}
                      handleChatClick={handleChatClick}
                      copyLink={handleCopyLink}
                      businessAffiliateData={businessAffiliateData}
                      rellerProfileData={rellerProfileData?.details}
                      isCopied={isCopied}
                      productDetails={activeProduct}
                      commercialDetails={commercialDetails}
                    />
                  </Grid>
                  <Grid item md={5.6} xs={12} ml={{ md: 4, xs: 0 }}>
                    <Box display={"flex"} flexDirection={"column"} gap={1}>
                      {matches && (
                        <ProductDescriptionHeader
                          title={activeProduct?.productName}
                          copyLink={handleCopyLink}
                          isCopied={isCopied}
                          subCategory={
                            activeProduct?.productSubCategory[0]?.value
                          }
                          subCategory2={
                            activeProduct?.productSubCategory2[0]?.value
                          }
                          handleChatClick={handleChatClick}
                          // Affiliate Share
                          commercialDetails={commercialDetails}
                          businessAffiliateData={businessAffiliateData}
                          rellerProfileData={rellerProfileData?.details}
                          productDetails={activeProduct}
                        />
                      )}
                      {/* <Divider sx={{ my: "16px !important" }} /> */}
                      <ProductVariantData
                        variants={variants}
                        onVariantClick={handleVariantClick}
                        activeVariant2={activeVariantOptions}
                        selectedVariant={selectedVariant}
                      />
                      {/* <Divider sx={{ my: "16px !important" }} /> */}
                      <ProductDescriptionBody
                        productName={activeProduct?.productName}
                        productOriginalPrice={
                          activeProduct?.productOriginalPrice
                        }
                        productSalePrice={activeProduct?.productSalePrice}
                        percentageOff={activeProduct?.percentageOff}
                        min={activeProduct?.minOrderQuantity}
                        max={activeProduct?.maxOrderQuantity}
                        productDetails={{ product: activeProduct }}
                        //   isOutOfStock={isOutOfStock}
                        onQtyUpdate={(quantity) => {
                          console.log("Hit 1");
                          if (qtyLoading) return;
                          console.log("Hit 2");
                          if (isLoggedIn) {
                            console.log("Hit 3");
                            dispatch(
                              updateCartProductQuantity({
                                cartId: cartDetails?.cartId,
                                productId: activeProduct?.id,
                                quantity: quantity,
                              })
                            );
                            setQtyLoading(true);
                          } else {
                            console.log("Hit 4");
                            dispatch(
                              updateToLocalCart({
                                cartId: cartDetails?.cartId,
                                productId: activeProduct?.id,
                                quantity: quantity,
                              })
                            ).then((res) => {
                              res?.payload?.productList?.forEach((item) => {
                                if (item.productId === activeProduct?.id) {
                                  setProductQty(item?.currentQuantity);
                                }
                              });
                              setCartProductList(res?.payload);
                              setTotalQty(res?.payload?.totalQuantity);
                            });
                          }
                        }}
                        productQty={productQty}
                        cartProductList={cartProductList}
                        totalQty={totalQty}
                        onAddProduct={() => onAddToCart(activeProduct)}
                        onRemoveProduct={() => removeFromCart(activeProduct)}
                        //   url={windowurl}
                        subscribe={
                          activeProduct?.subscription?.status === "active"
                        }
                        availability={
                          activeProduct?.availability?.[0] === "Yes"
                        }
                        subscription={activeProduct?.subscription}
                        handleChatClick={handleChatClick}
                        // Affiliate Share
                        businessAffiliateData={businessAffiliateData}
                        rellerProfileData={rellerProfileData?.details}
                        onAffiliateShareClick={handleAffiliateShare}
                        shortShareLink={shortShareLink}
                        whatsappShareRef={whatsappShareRef}
                        faceBookShareRef={faceBookShareRef}
                        twitterShareRef={twitterShareRef}
                        linkedInShareRef={linkedInShareRef}
                        telegramShareRef={telegramShareRef}
                      />
                    </Box>
                  </Grid>
                </Grid>
                {/* <Box px={{ xs: 2, md: 0 }} pt="16px">
                  <DescriptionSpecificationTabs
                    value={value}
                    handleChange={(event, newValue) =>
                      handleChange(event, newValue)
                    }
                    descriptionData={activeProduct?.productDescription}
                    specificationData={activeProduct?.label}
                    refLink={activeProduct?.refLink}
                    ondcData={activeProduct?.ondc}
                    uom={activeProduct?.productDisplayUnitOfMeasure}
                  />
                </Box> */}
              </Stack>
            ) : (
              <Box>
                <SingleProductShimmer />
              </Box>
            )}
          </Box>
        </Grid>
        {matches && (
          <Grid
            item
            md={2.55}
            xs={12}
            mb={{
              md: 0,
              xs:
                recommendedProducts?.length === 0 ? (totalQty > 0 ? 15 : 7) : 1,
            }}
          >
            {commercialDetails?.info?.rewardPointPercent ||
            commercialDetails?.info?.orderInfoDetails?.[0]?.minOrderAmount ||
            commercialDetails?.info?.immediateDiscount ||
            commercialDetails?.info?.shipmentArea ||
            commercialDetails?.info?.returnPolicy ||
            commercialDetails?.info?.notes ? (
              <ProductDescriptionSideBar
                rewardPointPercent={commercialDetails?.info?.rewardPointPercent}
                minOrderAmount={
                  commercialDetails?.info?.orderInfoDetails?.[0]?.minOrderAmount
                }
                immediateDiscount={commercialDetails?.info?.immediateDiscount}
                shipmentArea={commercialDetails?.info?.shipmentArea}
                shipmentDistance={commercialDetails?.info?.shipmentDistance}
                returnPolicy={commercialDetails?.info?.returnPolicy}
                notes={commercialDetails?.info?.notes}
                cashback={cashbackData}
              />
            ) : (
              <SingleProductSidebarShimmer />
            )}
          </Grid>
        )}
        {
          // !productDetails?.product?.variantInfo?.masterProductVariantId &&
          // isProductValid &&
          activeProduct ? (
            <Grid item md={12} xs={12} mt={2}>
              <DescriptionSpecificationTabs
                value={value}
                handleChange={(event, newValue) =>
                  handleChange(event, newValue)
                }
                descriptionData={activeProduct?.productDescription}
                specificationData={activeProduct?.label}
                refLink={activeProduct?.refLink}
                ondcData={activeProduct?.ondc}
                uom={activeProduct?.productDisplayUnitOfMeasure}
                dynamicSpecs={ondcSpecData}
              />
            </Grid>
          ) : null
        }
        {!matches && (
          <Grid
            item
            md={2.55}
            xs={12}
            mb={{
              md: 0,
              xs:
                recommendedProducts?.length === 0 ? (totalQty > 0 ? 15 : 7) : 1,
            }}
          >
            {commercialDetails?.info?.rewardPointPercent ||
            commercialDetails?.info?.orderInfoDetails?.[0]?.minOrderAmount ||
            commercialDetails?.info?.immediateDiscount ||
            commercialDetails?.info?.shipmentArea ||
            commercialDetails?.info?.returnPolicy ||
            commercialDetails?.info?.notes ? (
              <ProductDescriptionSideBar
                rewardPointPercent={commercialDetails?.info?.rewardPointPercent}
                minOrderAmount={
                  commercialDetails?.info?.orderInfoDetails?.[0]?.minOrderAmount
                }
                immediateDiscount={commercialDetails?.info?.immediateDiscount}
                shipmentArea={commercialDetails?.info?.shipmentArea}
                shipmentDistance={commercialDetails?.info?.shipmentDistance}
                returnPolicy={commercialDetails?.info?.returnPolicy}
                notes={commercialDetails?.info?.notes}
                cashback={cashbackData}
              />
            ) : null}
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default VariantProduct;
