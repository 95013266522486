import { Box, Grid, Skeleton, Stack, useMediaQuery } from "@mui/material";
import SingleProductShimmer from "components/ShimmerComponents/SingleProductShimmer";
import SingleProductSidebarShimmer from "components/ShimmerComponents/SingleProductSidebarShimmer";
import { whiteColor } from "configs/styles/muiThemes";
import React from "react";
import { useSelector } from "react-redux";

function SingleProductLoader() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const { commercialDetails } = useSelector((state) => state.product);
  const { businessDetails } = useSelector((state) => state.header);
  return matches ? (
    <Grid container direction="row" mt={0} spacing={{ md: 0, xs: 0 }}>
      <Grid item md={9.45}>
        <SingleProductShimmer />
      </Grid>
      <Grid item md={2.55}>
        <SingleProductSidebarShimmer />
      </Grid>
    </Grid>
  ) : (
    <Stack pt={{ xs: commercialDetails && businessDetails ? 11 : 0, md: 0 }}>
      <Stack gap={0.6} px="16px" mb="12px">
        <Skeleton variant="text" width="70%" />
        <Skeleton variant="text" width="90%" />
      </Stack>
      <Stack p="8px 0 14px" gap="16px" bgcolor={whiteColor}>
        <Skeleton variant="rectangle" width="100%" height={250} />
        <Grid container direction="row" columnSpacing="16px" px="16px">
          <Grid item xs={3}>
            <Skeleton
              variant="rectangle"
              width="100%"
              height={70}
              sx={{ borderRadius: "10px" }}
            />
          </Grid>
          <Grid item xs={3}>
            <Skeleton
              variant="rectangle"
              width="100%"
              height={70}
              sx={{ borderRadius: "10px" }}
            />
          </Grid>
          <Grid item xs={3}>
            <Skeleton
              variant="rectangle"
              width="100%"
              height={70}
              sx={{ borderRadius: "10px" }}
            />
          </Grid>
          <Grid item xs={3}>
            <Skeleton
              variant="rectangle"
              width="100%"
              height={70}
              sx={{ borderRadius: "10px" }}
            />
          </Grid>
        </Grid>
      </Stack>
      <Box
        p="23px 15px 15px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack gap={0.5}>
          <Skeleton variant="rectangle" width="130px" height={23} />
          <Skeleton variant="rectangle" width="120px" height={23} />
        </Stack>
        <Skeleton
          variant="rectangle"
          width="100px"
          sx={{ borderRadius: "4px" }}
          height={36}
        />
      </Box>
    </Stack>
  );
}

export default SingleProductLoader;
