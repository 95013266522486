import FreeShippingContentCard from 'components/FreeShippingContentCard'
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { fintechApplyData } from 'utils/utilities'
import {
  backgroundColor,
  backgroundColor01,
  bgColor,
  blackColor,
  borderCart,
  borderLineColor,
  cartBorderColor01,
  catalogSubHeaderColor,
  codBtnColor,
  couponColor,
  greyShade40,
  headingColor,
  loginPrimary,
  secondaryColor,
  topbarColor,
  whiteColor,
} from '../../configs/styles/muiThemes'
import InfoIcon from '@mui/icons-material/Info'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

function CartBottomTab({
  totalQty,
  amount,
  btnText,
  onBtnClicked,
  codAmt,
  onCodClicked,
  isDisabled,
  showCodBtn,
  codCost,
  freeAlert,
  confirmPayLoader,
  finboxStatus,
  finboxAvlAmt,
  page,
  onModalFinboxUrl,
  handleFinboxClick,
  freeAmt,
  finvervAmt,
  handleNotRegistered,
  handleNotCompleted,
  handleFinvervClick,
  showEasyCartBtn,
  confirmPaycartOrder,
  paycartLoading,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const [tooltip, setTooltip] = useState(false)
  const [selectedOption, setSelectedOption] = useState('')

  const handleClickOutside = () => {
    setTooltip(false)
  }
  const ref = useOutsideClick(handleClickOutside)

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
  }

  const handlePaymentConfirm = () => {
    if (selectedOption === 'pay-now') {
      onBtnClicked()
    } else if (selectedOption === 'easy-cart') {
      confirmPaycartOrder()
    } else if (selectedOption === 'cod') {
      onCodClicked()
    } else {
      return false
    }
  }

  return matches ? (
    <Box backgroundColor={whiteColor} py={1.5} sx={styles.card}>
      {freeAlert ? (
        <Box mb={1}>
          <FreeShippingContentCard amt={freeAmt} />
        </Box>
      ) : null}

      {page === "address-page" && (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: borderCart,
              // p: '6px 0 8px',
              boxShadow: `0px 4px 14px rgba(var(--black), 0.25)`,
              mb: 1.5,
              px: 1.5,
              borderRadius: "10px 10px 0 0 ",
              border: `1px solid ${borderLineColor}`,
            }}
          >
            <Typography
              variant="body2"
              fontSize={13}
              color={blackColor}
              fontWeight={600}
            >
              Select a Payment Method
            </Typography>

            <Box>
              <Typography
                variant="h5"
                fontSize={11}
                color={catalogSubHeaderColor}
                fontWeight={500}
              >
                Total Amount
              </Typography>

              <Typography
                variant="body1"
                fontSize={14.5}
                color={blackColor}
                fontWeight={600}
              >
                ₹ {Number(amount).toFixed(2)}
              </Typography>
            </Box>
          </Box>

          <Box px={1.5}>
            <Stack direction="row">
              <PaymentOption
                label="Pay Now"
                isChecked={selectedOption === "pay-now"}
                value="pay-now"
                handleChange={handleOptionChange}
              />

              {showEasyCartBtn && page === "address-page" && (
                <PaymentOption
                  label="Generate Easy Cart Link"
                  isChecked={selectedOption === "easy-cart"}
                  value="easy-cart"
                  handleChange={handleOptionChange}
                />
              )}
            </Stack>

            {showCodBtn && (
              <PaymentOption
                label="Cash on Delivery"
                subText={`(COD Cast ₹${Number(codCost).toFixed(2)}) | ₹${Number(
                  codAmt
                ).toFixed(2)}`}
                isChecked={selectedOption === "cod"}
                value="cod"
                handleChange={handleOptionChange}
              />
            )}

            <Box display="flex" mt={1.5}>
              <Button
                sx={{
                  color: "#fff",
                  borderRadius: "50px",
                  fontWeight: 600,
                  fontSize: "12px",
                  // border: '1px solid #000',
                  width: "95%",
                  margin: "8px auto 10px",
                  display: "block",
                  padding: "8px 14px",
                  boxShadow: `0px 4px 14px rgba(var(--black), 0.25)`,
                  // ...styles.gotoCardBtn(isDisabled),
                  border: confirmPayLoader ? "none" : `1px solid ${blackColor}`,
                  textTransform: "uppercase",
                  "&:hover": {
                    backgroundColor: whiteColor,
                    color: blackColor,
                  },
                }}
                variant="contained"
                disableElevation
                size="small"
                onClick={() => {
                  handlePaymentConfirm();
                }}
                disabled={isDisabled || confirmPayLoader || paycartLoading}
              >
                {confirmPayLoader || paycartLoading ? (
                  <CircularProgress
                    sx={{ color: blackColor, mr: 1 }}
                    size={16}
                  />
                ) : null}
                CONFIRM
              </Button>
            </Box>
          </Box>
        </>
      )}

      <Box px={1} sx={{ position: "relative" }}>
        {tooltip ? (
          <Box
            sx={{
              backgroundColor: "#f5f5f5",
              px: 2,
              py: 1,
              borderRadius: 2,
              maxWidth: "60%",
              position: "absolute",
              bottom: "115%",
              border: "1px solid #efefef",
            }}
            ref={ref}
          >
            {finboxStatus === "APPLY" || finboxStatus === "notRegistered" ? (
              <>
                {fintechApplyData.map((item, index) => {
                  return (
                    <ListItem sx={{ py: 0 }}>
                      <ListItemText
                        sx={{
                          display: "list-item",
                        }}
                        key={index + "main_product"}
                        primary={
                          <Typography
                            variant="subtitle1"
                            fontSize="12px"
                            color={couponColor}
                          >
                            {item}
                          </Typography>
                        }
                      />
                    </ListItem>
                  );
                })}
              </>
            ) : null}
            {finboxStatus === "INCOMPLETE" ||
            finboxStatus === "notCompleted" ? (
              <>
                <ListItem sx={{ py: 0 }}>
                  <ListItemText
                    sx={{
                      display: "list-item",
                    }}
                    key={"main_product"}
                    primary={
                      <Typography
                        variant="subtitle1"
                        fontSize="13px"
                        color={couponColor}
                      >
                        You are just few steps away from receiving working
                        capital
                      </Typography>
                    }
                  />
                </ListItem>
              </>
            ) : null}
            {finboxStatus === "APPROVED" ? (
              <>
                <ListItem sx={{ py: 0 }}>
                  <ListItemText
                    sx={{
                      display: "list-item",
                    }}
                    key={"main_product"}
                    primary={
                      <Typography
                        variant="subtitle1"
                        fontSize="13px"
                        color={couponColor}
                      >
                        Just complete last few steps to avail the funds
                      </Typography>
                    }
                  />
                </ListItem>
              </>
            ) : null}
            {finboxStatus === "ACTIVE" || finboxStatus === "DUE" ? (
              <>
                <ListItem sx={{ py: 0 }}>
                  <ListItemText
                    sx={{
                      display: "list-item",
                    }}
                    key={1}
                    primary={
                      <Typography
                        variant="subtitle1"
                        fontSize="13px"
                        color={couponColor}
                      >
                        Available credit: ₹{finboxAvlAmt}
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem sx={{ py: 0 }}>
                  <ListItemText
                    sx={{
                      display: "list-item",
                    }}
                    key={2}
                    primary={
                      <Typography
                        variant="subtitle1"
                        fontSize="13px"
                        color={couponColor}
                      >
                        You will receive an OTP in your registered phone number
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem sx={{ py: 0 }}>
                  <ListItemText
                    sx={{
                      display: "list-item",
                    }}
                    key={3}
                    primary={
                      <Typography
                        variant="subtitle1"
                        fontSize="13px"
                        color={couponColor}
                      >
                        Verify and agree to the terms to use the credit line
                      </Typography>
                    }
                  />
                </ListItem>
              </>
            ) : null}
            {finboxStatus === "balanceAvailable" ? (
              <>
                <ListItem sx={{ py: 0 }}>
                  <ListItemText
                    sx={{
                      display: "list-item",
                    }}
                    key={1}
                    primary={
                      <Typography
                        variant="subtitle1"
                        fontSize="13px"
                        color={couponColor}
                      >
                        Available credit: ₹{finvervAmt}
                      </Typography>
                    }
                  />
                </ListItem>
              </>
            ) : null}
          </Box>
        ) : null}

        {finboxStatus === "APPLY" && page === "address-page" ? (
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Grid item>
              <Button
                onClick={() => setTooltip(!tooltip)}
                sx={{
                  color: greyShade40,
                  fontSize: 12,
                  fontWeight: 400,
                  textTransform: "uppercase",
                }}
              >
                See More
                <InfoIcon sx={{ fontSize: "16px", ml: 1 }} />
              </Button>
            </Grid>

            <Grid item>
              <Button
                sx={styles.finboxBtn(isDisabled)}
                variant="contained"
                disableElevation
                size="small"
                onClick={() => {
                  onModalFinboxUrl();
                }}
              >
                Apply for Credit line
              </Button>
            </Grid>
          </Grid>
        ) : null}

        {(finboxStatus === "INCOMPLETE" || finboxStatus === "APPROVED") &&
        page === "address-page" ? (
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Grid item>
              <Button
                onClick={() => setTooltip(!tooltip)}
                sx={{
                  color: greyShade40,
                  fontSize: 12,
                  fontWeight: 400,
                  textTransform: "uppercase",
                }}
              >
                See More
                <InfoIcon sx={{ fontSize: "16px", ml: 1 }} />
              </Button>
            </Grid>

            <Grid item>
              <Button
                sx={styles.finboxBtn(isDisabled)}
                variant="contained"
                disableElevation
                size="small"
                onClick={() => {
                  onModalFinboxUrl();
                }}
                // disabled={isDisabled}
              >
                Complete your credit application
              </Button>
            </Grid>
          </Grid>
        ) : null}

        {(finboxStatus === "ACTIVE" || finboxStatus === "DUE") &&
        page === "address-page" ? (
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
            // sx={{ borderBottom: '1px solid #737373' }}
          >
            <Grid item>
              <Button
                onClick={() => setTooltip(!tooltip)}
                sx={{
                  color: greyShade40,
                  fontSize: 12,
                  fontWeight: 400,
                  textTransform: "uppercase",
                }}
              >
                See More
                <InfoIcon sx={{ fontSize: "16px", ml: 1 }} />
              </Button>
            </Grid>

            <Grid item>
              <Button
                sx={styles.finboxBtn(isDisabled)}
                variant="contained"
                disableElevation
                size="small"
                onClick={() => {
                  handleFinboxClick();
                }}
                // disabled={isDisabled}
              >
                PAY WITH CREDIT LINE
              </Button>
            </Grid>
          </Grid>
        ) : null}

        {finboxStatus === "balanceAvailable" && page === "address-page" ? (
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
            // sx={{ borderBottom: '1px solid #737373' }}
          >
            <Grid item>
              <Button
                onClick={() => setTooltip(!tooltip)}
                sx={{
                  color: greyShade40,
                  fontSize: 12,
                  fontWeight: 400,
                  textTransform: "uppercase",
                }}
              >
                See More
                <InfoIcon sx={{ fontSize: "16px", ml: 1 }} />
              </Button>
            </Grid>

            <Grid item>
              <Button
                sx={styles.finboxBtn(isDisabled)}
                variant="contained"
                disableElevation
                size="small"
                onClick={() => {
                  handleFinvervClick();
                }}
                // disabled={isDisabled}
              >
                PAY WITH CREDIT LINE
              </Button>
            </Grid>
          </Grid>
        ) : null}

        {finboxStatus === "notRegistered" && page === "address-page" ? (
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Grid item>
              <Button
                onClick={() => setTooltip(!tooltip)}
                sx={{
                  color: greyShade40,
                  fontSize: 12,
                  fontWeight: 400,
                  textTransform: "uppercase",
                }}
              >
                See More
                <InfoIcon sx={{ fontSize: "16px", ml: 1 }} />
              </Button>
            </Grid>

            <Grid item>
              <Button
                sx={styles.finboxBtn(isDisabled)}
                variant="contained"
                disableElevation
                size="small"
                onClick={() => {
                  handleNotRegistered();
                }}
              >
                Apply for Credit line
              </Button>
            </Grid>
          </Grid>
        ) : null}

        {finboxStatus === "notCompleted" && page === "address-page" ? (
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Grid item>
              <Button
                onClick={() => setTooltip(!tooltip)}
                sx={{
                  color: greyShade40,
                  fontSize: 12,
                  fontWeight: 400,
                  textTransform: "uppercase",
                }}
              >
                See More
                <InfoIcon sx={{ fontSize: "16px", ml: 1 }} />
              </Button>
            </Grid>

            <Grid item>
              <Button
                sx={styles.finboxBtn(isDisabled)}
                variant="contained"
                disableElevation
                size="small"
                onClick={() => {
                  handleNotCompleted();
                }}
                // disabled={isDisabled}
              >
                Complete your credit application
              </Button>
            </Grid>
          </Grid>
        ) : null}

        {/* {showEasyCartBtn && page === 'address-page' ? (
          <Grid
            container
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            mb={1}
          >
            <Grid item xs></Grid>

            <Grid item>
              <Button
                sx={styles.codBtn(paycartLoading)}
                variant='contained'
                disableElevation
                size='small'
                onClick={() => {
                  confirmPaycartOrder()
                }}
                disabled={paycartLoading}
              >
                GENERATE EASY CART LINK
              </Button>
            </Grid>
          </Grid>
        ) : null}

        {showCodBtn ? (
          <Grid
            container
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            mb={1}
          >
            <Grid item>
              <Typography
                variant='body2'
                color={catalogSubHeaderColor}
                fontWeight={500}
                display='inline'
                px={1.5}
                borderRight={borderCart}
              >
                COD Cost:{' '}
                <span style={{ fontWeight: 600, color: blackColor }}>
                  ₹{Number(codCost).toFixed(2)}
                </span>
              </Typography>
              
              <Typography
                variant='h6'
                color={headingColor}
                display='inline'
                ml={1.5}
              >
                ₹{Number(codAmt).toFixed(2)}
              </Typography>
            </Grid>

            <Grid item>
              <Button
                sx={styles.codBtn(isDisabled)}
                variant='contained'
                disableElevation
                size='small'
                onClick={() => {
                  onCodClicked()
                }}
                disabled={isDisabled}
              >
                CASH ON DELIVERY
              </Button>
            </Grid>
          </Grid>
        ) : null} */}

        {page !== "address-page" && (
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <Typography
                variant="body2"
                color={catalogSubHeaderColor}
                fontWeight={500}
                display="inline"
                px={1.5}
                borderRight={borderCart}
              >
                {totalQty} items in cart
              </Typography>
              <Typography
                variant="h6"
                color={headingColor}
                display="inline"
                ml={1.5}
              >
                ₹{Number(amount).toFixed(2)}
              </Typography>
            </Grid>

            <Grid item>
              <Button
                sx={{
                  ...styles.gotoCardBtn(isDisabled),
                  border: confirmPayLoader
                    ? "none"
                    : `1px solid ${{ md: blackColor, xs: blackColor }}`,
                }}
                variant="contained"
                disableElevation
                size="small"
                onClick={() => {
                  onBtnClicked();
                }}
                disabled={isDisabled || confirmPayLoader}
              >
                {confirmPayLoader ? (
                  <CircularProgress
                    sx={{ color: blackColor, mr: 1 }}
                    size={16}
                  />
                ) : null}
                {btnText}
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  ) : (
    <Box sx={styles.card}>
      {freeAlert ? (
        <Box mb={page === "address-page" ? 1 : 6}>
          <FreeShippingContentCard amt={freeAmt} />
        </Box>
      ) : null}
      <Box backgroundColor={{ md: whiteColor, xs: "none" }} py={1.5}>
        {page === "address-page" && (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: borderCart,
                border: `1px solid ${cartBorderColor01}`,
                // p: '6px 0 8px',
                // mb: 1.5,
                boxShadow: "0px 4px 14px rgba(var(--black), 0.25)",
                p: 1,
                px: 1.5,
                borderRadius: "10px 10px 0 0 ",
                // backgroundColor: { md: whiteColor, xs: "#f3f3f8" },
                backgroundColor: { md: whiteColor, xs: whiteColor },
              }}
            >
              <Typography
                variant="body2"
                fontSize={{ md: 13, xs: 12.5 }}
                color={blackColor}
                fontWeight={{ md: 600, xs: 400 }}
              >
                Select a Payment Method
              </Typography>

              <Box>
                {matches && (
                  <Typography
                    variant="h5"
                    fontSize={11}
                    color={catalogSubHeaderColor}
                    fontWeight={500}
                  >
                    Total Amount
                  </Typography>
                )}

                <Typography
                  variant="body1"
                  fontSize={{ md: 14.5, xs: 14 }}
                  color={blackColor}
                  fontWeight={600}
                >
                  ₹ {Number(amount).toFixed(2)}
                </Typography>
              </Box>
            </Box>

            <Box px={1.5} backgroundColor={whiteColor}>
              <Stack direction="row">
               <PaymentOption
                  label="Pay Now"
                  isChecked={(selectedOption !=="easy-cart" || selectedOption !=="cod")&& selectedOption === "pay-now" }
                  value="pay-now"
                  handleChange={handleOptionChange}
                />

                {showEasyCartBtn && page === "address-page" && (
                  <PaymentOption
                    label="Generate Easy Cart Link"
                    isChecked={selectedOption === "easy-cart"}
                    value="easy-cart"
                    handleChange={handleOptionChange}
                  />
                )}
              </Stack>

              {showCodBtn && (
                <PaymentOption
                  label="Cash on Delivery"
                  subText={`(COD Cast ₹${Number(codCost).toFixed(
                    2
                  )}) | ₹${Number(codAmt).toFixed(2)}`}
                  isChecked={selectedOption === "cod"}
                  value="cod"
                  handleChange={handleOptionChange}
                />
              )}

              <Box
                display="flex"
                // justifyContent='flex-end'
                mt={{ md: 1.5, xs: 0 }}
                pb={1}
              >
                <Button
                  sx={{
                    color: "#fff",
                    borderRadius: "50px",
                    fontWeight: 600,
                    fontSize: "12px",
                    //border: '1px solid #000',
                    backgroundColor: blackColor,
                    width: "99%",
                    margin: "8px auto 10px",
                    display: "block",
                    padding: "8px 14px",
                    // ...styles.gotoCardBtn(isDisabled),
                    border: confirmPayLoader
                      ? "none"
                      : `1px solid ${blackColor}`,
                    textTransform: "uppercase",
                    "&:hover": {
                      backgroundColor: whiteColor,
                      color: blackColor,
                    },
                  }}
                  variant="contained"
                  disableElevation
                  size="small"
                  onClick={() => {
                    handlePaymentConfirm();
                  }}
                  disabled={isDisabled || confirmPayLoader || paycartLoading}
                >
                  {confirmPayLoader || paycartLoading ? (
                    <CircularProgress
                      sx={{ color: blackColor, mr: 1 }}
                      size={16}
                    />
                  ) : null}
                  CONFIRM
                </Button>
              </Box>
            </Box>
          </>
        )}

        <Box px={1} sx={{ position: "relative" }}>
          {tooltip ? (
            <Box
              sx={{
                backgroundColor: "#f5f5f5",
                px: 2,
                py: 1,
                borderRadius: 2,
                maxWidth: "60%",
                position: "absolute",
                bottom: "115%",
                border: "1px solid #efefef",
              }}
              ref={ref}
            >
              {finboxStatus === "APPLY" || finboxStatus === "notRegistered" ? (
                <>
                  {fintechApplyData.map((item, index) => {
                    return (
                      <ListItem sx={{ py: 0 }}>
                        <ListItemText
                          sx={{
                            display: "list-item",
                          }}
                          key={index + "main_product"}
                          primary={
                            <Typography
                              variant="subtitle1"
                              fontSize="12px"
                              color={couponColor}
                            >
                              {item}
                            </Typography>
                          }
                        />
                      </ListItem>
                    );
                  })}
                </>
              ) : null}
              {finboxStatus === "INCOMPLETE" ||
              finboxStatus === "notCompleted" ? (
                <>
                  <ListItem sx={{ py: 0 }}>
                    <ListItemText
                      sx={{
                        display: "list-item",
                      }}
                      key={"main_product"}
                      primary={
                        <Typography
                          variant="subtitle1"
                          fontSize="13px"
                          color={couponColor}
                        >
                          You are just few steps away from receiving working
                          capital
                        </Typography>
                      }
                    />
                  </ListItem>
                </>
              ) : null}
              {finboxStatus === "APPROVED" ? (
                <>
                  <ListItem sx={{ py: 0 }}>
                    <ListItemText
                      sx={{
                        display: "list-item",
                      }}
                      key={"main_product"}
                      primary={
                        <Typography
                          variant="subtitle1"
                          fontSize="13px"
                          color={couponColor}
                        >
                          Just complete last few steps to avail the funds
                        </Typography>
                      }
                    />
                  </ListItem>
                </>
              ) : null}
              {finboxStatus === "ACTIVE" || finboxStatus === "DUE" ? (
                <>
                  <ListItem sx={{ py: 0 }}>
                    <ListItemText
                      sx={{
                        display: "list-item",
                      }}
                      key={1}
                      primary={
                        <Typography
                          variant="subtitle1"
                          fontSize="13px"
                          color={couponColor}
                        >
                          Available credit: ₹{finboxAvlAmt}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem sx={{ py: 0 }}>
                    <ListItemText
                      sx={{
                        display: "list-item",
                      }}
                      key={2}
                      primary={
                        <Typography
                          variant="subtitle1"
                          fontSize="13px"
                          color={couponColor}
                        >
                          You will receive an OTP in your registered phone
                          number
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem sx={{ py: 0 }}>
                    <ListItemText
                      sx={{
                        display: "list-item",
                      }}
                      key={3}
                      primary={
                        <Typography
                          variant="subtitle1"
                          fontSize="13px"
                          color={couponColor}
                        >
                          Verify and agree to the terms to use the credit line
                        </Typography>
                      }
                    />
                  </ListItem>
                </>
              ) : null}
              {finboxStatus === "balanceAvailable" ? (
                <>
                  <ListItem sx={{ py: 0 }}>
                    <ListItemText
                      sx={{
                        display: "list-item",
                      }}
                      key={1}
                      primary={
                        <Typography
                          variant="subtitle1"
                          fontSize="13px"
                          color={couponColor}
                        >
                          Available credit: ₹{finvervAmt}
                        </Typography>
                      }
                    />
                  </ListItem>
                </>
              ) : null}
            </Box>
          ) : null}

          {finboxStatus === "APPLY" && page === "address-page" ? (
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={1}
            >
              <Grid item>
                <Button
                  onClick={() => setTooltip(!tooltip)}
                  sx={{
                    color: greyShade40,
                    fontSize: 12,
                    fontWeight: 400,
                    textTransform: "uppercase",
                  }}
                >
                  See More
                  <InfoIcon sx={{ fontSize: "16px", ml: 1 }} />
                </Button>
              </Grid>

              <Grid item>
                <Button
                  sx={styles.finboxBtn(isDisabled)}
                  variant="contained"
                  disableElevation
                  size="small"
                  onClick={() => {
                    onModalFinboxUrl();
                  }}
                >
                  Apply for Credit line
                </Button>
              </Grid>
            </Grid>
          ) : null}

          {(finboxStatus === "INCOMPLETE" || finboxStatus === "APPROVED") &&
          page === "address-page" ? (
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={1}
            >
              <Grid item>
                <Button
                  onClick={() => setTooltip(!tooltip)}
                  sx={{
                    color: greyShade40,
                    fontSize: 12,
                    fontWeight: 400,
                    textTransform: "uppercase",
                  }}
                >
                  See More
                  <InfoIcon sx={{ fontSize: "16px", ml: 1 }} />
                </Button>
              </Grid>

              <Grid item>
                <Button
                  sx={styles.finboxBtn(isDisabled)}
                  variant="contained"
                  disableElevation
                  size="small"
                  onClick={() => {
                    onModalFinboxUrl();
                  }}
                  // disabled={isDisabled}
                >
                  Complete your credit application
                </Button>
              </Grid>
            </Grid>
          ) : null}

          {(finboxStatus === "ACTIVE" || finboxStatus === "DUE") &&
          page === "address-page" ? (
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={1}
              // sx={{ borderBottom: '1px solid #737373' }}
            >
              <Grid item>
                <Button
                  onClick={() => setTooltip(!tooltip)}
                  sx={{
                    color: greyShade40,
                    fontSize: 12,
                    fontWeight: 400,
                    textTransform: "uppercase",
                  }}
                >
                  See More
                  <InfoIcon sx={{ fontSize: "16px", ml: 1 }} />
                </Button>
              </Grid>

              <Grid item>
                <Button
                  sx={styles.finboxBtn(isDisabled)}
                  variant="contained"
                  disableElevation
                  size="small"
                  onClick={() => {
                    handleFinboxClick();
                  }}
                  // disabled={isDisabled}
                >
                  PAY WITH CREDIT LINE
                </Button>
              </Grid>
            </Grid>
          ) : null}

          {finboxStatus === "balanceAvailable" && page === "address-page" ? (
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={1}
              // sx={{ borderBottom: '1px solid #737373' }}
            >
              <Grid item>
                <Button
                  onClick={() => setTooltip(!tooltip)}
                  sx={{
                    color: greyShade40,
                    fontSize: 12,
                    fontWeight: 400,
                    textTransform: "uppercase",
                  }}
                >
                  See More
                  <InfoIcon sx={{ fontSize: "16px", ml: 1 }} />
                </Button>
              </Grid>

              <Grid item>
                <Button
                  sx={styles.finboxBtn(isDisabled)}
                  variant="contained"
                  disableElevation
                  size="small"
                  onClick={() => {
                    handleFinvervClick();
                  }}
                  // disabled={isDisabled}
                >
                  PAY WITH CREDIT LINE
                </Button>
              </Grid>
            </Grid>
          ) : null}

          {finboxStatus === "notRegistered" && page === "address-page" ? (
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={1}
            >
              <Grid item>
                <Button
                  onClick={() => setTooltip(!tooltip)}
                  sx={{
                    color: greyShade40,
                    fontSize: 12,
                    fontWeight: 400,
                    textTransform: "uppercase",
                  }}
                >
                  See More
                  <InfoIcon sx={{ fontSize: "16px", ml: 1 }} />
                </Button>
              </Grid>

              <Grid item>
                <Button
                  sx={styles.finboxBtn(isDisabled)}
                  variant="contained"
                  disableElevation
                  size="small"
                  onClick={() => {
                    handleNotRegistered();
                  }}
                >
                  Apply for Credit line
                </Button>
              </Grid>
            </Grid>
          ) : null}

          {finboxStatus === "notCompleted" && page === "address-page" ? (
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={1}
            >
              <Grid item>
                <Button
                  onClick={() => setTooltip(!tooltip)}
                  sx={{
                    color: greyShade40,
                    fontSize: 12,
                    fontWeight: 400,
                    textTransform: "uppercase",
                  }}
                >
                  See More
                  <InfoIcon sx={{ fontSize: "16px", ml: 1 }} />
                </Button>
              </Grid>

              <Grid item>
                <Button
                  sx={styles.finboxBtn(isDisabled)}
                  variant="contained"
                  disableElevation
                  size="small"
                  onClick={() => {
                    handleNotCompleted();
                  }}
                  // disabled={isDisabled}
                >
                  Complete your credit application
                </Button>
              </Grid>
            </Grid>
          ) : null}

          {/* {showEasyCartBtn && page === 'address-page' ? (
          <Grid
            container
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            mb={1}
          >
            <Grid item xs></Grid>

            <Grid item>
              <Button
                sx={styles.codBtn(paycartLoading)}
                variant='contained'
                disableElevation
                size='small'
                onClick={() => {
                  confirmPaycartOrder()
                }}
                disabled={paycartLoading}
              >
                GENERATE EASY CART LINK
              </Button>
            </Grid>
          </Grid>
        ) : null}

        {showCodBtn ? (
          <Grid
            container
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            mb={1}
          >
            <Grid item>
              <Typography
                variant='body2'
                color={catalogSubHeaderColor}
                fontWeight={500}
                display='inline'
                px={1.5}
                borderRight={borderCart}
              >
                COD Cost:{' '}
                <span style={{ fontWeight: 600, color: blackColor }}>
                  ₹{Number(codCost).toFixed(2)}
                </span>
              </Typography>
              
              <Typography
                variant='h6'
                color={headingColor}
                display='inline'
                ml={1.5}
              >
                ₹{Number(codAmt).toFixed(2)}
              </Typography>
            </Grid>

            <Grid item>
              <Button
                sx={styles.codBtn(isDisabled)}
                variant='contained'
                disableElevation
                size='small'
                onClick={() => {
                  onCodClicked()
                }}
                disabled={isDisabled}
              >
                CASH ON DELIVERY
              </Button>
            </Grid>
          </Grid>
        ) : null} */}

          {page !== "address-page" &&
            (matches ? (
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography
                    variant="body2"
                    color={catalogSubHeaderColor}
                    fontWeight={500}
                    display="inline"
                    px={1.5}
                    borderRight={borderCart}
                  >
                    {totalQty} items in cart
                  </Typography>
                  <Typography
                    variant="h6"
                    color={headingColor}
                    display="inline"
                    ml={1.5}
                  >
                    ₹{Number(amount).toFixed(2)}
                  </Typography>
                </Grid>

                <Grid item>
                  <Button
                    sx={{
                      ...styles.gotoCardBtn(isDisabled),
                      border: confirmPayLoader
                        ? "none"
                        : `1px solid ${{ md: loginPrimary, xs: blackColor }}`,
                    }}
                    variant="contained"
                    disableElevation
                    size="small"
                    onClick={() => {
                      onBtnClicked();
                    }}
                    disabled={isDisabled || confirmPayLoader}
                  >
                    {confirmPayLoader ? (
                      <CircularProgress
                        sx={{ color: blackColor, mr: 1 }}
                        size={16}
                      />
                    ) : null}
                    {btnText}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Box margin={"20px 20px"} mr={3}>
                <Box
                  sx={{
                    position: "fixed",
                    width: "83%",
                    bottom: "15px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    zIndex: 2,
                    maxWidth: "600px",
                    height: "auto",
                    // display: 'flex',
                    // alignItems: 'center',
                    padding: "6px 7px 6px 24px",
                    backgroundColor: "rgb(0, 0, 0)",
                    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
                    borderRadius: "50px",
                  }}
                >
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Box
                      display={"flex"}
                      flexDirection={{ md: "row", xs: "column" }}
                    >
                      <Typography
                        variant="h6"
                        // color={{ md: headingColor, xs: '#9ba3aa' }}
                        color={{ md: headingColor, xs: whiteColor }}
                        fontSize={{ md: "13px", xs: 11.5 }}
                        fontWeight={500}
                        borderRight={{ md: borderCart, xs: "none" }}
                      >
                        {totalQty} items in cart
                      </Typography>
                      <Typography
                        mt={-0.5}
                        variant="h5"
                        color={{ md: headingColor, xs: whiteColor }}
                        fontSize={{ md: "15px", xs: 14 }}
                        fontWeight={600}
                        display="inline"
                      >
                        ₹ {Number(amount).toFixed(2)}
                      </Typography>
                    </Box>
                    <Box
                      display={"flex"}
                      onClick={() => {
                        onBtnClicked();
                      }}
                      alignItems={"center"}
                    >
                      {/* <Button
                        sx={{
                          ...styles.gotoCardBtnmobile,
                          border: confirmPayLoader
                            ? "none"
                            : `1px solid ${{
                                md: blackColor,
                                xs: blackColor,
                              }}`,
                        }}
                        onClick={() => {
                          onBtnClicked();
                        }}
                        variant="contained"
                        disableElevation
                        size="small"
                        disabled={isDisabled || confirmPayLoader}
                      >
                        {confirmPayLoader ? (
                          <CircularProgress
                            sx={{ color: blackColor, mr: 1 }}
                            size={16}
                          />
                        ) : null}
                        {btnText}
                      </Button> */}
                      <Typography
                        sx={{
                          fontSize: { md: "12px", xs: 12.5 },
                          fontWeight: { md: 500, xs: 600 },
                          color: whiteColor,
                          textAlign: "center",
                          mr: 2,
                        }}
                      >
                        {confirmPayLoader ? (
                          <CircularProgress
                            sx={{ color: blackColor, mr: 1 }}
                            size={16}
                          />
                        ) : null}
                        {btnText}
                      </Typography>
                      <Box
                        sx={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#fff",
                          color: "#000",
                          borderRadius: "50px",
                          fontSize: "27px",
                          padding: "7px",
                          // marginLeft: '6px',
                          display: "flex",
                          alignItems: "center",
                          mt: 0.5,
                        }}
                        // component={Link}
                        onClick={() => {
                          onBtnClicked();
                        }}
                        // to={`/${businessName}/Cart`}
                      >
                        <NavigateNextIcon fontSize="27px" sx={{ ml: -0.4 }} />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
}

export default CartBottomTab

const PaymentOption = ({ isChecked, handleChange, value, label, subText }) => {
  return (
    <Box display="flex" alignItems="center">
      <Radio
        size="small"
        id={value}
        checked={isChecked}
        onChange={handleChange}
        value={value}
        sx={{
          color: blackColor, // default color
          "&.Mui-checked": {
            color: blackColor, // color when checked
          },
        }}
      />

      <Box>
        <Typography
          component="label"
          for={value}
          sx={{
            display: "inline",
            fontSize: 13.5,
            fontWeight: 600,
            color: blackColor,
          }}
        >
          {label}
        </Typography>

        <Typography
          sx={{
            display: "inline",
            fontSize: 12,
            fontWeight: 500,
            color: catalogSubHeaderColor,
            ml: 0.5,
          }}
        >
          {subText}
        </Typography>
      </Box>
    </Box>
  );
}

const useOutsideClick = (callback) => {
  const ref = useRef()

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }

    document.addEventListener('click', handleClick, true)

    return () => {
      document.removeEventListener('click', handleClick, true)
    }
  }, [ref])

  return ref
}

const styles = {
  card: {
    width: "100vw",
    display: { xs: "block", md: "none" },
    borderTop: { md: "1px solid #f4f6fa", xs: "none" },
    borderBottom: "1.5px solid #f4f6fa",
    position: "fixed",
    bottom: { md: "-1px", xs: "-15px" },
    // bottom: '58px',
    left: 0,
    zIndex: 10,
    boxShadow: "0 1px 4px rgb(0 0 0 / 8%)",
  },

  gotoCardBtn: (disabled) => ({
    // width:'145px',
    fontSize: "12px",
    fontWeight: 600,
    color: whiteColor,
    backgroundColor: blackColor,
    p: { xs: "3px 15px", md: "3px 8px" },
    border: disabled ? "0px solid " + blackColor : "2px solid " + blackColor,
    textTransform: "uppercase",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: whiteColor,
      color: blackColor,
      border: `1px solid ${blackColor}`,
    },
  }),
  gotoCardBtnmobile: {
    fontSize: { md: "12px", xs: 12.5 },
    fontWeight: { md: 500, xs: 600 },
    color: whiteColor,
    textTransform: "uppercase",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: whiteColor,
      color: blackColor,
    },
  },

  codBtn: (disabled) => ({
    // width:'145px',
    fontSize: "12px",
    fontWeight: 500,
    color: "white",
    p: { xs: "3px 15px", md: "3px 8px" },
    border: disabled
      ? "0px solid " + secondaryColor
      : "2px solid " + secondaryColor,
    textTransform: "none",
    borderRadius: "6px",
    backgroundColor: secondaryColor,
    textTransform: "uppercase",
  }),

  finboxBtn: (disabled) => ({
    // width:'145px',
    fontSize: "12px",
    fontWeight: 500,
    color: "white",
    p: { xs: "3px 15px", md: "3px 8px" },
    border: disabled ? "0px solid " + topbarColor : "2px solid " + topbarColor,
    borderRadius: "6px",
    backgroundColor: topbarColor,
    textTransform: "uppercase",
  }),
};
