import { Box, Button, Typography } from '@mui/material'
import {
  cardEmptyGuestBtnText,
  reviewLoginAlertImgUrl,
  reviewLoginMsg,
} from 'configs/Constants'
import { blackColor, headingColor } from 'configs/styles/muiThemes'
import React from 'react'

function ReviewLoginAlert({ onLoginClick }) {
  let businessFullName = sessionStorage.getItem('businessFullName')

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      my={{ xs: 1, md: 2.2 }}
    >
      <Box
        component="img"
        src={reviewLoginAlertImgUrl}
        width={{ xs: "60px", md: "64.34px" }}
        height="auto"
        alt={businessFullName + " CostBo"}
        sx={{ padding: "0px 0px 0 0px" }}
      />
      <Typography
        variant="subtitle2"
        fontSize={{ xs: 13, md: 15 }}
        color={headingColor}
        p="14px 0 10px 0"
        m={{xs:'2px 0 2px !important', md:0}}
      >
        {reviewLoginMsg}
      </Typography>

      <Button
        variant="contained"
        disableElevation
        sx={{
          bgcolor: blackColor,
          fontSize: { xs: 12, md: 12 },
          fontWeight: 600,
          lineHeight: "18px",
          p: "7.5px 30px",
          borderRadius: "7px",
          textTransform: "uppercase",
          "&:hover": {
            bgcolor: blackColor,
          },
        }}
        onClick={() => {
          onLoginClick();
        }}
      >
        {cardEmptyGuestBtnText}
      </Button>
    </Box>
  );
}

export default ReviewLoginAlert
