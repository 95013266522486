import React from 'react'
import GoogleMapReact from 'google-map-react'
import { Box } from '@mui/material'
import RoomIcon from '@mui/icons-material/Room'

const MapComponent = ({ latitude, longitude }) => {
  return (
    <Box
      style={{ height: '350px', marginBottom: '10px', overflow: 'hidden' }}
      borderRadius={{xs: '20px', md:3}}
      width='100%'
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyAzzu692ouH2AXFzesPIVLfHeO8RmhN44Q' }}
        defaultCenter={{
          lat: latitude,
          lng: longitude,
        }}
        defaultZoom={15}
      >
        <LocationPin lat={latitude} lng={longitude} />
      </GoogleMapReact>
    </Box>
  )
}

const LocationPin = (props) => {
  return (
    <Box>
      <RoomIcon sx={{ fontSize:'35px', color: 'red' }} />
    </Box>
  )
}

export default MapComponent
