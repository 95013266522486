import { StarRounded } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Rating,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  addReview,
  editReview,
} from '../../services/api_calls/review_api/reviewApi'
import moment from 'moment'
import {
  arrowBgColor,
  backgroundColor,
  blackColor,
  borderTextbox,
  cartBorderColor01,
  labelColor,
  orangeColor01,
  whiteColor,
} from '../../configs/styles/muiThemes'
import AlertDialog from 'components/AlertDialog'
import { allowOnlyEnglish } from 'utils/utilities'
import StarRoundedIcon from '@mui/icons-material/StarRounded';

/**
 * Edit Existing Personal Reviews
 * @param styles
 * @param isUpdate
 * @param ownReview
 * @param onClose
 * @param getOwnReviewDetails
 * @param getSummary
 *  @author Rupesh
 */
function EditReviewForm(props) {
  const [reviewRating, setReviewRating] = useState(
    props.ownReview ? props.ownReview.reviewRating || 0 : 0
  )
  const [reviewTitle, setReviewTitle] = useState(
    props.ownReview?.reviewRating || ''
  )
  const [message, setMessage] = useState(props.ownReview?.reviewRating || '')
  const [errorDialog, setErrorDialog] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setMessage(props.ownReview?.message || '')
    setReviewRating(props.ownReview?.reviewRating || 0)
    setReviewTitle(props.ownReview?.reviewTitle || '')
  }, [props])

  const handleTitleChange = (event) => {
    const finalValue = allowOnlyEnglish(event.target.value)
    setReviewTitle(finalValue)
  }
  const handleDescriptionChange = (event) => {
    const finalValue = allowOnlyEnglish(event.target.value)
    setMessage(finalValue)
  }

  /**
   * Save Edited Review
   * @author Rupesh
   */
  const editReviewData = async () => {
    if (loading) return
    console.log('EDIT_HIT', reviewTitle, '--', message)

    if (reviewTitle.length > 200) {
      setErrorMsg('Title should not be more than 200 characters.')
      setErrorDialog(true)
      return
    }

    if (message.length > 5000) {
      setErrorMsg('Description should not be more than 5000 characters.')
      setErrorDialog(true)
      return
    }

    if (reviewTitle.length < 10 || message.length < 10) {
      setErrorMsg('Title and description should be of minimum 10 characters.')
      setErrorDialog(true)
      return
    }

    if (reviewRating <= 0) {
      setErrorMsg('Your Rating should not be empty   ')
      setErrorDialog(true)
      return
    }

    setLoading(true)

    if (reviewRating > 0 && reviewTitle.length > 10 && message.length > 10) {
      const data = {
        reviewRating,
        reviewDetails: [
          {
            reviewTitle,
            reviewRating,
            message,
            messageCreateDate: moment().format('MMM-DD-YYYY'),
          },
        ],
      }

      editReview(data, props.ownReview.id)
        .then((res) => {
          props.getOwnReviewDetails()
          props.getSummary()
          props.onClose()

          setTimeout(() => {
            setLoading(false)
          }, 3000)
        })
        .catch((err) => {
          setLoading(false)
        })
    }
    // else{
    //   setErrorDialog(true)
    // }
  }
  return (
    <Box sx={props.styles}>
      <Box sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
        <Typography variant='body1' sx={{...styles.label, fontSize:14}}>
          Your Rating : 
        </Typography>
        <Rating
          icon={
            <StarRoundedIcon sx={{
              width: "18.5px",
              height: "18.5px",
              color: orangeColor01,
              ml:-0.2
            }} />

            // <Box
            //   component='img'
            //   src={`https://storage.googleapis.com/bodefaults/shopweb/reviews/star.svg`}
            //   alt={`Star`}
            //   sx={{
            //     width: '14x',
            //     height: '14px',
            //     display: 'flex',
            //     lineHeight: 1.5,
            //     mr: '2px',
            //   }}
            // />
          }
          emptyIcon={
            <StarRoundedIcon sx={{
              width: "18.5px",
              height: "18.5px",
              ml:-0.2
            }} />
            // <Box
            //   component='img'
            //   src={`https://storage.googleapis.com/bodefaults/shopweb/reviews/star-1.svg`}
            //   alt={`Star`}
            //   sx={{
            //     width: '14x',
            //     height: '14px',
            //     display: 'flex',
            //     lineHeight: 1.5,
            //   }}
            // />
          }
         
        precision={1}
        value={reviewRating}
        onChange=
        {(event) => {
          setReviewRating(parseInt(event.target.value, 10))
        }}
        sx={{ ml: 1, gap: 0.5 }}
        />
      </Box>

      <Box sx={{ m: "8px 0 4px" }}>
        <Typography variant='body1' sx={styles.label}>
          Title{' '}
        </Typography>
        <Typography variant='body1' sx={[styles.label, { color: '#f00' }]}>
          *
        </Typography>
      </Box>

      <Box>
        <TextField
          value={reviewTitle}
          variant='standard'
          sx={styles.inputBox(props?.isUpdate ? whiteColor : backgroundColor)}
          fullWidth
          InputProps={{
            disableUnderline: true,
          }}
          textProps={{
            error: reviewTitle.length < 10 || reviewTitle.length > 200,
            helperText:
              reviewTitle.length < 10 || reviewTitle.length > 200
                ? 'Min 10 characters, Max 200 characters'
                : '',
            value: reviewTitle,

            onChange: (title) => {
              handleTitleChange(title)
            },
          }}
          placeholder='(Min 10 characters, Max 200 characters)'
          onChange={(event, newValue) => {
            handleTitleChange(event)
          }}
        />

        <Box sx={{ m: "8px 0 4px" }}>
          <Typography variant='body1' sx={styles.label}>
            Description{' '}
          </Typography>
          <Typography variant='body1' sx={[styles.label, { color: '#f00' }]}>
            *
          </Typography>
        </Box>

        <TextareaAutosize
          minRows={2}
          aria-label='maximum height'
          style={{...styles.inputBox(
            props?.isUpdate ? whiteColor : backgroundColor
          ),  padding: "6px 10px 4px 10px"}}
          placeholder='(Min 10 chracters, Max 5000 characters)'
          value={message}
          onChange={(event, newValue) => {
            handleDescriptionChange(event)
          }}
        />
      </Box>

      <Box sx={styles.btnContainer}>
        <Button
          sx={styles.submitBtnNew}
          variant='contained'
          disableElevation
          size='small'
          onClick={() => (props.isUpdate ? editReviewData() : addReview())}
        >
          {loading ? (
            <CircularProgress sx={{ color: whiteColor, mr: 1 }} size={16} />
          ) : null}{' '}
          {props.isUpdate ? 'Update' : 'Submit Review'}
        </Button>
      </Box>

      <AlertDialog
        content={errorMsg}
        isOpen={errorDialog}
        onClose={() => {
          setErrorDialog(false)
        }}
      />
    </Box>
  )
}

export default EditReviewForm

const styles = {
  label: {
    fontSize: "13px",
    color: blackColor,
    fontWeight: 500,
    display: "inline",
  },

  inputBox: (color) => ({
    width: "90%",
    border: `1px solid ${cartBorderColor01}`,
    borderRadius: "6px",
    fontSize: "13px",
    fontFamily: "Poppins",
    outline: "none",
    color: blackColor,
    background: color,
    padding: "4px 10px",
  }),

  infolabel: {
    display: "block",
    fontSize: "13px",
    textAlign: "right",
    py: "8px",
    color: "#9e9fa3",
  },

  btnContainer: { display: "flex", justifyContent: "flex-end", mt: 1 },

  submitBtn: {
    fontSize: "14px",
    fontWeight: 500,
    color: whiteColor,
    p: "7px 28px",
    backgroundColor: { md: `primary.main`, xs: blackColor },
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "primary.main",
    },
  },
  submitBtnNew: {
    backgroundColor: "#ffffff",
    border: "1px solid #000000",
    float: "right",
    fontSize: "13px",
    padding: "4px 20px",
    color: "#000",
    fontWeight: "600",
    borderRadius: "5px",
    textTransform: "uppercase",
    mr:"50px",
    "&:hover": {
      color: { xs: blackColor, md: whiteColor },
      backgroundColor: { md: blackColor, xs: whiteColor },
    },
  },
};
