import { Box, Container, Grid, Skeleton } from "@mui/material";
import React from "react";
import CartItemsLoader from "./CartItemsLoader";
import { backgroundColor01 } from "configs/styles/muiThemes";

const CartLoader = () => {
  return (
    <Box bgcolor={backgroundColor01}>
      <Container
        sx={{
          maxWidth: "1350px !important",
          p: "0 10px 0 15px !important",
        }}
      >
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} md={7.953}>
            <CartItemsLoader />
          </Grid>

          <Grid item xs={12} md={3.95}>
            <Skeleton variant="rounded" width="100px" height="13px" />
            <Skeleton variant="rounded" width="auto" height="185px" sx={{mt:'12px !important'}} />
            <Skeleton variant="rounded" width="auto" height="47px" sx={{mt:'12px !important'}} />

            <Skeleton variant="rounded" width="100px" height="13px" sx={{mt:'18px !important'}}/>
            <Skeleton variant="rounded" width="auto" height="135px" sx={{mt:'12px !important'}} />
            <Skeleton variant="rounded" width="auto" height="58px" sx={{mt:'12px !important'}} />
            <Skeleton variant="rounded" width="auto" height="58px" sx={{mt:'12px !important'}} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default CartLoader;
