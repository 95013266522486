import { Box, Grid, Skeleton } from '@mui/material'
import { whiteColor } from 'configs/styles/muiThemes'
import React from 'react'

const ApplyDiscountLoader = () => {
  return (
    <Box mt={0.6} height='215px'>
        <Skeleton variant="rounded" width="350px" height="13px" />

        <Box mt={2}>
            <Grid container spacing={3}>
                <Grid item md={6}>
                    <DiscountCardSkeleton />
                </Grid>
                <Grid item md={6}>
                    <DiscountCardSkeleton />
                </Grid>
            </Grid>
        </Box>
    </Box>
  )
}

export default ApplyDiscountLoader

const DiscountCardSkeleton = ()=>(
    <Box sx={{bgcolor: whiteColor, borderRadius: '10px', p:'10px', height:'142px'}}>
        <Box display='flex' alignItems='center' justifyContent='space-between' mb={2}>
            <Skeleton variant="rounded" width="198px" height="15px"  />
            <Skeleton variant="rounded" width="30px" height="30px" sx={{borderRadius:'50%', mr:2}}  />
        </Box>

        <Skeleton variant="rounded" width="263px" height="36px"  />
        <Skeleton variant="rounded" width="90%" height="11px" sx={{mt: '12px !important'}} />
        <Skeleton variant="rounded" width="30%" height="11px" sx={{mt: '12px !important'}} />
    </Box>
)