import { Box, Typography } from '@mui/material'
import {
  backgroundColor01,
  blackColor,
  lightBlueColor,
  orangeColor,
  orangeLightColor,
} from '../../configs/styles/muiThemes'

function HowItWorks() {
  return (
    <Box p='15px'>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
      >
        <Typography
          variant='h6'
          fontSize={12}
          fontWeight={500}
          color={orangeColor}
          textAlign='center'
          sx={styles.heading}
        >
          HOW IT WORKS ?
        </Typography>

        <Typography
          variant='h3'
          fontSize={16}
          fontWeight={600}
          lineHeight='24px'
          color={blackColor}
          textAlign='center'
          sx={{ m: '5px 0 4px !important' }}
        >
          Sign up, Promote, Earn. It's that Simple
        </Typography>
      </Box>

      <Box display={"flex"} flexDirection={"column"} gap={2} m='15px 5px !important'>
        <InfoStepCard
          icon='https://storage.googleapis.com/bodefaults/shopweb/register-as-brand.svg'
          title='Register as a Brand affiliate'
          description='Get a unique affiliate Code'
        />
        <InfoStepCard
          icon='https://storage.googleapis.com/bodefaults/shopweb/promote-brands-products.svg'
          title='Promote our products'
          description='Via Whatsapp, Instagram, other Social Media/Blogs/Emails/Community groups etc'
        />
        <InfoStepCard
          icon='https://storage.googleapis.com/bodefaults/shopweb/earn-commission.svg'
          title='Earn Commission'
          description='Receive commission payment based on customer orders'
        />
      </Box>
    </Box>
  )
}

export default HowItWorks

const InfoStepCard = ({ icon, title, description }) => {
  return (
    <Box sx={styles.card}>
      <Box
        component='img'
        src={icon}
        sx={{ width: '60px', height: '60px', objectFit: 'contain', mb: '6px' }}
      />

      <Box>
        <Typography
          variant='h6'
          fontSize={14}
          fontWeight={600}
          lineHeight='20px'
          textAlign='center'
          color={blackColor}
          mb='6px !important'
        >
          {title}
        </Typography>

        <Typography
          variant='body2'
          fontSize={12.5}
          fontWeight={400}
          lineHeight='20px'
          textAlign='center'
          color={blackColor}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  )
}

const styles = {
  heading: {
    color: '#e56b46',
    fontSize: '12px',
    textTransform: 'uppercase',
    fontWeight: '500',
    background: '#fcefec',
    padding: '2px 20px',
    borderRadius: '50px',
    marginBottom: '4px',
    display: 'inline-block',
  },

  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '15px',
    // margin: '15px',
    borderRadius: '8px',
    backgroundColor: backgroundColor01,
  },
}
