import { Box, Typography } from '@mui/material'
import { blackColor, whiteColor } from 'configs/styles/muiThemes'

function ProfileCharAvatar({ username, imgwidth, imgheight, bgcolor }) {
  const charList = [
    { char: ['b', 'c', 'd', 'e', 't'], color: '#fb7b1d' },
    { char: ['f', 'h', 'i', 'j'], color: '#e84533' },
    { char: ['m', 'n', 'o', 'p', 'q'], color: '#01b9cd' },
    { char: ['s', 'u', 'v', 'w'], color: '#16be4c' },
    { char: ['x', 'y', 'z', 'g', 'k'], color: '#865ed8' },
    { char: ['r', 'l', 'a'], color: '#354bc0' },
  ]

  const getColor = (key) => {
    let color = '#e8e8e8'

    if (username.length > 0) {
      charList.forEach((item) => {
        if (item.char.includes(username.charAt(0).toLowerCase())) {
          color = item.color
        }
      })
    }

    return color
  }

  return (
    <Box
      width={imgwidth ? imgwidth : {md:'40px',xs:"33px"}}
      height={imgheight ? imgheight : '40px'}
      borderRadius='50%'
      backgroundColor={{ xs: whiteColor, md: bgcolor ? bgcolor : blackColor }}
      display='flex'
      alignItems='center'
      justifyContent='center'
    >
      <Typography
        variant='boby1'
        fontSize={{ xs: 16, md: 13 }}
        fontWeight={600}
        color={{ xs: blackColor, md: bgcolor ? blackColor:whiteColor }}
      >
        {username
          .split(' ')
          .map((word) => word[0])
          .join('')
          .slice(0, 2)}
      </Typography>
    </Box>
  )
}

export default ProfileCharAvatar
