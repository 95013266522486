import axios from "axios";
import {
  ADD_PAYMENT_PROCESS,
  BUSINESS_CONTACT_API,
  CHECK_DELIVERY_AVAILABILITY_API,
  CHECK_RESELLER_COUPON_API,
  DELHIVERY_COST_CALCUTATION_API,
  DELHIVERY_SERVICEABILITY_API,
  DELHIVERY_SHIPMENTPRICE_API,
  FINVERV_USER_EXISTS_URL,
  FIRST_TIME_DISCOUNT_API,
  GET_COORDINATES_API,
  GET_FINVERV_LINE_URL,
  GET_FINVERV_URL,
  MODIFY_ADDRESS_API,
  ORDER_LOAN_URL,
  ORDER_COD_CART_URL,
  REGISTER_FINVERV_USER_URL,
  SEND_ORDER_MESSAGE,
  SEND_ORDER_NOTIFICATION,
  STATE_CITY_API,
  TELYPORT_PRICING_URL,
  TELYPORT_REGION_CHECK_URL,
  TELYPORT_SERVICE_TIME_CHECK_URL,
  UPDATE_WEB_ORDER_PAYMENT,
  VALIDATE_CODE_API,
  VIEW_ACTIVE_BUSINESS_API,
  FINVERV_APPLICATION_STATUS_URL,
  VALIDATE_CODE_ATAG,
  CHECK_FINBOX_USER_URL,
  REGISTER_FINBOX_USER_URL,
  FINBOX_PARTNER_PUSH_URL,
  GENERATE_FINBOX_URL,
  ELIGIBILITY_FINBOX_URL,
  BANNER_FINBOX_URL,
  CREDIT_LINE_FINBOX_URL,
  LOAN_ORDER_FINBOX_URL,
  TRANSACTION_FINBOX_URL,
  CREATE_LOAN_ORDER_URL,
  UPDATE_GST_INFO,
  TELYPORT_PRICING_V2_URL,
  GENERATE_PAYMENT_LINK,
  UPGRADE_TO_PAYCART,
  ONDC_PRICING,
} from "services/constant";
import {
  getAccessToken,
  getProfileId,
  getUserDetails,
} from "utils/authHelpers";
import { getApiInstance } from "../../ApiInstance";

export const getAddressData = async () => {
  try {
    const instance = getApiInstance();

    const response = await instance.get(
      `account-query-side/api/v1/account/getAddress`
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
export const getRewardPointsData = async () => {
  const businessId = sessionStorage.getItem("businessId");
  try {
    const instance = getApiInstance();
    const profile = getProfileId();
    if (profile) {
      const response = await instance.get(
        `reward-query-side/api/v1/rewardpoint/getRewardPoints`,
        {
          headers: {
            businessId: businessId,
          },
        }
      );
      console.log("REWARD", response.data);
      sessionStorage.setItem("cashback", JSON.stringify(response?.data));
      return response?.data;
    } else return null;
  } catch (error) {
    console.log(error);
  }
};

export const modifyAddress = async (data) => {
  try {
    const instance = getApiInstance();

    const response = await instance.post(MODIFY_ADDRESS_API, data);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getStateCity = async (data) => {
  try {
    const instance = getApiInstance();

    const response = await instance.get(STATE_CITY_API, {
      params: {
        address: data,
      },
    });
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
export const getCoordinates = async (data) => {
  console.log("Coordinates_data", data.city, data.address);
  try {
    console.log("Coordinates", data);
    const instance = getApiInstance();

    const response = await instance.get(
      `${GET_COORDINATES_API}?address=${data.address}&city=${data.city}&state=${data.state}&zipcode=${data.pincode}`
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
  // return data
};

export const validateCoupon = async (data) => {
  const businessId = sessionStorage.getItem("businessId");
  try {
    const instance = getApiInstance();

    const response = await instance.get(`${VALIDATE_CODE_API}${data}`, {
      headers: {
        businessId,
      },
    });
    return response?.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const validateCouponAtag = async (data) => {
  const businessId = sessionStorage.getItem("businessId");
  try {
    const instance = getApiInstance();

    const response = await instance.get(`${VALIDATE_CODE_ATAG}${data}`, {
      headers: {
        businessId,
      },
    });
    return response?.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const firstTimeDiscouunt = async () => {
  const userInfo = getUserDetails();
  const businessId = sessionStorage.getItem("businessId");
  // console.log('USER_DATA', userInfo)
  // return userInfo
  try {
    const instance = getApiInstance();

    const response = await instance.get(`${FIRST_TIME_DISCOUNT_API}`, {
      headers: {
        businessId,
        phone: userInfo?.account?.phonenumber,
      },
    });
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
export const checkBusinessReseller = async () => {
  // const userInfo = getUserDetails()
  const businessId = sessionStorage.getItem("businessId");
  // console.log('USER_DATA', userInfo)
  // return userInfo
  try {
    const instance = getApiInstance();

    const response = await instance.get(CHECK_RESELLER_COUPON_API, {
      headers: {
        businessId,
        // phone: userInfo?.account?.phonenumber,
      },
    });
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
export const veiwActiveBusiness = async () => {
  const businessId = sessionStorage.getItem("businessId");
  try {
    const instance = getApiInstance();

    const response = await instance.get(
      `${VIEW_ACTIVE_BUSINESS_API}/${businessId}`
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
export const checkDeliveryAvailability = async (data) => {
  try {
    const instance = getApiInstance();

    const response = await instance.get(CHECK_DELIVERY_AVAILABILITY_API + data);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
// export const getTelyportPrizing = async (data) => {
//   try {
//     const response = await axios.post(TELYPORT_PRICING_URL, data)
//     return response?.data
//   } catch (error) {
//     console.log(error)
//   }
// }

export const getTelyportPrizing = async (data) => {
  try {
    const response = await axios.post(TELYPORT_PRICING_V2_URL, data, {
      headers: {
        profileId: getProfileId(),
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const checkTelyportRegion = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.post(TELYPORT_REGION_CHECK_URL, data);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
export const checkTelyportServiceTime = async () => {
  try {
    const instance = getApiInstance();
    const response = await instance.post(TELYPORT_SERVICE_TIME_CHECK_URL);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
export const delhiveryServiceability = async (data) => {
  try {
    const instance = getApiInstance();

    const response = await instance.get(DELHIVERY_SERVICEABILITY_API, {
      params: data,
    });
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
export const delhiveryCostCalculation = async (data) => {
  try {
    const instance = getApiInstance();

    const response = await instance.get(DELHIVERY_COST_CALCUTATION_API, {
      params: data,
    });
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
export const delhiveryShipmentPrice = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(DELHIVERY_SHIPMENTPRICE_API, {
      params: data,
    });
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getBusinessContact = async () => {
  const userInfo = getUserDetails();
  const businessId = sessionStorage.getItem("businessId");
  try {
    const instance = getApiInstance();
    const response = await instance.get(BUSINESS_CONTACT_API, {
      params: {
        contactNo: userInfo?.account?.phonenumber,
        businessKey: businessId,
      },
    });
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getWebOrderPaymentId = async (data) => {
  const businessId = sessionStorage.getItem("businessId");
  const profileId = getProfileId();
  try {
    const instance = getApiInstance({
      header: {
        businessId: businessId,
        cartId: data?.cartId,
        profileId: profileId,
      },
    });
    const response = await instance.post(UPDATE_WEB_ORDER_PAYMENT, data);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const orderCODCartAPI = async (data) => {
  const businessId = sessionStorage.getItem("businessId");
  const profileId = getProfileId();
  try {
    const instance = getApiInstance({
      header: {
        businessId: businessId,
        cartId: data?.cartId,
        profileId: profileId,
      },
    });
    const response = await instance.post(ORDER_COD_CART_URL, data);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const sendOrderMessageApi = async (data) => {
  const profileId = getProfileId();

  try {
    const instance = getApiInstance({
      header: { cartId: data?.cartId, profileId: profileId },
    });
    const response = await instance.post(SEND_ORDER_MESSAGE, data?.postData);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const sendOrderNotificationApi = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.post(SEND_ORDER_NOTIFICATION, data);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const addPaymentProcessApi = async (data) => {
  const profileId = getProfileId();
  const businessId = sessionStorage.getItem("businessId");

  try {
    const instance = getApiInstance({
      header: {
        cartId: data?.cartId,
        profileId: profileId,
        businessId: businessId,
      },
    });
    const response = await instance.post(ADD_PAYMENT_PROCESS, data);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const generatePaymentLinkApi = async (data) => {
  const profileId = getProfileId();
  const businessId = sessionStorage.getItem("businessId");

  try {
    const instance = getApiInstance({
      header: {
        profileId: profileId,
        // businessId: businessId,
      },
    });
    const response = await instance.post(GENERATE_PAYMENT_LINK, data);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const upgradeToPayCartApi = async (cartId) => {
  const profileId = getProfileId();
  const businessId = sessionStorage.getItem("businessId");

  try {
    const instance = getApiInstance({
      header: {
        profileId: profileId,
        businessId: businessId,
        cartId: cartId,
        cartType: "businesspaycart",
      },
    });
    const response = await instance.post(UPGRADE_TO_PAYCART);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const checkFinvervUserExists = async () => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(FINVERV_USER_EXISTS_URL);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
export const registeFinvervUser = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.post(REGISTER_FINVERV_USER_URL, data);
    sessionStorage.removeItem("finvervUser");
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
export const getFinvervUrl = async () => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(GET_FINVERV_URL);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
export const getFinvervLines = async () => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(GET_FINVERV_LINE_URL);
    sessionStorage.setItem("finvervStatus", JSON.stringify(response?.data));
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
export const getFinvervApplicationStatus = async () => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(FINVERV_APPLICATION_STATUS_URL);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
export const payUsingCredit = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.post(ORDER_LOAN_URL, data);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
export const updateEmail = async (data) => {
  try {
    const instance = getApiInstance();
    const profile = getProfileId();
    const response = await instance.post(
      "costbouaa/api/account/updateGuestEmail",
      {
        ...data,
        profileId: profile,
      }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const createLoanOrderFinverv = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.post(CREATE_LOAN_ORDER_URL, data);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

// Finbox Api's

export const checkFinboxUserExists = async () => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(CHECK_FINBOX_USER_URL);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const registeFinboxUser = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.post(REGISTER_FINBOX_USER_URL, data);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
export const finboxPartnerPush = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.post(FINBOX_PARTNER_PUSH_URL, data);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
export const getFinboxUrl = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.post(GENERATE_FINBOX_URL, data);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
export const getFinboxEligibility = async () => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(ELIGIBILITY_FINBOX_URL);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
export const getFinboxBanner = async () => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(BANNER_FINBOX_URL);
    sessionStorage.setItem("finboxStatus", JSON.stringify(response?.data));
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
export const getFinboxCreditLine = async () => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(CREDIT_LINE_FINBOX_URL);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
export const finboxLoanOrder = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.post(LOAN_ORDER_FINBOX_URL, data);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
export const getFinboxTransactions = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(TRANSACTION_FINBOX_URL, data);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
export const updateGstIfo = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.post(UPDATE_GST_INFO, data);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getOndcPrizing = async (data) => {
  try {
    const instance = getApiInstance();
    const response = await instance.post(ONDC_PRICING, data, {
      headers: {
        businessID: data?.bid,
      },
    });
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
