import { Box, Button, Container, IconButton, Typography } from "@mui/material";
import {
  backgroundColor02,
  blackColor,
  borderBottomLine2,
  borderCard01,
  greenColor03,
  greyShade48,
  orangeColor03,
  orangeColor01,
  whiteColor,
} from "configs/styles/muiThemes";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Slider from "react-slick/lib/slider";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const SingleBannerBusinessInfo = ({
  businessDetails,
  setShowLogin,
  reviewSummary,
  social,
  total,
}) => {
  const slider = useRef(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const banners = businessDetails?.documentsURL || [];

  const businessName = sessionStorage.getItem("businessName");
  const navigate = useNavigate();

  const { rewardpoints } = useSelector((state) => state.checkout);
  const { isUserLogin } = useSelector((state) => state.auth);
  const { commercialDetails } = useSelector((state) => state.product);

  const [isCopied, setIsCopied] = useState(false);

  const handleCopyLink = (copyLink) => {
    const BUSINESS_LINK =
      commercialDetails?.info?.costboPowered?.domainURL ||
      `https://shop.costbo.com/${businessDetails?.costboWebURL}`;
    navigator.clipboard.writeText(BUSINESS_LINK);

    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    speed: 1000,
  };

  const onSlideNext = () => {
    if (currentSlideIndex < banners?.length - 1) {
      slider.current.slickNext();
    }
  };

  const onSlidePrev = () => {
    if (currentSlideIndex > 0) {
      slider.current.slickPrev();
    }
  };

  return (
    <Container
      sx={{
        maxWidth: "1350px !important",
        p: "20px 15px 0px 15px !important",
      }}
    >
      <Box sx={styles.card}>
        {banners?.length == 1 ? (
          <Box
            component="img"
            src={businessDetails?.documentsURL?.[0]?.docURL}
            sx={styles.bannerImg}
          />
        ) : (
          <Box
            sx={{
              width: "500px",
              borderRadius: "20px",
            }}
            position="relative"
          >
            <Box overflow="hidden">
              <Slider
                ref={slider}
                {...settings}
                beforeChange={(oldIndex, newIndex) => {
                  setCurrentSlideIndex(newIndex);
                }}
              >
                {[...banners, ...banners]?.map((item, index) => (
                  <Box
                    key={`banner_${index}`}
                    component="img"
                    src={item?.docURL}
                    sx={styles.bannerImg}
                  />
                ))}
              </Slider>
            </Box>

            {/* {banners?.length > 1 && (
              <>
                {currentSlideIndex > 0 && (
                  <IconButton
                    sx={{ ...styles.arrowIconBtn, left: "-20px" }}
                    onClick={onSlidePrev}
                  >
                    <KeyboardArrowLeftIcon color="inherit" />
                  </IconButton>
                )}

                {currentSlideIndex < banners?.length - 1 && (
                  <IconButton
                    sx={{ ...styles.arrowIconBtn, right: "-20px" }}
                    onClick={onSlideNext}
                  >
                    <KeyboardArrowRightIcon color="inherit" />
                  </IconButton>
                )}
              </>
            )} */}
          </Box>
        )}

        <Box p="20px 50px 20px 0">
          <Box borderBottom={borderBottomLine2} mb="18px">
            <Typography
              fontSize={14}
              color={blackColor}
              fontWeight={500}
              lineHeight="20px"
              mb="5px !important"
            >
              Welcome to
            </Typography>

            <Typography
              variant="h4"
              fontSize={22}
              fontWeight={600}
              color={blackColor}
              lineHeight="35px"
              mb="10px !important"
            >
              {businessDetails?.businessName}
            </Typography>

            <Typography
              variant="body1"
              fontSize={13}
              fontWeight={400}
              lineHeight="23px"
              color={blackColor}
              mb="5px !important"
            >
              {businessDetails?.mainProducts
                ?.map((e) => e.productName)
                .join(", ")}
            </Typography>

            <Box display="flex" justifyContent="flex-start" mb="20px">
              <Box sx={styles.statsInfo}>
                {reviewSummary?.average > 0 && (
                  <>
                    <Box
                      component="img"
                      src="https://storage.googleapis.com/bodefaults/shopweb/reviews/banner-star.svg"
                      sx={{ width: "15px", mr: "6px", mb: "-3px" }}
                    />
                    {reviewSummary?.average} -
                  </>
                )}{" "}
                {reviewSummary?.totalRated || 0} Reviews -{" "}
                {social?.totalFollower || 0} Followers - {total?.totalView || 0}{" "}
                Views
              </Box>
            </Box>
          </Box>

          <Box sx={styles.actionBtnContainer}>
            <Box>
              <Typography sx={styles.label}>Available Cashback</Typography>

              {!isUserLogin ? (
                <Button
                  variant="contained"
                  disableElevation
                  sx={styles.actionBtn(orangeColor03)}
                  onClick={() => {
                    setShowLogin(true);
                  }}
                >
                  Login to Check
                </Button>
              ) : (
                <>
                  {rewardpoints ? (
                    rewardpoints !== undefined &&
                    rewardpoints?.details?.length > 0 ? (
                      <Typography
                        variant="body2"
                        component="p"
                        sx={styles.rewardPtText}
                      >
                        ₹{" "}
                        {rewardpoints?.details?.[0].availablePointValue?.toFixed(
                          2
                        )}
                      </Typography>
                    ) : (
                      <Typography
                        variant="body2"
                        component="p"
                        sx={styles.rewardPtText}
                      >
                        ₹ 0
                      </Typography>
                    )
                  ) : null}
                </>
              )}
            </Box>

            <Box>
              <Typography sx={styles.label}>Brand Affiliate Program</Typography>

              <Button
                variant="contained"
                disableElevation
                sx={styles.actionBtn(blackColor)}
                onClick={() => {
                  navigate(`/${businessName}/affiliates`);
                }}
              >
                Share & Earn
              </Button>
            </Box>
          </Box>

          <Box
            display="flex"
            gap="7px"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              handleCopyLink();
            }}
          >
            <Typography
              fontSize={13}
              fontWeight={400}
              color={blackColor}
              lineHeight="18px"
            >
              {commercialDetails?.info?.costboPowered?.domainURL ||
                `https://shop.costbo.com/${businessDetails?.costboWebURL}`}
            </Typography>

            {!isCopied ? (
              <Box
                component="img"
                src="https://storage.googleapis.com/bodefaults/shopweb/affiliates/copy.svg"
                sx={{ width: "15px", mt: -0.8 }}
              />
            ) : (
              <Box display="flex" alignItems="center" gap={0.4} mt={-0.3}>
                <CheckCircleOutlineIcon
                  sx={{ fontSize: "18px", color: greenColor03 }}
                />
                <Typography fontSize={12} fontWeight={500} color={greenColor03}>
                  Copied
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default SingleBannerBusinessInfo;

const styles = {
  card: {
    display: "flex",
    alignItems: "center",
    gap: "40px",
    borderRadius: "20px",
    bgcolor: whiteColor,
    overflow: "hidden",
    mt: { xs: "30px", md: "5px" },
  },

  bannerImg: {
    width: "500px",
    //   height: '350px',
    borderRadius: "20px",
    transition: "all 0.3s ease-in-out",
    transform: "scale(1)",
    "&:hover": {
      transform: "scale(0.97)",
    },
  },

  label: {
    fontSize: 13,
    fontWeight: 400,
    color: blackColor,
    lineHeight: "20px",
    mb: "5px !important",
  },

  actionBtnContainer: {
    display: "flex",
    alignItems: "flex-start",
    gap: "30px",
    pb: "20px",
    mb: "20px",
    borderBottom: borderBottomLine2,
    textTransform: "uppercase",
  },

  actionBtn: (color) => ({
    fontSize: 12,
    fontFamily: 600,
    lineHeight: "18px",
    color: whiteColor,
    bgcolor: color,
    p: "8px 20px",
    border: `1px solid ${color}`,
    borderRadius: "8px",
    textTransform: "uppercase",
    "&:hover": {
      color: color,
      bgcolor: whiteColor,
    },
  }),

  rewardPtText: {
    fontSize: 18,
    fontWeight: 600,
    color: orangeColor01,
    my: 0.8,
  },

  statsInfo: {
    bgcolor: backgroundColor02,
    p: "1px 15px",
    fontSize: "12px",
    fontWeight: 500,
    borderRadius: "30px",
    mt: "8px",
  },

  arrowIconBtn: {
    width: "38px",
    height: "38px",
    color: blackColor,
    bgcolor: whiteColor,
    p: 0.7,
    border: borderCard01,
    borderRadius: "7px",
    position: "absolute",
    top: "40%",
    boxShadow: "0 1px 5px rgb(202 202 214 / 55%)",
    textTransform: "uppercase",
    "&:hover": {
      color: blackColor,
      bgcolor: whiteColor,
    },
  },
};
