import { Box, Container, Grid, Skeleton, Stack } from '@mui/material'
import { whiteColor } from 'configs/styles/muiThemes'
import React from 'react'

const HomeContentLoader = () => {
  return (
    <Box>
        <Container sx={{ maxWidth: "1350px !important", px: "15px !important", mt:5}}>
            <Stack sx={{ alignItems:'center', bgcolor: whiteColor, borderRadius:'15px', p:'18px' }}>
                <Skeleton variant="text" width="150px" />

                <Box display='flex' alignItems='center' gap='60px' mt={2.3}>
                    <Skeleton variant="rounded" width="65px" height="65px" sx={{borderRadius:'50%'}} />
                    <Skeleton variant="rounded" width="65px" height="65px" sx={{borderRadius:'50%'}} />
                    <Skeleton variant="rounded" width="65px" height="65px" sx={{borderRadius:'50%'}} />
                    <Skeleton variant="rounded" width="65px" height="65px" sx={{borderRadius:'50%'}} />
                    <Skeleton variant="rounded" width="65px" height="65px" sx={{borderRadius:'50%'}} />
                    <Skeleton variant="rounded" width="65px" height="65px" sx={{borderRadius:'50%'}} />
                </Box>

                <Skeleton variant="rounded" width="250px" height='14px' sx={{mt:'22px !important'}} />
            </Stack>


            <TopProductsSkeleton />

            <Box m='24px 0 18px'>
                <Skeleton variant="rounded" width="200px" height="16px" />
                <Skeleton variant="rounded" width="auto" height="53px" sx={{borderRadius:'10px', mt:"18px !important"}} />
            </Box>

            <TopProductsSkeleton />
      </Container>
    </Box>
  )
}

const TopProductsSkeleton=()=>(
    <Box display='flex' alignItems='flex-start' height='275px' bgcolor={whiteColor} borderRadius='15px' overflow='hidden' mt={3}>
        <Skeleton variant="rounded" width="226px" height="275px" />

        <Box flex={1} p="15px">
            <Skeleton variant="rounded" width="150px" height="15px"/>

            <Box mt={3}>
                <Grid container spacing={2}>
                    <Grid item md={2.4}>
                        <ProductCardOne />
                    </Grid>
                    <Grid item md={2.4}>
                        <ProductCardOne />
                    </Grid>
                    <Grid item md={2.4}>
                        <ProductCardOne />
                    </Grid>
                    <Grid item md={2.4}>
                        <ProductCardOne />
                    </Grid>
                    <Grid item md={2.4}>
                        <ProductCardOne />
                    </Grid>
                </Grid>
            </Box>
        </Box> 
    </Box>
)

const ProductCardOne = ()=>(
    <Box>
        <Skeleton variant="rounded" width="auto" height="100px" />

        <Skeleton variant="rounded" width="auto" height="12px" sx={{m:'10px 0 8px !important'}} />
        <Skeleton variant="rounded" width="130px" height="12px" sx={{m:'0px 0 12px !important'}} />
        <Skeleton variant="rounded" width="50px" height="16px" sx={{m:'0px 0 8px !important'}} />
    </Box>
)

export default HomeContentLoader