import { Box, Container, Skeleton } from "@mui/material";
import React from "react";

function BreadcrumbLoader() {
  return (
    <Container
      sx={{
        maxWidth: "1375px !important",
        mt: 2,
      }}
    >
      <Skeleton
        variant="rectangle"
        width="100%"
        height={51.6}
        sx={{ borderRadius: "8px" }}
      />
    </Container>
  );
}

export default BreadcrumbLoader;
