import React from "react";
import { Box } from "@mui/material";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { backgroundColor05, orangeColor01 } from "configs/styles/muiThemes";

const SingleStarRatingDisplay = ({ rating }) => {

    const getFillCount = (ratingValue) =>{
        switch (ratingValue) {
            case 1:
                return (1.5 / 5) * 100
            case 2:
                return (2.5 / 5) * 100
        
            default:
                return (ratingValue / 5) * 100
        }
    }

  return (
    <Box position="relative" display="inline-block" width={18.5} height={18.5}>
      <StarRoundedIcon
        sx={{
          width: "18.5px",
          height: "18.5px",
          color: 'rgba(0,0,0,0.3)',
          position: "absolute"
        }}
      />

      <Box
        width={`${getFillCount(rating)}%`}
        overflow="hidden"
        position="absolute"
        top={0}
        left={0}
      >
        <StarRoundedIcon sx={{
          width: "18.5px",
          height: "18.5px",
          color: orangeColor01,
        }} />
      </Box>
    </Box>
  );
};

export default SingleStarRatingDisplay;
