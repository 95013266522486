import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  blackColor,
  borderCard01,
  borderLineColor,
  catalogSubHeaderColor,
  filterBgColor,
  headingColor,
  iconInactiveColor,
  navLinkColor,
  orangeColor01,
  whiteColor,
} from "../../configs/styles/muiThemes";

import Slider from "react-slick/lib/slider";
import { getOutOfStock } from "utils/productHelper";
import ScrollIndicator from "components/ScrollIndicator";
import MobileProductCard from "./MobileUICards/MobileProductCard";
import { scrollBox } from "utils/styleUtility";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const ProductCard = React.lazy(() => import("pages/CatalogMui/ProductCard"));

function HomeProductGroup({
  title,
  products,
  type,
  onlyProduct,
  buyProduct,
  item,
  count,
  key,
  mod,
}) {
  const slider = useRef(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const businessName = sessionStorage.getItem("businessName");
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [isScrollEnd, setIsScrollEnd] = useState(false);
  const containerRef = useRef(null);

  let navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: onlyProduct ? 7 : 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: onlyProduct ? 5 : 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
    ],
  };

  const onSlideNext = () => {
    if (currentSlideIndex < products?.length - 7) {
      slider.current.slickNext();
    }
  };

  const onSlidePrev = () => {
    if (currentSlideIndex > 0) {
      slider.current.slickPrev();
    }
  };

  useEffect(() => {
    const container = containerRef.current;

    const handleScroll = () => {
      // Check if the horizontal scroll has reached the end
      const isEnd =
        container?.scrollLeft + container?.clientWidth + 200 >=
        container?.scrollWidth;

      setIsScrollEnd(isEnd);
    };

    container?.addEventListener("scroll", handleScroll);

    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      // mt={{ md: 3.5, xs: 0.5 }}
      mt={{ md: 3.5, xs: mod % 2 === 0 ? 0.5 : 0 }}
      // mb={buyProduct ? "8px" : "8px"}
      position="relative"
    >
      {/* Header */}
      <Box sx={{ ml: { xs: 2, md: 0 } }}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <Typography
              variant="h2"
              fontSize={{ md: "17px", xs: "15px" }}
              color={headingColor}
              display="inline"
              mr={1}
            >
              {title}
            </Typography>
            {onlyProduct ? (
              <>
                {/* <Box
                component='span'
                color={catalogSubHeaderColor}
                fontSize='14px'
                fontWeight={500}
                mx={1}
                textAlign='center'
              >
                ·
              </Box> */}

                <Typography
                  variant="body2"
                  color={catalogSubHeaderColor}
                  fontSize="13px"
                  fontWeight={400}
                  display="inline"
                >
                  . {count} items
                </Typography>
              </>
            ) : null}
          </Grid>
          {products?.length > 7 ? (
            <Grid item>
              <Grid container spacing={1.5}>
                {onlyProduct ? (
                  <Grid item>
                    <Button
                      // component={Link}
                      // to={{
                      //   pathname: `/${businessName}/Catalog`,
                      //   state: { categorySideBar: item },
                      // }}
                      onClick={() => {
                        navigate(`/${businessName}/catalog`, {
                          state: { categorySideBar: item },
                        });
                      }}
                      sx={{
                        textTransform: "uppercase",
                        color: orangeColor01,
                        textDecorationLine: "underline",
                        display: { xs: "none", md: "block" },
                      }}
                    >
                      View More
                    </Button>
                  </Grid>
                ) : null}

                <Grid item display={{ xs: "none", md: "block" }}>
                  <Box
                    width="40px"
                    height="40px"
                    backgroundColor={
                      currentSlideIndex === 0 ? filterBgColor : whiteColor
                    }
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius={2}
                    sx={{ cursor: "pointer" }}
                    border={"1px solid " + borderLineColor}
                    onClick={() => onSlidePrev()}
                  >
                    <ChevronLeft
                      fontSize="medium"
                      sx={{
                        color:
                          currentSlideIndex === 0
                            ? iconInactiveColor
                            : navLinkColor,
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item display={{ xs: "none", md: "block" }}>
                  <Box
                    width="40px"
                    height="40px"
                    backgroundColor={
                      currentSlideIndex === products?.length - 7
                        ? filterBgColor
                        : whiteColor
                    }
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius={2}
                    border={"1px solid " + borderLineColor}
                    sx={{ cursor: "pointer" }}
                    onClick={() => onSlideNext()}
                  >
                    <ChevronRight
                      fontSize="medium"
                      sx={{
                        color:
                          currentSlideIndex === products?.length - 7
                            ? iconInactiveColor
                            : navLinkColor,
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Box>

      {/* Products List */}
      <Grid container mt={{ xs: 0, md: 0 }} spacing={1.5}>
        {matches ? (
          onlyProduct ? null : (
            <Grid item xs={0} md={2}>
              <SectionInfoCard type={type} />
            </Grid>
          )
        ) : null}

        <Grid item xs={12} md={onlyProduct ? 12 : 10}>
          {products?.length > 7 ? (
            <>
              <Box display={{ xs: "none", md: "block" }}>
                <Slider
                  ref={slider}
                  {...settings}
                  beforeChange={(oldIndex, newIndex) => {
                    setCurrentSlideIndex(newIndex);
                  }}
                >
                  {products.map((item, index) => {
                    if (item?.variantInfo?.masterVariant) return null;

                    return (
                      <Box py={0} ml={1} key={`${title}_${index}`}>
                        {buyProduct ? (
                          matches ? (
                            <ProductCard
                              img={item?.item?.productPhoto?.[0]?.docURL}
                              price={item?.item.productSalePrice}
                              oldPrice={item?.item.productOriginalPrice}
                              description={item?.item.productName}
                              discount={item?.item.percentageOff}
                              style={{ my: 1.5 }}
                              // isOutOfStock={getOutOfStock(item?.item)}
                              // isComingSoon={item?.item.commerceEnabled === 'NO'}
                              minQty={item?.item.minOrderQuantity}
                              maxQty={item?.item.maxOrderQuantity}
                              disableAddtoCart
                              product={item?.item}
                            />
                          ) : (
                            <Box
                              sx={{
                                ...scrollBox,
                                // overflowX: 'auto',
                                display: "flex",
                                gap: "15px",
                                pb: "12px",
                                mb: "18px",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  width: "30%",
                                  gap: "10px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {products.map((item, index) => {
                                  if (item?.variantInfo?.masterVariant)
                                    return null;

                                  return (
                                    <Box
                                      key={index}
                                      sx={{
                                        //flex: '0 0 auto',
                                        // width: '100vw',
                                        // backgroundColor: '#F3F3F8',
                                        borderRadius: "6px",
                                      }}
                                    >
                                      <MobileProductCard
                                        img={item.productPhoto?.[0]?.docURL}
                                        price={item.productSalePrice}
                                        oldPrice={item?.productOriginalPrice}
                                        description={item?.productName}
                                        productDescription={
                                          item?.productDescription
                                        }
                                        discount={products?.[0]?.percentageOff}
                                        style={{ my: 1.5 }}
                                        isOutOfStock={getOutOfStock(item)}
                                        isComingSoon={
                                          item?.commerceEnabled === "NO"
                                        }
                                        minQty={item?.minOrderQuantity}
                                        maxQty={item?.maxOrderQuantity}
                                        disableAddtoCart
                                        product={products}
                                        productid={item?.id}
                                        direction={mod % 2 === 0 ? true : false}
                                        mapLength={products?.length}
                                      />
                                    </Box>
                                  );
                                })}
                              </Box>
                            </Box>
                          )
                        ) : matches ? (
                          <ProductCard
                            img={item.productPhoto?.[0]?.docURL}
                            price={item.productSalePrice}
                            oldPrice={item.productOriginalPrice}
                            description={item.productName}
                            discount={item.percentageOff}
                            style={{ my: 1.5 }}
                            // isOutOfStock={getOutOfStock(item)}
                            // isComingSoon={item.commerceEnabled === 'NO'}
                            minQty={item.minOrderQuantity}
                            maxQty={item.maxOrderQuantity}
                            disableAddtoCart
                            product={item}
                          />
                        ) : (
                          <>
                            <MobileProductCard
                              productid={item?.id}
                              img={item.productPhoto?.[0]?.docURL}
                              price={item.productSalePrice}
                              oldPrice={item?.productOriginalPrice}
                              description={item?.productName}
                              productDescription={item?.productDescription}
                              discount={products?.[0]?.percentageOff}
                              style={{ my: 1.5 }}
                              isOutOfStock={getOutOfStock(item)}
                              isComingSoon={item?.commerceEnabled === "NO"}
                              minQty={item?.minOrderQuantity}
                              maxQty={item?.maxOrderQuantity}
                              disableAddtoCart
                              product={products}
                              direction={mod % 2 === 0 ? true : false}
                              mapLength={products?.length}
                            />
                          </>
                        )}
                      </Box>
                    );
                  })}
                </Slider>
              </Box>

              {/* Displaying Products in Mobile Web */}
              <Box display={{ xs: "block", md: "none" }}>
                <Box
                  sx={{ ...styles.scrollBox, alignItems: "flex-start" }}
                  ref={containerRef}
                >
                  {products.map((item, index) => {
                    if (item?.variantInfo?.masterVariant) return null;

                    return (
                      <Box
                        py={{md:0,xs:1}}
                        mr={1}
                        ml={index == 0 ? 2 : 1}
                        key={`${title}_${index}`}
                      >
                        {buyProduct ? (
                          matches ? (
                            <ProductCard
                              img={item?.item?.productPhoto?.[0]?.docURL}
                              price={item?.item.productSalePrice}
                              oldPrice={item?.item.productOriginalPrice}
                              description={item?.item.productName}
                              discount={item?.item.percentageOff}
                              style={{ my: 1.5 }}
                              // isOutOfStock={getOutOfStock(item?.item)}
                              // isComingSoon={item?.item.commerceEnabled === 'NO'}
                              minQty={item?.item.minOrderQuantity}
                              maxQty={item?.item.maxOrderQuantity}
                              disableAddtoCart
                              product={item?.item}
                            />
                          ) : (
                            <MobileProductCard
                              productid={item?.id}
                              img={item.productPhoto?.[0]?.docURL}
                              price={item.productSalePrice}
                              oldPrice={item?.productOriginalPrice}
                              description={item?.productName}
                              productDescription={item?.productDescription}
                              discount={products?.[0]?.percentageOff}
                              style={{ my: 1.5 }}
                              isOutOfStock={getOutOfStock(item)}
                              isComingSoon={item?.commerceEnabled === "NO"}
                              minQty={item?.minOrderQuantity}
                              maxQty={item?.maxOrderQuantity}
                              disableAddtoCart
                              product={products}
                              direction={mod % 2 === 0 ? true : false}
                              mapLength={products?.length}
                            />
                          )
                        ) : matches ? (
                          <ProductCard
                            img={item.productPhoto?.[0]?.docURL}
                            price={item.productSalePrice}
                            oldPrice={item.productOriginalPrice}
                            description={item.productName}
                            discount={item.percentageOff}
                            style={{ my: 1.5 }}
                            // isOutOfStock={getOutOfStock(item)}
                            // isComingSoon={item.commerceEnabled === 'NO'}
                            minQty={item.minOrderQuantity}
                            maxQty={item.maxOrderQuantity}
                            disableAddtoCart
                            product={item}
                          />
                        ) : (
                          <MobileProductCard
                            productid={item?.id}
                            img={item.productPhoto?.[0]?.docURL}
                            price={item.productSalePrice}
                            oldPrice={item?.productOriginalPrice}
                            description={item?.productName}
                            productDescription={item?.productDescription}
                            discount={products?.[0]?.percentageOff}
                            style={{ my: 1.5 }}
                            isOutOfStock={getOutOfStock(item)}
                            isComingSoon={item?.commerceEnabled === "NO"}
                            minQty={item?.minOrderQuantity}
                            maxQty={item?.maxOrderQuantity}
                            disableAddtoCart
                            product={products}
                            direction={mod % 2 === 0 ? true : false}
                            mapLength={products?.length}
                          />
                        )}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </>
          ) : matches ? (
            <Box display="flex" flexDirection="row" pl={1}>
              {products.map((item, index) => {
                if (item?.variantInfo?.masterVariant) return null;

                return (
                  <Box py={0} mr={2} key={`${title}_${index}`}>
                    {buyProduct ? (
                      matches ? (
                        <ProductCard
                          img={item?.item?.productPhoto?.[0]?.docURL}
                          price={item?.item.productSalePrice}
                          oldPrice={item?.item.productOriginalPrice}
                          description={item?.item.productName}
                          discount={item?.item.percentageOff}
                          style={{ my: 1.5 }}
                          isOutOfStock={getOutOfStock(item?.item)}
                          isComingSoon={item?.item.commerceEnabled === "NO"}
                          minQty={item?.item.minOrderQuantity}
                          maxQty={item?.item.maxOrderQuantity}
                          disableAddtoCart
                          product={item?.item}
                        />
                      ) : (
                        <Box
                          sx={{
                            ...scrollBox,
                            display: "flex",
                            gap: "15px",
                            pb: "18px",
                            mb: "18px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              width: "30%",
                              gap: "10px",
                              whiteSpace: "nowrap",
                              backgroundColor: "red",
                            }}
                          >
                            {products.map((item, index) => (
                              <Box
                                key={index}
                                sx={{
                                  //flex: '0 0 auto',
                                  width: "100vw",
                                  backgroundColor: "#F3F3F8",
                                  borderRadius: "6px",
                                }}
                              >
                                {console.log("item5", item)}
                                <MobileProductCard
                                  productid={item?.id}
                                  img={item.productPhoto?.[0]?.docURL}
                                  price={item.productSalePrice}
                                  oldPrice={item?.productOriginalPrice}
                                  description={item?.productName}
                                  discount={products?.[0]?.percentageOff}
                                  style={{ my: 1.5 }}
                                  isOutOfStock={getOutOfStock(item)}
                                  isComingSoon={item?.commerceEnabled === "NO"}
                                  minQty={item?.minOrderQuantity}
                                  maxQty={item?.maxOrderQuantity}
                                  disableAddtoCart
                                  product={products}
                                />
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      )
                    ) : matches ? (
                      <ProductCard
                        img={item.productPhoto?.[0]?.docURL}
                        price={item.productSalePrice}
                        oldPrice={item.productOriginalPrice}
                        description={item.productName}
                        discount={item.percentageOff}
                        style={{ my: 1.5 }}
                        isOutOfStock={getOutOfStock(item)}
                        isComingSoon={item.commerceEnabled === "NO"}
                        minQty={item.minOrderQuantity}
                        maxQty={item.maxOrderQuantity}
                        disableAddtoCart
                        product={item}
                      />
                    ) : (
                      <Box
                        sx={{
                          ...scrollBox,
                          display: "flex",
                          gap: "15px",
                          pb: "18px",
                          mb: "18px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "30%",
                            gap: "10px",
                            whiteSpace: "nowrap",
                            backgroundColor: "red",
                          }}
                        >
                          {products.map((item, index) => (
                            <Box
                              key={index}
                              sx={{
                                //flex: '0 0 auto',
                                width: "100vw",
                                backgroundColor: "#F3F3F8",
                                borderRadius: "6px",
                              }}
                            >
                              {console.log("item6", item)}
                              <MobileProductCard
                                productid={item?.id}
                                img={item.productPhoto?.[0]?.docURL}
                                price={item.productSalePrice}
                                oldPrice={item?.productOriginalPrice}
                                description={item?.productName}
                                discount={products?.[0]?.percentageOff}
                                style={{ my: 1.5 }}
                                isOutOfStock={getOutOfStock(item)}
                                isComingSoon={item?.commerceEnabled === "NO"}
                                minQty={item?.minOrderQuantity}
                                maxQty={item?.maxOrderQuantity}
                                disableAddtoCart
                                product={products}
                              />
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    )}
                  </Box>
                );
              })}

              {products?.length > 10 ? (
                <Box sx={styles.productCard}>
                  <Button
                    // component={Link}
                    // to={{
                    //   pathname: `/${businessName}/Catalog`,
                    //   state: { categorySideBar: item },
                    // }}
                    onClick={() => {
                      navigate(`/${businessName}/catalog`, {
                        state: { categorySideBar: item },
                      });
                    }}
                    sx={{
                      width: "143px",
                      textTransform: "uppercase",
                      color: orangeColor01,
                      textDecorationLine: "underline",
                    }}
                  >
                    View More
                  </Button>
                </Box>
              ) : null}
            </Box>
          ) : (
            <>
              <Box sx={styles.scrollBox} ref={containerRef}>
                {products.map((item, index) => {
                  if (item?.variantInfo?.masterVariant) return null;

                  return (
                    <Box
                      py={0}
                      ml={index == 0 ? 2 : 1}
                      key={`${title}_${index}`}
                    >
                      {buyProduct ? (
                        matches ? (
                          <ProductCard
                            img={item?.item?.productPhoto?.[0]?.docURL}
                            price={item?.item.productSalePrice}
                            oldPrice={item?.item.productOriginalPrice}
                            description={item?.item.productName}
                            discount={item?.item.percentageOff}
                            style={{ my: 1.5 }}
                            isOutOfStock={getOutOfStock(item?.item)}
                            isComingSoon={item?.item.commerceEnabled === "NO"}
                            minQty={item?.item.minOrderQuantity}
                            maxQty={item?.item.maxOrderQuantity}
                            disableAddtoCart
                            product={item?.item}
                          />
                        ) : (
                          <Box
                            sx={{
                              ...scrollBox,
                              display: "flex",
                              gap: "15px",
                              pb: { md: "18px", xs: 0 },
                              mb: { md: "18px", xs: "none" },
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                width: "30%",
                                gap: "10px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {products.map((item, index) => (
                                <Box
                                  key={index}
                                  sx={{
                                    //flex: '0 0 auto',
                                    width: "100vw",
                                    backgroundColor: "#F3F3F8",
                                    borderRadius: "6px",
                                  }}
                                >
                                  {console.log("item7", item)}
                                  <MobileProductCard
                                    productid={item?.id}
                                    img={item.productPhoto?.[0]?.docURL}
                                    price={item.productSalePrice}
                                    oldPrice={item?.productOriginalPrice}
                                    description={item?.productName}
                                    productDescription={
                                      item?.productDescription
                                    }
                                    discount={products?.[0]?.percentageOff}
                                    style={{ my: 1.5 }}
                                    isOutOfStock={getOutOfStock(item)}
                                    isComingSoon={
                                      item?.commerceEnabled === "NO"
                                    }
                                    minQty={item?.minOrderQuantity}
                                    maxQty={item?.maxOrderQuantity}
                                    disableAddtoCart
                                    product={products}
                                  />
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        )
                      ) : matches ? (
                        <ProductCard
                          img={item.productPhoto?.[0]?.docURL}
                          price={item.productSalePrice}
                          oldPrice={item.productOriginalPrice}
                          description={item.productName}
                          discount={item.percentageOff}
                          style={{ my: 1.5 }}
                          isOutOfStock={getOutOfStock(item)}
                          isComingSoon={item.commerceEnabled === "NO"}
                          minQty={item.minOrderQuantity}
                          maxQty={item.maxOrderQuantity}
                          disableAddtoCart
                          product={item}
                        />
                      ) : (
                        <MobileProductCard
                          productid={item?.id}
                          img={item.productPhoto?.[0]?.docURL}
                          price={item.productSalePrice}
                          oldPrice={item?.productOriginalPrice}
                          description={item?.productName}
                          productDescription={item?.productDescription}
                          discount={products?.[0]?.percentageOff}
                          style={{ my: 1.5 }}
                          isOutOfStock={getOutOfStock(item)}
                          isComingSoon={item?.commerceEnabled === "NO"}
                          minQty={item?.minOrderQuantity}
                          maxQty={item?.maxOrderQuantity}
                          disableAddtoCart
                          product={products}
                          // direction={index/2 ===0 ? true :false}
                          direction={mod % 2 === 0 ? true : false}
                        />
                      )}
                    </Box>
                  );
                })}

                {products?.length > 10 ? (
                  <Box sx={styles.productCard}>
                    <Button
                      onClick={() => {
                        navigate(`/${businessName}/catalog`, {
                          state: { categorySideBar: item },
                        });
                      }}
                      sx={{
                        width: "143px",
                        textTransform: "none",
                        color: orangeColor01,
                        textDecorationLine: "underline",
                        textTransform: "uppercase",
                      }}
                    >
                      View More
                    </Button>
                  </Box>
                ) : null}
              </Box>
            </>
          )}
        </Grid>
      </Grid>

      {/* Scroll Indicator Icon */}
      {!isScrollEnd && products?.length > 2 && matches && (
        <Box
          sx={{
            display: { xs: "block", md: "none" },
            position: "absolute",
            right: "0px",
            top: "50%",
          }}
        >
          <ScrollIndicator />
        </Box>
      )}
      {/* {!isScrollEnd && products?.length > 2 && !matches && (
        <Box
          sx={{
            position: 'absolute',
            right: '-8px',
            zIndex: '9',
            top: '60%',
            margin: 'auto 0',
            bottom: '0px',
          }}
        >
          <IconButton
            sx={{ ...styles.arrowIconBtn, right: '0px' }}
            onClick={onSlideNext}
          >
            <KeyboardDoubleArrowRightIcon
              color='inherit'
              sx={{ fontSize: '20px' }}
            />
          </IconButton>
        </Box>
      )} */}
    </Box>
  );
}

export default HomeProductGroup;

const styles = {
  productCard: {
    width: "150px !important",
    height: { md: "232px", xs: "226px" },
    display: { md: "none !important", xs: "flex !important" },
    alignItems: "center",
    justifyContent: "center",
    borderRadius: { md: 1.5, xs: "0" },
    backgroundColor: { md: whiteColor, xs: whiteColor },
    border: "1px solid #f2f2f2",
    px: 1.2,
    pt: 1.2,
    pb: 0.5,
    mt: "5px",
    ml: "12px",
    boxShadow: "0px 1px 3px rgb(0 0 0 / 13%)",
    "&:hover": {
      boxShadow: "0 8px 15px 0 rgba(0, 0, 0, 0.1)",
    },
  },

  scrollBox: {
    display: "flex",
    alignItems: "center",

    overflowX: "scroll",
    overscrollBehaviorInline: "contain",

    "&::-webkit-scrollbar": {
      display: "none",
    },

    "-webkit-overflow-scrolling": "touch",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },

  arrowIconBtn: {
    width: "30px",
    height: "38px",
    color: blackColor,
    bgcolor: whiteColor,
    p: 0.7,
    border: borderCard01,
    borderRadius: "7px",
    position: "absolute",
    top: "-30%",
    boxShadow: "0 1px 5px rgb(202 202 214 / 55%)",
    "&:hover": {
      color: blackColor,
      bgcolor: whiteColor,
    },
  },
};

const getImageColor = (type) => {
  switch (type) {
    case "buy-again":
      return {
        img: "https://storage.googleapis.com/bodefaults/shopweb/banner_buy_again.png",
        bgColor: "#dae0ff",
      };
    case "trending":
      return {
        img: "https://storage.googleapis.com/bodefaults/shopweb/banner_trending_product.png",
        bgColor: "#ffe5f5",
      };
    case "top-deals":
      return {
        img: "https://storage.googleapis.com/bodefaults/shopweb/banner_top_deals.png",
        bgColor: "#f5e7cf",
      };
    case "bestseller":
      return {
        img: "https://storage.googleapis.com/bodefaults/shopweb/banner_best_sellers.png",
        bgColor: "#fbe6ff",
      };
    case "try-before":
      return {
        img: "https://storage.googleapis.com/bodefaults/shopweb/catalog-banner-5.png",
        bgColor: "#ffd9e2",
      };
    case "new-store":
      return {
        img: "https://storage.googleapis.com/bodefaults/shopweb/banner_new_in_our_store.png",
        bgColor: "#dde8ff",
      };
    default:
      return {
        img: "https://storage.googleapis.com/bodefaults/shopweb/banner_top_deals.png",
        bgColor: "#e7f4db",
      };
  }
};

const SectionInfoCard = ({ type }) => {
  const data = getImageColor(type);
  let businessFullName = sessionStorage.getItem("businessFullName");

  return (
    <Box
      height="210px"
      //   backgroundColor='#f5e7cf'
      backgroundColor={data.bgColor}
      p={1.2}
      borderRadius={1.5}
      maxWidth="200px "
      //   display='flex'
      display={{ xs: "none", md: "flex" }}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      py={1.5}
    >
      <Box
        component="img"
        src={{
          ...data.img,
          transition: "all 0.3s ease-in-out",
          transform: "scale(1)",
          "&:hover": {
            transform: "scale(0.97)",
          },
        }}
        alt={businessFullName + " CostBo"}
        maxWidth="180px "
        height="auto"
      />
    </Box>
  );
};
