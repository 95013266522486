import { Box, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { InfoCard_DeliveryChargeInfo, InfoCard_DiscountInfo } from "configs/Constants";
import React from "react";
import {
  backgroundColor,
  blackColor,
  borderBackground,
  borderLineColor,
  borderLineSearchColor,
  couponColor,
  whiteColor,
} from "../configs/styles/muiThemes";

function InfoCard({ freeAlert }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#fff",
          border: "1px solid #e2e5ec",
          borderRadius: "10px",
          padding: "10px 12px",
          marginBottom: { xs: "14px", md: 2 },
        }}
      >
        <Box
          component="img"
          maxHeight={{ xs: "35x", md: "35px" }}
          src="https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/avaiable-discount.svg"
          alt="CostBoDiscounts"
        />
        <Typography
          sx={{
            color: blackColor,
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "18px",
            position: "relative",
            paddingLeft: "10px",
            display: "block",
            borderLeft: `1px solid ${"#e2e5ec"}`,
            ml: 1,
          }}
        >
          {InfoCard_DiscountInfo}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#fff",
          border: "1px solid #e2e5ec",
          borderRadius: "10px",
          padding: "10px 12px",
          marginBottom: { md: 2, xs:freeAlert ? 0 : 18 },
        }}
      >
        <Box
          component="img"
          maxHeight={{ xs: "35x", md: "35px" }}
          src="https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/truck-delivery.svg"
          alt="CostBoDelivery"
        />
        <Typography
          sx={{
            color: blackColor,
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "18px",
            position: "relative",
            paddingLeft: "10px",
            display: "block",
            borderLeft: `1px solid ${"#e2e5ec"}`,
            ml: 1,
          }}
        >
          {InfoCard_DeliveryChargeInfo}
        </Typography>
      </Box>
    </>
  );
}

export default InfoCard;
