import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { blackColor, borderCard, whiteColor } from "configs/styles/muiThemes";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const FeaturedCategories = ({ businessName, categories, businessDetails }) => {
  const navigate = useNavigate();
  const [mainProductList, setMainProductList] = useState([]);

  useEffect(() => {
    if (categories?.length <= 3) {
      const products = [];

      categories.forEach((item) => {
        if (item?.products?.length > 0) {
          products.push(...item.products);
        }
      });

      const selectedItems = products
        .sort(() => 0.5 - Math.random()) // Shuffle
        .slice(0, 7); // Select 7 items

      setMainProductList(selectedItems);
    }
  }, [categories]);

  return (
    <Box sx={styles.card}>
      <Typography
        variant="h2"
        color={blackColor}
        fontSize={16}
        lineHeight="25px"
        textAlign="center"
      >
        {categories?.length > 3 ? "Featured Categories" : "Featured Products"}
      </Typography>

      <Box mt={1.9}>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="center"
          gap={2}
          flexWrap="wrap"
        >
          {categories?.length > 3 ? (
            <>
              {categories?.slice(0, 7)?.map((item, index) => (
                <CategoryItem
                  key={`category_${index}`}
                  imgUrl={item?.products?.[0]?.productPhoto?.[0]?.docURL || ""}
                  label={item.subCatValue}
                  title={item?.subCatValue}
                  onClick={() => {
                    navigate(`/${businessName}/catalog`, {
                      state: { categorySideBar: item },
                    });
                  }}
                />
              ))}
              {/* <Typography
                fontSize={15}
                fontWeight={500}
                color={blackColor}
                lineHeight="20px"
                textAlign="center"
              >
                {businessDetails?.mainProducts
                  ?.map((e) => e.productName)
                  .join(", ")}
              </Typography> */}
            </>
          ) : (
            <>
              <Box display={"flex"} flexDirection={"column"}>
                <Box display={"flex"} justifyContent='center'>
                  {mainProductList?.slice(0, 7)?.map((item, index) => (
                    <Tooltip
                      title={
                        <Typography
                          fontSize={11}
                          lineHeight={1.2}
                          sx={{ p: "2px 0" }}
                        >
                          {item?.productName}
                        </Typography>
                      }
                      key={`category_${index}`}
                      placement="top"
                    >
                      <Box>
                        <CategoryItem
                          imgUrl={item?.productPhoto?.[0]?.docURL || ""}
                          hideLabel
                          onClick={() => {
                            navigate(`/${businessName}/catalog`);
                          }}
                        />
                      </Box>
                    </Tooltip>
                  ))}
                </Box>
                <Typography
                  fontSize={13.5}
                  fontWeight={400}
                  color={blackColor}
                  lineHeight="14px"
                  textAlign="center"
                  mt={0.5}
                >
                  {businessDetails?.mainProducts
                    ?.map((e) => e.productName)
                    .join(", ")}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default FeaturedCategories;

const CategoryItem = ({ imgUrl, label, hideLabel, onClick, title }) => {
  return (
    <Stack
      width="120px"
      alignItems="center"
      sx={{ cursor: "pointer", overflow: "hidden" }}
      title={title}
      onClick={onClick}
      display={"flex"}
      flexDirection={"column"}
    >
      <Box sx={styles.imgContainer}>
        <Box
          component="img"
          src={imgUrl}
          alt=""
          sx={{
            width: "56px",
            height: "45px",
            objectFit: "contain",
            overflow: "hidden",
            padding: "5px",
            transition: "all 0.3s ease-in-out",
            transform: "scale(1)",
            "&:hover": {
              transform: "scale(0.97)",
            },
          }}
        />
      </Box>

      {!hideLabel && (
        <Typography
          fontSize={13.5}
          fontWeight={500}
          color={blackColor}
          lineHeight="20px"
          textAlign="center"
        >
          {/* {label} */}
          {label.length > 15 ? `${label.slice(0, 13)}...` : label}
        </Typography>
      )}
    </Stack>
  );
};

const styles = {
  card: {
    bgcolor: whiteColor,
    p: "18px",
    m: "25px 0 30px 0 !important",
    borderRadius: "15px",
  },

  imgContainer: {
    width: "73px",
    height: "73px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    mb: "12px",
    border: borderCard,
    overflow: "hidden",
  },
};
