import { Box, Grid, Typography } from '@mui/material'
import {
  borderLineColor,
  filterBgColor,
  iconInactiveColor,
  navLinkColor,
  trySampleBGColor,
  whiteColor,
} from 'configs/styles/muiThemes'
import ProductCard from 'pages/CatalogMui/ProductCard'
import { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick/lib/slider'
import { getOutOfStock } from 'utils/productHelper'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'

function TryBeforeBuyCard({ products }) {
  const slider = useRef(null)
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  let businessFullName = sessionStorage.getItem('businessFullName')

  const [productList, setProductList] = useState([])

  useEffect(() => {
    products?.forEach((item) => {
      const isOutOfStock = getOutOfStock(item)
      if (!isOutOfStock) {
        setProductList([...productList, item])
      }
    })
  }, [products])

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
    ],
  }

  const onSlideNext = () => {
    if (currentSlideIndex < products?.length - 6) {
      slider.current.slickNext()
    }
  }

  const onSlidePrev = () => {
    if (currentSlideIndex > 0) {
      slider.current.slickPrev()
    }
  }

  return productList.length > 0 ? (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <Box
        sx={{
          background: `url("https://storage.googleapis.com/bodefaults/shopweb/samples-bg.jpg")`,
          backgroundSize: '100% 100%',
          py: 2.5,
          borderRadius: 4,
          display: { md: 'block', xs: 'none' },
        }}
      >
        <Grid container display='flex' alignItems='center'>
          <Grid item md={5}>
            <Grid container spacing={2}>
              <Grid item md={5}>
                <Box
                  component='img'
                  src='https://storage.googleapis.com/bodefaults/shopweb/samples-poster.png'
                  alt={businessFullName + ' CostBo'}
                  width='100%'
                  height='auto'
                />
              </Grid>
              <Grid item md={7} sx={{ display: 'flex', alignItems: 'center' }}>
                <Box p={3}>
                  <Typography
                    color={whiteColor}
                    fontSize={17}
                    fontWeight={500}
                    lineHeight={1.5}
                    textAlign='center'
                  >
                    Don’t just take our word
                  </Typography>
                  <Typography
                    color={whiteColor}
                    fontSize={22}
                    fontWeight={600}
                    lineHeight={1.5}
                    textAlign='center'
                    mt={1.2}
                  >
                    Try before you buy!
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={7} pr={4}>
            <Box sx={{ position: 'relative' }}>
              <Slider
                ref={slider}
                {...settings}
                beforeChange={(oldIndex, newIndex) => {
                  setCurrentSlideIndex(newIndex)
                }}
              >
                {productList?.map((item, index) => {
          if (item?.variantInfo?.masterVariant) return null;

                  return (
                    <Box py={0.5} ml={0.5} key={index}>
                      {
                        <ProductCard
                          img={item?.productPhoto?.[0]?.docURL}
                          price={item?.productSalePrice}
                          oldPrice={item?.productOriginalPrice}
                          description={item?.productName}
                          discount={item?.percentageOff}
                          style={{ my: 1.5 }}
                          isOutOfStock={getOutOfStock(item)}
                          isComingSoon={item?.commerceEnabled === 'NO'}
                          minQty={item?.minOrderQuantity}
                          maxQty={item?.maxOrderQuantity}
                          disableAddtoCart
                          product={item}
                        />
                      }
                    </Box>
                  )
                })}
              </Slider>
              {products?.length > 3 ? (
                <Box
                  position='absolute'
                  width='40px'
                  height='40px'
                  backgroundColor={
                    currentSlideIndex === products?.length - 4
                      ? filterBgColor
                      : whiteColor
                  }
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  borderRadius={2}
                  border={'1px solid ' + borderLineColor}
                  sx={{
                    cursor: 'pointer',
                    right: '-13px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                  onClick={() => onSlideNext()}
                >
                  <ChevronRight
                    fontSize='medium'
                    sx={{
                      color:
                        currentSlideIndex === products?.length - 4
                          ? iconInactiveColor
                          : navLinkColor,
                    }}
                  />
                </Box>
              ) : null}
              {products?.length > 3 ? (
                currentSlideIndex > 0 ? (
                  <Box
                    position='absolute'
                    width='40px'
                    height='40px'
                    backgroundColor={
                      currentSlideIndex === 0 ? filterBgColor : whiteColor
                    }
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    borderRadius={2}
                    sx={{
                      cursor: 'pointer',
                      left: '-13px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                    border={'1px solid ' + borderLineColor}
                    onClick={() => onSlidePrev()}
                  >
                    <ChevronLeft
                      fontSize='medium'
                      sx={{
                        color:
                          currentSlideIndex === 0
                            ? iconInactiveColor
                            : navLinkColor,
                      }}
                    />
                  </Box>
                ) : null
              ) : null}

              {}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          background: trySampleBGColor,
          p: 2,
          borderRadius: 4,
          display: { xs: 'block', md: 'none' },
        }}
      >
        <Typography
          color={whiteColor}
          fontSize={15}
          fontWeight={500}
          lineHeight={1.5}
          textAlign='center'
        >
          Don’t just take our word
        </Typography>
        <Typography
          color={whiteColor}
          fontSize={17}
          fontWeight={600}
          lineHeight={1.5}
          textAlign='center'
          pb={1.2}
        >
          Try before you buy!
        </Typography>
        <Slider
          ref={slider}
          {...settings}
          beforeChange={(oldIndex, newIndex) => {
            setCurrentSlideIndex(newIndex)
          }}
        >
          {productList?.map((item, index) => {
          if (item?.variantInfo?.masterVariant) return null;

            return (
              <Box py={0.5} ml={0.5} key={index}>
                {
                  <ProductCard
                    img={item?.productPhoto?.[0]?.docURL}
                    price={item?.productSalePrice}
                    oldPrice={item?.productOriginalPrice}
                    description={item?.productName}
                    discount={item?.percentageOff}
                    style={{ my: 1.5 }}
                    isOutOfStock={getOutOfStock(item)}
                    isComingSoon={item?.commerceEnabled === 'NO'}
                    minQty={item?.minOrderQuantity}
                    maxQty={item?.maxOrderQuantity}
                    disableAddtoCart
                    product={item}
                  />
                }
              </Box>
            )
          })}
        </Slider>
      </Box>
    </Box>
  ) : null
}

export default TryBeforeBuyCard
