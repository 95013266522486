import { getProfileId } from "utils/authHelpers";
import { getApiInstance } from "../../ApiInstance";
import { MULTI_PRODUCTS } from "services/constant";

export const getCartData = async (data) => {
  // const business
  //   const businessId = 'f7b2fa9797ce4e8f858bd372693d0980'//Coorg Basket
  //   const businessId = '6c45e3ce64a341d18e0eda7c09d64abe'//Presso Coldpress Oil
  //   const businessId = '186492f9a0fa41e29ab17dcc0ab3ba7f'//Divyam
  // const businessId = '646e59183b28459fbb7f1ddf6baf53c7'//Timios
  const businessId = sessionStorage.getItem("businessId");
  try {
    const instance = getApiInstance({
      header: { businessId },
    });
    const profileID = getProfileId();
    if (profileID) {
      const response = await instance.get(
        `cart-query-side/api/v2/cart/consumer/checkMyCart`
      );
      console.log("CART_DATA:", response?.data);
      return response?.data;
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getCartDataById = async (data) => {
  try {
    const instance = getApiInstance({
      header: {
        cartid: data,
        // cartid: '5fe9a401da22462ea283bbcb6562d5b3',
      },
    });

    const response = await instance.get(
      `cart-query-side/api/v2/cart/consumer/getCart`
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
export const getMultiProducts = async (productIds) => {
  try {
    const instance = getApiInstance({
      // header: {
      //   cartid: data,
      //   // cartid: '5fe9a401da22462ea283bbcb6562d5b3',
      // },
    });

    const response = await instance.get(MULTI_PRODUCTS, { params: productIds });
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
