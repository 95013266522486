import { Box, Grid } from "@mui/material";
import CategoryFilterLoader from "components/skeletonLoaders/CategoryFilterLoader";
import ProductSectionLoader from "components/skeletonLoaders/ProductSectionLoader";
import React from "react";

function CatalogBodyShimmer() {
  return (
    <Box>
      <Grid container spacing={3.5}>
        <Grid item xs={0} md={2.8} display={{ xs: "none", md: "block" }}>
          <CategoryFilterLoader />
        </Grid>
        <Grid item xs={12} md={9.2} mb={{ xs: 15, md: 3 }}>
          <ProductSectionLoader />
        </Grid>
      </Grid>
    </Box>
  );
}

export default CatalogBodyShimmer;
