import { LocalShipping } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  InputAdornment,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import TextboxWithLabel from "components/TextboxWithLabel";
import {
  blackColor,
  borderLineColor,
  borderTextbox,
  descriptionColor,
  errorTextColor,
  filterMobileColor,
  headingColor,
  redColor01,
  whiteColor,
} from "configs/styles/muiThemes";
import React, { useState } from "react";
import { updateGstIfo } from "services/api_calls/checkout/checkoutApi";
import { getProfileData } from "services/api_calls/auth/login_api";
import { getUserDetails } from "utils/authHelpers";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import DiscountHeader from "./DiscountHeader";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
function GSTDetialsSection({
  gstChecked,
  setGstChecked,
  selectedGst,
  setGstSelected,
  gstNumber,
  setGstNumber,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [anchorEl, setAnchorEl] = useState(null);
  const [gstError, setGstError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [gstErrorMsg, setGstErrorMsg] = useState("");
  const [collapseCartTabel, setCollapseCartTabel] = useState(true);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const userDetails = getUserDetails();

  const handleClose = (item) => {
    setAnchorEl(null);
    setGstSelected(item);
    // if (!item?.id) return

    // if (!item?.pointValue || item?.pointValue === 0) {
    //   if (item?.pointPercentage !== 0) {
    //     const orderTotal = cartDetails?.orderTotalProductCost
    //     const discountPercentage = item?.pointPercentage

    //     const discountAmt = (orderTotal / 100) * discountPercentage
    //     setSelectedDiscount({ ...item, pointValue: discountAmt })
    //   }
    // } else {
    //   setSelectedDiscount(item)
    // }
  };
  return matches ? (
    <Box>
      <DiscountHeader
        topmargin={"15px"}
        icon={
          matches ? (
            ""
          ) : (
            <ReceiptLongIcon
              sx={{
                fontSize: { xs: "22px", md: "20px" },
                color: headingColor,
                mb: "-5px",
                mr: 0.5,
              }}
            />
          )
        }
        title="GST Details"
        gst={false}
        disablePB
        collapseCartTabel={collapseCartTabel}
        onToggleCartItems={() => setCollapseCartTabel(!collapseCartTabel)}
      />
      <Box
        sx={{
          // boxShadow: "0 4px 8px rgb(0 0 0 / 6%)",
          px: 1.5,
          pt: { md: 0.5, xs: 0 },
          pb: { md: 1, xs: 0 },
          borderRadius: 2.2,
          border: "1px solid" + borderLineColor,
          backgroundColor: { md: whiteColor, xs: whiteColor },
        }}
        // display={{ md: 'block', xs: collapseCartTabel ? 'none' : 'block' }}
        m={{ md: "6px 0 0px 0", xs: "2px 0 15px 0" }}
      >
        <FormControlLabel
          control={<Checkbox size="small" />}
          label={
            <Typography
              fontSize={{ md: 12, xs: 11.5 }}
              fontWeight={500}
              color={blackColor}
            >
              Do you want to claim GST refund on this order?
            </Typography>
          }
          value={gstChecked}
          onChange={() => {
            setGstChecked(!gstChecked);
          }}
        />

        {gstChecked ? (
          <Box
            mt={-0.8}
            display="flex"
            alignItems={{ md: "flex-start", xs: "flex-start" }}
            gap={{ md: 2, xs: 1 }}
            flexDirection={{ md: "row", xs: "column" }}
            width={"100%"}
            justifyContent={{ md: "space-between" }}
          >
            <Box width={{ xs: "100%", md: "40%" }}>
              <Typography
                fontSize={{ md: "12px", xs: "13px" }}
                sx={{
                  color: blackColor,
                  fontWeight: 500,
                  mb: `2px !important`,
                }}
              >
                GST Number
              </Typography>
              <Box
                width={{ md: "100%", xs: "100%" }}
                display="flex"
                alignItems={"flex-start"}
                minWidth={{ xs: "100%", md: "100%" }}
                maxWidth={{ xs: "100%", md: "100%" }}
              >
                <TextField
                  variant="standard"
                  required={true}
                  fullWidth
                  name={""}
                  value={gstNumber}
                  onChange={({ target }) => {
                    const value =
                      typeof target?.value === "string"
                        ? target?.value
                            .replace(/[^a-zA-Z0-9]/g, "")
                            .toUpperCase()
                        : "";
                    if (gstError) setGstError(false);
                    if (success) setSuccess(false);
                    setGstSelected("");
                    if (value.length == 15) {
                      const reg =
                        /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(
                          value
                        );
                      console.log("reg", reg);
                      if (reg) {
                        setGstNumber(value);
                        if (userDetails?.account?.gstDetails?.includes(value)) {
                          setGstError(true);
                          setGstErrorMsg(
                            "This GST number already exists in your account. Pick from the dropdown"
                          );
                        }
                      } else {
                        setGstNumber(value);
                        setGstError(true);
                        setGstErrorMsg("Enter a valid GST number");
                      }
                    } else {
                      setGstNumber(value);
                    }
                  }}
                  placeholder={"Enter the GST Number"}
                  sx={{
                    fontSize: 12.5,
                    border: borderTextbox,
                    borderRadius: " 10px 0 0 10px ",
                    p: 0,
                  }}
                  InputProps={{
                    style: { padding: "5px 10px" },
                    disableUnderline: true,
                  }}
                  inputProps={{
                    maxLength: 15,
                  }}
                />
                {/* <TextboxWithLabel
                  small
                  margingright={3}
                  // label='Enter the GST Number'
                  value={gstNumber}
                  onValueChange={(text) => {
                    const value = text
                      .replace(/[^a-zA-Z0-9]/g, '')
                      .toUpperCase()
                    if (gstError) setGstError(false)
                    if (success) setSuccess(false)
                    setGstSelected('')
                    if (value.length == 15) {
                      const reg =
                        /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(
                          value
                        )
                      console.log('reg', reg)
                      if (reg) {
                        setGstNumber(value)
                        if (userDetails?.account?.gstDetails?.includes(value)) {
                          setGstError(true)
                          setGstErrorMsg(
                            'This GST number already exists in your account. Pick from the dropdown'
                          )
                        }
                      } else {
                        setGstNumber(value)
                        setGstError(true)
                        setGstErrorMsg('Enter a valid GST number')
                      }
                    } else {
                      setGstNumber(value)
                    }
                  }}
                  placeholder={' Enter the GST Number'}
                  maxLength={15}
                  isError={gstError}
                  errorMsg={gstErrorMsg}
                  widthtext={{ md: '22vw', xs: '62vw' }}
                /> */}
                <Button
                  variant="contained"
                  sx={{
                    color: { md: whiteColor, xs: whiteColor },
                    backgroundColor: { md: blackColor, xs: blackColor },
                    borderRadius: " 0 10px 10px 0",
                    border: `1px soild ${blackColor}`,
                    p: "7px",
                    // fontSize: 12,
                    textTransform: "uppercase",
                    "&:hover": {
                      backgroundColor: whiteColor,
                      color: blackColor,
                    },
                  }}
                  onClick={() => {
                    setGstError(false);
                    setGstErrorMsg("");

                    if (gstNumber.length == 15) {
                      const reg =
                        /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(
                          gstNumber
                        );
                      console.log("reg", reg);
                      if (reg) {
                        // setGstNumber(value)
                        if (
                          userDetails?.account?.gstDetails?.includes(gstNumber)
                        ) {
                          setGstError(true);
                          setGstErrorMsg(
                            "This GST number already exists in your account. Pick from the dropdown"
                          );
                        } else {
                          userDetails?.account?.gstDetails?.length > 0
                            ? updateGstIfo([
                                gstNumber,
                                ...userDetails?.account?.gstDetails,
                              ]).then((res) => {
                                if (res.success) setSuccess(true);
                                getProfileData();
                              })
                            : updateGstIfo([gstNumber]).then((res) => {
                                if (res.success) setSuccess(true);
                                getProfileData();
                              });
                        }
                      } else {
                        setGstError(true);
                        setGstErrorMsg("Enter a valid GST number");
                      }
                    } else {
                      setGstError(true);
                      setGstErrorMsg("Enter a valid GST number");
                    }
                  }}
                >
                  Add
                </Button>
              </Box>
              {gstError ? (
                <Typography
                  variant="subtitle1"
                  component="p"
                  fontSize={13}
                  color={errorTextColor}
                >
                  {gstError}
                </Typography>
              ) : null}

              {gstErrorMsg ? (
                <Typography
                  variant="body1"
                  component="p"
                  fontSize={11}
                  fontWeight={500}
                  lineHeight="16px"
                  color={errorTextColor}
                  mt={0.8}
                >
                  {gstErrorMsg}
                </Typography>
              ) : null}
            </Box>
            {userDetails?.account?.gstDetails?.length > 0 ? (
              <Box
                display={"flex"}
                justifyContent="center"
                width={{ xs: "100%", md: "10%" }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    background: "#f3f3f8",
                    width: "26px",
                    height: "26px",
                    padding: "5px",
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "50px",
                    fontSize: "11px",
                    fontWeight: 600,
                    color: "#000000",
                    textTransform: "uppercase",
                    mt: 3,
                  }}
                >
                  OR
                </Typography>
              </Box>
            ) : null}
            {userDetails?.account?.gstDetails?.length > 0 ? (
              <Box width={{ md: "40%", xs: "80%" }}>
                <Typography
                  fontSize={{ md: "12px", xs: "13px" }}
                  sx={{
                    color: blackColor,
                    fontWeight: 500,
                    display: "inline",
                  }}
                >
                  Pick from existing value
                </Typography>
                <Box
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  sx={{
                    border: borderTextbox,
                    borderRadius: "6px",
                    fontSize: "13px",
                    color: "#777",
                    background: whiteColor,
                    padding: { md: "9px 10px", xs: "7px 10px" },
                    // width: '100%',
                    "& .MuiInputBase-root.Mui-disabled": {
                      color: "red !important",
                    },
                    minHeight: "20px",
                    mb: 1,
                    mt: 0.3,
                  }}
                  // sx={styles.inputBox(isSelected, isSuccess)}
                  // fullWidth
                  disableRipple
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  // sx={styles.dropdownBtn}
                  // endIcon={<KeyboardArrowDownOutlined />}
                >
                  <Typography
                    variant="body1"
                    component="p"
                    fontSize="13px"
                    color={headingColor}
                    lineHeight="16px"
                  >
                    {selectedGst}
                  </Typography>

                  <KeyboardArrowDownRoundedIcon sx={{ fontSize: "20px" }} />
                </Box>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  variant="selectedMenu"
                  PaperProps={{
                    style: {
                      width: 320,
                      maxHeight: 250,
                    },
                  }}
                  label={"select Existing value"}
                >
                  {getUserDetails()?.account?.gstDetails?.map((item, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        handleClose(item);
                        setGstNumber("");
                        setGstError(false);
                        setGstErrorMsg("");
                        setSuccess(false);
                      }}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Menu>
                {/* <TextboxWithLabel
            small
            label='Pick from existing value'
            // value={pincode}
            onValueChange={(text) => {
              // if (pincode.length === 5) {
              //   dispatch(getStateCityDetails(text))
              //   setIsPincodeError(false)
              // }
              // if (text.length === 6) {
              //   dispatch(getStateCityDetails(text))
              //   setIsPincodeError(false)
              // }
              // setPincode(text)
            }}
            // maxLength={6}
            // placeholder={addressForm_Pincode}
            // required={true}
            // isError={isPincodeError}
            // errorMsg={addressForm_PincodeError}
          /> */}
              </Box>
            ) : null}
          </Box>
        ) : null}
        {success ? (
          <Typography
            variant="subtitle1"
            component="p"
            fontSize={11}
            color={"green"}
          >
            GST Number Successfully added
          </Typography>
        ) : null}
      </Box>
    </Box>
  ) : (
    <Box my={1}>
      <DiscountHeader
        icon={
          // <Box
          //   component={'img'}
          //   src=''
          //   width='20px'
          //   sx={{ mb: '-5px' }}
          // />
          matches ? (
            <ReceiptLongOutlinedIcon
              sx={{
                fontSize: { xs: "19px", md: "20px" },
                color: headingColor,
                mb: "-5px",
              }}
            />
          ) : (
            <ReceiptLongIcon
              sx={{
                fontSize: { xs: "22px", md: "20px" },
                color: headingColor,
                mb: "-5px",
                mr: 0.5,
              }}
            />
          )
        }
        title="GST Details"
        gst={false}
        disablePB
        collapseCartTabel={collapseCartTabel}
        onToggleCartItems={() => setCollapseCartTabel(!collapseCartTabel)}
      />
      <Divider />
      <Box
        sx={{
          // boxShadow: '0 4px 8px rgb(0 0 0 / 6%)',
          // px: 1.5,
          py: { md: 0.5, xs: 0 },
          borderRadius: 2.2,
          // border: '1px solid' + borderLineColor,
          backgroundColor: { md: whiteColor, xs: whiteColor },
        }}
        // display={{ md: 'block', xs: collapseCartTabel ? 'none' : 'block' }}
        m={{ md: "8px 0 15px 0", xs: "2px 0 0px 2px" }}
      >
        <FormControlLabel
          control={<Checkbox size="small" />}
          label={
            <Typography fontSize={{ md: 12, xs: 11.5 }} color={blackColor}>
              Do you want to claim GST refund on this order?
            </Typography>
          }
          value={gstChecked}
          onChange={() => {
            setGstChecked(!gstChecked);
          }}
        />

        {gstChecked ? (
          <Box
            display="flex"
            alignItems={{ md: "center", xs: "flex-start" }}
            gap={{ md: 2, xs: 1 }}
            flexDirection={{ md: "row", xs: "column" }}
            width={"100%"}
            justifyContent={{ md: "space-between" }}
          >
            <Box width={{ xs: "100%", md: "40%" }}>
              <Typography
                fontSize={{ md: "12px", xs: "13px" }}
                sx={{
                  color: blackColor,
                  fontWeight: 500,
                  mb: `8px !important`,
                }}
              >
                GST Number
              </Typography>
              <Box
                width={{ md: "100%", xs: "100%" }}
                display="flex"
                alignItems={"flex-start"}
                minWidth={{ xs: "100%", md: "100%" }}
                maxWidth={{ xs: "100%", md: "100%" }}
              >
                <TextField
                  variant="standard"
                  required={true}
                  fullWidth
                  name={""}
                  value={gstNumber}
                  onChange={({ target }) => {
                    const value = target?.value
                      .replace(/[^a-zA-Z0-9]/g, "")
                      .toUpperCase();
                    if (gstError) setGstError(false);
                    if (success) setSuccess(false);
                    setGstSelected("");
                    if (value.length == 15) {
                      const reg =
                        /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(
                          value
                        );
                      console.log("reg", reg);
                      if (reg) {
                        setGstNumber(value);
                        if (userDetails?.account?.gstDetails?.includes(value)) {
                          setGstError(true);
                          setGstErrorMsg(
                            "This GST number already exists in your account. Pick from the dropdown"
                          );
                        }
                      } else {
                        setGstNumber(value);
                        setGstError(true);
                        setGstErrorMsg("Enter a valid GST number");
                      }
                    } else {
                      setGstNumber(value);
                    }
                  }}
                  placeholder={"Enter the GST Number"}
                  sx={{
                    fontSize: 12.5,
                    border: borderTextbox,
                    borderRadius: " 10px 0 0 10px ",
                    p: 0,
                  }}
                  InputProps={{
                    style: { padding: "5px 10px" },
                    disableUnderline: true,
                  }}
                  inputProps={{
                    maxLength: 15,
                  }}
                />
                <Button
                  variant="contained"
                  sx={{
                    color: { md: whiteColor, xs: whiteColor },
                    backgroundColor: { md: blackColor, xs: blackColor },
                    borderRadius: " 0 10px 10px 0",
                    border: `1px soild ${blackColor}`,
                    p: "7px",
                    textTransform: "uppercase",
                    "&:hover": {
                      backgroundColor: whiteColor,
                      color: blackColor,
                    },
                  }}
                  onClick={() => {
                    setGstError(false);
                    setGstErrorMsg("");

                    if (gstNumber.length == 15) {
                      const reg =
                        /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(
                          gstNumber
                        );
                      console.log("reg", reg);
                      if (reg) {
                        // setGstNumber(value)
                        if (
                          userDetails?.account?.gstDetails?.includes(gstNumber)
                        ) {
                          setGstError(true);
                          setGstErrorMsg(
                            "This GST number already exists in your account. Pick from the dropdown"
                          );
                        } else {
                          userDetails?.account?.gstDetails?.length > 0
                            ? updateGstIfo([
                                gstNumber,
                                ...userDetails?.account?.gstDetails,
                              ]).then((res) => {
                                if (res.success) setSuccess(true);
                                getProfileData();
                              })
                            : updateGstIfo([gstNumber]).then((res) => {
                                if (res.success) setSuccess(true);
                                getProfileData();
                              });
                        }
                      } else {
                        setGstError(true);
                        setGstErrorMsg("Enter a valid GST number");
                      }
                    } else {
                      setGstError(true);
                      setGstErrorMsg("Enter a valid GST number");
                    }
                  }}
                >
                  Add
                </Button>
              </Box>
              {gstError ? (
                <Typography
                  variant="subtitle1"
                  component="p"
                  fontSize={13}
                  color={redColor01}
                >
                  {gstError}
                </Typography>
              ) : null}

              {gstErrorMsg ? (
                <Typography
                  variant="body1"
                  component="p"
                  fontSize={11}
                  fontWeight={500}
                  color={redColor01}
                  mt={0.9}
                  lineHeight="18px"
                >
                  {gstErrorMsg}
                </Typography>
              ) : null}

              {success ? (
                <Typography
                  variant="subtitle1"
                  component="p"
                  fontSize={11}
                  fontWeight={500}
                  color={"green"}
                  mt={0.9}
                >
                  GST Number Successfully added
                </Typography>
              ) : null}
            </Box>
            {userDetails?.account?.gstDetails?.length > 0 ? (
              <Box
                display={"flex"}
                justifyContent="center"
                width={{ xs: "100%", md: "10%" }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    background: "#f3f3f8",
                    width: "26px",
                    height: "26px",
                    padding: "5px",
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "50px",
                    fontSize: "11px",
                    fontWeight: 600,
                    color: "#000000",
                    textTransform: "uppercase",
                  }}
                >
                  OR
                </Typography>
              </Box>
            ) : null}
            {userDetails?.account?.gstDetails?.length > 0 ? (
              <Box width={{ md: "40%", xs: "100%" }}>
                <Typography
                  fontSize={{ md: "12px", xs: "13px" }}
                  sx={{
                    color: blackColor,
                    fontWeight: 500,
                    display: "inline",
                  }}
                >
                  Pick from existing value
                </Typography>
                <Box
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  sx={{
                    border: borderTextbox,
                    borderRadius: "6px",
                    fontSize: "13px",
                    color: "#777",
                    background: whiteColor,
                    padding: { md: "9px 10px", xs: "7px 10px" },
                    // width: '100%',
                    "& .MuiInputBase-root.Mui-disabled": {
                      color: "red !important",
                    },
                    minHeight: "20px",
                    mb: 1,
                    mt: 1,
                  }}
                  // sx={styles.inputBox(isSelected, isSuccess)}
                  // fullWidth
                  disableRipple
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  // sx={styles.dropdownBtn}
                  // endIcon={<KeyboardArrowDownOutlined />}
                >
                  <Typography
                    variant="body1"
                    component="p"
                    fontSize="13px"
                    color={headingColor}
                    lineHeight="16px"
                  >
                    {selectedGst}
                  </Typography>

                  <KeyboardArrowDownRoundedIcon sx={{ fontSize: "20px" }} />
                </Box>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  variant="selectedMenu"
                  PaperProps={{
                    style: {
                      width: 320,
                      maxHeight: 250,
                    },
                  }}
                  label={"select Existing value"}
                >
                  {getUserDetails()?.account?.gstDetails?.map((item, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        handleClose(item);
                        setGstNumber("");
                        setGstError(false);
                        setGstErrorMsg("");
                        setSuccess(false);
                      }}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Menu>
                {/* <TextboxWithLabel
            small
            label='Pick from existing value'
            // value={pincode}
            onValueChange={(text) => {
              // if (pincode.length === 5) {
              //   dispatch(getStateCityDetails(text))
              //   setIsPincodeError(false)
              // }
              // if (text.length === 6) {
              //   dispatch(getStateCityDetails(text))
              //   setIsPincodeError(false)
              // }
              // setPincode(text)
            }}
            // maxLength={6}
            // placeholder={addressForm_Pincode}
            // required={true}
            // isError={isPincodeError}
            // errorMsg={addressForm_PincodeError}
          /> */}
              </Box>
            ) : null}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}

export default GSTDetialsSection;
