import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import {
  blackColor,
  borderLineColor,
  couponColor,
  greenColor06,
  greyColor9,
  headingColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import { PaymentRounded } from "@mui/icons-material";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import moment from "moment";
import HistoryIcon from "@mui/icons-material/History";

function ETADetails({
  deliveryETA,
  deliveryDate,
  orderDeliveredIn,
  orderDeliveredOn,
  orderStatus,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Stack
      border={"1px solid " + borderLineColor}
      borderRadius={2.2}
      px={1.85}
      py={{ xs: 1.85, md: 1.6 }}
      gap={1}
      my={1}
      backgroundColor={{ md: whiteColor, xs: whiteColor }}
    >
      {/* <Typography
        variant="subtitle1"
        component="p"
        fontSize={{ xs: 13, md: 13 }}
        lineHeight="24px"
        color={{ md: blackColor, xs: blackColor }}
      >
        PickUp Time :{" "}
        {pickupDate && pickupDate !== 0
          ? moment(pickupDate).format("MMM Do, ")
          : "N/A"}
        {pickupETA && pickupETA !== 0
          ? moment(pickupETA).format("hh:mm A")
          : ""}
      </Typography> */}
      {orderStatus !== "orderdelivered" ? (
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={{ xs: 13, md: 13 }}
          lineHeight="24px"
          color={{ md: blackColor, xs: blackColor }}
        >
          Delivery ETA :{" "}
          <Typography component={"span"} color={greenColor06} fontWeight={500}>
            {deliveryDate && deliveryDate !== 0
              ? moment(deliveryDate).format("MMM Do, ")
              : "N/A"}
            {deliveryETA && deliveryETA !== 0
              ? moment(deliveryETA).format("hh:mm A")
              : ""}
          </Typography>
        </Typography>
      ) : (
        <>
          <Typography
            variant="subtitle1"
            component="p"
            fontSize={{ xs: 13, md: 13 }}
            lineHeight="24px"
            color={{ md: blackColor, xs: blackColor }}
          >
            Delivered On :{"  "}
            <Typography
              component={"span"}
              color={greenColor06}
              fontWeight={500}
            >
              {orderDeliveredOn
                ? moment(orderDeliveredOn).format("MMM Do, hh:mm A")
                : "N/A"}
            </Typography>
          </Typography>
          <Box display="flex" alignItems="center" gap={0.6}>
            <HistoryIcon sx={{ fontSize: "18px", color: blackColor }} />
            <Typography
              variant="subtitle1"
              component="p"
              fontSize={{ xs: 13, md: 13 }}
              lineHeight="24px"
              color={{ md: blackColor, xs: blackColor }}
            >
              Order Completed In:{" "}
              <Typography
                component={"span"}
                color={greenColor06}
                fontWeight={500}
              >
                {orderDeliveredIn || "N/A"}
              </Typography>
            </Typography>
          </Box>
        </>
      )}
      {/* {orderDeliveredOn && (
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={{ xs: 13, md: 13 }}
          lineHeight="24px"
          color={{ md: blackColor, xs: blackColor }}
        >
          Delivered On :{"  "}
          {moment(orderDeliveredOn).format("MMM Do, hh:mm A")}
        </Typography>
      )} */}
      {/* {orderApprovedIn && (
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={{ xs: 13, md: 13 }}
          lineHeight="24px"
          color={{ md: blackColor, xs: blackColor }}
        >
          Approved In :{"  "}
          {orderApprovedIn}
        </Typography>
      )} */}
      {/* {orderDeliveredIn && (
        <Box display="flex" alignItems="center" gap={1}>
          <HistoryIcon sx={{ fontSize: "18px", color: blackColor }} />
          <Typography
            variant="subtitle1"
            component="p"
            fontSize={{ xs: 13, md: 13 }}
            lineHeight="24px"
            color={{ md: blackColor, xs: blackColor }}
          >
            {orderDeliveredIn}
          </Typography>
        </Box>
      )} */}
    </Stack>
  );
}

export default ETADetails;
