import { createTheme } from "@mui/material";

export const kPrimaryMain = "#4543d9";
// export const primaryColor = '#fa6138'
export const primaryColor = sessionStorage.getItem(`primary`)
  ? `#${sessionStorage.getItem(`primary`)}`
  : "#fa6138";
export const mobilePrimaryColor = "#F58220";
// export const secondaryColor = '#3C98AF'
export const secondaryColor = sessionStorage.getItem(`secondary`)
  ? `#${sessionStorage.getItem(`secondary`)}`
  : "rgb(74, 74, 74)";
// export const secondaryColor = "#8E5533";
export const headingColor = "#000";
// export const headingColor = '#252525'
export const navLinkColor = "#4a4a4a";
export const backgroundColor = "#f4f6fa";

export const backgroundColor01 = "#F3F3F8";
export const backgroundColor02 = "#f3f3f8cf";
export const backgroundColor03 = "#f6f6fa";
export const borderbackgroundColor03 = "#ececf4";
export const borderbackgroundColor04 = "#e7e5e5";
export const backgroundColor05 = "#e2e2eb";

export const greyColor6 = "#7e8a9e";
export const greyShade07 = "#4d4d4d";
export const greyColor40 = "#808BA0";
export const tabTextOrangeColor = "#F58220";

export const descriptionColor = "#828282";
export const couponColor = "#505050";
export const businessBodyColor = "#646464";
export const openColor = "#21bf0e";
export const closeColor = "#ff0000";
export const whiteColor = "#fff";
export const reviewBodyColor = "#747474";
export const reviewSubColor = "#a1a1a1";
export const labelColor = "#4c4c4c";
export const bannerDesColor = "#878787";
export const bannerListColor = "#1B1B1B";
export const navHeaderColor = "#4f4f4f";
export const footerTextColor = "#1a1a1a";
// export const topbarColor = "#4f539d";
// export const topbarColor = "#8E5533";
// export const topbarColor = '#3C98AF' //blue
export const topbarColor = sessionStorage.getItem(`secondary`)
  ? `#${sessionStorage.getItem(`secondary`)}`
  : "rgb(74, 74, 74)";
export const tagTrackColor = "#a4e5f5"; //blue
export const emptyMessageColor = "#181818";
export const borderColor = "#f3f3f8";
export const catalogSubHeaderColor = "#727272";
export const arrowBgColor = "#e8e9eb";
export const productLabelColor = "#343434";
export const offerTextColor = "rgb(155, 163, 170)";
export const productColor = "#565656";
export const outOfStockColor = "rgb(226 62 29 / 85%)";
export const ComingSoonColor = "rgb(15 190 83 / 85%)";
export const sidebarHeadingColor = "#111111";
export const bannerstarColor = "rgb(246, 176, 50)";
export const footerListColor = "#545454";
export const badgeBackgroundColor = "#cbcbcb";
export const progressBgColor = "#e9ecef";
export const filterBgColor = "#fafafa";
export const filterMobileColor = "#232323";
export const iconInactiveColor = "#C7C8CD";
export const breadcrumbBgColor = "#f5f5f5";
export const categoryLabelColor = "#949494";
export const quantityIconBgColor = "#ededed";
export const savingTextColor = "#09ba77";
export const quatityIconColor = "#757575";
export const borderLineColor = "#e4e4eb";
export const outOfStockTextColor = "#e23e1d";
export const delectIconInactiveColor = "#818181";
export const totalSavingBGColor = "#f0f9f1";
export const stepperInactiveColor = "#f5f5f5";
export const stepperIconColor = "#999999";
export const stepperTextInactiveColor = "#808080";
export const categoryLabelSpanColor = "#6c6c6c";
export const discountBgColor = "#f6f6f6";
export const errorTextColor = "#e50000";
export const minOrderTextColor = "#7a7a7a";
export const infoAlertBgColor = "#f1f1f1";
export const radioBtnLabelColor = "#929292";
export const borderLineSearchColor = "#f0f0f0";
export const borderTextBoxColor = "#e8e8e8";
export const productBorderColor = "#d5d5d5";
export const cartBorderColor = "#d9d9d9";
export const cartBorderColor01 = "#e5e5ed";
export const authInfoColor = "#a2a5a7";
export const tncColor = "#787878";
export const dashboardDescriptionColor = "#7e7e7e";
export const dashboardInfoColor = "#777777";
export const dashboardNavColor = "#5d5d5d";
export const orderStepInactiveColor = "#c0c0c0";
export const canceledColor = "#cf0707";
export const completedColor = "#299956";
export const orderDateColor = "#686b78";
export const trackColor = "#2598AF";
export const searchLabelColor = "#959595";
export const homeSubTextColor = "#6e6e6e";
export const discountSectionBGColor = "#f8f8f8";
export const discountInfoCardBGColor = "#ffeee9";
// export const loginPrimary = '#fa6138'
export const loginPrimary = sessionStorage.getItem(`primary`)
  ? `#${sessionStorage.getItem(`primary`)}`
  : "#fa6138";
export const copyrightTextColor = "#12092c";
export const footerCopyrightBGColor = "#f7f7f7";
export const bannerColor = "#97b45a";
export const whatsappIconColor = "#3FBE4F";
export const navBarBorderColor = "#ebebeb";
export const blackColor = "#000000";
export const blackColor01 = "#212529";
export const influencerCardBGColor = "#fff3ea";
export const shareBGColor = "#e5e7eb";
export const trySampleBGColor = "#21acc9";
export const reviewCardBorderColor = "#e7e7f0";

export const blueColor04 = "#3f53d8";
export const greenColor07 = "#169f4c";

export const blackShade8 = "#252525";
export const blackShade11 = "#7c7c7c";
export const blackShade14 = "#787878";
export const blackShade17 = "#999999";
export const blackShade18 = "#747474";
export const blackShade19 = "#141414";
export const blackShade20 = "#051e3b";
export const blackShade21 = "#242d3e";

export const greyColor3 = "#707070";
export const greyColor8 = "#023143";
export const greyColor9 = "#9090a3";
export const bgColor15 = "#8593ac";

export const greyShade7 = "#828282";
export const greyShade8 = "#a8adb5";
export const greyShade9 = "#e4e6e9";
export const greyShade10 = "#ababab";
export const greyShade40 = "#9b9b9b";
export const greyShade32 = "#6f6f6f";
export const greyShade33 = "#d8d8d8";
export const greyShade34 = "#b8b8b8";
export const greyShade35 = "#7e7e7e";
export const greyShade36 = "#f7f7f7";
export const greyShade37 = "#eeeff7";
export const greyShade38 = "#b0b0b0";
export const greyShade39 = "#d1d1d1";
export const greyShade41 = "#f5f6f8";
export const greyShade42 = "#7e8a9e";
export const greyShade43 = "#e5e9f0";
export const greyShade44 = "#f5f7fa";
export const greyShade45 = "#e2e5ec";
export const greyShade46 = "#9ba3aa";
export const greyShade47 = "#EAEAF1";
export const greyShade48 = "#6C6C7C";
export const greyShade49 = "#e8e8f0";
export const greyShade50 = "#646667";
export const greyShade51 = "#e5e5ec";
export const greyShade52 = "#9595a9";
export const greyShade53 = "#dedee8";
export const greyShade54 = "#878795";
export const greyShade55 = "#a1a5af";
export const greyShade56 = "#E8E8F2";
export const greyShade57 = "#A0A0B2";
export const greyShade58 = "#9090A3";
export const greyShade59 = "#808287";
export const greyShade60 = "#e7e7eb";
export const greyShade61 = "#dcdce5";
export const greyShade62 = "#dfe3e6";
export const greyShade63 = "#e1e1ec";
export const greyShade64 = "#969caa";
export const greyShade65 = "#dedee9";
export const greyShade66 = "#656565";
export const greyShade67 = "#737385";
export const greyShade68 = "#e6e6ed";
export const greyShade69 = "#8a939b";
export const greyShade70 = "#E9E9F2";
export const greyShade71 = "#9C9CAE";
export const greyShade72 = "#8593AC";
export const greyShade73 = "#787885";

export const greyShade001 = "#808BA0";
export const greyShade002 = "#e7e7e7";
export const greyShade003 = "#f9fafb";
export const blueColor03 = "#242d3e";

export const stepperBGColor = "#d6dae3";

export const lightOrange01 = "#fff2e4";
export const lightOrange02 = "#FFE5F6";

export const orangeColor01 = "#e56b46";
export const orangeColor12 = "#ff7f590f";
export const orangeColor13 = "#e56b4624";

export const redColor = "#ff0000";
export const redColor01 = "#dd3e0f";
export const redColor02 = "#da3412";
export const redColor03 = "#e53935";
export const redColor04 = "#e90525";

export const greenColor01 = "#00B293";
export const greenColor04 = "#1e8e3e";
export const greenColor05 = "#10847e";
export const greenColor06 = "#24B794";

export const blueShade10 = "#D9E9FF";
export const perpleShade10 = "#F6D4FF";
export const orangeShade10 = "#FFEFCF";

export const greenColor03 = "#4CBB17";
export const blackShade05 = "#051e3b";

export const lightGreen01 = "#dcfff7";
export const lightGreen02 = "#00d395";
export const lightGreen03 = "#21c27a";
export const lightGreen04 = "#E8F3F3";

export const lightGreenShade01 = "#ebfaf4";

export const codBtnColor = "#12a6de";
export const bgColor = "#f2f2f2";
export const bgColor5 = "#e5e5ee";
export const greyShade13 = "#8b8b8b";

export const discountPercentageColor = "#01a4b5";
export const blackColor25 = "#333";
export const freeContentBgColor = "#ecf6f0";

export const orangeThickColor = "#f86038";
export const orangeColor = "#ff7b2c";
export const orangeColor02 = "#fca549";
export const orangeColor03 = "#E56B46";
export const orangeColor04 = "#f7f1f2";
export const orangeColor05 = "#ffe4dc";
export const orangeLightColor = "#ffe3d2";
export const lightBlueColor = "#bee1e6";
export const lightBlueColor01 = "#CBEFFB";
export const lightBlueColor02 = "#baede3";
export const lightBlueColor03 = "#d9f7f1";
export const lightBlueColor04 = "#DDEDE9";
export const greenTextColor = "#299956";

export const stepperActiveColor = "#04c47e";
export const soldTextColor = "#f52454";

export const textBtnColor = "#ff6e3b";
export const textBtnColorLight = "#fff8f5";

export const shareEarnLinearGradient =
  "linear-gradient(135deg, #01a4b5, #62c9a9)";

export const whatsappBGColor = "#42be50";
export const facebookBGColor = "#4267B2";
export const instagramBGColor = "#cc0001";
export const linkedInBGColor = "#0072b1";
export const twitterBGColor = "#1DA1F2";
export const telegramBGColor = "#0088cc";

export const greenColor = "#34c518";
export const greenColor02 = "#50bca2";
export const yellowColor = "#fdca49";

export const lightPink01 = "#ffc3ea";
export const lightPink02 = "#ffe5f6";
export const lightPink03 = "#df51ae";

export const lightPurple01 = "#E1E1FF";
export const lightPurple02 = "#DDE8FE";
export const lightOragne01 = "#FFE9D2";

export const lightOrange03 = "#FFEDDA";
export const lightOrange04 = "#f4dbc1";
export const lightOrange05 = "#ff8e18";
export const lightOrange06 = "#fdf2ee";

// border
export const borderSearchBar = "1px solid" + borderLineSearchColor;
export const borderTextbox = "1px solid" + borderTextBoxColor;
export const borderOTPTextbox = "1px solid #737373";
export const borderProduct = "1px solid" + productBorderColor;
export const borderCart = "1px solid" + cartBorderColor;
export const borderBackground = "1px solid" + backgroundColor;
export const borderDashedFreeContent = "1px dashed" + savingTextColor;
export const borderBottomLine2 = "1px solid #efefef";
export const borderInputTextbox = "1px solid #f3f3f3";
export const borderPaymentInfo = "1px solid #ebebeb";
export const borderCard = "1px solid #d7d7e4";
export const borderCard01 = "1px solid #d1d1e0";
export const borderCard02 = "1px solid #e2e2e2";
export const borderCard03 = "1px solid #e8e8e8";
export const borderCard04 = "1px solid #e1e1eb";
export const borderCard05 = "1px solid #e7eaef";

// Gradient color
export const applyBg = "linear-gradient(to right, #2345c4 0%, #027fff 100%)";
export const approvedBg = "linear-gradient(to right, #578cff 0%, #2b63dc 100%)";
export const incompleteBg = "#009793";
export const rejectedBg = "#f86d70";
export const dueBg = "#d65db1";
export const overDueBg = "#f47960";
export const reApplyBg = "#dc5858";
export const closedBg = "#cc543c";
export const socialTrendBorder = "1px solid #e8eaed";

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    allVariants: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
    },
    h1: {
      fontWeight: 600,
      fontSize: "23px",
    },

    h2: {
      fontSize: "22px",
      fontWeight: 600,
    },

    h3: {
      fontSize: "18px",
      fontWeight: 600,
    },

    h4: {
      fontSize: "17px",
      fontWeight: 600,
    },

    h5: {
      fontSize: "16px",
      fontWeight: 600,
    },
    h6: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "26px",
    },
    subtitle1: {
      fontSize: 15,
      fontWeight: 500,
      lineHeight: "normal",
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: "normal",
    },
    body1: {
      fontSize: "13px",
      lineHeight: "23px",
    },
    body2: {
      fontSize: "12px",
      lineHeight: "normal",
    },
    caption: {
      fontSize: "16px",
    },
  },
  palette: {
    primary: {
      main: orangeColor01,
    },
    secondary: {
      main: secondaryColor,
    },
  },
  img: {
    transition: "all 0.3s ease-in-out",
    transform: "scale(1)",
    "&:hover": {
      transform: "scale(0.97)",
    },
  },
});

export default theme;
