import {
  Box,
  Button,
  Divider,
  FormGroup,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import { ProductFilter_Info } from 'configs/Constants'
import { useState } from 'react'
import {
  backgroundColor03,
  blackColor,
  borderColor,
  catalogSubHeaderColor,
  filterBgColor,
  labelColor,
  orangeColor01,
  productLabelColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import CheckBoxLabel from './CheckBoxLabel'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

function ProductFilterMobile({ onFilterClick, category, onCloseClick }) {
  const [selectedFilter, setSelectedFilter] = useState('')
  const [type, setType] = useState('')
  const [optionSubCat, setOptionSubCat] = useState([])
  const [filteredSubCat, setFilteredSubCat] = useState([])
  const [filteredProduct, setFilteredProduct] = useState(null)

  const handleSubCatClick = (catCode) => {
    const isPresent = filteredSubCat.filter((item, index) => {
      return item.code === catCode.code
    })
    if (isPresent.length > 0) {
      if (filteredSubCat.length === 1) {
        setFilteredSubCat([])
        return
      }
      const newList = filteredSubCat.filter((item, index) => {
        return item.code !== catCode.code
      })
      setFilteredSubCat(newList)
    } else {
      setFilteredSubCat([...filteredSubCat, catCode])
    }
    console.log('SUB_CAT_CODE:', filteredSubCat)
  }
  return (
    <Box>
      {/* <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        px={2}
        py={1}
      >
        <Grid item>
          <Typography
            variant='body2'
            color={{ md: catalogSubHeaderColor, xs: blackColor }}
            fontSize='15px'
            fontWeight={500}
          >
            FILTER
          </Typography>
        </Grid>

        <Grid item>
          <Button
            onClick={() => {
              setType('')
              setFilteredSubCat([])
              setFilteredProduct(null)
            }}
            sx={{
              textTransform: 'none',
              color: 'primary',
            }}
          >
            Clear All
          </Button>
        </Grid>
      </Grid> */}

      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        p='3px'
      >
        <IconButton
          onClick={() => {
            onCloseClick()
          }}
        >
          <KeyboardBackspaceIcon sx={{ fontSize: '24px', color: blackColor }} />
        </IconButton>

        <Typography
          variant='body2'
          color={{ md: catalogSubHeaderColor, xs: blackColor }}
          fontSize='15px'
          fontWeight={500}
        >
          Filter
        </Typography>

        <Box sx={{ width: '24px' }} />
      </Box>

      <Divider light />

      <Box>
        <Grid container>
          <Grid item xs={5.7}>
            <Box
              height='83.5vh'
              sx={{
                backgroundColor: { md: filterBgColor, xs: backgroundColor03 },
              }}
              overflow='scroll'
            >
              <FilterOptionLabel
                title='All Products'
                onFilterClick={() => {
                  setType('all-products')
                  setFilteredProduct(null)
                  setSelectedFilter('')
                }}
                isActive={type === 'all-products'}
              />

              <Divider light />

              {category?.map((mainCat, index) => {
                return (
                  <Box key={index}>
                    <FilterOptionLabel
                      title={mainCat.value}
                      onFilterClick={() => {
                        setSelectedFilter('')
                        setType('filtered-products')
                        setFilteredProduct(mainCat)
                      }}
                      isActive={filteredProduct?.value === mainCat?.value}
                    />
                    {mainCat?.subCat?.map((subCat, i) => {
                      return (
                        <Box key={`sub_cat_${i}`}>
                          <FilterOptionLabel
                            title={subCat?.value}
                            isSubCategory
                            onFilterClick={() => {
                              setSelectedFilter(subCat?.value)
                              setOptionSubCat(subCat?.subCat2 || [])
                              setType('filtered-subcat')
                              setFilteredProduct(subCat)
                            }}
                            isActive={selectedFilter === subCat?.value}
                          />
                          <Divider light />
                        </Box>
                      )
                    })}
                  </Box>
                )
              })}
            </Box>
          </Grid>

          <Grid item xs={6.3}>
            {selectedFilter && optionSubCat.length > 0 ? (
              <Box pl={2}>
                <FilterOptionLabel
                  title={selectedFilter}
                  // count={136}
                  showHeader
                />
                <FormGroup>
                  {optionSubCat?.map((option, index) => {
                    return (
                      <>
                        <CheckBoxLabel
                          label={option.value}
                          textSmall
                          subCategory={option}
                          checked={filteredSubCat.includes(option)}
                          onValuesChanged={(catCode) => {
                            handleSubCatClick(catCode)
                          }}
                          key={index}
                        />
                        {/* <Divider light /> */}
                      </>
                    )
                  })}
                </FormGroup>
              </Box>
            ) : (
              <Box
                sx={{ height: '100%' }}
                display='flex'
                alignItems='center'
                justifyContent='center'
              >
                <Typography
                  color={labelColor}
                  fontSize={11}
                  fontWeight={400}
                  p={0.7}
                  textAlign='center'
                >
                  {ProductFilter_Info}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>

      <Divider light />

      <Box width='100vw !important' position='fixed' bottom='0'>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          gap={7}
          p='0px 14px 0px 14px'
        >
          <Button
            sx={[styles.mobilesubmitBtn, {color: blackColor, bgcolor: whiteColor, border:`1px solid ${blackColor}`}]}
            onClick={() => {
              setType('')
              setFilteredSubCat([])
              setFilteredProduct(null)
            }}
          >
            CLEAR FILTER
          </Button>

          <Button
            sx={[styles.mobilesubmitBtn]}
            onClick={() => {
              onFilterClick({ type, filteredSubCat, filteredProduct })
            }}
            // color='secondary'
          >
            APPLY
          </Button>
        </Box>
        <Grid
          container
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{ backgroundColor: whiteColor }}
          px={2}
          py={1}
          spacing={3}
        >
          <Grid item xs={6}></Grid>

          <Grid item xs={6}></Grid>
        </Grid>
      </Box>
    </Box>
  )
}

const FilterOptionLabel = ({
  title,
  count,
  isSubCategory,
  showHeader,
  isActive,
  onFilterClick,
}) => {
  return (
    <Box
      backgroundColor={
        isActive
          ? whiteColor
          : showHeader
          ? whiteColor
          : { md: filterBgColor, xs: "#f6f6fa" }
      }
      borderLeft={
        isActive
          ? { md: "2px solid #4f539d", xs: `3px solid ${blackColor}` }
          : ""
      }
      pt={0.4}
      pb={0}
      onClick={() => {
        onFilterClick();
      }}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        py={0.6}
        px={1}
      >
        <Typography
          variant="body1"
          color={
            isActive ? { md: orangeColor01, xs: blackColor } : productLabelColor
          }
          fontSize="12px"
          lineHeight="21px"
          fontWeight={isSubCategory ? 500 : showHeader ? 400 : 500}
          pl={isSubCategory ? 1.5 : 0}
        >
          {title}
        </Typography>

        <Typography
          variant="body2"
          color={catalogSubHeaderColor}
          fontSize="11px"
          fontWeight={500}
        >
          {count}
        </Typography>
      </Grid>
    </Box>
  );
}

export default ProductFilterMobile

const styles = {
  mobilesubmitBtn: {
    minWidth: "140px",
    fontSize: "12px",
    fontWeight: 500,
    color: whiteColor,
    p: "8px 18px",
    borderRadius: "6px",
    backgroundColor: blackColor,
    textTransform: "uppercase",
    "&:hover": {
      color: blackColor,
      backgroundColor: whiteColor,
    },
  },
  mobileCloseBtn: {
    fontSize: "12.5px",
    fontWeight: 500,
    color: blackColor,
    p: "7px 20px",
    borderRadius: "6px",
    backgroundColor: whiteColor,
    border: `1px solid ${blackColor}`,
    "&:hover": {
      color: whiteColor,
      backgroundColor: blackColor,
    },
  },
};
