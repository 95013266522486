import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./ImageCarousel.css"; // Import your custom styles

import LazyLoad from "react-lazyload";

import { Autoplay } from "swiper/modules";
import { Box, Skeleton } from "@mui/material";
import { bgColor, blackColor, whiteColor } from "configs/styles/muiThemes";

const ImageCarousel = ({ businessBanners }) => {
  return (
    <Box sx={{ transform: "scale(1.043)" }}>
      <Swiper
        centeredSlides={true}
        loop={true}
        speed={800}
        slidesPerView={3}
        // spaceBetween={30}
        autoplay={{
          delay: 2000,
        }}
        modules={[Autoplay]}
      >
        {businessBanners?.map((bannerItem, index) => (
          <SwiperSlide key={index}>
            {/* <Box
              component="img"
              sx={{
                borderRadius: "20px",
                border: `1px solid rgb(217 217 231)`,
              }}
              src={bannerItem?.docURL}
              alt={`Slide ${index + 1}`}
            /> */}
            <LasyLoadImage
              img={bannerItem?.docURL}
              alt={`Slide ${index + 1}`}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default ImageCarousel;

const LasyLoadImage = ({ img, alt }) => {
  const loadingRef = useRef(null);
  const productImgRef = useRef(null);

  return (
    <>
      <Box ref={loadingRef}>
        <Skeleton
          variant="rectangular"
          width="calc(100vw / 3.1)"
          height="427px"
          sx={{
            bgcolor: whiteColor,
            transform: "scale(0.9) translateY(6px) !important",
            borderRadius: "20px",
          }}
        />
      </Box>

      <LazyLoad offset={100} throttle={200}>
        <Box
          ref={productImgRef}
          component="img"
          src={img}
          alt={alt}
          width="1px"
          height="1px"
          onLoad={() => {
            loadingRef.current.style.display = "none";
            productImgRef.current.style.width = "100%";
            productImgRef.current.style.height = "100%";
          }}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            // currentTarget.src = noProductImgUrl;
            // setDisableCard(true);
          }}
          sx={{
            borderRadius: "20px",
            border: `1px solid rgb(217 217 231)`,
          }}
        />
      </LazyLoad>
    </>
  );
};
