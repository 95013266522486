import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  getActiveBusinessDetails,
  getLocation,
  getReviewSummary,
  getSocialActivity,
  getTotalViews,
  updateFollow,
} from '../../services/api_calls/header/HeaderApi'

const initialState = {
  businessDetails: null,
  summary: undefined || {},
  social: null,
  total: null,
  loading: false,
  updateFollowdata: null,
  businessFullName: ''
}

//Fetching overview details
export const getActiveBusiness = createAsyncThunk(
  'getActiveBusiness',
  async (thunkAPI) => {
    const res = await getActiveBusinessDetails()
    return res
  }
)

//Fetching summary details
export const getReviewSummaryData = createAsyncThunk(
  'getReviewSummaryData',
  async (thunkAPI) => {
    const res = await getReviewSummary()
    return res
  }
)

//Fetching Social Count
export const getSocialActivityOfBusiness = createAsyncThunk(
  'getSocialActivityOfBusiness',
  async (thunkAPI) => {
    const res = await getSocialActivity()
    return res
  }
)

//Fetching total review Count
export const getBusinessReviewCount = createAsyncThunk(
  'getBusinessReviewCount',
  async (thunkAPI) => {
    const res = await getTotalViews()
    return res
  }
)
export const updateFollowDetails = createAsyncThunk(
  'updateFollowDetails',
  async (params) => {
    const res = await updateFollow(params)
    if (res?.businessSocialId) {
      sessionStorage.setItem('businessSocialId', res?.businessSocialId)
    }
    return res
  }
)
export const getLocationDetails = createAsyncThunk(
  'getLocationDetails',
  async (params) => {
    const res = await getLocation(params)
    // if (res?.businessSocialId) {
    //   sessionStorage.setItem('businessSocialId', res?.businessSocialId)
    // }
    return res
  }
)

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    clearHeaderDetails: (state) => {
      state.social = {...state.social, status: 0}
    },
  },
  extraReducers: {
    [getActiveBusiness.pending]: (state) => {
      state.loading = true
    },
    [getActiveBusiness.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.businessDetails = payload
    },
    [getActiveBusiness.rejected]: (state) => {
      state.loading = false
    },
    //all customer reviews reducer
    [getReviewSummaryData.pending]: (state) => {
      state.loading = true
    },
    [getReviewSummaryData.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.summary = payload
    },
    [getReviewSummaryData.rejected]: (state) => {
      state.loading = false
    },

    //Get Social Activity
    [getSocialActivityOfBusiness.pending]: (state) => {
      state.loading = true
    },
    [getSocialActivityOfBusiness.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.social = payload
    },
    [getSocialActivityOfBusiness.rejected]: (state) => {
      state.loading = false
    },

    [getBusinessReviewCount.pending]: (state) => {
      state.loading = true
    },
    [getBusinessReviewCount.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.total = payload
    },
    [getBusinessReviewCount.rejected]: (state) => {
      state.loading = false
    },

    [updateFollowDetails.pending]: (state) => {
      state.loading = true
    },
    [updateFollowDetails.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.updateFollowdata = payload
    },
    [updateFollowDetails.rejected]: (state) => {
      state.loading = false
    },
    [getLocationDetails.pending]: (state) => {
      state.loading = true
    },
    [getLocationDetails.fulfilled]: (state, { payload }) => {
      state.loading = false
      // state.updateFollowdata = payload
    },
    [getLocationDetails.rejected]: (state) => {
      state.loading = false
    },
  },
})


export const { clearHeaderDetails } = headerSlice.actions

export const headerReducer = headerSlice.reducer
