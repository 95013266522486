import {
  Box,
  Grid,
  Typography,
  Button,
  Container,
  Stack,
  Pagination,
  PaginationItem,
  Card,
  CardContent,
  Snackbar,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import SocialShare from "../../components/SocialShare";
import DiscountStepCard from "./DiscountStepCard";
import DiscountCard from "./DiscountCard";
import {
  backgroundColor01,
  blackColor,
  blackColor01,
  borderCard,
  borderDashedFreeContent,
  freeContentBgColor,
  greenColor01,
  greenColor05,
  greyShade61,
  greyShade62,
  greyShade68,
  headingColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Slider from "react-slick/lib/slider";
import {
  dealsDiscounts_ShareMgs,
  dealsDiscounts_SubTitle,
  dealsDiscounts_Title,
  dealsEmptyImgUrl,
  siteBaseURL,
} from "configs/Constants";
import { addTimelineShare } from "services/api_calls/header/HeaderApi";
import { getUserName } from "utils/authHelpers";
import { CopyAll as CopyIcon } from "@mui/icons-material";
import moment from "moment";
import DisCountCard from "./DiscountCard";
import EmptyCard from "components/EmptyCard";
import CounponCardsLoader from "components/skeletonLoaders/discountCoupons/CounponCardsLoader";
import ShareCouponInfoLoader from "components/skeletonLoaders/discountCoupons/ShareCouponInfoLoader";
// import DisCountCard from 'pages/DealsCoupons/DiscountCard'
const DiscountComponent = ({ dealsCouponsDetails, dealData, loading }) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    rows: 2,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // variableWidth: true,
        },
      },
    ],
  };

  const [couponList, setCouponList] = useState(dealData.slice(0, 4));
  const [currentPage, setCurrentPage] = useState(1);
  const couponCount = Math.ceil(dealData.length / 4);
  const businessName = sessionStorage.getItem("businessName");

  const handlePagination = (event, value) => {
    const deals = dealData.slice(value * 4 - 4, value * 4);
    setCouponList(deals);
    setCurrentPage(value);
  };

  const handleCopy = (code) => {
    navigator.clipboard.writeText(code);
    alert(`Coupon code "${code}" copied!`);
  };
  const [loggedIn, setIsLoggedIn] = useState(
    getUserName().length === 0 ? false : true
  );

  const [windowurl, setWindowurl] = useState("");

  useEffect(() => {
    const url = window.location.href;
    setWindowurl(url);
  }, []);

  // const formatData = () => {
  //   // const formattedList = []
  //   // count count = 0
  //   for(let i=0; i < dealData.length; i++){
  //     if(i>Math.ceil(dealData/2)) return
  //     console.log('FORMAT_DEAL: ', i*2);
  //     console.log('FORMAT_DEAL: ', i*2+1);
  //   }
  // }

  // formatData()
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (dealData?.length > 0) {
      setCouponList(dealData?.slice(0, 4));
    }
    return () => {};
  }, [dealData]);

  return (
    <Container sx={styles.container}>
      

      {!loading && dealData.length > 0 && (
        <Grid
          container
          columnSpacing={{ md: 0, xs: 1 }}
          rowSpacing={{ md: 0, xs: 1 }}
        >
          {/* loading: {JSON.stringify(loading)} <br /> */}
          {/* dealData: {JSON.stringify(dealData)} <br />
        couponList: {JSON.stringify(couponList)} <br /> */}
          <Grid item xs={12} md={8.25}>
            <Stack>
              <Typography
                variant="h2"
                fontSize={{ md: "16px", xs: "16px" }}
                color={headingColor}
                pt={"6px"}
              >
                {dealsDiscounts_Title}
              </Typography>
              <Typography
                variant="caption"
                fontSize={{ md: "13px", xs: "13px" }}
                marginBottom="15px !important"
                mt={"5px"}
                color={blackColor01}
              >
                {dealsDiscounts_SubTitle}
              </Typography>
            </Stack>

            {couponList && (
              <Grid
                container
                rowSpacing={matches ? 3 : 3}
                columnSpacing={matches ? 0 : 3}
                display={{ md: "flex", xs: "none" }}
                ml={-2}
              >
                {couponList.map((deal, index) => {
                  return (
                    <Grid item xs={12} sm={5.9505}>
                      <Snackbar
                        open={success}
                        onClose={() => setSuccess(false)}
                        message="Coupon code copied"
                        autoHideDuration={3000}
                      />
                      {!matches && <DiscountCard data={deal} index={index} />}
                      {matches && (
                        <CouponCard
                          key={`coupon_${index}`}
                          percentageOff={deal?.discountPercentage}
                          couponCode={deal?.resellerCode}
                          expiryDate={deal?.codeValidityEnd}
                          index={index}
                          // isCopied={isCopiedIndex == index}
                          handleCopy={() =>
                            handleCopy(deal?.resellerCode, index)
                          }
                        />
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            )}

            <Box display={{ xs: "block", md: "none" }}>
              <Slider {...settings}>
                {dealData?.map((deal, index) => {
                  return (
                    <Grid
                      container
                      xs={12}
                      key={index}
                      direction="column"
                      spacing={2}
                    >
                      <Grid item>
                        <DiscountCard index={index} data={deal} />
                      </Grid>
                    </Grid>
                  );
                })}
              </Slider>
            </Box>

            {/* ---Pagination--- */}
            {couponCount > 1 ? (
              <Stack
                spacing={2}
                my={4}
                display={{ md: "flex", xs: "none" }}
                alignItems="center"
                justifyContent="center"
              >
                <Pagination
                  count={couponCount}
                  page={currentPage}
                  onChange={handlePagination}
                  color={blackColor}
                  renderItem={(item) => (
                    <PaginationItem
                      components={{
                        next: NextBtn,
                        previous: PreviousBtn,
                      }}
                      {...item}
                    />
                  )}
                />
              </Stack>
            ) : null}
            {/* ---Pagination End--- */}
          </Grid>
          <Grid item xs={12} md={3.75} mb={3}>
            <Stack sx={{ mb: "13px !important" }}>
              <Typography
                variant="subtitle1"
                color={blackColor}
                fontSize={13.5}
                fontWeight={500}
                marginBottom="10px !important"
                pt={8}
              >
                {dealsDiscounts_ShareMgs}
              </Typography>
              <SocialShare
                back={() => {
                  if (loggedIn)
                    addTimelineShare({
                      shareType: "timelineshare",
                      latitude: 77.65563062,
                      longitude: 12.92352506,
                    });
                }}
                // url={`${siteBaseURL}${businessName}/dealsCoupons`}
                url={`${windowurl}`}
                iconSize={"17px"}
                quote="My product"
              />
            </Stack>
            <DiscountStepCard />
          </Grid>
        </Grid>
      )}

      {loading === false &&
        dealsCouponsDetails !== null &&
        dealsCouponsDetails.length === 0 ? (
          <>
            <Typography
              variant="h2"
              fontSize="16px"
              color={headingColor}
              pt={1}
            >
              Deals & Coupons
            </Typography>
            <Box m="20px 0 40px 0">
              <EmptyCard
                message={"No Active Deals/Coupons."}
                imageUrl={dealsEmptyImgUrl}
                iconWidth={"60.77px"}
                iconHeignt={"66.27px"}
                description={
                  "No Active Deals/Coupons. Please check back later!"
                }
              />
            </Box>
          </>
        ) : loading && <Grid
        container
        columnSpacing={{ md: 0, xs: 1 }}
        rowSpacing={{ md: 0, xs: 1 }}
      >
        <Grid item xs={12} md={8.25}>
          <CounponCardsLoader />
        </Grid>
        <Grid item xs={12} md={3.75} mb={3}>
          <ShareCouponInfoLoader />
        </Grid>
      </Grid>}
    </Container>
  );
};
export default DiscountComponent;

const styles = {
  container: {
    maxWidth: "1350px !important",
    px: `15px !important`,
  },
  btnHover: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  couponCard: {
    borderRadius: "12px",
    border: `1px solid ${greyShade68}`,
    bgcolor: whiteColor,
    // boxShadow: "0 2px 4px 0 rgb(234 234 239)",
    position: "relative",
    "&::before": {
      content: "''",
      width: "12px",
      height: "23px",
      bgcolor: backgroundColor01,
      position: "absolute",
      top: "54%",
      left: "-1px",
      borderRight: borderCard,
      borderRadius: "0 80px 80px 0",
      boxShadow: "inset -4px 0 5px -5px rgba(0, 0, 0, .2), -4px 0 0 0 #f3f3f8",
    },
    "&::after": {
      content: "''",
      width: "12px",
      height: "23px",
      bgcolor: backgroundColor01,
      position: "absolute",
      top: "54%",
      right: "-1px",
      borderLeft: borderCard,
      borderRadius: "80px 0 0 80px",
      boxShadow: "inset 4px 0 5px -5px rgba(0, 0, 0, .2), 4px 0 0 0 #f3f3f8",
    },
  },

  discountIcon: {
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "12px",
    bgcolor: whiteColor,
  },

  offerTag: {
    width: "48px",
    height: "48px",
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.25,
    color: whiteColor,
    bgcolor: greenColor05,
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  copyBtn: {
    color: greenColor05,
    fontWeight: 600,
    fontSize: 12.5,
    p: 0,
    textTransform: "uppercase",
    "&:hover": {
      bgcolor: whiteColor,
    },
  },
};

const PreviousBtn = () => {
  return (
    <Button startIcon={<ArrowBackIcon />} sx={styles.btnHover}>
      Prev
    </Button>
  );
};

const NextBtn = () => {
  return (
    <Button endIcon={<ArrowForwardIcon />} sx={styles.btnHover}>
      Next
    </Button>
  );
};

const CouponCard = ({
  percentageOff,
  couponCode,
  expiryDate,
  handleCopy,
  isCopied,
}) => {
  return (
    <Box px={"15px"}>
      <Box sx={styles.couponCard}>
        <Box p="15px 20px 15px">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Box sx={styles.offerTag}>
                {percentageOff}%
                <br />
                OFF
              </Box>

              <Box>
                <Typography
                  fontSize={13}
                  fontWeight={400}
                  color={blackColor}
                  lineHeight="20px"
                >
                  Deals of this week
                </Typography>

                <Typography
                  fontSize={13.5}
                  fontWeight={600}
                  lineHeight="22px"
                  color={greenColor05}
                >
                  Get extra {percentageOff}% Off
                </Typography>
              </Box>
            </Box>

            <Box textAlign="right">
              <Typography
                fontSize={11}
                fontWeight={400}
                color={blackColor}
                lineHeight="20px"
              >
                Expiry
              </Typography>
              <Typography
                fontSize={12}
                fontWeight={600}
                color={blackColor}
                lineHeight="20px"
              >
                {expiryDate
                  ? moment(expiryDate)?.format("MMM Do, YYYY")
                  : "N/A"}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box p="12px 15px" borderTop={`1px dashed ${greyShade62}`}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography fontSize={13} fontWeight={400} color={blackColor}>
              Code: <span style={{ fontWeight: 600 }}>{couponCode}</span>
            </Typography>

            <Button variant="text" sx={styles.copyBtn} onClick={handleCopy}>
              {isCopied ? "Copied" : "Copy Code"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
