import { Box, Button, Grid, Typography } from '@mui/material'
import { blackColor25, whiteColor } from 'configs/styles/muiThemes'
import moment from 'moment'

function FinboxBanner({
  title,
  msg,
  msg2,
  onClick,
  bgColor,
  img,
  btnName,
  avlAmt,
  minAmt,
  overdueAmt,
  date,
}) {
  return (
    <Box
      sx={{
        background: bgColor,
        backgroundSize: '100% 100%',
        width: { md: '40%', xs: '90%' },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        borderRadius: '12px',
        mt: { md: 4, xs: 1.5 },
        mb:{md:4,xs:1.5},
        p: '14px 5px',
        mx: 'auto',
        position: 'relative',
      }}
      onClick={onClick}
      //   component='a'
      //   href='https://mail.google.com/mail/u/0/?fs=1&to=credits@costbo.com&tf=cm'
      //   target={'_blank'}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          md={2.5}
          display={{ md: 'flex', xs: 'none' }}
          justifyContent='center'
        >
          <Box
            component='img'
            src={img}
            width={{ md: '90px', xs: '55px' }}
            sx={{ mb: { xs: '5px', md: 0 } }}
          />
        </Grid>
        <Grid item xs={12} md={9.5} display='flex' alignItems='center'>
          <Box width={'100%'} mx={'10px'}>
            <Typography
              color={whiteColor}
              fontSize={{ md: 20, xs: 16 }}
              fontWeight={600}
              textAlign={{ xs: 'center', md: 'start' }}
              lineHeight={1.5}
              mt={1}
              // sx={{ mb: '20px !important' }}
            >
              {title}
            </Typography>
            {avlAmt ? (
              <Box
                display='flex'
                flexWrap='wrap'
                gap={{ md: 2, xs: 0 }}
                flexDirection={{ md: 'row', xs: 'column' }}
              >
                <Typography
                  color={whiteColor}
                  fontSize={{ md: 14, xs: 12 }}
                  fontWeight={400}
                  textAlign={{ xs: 'center', md: 'start' }}
                  lineHeight={1.5}
                  mt={{ md: '8px', xs: '8px' }}
                >
                  Available credit: ₹{avlAmt}
                </Typography>
                <Typography
                  color={whiteColor}
                  fontSize={{ md: 14, xs: 12 }}
                  fontWeight={400}
                  textAlign={{ xs: 'center', md: 'start' }}
                  lineHeight={1.5}
                  mt={{ md: '8px', xs: '4px' }}
                >
                  Minimum order amount: ₹{minAmt}
                </Typography>
              </Box>
            ) : null}

            {msg ? (
              <Typography
                color={whiteColor}
                fontSize={{ md: 14, xs: 12 }}
                fontWeight={400}
                textAlign={{ xs: 'center', md: 'start' }}
                lineHeight={1.5}
                mt={{ md: '8px', xs: '8px' }}
              >
                {msg}
              </Typography>
            ) : null}
            {msg2 ? (
              <Typography
                color={whiteColor}
                fontSize={{ md: 11.5, xs: 10.5 }}
                fontWeight={400}
                textAlign={{ xs: 'center', md: 'start' }}
                lineHeight={1.5}
                mt={{ md: '8px', xs: '8px' }}
              >
                {msg2}
              </Typography>
            ) : null}
            {overdueAmt ? (
              <Box
                display='flex'
                flexWrap='wrap'
                gap={{ md: 2, xs: 0 }}
                flexDirection={{ md: 'row', xs: 'column' }}
              >
                <Typography
                  color={whiteColor}
                  fontSize={{ md: 14, xs: 12 }}
                  fontWeight={400}
                  textAlign={{ xs: 'center', md: 'start' }}
                  lineHeight={1.5}
                  mt={{ md: '8px', xs: '8px' }}
                >
                  Amount: ₹{overdueAmt}
                </Typography>
                <Typography
                  color={whiteColor}
                  fontSize={{ md: 14, xs: 12 }}
                  fontWeight={400}
                  textAlign={{ xs: 'center', md: 'start' }}
                  lineHeight={1.5}
                  mt={{ md: '8px', xs: '8px' }}
                >
                  Due date: {moment(date).format('DD-MM-YYYY')}
                </Typography>
              </Box>
            ) : null}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: { md: 'flex-start', xs: 'center' },
              }}
            >
              <Button sx={styles.btn}>{btnName}</Button>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          display='flex'
          alignItems='flex-end'
          justifyContent={'flex-end'}
          sx={{ display: { md: 'none', xs: 'block' }, mt: 2 }}
        >
          <Typography
            color={whiteColor}
            fontSize={{ md: 11, xs: 10 }}
            fontWeight={400}
            textAlign='right'
            lineHeight={1.5}
            px={1}
          >
            Powered by CostBo
          </Typography>
        </Grid>
      </Grid>
      <Box sx={styles.poweredText}>
        <Typography
          color={whiteColor}
          fontSize={{ md: 11, xs: 10 }}
          fontWeight={400}
          // textAlign='center'
          lineHeight={1.5}
        >
          Powered by CostBo
        </Typography>
      </Box>
      {/* <Typography
        color={whiteColor}
        fontSize={{ md: 11, xs: 10 }}
        fontWeight={400}
        // textAlign='center'
        lineHeight={1.5}
      >
        Powered by CostBo
      </Typography> */}
    </Box>
  )
}

export default FinboxBanner

const styles = {
  btn: {
    background: whiteColor,
    fontSize: "12px",
    color: blackColor25,
    p: "3px 20px",
    mt: 1.8,
    fontWeight: 600,
    border: "1px solid #fff",
    textTransform: "uppercase",
    "&:hover": {
      color: whiteColor,
    },
  },
  poweredText: {
    position: "absolute",
    right: "16px",
    bottom: "11px",
    display: { md: "block", xs: "none" },
  },
};
