import { Box, Skeleton, Stack } from '@mui/material'
import { borderColor, whiteColor } from 'configs/styles/muiThemes'
import React from 'react'

const OrderCardLoader = () => {
  return (
    <Box sx={{ height:'165px', bgcolor: borderColor, borderRadius:'12px', overflow:'hidden', mb:'20px'}}>
        <Box display='flex' alignItems='center' bgcolor={borderColor} p='8px 10px'>
            <Box display='flex' alignItems='center' gap='12px' width="33%">
                <Skeleton variant="rounded" width="36px" height="36px" sx={{ borderRadius:'50px'}} />

                <Box>
                    <Skeleton variant="rounded" width="145px" height="11px" sx={{ mb:'10px !important' }} />
                    <Skeleton variant="rounded" width="140px" height="10px" />
                </Box>
            </Box>

            <Stack alignItems='center' width='22%'>
                <Skeleton variant="rounded" width="90px" height="10px" sx={{ mb:'8px !important' }} />
                <Skeleton variant="rounded" width="60px" height="10px" />
            </Stack>

            <Stack alignItems='center' width='30%'>
                <Skeleton variant="rounded" width="90px" height="10px" sx={{ mb:'8px !important' }} />
                <Skeleton variant="rounded" width="60px" height="10px" />
            </Stack>

            <Skeleton variant="rounded" width="60px" height="10px" sx={{m:'0 12px 0 auto !important'}} />
        </Box>

        <Box display='flex' alignItems='center' justifyContent='space-between' height='115px' mx={3}>
            <Skeleton variant="rounded" width="475px" height="66px" />

            <Box display='flex' alignItems='center' gap={1}>
                <Skeleton variant="rounded" width="114px" height="31px" />
                <Skeleton variant="rounded" width="97px" height="31px" />
            </Box>
        </Box>
    </Box>
  )
}

export default OrderCardLoader