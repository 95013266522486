import { Box, Grid, Skeleton } from '@mui/material'
import React from 'react'

const CounponCardsLoader = () => {
  return (
    <Box mr={4}>
       <Skeleton variant="rounded" width="315px" height='16px' sx={{ mt:'6px !important' }} />
       <Skeleton variant="rounded" width="285px" height='14px' sx={{ m:'10px 0 20px !important' }} />

       <Grid container spacing='30px'>
            <Grid item md={6}>
               <Skeleton variant="rounded" width="auto" height="126px" sx={{borderRadius:'12px'}} />
            </Grid>
            <Grid item md={6}>
               <Skeleton variant="rounded" width="auto" height="126px" sx={{borderRadius:'12px'}} />
            </Grid>
            <Grid item md={6}>
               <Skeleton variant="rounded" width="auto" height="126px" sx={{borderRadius:'12px'}} />
            </Grid>
            <Grid item md={6}>
               <Skeleton variant="rounded" width="auto" height="126px" sx={{borderRadius:'12px'}} />
            </Grid>
       </Grid>
    </Box>
  )
}

export default CounponCardsLoader