import { Box, Grid, IconButton, Stack, useMediaQuery } from "@mui/material";
import { noProductImgUrl } from "configs/Constants";
import {
  bgColor,
  blackColor,
  greenColor,
  greyShade07,
  greyShade65,
  orangeColor01,
  whiteColor,
} from "../../configs/styles/muiThemes";
import ShareIcon from "@mui/icons-material/Share";
import { useEffect, useState } from "react";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import AffiliateShareModal from "pages/Affiliates/AffiliateShareModal";
import SocialShareVertical from "components/SocialShareVertical";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

export default function ProductImages({
  onImageClick,
  productImages,
  activeImg,
  onActiveClick,
  copyLink,
  isCopied,
  handleChatClick,
  businessAffiliateData,
  rellerProfileData,
  productDetails,
  commercialDetails,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  // const [activeImg, setActiveImg] = useState(productImages?.[0]?.docURL)

  // useEffect(() => {
  //   setActiveImg(productImages?.[0]?.docURL)
  // }, [productImages])

  const [showShareOptions, setShowShareOptions] = useState(false);
  const [windowurl, setWindowurl] = useState("");

  useEffect(() => {
    const url = window.location.href;
    setWindowurl(url);
  }, []);

  let businessFullName = sessionStorage.getItem("businessFullName");

  return matches ? (
    <>
      <Grid
        container
        // spacing={1}
        backgroundColor={{ xs: whiteColor }}
        borderRadius={"10px"}
      >
        <Grid item xs={12} borderRadius={"10px"} border={`1px solid #e8e8f0`}>
          <Box
            component="div"
            sx={{
              position: "relative",
              display: "inline-block",
              width: "100%",
              height: { xs: "250px", md: "340px" },
            }}
          >
            <Box
              component="img"
              src={activeImg}
              alt={businessFullName + " CostBo"}
              backgroundColor={whiteColor}
              sx={{
                width: "100%",
                height: { xs: "250px", md: "340px" },
                // boxShadow: '0px 1px 3px rgb(0 0 0 / 6%)',
                borderRadius: "10px",
                objectFit: "contain",
                cursor: "pointer",
                // objectFit: "cover",
                // py: 0.5,
                // px: 7,
                // border:`1px solid #e8e8f0`,
                transition: "all 0.3s ease-in-out",
                transform: "scale(1)",
                "&:hover": {
                  transform: "scale(0.97)",
                },
              }}
              onClick={() => onImageClick()}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = noProductImgUrl;
              }}
            />
            <IconButton
              // onClick={() => setShowShareOptions('he')} // replace this with your share function
              sx={{
                position: "absolute",
                top: 14,
                right: 14,
                backgroundColor: "#f3f3f8",
                boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)",
                borderRadius: "50px",
                textTransform: "uppercase",
                // '&:hover': {
                //   backgroundColor: 'lightgray',
                // },
              }}
            >
              {isCopied ? (
                <>
                  <TaskAltIcon
                    sx={{
                      color: orangeColor01,
                      fontSize: { md: 18, xs: 16 },
                    }}
                  />
                  <Box
                    component="span"
                    sx={{
                      fontSize: 13,
                      fontWeight: 500,
                      color: orangeColor01,
                      ml: "5px",
                      display: { md: "inline", xs: "none" },
                      textTransform: "unset",
                    }}
                  >
                    Copied
                  </Box>
                </>
              ) : (
                <ShareIcon
                  // onClick={copyLink}
                  onClick={() => {
                    setShowShareOptions(true);
                  }}
                  sx={{
                    color: blackColor,
                    cursor: "pointer",
                    fontSize: { md: 18, xs: 16 },
                    mb: "1px",
                  }}
                />
              )}

              {showShareOptions ? (
                <Box position="absolute" bottom="-50px" zIndex={10}>
                  {commercialDetails?.info?.costboPowered?.affiliateEnabled &&
                  businessAffiliateData?.businessResellers?.length > 0 &&
                  rellerProfileData ? (
                    <AffiliateShareModal
                      productDetails={productDetails}
                      commissionDetails={businessAffiliateData}
                      rellerProfileData={{ details: rellerProfileData }}
                      open={showShareOptions}
                      onClose={() => {
                        setShowShareOptions(() => false);
                      }}
                      isProductShare
                    />
                  ) : (
                    <SocialShareVertical
                      // url={`${siteBaseURL}${businessName}`}
                      url={`${windowurl}`}
                      quote=""
                      back={() => {
                        setShowShareOptions(false);
                      }}
                      showCopy
                      onCopyClick={() => {
                        copyLink();
                        setShowShareOptions(false);
                      }}
                    />
                  )}
                </Box>
              ) : null}
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        // spacing={1}
        // backgroundColor={{ xs: whiteColor }}
        borderRadius={"10px"}
        mt={2.5}
      >
        <Grid item xs={12}>
          <Box mx={{ xs: 2, md: 0 }}>
            <Grid container spacing={{ xs: 2, md: 1 }}>
              {productImages?.map((item, i) => {
                return (
                  <Grid item xs={3}>
                    <Box
                      component="img"
                      src={item.docURL}
                      alt={businessFullName + " CostBo"}
                      onClick={() => onActiveClick(item.docURL)}
                      backgroundColor={whiteColor}
                      sx={{
                        width: { xs: "100%", md: "85px" },
                        height: { xs: "70px", md: "85px" },
                        border:
                          activeImg === item.docURL
                            ? "1.5px solid" + blackColor
                            : `1px solid ${greyShade65}`,
                        // boxShadow: '0px 1px 3px rgb(0 0 0 / 6%)',
                        borderRadius: "10px",
                        // p: 0.5,
                        objectFit: "contain",
                        cursor: "pointer",
                        // objectFit: "cover",
                        transition: "all 0.3s ease-in-out",
                        transform: "scale(1)",
                        "&:hover": {
                          transform: "scale(0.97)",
                        },
                      }}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = noProductImgUrl;
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  ) : (
    <Grid container spacing={1} backgroundColor={{ xs: whiteColor }}>
      <Grid item xs={12}>
        <Box
          component="div"
          sx={{
            position: "relative",
            display: "inline-block",
            width: "100%",
            height: "250px",
          }}
        >
          <Box
            component="img"
            src={activeImg}
            alt={businessFullName + " CostBo"}
            backgroundColor={whiteColor}
            sx={{
              width: "100%",
              height: { xs: "250px", md: "340px" },
              // boxShadow: '0px 1px 3px rgb(0 0 0 / 6%)',
              borderRadius: "10px",
              objectFit: "contain",
              cursor: "pointer",
              // objectFit: "cover",
              // py: 0.5,
              // px: 7,
              transition: "all 0.3s ease-in-out",
              transform: "scale(1)",
              "&:hover": {
                transform: "scale(0.97)",
              },
            }}
            onClick={() => onImageClick()}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = noProductImgUrl;
            }}
          />
          <Stack
            gap="10px"
            sx={{
              position: "absolute",
              top: 10,
              right: 14,
            }}
          >
            <IconButton
              sx={{
                backgroundColor: bgColor,
                width: "30px",
                height: "30px",
                borderRadius: "50px",
                textTransform: "uppercase",
              }}
            >
              {isCopied ? (
                <>
                  <TaskAltIcon
                    sx={{
                      color: greenColor,
                      fontSize: 18,
                    }}
                  />
                  <Box
                    component="span"
                    sx={{
                      fontSize: 13,
                      fontWeight: 500,
                      color: orangeColor01,
                      ml: "5px",
                      display: { md: "inline", xs: "none" },
                      textTransform: "unset",
                    }}
                  >
                    Copied
                  </Box>
                </>
              ) : (
                <ShareIcon
                  // onClick={copyLink}
                  onClick={() => {
                    setShowShareOptions(true);
                  }}
                  sx={{
                    color: blackColor,
                    cursor: "pointer",
                    fontSize: 18,
                    mb: "1px",
                  }}
                />
              )}

              {showShareOptions ? (
                <Box
                  position="absolute"
                  bottom="-50px"
                  right={0}
                  zIndex={10}
                  // sx={{ border: "1px solid" + blackColor }}
                >
                  {commercialDetails?.info?.costboPowered?.affiliateEnabled &&
                  businessAffiliateData?.businessResellers?.length > 0 &&
                  rellerProfileData ? (
                    <AffiliateShareModal
                      productDetails={productDetails}
                      commissionDetails={businessAffiliateData}
                      rellerProfileData={{ details: rellerProfileData }}
                      open={showShareOptions}
                      onClose={() => {
                        setShowShareOptions(() => false);
                      }}
                      isProductShare
                    />
                  ) : (
                    <SocialShareVertical
                      // url={`${siteBaseURL}${businessName}`}
                      url={`${windowurl}`}
                      quote=""
                      back={() => {
                        setShowShareOptions(false);
                      }}
                      showCopy
                      onCopyClick={() => {
                        copyLink();
                        setShowShareOptions(false);
                      }}
                    />
                  )}
                </Box>
              ) : null}
            </IconButton>
            <IconButton
              sx={{
                backgroundColor: bgColor,
                width: "30px",
                height: "30px",
                borderRadius: "50px",
                textTransform: "uppercase",
              }}
              onClick={handleChatClick}
            >
              <QuestionAnswerIcon
                sx={{
                  color: blackColor,
                  cursor: "pointer",
                  fontSize: 18,
                  mb: "1px",
                }}
              />
            </IconButton>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mx={{ xs: 2, md: 0 }} mb={0.7}>
          <Grid container spacing={{ xs: 2, md: 1 }}>
            {productImages?.map((item, i) => {
              return (
                <Grid item xs={3}>
                  <Box
                    component="img"
                    src={item.docURL}
                    alt={businessFullName + " CostBo"}
                    onClick={() => onActiveClick(item.docURL)}
                    backgroundColor={whiteColor}
                    sx={{
                      width: "100%",
                      height: { xs: "70px", md: "100px" },
                      border:
                        activeImg === item.docURL
                          ? "1.5px solid" + orangeColor01
                          : `1px solid rgba(0,0,0,0.09)`,
                      // boxShadow: '0px 1px 3px rgb(0 0 0 / 6%)',
                      borderRadius: "10px",
                      // p: 0.5,
                      objectFit: "contain",
                      cursor: "pointer",
                      // objectFit: "cover",
                      transition: "all 0.3s ease-in-out",
                      transform: "scale(1)",
                      "&:hover": {
                        transform: "scale(0.97)",
                      },
                    }}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = noProductImgUrl;
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
