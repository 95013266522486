import { Box, Button, Typography } from "@mui/material";
import {
  blackColor,
  borderBottomLine2,
  greenColor03,
  greyShade48,
  homeSubTextColor,
  orangeColor03,
  orangeColor01,
  whiteColor,
} from "configs/styles/muiThemes";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const BrandInfoCard = ({ businessDetails, setShowLogin }) => {
  const businessName = sessionStorage.getItem("businessName");
  const navigate = useNavigate();

  const { rewardpoints } = useSelector((state) => state.checkout);
  const { isUserLogin } = useSelector((state) => state.auth);
  const { commercialDetails } = useSelector((state) => state.product);

  const [isCopied, setIsCopied] = useState(false);

  const handleCopyLink = (copyLink) => {
    const BUSINESS_LINK =
      commercialDetails?.info?.costboPowered?.domainURL ||
      `https://shop.costbo.com/${businessDetails?.costboWebURL}`;
    navigator.clipboard.writeText(BUSINESS_LINK);

    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <Box sx={styles.card}>
      <Box
        component="img"
        src={businessDetails?.documentsURL?.[0]?.docURL}
        sx={styles.bannerImg}
      />

      <Box p="0 50px 0 0">
        <Box borderBottom={borderBottomLine2} mb="18px">
          <Typography
            variant="h4"
            fontSize={24}
            fontWeight={600}
            color={blackColor}
            lineHeight="35px"
            mb="10px !important"
          >
            {businessDetails?.businessName}
          </Typography>

          <Typography
            variant="body1"
            fontSize={13.5}
            fontWeight={400}
            lineHeight="23px"
            color={greyShade48}
            mb="15px !important"
            textAlign={"justify"}
          >
            {businessDetails?.description?.length > 400
              ? `${businessDetails?.description?.slice(0, 400)}...`
              : businessDetails?.description}
          </Typography>
        </Box>

        <Box sx={styles.actionBtnContainer}>
          <Box>
            <Typography sx={styles.label}>Available Cashback</Typography>

            {!isUserLogin ? (
              <Button
                variant="contained"
                disableElevation
                sx={styles.actionBtn(orangeColor03)}
                onClick={() => {
                  setShowLogin(true);
                }}
              >
                Login to Check
              </Button>
            ) : (
              <>
                {rewardpoints ? (
                  rewardpoints !== undefined &&
                  rewardpoints?.details?.length > 0 ? (
                    <Typography
                      variant="body2"
                      component="p"
                      sx={styles.rewardPtText}
                    >
                      ₹{" "}
                      {rewardpoints?.details?.[0].availablePointValue?.toFixed(
                        2
                      )}
                    </Typography>
                  ) : (
                    <Typography
                      variant="body2"
                      component="p"
                      sx={styles.rewardPtText}
                    >
                      ₹ 0
                    </Typography>
                  )
                ) : null}
              </>
            )}
          </Box>

          <Box>
            <Typography sx={styles.label}>Brand Affiliate Program</Typography>

            <Button
              variant="contained"
              disableElevation
              sx={styles.actionBtn(blackColor)}
              onClick={() => {
                navigate(`/${businessName}/affiliates`);
              }}
            >
              Share & Earn
            </Button>
          </Box>
        </Box>

        <Box
          display="flex"
          gap="7px"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            handleCopyLink();
          }}
        >
          <Typography
            fontSize={13}
            fontWeight={400}
            color={blackColor}
            lineHeight="18px"
          >
            {commercialDetails?.info?.costboPowered?.domainURL ||
              `https://shop.costbo.com/${businessDetails?.costboWebURL}`}
          </Typography>

          {!isCopied ? (
            <Box
              component="img"
              src="https://storage.googleapis.com/bodefaults/shopweb/affiliates/copy.svg"
              sx={{ width: "15px", mt: -0.8 }}
            />
          ) : (
            <Box display="flex" alignItems="center" gap={0.4} mt={-0.3}>
              <CheckCircleOutlineIcon
                sx={{ fontSize: "18px", color: greenColor03 }}
              />
              <Typography fontSize={12} fontWeight={500} color={greenColor03}>
                Copied
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BrandInfoCard;

const styles = {
  card: {
    display: "flex",
    alignItems: "center",
    gap: "40px",
    borderRadius: "15px",
    bgcolor: whiteColor,
    overflow: "hidden",
    mt: "30px",
  },

  bannerImg: {
    width: "511px",
    height: "315px",
    borderRadius: "15px",
    transition: "all ease 0.3s",
    "&:hover": {
      transform: "scale(0.95)",
    },
  },

  label: {
    fontSize: 13,
    fontWeight: 400,
    color: blackColor,
    lineHeight: "20px",
    mb: "5px !important",
  },

  actionBtnContainer: {
    display: "flex",
    alignItems: "flex-start",
    gap: "30px",
    pb: "20px",
    mb: "20px",
    borderBottom: borderBottomLine2,
  },

  actionBtn: (color) => ({
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "18px",
    color: whiteColor,
    bgcolor: color,
    textTransform: "uppercase",
    p: "8px 20px",
    border: `1px solid ${color}`,
    borderRadius: "8px",

    "&:hover": {
      color: color,
      bgcolor: whiteColor,
    },
  }),

  rewardPtText: {
    fontSize: 18,
    fontWeight: 600,
    color: orangeColor01,
    my: 0.8,
  },
};
