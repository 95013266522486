import { Button, Divider, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import GetAppModalCard from "components/GetAppModalCard";
import ModalCard from "components/ModalCard";
import React, { useEffect, useState } from "react";
import { getUserName } from "utils/authHelpers";
import {
  blackColor,
  filterMobileColor,
  greenColor03,
  greenColor05,
  headingColor,
  offerTextColor,
  orangeColor01,
  savingTextColor,
  topbarColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import CartQuantityPicker from "../Cart/CartQuantityPicker";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IosShareIcon from "@mui/icons-material/IosShare";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import AffiliateShareModal from "pages/Affiliates/AffiliateShareModal";
import SocialShareVertical from "components/SocialShareVertical";
import { getDiscountPercentage } from "utils/utilities";
// const QuantityPicker = React.lazy(() => import("./QuantityPicker"));

export default function ProductDescriptionBody({
  productOriginalPrice,
  productSalePrice,
  percentageOff,
  min,
  max,
  isOutOfStock,
  onQtyUpdate,
  productQty,
  onAddProduct,
  onRemoveProduct,
  subscribe,
  availability,
  subscription,
  handleChatClick,
  commercialDetails,
  businessAffiliateData,
  rellerProfileData,
  productDetails,
  copyLink,
  isCopied,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [showShareOptions, setShowShareOptions] = useState(false);
  const [windowurl, setWindowurl] = useState("");

  const commerceInfo = JSON.parse(sessionStorage.getItem("commerceInfo"));
  const commerceEnable = commerceInfo
    ? commerceInfo?.info?.commerceEnable
    : true;

  const [loggedIn, setIsLoggedIn] = useState(
    getUserName().length === 0 ? false : true
  );
  const [isModal, setIsModal] = useState(false);

  useEffect(() => {
    const url = window.location.href;
    setWindowurl(url);
  }, []);

  return matches ? (
    <Box mt={1}>
      <Box mb={{ md: 3, xs: 0 }}>
        <Box display="flex" mt={{ md: 2, xs: 0 }}>
          {productOriginalPrice !== productSalePrice ? (
            <Typography
              variant="h4"
              color={offerTextColor}
              fontSize={15}
              fontWeight={400}
              display="inline"
              sx={{ textDecorationLine: "line-through" }}
              mr={1.2}
            >
              ₹ {productOriginalPrice}
            </Typography>
          ) : null}

          <Typography
            variant="h2"
            color={headingColor}
            display="inline"
            component="p"
            fontSize={16}
            mr={1.2}
          >
            ₹ {productSalePrice}
          </Typography>
          {productOriginalPrice !== productSalePrice ? (
            <Box
              sx={{
                fontSize: "12px",
                lineHeight: "21px",
                padding: "0px 9px",
                borderRadius: "5px",
                color: "#e56b46",
                background: "#e56b461c",
                textAlign: "center",
                fontWeight: 600,
                position: "relative",
                bottom: "2px",
              }}
              px={2}
              display="flex"
              alignItems="center"
              borderRadius="5px"
            >
              <Typography color={orangeColor01} fontSize={12} fontWeight={600}>
                {getDiscountPercentage(productSalePrice, productOriginalPrice)}%
                OFF
              </Typography>
            </Box>
          ) : null}
        </Box>

        <Typography
          variant="body1"
          fontSize={12.5}
          pt={{ xs: "10px", md: "5px" }}
          fontWeight={500}
          color={{ xs: greenColor03, md: greenColor05 }}
          lineHeight="25px"
        >
          (Inclusive of all taxes)
        </Typography>
      </Box>
      {/* <Divider sx={{ my: "15px !important" }} /> */}

      {!subscribe ? (
        isOutOfStock || !commerceEnable ? (
          <Typography
            variant="body1"
            color={
              isOutOfStock ? "rgb(226 62 29 / 85%)" : "rgb(15 190 83 / 85%)"
            }
            fontSize="18px"
            fontWeight={500}
            textAlign="left"
          >
            {isOutOfStock ? "Out of Stock" : "Coming Soon"}
          </Typography>
        ) : (
          <Box>
            {productQty > 0 ? (
              <CartQuantityPicker
                onIncrement={() => {
                  if (productQty < max && productQty >= min) {
                    onQtyUpdate(productQty + 1);
                  }
                }}
                onDecrement={() => {
                  if (productQty > min && productQty <= max) {
                    onQtyUpdate(productQty - 1);
                  } else {
                    onRemoveProduct();
                  }
                }}
                minQty={min}
                maxQty={max}
                count={productQty}
                removeEnabled
              />
            ) : (
              <Button
                fullWidth
                variant="contained"
                sx={{
                  backgroundColor: blackColor,
                  fontSize: 12.5,
                  textTransform: "uppercase",
                  width: { md: "26%", xs: "50px" },
                  height: 37,
                  fontWeight: 600,
                  borderRadius: "7px",
                  padding: "0px 10px",
                  border: `1px solid ${blackColor}`,
                  "&:hover": {
                    backgroundColor: whiteColor,
                    color: blackColor,
                  },
                }}
                onClick={() => {
                  onAddProduct();
                }}
              >
                Add to Cart
              </Button>
            )}
          </Box>
        )
      ) : null}

      {subscribe ? (
        !availability ? (
          <Typography
            variant="body1"
            color={"rgb(226 62 29 / 85%)"}
            fontSize="18px"
            fontWeight={500}
            textAlign="left"
          >
            Subscribe Only
          </Typography>
        ) : (
          <Box>
            {productQty > 0 ? (
              <CartQuantityPicker
                onIncrement={() => {
                  if (productQty < max && productQty >= min) {
                    onQtyUpdate(productQty + 1);
                  }
                }}
                onDecrement={() => {
                  if (productQty > min && productQty <= max) {
                    onQtyUpdate(productQty - 1);
                  } else {
                    onRemoveProduct();
                  }
                }}
                minQty={min}
                maxQty={max}
                count={productQty}
                removeEnabled
              />
            ) : (
              <Button
                fullWidth
                variant="contained"
                sx={{
                  backgroundColor: blackColor,
                  width: "40%",
                  color: whiteColor,
                  border: "1px solid #000000",
                  padding: "0px 10px",
                  lineHeight: "14px",
                  height: "37px",
                  fontSize: "12.5px",
                  marginTop: "0px",
                  borderRadius: "7px",
                  fontWeight: "600",
                  textTransform: "uppercase",
                  "&:hover": {
                    backgroundColor: whiteColor,
                    color: blackColor,
                  },
                }}
                onClick={() => {
                  onAddProduct();
                }}
              >
                Add to Cart
              </Button>
            )}
          </Box>
        )
      ) : null}

      {subscribe ? (
        <Typography
          color={blackColor}
          fontSize={16}
          fontWeight={500}
          sx={{ cursor: "pointer", mt: { md: 3, xs: 1.8 } }}
          onClick={() => setIsModal(true)}
        >
          Click to subscribe for ₹ {subscription?.offerValue}
        </Typography>
      ) : null}
      {/* <Grid container spacing={2}>
        <Grid item xs={5}>
          <Grid container>
            <Grid item xs>
              <Typography mt={1.5} variant='subtitle1' color={offerTextColor}>
                Qty
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Box>
                <Select
                  fullWidth
                  variant='standard'
                  sx={{
                    border: borderTextbox,
                    borderRadius: '6px',
                    fontSize: '13px',
                    padding: '8px 10px',
                    textAlign: 'center !important',
                  }}
                  defaultValue={1}
                  disableUnderline
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                </Select>
              </Box>
              <Box display='flex' justifyContent='space-between'>
                <Typography color={headingColor} fontWeight={600} fontSize={13}>
                  Min {min}
                </Typography>
                <Typography color={headingColor} fontWeight={600} fontSize={13}>
                  Min {max}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={7}>
          
        </Grid>
      </Grid> */}
      {/* <Divider sx={{ my: "20px !important" }} /> */}
      {/* <Typography variant='h6' fontWeight={500} color={offerTextColor} pb={1}>
        Share on:
      </Typography>

      {businessAffiliateData?.businessResellers?.length > 0 &&
      rellerProfileData ? (
        <AffiliateShareMenu
          url={shortShareLink}
          onClick={(sharemedium) => {
            onAffiliateShareClick(sharemedium)
          }}
          hideLabel
          quote={`${productName} at ₹${productSalePrice}\n\nGET additional ${businessAffiliateData?.businessResellers?.[0]?.affiliatePercentage}% OFF on all products \nUse below link for instant discount!`}
          whatsappShareRef={whatsappShareRef}
          faceBookShareRef={faceBookShareRef}
          twitterShareRef={twitterShareRef}
          linkedInShareRef={linkedInShareRef}
          telegramShareRef={telegramShareRef}
        />
      ) : (
        <SocialShareVertical
          url={url}
          quote='My product'
          back={() => {
            if (loggedIn)
              addTimelineShare({
                shareType: 'timelineshare',
                latitude: 77.65563062,
                longitude: 12.92352506,
              })
          }}
        />
      )} */}

      <ModalCard
        handleClose={() => setIsModal(false)}
        open={isModal}
        width="40%"
      >
        <GetAppModalCard />
      </ModalCard>
    </Box>
  ) : (
    <Box mt={1} p="15px 15px 0px 15px">
      <Box
        // pb="12px"
        // borderBottom={`1px dashed rgba(0,0,0,0.10)`}
        alignItems="center"
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Box>
            <Box display="flex" alignItems="center">
              <Typography
                variant="h2"
                color={headingColor}
                display="inline"
                component="p"
                mr={1.2}
                fontSize={{ md: 22, xs: 18 }}
                fontWeight={600}
                lineHeight={1}
              >
                ₹ {productSalePrice}
              </Typography>

              {productOriginalPrice !== productSalePrice ? (
                <Typography
                  fontSize={{ md: 17, xs: 14 }}
                  variant="h4"
                  color={offerTextColor}
                  fontWeight={400}
                  display="inline"
                  sx={{ textDecorationLine: "line-through" }}
                  mr={0.8}
                  lineHeight={1}
                >
                  ₹ {productOriginalPrice}
                </Typography>
              ) : null}

              {productOriginalPrice !== productSalePrice ? (
                <Box
                  sx={{
                    backgroundColor: { md: "primary.main", xs: "#ebfaf4" },
                  }}
                  p="4px 10px"
                  display="flex"
                  alignItems="center"
                  borderRadius="12px"
                >
                  <Typography
                    color={{ md: whiteColor, xs: "#00d395" }}
                    fontSize={13}
                    fontWeight={600}
                    lineHeight="15px"
                  >
                    {getDiscountPercentage(
                      productSalePrice,
                      productOriginalPrice
                    )}
                    % OFF
                  </Typography>
                </Box>
              ) : null}
            </Box>
            <Typography
              variant="body1"
              fontSize={{ xs: 12, md: 13 }}
              fontWeight={400}
              color={savingTextColor}
              lineHeight="25px"
            >
              (Inclusive of all taxes)
            </Typography>
          </Box>
          <Box>
            {!subscribe ? (
              isOutOfStock || !commerceEnable ? (
                <Typography
                  variant="body1"
                  color={
                    isOutOfStock
                      ? "rgb(226 62 29 / 85%)"
                      : "rgb(15 190 83 / 85%)"
                  }
                  fontSize={{ xs: 15, md: 18 }}
                  fontWeight={500}
                  textAlign="left"
                >
                  {isOutOfStock ? "Out of Stock" : "Coming Soon"}
                </Typography>
              ) : (
                <Box>
                  {productQty > 0 ? (
                    <CartQuantityPicker
                      onIncrement={() => {
                        if (productQty < max && productQty >= min) {
                          onQtyUpdate(productQty + 1);
                        }
                      }}
                      onDecrement={() => {
                        if (productQty > min && productQty <= max) {
                          onQtyUpdate(productQty - 1);
                        } else {
                          onRemoveProduct();
                        }
                      }}
                      minQty={min}
                      maxQty={max}
                      count={productQty}
                      removeEnabled
                    />
                  ) : (
                    <Button
                      fullWidth
                      variant="contained"
                      sx={styles.addBtn}
                      onClick={() => {
                        onAddProduct();
                      }}
                    >
                      {matches ? "Add to Cart" : "Add"}
                    </Button>
                  )}
                </Box>
              )
            ) : null}

            {subscribe ? (
              !availability ? (
                <Typography
                  variant="body1"
                  color={"rgb(226 62 29 / 85%)"}
                  fontSize="18px"
                  fontWeight={500}
                  textAlign="left"
                >
                  Subscribe Only
                </Typography>
              ) : (
                <Box>
                  {productQty > 0 ? (
                    <CartQuantityPicker
                      onIncrement={() => {
                        if (productQty < max && productQty >= min) {
                          onQtyUpdate(productQty + 1);
                        }
                      }}
                      onDecrement={() => {
                        if (productQty > min && productQty <= max) {
                          onQtyUpdate(productQty - 1);
                        } else {
                          onRemoveProduct();
                        }
                      }}
                      minQty={min}
                      maxQty={max}
                      count={productQty}
                      removeEnabled
                    />
                  ) : (
                    <Button
                      fullWidth
                      variant="contained"
                      sx={{
                        backgroundColor: blackColor,
                        color: whiteColor,
                        py: { md: 1.3, xs: 0.5 },
                        width: { md: "250px", xs: "130px" },
                        textTransform: "uppercase",
                        borderRadius: "7px",
                        "&:hover": {
                          backgroundColor: whiteColor,
                          color: blackColor,
                        },
                      }}
                      onClick={() => {
                        onAddProduct();
                      }}
                    >
                      Add to Cart
                    </Button>
                  )}
                </Box>
              )
            ) : null}
          </Box>
        </Box>

        {matches && (
          <Box
            display="flex"
            gap={1}
            mt={{ xs: 1, md: 1.5 }}
            alignItems="center"
            sx={{ cursor: "pointer" }}
            onClick={handleChatClick}
          >
            <Typography
              // component='a'
              // href={

              // }
              target="_blank"
              textAlign="center"
              fontSize={{ md: 14, xs: 13 }}
              fontWeight={500}
              sx={{
                color: topbarColor,
              }}
            >
              Inquire about this product
            </Typography>

            <QuestionAnswerOutlinedIcon
              sx={{
                cursor: "pointer",
                fontSize: { md: 18, xs: 18 },
              }}
            />
          </Box>
        )}
      </Box>

      {/* {!matches && (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box
            display="flex"
            gap={1}
            mt={{ xs: 1, md: 1.5 }}
            alignItems="center"
            position="relative"
          >
            <Typography
              textAlign="center"
              fontSize={{ md: 14, xs: 13 }}
              fontWeight={500}
              color={blackColor}
              onClick={() => {
                setShowShareOptions(true);
              }}
            >
              Share Product Links
            </Typography>

            {isCopied ? (
              <>
                <TaskAltIcon
                  sx={{
                    color: greenColor03,
                    fontSize: { md: 18, xs: 16 },
                  }}
                />
                <Box
                  component="span"
                  sx={{
                    fontSize: 13,
                    fontWeight: 500,
                    color: blackColor,
                    ml: "-5px",
                    display: { md: "inline", xs: "none" },
                  }}
                >
                  Copied
                </Box>
              </>
            ) : (
              <ContentCopyIcon
                onClick={() => {
                  setShowShareOptions(true);
                }}
                sx={{
                  cursor: "pointer",
                  fontSize: { md: 18, xs: 16 },
                  mb: "1px",
                }}
              />
            )}

            {showShareOptions ? (
              <Box position="absolute" bottom="-50px" zIndex={10}>
                {commercialDetails?.info?.costboPowered?.affiliateEnabled &&
                businessAffiliateData?.businessResellers?.length > 0 &&
                rellerProfileData ? (
                  <AffiliateShareModal
                    productDetails={productDetails}
                    commissionDetails={businessAffiliateData}
                    rellerProfileData={{ details: rellerProfileData }}
                    open={showShareOptions}
                    onClose={() => {
                      setShowShareOptions(false);
                    }}
                    isProductShare
                  />
                ) : (
                  <SocialShareVertical
                    // url={`${siteBaseURL}${businessName}`}
                    url={`${windowurl}`}
                    quote=""
                    back={() => {
                      setShowShareOptions(false);
                    }}
                    showCopy
                    onCopyClick={() => {
                      copyLink();
                      setShowShareOptions(false);
                    }}
                  />
                )}
              </Box>
            ) : null}
          </Box>

          <ActionLink
            onClick={handleChatClick}
            label="Inquire this product"
            icon={
              <QuestionAnswerOutlinedIcon
                sx={{
                  cursor: "pointer",
                  fontSize: { md: 18, xs: 18 },
                  color: blackColor,
                }}
              />
            }
          />
        </Box>
      )} */}
      {subscribe ? (
        <Typography
          color={blackColor}
          fontSize={16}
          fontWeight={500}
          sx={{ cursor: "pointer", mt: { md: 3, xs: 1.8 } }}
          onClick={() => setIsModal(true)}
        >
          Click to subscribe for ₹ {subscription?.offerValue}
        </Typography>
      ) : null}

      {/* <Typography variant='h6' fontWeight={500} color={offerTextColor} pb={1}>
        Share on:
      </Typography>

      {businessAffiliateData?.businessResellers?.length > 0 &&
      rellerProfileData ? (
        <AffiliateShareMenu
          url={shortShareLink}
          onClick={(sharemedium) => {
            onAffiliateShareClick(sharemedium)
          }}
          hideLabel
          quote={`${productName} at ₹${productSalePrice}\n\nGET additional ${businessAffiliateData?.businessResellers?.[0]?.affiliatePercentage}% OFF on all products \nUse below link for instant discount!`}
          whatsappShareRef={whatsappShareRef}
          faceBookShareRef={faceBookShareRef}
          twitterShareRef={twitterShareRef}
          linkedInShareRef={linkedInShareRef}
          telegramShareRef={telegramShareRef}
        />
      ) : (
        <SocialShareVertical
          url={url}
          quote='My product'
          back={() => {
            if (loggedIn)
              addTimelineShare({
                shareType: 'timelineshare',
                latitude: 77.65563062,
                longitude: 12.92352506,
              })
          }}
        />
      )} */}

      <ModalCard
        handleClose={() => setIsModal(false)}
        open={isModal}
        width="40%"
      >
        <GetAppModalCard />
      </ModalCard>
    </Box>
  );
}

const ActionLink = ({ onClick, label, icon }) => {
  return (
    <Box
      display="flex"
      gap={0.8}
      mt={{ xs: 1, md: 1.5 }}
      alignItems="center"
      sx={{ cursor: "pointer" }}
      onClick={onClick}
    >
      <Typography
        textAlign="center"
        fontSize={{ md: 14, xs: 13 }}
        fontWeight={500}
        color={blackColor}
      >
        {label}
      </Typography>

      {icon}
    </Box>
  );
};

const styles = {
  addBtn: {
    textTransform: "uppercase",
    backgroundColor: {
      md: filterMobileColor,
      xs: blackColor,
    },
    width: { md: "250px", xs: "100px" },
    "&:hover": {
      backgroundColor: {
        md: filterMobileColor,
        xs: blackColor,
      },
    },
  },
};
