import { Box, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import {
  blackShade20,
  greyShade42,
  orangeColor01,
} from "../../../configs/styles/muiThemes";

function VerticalTimeline({ data }) {
  return (
    <Box mt={1}>
      {data?.map((item, index) => {
        return (
          <Box
            display="flex"
            alignItems="center"
            height="95px"
            gap="10px"
            key={`tracking_scan_${index}`}
          >
            <Box sx={styles.connector}>
              {index !== 0 && <Box sx={styles.connectorLineTop} />}
              <Box sx={styles.connectorCircle} />
              {index !== data?.length - 1 && (
                <Box sx={styles.connectorLineBottom} />
              )}
            </Box>

            <Box>
              <Typography
                variant="h5"
                fontSize={14}
                fontWeight={600}
                color={blackShade20}
                mb="2px !important"
              >
                {item?.ScanDetail?.Scan}
              </Typography>

              <Typography
                variant="h6"
                fontSize={12}
                fontWeight={400}
                color={greyShade42}
                lineHeight="16px"
              >
                {item?.ScanDetail?.ScannedLocation}
              </Typography>

              <Typography
                variant="body1"
                fontSize={11}
                fontWeight={500}
                color={orangeColor01}
                mb="-3px !important"
              >
                {item?.ScanDetail?.Instructions}
              </Typography>

              <Typography
                variant="body1"
                fontSize={11}
                fontWeight={400}
                color={greyShade42}
                mb="3px !important"
              >
                {moment(item?.ScanDetail?.ScanDateTime).format(
                  "hh:mm:ss, MMM DD, YYYY"
                )}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  )
}

export default VerticalTimeline

const styles = {
  connector: {
    width: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },

  connectorLineTop: {
    height: '40px',
    borderRight: `1px dashed ${greyShade42}`,
    position: 'absolute',
    top: '-40px',
  },

  connectorCircle: {
    width: '14px',
    height: '14px',
    borderRadius: '10px',
    border: `1.9px solid ${blackShade20}`,
  },

  connectorLineBottom: {
    height: '40px',
    borderRight: `1px dashed ${greyShade42}`,
    position: 'absolute',
    bottom: '-40px',
  },
}
