import { Box, Grid, Skeleton, Stack } from "@mui/material";
import { whiteColor } from "configs/styles/muiThemes";
import React from "react";

const ReviewCardLoader = () => {
  return (
    <Box mt={0}>
      <Grid container spacing={{ xs: 1.5, md: 2.5 }}>
        <Grid item xs={12}  md={6}>
            <ReviewCardSkeleton />
        </Grid>
        <Grid item xs={12}  md={6}>
            <ReviewCardSkeleton />
        </Grid>
        <Grid item xs={12}  md={6}>
            <ReviewCardSkeleton />
        </Grid>
        <Grid item xs={12}  md={6}>
            <ReviewCardSkeleton />
        </Grid>
        <Grid item xs={12}  md={6}>
            <ReviewCardSkeleton />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReviewCardLoader;

const ReviewCardSkeleton = ()=>{
    return <Box bgcolor={whiteColor} borderRadius='12px' p='15px' height="120px">
        <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Box display='flex' alignItems='center' gap={2}>
                <Skeleton variant="rounded" width="40px" height="40px" sx={{ borderRadius:'50px'}} />

                <Box>
                    <Skeleton variant="rounded" width="160px" height="13px" sx={{ mb:'10px !important' }} />
                    <Skeleton variant="rounded" width="320px" height="13px" sx={{ mb:'8px !important' }} />
                </Box>
            </Box>

            <Stack alignItems='flex-end'>
                <Skeleton variant="rounded" width="70px" height="12px" sx={{ mb:'10px !important' }} />
                <Skeleton variant="rounded" width="40px" height="10px" sx={{ mb:'8px !important' }} />
            </Stack>
        </Box>

        <Box mt={2}>
            <Skeleton variant="rounded" width="270px" height="12px" sx={{ mb:'10px !important' }} />
            <Skeleton variant="rounded" width="100%" height="10px" sx={{ m:'15px 0 8px !important' }} />
            <Skeleton variant="rounded" width="70%" height="10px" sx={{ mb:'8px !important' }} />
        </Box>
    </Box>
}