import { Box, Grid, keyframes, Typography } from '@mui/material'
import { blackColor, whiteColor } from 'configs/styles/muiThemes'
import React from 'react'

const ReturnInfo = ({ commercialDetails }) => {
  const marqueX = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
  `

  const textLength =
    (commercialDetails?.info?.returnPolicy?.length || 0) +
    (commercialDetails?.info?.notes?.length || 0)

  return (
    <Box mt={-1} pt={0}>
      <Typography
        variant="h5"
        fontSize={16}
        fontWeight={600}
        lineHeight="25px"
        color={blackColor}
      >
        Return Policy & Special Info
      </Typography>

      <Box
        sx={{
          bgcolor: blackColor,
          mt: "10px",
          borderRadius: "10px",
          p: "10px 15px",
        }}
      >
        <Grid container alignItems="center">
          <Grid item>
            <Box
              component="img"
              src="https://storage.googleapis.com/bodefaults/shopweb/supportANDFooter/return-policy.svg"
              sx={{
                width: "30px",
                height: "30px",
                objectFit: "contain",
                mb: -0.65,
              }}
            />
          </Grid>
          <Grid item xs={11}>
            <Box display={"flex"}>
              <Box
                sx={{height:"20px", borderLeft: `1px solid ${whiteColor}` }}
                ml={2}
                mt={0.6}
              />
              <Box sx={styles.textInfoBox}>
                <Typography
                  sx={{
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    animation:
                      textLength > 100
                        ? `${marqueX} 30s linear infinite`
                        : "none",
                    animationDirection: "reverse",
                  }}
                >
                  {commercialDetails?.info?.returnPolicy ? (
                    <Typography variant="subtitle1" sx={styles.textStyle}>
                      • {commercialDetails?.info?.returnPolicy}
                    </Typography>
                  ) : null}

                  {commercialDetails?.info?.notes ? (
                    <Typography variant="subtitle1" sx={styles.textStyle}>
                      • {commercialDetails?.info?.notes}
                    </Typography>
                  ) : null}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default ReturnInfo

const styles = {
  textStyle: {
    fontSize: 13,
    component: 'p',
    color: whiteColor,
    fontWeight: 500,
    display: 'inline',
    pr: 3,
  },

  textInfoBox: {
    width: '100%',
    overflow: 'hidden',
    m: '0px 0px 5px 16px',
    p: '0 0 0 10px',
    position: 'relative',
    pl:1,
    '&::after': {
      content: "''",
      position: 'absolute',
      top: '53%',
      left: '0px',
      width: '1px',
      height: '70%',
      // borderLeft: '1px solid rgb(255 255 255 / 66%)',
      transform: 'translateY(-50%)',
    },
  },
}
