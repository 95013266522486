import { Box, Typography } from "@mui/material";
import { Suspense, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  backgroundColor01,
  backgroundColor02,
  backgroundColor03,
  blackColor,
  borderbackgroundColor04,
  borderCard03,
  greenColor01,
  greyShade47,
  greyShade55,
  greyShade57,
  infoAlertBgColor,
  lightGreen02,
  lightGreenShade01,
  offerTextColor,
  whiteColor,
} from "configs/styles/muiThemes";
import { textOneLines, textTwoLines } from "utils/styleUtility";
import { getDiscountPercentage } from "utils/utilities";
// const QuantityPicker = React.lazy(() => import('./QuantityPicker'))

function MobileProductCard({
  img,
  price,
  oldPrice,
  description,
  productDescription,
  discount,
  disableAddtoCart,
  currencySymbol,
  direction,
  mapLength,
  productid,
  rowIndex,
  variantCount,
}) {
  const [isModal, setIsModal] = useState(false);
  const [disableCard, setDisableCard] = useState(false);
  const businessName = sessionStorage.getItem("businessName");

  const { rellerProfileData, businessAffiliateData } = useSelector(
    (state) => state.affiliate
  );
  const [isExpanded, setIsExpanded] = useState(false);

  const handleReadMoreClick = () => {
    setIsExpanded((prev) => !prev);
  };
  // Affiliate Share
  const [openShareModal, setOpenShareModal] = useState({
    show: false,
    data: null,
  });

  return (
    <Suspense fallback={<p></p>}>
      {direction ? (
        <Box
          width={mapLength > 2 ? "calc(100vw - 100px)" : "calc(100vw - 38px)"}
          minHeight="90px"
          maxHeight="100px"
          sx={{
            display: "flex",
            gap: "15px",
            pb: rowIndex == 0 ? "6px" : "8px",
            mb: rowIndex == 0 ? "12px" : "0px",
            borderBottom: rowIndex == 0 ? `1px solid #EAEAF1` : "none",
            cursor: "pointer",
            overflow: "hidden",
            position: "relative",
          }}
        >
          {price !== oldPrice && (
            <Box
              // sx={{
              //   position: "absolute",
              //   overflow: "hidden",
              //   display: "flex",
              //   alignItems: "center",
              //   gap: "4px",
              //   minWidth: "0px",
              //   background: "#10847e",
              //   color: "rgb(255, 255, 255)",
              //   borderRadius: "5px",
              //   lineHeight: "18px",
              //   padding: "1px 5px",
              //   fontWeight: 600,
              //   fontSize: "10px",
              //   textTransform: "capitalize",
              //   zIndex: 9,
              //   top: "0px",
              //   left: "12px",
              // }}
              sx={{
                position: "absolute",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                gap: "4px",
                minWidth: "0px",
                backgroundColor: greenColor01,
                color: "rgb(255, 255, 255)",
                borderRadius: "10px",
                lineHeight: "18px",
                padding: "2px 6px",
                fontWeight: 600,
                fontSize: "11px",
                textTransform: "capitalize",
                zIndex: 9,
                top: 0,
                left: 0,
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "0px",
                borderTopRightRadius: "0px",
              }}
            >
              {" "}
              {getDiscountPercentage(price, oldPrice)}%
            </Box>
          )}

          <Box
            component={Link}
            to={`/${businessName}/singleProduct/${productid}`}
            sx={{
              width: "60px",
              height: "60px",
              border: borderCard03,
              borderRadius: "6px",
              p: "10px",
              position: "relative",
            }}
          >
            <Box
              component="img"
              borderRadius={"6px"}
              src={img}
              alt="CostBo"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                aspectRatio: "4/3",
                // transition: "all 0.3s ease-in-out",
                transform: "scale(1)",
                "&:hover": {
                  transform: "scale(0.97)",
                },
              }}
              // border={`1px solid ${greyShade47}`}
            />
            {variantCount ? (
              <Typography
                sx={{
                  color: blackColor,
                  fontSize: "11px",
                  fontWeight: "500",
                  cursor: "pointer",
                  p: "1px 8px",
                  backgroundColor: greyShade47,
                  borderRadius: "8px 0px 8px 0px",
                  position: "absolute",
                  lineHeight: "18px",
                  bottom: 0,
                  right: 0,
                  zIndex: 3,
                }}
              >
                {variantCount || "X"} options
              </Typography>
            ) : null}
          </Box>
          <Box flex={1}>
            <Typography
              component="p"
              fontSize={{ xs: 12, md: 14 }}
              color={blackColor}
              fontWeight={500}
              lineHeight="18px"
              sx={{ ...textTwoLines }}
              height={"36px"}
            >
              {description}
            </Typography>

            <Box display={"flex"} gap={1} mt="8px !important">
              <Typography
                component="p"
                fontSize={{ xs: 13, md: 14 }}
                color={blackColor}
                fontWeight={600}
                sx={textOneLines}
                lineHeight="16px"
              >
                ₹{price}
              </Typography>

              {/* {discount ? (
                <Typography
                  variant="body1"
                  color={greyShade57}
                  fontSize="12.5px"
                  fontWeight={400}
                  sx={{ textDecorationLine: "line-through" }}
                  display={disableAddtoCart ? "inline" : "block"}
                  lineHeight="16px"
                >
                  {discount
                    ? currencySymbol
                      ? `${currencySymbol}${oldPrice}`
                      : `₹${oldPrice}`
                    : ""}
                </Typography>
              ) : null} */}
            </Box>
            {/* {discount ? (
                <Box
                  sx={{
                    fontSize: "13px",
                    fontWeight: 500,
                    color: lightGreen02,
                    lineHeight: "1.2",
                    p: "4px 0px",
                    mt: "5px",
                  }}
                >
                  {discount}% Off
                </Box>
              ) : (
                ""
              )} */}

            {/* <Typography
              component="p"
              fontSize={{ xs: 11, md: 14 }}
              color={greyShade55}
              fontWeight={400}
              lineHeight="16px"
              mt={discount <= 0 ? "5px !important" : "0px"}
            >
              {isExpanded || productDescription?.length <= 26 ? (
                // Show full description
                <>
                  {productDescription}
                  {productDescription?.length > 26 && (
                    <span
                      onClick={handleReadMoreClick}
                      style={{ color: lightGreen02, cursor: "pointer" }}
                    >
                      {" "}
                      read less
                    </span>
                  )}
                </>
              ) : (
                // Show truncated description with "read more"
                <>
                  {`${productDescription?.slice(0, 26)}... `}
                  <span
                    onClick={handleReadMoreClick}
                    style={{ color: lightGreen02, cursor: "pointer" }}
                  >
                    read more
                  </span>
                </>
              )}
            </Typography> */}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            // width: "calc(50vw - 42px)",
            width: "120px",
            // p: "10px 8px",
            borderRadius: "10px",
            cursor: "pointer",
            overflow: "hidden",
            bgcolor: whiteColor,
          }}
          component={Link}
          to={`/${businessName}/singleProduct/${productid}`}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              height: "98px",
              border: `1px solid ${borderbackgroundColor04}`,
              p: "10px",
              borderRadius: "10px",
              position: "relative",
            }}
          >
            {oldPrice !== price && (
              <Box
                // sx={{
                //   position: "absolute",
                //   overflow: "hidden",
                //   display: "flex",
                //   alignItems: "center",
                //   gap: "4px",
                //   minWidth: "0px",
                //   background: "#10847e",
                //   color: "rgb(255, 255, 255)",
                //   borderRadius: "5px",
                //   lineHeight: "18px",
                //   padding: "1px 5px",
                //   fontWeight: 600,
                //   fontSize: "10px",
                //   textTransform: "capitalize",
                //   zIndex: 9,
                //   top: "0px",
                //   left: "12px",
                // }}
                sx={{
                  position: "absolute",
                  overflow: "hidden",
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  minWidth: "0px",
                  backgroundColor: greenColor01,
                  color: "rgb(255, 255, 255)",
                  borderRadius: "10px",
                  lineHeight: "18px",
                  padding: "2px 6px",
                  fontWeight: 600,
                  fontSize: "11px",
                  textTransform: "capitalize",
                  zIndex: 9,
                  top: 0,
                  left: 0,
                  borderTopLeftRadius: "8px",
                  borderBottomLeftRadius: "0px",
                  borderTopRightRadius: "0px",
                }}
              >
                {" "}
                {getDiscountPercentage(price, oldPrice)}%
              </Box>
            )}

            {variantCount ? (
              <Typography
                sx={{
                  color: blackColor,
                  fontSize: "11px",
                  fontWeight: "500",
                  cursor: "pointer",
                  p: "1px 8px",
                  backgroundColor: greyShade47,
                  borderRadius: "8px 0px 8px 0px",
                  position: "absolute",
                  lineHeight: "18px",
                  bottom: 0,
                  right: 0,
                  zIndex: 3,
                }}
              >
                {variantCount || "X"} options
              </Typography>
            ) : null}

            <Box
              component="img"
              src={img}
              alt="CostBo"
              sx={{
                width: "98px",
                height: "90px",
                p: 0,
                borderRadius: "10px",
                aspectRatio: "4/3",
                // transition: "all 0.3s ease-in-out",
                transform: "scale(1)",
                "&:hover": {
                  transform: "scale(0.97)",
                },
              }}
              // border={`1px solid ${greyShade47}`}
            />
          </Box>
          <Box>
            <Typography
              component="h4"
              fontSize={{ xs: 12, md: 14 }}
              color={blackColor}
              fontWeight={500}
              lineHeight={1.5}
              sx={{ ...textTwoLines }}
              mt={1}
              height={"36px"}
            >
              {description}
              {/* {description?.slice(0, 16)}
              {description?.length > 16 ? '. . .' : ''} */}
            </Typography>

            <Box display={"flex"} gap={0.7} mt="3px">
              <Typography
                component="h1"
                fontSize={{ xs: 13, md: 14 }}
                color={blackColor}
                fontWeight={600}
                sx={textOneLines}
                lineHeight={1.5}
              >
                ₹{price}
              </Typography>

              {oldPrice !== price ? (
                <Typography
                  variant="body1"
                  color={offerTextColor}
                  fontSize="13px"
                  fontWeight={400}
                  lineHeight={1.5}
                  sx={{ textDecorationLine: "line-through" }}
                  mr={1.2}
                  display={disableAddtoCart ? "inline" : "block"}
                >
                  {currencySymbol
                    ? `${currencySymbol}${oldPrice}`
                    : `₹${oldPrice}`}
                </Typography>
              ) : null}
            </Box>

            {/* {discount ? (
              <Box display="flex" justifyContent="flex-start" mt={0.3}>
                <Box sx={styles.percentageOffTag}>{discount}% Off</Box>
              </Box>
            ) : (
              <Box p={1} />
            )} */}
            {/* <Typography
              component="p"
              fontSize={{ xs: 11, md: 14 }}
              color={greyShade55}
              fontWeight={400}
              lineHeight="16px"
              mt={discount <= 0 ? "5px !important" : "0px"}
            >
              {isExpanded || productDescription?.length <= 50 ? (
                // Show full description
                <>
                  {productDescription}
                  {productDescription?.length > 50 && (
                    <span
                      onClick={handleReadMoreClick}
                      style={{ color: lightGreen02, cursor: "pointer" }}
                    >
                      {" "}
                      read less
                    </span>
                  )}
                </>
              ) : (
                // Show truncated description with "read more"
                <>
                  {`${productDescription?.slice(0, 55)}... `}
                  <span
                    onClick={handleReadMoreClick}
                    style={{ color: lightGreen02, cursor: "pointer" }}
                  >
                    read more
                  </span>
                </>
              )}
            </Typography> */}
          </Box>
        </Box>
      )}
    </Suspense>
  );
}

export default MobileProductCard;

const styles = {
  productCard: {
    width: "143px",
    // maxWidth: '173px',
    borderRadius: 1.5,
    backgroundColor: whiteColor,
    border: "1px solid #f2f2f2",
    px: 1.2,
    pt: 1.2,
    pb: 0.5,
    boxShadow: "0px 1px 3px rgb(0 0 0 / 6%)",
    position: "relative",
    transition: "all 0.6s ease",
    "&:hover": {
      boxShadow: "0 8px 15px 0 rgba(0, 0, 0, 0.1)",
    },
  },

  addBtn: {
    width: "28px",
    height: "28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: whiteColor,
    border: "1px solid" + infoAlertBgColor,
    borderRadius: 1.2,
    // padding: '5px',
    // position: 'absolute',
    // right: '0px',
    // bottom: '-12px',
    cursor: "pointer",
  },

  minMaxContainer: {
    width: "100%",
    backgroundColor: "rgb(207 207 207 / 88%)",
    borderRadius: "0 0 6px 6px",
    position: "absolute",
    left: "0px",
    bottom: "0px",
    // py: 0.1,
  },

  offTag: {
    height: "20px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "primary.main",
    borderRadius: "6px",
    lineHeight: "12px",
    p: "1px 8px",
    position: "absolute",
    top: "0px",
    left: "0px",
    zIndex: 1,
  },

  tagUi: {
    width: "16.5px",
    height: "16.5px",
    backgroundColor: "primary.main",
    transform: "rotate(45deg)",
    position: "absolute",
    top: "3px",
    left: "-8px",
    zIndex: "0",
    borderRadius: "3px",
  },

  alertTag: {
    width: "100%",
    // backgroundColor: "rgb(226 62 29 / 85%)",
    borderRadius: "0 0 6px 6px",
    py: 0.3,
    position: "absolute",
    bottom: 0,
    left: 0,
  },

  alertContainer: {
    // width: '100%',
    // height: '148px',
    // backgroundColor: 'rgba(256, 256, 256, 0.8)',
    blur: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // position: 'absolute',
    // top: 0,
  },
  actionBtn: {
    // minWidth: "76px",
    fontSize: 12,
    fontWeight: 600,
    padding: "3px 15px",
    textTransform: "unset",
    borderRadius: "30px",
    backgroundColor: blackColor,
    border: {
      xs: `1px solid ${blackColor}`,
      md: `1px solid ${blackColor}`,
    },
    "&:hover": {
      color: blackColor,
      backgroundColor: whiteColor,
    },
    "&.Mui-disabled": {
      borderColor: "rgba(0,0,0,0.2)",
    },
  },
  percentageOffTag: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "1.2",
    color: lightGreen02,
    bgcolor: lightGreenShade01,
    p: "4px 6px",
    borderRadius: "10px",
  },
};
