import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React, { Suspense, useRef, useState } from "react";
import { AddRounded } from "@mui/icons-material";
import {
  bgColor5,
  blackColor,
  greenColor01,
  greenColor06,
  greyShade47,
  greyShade55,
  greyShade57,
  greyShade58,
  headingColor,
  infoAlertBgColor,
  lightGreen02,
  orangeColor01,
  orangeColor12,
  orangeColor13,
  whiteColor,
} from "../../configs/styles/muiThemes";
import { Link } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import { textOneLines, textTwoLines } from "utils/styleUtility";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ModalCard from "components/ModalCard";
import GetAppModalCard from "components/GetAppModalCard";
import AffiliateShareModal from "pages/Affiliates/AffiliateShareModal";
import ShareIcon from "@mui/icons-material/Share";
import { getDiscountPercentage } from "utils/utilities";

const QuantityPicker = React.lazy(() => import("./QuantityPicker"));

function ProductMobileCard({
  img,
  price,
  oldPrice,
  description,
  productDescription,
  discount,
  isOutOfStock,
  isComingSoon,
  disableAddtoCart,
  minQty,
  maxQty,
  currencySymbol,
  cartData,
  product,
  cartId,
  onQtyUpdate,
  onAddProduct,
  onRemoveProduct,
  subscribe,
  availability,
  subscription,
  direction,
  productid,
  index,
  variantCount,
  variant,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [isModal, setIsModal] = useState(false);
  const [disableCard, setDisableCard] = useState(false);
  const businessName = sessionStorage.getItem("businessName");
  let businessFullName = sessionStorage.getItem("businessFullName");
  const commerceInfo = JSON.parse(sessionStorage.getItem("commerceInfo"));
  const commerceEnable = commerceInfo
    ? commerceInfo?.info?.commerceEnable
    : true;

  const { rellerProfileData, businessAffiliateData } = useSelector(
    (state) => state.affiliate
  );

  const getProductCartCount = (cartList, product) => {
    const isPresent = cartList?.filter((cartProduct) => {
      return cartProduct?.productId === product?.id;
    });

    const cartCount =
      isPresent?.length > 0 ? isPresent?.[0]?.currentQuantity : 0;

    return cartCount;
  };

  const count = getProductCartCount(cartData, product);

  const loading = cartData?.filter((item) => {
    return item?.productId === product?.id;
  });

  const loadingRef = useRef(null);
  const productImgRef = useRef(null);

  const currentDate = new Date();

  const isStartDateAfter = moment(product?.commerceStartDate).isAfter(
    currentDate
  );

  // Affiliate Share
  const [openShareModal, setOpenShareModal] = useState({
    show: false,
    data: null,
  });

  const handleShareProduct = () => {
    console.log("PRODUCT:", product);
    console.log("commissionDetails:", businessAffiliateData);
    console.log("rellerProfileData:", rellerProfileData);

    setOpenShareModal({
      show: true,
      data: {
        productDetails: product,
        commissionDetails: businessAffiliateData,
      },
    });
  };

  return (
    <Suspense fallback={<p></p>}>
      {/* Affiliate Share Modal */}
      {openShareModal?.show && (
        <AffiliateShareModal
          productDetails={openShareModal?.data?.productDetails}
          commissionDetails={openShareModal?.data?.commissionDetails}
          rellerProfileData={rellerProfileData}
          open={openShareModal?.show}
          onClose={() => {
            setOpenShareModal({ show: false, data: null });
          }}
          isProductShare
        />
      )}
      <Box
        sx={{
          px: "15px",
          display: "flex",
          justifyContent: "space-between",
          gap: "15px",
          borderBottom: `1px solid ${greyShade47}`,
          cursor: "pointer",
          overflow: "hidden",
          pb: 3,
          pt: index === 0 ? null : 1.5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            gap: "6px",
          }}
        >
          <Typography
            component="h1"
            fontSize={{ xs: 12, md: 14 }}
            color={blackColor}
            fontWeight={500}
            lineHeight="18px"
            height={"36px"}
            sx={{ ...textTwoLines }}
          >
            {description}
          </Typography>

          <Box display={"flex"} alignItems="center" gap={1}>
            <Typography
              component="h1"
              fontSize={{ xs: 13, md: 14 }}
              color={blackColor}
              fontWeight={600}
              sx={textOneLines}
              lineHeight={1.1}
            >
              ₹{price}
            </Typography>
            {oldPrice !== price ? (
              <Typography
                variant="body1"
                color={greyShade57}
                fontSize="12.5px"
                fontWeight={400}
                sx={{ textDecorationLine: "line-through" }}
                mr={1.2}
                display={disableAddtoCart ? "inline" : "block"}
              >
                {currencySymbol
                  ? `${currencySymbol}${oldPrice}`
                  : `₹${oldPrice}`}
              </Typography>
            ) : null}
          </Box>

          {/* {discount > 0 ? (
            <Box sx={styles.percentageOffTag}>{discount}% Off</Box>
          ) : (
            <Box p={1.5} />
          )} */}

          {/* <Typography
            component="h1"
            fontSize={{ xs: 11, md: 14 }}
            color={greyShade55}
            fontWeight={400}
            lineHeight="16px"
          >
            {productDescription?.length > 50 ? (
              <>
                {`${productDescription?.slice(0, 60)}... `}
                <span style={{ color: lightGreen02 }}>read more</span>
              </>
            ) : (
              `${productDescription}`
            )}
          </Typography> */}
          {!variant && isStartDateAfter && (
            <Box display={"flex"} mt={1}>
              <CalendarMonthIcon
                sx={{ fontSize: "18px", color: greenColor06 }}
              />
              <Typography
                variant="body1"
                color={blackColor}
                fontSize="11px"
                fontWeight={500}
                textAlign="right"
                sx={{ whiteSpace: "pre-wrap" }}
                lineHeight="18px"
              >
                {isStartDateAfter
                  ? `Available - ${moment(product?.commerceStartDate).format(
                      "DD/MM/YYYY"
                    )}`
                  : ""}
              </Typography>
            </Box>
          )}
          {!variant && subscribe ? (
            <Box display={"flex"}>
              <Button
                sx={{
                  // backgroundColor: blackColor,
                  // position: "absolute",
                  // bottom: 0,
                  // cursor: "pointer",
                  // width: "90%",
                  // width: "130px",
                  //position: "absolute",
                  // width:"100%",
                  mb: 1.5,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "8px",
                  minWidth: "0",
                  color: "#fff",
                  borderRadius: "6px",
                  // borderTopLeftRadius: "8px",
                  // borderTopRightRadius: "8px",
                  // borderBottomRightRadius: "8px",
                  padding: "0px 10px",
                  backgroundColor: "#ffede8",
                  textTransform: "uppercase",
                  // "&::after": {
                  //   content: '""',
                  //   position: "absolute",
                  //   top: "96%",
                  //   left: "0px",
                  //   zIndex: 100,
                  //   borderBottom: `5px solid transparent`,
                  //   borderRight: `5px solid #616168;`,
                  // },
                }}
                onClick={() => setIsModal(true)}
              >
                <Typography
                  color={orangeColor01}
                  fontSize={11}
                  fontWeight={400}
                  textAlign="center"
                >
                  Subscribe for ₹ {subscription?.offerValue}
                </Typography>
                {/* <Box
                  component="img"
                  src={`/./assets/images/chevron-right-white.svg`}
                  height="8px"
                  width={"10px"}
                  alt=""
                  sx={{ objectFit: "contain", ml: 0.5 }}
                /> */}
              </Button>
            </Box>
          ) : (
            "" // <Box p={2.2} />
          )}
        </Box>

        <Box
          sx={{
            width: "108px",
            height: "108px",
            borderRadius: "10px",
            // border: '1px solid #EAEAF1',
            // overflow: 'hidden',
            position: "relative",
          }}
        >
          {oldPrice !== price && (
            <Box
              // sx={{
              //   position: "absolute",
              //   overflow: "hidden",
              //   display: "flex",
              //   alignItems: "center",
              //   gap: "4px",
              //   minWidth: "0px",
              //   background: "#10847e",
              //   color: "rgb(255, 255, 255)",
              //   borderRadius: "5px",
              //   lineHeight: "18px",
              //   padding: "1px 5px",
              //   fontWeight: 600,
              //   fontSize: "10px",
              //   textTransform: "capitalize",
              //   zIndex: 9,
              //   top: "0px",
              //   left: "12px",
              // }}
              sx={{
                position: "absolute",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                gap: "4px",
                minWidth: "0px",
                backgroundColor: greenColor01,
                color: "rgb(255, 255, 255)",
                borderRadius: "10px",
                lineHeight: "18px",
                padding: "2px 6px",
                fontWeight: 600,
                fontSize: "11px",
                textTransform: "capitalize",
                zIndex: 9,
                top: 0,
                left: 0,
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "0px",
                borderTopRightRadius: "0px",
              }}
            >
              {" "}
              {getDiscountPercentage(price, oldPrice)}%
            </Box>
          )}
          {commerceInfo?.info?.costboPowered?.affiliateEnabled &&
            businessAffiliateData?.businessResellers?.length > 0 &&
            rellerProfileData?.details && (
              <Box
                sx={{
                  position: "absolute",
                  overflow: "hidden",
                  display: "flex",
                  alignItems: "center",
                  minWidth: "0px",
                  backgroundColor: bgColor5,
                  borderRadius: "10px",
                  padding: "6px 6px",
                  zIndex: 9,
                  top: 0,
                  right: 0,
                  borderTopLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                  cursor: "pointer",
                }}
                onClick={handleShareProduct}
              >
                <ShareIcon sx={{ fontSize: 14, color: blackColor }} />
              </Box>
            )}
          <Box
            component={Link}
            to={`/${businessName}/singleProduct/${productid}`}
          >
            <Box
              component="img"
              maxWidth={{ xs: "108px", md: "108px" }}
              borderRadius={"6px"}
              src={img}
              alt="CostBo"
              sx={{
                width: "108px",
                height: "108px",
                objectFit: "contain",
                overflow: "hidden",
                position: "relative",
                borderRadius: "10px",
                transition: "all 0.3s ease-in-out",
                transform: "scale(1)",
                "&:hover": {
                  transform: "scale(0.97)",
                },
              }}
              border={`1px solid ${greyShade47}`}
            />
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: "-16px",
              right: "8px",
            }}
          >
            {subscribe && !availability && !isOutOfStock ? (
              <Box sx={styles.alertContainer}>
                <Box>
                  <Typography
                    variant="body1"
                    // color={
                    // isOutOfStock
                    //     ? "rgb(226 62 29 / 85%)"
                    //     : "rgb(15 190 83 / 85%)"
                    // }
                    // textAlign="right"
                    // pt="10px !important"
                    sx={{
                      backgroundColor: "#ffffff",
                      color: "#000000",
                      fontSize: "11px",
                      fontWeight: 600,
                      textTransform: "initial",
                      padding: "1px 6px",
                      // m: "2px",
                      borderRadius: "6px",
                      border: "1px solid #c6c6c6",
                      boxShadow: "0px 2px 3px rgb(177 177 190 / 50%)",
                    }}
                    onClick={() => setIsModal(true)}
                  >
                    Subscribe Only
                  </Typography>
                </Box>
              </Box>
            ) : null}
            {isOutOfStock || !commerceEnable ? (
              <Box sx={styles.alertContainer}>
                <Box>
                  <Typography
                    variant="body1"
                    // color={
                    // isOutOfStock
                    //     ? "rgb(226 62 29 / 85%)"
                    //     : "rgb(15 190 83 / 85%)"
                    // }
                    textAlign="right"
                    // pt="10px !important"
                    sx={{
                      backgroundColor: "#fff1f1",
                      color: "#e61010",
                      fontSize: "12px",
                      fontWeight: 600,
                      textTransform: "initial",
                      padding: "2px 6px",
                      borderRadius: "6px",
                      border: "1px solid #fff1f1",
                      boxShadow: "0px 2px 3px rgba(177, 177, 190, 0.5)",
                      position: "relative",
                      // left: "5px",
                    }}
                  >
                    {isOutOfStock ? "Out Of Stock" : "Coming Soon"}
                  </Typography>
                </Box>
              </Box>
            ) : variant ? (
              <Box
                display="flex"
                alignItems="center"
                // justifyContent="flex-end"
                gap={0.5}
                component={Link}
                to={`/${businessName}/singleProduct/${
                  product?.id || productid
                }`}
                // mt={1}
              >
                <Typography
                  sx={{
                    color: blackColor,
                    // textDecoration: "underline",
                    fontSize: "12px",
                    fontWeight: "600",
                    cursor: "pointer",
                    backgroundColor: whiteColor,
                    padding: "1px 8px",
                    m: "2px",
                    borderRadius: "6px",
                    border: "1px solid #c6c6c6",
                    boxShadow: "0px 2px 3px rgb(177 177 190 / 50%)",
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      color: orangeColor01,
                      textDecoration: "underline",
                      fontSize: "12px",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                  >
                    +{Number(variantCount) - 1 || "X"}
                  </Typography>
                  {"  "}
                  Options
                </Typography>
              </Box>
            ) : count > 0 ? (
              <QuantityPicker
                count={count}
                onIncrement={() => {
                  if (loading?.[0]?.loading) return;

                  if (count < maxQty) {
                    onQtyUpdate({
                      cartId: cartId,
                      productId: product?.id,
                      quantity: count + 1,
                    });
                  }
                }}
                onDecrement={() => {
                  if (loading?.[0]?.loading) return;

                  if (count > minQty) {
                    onQtyUpdate({
                      cartId: cartId,
                      productId: product?.id,
                      quantity: count - 1,
                    });
                  } else {
                    onRemoveProduct({
                      productId: product?.id,
                    });
                  }
                }}
                minQty={minQty}
                maxQty={maxQty}
              />
            ) : !disableAddtoCart && availability ? (
              <Box
                //   sx={styles.addBtn}
                sx={{
                  backgroundColor: "#ffffff",
                  color: "#000000",
                  fontSize: "13px",
                  fontWeight: 600,
                  textTransform: "initial",
                  padding: "1px 28px",
                  m: "2px",
                  borderRadius: "6px",
                  border: "1px solid #c6c6c6",
                  boxShadow: "0px 2px 3px rgb(177 177 190 / 50%)",
                }}
                disa
                onClick={() => {
                  if (loading?.[0]?.loading) return;

                  let gstTaxValue = 0;

                  if (product.gstInfo != null) {
                    gstTaxValue = product.gstInfo.gstPercentage.replace(
                      /[&\/\\#,+()$~%.'":*?<>{}]/g,
                      ""
                    );
                  } else {
                    gstTaxValue = 0;
                  }

                  let productAmtWithoutGST =
                    (product.productSalePrice * 100) /
                    (100 + Number(gstTaxValue));
                  let productTaxPerUnit =
                    product.productSalePrice - productAmtWithoutGST;

                  onAddProduct({
                    cartId: cartId,
                    product: {
                      productId: product?.id,
                      productName: product?.productName,
                      productPicURL: img,
                      topicId: "",
                      productOriginalPrice: product?.productOriginalPrice,
                      productSalePrice: product?.productSalePrice,
                      originalQuantity: minQty,
                      currentQuantity: minQty,
                      currencySymbol: "₹",
                      percentageOff: product?.percentageOff,
                      quantityChangedFlag: false,
                      priceChangedFlag: false,
                      productTotalPrice: minQty * product?.productSalePrice,
                      productTax: productTaxPerUnit.toFixed(2),
                      productTaxRate: gstTaxValue,
                      measurementInfo: product?.measurementInfo,
                      catalogPartnerItemInfo:
                        product?.catalogPartnerItemInfo?.[0] || null,
                    },
                    clientType: "web",
                  });
                }}
              >
                {matches ? (
                  <AddRounded sx={{ color: orangeColor01, fontSize: "18px" }} />
                ) : (
                  "Add"
                )}
              </Box>
            ) : null}
          </Box>
        </Box>
        <ModalCard
          handleClose={() => setIsModal(false)}
          open={isModal}
          width="40%"
        >
          <GetAppModalCard />
        </ModalCard>
      </Box>
    </Suspense>
  );
}

export default ProductMobileCard;

// const AlertTag = ({ message, bgColor }) => {
//   return (
//     <Box backgroundColor={bgColor} sx={styles.alertTag}>
//       <Typography
//         variant='body1'
//         color={whiteColor}
//         fontSize='13px'
//         fontWeight={600}
//         textAlign='center'
//       >
//         {message}
//       </Typography>
//     </Box>
//   )
// }

const styles = {
  productCard: {
    width: "143px",
    // maxWidth: '173px',
    borderRadius: 1.5,
    backgroundColor: whiteColor,
    border: "1px solid #f2f2f2",
    px: 1.2,
    pt: 1.2,
    pb: 0.5,
    // boxShadow: '0px 1px 3px rgb(0 0 0 / 6%)',
    position: "relative",
    transition: "all 0.6s ease",
    "&:hover": {
      // boxShadow: '0 8px 15px 0 rgba(0, 0, 0, 0.1)',
    },
  },

  addBtn: {
    width: "28px",
    height: "28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: whiteColor,
    border: "1px solid" + infoAlertBgColor,
    borderRadius: 1.2,
    // padding: '5px',
    // position: 'absolute',
    // right: '0px',
    // bottom: '-12px',
    cursor: "pointer",
  },

  minMaxContainer: {
    width: "100%",
    backgroundColor: "rgb(207 207 207 / 88%)",
    borderRadius: "0 0 6px 6px",
    position: "absolute",
    left: "0px",
    bottom: "0px",
    // py: 0.1,
  },

  offTag: {
    height: "20px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "primary.main",
    borderRadius: "6px",
    lineHeight: "12px",
    p: "1px 8px",
    position: "absolute",
    top: "0px",
    left: "0px",
    zIndex: 1,
  },

  tagUi: {
    width: "16.5px",
    height: "16.5px",
    backgroundColor: "primary.main",
    transform: "rotate(45deg)",
    position: "absolute",
    top: "3px",
    left: "-8px",
    zIndex: "0",
    borderRadius: "3px",
  },

  alertTag: {
    width: "100%",
    // backgroundColor: "rgb(226 62 29 / 85%)",
    borderRadius: "0 0 6px 6px",
    py: 0.3,
    position: "absolute",
    bottom: 0,
    left: 0,
  },

  alertContainer: {
    // width: '100%',
    // height: '148px',
    // backgroundColor: 'rgba(256, 256, 256, 0.8)',
    blur: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // position: 'absolute',
    // top: 0,
  },
  percentageOffTag: {
    width: "50px",
    fontSize: 13,
    fontWeight: 500,
    color: lightGreen02,
    // bgcolor: lightGreenShade01,
    mt: "-6px !important",
  },
};
