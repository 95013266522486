import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import React, { useState } from 'react'
import { blackColor, labelColor } from '../../configs/styles/muiThemes'

function CheckBoxLabel({
  label,
  textSmall,
  subCategory,
  onValuesChanged,
  checked,
}) {
  const [selectedIds, setSelectedIds] = useState([])

  function handleChecked(code) {
    if (selectedIds.includes(code)) {
      const tempIds = selectedIds.filter((item) => item !== code)
      setSelectedIds(tempIds)
      if (onValuesChanged) onValuesChanged(selectedIds)
    } else {
      const tempIds = [...selectedIds]
      tempIds.push(code)
      setSelectedIds(tempIds)
      if (onValuesChanged) onValuesChanged(selectedIds)
    }
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          color='primary'
          // checked={selectedIds.indexOf(subCategory.code) !== -1}
          checked={checked}
          onChange={() => {
            // handleChecked(subCategory.code)
            onValuesChanged(subCategory)
          }}
          sx={{
            borderRadius: 3,
            transform: 'scale(0.7)',
            p:{xs:'4px 8px', md:'4px'},
            '&.Mui-checked': {
              color: blackColor,
              
            },
          }}
        />
      }
      label={
        <Typography
          variant='body1'
          color={{xs: blackColor , md:labelColor}}
          fontSize={textSmall ? '12px' : '14px'}
          fontWeight={400}
        >
          {label}
        </Typography>
      }
    />
  )
}

export default CheckBoxLabel

const styles = {
  // checkbox: {
  //   p: 0,
  //   transform: 'scale(1.3)',
  //   '&.Mui-checked': {
  //     color: greyColor8,
  //   },
  // },
}
