import { Box, Typography } from '@mui/material'
import {
  blackColor,
  categoryLabelSpanColor,
  greyColor9,
  headingColor,
  labelColor,
  productLabelColor,
  searchLabelColor,
} from 'configs/styles/muiThemes'
import moment from 'moment'
import React from 'react'

function VerticalTimeline({ data }) {
  return (
    <Box px={2} mt={1}>
      <ol className='c-timeline' >
        {data?.map((item, i) => {
          return (
            <li className="c-timeline__item" key={i}>
              <div className="c-timeline__content">
                <Typography fontSize={14} fontWeight={500} color={blackColor}>
                  {item?.ScanDetail?.Scan}
                </Typography>
                <Typography fontSize={11} fontWeight={400} color={greyColor9}>
                  {item?.ScanDetail?.ScannedLocation}
                </Typography>
                <Typography
                  // fontSize={13}
                  // fontWeight={500}
                  // color={orangeColor01}
                  sx={{
                    color: "#10847e",
                    fontWeight: 500,
                    fontSize: "11px",
                    lineHeight: "23px",
                    backgroundColor: "#10847e1f",
                    borderRadius: "5px",
                    display: "inline",
                    padding: "2px 8px",
                  }}
                >
                  {item?.ScanDetail?.Instructions}
                </Typography>
                <Typography
                  fontSize={11}
                  fontWeight={400}
                  color={searchLabelColor}
                  sx={{ mb: "15px !important" }}
                >
                  {moment(item?.ScanDetail?.ScanDateTime).format(
                    "hh:mm:ss, MMM DD, YYYY"
                  )}
                </Typography>
              </div>
            </li>
          );
        })}
      </ol>
    </Box>
  )
}

export default VerticalTimeline
