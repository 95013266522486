import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import { Typography, Grid, Box, Button, Container, keyframes } from '@mui/material'
import { blackColor, trackColor, whiteColor } from 'configs/styles/muiThemes'

function HeaderSection() {
  let businessFullName = sessionStorage.getItem('businessFullName')

  const slideInDown = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;
  return (
    <Box sx={styles.headerSection}>
      <Box
        sx={{
          borderBottom: "1px solid #f3f3f8",
          boxShadow: "0 1px 8px rgb(87 87 101 / 7%)",
          WebkitBoxShadow: "0 1px 8px rgb(87 87 101 / 7%)",
          background: "#ffffff",
          position: "sticky",
          zIndex: 999,
          width: "100%",
          top: "0",
          WebkitTransition: "0.2s ease-in",
          OTransition: "0.2s ease-in",
          transition: "0.2s ease-in",
          WebkitAnimationDuration: "0.5s",
          animationDuration: "0.5s",
          WebkitAnimationName: "slideInDown",
          animationName: "slideInDown",
        }}
      >
        <Container maxWidth={"1350px"}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              // position: 'relative',
              justifyContent: "space-between",
              padding: "10px 0px 5px",
              marginRight: { md: "35px", xs: 0 },
              marginLeft: { md: "35px", xs: 0 },
            }}
          >
            <Box
              component="img"
              src={
                "https://storage.googleapis.com/bodefaults/shopweb/home/costbo-logo.svg"
              }
              // src={'https://storage.googleapis.com/bodefaults/shopweb/logo_v3.png'}
              alt={businessFullName + " CostBo"}
              width={{ xs: "110px", md: "150px" }}
              height="43.77px"
              display="block"
              sx={{ m: "10px 0 18px" }}
              // component='a'
              // href='https://www.costbo.com/'
            />
            <Button
              sx={{
                backgroundColor: "#000000",
                border: "1px solid #000000",
                marginTop: "0px",
                fontSize: { md: "15px", xs: "14px" },
                padding: { md: "8px 25px", xs: "2px 8px" },
                borderRadius: "9px",
                color: "#fff",
                fontWeight: 500,
                textTransform: "uppercase",
                "&:hover": {
                  backgroundColor: whiteColor,
                  color: blackColor,
                },
              }}
              onClick={() => {}}
              // component='a'
              href="https://buy.costbo.com/"
            >
              shop@costbo.com
            </Button>
          </Box>
        </Container>
      </Box>

      {/* <Container
        sx={{
          maxWidth: '1300px !important',
        }}
      >
        <Grid container>
          <Grid item md={5.8} xs={12}>
            <Box>
              <Box textAlign={{ xs: 'center', md: 'left' }}>
                <Box
                  component='img'
                  src='https://storage.googleapis.com/bodefaults/shopweb/logo_footer.png'
                  alt={businessFullName + ' CostBo'}
                  width='212px'
                  height='auto'
                />
              </Box>

              <Typography
                variant='h3'
                color={whiteColor}
                fontSize={{ xs: 22, md: 40 }}
                mt={{ xs: '20px', md: '40px' }}
                mb={{ xs: '10px !important', md: '18px !important' }}
                lineHeight={{ xs: '36px', md: '58px' }}
                textAlign={{ xs: 'center', md: 'left' }}
              >
                Enjoy a whole new shopping experience
              </Typography>

              <Typography
                variant='subtitle1'
                color={whiteColor}
                fontSize={{ xs: 16, md: 20 }}
                mt={{ xs: '10px', md: '35px' }}
                mb={{ xs: '0px !important', md: '14px !important' }}
                pr={{ xs: '0%', md: '30%' }}
                lineHeight={{ xs: '27px', md: '36px' }}
                textAlign={{ xs: 'center', md: 'left' }}
              >
                India's leading Direct to Consumer (D2C) network for Brands &
                Stores
              </Typography>

              <Box
                display='flex'
                alignItems='center'
                justifyContent={{ xs: 'center', md: 'flex-start' }}
              >
                <Button
                  sx={styles.actionBtn}
                  variant='contained'
                  disableElevation
                  onClick={() => {}}
                  component='a'
                  href='https://www.costbo.com/'
                  endIcon={<ArrowForwardRoundedIcon fontSize='small' />}
                >
                  shop@costbo.com
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6.2} xs={12}>
            <Box
              display={{ xs: 'none', md: 'flex' }}
              alignItems='flex-end'
              mt='23px'
            >
              <Box
                component='img'
                src='https://storage.googleapis.com/bodefaults/shopweb/landing_shopping_banner_2.jpg'
                alt={businessFullName + ' CostBo'}
                width='220px'
                height='auto'
                sx={{ borderRadius: '25px', mr: 1.5 }}
              />
              <Box
                component='img'
                src='https://storage.googleapis.com/bodefaults/shopweb/landing_shopping_banner_1.jpg'
                alt={businessFullName + ' CostBo'}
                width='409px'
                height='auto'
                sx={{ borderRadius: '25px', ml: 1.5 }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container> */}
    </Box>
  );
}

export default HeaderSection

const styles = {
  headerSection: {
    padding: '0px 0',
    float: 'left',
    width: '100%',
    position: 'fixed',
    borderBottom: '0px solid #B9CAD0',
    backgroundColor: 'transparent',
    zIndex: 1004,
    top: '0px',
    left: '0px',
    marginBottom: '50px',
    // height: { xs: 'auto', md: '690px' },
    // background: 'url(https://storage.googleapis.com/bodefaults/shopweb/home-bg.png) no-repeat center',
    // backgroundSize: '100% 100%',
    // backgroundColor: { xs: trackColor, md: 'transparent' },
    // paddingTop: { xs: '20px', md: '100px' },
    // paddingBottom: { xs: '20px', md: '0px' },
    // marginBottom: '30px',
  },

  actionBtn: {
    fontSize: '15px',
    fontWeight: 600,
    color: whiteColor,
    p: '8px 25px',
    border: '1px solid ' + blackColor,
    textTransform: 'none',
    borderRadius: '5px',
    backgroundColor: blackColor,
    marginTop: '24px',
    '&:hover': {
      backgroundColor: whiteColor,
      color: blackColor,
    },
  },
}
