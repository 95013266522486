import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getResellerProfileData } from 'redux/affiliate/AffiliateSlice'
import {
  addResellerProfileAPI,
  validateAffiliateCodeApi,
} from '../../services/api_calls/affiliate/AffiliateApi'
import { getUserName } from '../../utils/authHelpers'
import ShareEarnHeader from '../ShareAndEarn/ShareEarnHeader'
import AffiliateCodeForm from './AffiliateCodeForm'
import { whiteColor } from 'configs/styles/muiThemes'

function AffiliateEnrollContent({ formNum, isReadOnly }) {
  const { businessName } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { rellerProfileData } = useSelector((state) => state.affiliate)

  const [currentPage, setCurrentPage] = useState(isReadOnly ? formNum : 0)
  const [loading, setLoading] = useState(false)

  const userType = sessionStorage.getItem('userType')

  // Affiliate Code
  // const [name, setName] = useState(getUserName() || '')
  const [name, setName] = useState(
    userType === 'guest' ? '' : getUserName() || ''
  )
  const [affiliateCode, setAffiliateCode] = useState('')
  const [refferedBy, setRefferedBy] = useState('')

  const [nameError, setNameError] = useState('')
  const [codeError, setCodeError] = useState('')
  const [refferedByError, setRefferedByError] = useState('')

  const [isCodeValid, setIsCodeValid] = useState(false)

  useEffect(() => {
    setCurrentPage(isReadOnly ? formNum : 0)
    setName(
      isReadOnly
        ? rellerProfileData?.details?.name
        : userType === 'guest'
        ? ''
        : getUserName() || ''
    )
    setAffiliateCode(isReadOnly ? rellerProfileData?.details?.resellerCode : '')
    setRefferedBy(isReadOnly ? rellerProfileData?.details?.referralCode : '')
    return () => {}
  }, [formNum, rellerProfileData])

  // Affiliate Code Validation
  const isAffiliateCodeValid = async () => {
    if (affiliateCode.length < 6) {
      setCodeError('Affiliate code must be 6 to 30 character')
      setIsCodeValid(false)
      return false
    }

    const response = await validateAffiliateCodeApi(affiliateCode)

    if (response?.isPresent) {
      setCodeError('Affiliate code already exist')
      setIsCodeValid(false)
      return false
    } else {
      setCodeError('')
      setIsCodeValid(true)
      return true
    }
  }

  // Validating Affiliate Code Form and Show Next Form
  const validateAffiliateCodeForm = async () => {
    setNameError('')
    setRefferedByError('')

    if (!name) {
      setNameError('Please enter your name')
      return
    }

    const codeValid = await isAffiliateCodeValid()

    if (!codeValid) {
      return
    }

    if (refferedBy && refferedBy.length < 10) {
      setRefferedByError('Invalid phone number')
      return
    }

    const response = await addResellerProfileAPI({
      code: { name, affiliateCode, refferedBy },
    })

    if (response) {
      // console.log(response);
      dispatch(getResellerProfileData())
      navigate(`/${businessName}/affiliateHome`)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  return (
    <Box backgroundColor={whiteColor} height={"100vh"} mt={1}>
      <ShareEarnHeader
        title='Affiliate Enrollment '
        hideActiveBtn={rellerProfileData?.details ? false : true}
        handleBackClick={() => {
          if (rellerProfileData?.details) {
            navigate(`/${businessName}/affiliateHome`)
          } else {
            navigate(`/${businessName}/affiliates`, {
              state: { disableAutoNav: true },
            })
          }
        }}
        handleSettingClick={() => {}}
        handleSearchClick={() => {}}
         // hideBackBtn
      />

      {currentPage === 0 && (
        <AffiliateCodeForm
          name={name}
          setName={setName}
          affiliateCode={affiliateCode}
          setAffiliateCode={setAffiliateCode}
          refferedBy={refferedBy}
          setRefferedBy={setRefferedBy}
          isCodeValid={isCodeValid}
          // Error Msg
          nameError={nameError}
          codeError={codeError}
          refferedByError={refferedByError}
          setCurrentPage={setCurrentPage}
          onValidateCode={isAffiliateCodeValid}
          handleNext={validateAffiliateCodeForm}
          isReadOnly={isReadOnly}
          loading={loading}
        />
      )}
    </Box>
  )
}

export default AffiliateEnrollContent
