import { Box, Skeleton, Stack } from '@mui/material'
import { whiteColor } from 'configs/styles/muiThemes'
import React from 'react'

const CartItemsLoader = () => {
  return (
    <Box sx={{bgcolor: whiteColor, borderRadius:'10px', p:'20px'}}>
        <Skeleton variant="rounded" width="160px" height="13px" />

        <CartItemSkeleton sx={{mt:4}} />
        <CartItemSkeleton sx={{mt:5}} />
        <CartItemSkeleton sx={{mt:5}} />
        <CartItemSkeleton sx={{mt:5}} />
    </Box>
  )
}

export default CartItemsLoader

const CartItemSkeleton = ({sx})=>(
    <Box sx={{display:'flex', alignItems:'center', gap:'25px', ...sx}}>
        <Skeleton variant="rounded" width="67px" height="80px" />

        <Box flex={1}>
            <Skeleton variant="rounded" width="230px" height="12px" sx={{ mb:'12px !important' }} />
            <Skeleton variant="rounded" width="140px" height="12px"  />
        </Box>

        <Box display='flex' alignItems='center' gap='50px'>
            <Stack alignItems='center'>
                <Skeleton variant="rounded" width="90px" height="11px" sx={{ mb:'8px !important' }} />
                <Skeleton variant="rounded" width="60px" height="10px" sx={{ mb:'8px !important' }} />
                <Skeleton variant="rounded" width="120px" height="10px"  />
            </Stack>

            <Box display='flex' alignItems='center' gap='20px'>
                <Skeleton variant="rounded" width="98px" height="35px"  />
                <Skeleton variant="rounded" width="30px" height="30px" sx={{borderRadius:'50%', mr:1}}  />
            </Box>
        </Box>
    </Box>
)