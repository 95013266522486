import { Box, Grid, Typography, TextField, Button, Chip } from '@mui/material'
import TextboxWithLabel from 'components/TextboxWithLabel'
import {
  blackColor,
  borderTextbox,
  greyShade41,
  greyShade42,
  headingColor,
  orangeColor01,
  redColor,
  whiteColor,
} from "configs/styles/muiThemes";
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import AddressCard from '../AddressCards/AddressCard'
import WrongLocationOutlinedIcon from '@mui/icons-material/WrongLocationOutlined'
import { allowOnlyEnglish } from 'utils/utilities'

const AddressSearchContent = ({
  addressDetails,
  setOpenUpdateAddress,
  onSelectMethod,
  onEditAddress,
  onDeleteAddress,
  seletedAddress,
  handleClose,
}) => {
  const [addressList, setAddressList] = useState(addressDetails)

  const [searchQuery, setSearchQuery] = useState({
    name: '',
    phoneNo: '',
    errors: '',
  })

  const [showClearSearch, setShowClearSearch] = useState({
    show: false,
    query: '',
  })

  const handleUpdateValue = (key, value) => {
    setSearchQuery((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  const handleSearch = () => {
    if (!searchQuery?.name && !searchQuery?.phoneNo) {
      handleUpdateValue('errors', 'Enter Name or Contact Number to search')
      return
    }

    if (searchQuery?.phoneNo?.length > 0 && searchQuery?.phoneNo?.length < 5) {
      handleUpdateValue('errors', 'Enter atleast 5 digits to search')
      return
    }

    let filteredList = []
    handleUpdateValue('errors', null)

    if (searchQuery?.name && searchQuery?.phoneNo) {
      filteredList = addressDetails?.filter(
        (item) =>
          item?.name
            ?.toLowerCase()
            ?.includes(searchQuery?.name?.toLowerCase()) &&
          (item?.phoneNo
            ?.toLowerCase()
            ?.includes(searchQuery?.phoneNo?.toLowerCase()) ||
            item?.alternatePhoneNo
              ?.toLowerCase()
              ?.includes(searchQuery?.phoneNo?.toLowerCase()))
      )

      setAddressList(() => filteredList)
      setShowClearSearch({
        show: true,
        query: 'Clear Search',
      })
      return
    }

    if (searchQuery?.name) {
      filteredList = addressDetails?.filter((item) =>
        item?.name?.toLowerCase()?.includes(searchQuery?.name?.toLowerCase())
      )
    }

    if (searchQuery?.phoneNo) {
      filteredList = addressDetails?.filter(
        (item) =>
          item?.phoneNo
            ?.toLowerCase()
            ?.includes(searchQuery?.phoneNo?.toLowerCase()) ||
          item?.alternatePhoneNo
            ?.toLowerCase()
            ?.includes(searchQuery?.phoneNo?.toLowerCase())
      )
    }

    setAddressList(() => filteredList)
    setShowClearSearch({
      show: true,
      // query: searchQuery?.name || searchQuery?.phoneNo,
      query: 'Clear Search',
    })
  }

  const handleClearSearch = () => {
    setAddressList(() => addressDetails)
    setSearchQuery({
      name: '',
      phoneNo: '',
      errors: '',
    })
    setShowClearSearch({
      show: false,
      query: '',
    })
  }

  useEffect(() => {
    setAddressList(() => addressDetails)
    return () => {}
  }, [addressDetails])

  return (
    <Box
      sx={{ maxHeight: '88vh', overflowY: 'scroll', background: whiteColor }}
      borderRadius={3}
    >
      <Box sx={{ bgcolor: greyShade41, px: { md: 4, xs: 1.5 }, py: 2.5 }}>
        <Typography
          variant='h3'
          textAlign='center'
          fontSize={18}
          color={headingColor}
        >
          Search Address
        </Typography>
      </Box>

      <Box p={2}>
        <Grid container xs={12} spacing={2}>
          <Grid item xs={12} md={12}>
            <TextField
              value={searchQuery?.name}
              fullWidth
              variant='standard'
              sx={styles.inputBox}
              // maxLength={maxLength}
              onChange={({ target }) => {
                const finalValue = allowOnlyEnglish(target.value)
                handleUpdateValue('name', finalValue)
              }}
              placeholder='Search by Name'
              InputProps={{
                disableUnderline: true,
              }}
             
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <TextField
              value={searchQuery?.phoneNo}
              fullWidth
              variant='standard'
              sx={styles.inputBox}
              maxLength={10}
              onChange={({ target }) => {
                const finalValue = allowOnlyEnglish(target.value)
                handleUpdateValue('phoneNo', finalValue)
              }}
              placeholder='Search by Contact No'
              InputProps={{
                disableUnderline: true,
                type: 'number',
              }}
            />

            <Typography
              fontSize={11.5}
              fontWeight={500}
              color={redColor}
              m='5px 0 0 8px'
            >
              {searchQuery?.errors}
            </Typography>
          </Grid>
        </Grid>

        <Box display='flex' justifyContent='space-between' my={2.5}>
          {showClearSearch?.show ? (
            <Chip
              label={showClearSearch?.query || ''}
              onDelete={handleClearSearch}
            />
          ) : (
            <Box />
          )}

          <Button
            variant='outlined'
            disableElevation
            sx={styles.searchBtn}
            onClick={handleSearch}
          >
            SEARCH ADDRESS
          </Button>
        </Box>

        <Box sx={{ maxHeight: '300px', overflowY: 'scroll' }}>
          {addressList?.map((address, i) => {
            return (
              <Box mt={i == 0 ? 1 : 0} mb={2} key={`address_item_${i}`}>
                <AddressCard
                  isAddressSelected={
                    seletedAddress?.addressLine1 === address?.addressLine1 &&
                    seletedAddress?.zip === address?.zip &&
                    seletedAddress?.phoneNo === address?.phoneNo
                  }
                  onEdit={() => setOpenUpdateAddress(true)}
                  address={address}
                  onSelectMethod={() => {
                    onSelectMethod(address)
                  }}
                  onEditAddress={() => {
                    onEditAddress(address)
                  }}
                  onDeleteAddress={() => {
                    onDeleteAddress(address)
                  }}
                  cardStyle={{ mr: 0, mx: 1, bgcolor: whiteColor, p: 1 }}
                  showAlternateNo
                />
              </Box>
            )
          })}

          {showClearSearch?.show && addressList?.length === 0 && (
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
            >
              <WrongLocationOutlinedIcon sx={{ fontSize: '50px' }} />

              <Typography
                variant='body1'
                fontSize={15}
                fontWeight={500}
                color={blackColor}
                mt={2}
              >
                Address Not Found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Box
        bgcolor={greyShade41}
        p={'0 20px'}
        display='flex'
        justifyContent='space-between'
        mt={2.5}
        py={1.5}
      >
        <Button
          variant='outlined'
          disableElevation
          sx={styles.cancelBtn}
          onClick={handleClose}
        >
          CANCEL
        </Button>
        <Button
          variant='contained'
          disableElevation
          sx={styles.submitBtn}
          onClick={handleClose}
        >
          SUBMIT
        </Button>
      </Box>
    </Box>
  )
}

export default AddressSearchContent

const styles = {
  inputBox: {
    border: borderTextbox,
    borderRadius: "5px",
    fontSize: "14px",
    color: "#777",
    background: whiteColor,
    padding: { md: "8px 10px", xs: "4px 10px" },
    ml: { md: 1, xs: 0 },
    "& .MuiInputBase-root.Mui-disabled": {
      color: "red !important",
    },
    "& .MuiInputBase-input::placeholder": {
      opacity: 0.7,
      color: greyShade42, // Change the placeholder color
      fontSize: "13px", // Change the placeholder font size
    },
  },

  searchBtn: {
    fontSize: {md:13,xs:12},
    color: whiteColor,
    background: blackColor,
    borderColor: headingColor,
    p: { md: "6px 20px", xs: "4px 15px" },
    mr: { md: 1, xs: 0 },
    textTransform: "uppercase",
    borderRadius:"6px",
    "&:hover": {
      color: blackColor,
      backgroundColor: whiteColor,
      borderColor: headingColor,
    },
  },

  submitBtn: {
    color: whiteColor,
    fontSize: "12px",
    fontWeight: 500,
    textTransform: "uppercase",
    border: "1px solid #000000",
    background: blackColor,
    padding: "4px 12px",
    borderRadius: "6px",
    marginLeft: "10px",
    display: "flex",
    alignItems: "center",
    gap: 0.5,
    // ...styles.actionBtn,
    // display: { md: 'flex', xs: 'none' },
    "&:hover": {
      color: blackColor,
      backgroundColor: whiteColor,
    },
  },
  cancelBtn: {
    color: "#000000",
    fontSize: "12px",
    fontWeight: 500,
    textTransform: "uppercase",
    border: "1px solid #000000",
    background: "#fff",
    padding: "4px 12px",
    borderRadius: "6px",
    marginLeft: "10px",
    display: "flex",
    alignItems: "center",
    gap: 0.5,
    // ...styles.actionBtn,
    // display: { md: 'flex', xs: 'none' },
    "&:hover": {
      color: whiteColor,
      backgroundColor: blackColor,
    },
  },
};
