import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import Loader from "components/Loader";
import {
  orders_AvailableCashback,
  orders_PendingOrders,
  orders_TotalOrder,
} from "configs/Constants";
import BreadcrumbsSection from "../../components/Breadcrumbs";
import {
  backgroundColor,
  backgroundColor01,
  blackColor,
  dashboardDescriptionColor,
  headingColor,
  orangeColor01,
  whiteColor,
} from "../../configs/styles/muiThemes";
import DasboardInfoCard from "./DasboardInfoCard";
import DashboardCard from "./DashboardCard";
import DashboardNavbar from "./DashboardNavbar";

function DashboardContent({
  accountDetails,
  orderDetails,
  openOrderDetails,
  cashbackData,
  loading,
  businessId,
  address,
  ordersCountData,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  // const orderList = orderDetails?.details?.filter((item) => {
  //   return (
  //     item.orderStatus !== 'paycartcreated' &&
  //     item.orderStatus !== 'businesscartcreated'
  //   )
  // })

  const businessName = sessionStorage.getItem("businessName");

  return matches ? (
    <>
      <Box backgroundColor={{ md: backgroundColor01, xs: whiteColor }}>
        <Box
          sx={{
            display: "flex",
            // background: "#fff",
            // padding: "20px 10px",
            borderRadius: "12px",
            // border: "1px solid #e8e8f0",
            marginBottom: "20px",
          }}
        >
          <Grid container columnSpacing={2}>
            <Grid item md={2}>
              <DashboardNavbar active="dashboard" businessId={businessId} />
            </Grid>
            <Grid item md={10}>
              <Box pl={1}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={{ xs: 2.0, md: 1 }}
                  mt={{ md: 0, xs: 2.3 }}
                >
                  <Typography
                    variant="h4"
                    component="h2"
                    fontSize={{ xs: 15, md: 16 }}
                    color={blackColor}
                  >
                    My Dashboard
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    fontSize={14}
                    lineHeight="24px"
                    color={blackColor}
                    display="inline"
                  >
                    Hello,{" "}
                    <Typography
                      variant="subtitle1"
                      component="p"
                      fontSize="14px"
                      fontWeight={600}
                      color={orangeColor01}
                      display="inline"
                    >
                      {/* Show only first name */}
                      {/* {(accountDetails?.account?.name).split(' ')[0]} ! */}
                      {accountDetails?.account?.name} !
                    </Typography>
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  fontSize={{ xs: 13, md: 12.5 }}
                  color={blackColor}
                  mb="15px !important"
                >
                  From your My Dashboard you have the ability to view a snapshot
                  of your account information and recent order activity
                </Typography>
                <Grid container spacing={{ xs: 1.25, md: 2 }} mt={0}>
                  <Grid item md={4} xs={12}>
                    <DashboardCard
                      icon="https://storage.googleapis.com/bodefaults/shopweb/orders/total-order1.svg"
                      // icon='https://storage.googleapis.com/bodefaults/shopweb/dashboard_total_orders.png'
                      title={orders_TotalOrder}
                      // value={orderList?.length}
                      value={ordersCountData?.details?.allOrder}
                      linkPath={`/${businessName}/allOrders`}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <DashboardCard
                      icon="https://storage.googleapis.com/bodefaults/shopweb/payments/pending-order.svg"
                      // icon='https://storage.googleapis.com/bodefaults/shopweb/dashboard_pending_orders.png'
                      title={orders_PendingOrders}
                      // value={openOrderDetails?.details?.length}
                      value={ordersCountData?.details?.openOrder}
                      linkPath={`/${businessName}/openOrders`}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <DashboardCard
                      icon="https://storage.googleapis.com/bodefaults/shopweb/payments/total-wallet.svg"
                      // icon='https://storage.googleapis.com/bodefaults/shopweb/dashboard_available_cashback.png'
                      title={orders_AvailableCashback}
                      value={`₹ ${
                        cashbackData?.details.length > 0
                          ? cashbackData.details[0].availablePointValue.toFixed(
                              2
                            )
                          : 0
                      }`}
                    />
                  </Grid>
                </Grid>
                <Typography
                  variant="h5"
                  color={headingColor}
                  fontSize="15px"
                  mt={1}
                  mb="16px !important"
                >
                  Account Information
                </Typography>

                <Grid container spacing={{ md: 2, xs: 1 }}>
                  <Grid item md={6} xs={12}>
                    <DasboardInfoCard
                      title="Name"
                      label=""
                      value={accountDetails?.account?.name}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <DasboardInfoCard
                      title="Contact Number"
                      label=""
                      value={accountDetails?.account?.phonenumber}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <DasboardInfoCard
                      title="E-Mail Address"
                      label=""
                      value={accountDetails?.account?.email}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  ) : (
    <Box>
      <DashboardNavbar active="dashboard" businessId={businessId} />

      <Box
        display={"flex"}
        flexDirection={"column"}
        padding="10px 15px"
        mb={10}
        mt={10}
        backgroundColor={whiteColor}
        // borderRadius={"7px"}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "16px",
                color: "#000",
                fontWeight: 600,
                lineHeight: "20px",
                marginTop: "0px",
                marginBottom: "0px",
                paddingTop: "10px",
              }}
            >
              Dashboard
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "13px",
                color: "#000",
                fontWeight: 500,
                marginBottom: "0px",
                lineHeight: "18px",
                marginTop: "10px",
              }}
            >
              Hello,{" "}
              <Typography
                component={"strong"}
                sx={{
                  fontSize: "13px",
                  color: "#F58220",
                  fontWeight: 600,
                  marginBottom: "0px",
                  lineHeight: "18px",
                  marginTop: "4px",
                }}
              >
                {" "}
                {accountDetails?.account?.name}
              </Typography>
            </Typography>
          </Box>
        </Box>
        <Typography
          sx={{
            fontSize: "12.5px",
            color: "#000",
            fontWeight: 400,
            marginBottom: "15px",
            lineHeight: "18px",
            marginTop: "10px",
          }}
        >
          From your My Dashboard you have the ability to view a snapshot of your
          account information and recent order activity
        </Typography>
        <Box display={"flex"} gap={1} mt={1}>
          <Box
            display={"flex"}
            sx={{
              background: "#f3f3f8",
              padding: "10px 10px",
              borderRadius: "8px",
              border: "1px solid #e9e9f1",
              marginBottom: "14px",
              width: "100%",
              gap: 1,
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              maxHeight={{ xs: "40px", md: "40px" }}
              borderRadius={"8px"}
              src={
                "https://storage.googleapis.com/bodefaults/shopweb/orders/total-order1.svg"
              }
              alt="CostBo"
            />
            <Box display={"flex"} flexDirection={"column"}>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                  color: "#000000",
                  lineHeight: 1.5,
                  marginBottom: "2px",
                }}
              >
                Total Orders
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  fontWeight: 600,
                  color: "#000000",
                  lineHeight: 1.5,
                }}
              >
                {ordersCountData?.details?.allOrder}
              </Typography>
            </Box>
          </Box>
          <Box
            display={"flex"}
            sx={{
              background: "#f3f3f8",
              padding: "10px 10px",
              borderRadius: "8px",
              border: "1px solid #e9e9f1",
              marginBottom: "14px",
              width: "100%",
              gap: 1,
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              maxHeight={{ xs: "40px", md: "40px" }}
              borderRadius={"8px"}
              src={
                "https://storage.googleapis.com/bodefaults/shopweb/payments/pending-order.svg"
              }
              alt="CostBo"
            />
            <Box display={"flex"} flexDirection={"column"}>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                  color: "#000000",
                  lineHeight: 1.5,
                  marginBottom: "4px",
                }}
              >
                Pending Orders
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  fontWeight: 600,
                  color: "#000000",
                  lineHeight: 1.5,
                }}
              >
                {ordersCountData?.details?.openOrder}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          display={"flex"}
          sx={{
            background: "#f3f3f8",
            padding: "10px 10px",
            borderRadius: "8px",
            border: "1px solid #e9e9f1",
            marginBottom: "10px",
            // width: '100%',
            gap: 1,
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            maxHeight={{ xs: "40px", md: "40px" }}
            borderRadius={"8px"}
            src={
              "https://storage.googleapis.com/bodefaults/shopweb/payments/total-wallet.svg"
            }
            alt="CostBo"
          />
          <Box display={"flex"} flexDirection={"column"}>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                color: "#000000",
                lineHeight: 1.5,
                marginBottom: "4px",
              }}
            >
              Avialable Cashback
            </Typography>
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: 600,
                color: "#000000",
                lineHeight: 1.5,
              }}
            >
              {`₹${
                cashbackData?.details.length > 0
                  ? cashbackData.details[0].availablePointValue.toFixed(2)
                  : 0
              }`}
            </Typography>
          </Box>
        </Box>
        <Typography variant="h5" color={headingColor} fontSize="15px">
          Account Information
        </Typography>
        <Box
          sx={{
            marginBottom: "0px",
            border: "1px solid #ececf4",
            borderRadius: "8px",
            background: "#fff",
            paddingBottom: "8px",
            marginTop: "10px",
          }}
        >
          <Box
            sx={{
              marginBottom: "5px",
              padding: "5px 10px",
              display: "flex",
              justifyContent: "space-between",
              fontWeight: 500,
              color: "#000000",
              background: "#f3f3f8",
              fontSize: "13px",
              borderRadius: "5px",
              lineHeight: "22px",
              alignItems: "center",
            }}
          >
            <Typography>Name</Typography>
          </Box>
          <Box>
            <Typography ml={1.5}> {accountDetails?.account?.name}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginBottom: "0px",
            border: "1px solid #ececf4",
            borderRadius: "8px",
            background: "#fff",
            paddingBottom: "8px",
            marginTop: "10px",
          }}
        >
          <Box
            sx={{
              marginBottom: "5px",
              padding: "5px 10px",
              display: "flex",
              justifyContent: "space-between",
              fontWeight: 500,
              color: "#000000",
              background: "#f3f3f8",
              fontSize: "13px",
              borderRadius: "5px",
              lineHeight: "22px",
              alignItems: "center",
            }}
          >
            <Typography>Contact Number</Typography>
          </Box>
          <Box>
            <Typography ml={1.5}>
              {" "}
              {accountDetails?.account?.phonenumber}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginBottom: "0px",
            border: "1px solid #ececf4",
            borderRadius: "8px",
            background: "#fff",
            paddingBottom: "8px",
            marginTop: "10px",
          }}
        >
          <Box
            sx={{
              marginBottom: "5px",
              padding: "5px 10px",
              display: "flex",
              justifyContent: "space-between",
              fontWeight: 500,
              color: "#000000",
              background: "#f3f3f8",
              fontSize: "13px",
              borderRadius: "5px",
              lineHeight: "22px",
              alignItems: "center",
            }}
          >
            <Typography>E-Mail Address</Typography>
          </Box>
          <Box>
            <Typography ml={1.5}> {accountDetails?.account?.email}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default DashboardContent;
