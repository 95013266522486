import { Box, Skeleton, Typography } from '@mui/material'
import React from 'react'
import {
  blackColor,
  borderPaymentInfo,
  greyShade35,
  greyShade36,
  whiteColor,
} from '../../configs/styles/muiThemes'

function PaymentInfoCard({ icon, label, value }) {
  return (
    <Box sx={styles.card}>
      <Box
        component='img'
        src={icon}
        alt='costbo icon'
        sx={{
          width: '35px',
          height: '35px',
          objectFit: 'contain',
          display: { xs: 'inline-block', md: 'inline-block' },
        }}
      />

      <Box>
        <Typography
          variant='body2'
          fontSize={11.4}
          fontWeight={400}
          lineHeight='18px'
          color={blackColor}
        >
          {label}
        </Typography>

        <Typography
          variant='body2'
          fontSize={13}
          fontWeight={600}
          lineHeight={1.2}
          color={blackColor}
          mt='4px !important'
        >
          {value == undefined ? (
            <Skeleton variant='text' width={60} height={18} />
          ) : (
            '₹ ' + value
          )}
        </Typography>
      </Box>
    </Box>
  )
}

export default PaymentInfoCard

const styles = {
  card: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: { xs: "0", md: "10px" },
    borderRadius: "7px",
    boxShadow: { xs: "none", md: "0 1px 2px rgb(0 0 0 / 5%)" },
    position: "relative",
    alignItems: "center",
    background: "#f3f3f8",
    marginTop: "0px",
    borderRadius: "10px",
    padding: "5px",
    marginBottom: "15px",
    border: "1px solid #e9e9f1",
  },
};
