import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import {
  blackColor,
  borderCard01,
  borderTextbox,
  filterBgColor,
  greyShade54,
  greyShade58,
  iconInactiveColor,
  navLinkColor,
  whiteColor,
} from "configs/styles/muiThemes";
import React, { useRef, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick/lib/slider";
import ProductCardMinimal from "components/Cards/ProductCardMinimal";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  ChevronLeft,
  ChevronRight,
  KeyboardArrowDownOutlined,
} from "@mui/icons-material";

const CategoryProducts = ({ title, productCount, onViewMore, products }) => {
  const slider = useRef(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const navigate = useNavigate();
  const businessName = sessionStorage.getItem("businessName");

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 4,
    arrows: false,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1060,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };

  const onSlideNext = () => {
    console.log("currentSlideIndex", currentSlideIndex);
    if (currentSlideIndex < products?.length - 5) {
      slider.current.slickNext();
    }
  };

  const onSlidePrev = () => {
    if (currentSlideIndex > 0) {
      slider.current.slickPrev();
    }
  };

  const getCount = () => {
    return Number(productCount) > 15 ? 5 : 6;
  };

  return (
    <Box sx={styles.card}>
      <Box sx={styles.header}>
        <Box>
          <Typography
            fontSize={16}
            fontWeight={600}
            lineHeight="22px"
            color={blackColor}
            display="inline"
          >
            {title}
          </Typography>
          <Box
            component="span"
            color={greyShade54}
            fontSize={18}
            fontWeight={500}
            mx={1}
            textAlign="center"
          >
            ·
          </Box>
          <Typography
            variant="body2"
            color={greyShade54}
            fontSize="13px"
            fontWeight={500}
            display="inline"
          >
            {productCount} items
          </Typography>
        </Box>
        <Box display={"flex"} alignItems={"center"}>
          {products?.length > 6 && (
            <Typography
              variant="text"
              disableElevation
              // endIcon={<ArrowForwardIcon />}
              onClick={onViewMore}
              sx={styles.viewBtn}
            >
              View More
            </Typography>
          )}
          {/* {products?.length > 6 && (
          <>
            {currentSlideIndex > 0 && (
              <IconButton
                sx={{ ...styles.arrowIconBtn, left: "-20px" }}
                onClick={onSlidePrev}
              >
                <KeyboardArrowLeftIcon color="inherit" />
              </IconButton>
            )}

            {currentSlideIndex < products?.length - 6 && (
              <IconButton
                sx={{ ...styles.arrowIconBtn, right: "-20px" }}
                onClick={onSlideNext}
              >
                <KeyboardArrowRightIcon color="inherit" />
              </IconButton>
            )}
          </>
        )} */}
          {products?.length > 6 && (
            <Grid item display={{ xs: "none", md: "block" }}>
              <Grid container spacing={1.5}>
                <Grid item>
                  <Box
                    width="32px"
                    height="32px"
                    // backgroundColor={arrowBgColor}
                    backgroundColor={
                      currentSlideIndex > 0 ? filterBgColor : whiteColor
                    }
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius={2}
                    sx={{ cursor: "pointer" }}
                    // onClick={() => onPrev()}
                    onClick={onSlidePrev}
                    border={borderTextbox}
                  >
                    <ChevronLeft
                      sx={{
                        fontSize: "22px",
                        color:
                          currentSlideIndex > 0
                            ? navLinkColor
                            : iconInactiveColor,
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item>
                  <Box
                    width="32px"
                    height="32px"
                    // backgroundColor={arrowBgColor}
                    backgroundColor={
                      currentSlideIndex < products?.length - getCount()
                        ? filterBgColor
                        : whiteColor
                    }
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius={2}
                    sx={{ cursor: "pointer" }}
                    // onClick={() => onNext()}
                    onClick={onSlideNext}
                    border={borderTextbox}
                  >
                    <ChevronRight
                      sx={{
                        fontSize: "22px",
                        color:
                          currentSlideIndex < products?.length - getCount()
                            ? navLinkColor
                            : iconInactiveColor,
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
      <Box>
        {products?.length > 6 ? (
          <Box
            sx={{
              borderRadius: "20px",
            }}
            position="relative"
          >
            <Box overflow="hidden">
              <Slider
                ref={slider}
                {...settings}
                beforeChange={(oldIndex, newIndex) => {
                  setCurrentSlideIndex(newIndex);
                }}
              >
                {products?.map((item, index) => (
                  <ProductCardMinimal
                    key={`${title}_${index}`}
                    title={item?.productName}
                    imgUrl={item?.productPhoto?.[0]?.docURL}
                    oldPrice={item?.productOriginalPrice}
                    price={item?.productSalePrice}
                    discount={item?.percentageOff}
                    onClick={() => {
                      navigate(`/${businessName}/singleProduct/${item?.id}`);
                    }}
                    variantCount={item?.count}
                  />
                ))}
                {Number(productCount) > 15 && (
                  <Box
                    sx={{
                      p: "0px 12px",
                      height: "192px",
                      bgcolor: whiteColor,
                      display: "flex",
                      // borderRight: `1px solid #EFEFEF`,
                    }}
                  >
                    <Typography
                      onClick={onViewMore}
                      sx={styles.viewBtn}
                      textAlign={"center"}
                      pt="80px"
                    >
                      View More
                    </Typography>
                  </Box>
                )}
              </Slider>
            </Box>

            {/* {products?.length > 6 && (
              <>
                {currentSlideIndex > 0 && (
                  <IconButton
                    sx={{ ...styles.arrowIconBtn, left: "-20px" }}
                    onClick={onSlidePrev}
                  >
                    <KeyboardArrowLeftIcon color="inherit" />
                  </IconButton>
                )}

                {currentSlideIndex < products?.length - 6 && (
                  <IconButton
                    sx={{ ...styles.arrowIconBtn, right: "-20px" }}
                    onClick={onSlideNext}
                  >
                    <KeyboardArrowRightIcon color="inherit" />
                  </IconButton>
                )}
              </>
            )} */}
          </Box>
        ) : (
          <Grid container>
            {products?.slice(0, 6)?.map((item, index) => (
              <Grid key={`${title}_${index}`} item md={2}>
                <ProductCardMinimal
                  title={item?.productName}
                  imgUrl={item?.productPhoto?.[0]?.docURL}
                  oldPrice={item?.productOriginalPrice}
                  price={item?.productSalePrice}
                  discount={item?.percentageOff}
                  onClick={() => {
                    navigate(`/${businessName}/singleProduct/${item?.id}`);
                  }}
                  variantCount={item?.count}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default CategoryProducts;

const styles = {
  card: {
    borderRadius: "15px",
    bgcolor: whiteColor,
    py: "15px",
  },

  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    p: "0px 20px 17px",
  },

  viewBtn: {
    color: blackColor,
    textTransform: "none",
    textDecoration: "underline",
    fontSize: 12.5,
    fontWeight: 500,
    lineHeight: "20px",
    pr: 2.5,
  },

  arrowIconBtn: {
    width: "32px",
    height: "38px",
    color: blackColor,
    bgcolor: whiteColor,
    p: 0.7,
    border: borderCard01,
    borderRadius: "7px",
    position: "absolute",
    top: "40%",
    boxShadow: "0 1px 5px rgb(202 202 214 / 55%)",
    "&:hover": {
      color: blackColor,
      bgcolor: whiteColor,
    },
  },
};
