import {
  Box,
  Container,
  Grid,
  Typography,
} from '@mui/material'
import EmptyCard from 'components/EmptyCard'
import Loader from 'components/Loader'
import {
  emptyOrderBtnText,
  OrderEmptyDescription,
  otherOrdersEmptyImgUrl,
  otherOrdersEmptyMessage,
} from 'configs/Constants'
import BreadcrumbsSection from '../../components/Breadcrumbs'
import {
  dashboardDescriptionColor,
  headingColor,
  orangeColor01,
} from "../../configs/styles/muiThemes";
import OrderCard from '../AllOrders/OrderCard'
import DashboardNavbar from '../Dashboard/DashboardNavbar'

function OtherOrdersContent({
  accountDetails,
  orderDetails,
  loading,
  businessId,
}) {
  return (
    <Container sx={{ maxWidth: "1300px !important", mt: 3.5 }}>
      <BreadcrumbsSection />
      <Grid
        container
        rowSpacing={{ md: 2, xs: 0 }}
        columnSpacing={{ md: 5, xs: 0 }}
        mt={{ md: 2, xs: 0 }}
      >
        <Grid item md={3} xs={12}>
          <DashboardNavbar active="otherOrders" businessId={businessId} />
        </Grid>

        {loading ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Loader />
          </Box>
        ) : (
          <Grid item md={9} xs={12}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
              gap={1}
              mb={2.6}
              mt={{ md: 0, xs: 3 }}
            >
              <Typography variant="h2" color={headingColor}>
                Other Orders
              </Typography>
              <Typography
                variant="subtitle1"
                component="p"
                fontSize="16px"
                lineHeight="24px"
                color={dashboardDescriptionColor}
                display="inline"
              >
                Hello,{" "}
                <Typography
                  variant="subtitle1"
                  component="p"
                  fontSize="16px"
                  fontWeight={600}
                  color={orangeColor01}
                  display="inline"
                >
                  {accountDetails?.account?.name} !
                </Typography>
              </Typography>
            </Box>
            {orderDetails?.map((order, index) => {
              return (
                <OrderCard
                  cartId={order.cartId}
                  orderId={order.orderId}
                  date={order.orderDate}
                  quantity={order.totalQuantity}
                  totalPrice={order.orderTotalProductCost}
                  orderStatus={order.orderStatus}
                  cartDate={order.cartDate}
                />
              );
            })}

            {orderDetails?.length === 0 ? (
              <EmptyCard
                message={otherOrdersEmptyMessage}
                description={OrderEmptyDescription}
                imageUrl={otherOrdersEmptyImgUrl}
                actionBtnText={emptyOrderBtnText}
              />
            ) : null}
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default OtherOrdersContent
