import { Box, Container, Grid, Typography } from '@mui/material'
import { businessClosedMsg, topBarMsg } from 'configs/Constants'
import {
  blackColor,
  discountInfoCardBGColor,
  headingColor,
  orangeColor01,
  topbarColor,
  whiteColor,
} from "configs/styles/muiThemes";
import React, { Suspense } from 'react'

function BusinessClosed() {
  let businessFullName = sessionStorage.getItem('businessFullName')

  return (
    <Suspense fallback={<p></p>}>
      <Box>
        <Box sx={styles.topBar}>
          <Typography textAlign="center" variant="subtitle2" color={whiteColor}>
            {topBarMsg}
          </Typography>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            component="img"
            src="https://storage.googleapis.com/bodefaults/shopweb/Empty/home_empty.svg"
            width="190px"
            height="auto"
            sx={{ mt: 3 }}
            alt={businessFullName + " CostBo"}
          />
          <Typography
            variant="subtitle2"
            fontSize={16}
            color={headingColor}
            py={2}
          >
            {businessClosedMsg}
          </Typography>
        </Box>

        <Container sx={{ maxWidth: "1315px !important", mt: 3 }}>
          <Box
            backgroundColor={discountInfoCardBGColor}
            borderRadius={1.8}
            boxShadow="0px 2px 8px rgb(94 94 94 / 10%)"
            sx={{
              px: { xs: 4, md: 8 },
              py: { xs: 4, md: 7 },
              mb: 3,
              ml: 1,
              cursor: "pointer",
            }}
            onClick={() => {
              window.open("https://buy.costbo.com/", "_self");
            }}
          >
            <Grid container direction={{ xs: "column", md: "row" }}>
              <Grid item xs={6} md={4}>
                <Typography
                  variant="subtitle1"
                  fontSize={16}
                  color={headingColor}
                  display="inline"
                >
                  Please visit{" "}
                  <Typography
                    variant="subtitle1"
                    fontSize={16}
                    color={orangeColor01}
                    display="inline"
                  >
                    www.costbo.com
                  </Typography>{" "}
                  to
                </Typography>

                <Typography
                  variant="h1"
                  fontSize={{ xs: 19, md: 33 }}
                  color={headingColor}
                  sx={{ pt: 2.8, lineHeight: { xs: "28px", md: "50px" } }}
                >
                  Buy online directy from your favourite brands & stores
                </Typography>

                <Typography
                  variant="body2"
                  fontSize={{ xs: 14, md: 16 }}
                  color={headingColor}
                  sx={{ pt: 2.8 }}
                >
                  Discover great products & deals! Enjoy great savings!
                </Typography>
              </Grid>

              <Grid item xs={6} md={8}>
                <Grid
                  container
                  justifyContent={{ xs: "center", md: "flex-end" }}
                >
                  <Grid item>
                    <Box
                      component="img"
                      src="https://storage.googleapis.com/bodefaults/shopweb/Empty/empty_banner.png"
                      width={{ xs: "180px", md: "330px" }}
                      height="auto"
                      alt={businessFullName + " CostBo"}
                      mt={{ xs: 2, md: 0 }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Suspense>
  );
}

export default BusinessClosed

const styles = {
  topBar: {
    background: blackColor,
    p: '6px 0',
  },
}
