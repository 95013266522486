import { Box, Typography } from "@mui/material";
import {
  blackColor,
  greenColor05,
  greyShade70,
  greyShade71,
  lightGreen04,
  whiteColor,
} from "configs/styles/muiThemes";
import React from "react";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";

const BranchCard = ({
  logoUrl,
  branchName,
  address,
  distance,
  branchId,
  onClick,
}) => {
  const currentBusinessId = sessionStorage.getItem("businessId");

  return (
    <Box
      sx={{
        ...styles.card,
        border:
          currentBusinessId == branchId
            ? `2px solid ${greenColor05}`
            : `1px solid ${greyShade70}`,
      }}
      onClick={onClick}
    >
      <Box p="10px" display="flex" alignItems="center" gap="10px">
        <Box component="img" src={logoUrl} sx={styles.logoImg} />

        <Box>
          <Typography
            fontSize={{xs:12.5, md:13}}
            fontWeight={600}
            color={blackColor}
            lineHeight={{xs:'20px', md:"24px"}}
            mb="2px !important"
          >
            {branchName}
          </Typography>

          <Box display="flex" alignItems={{xs:'flex-start', md:"center"}} gap="4px" ml={-0.25}>
            <PlaceOutlinedIcon
              sx={{ fontSize: "17px", color: greyShade71, mt: -0.06, display:{xs:'none', md:'block'} }}
            />
            <Typography
              fontSize={12}
              fontWeight={400}
              color={greyShade71}
              lineHeight={{xs:'16px', md:"13px"}}
            >
              {address}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={styles.distanceInfoBox}>
        <PlaceOutlinedIcon sx={{ fontSize: "20px", color: greenColor05 }} />
        <Typography
          fontSize={{xs: 10.5, md:11}}
          fontWeight={600}
          color={greenColor05}
          lineHeight="13px"
          mt="4px !important"
        >
          {distance}
        </Typography>
      </Box>
    </Box>
  );
};

export default BranchCard;

const styles = {
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: `1px solid ${greyShade70}`,
    bgcolor: whiteColor,
    borderRadius: "10px",
    overflow: "hidden",
    mb: "15px",
    cursor: "pointer",

    "&:hover": {
      border: `1px solid ${greenColor05}`,
    },
  },

  logoImg: {
    width: {xs:'40px', md:"58px"},
    height: {xs:'40px', md:"58px"},
    borderRadius: "7px",
    border: `1px solid ${greyShade70}`,
  },

  distanceInfoBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    bgcolor: {xs: whiteColor, md:lightGreen04},
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "8px 0px 0px 8px",
    height: {xs:'65px', md:"80px"},
    width: {xs:'78px', md:"88px"},
  },
};
