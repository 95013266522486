import {
  Box,
  Button,
  Fade,
  IconButton,
  Paper,
  Popper,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { Suspense, useRef, useState } from "react";
import { AddRounded } from "@mui/icons-material";
import LazyLoad from "react-lazyload";
import {
  backgroundColor01,
  blackColor,
  greenColor03,
  greenColor06,
  greyShade37,
  greyShade51,
  greyShade52,
  headingColor,
  infoAlertBgColor,
  offerTextColor,
  orangeColor01,
  orangeColor12,
  orangeColor13,
  primaryColor,
  redColor04,
  topbarColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import { Link } from "react-router-dom";
import { noProductImgUrl } from "configs/Constants";
import moment from "moment";
import ModalCard from "components/ModalCard";
import GetAppModalCard from "components/GetAppModalCard";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { useSelector } from "react-redux";
import AffiliateShareModal from "pages/Affiliates/AffiliateShareModal";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { getDiscountPercentage } from "utils/utilities";

const QuantityPicker = React.lazy(() => import("./QuantityPicker"));
function ProductCard({
  img,
  price,
  oldPrice,
  description,
  discount,
  isOutOfStock,
  isComingSoon,
  disableAddtoCart,
  minQty,
  maxQty,
  currencySymbol,
  cartData,
  product,
  cartId,
  onQtyUpdate,
  onAddProduct,
  onRemoveProduct,
  subscribe,
  availability,
  subscription,
  direction,
  productid,
  cardBGColor,
  variant,
  variantCount,
  circularCountView,
}) {
  const [isModal, setIsModal] = useState(false);
  const [disableCard, setDisableCard] = useState(false);
  const businessName = sessionStorage.getItem("businessName");
  let businessFullName = sessionStorage.getItem("businessFullName");
  const commerceInfo = JSON.parse(sessionStorage.getItem("commerceInfo"));
  const commerceEnable = commerceInfo
    ? commerceInfo?.info?.commerceEnable
    : true;

  const { rellerProfileData, businessAffiliateData } = useSelector(
    (state) => state.affiliate
  );

  const getProductCartCount = (cartList, product) => {
    const isPresent = cartList?.filter((cartProduct) => {
      return cartProduct?.productId === product?.id;
    });

    const cartCount =
      isPresent?.length > 0 ? isPresent?.[0]?.currentQuantity : 0;

    return cartCount;
  };

  const count = getProductCartCount(cartData, product);

  const loading = cartData?.filter((item) => {
    return item?.productId === product?.id;
  });

  const loadingRef = useRef(null);
  const productImgRef = useRef(null);

  const currentDate = new Date();

  const isStartDateAfter = moment(product?.commerceStartDate).isAfter(
    currentDate
  );

  // Affiliate Share
  const [openShareModal, setOpenShareModal] = useState({
    show: false,
    data: null,
  });

  const handleShareProduct = () => {
    console.log("PRODUCT:", product);
    console.log("commissionDetails:", businessAffiliateData);
    console.log("rellerProfileData:", rellerProfileData);

    setOpenShareModal({
      show: true,
      data: {
        productDetails: product,
        commissionDetails: businessAffiliateData,
      },
    });
  };

  return (
    <Suspense fallback={<p></p>}>
      {/* Affiliate Share Modal */}
      {openShareModal?.show && (
        <AffiliateShareModal
          productDetails={openShareModal?.data?.productDetails}
          commissionDetails={openShareModal?.data?.commissionDetails}
          rellerProfileData={rellerProfileData}
          open={openShareModal?.show}
          onClose={() => {
            setOpenShareModal({ show: false, data: null });
          }}
          isProductShare
        />
      )}

      <Box
        sx={{
          ...styles.productCard,
        }}
        title={description}
      >
        {subscribe && (
          <Box
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              paddingTop: 0,
              // marginLeft: "-14px",
              marginRight: 0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              transition: "margin 100ms ease-in-out 50ms",
              zIndex: 9,
            }}
          >
            <Box
              sx={{
                width: "100%",
                position: "relative",
                display: "flex",
                justifyContent: "flex-end",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "8px",
                minWidth: 0,
                right: 0,
                color: "#e56b46",
                padding: "0px 10px",
                backgroundColor: "#ffede8",
                borderRadius: "0px 10px 0px 10px",
              }}
              onClick={() => setIsModal(true)}
            >
              <Box display={"flex"}>
                <Typography
                  color={orangeColor01}
                  fontSize={11}
                  fontWeight={500}
                  //  textAlign="center"
                >
                  Subscribe for{" "}
                </Typography>
                <Typography
                  color={orangeColor01}
                  fontSize={11}
                  fontWeight={600}
                  // textAlign="center"
                  ml={0.5}
                >
                  ₹ {subscription?.offerValue}
                </Typography>
                {/* <Box
                  component="img"
                  src={`/./assets/images/chevron-right-white.svg`}
                  height="8px"
                  width={"10px"}
                  alt=""
                  sx={{ objectFit: "contain", mt: 0.8, ml: 0.5 }}
                /> */}
              </Box>
            </Box>
          </Box>
        )}
        <Box padding={"15px 10px 10px"}>
          <Box sx={{ position: "relative" }}>
            {commerceInfo?.info?.costboPowered?.affiliateEnabled &&
            businessAffiliateData?.businessResellers?.length > 0 &&
            rellerProfileData?.details ? (
              <Box position="absolute" top={0} right={0} zIndex={2}>
                <IconButton
                  onClick={handleShareProduct}
                  size="small"
                  sx={{
                    textTransform: "uppercase",
                    backgroundColor: greyShade37,
                  }}
                >
                  <ShareOutlinedIcon
                    fontSize="small"
                    sx={{ color: blackColor }}
                  />
                </IconButton>
              </Box>
            ) : (
              <></>
            )}

            <Box
              display={"flex"}
              component={Link}
              to={`/${businessName}/singleProduct/${product?.id || productid}`}
              sx={{
                width: "100%",
                position: "relative",
                alignItems: "center",
                justifyContent: "center",
                mt: "5px",
              }}
            >
              {/* {discount ? (
              <Box sx={styles.offTag}>
                <Typography
                  variant='body1'
                  color={whiteColor}
                  fontSize='12px'
                  fontWeight={400}
                  textAlign='center'
                >
                  {discount}%
                </Typography>
              </Box>
            ) : null} */}

              <Box
              // width='143px'
              // height='128px'
              >
                <Box ref={loadingRef}>
                  <Skeleton
                    variant="rectangular"
                    width="120px"
                    height="90px"
                    sx={{
                      mb: 0,
                    }}
                  />
                </Box>

                <LazyLoad offset={100} throttle={200}>
                  <Box
                    ref={productImgRef}
                    component="img"
                    src={img}
                    alt={businessFullName + " CostBo"}
                    width="1px"
                    height="1px"
                    onLoad={() => {
                      loadingRef.current.style.display = "none";
                      productImgRef.current.style.width = "120px";
                      productImgRef.current.style.height = "90px";
                    }}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = noProductImgUrl;
                      setDisableCard(true);
                    }}
                    sx={{
                      objectFit: "contain",
                      transform: "scale(1)",
                      "&:hover": {
                        transform: "scale(0.97)",
                      },
                    }}
                  />
                </LazyLoad>
              </Box>
            </Box>
          </Box>

          <Box>
            <Typography
              variant="body1"
              color={blackColor}
              fontSize="12px"
              fontWeight={500}
              lineHeight="18px"
              height="40px"
              mt={1.5}
              overflow="hidden"
              title={description}
            >
              {description?.slice(0, 65)}
              {description?.length > 65 ? "..." : ""}
            </Typography>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              height={"40px"}
              mt={"6px"}
            >
              <Typography
                fontSize={14}
                fontWeight={600}
                lineHeight={"20px"}
                color={blackColor}
              >
                ₹ {price}
              </Typography>
              {oldPrice != price && (
                <Typography
                  fontSize={12}
                  fontWeight={500}
                  color={greyShade52}
                  lineHeight={"20px"}
                  sx={{ textDecoration: "line-through", display: "inline" }}
                >
                  ₹ {oldPrice}
                </Typography>
              )}
            </Box>
            {variant && circularCountView ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: "30px",
                  height: "30px",
                  color: orangeColor01,
                  fontSize: "12px",
                  fontWeight: "600",
                  cursor: "pointer",
                  backgroundColor: orangeColor12,
                  borderRadius: "50%",
                  border: "1px solid" + orangeColor13,
                }}
              >
                +{Number(variantCount) - 1 || "X"}
              </Box>
            ) : null}
          </Box>

          {variant && !circularCountView ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gap={0.5}
              component={Link}
              to={`/${businessName}/singleProduct/${product?.id || productid}`}
              mt={1}
            >
              <Typography
                sx={{
                  color: orangeColor01,
                  textDecoration: "underline",
                  fontSize: "12px",
                  fontWeight: "600",
                  cursor: "pointer",
                  backgroundColor: orangeColor12,
                  p: "0px 5px",
                  borderRadius: "6px",
                  border: "1px solid" + orangeColor13,
                }}
              >
                +{Number(variantCount) - 1 || "X"}
              </Typography>
              <Typography
                component={"span"}
                color={blackColor}
                fontSize="12px"
                fontWeight={500}
                sx={{
                  textDecoration: "none",
                }}
              >
                Options
              </Typography>
            </Box>
          ) : null}

          {!variant ? (
            !disableAddtoCart ? (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                height="30px"
                maxHeight="50px"
                // height={disableAddtoCart ? 'auto' : '68px'}
              >
                <Box></Box>
                {subscribe && !availability && !isOutOfStock ? (
                  <Box>
                    <Typography
                      variant="p"
                      // color={"rgb(226 62 29 / 85%)"}
                      color={blackColor}
                      fontSize="12px"
                      fontWeight={500}
                      textAlign="left"
                      // textTransform={"uppercase"}
                    >
                      Subscribe Only
                    </Typography>
                  </Box>
                ) : null}
                {isOutOfStock || !commerceEnable ? (
                  // <Box sx={styles.alertContainer}>
                  <Box
                    height="35px"
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"right"}
                  >
                    <Typography
                      variant="body1"
                      color={isOutOfStock ? redColor04 : "rgb(15 190 83 / 85%)"}
                      fontSize="13px"
                      fontWeight={500}
                      textAlign="right"
                      lineHeight="18px"
                    >
                      {isOutOfStock ? "Out Of Stock" : "Coming Soon"}
                    </Typography>
                    {isStartDateAfter && (
                      <Box display={"flex"}>
                        <CalendarMonthIcon
                          sx={{
                            fontSize: "16px",
                            mr: 0.5,
                            color: greenColor06,
                          }}
                        />
                        <Typography
                          variant="body1"
                          color={blackColor}
                          fontSize="11px"
                          fontWeight={500}
                          textAlign="right"
                          sx={{ whiteSpace: "pre-wrap" }}
                          lineHeight="18px"
                        >
                          {isStartDateAfter
                            ? `Available - ${moment(
                                product?.commerceStartDate
                              ).format("DD/MM/YYYY")}`
                            : ""}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                ) : // </Box>
                count > 0 ? (
                  <QuantityPicker
                    count={count}
                    onIncrement={() => {
                      if (loading?.[0]?.loading) return;

                      if (count < maxQty) {
                        onQtyUpdate({
                          cartId: cartId,
                          productId: product?.id,
                          quantity: count + 1,
                        });
                      }
                    }}
                    onDecrement={() => {
                      if (loading?.[0]?.loading) return;

                      if (count > minQty) {
                        onQtyUpdate({
                          cartId: cartId,
                          productId: product?.id,
                          quantity: count - 1,
                        });
                      } else {
                        onRemoveProduct({
                          productId: product?.id,
                        });
                      }
                    }}
                    minQty={minQty}
                    maxQty={maxQty}
                  />
                ) : !disableAddtoCart && availability ? (
                  <IconButton
                    sx={styles.actionBtn}
                    onClick={() => {
                      if (loading?.[0]?.loading) return;

                      let gstTaxValue = 0;

                      if (product.gstInfo != null) {
                        gstTaxValue = product.gstInfo.gstPercentage.replace(
                          /[&\/\\#,+()$~%.'":*?<>{}]/g,
                          ""
                        );
                      } else {
                        gstTaxValue = 0;
                      }

                      let productAmtWithoutGST =
                        (product.productSalePrice * 100) /
                        (100 + Number(gstTaxValue));
                      let productTaxPerUnit =
                        product.productSalePrice - productAmtWithoutGST;

                      console.log(
                        "ADD_PRODUCT_productSalePrice",
                        product.productSalePrice
                      );
                      console.log("ADD_PRODUCT_gstTaxValue", gstTaxValue);
                      console.log(
                        "ADD_PRODUCT_productSalePrice100",
                        (product.productSalePrice * 100) / 100 + gstTaxValue
                      );
                      console.log(
                        "ADD_PRODUCT_productAmtWithoutGST",
                        productAmtWithoutGST
                      );
                      console.log(
                        "ADD_PRODUCT_productTaxPerUnit",
                        productTaxPerUnit
                      );

                      onAddProduct({
                        cartId: cartId,
                        product: {
                          productId: product?.id,
                          productName: product?.productName,
                          productPicURL: img,
                          topicId: "",
                          productOriginalPrice: product?.productOriginalPrice,
                          productSalePrice: product?.productSalePrice,
                          originalQuantity: minQty,
                          currentQuantity: minQty,
                          currencySymbol: "₹",
                          percentageOff: product?.percentageOff,
                          quantityChangedFlag: false,
                          priceChangedFlag: false,
                          productTotalPrice: minQty * product?.productSalePrice,
                          productTax: productTaxPerUnit.toFixed(2),
                          productTaxRate: gstTaxValue,
                          measurementInfo: product?.measurementInfo,
                          catalogPartnerItemInfo:
                            product?.catalogPartnerItemInfo?.[0] || null,
                        },
                        clientType: "web",
                      });
                    }}
                  >
                    <AddRounded sx={{ fontSize: "18px" }} />
                  </IconButton>
                ) : null}
              </Box>
            ) : null
          ) : null}
        </Box>
        {/* {subscribe ? (
          <Box
            sx={{
              // backgroundColor: blackColor,
              // position: "absolute",
              // bottom: 0,
              // cursor: "pointer",
              // width: "90%",
              width: "67%",
              //position: "absolute",
              mb: 1.5,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // gap: "4px",
              minWidth: "0",
              color: "#fff",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              borderBottomRightRadius: "8px",
              padding: "0px 5px 0px 10px",
              backgroundColor: "#000000",
              "&::after": {
                content: '""',
                position: "absolute",
                top: "96%",
                left: "0px",
                // top: "-44px",
                // bottom: "-36px",
                // borderRight: { md: "5px solid #f2f2f2", xs: "none" },
                // "-webkit-transform": "rotate(26deg)",
                // transform: "rotate(26deg)",
                zIndex: 100,
                borderBottom: `5px solid transparent`,
                borderRight: `5px solid #616168;`,
              },
            }}
            onClick={() => setIsModal(true)}
          >
            <Box display={"flex"}>
              <Typography
                color={whiteColor}
                fontSize={11}
                fontWeight={400}
                //  textAlign="center"
              >
                Subscribe for
              </Typography>
              <Typography
                color={whiteColor}
                fontSize={11}
                fontWeight={600}
                // textAlign="center"
                ml={0.5}
              >
                ₹ {subscription?.offerValue}
              </Typography>
              <Box
                component="img"
                src={`/./assets/images/chevron-right-white.svg`}
                height="8px"
                width={"10px"}
                alt=""
                sx={{ objectFit: "contain", mt: 0.8, ml: 0.5 }}
              />
            </Box>
          </Box>
        ) : (
          <Box p={2.2} />
        )} */}
        <ModalCard
          handleClose={() => setIsModal(false)}
          open={isModal}
          width="40%"
        >
          <GetAppModalCard />
        </ModalCard>
        {oldPrice !== price && (
          <Box
            // sx={{
            //   position: "absolute",
            //   overflow: "hidden",
            //   display: "flex",
            //   alignItems: "center",
            //   gap: "4px",
            //   minWidth: "0px",
            //   background: "#10847e",
            //   color: "rgb(255, 255, 255)",
            //   borderRadius: "5px",
            //   lineHeight: "18px",
            //   padding: "1px 5px",
            //   fontWeight: 600,
            //   fontSize: "10px",
            //   textTransform: "capitalize",
            //   zIndex: 9,
            //   top: "0px",
            //   left: "12px",
            // }}
            sx={{
              position: "absolute",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              gap: "4px",
              minWidth: "0px",
              backgroundColor: "#10847e",
              color: "rgb(255, 255, 255)",
              borderRadius: "10px",
              lineHeight: "18px",
              padding: "2px 6px",
              fontWeight: 600,
              fontSize: "11px",
              textTransform: "capitalize",
              zIndex: 9,
              top: 0,
              left: 0,
              borderTopLeftRadius: "8px",
              borderBottomLeftRadius: "0px",
              borderTopRightRadius: "0px",
            }}
          >
            {" "}
            {getDiscountPercentage(price, oldPrice)}%
          </Box>
        )}
      </Box>
    </Suspense>
  );
}

export default ProductCard;

// const AlertTag = ({ message, bgColor }) => {
//   return (
//     <Box backgroundColor={bgColor} sx={styles.alertTag}>
//       <Typography
//         variant='body1'
//         color={whiteColor}
//         fontSize='13px'
//         fontWeight={600}
//         textAlign='center'
//       >
//         {message}
//       </Typography>
//     </Box>
//   )
// }

const styles = {
  productCard: {
    // width: '143px',
    // maxWidth: '173px',
    borderRadius: "10px",
    backgroundColor: whiteColor,
    border: `1px solid ${greyShade51}`,
    // boxShadow: '0px 1px 3px rgb(0 0 0 / 6%)',
    position: "relative",
    transition: "all 0.6s ease",
    "&:hover": {
      boxShadow: "0 4px 5px 0 rgba(0, 0, 0, 0.1)",
    },
    // mr: "15px",
  },

  addBtn: {
    width: "28px",
    height: "28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: whiteColor,
    border: "1px solid" + infoAlertBgColor,
    borderRadius: 1.2,
    // padding: '5px',
    // position: 'absolute',
    // right: '0px',
    // bottom: '-12px',
    cursor: "pointer",
  },

  minMaxContainer: {
    width: "100%",
    backgroundColor: "rgb(207 207 207 / 88%)",
    borderRadius: "0 0 6px 6px",
    position: "absolute",
    left: "0px",
    bottom: "0px",
    // py: 0.1,
  },

  offTag: {
    height: "20px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "primary.main",
    borderRadius: "6px",
    lineHeight: "12px",
    p: "1px 8px",
    position: "absolute",
    top: "0px",
    left: "0px",
    zIndex: 1,
  },

  tagUi: {
    width: "16.5px",
    height: "16.5px",
    backgroundColor: "primary.main",
    transform: "rotate(45deg)",
    position: "absolute",
    top: "3px",
    left: "-8px",
    zIndex: "0",
    borderRadius: "3px",
  },

  alertTag: {
    width: "100%",
    // backgroundColor: "rgb(226 62 29 / 85%)",
    borderRadius: "0 0 6px 6px",
    py: 0.3,
    position: "absolute",
    bottom: 0,
    left: 0,
  },

  alertContainer: {
    width: "100%",
    // height: '148px',
    // backgroundColor: 'rgba(256, 256, 256, 0.8)',
    blur: "10px",
    display: "flex",
    alignItems: "right",
    // justifyContent: "flex-start",
    // position: 'absolute',
    // top: 0,
  },

  discountText: {
    fontSize: 12,
    fontWeight: 600,
    color: greenColor03,
    lineHeight: "20px",
    ml: "10px",
    display: "inline",
  },

  actionBtn: {
    width: "30px",
    height: "30px",
    bgcolor: whiteColor,
    color: blackColor,
    // transition: "all ease 0.3s",
    border: `1px solid ${blackColor}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    textTransform: "uppercase",
    "&:hover": {
      color: whiteColor,
      bgcolor: blackColor,
      // transform: "scale(1.08)",
    },
  },
};
