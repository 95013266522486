import { Box, Container, Skeleton } from '@mui/material'
import React from 'react'

const ReviewContentMobileLoader = () => {
  return (
    <Box>
        <Skeleton variant="rounded" width="auto" height='33px' sx={{ m:'0 0 14px !important' }} />

        <Container sx={{ maxWidth: "1370px !important" }}>
            <Box>
                <Skeleton variant="rounded" width="70px" height="14px" />
                <Skeleton variant="rounded" width="auto" height="140px" sx={{ mt:'14px !important' }} />
                <Skeleton variant="rounded" width="auto" height="190px" sx={{ mt:'14px !important' }} />
            </Box>

            <Box display='flex' alignItems='center' justifyContent='space-between' mt={3}>
                <Skeleton variant="rounded" width="70px" height="14px" />
                <Skeleton variant="rounded" width="70px" height="14px" />
            </Box>

            <Skeleton variant="rounded" width="auto" height="190px" sx={{ mt:'14px !important' }} />
        </Container>
    </Box>
  )
}

export default ReviewContentMobileLoader