import {
  ChevronLeft,
  ChevronRight,
  KeyboardArrowDownOutlined,
} from "@mui/icons-material";
import { Box, Button, Grid, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import {
  blackColor,
  borderTextbox,
  catalogSubHeaderColor,
  filterBgColor,
  greyShade54,
  greyShade58,
  headingColor,
  iconInactiveColor,
  labelColor,
  navLinkColor,
  whiteColor,
} from "../../configs/styles/muiThemes";

function CategoryGroupHeader({
  title,
  onNext,
  onPrev,
  showSortBy,
  slideIndex,
  productCount,
  totalProduct,
  onViewMore,
  onSort,
  displaySelection,
  subCat,
  noAction,
  variant,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(displaySelection);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    console.log("DATA : ", event.target.innerText);
    setSelectedOption(event.target.innerText);
    onSort(event.target.innerText);
  };

  return (
    <Box
      mt={{ xs: 0, md: 1.5 }}
      sx={{ minHeight: "34px", display: "flex", alignItems: "center" }}
    >
      <Grid
        container
        direction={{ xs: "column", md: "row" }}
        alignItems={{ xs: "flex-start", md: "center" }}
        justifyContent="space-between"
      >
        <Grid item sx={{ width: { xs: "100%", md: "auto" } }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                variant="h5"
                color={headingColor}
                fontSize={{ md: "16px", xs: "14px" }}
                display="inline"
                ml={{ xs: 2, md: 0 }}
              >
                {title}
              </Typography>

              {!variant && (
                <>
                  <Box
                    component="span"
                    color={greyShade58}
                    fontSize={{ xs: "13px", md: "14px" }}
                    fontWeight={400}
                    mx={1}
                    textAlign="center"
                  >
                    ·
                  </Box>

                  <Typography
                    variant="body2"
                    color={greyShade58}
                    fontSize="13px"
                    fontWeight={400}
                    display="inline"
                  >
                    {totalProduct} items
                  </Typography>
                </>
              )}
            </Box>

            {/* {totalProduct > 2 && (
              <Box sx={styles.scrollArrow}>
                <KeyboardDoubleArrowRightIcon />
              </Box>
            )} */}
          </Box>
        </Grid>

        {showSortBy ? (
          <Grid item>
            {/* <Typography
              variant='body'
              fontSize='14px'
              color={labelColor}
              fontWeight='400'
            >
              Sort by :
            </Typography>

            <Button
              id='basic-button'
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              sx={styles.dropdownBtn}
              endIcon={<KeyboardArrowDownOutlined />}
            >
              {selectedOption ? selectedOption : 'Discount - High To Low'}
            </Button>

            <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              variant='selectedMenu'
            >
              <MenuItem onClick={handleClose}>Newest First</MenuItem>
              <MenuItem onClick={handleClose}>Discount - High To Low</MenuItem>
              <MenuItem onClick={handleClose}>Price - Low To High</MenuItem>
              <MenuItem onClick={handleClose}>Price - High To Low</MenuItem>
            </Menu> */}
          </Grid>
        ) : noAction ? null : (
          <Grid item>
            {totalProduct > 5 && (
              <Grid container spacing={1.5} display="flex" alignItems="center">
                <Grid item>
                  <Typography
                    onClick={() => {
                      onViewMore(subCat.subcatcode);
                    }}
                    sx={{
                      textTransform: "none",
                      textDecoration: "underline",
                      color: blackColor,
                      fontSize: "12.5px",
                      fontWeight: 500,
                      display: { xs: "none", md: "flex" },
                      cursor: "pointer",
                    }}
                  >
                    View More
                  </Typography>
                </Grid>

                <Grid item display={{ xs: "none", md: "block" }}>
                  <Grid container spacing={1.5}>
                    <Grid item>
                      <Box
                        width="32px"
                        height="32px"
                        // backgroundColor={arrowBgColor}
                        backgroundColor={
                          slideIndex === 0 ? filterBgColor : whiteColor
                        }
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius={2}
                        sx={{ cursor: "pointer" }}
                        onClick={() => onPrev()}
                        border={borderTextbox}
                      >
                        <ChevronLeft
                          sx={{
                            color:
                              slideIndex === 0
                                ? iconInactiveColor
                                : navLinkColor,
                            fontSize: "22px",
                          }}
                        />
                      </Box>
                    </Grid>

                    <Grid item>
                      <Box
                        width="32px"
                        height="32px"
                        // backgroundColor={arrowBgColor}
                        backgroundColor={
                          productCount === slideIndex
                            ? filterBgColor
                            : whiteColor
                        }
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius={2}
                        sx={{ cursor: "pointer" }}
                        onClick={() => onNext()}
                        border={borderTextbox}
                      >
                        <ChevronRight
                          sx={{
                            color:
                              productCount - 1 === slideIndex
                                ? iconInactiveColor
                                : navLinkColor,
                            fontSize: "22px",
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default CategoryGroupHeader;

const styles = {
  dropdownBtn: {
    fontSize: "15px",
    fontWeight: 600,
    textTransform: "none",
    color: headingColor,
    "&:hover": {
      backgroundColor: whiteColor,
    },
  },

  scrollArrow: {
    display: { xs: "block", md: "none" },
    mb: -0.8,
    // '-webkit-animation': `${load7} 1.8s infinite ease-in-out`,
    // animation: `${load7} 1.8s infinite ease-in-out`,
  },
};
