import { Box, Button, Typography } from '@mui/material'
import NumberInput from 'components/NumberInput'
import { useState } from 'react'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import {
  blackColor,
  headingColor,
  secondaryColor,
  whiteColor,
} from 'configs/styles/muiThemes'
import { validateMobileNumber } from 'utils/validationUtils'
import { checkExistingUser } from 'redux/authRedux/authSlice'
import { useDispatch } from 'react-redux'
import firebase from '../../../firebase'

function MobileNumberScreen({ onContinue, setUserType }) {
  const [mobileNumber, setMobileNumber] = useState(
    localStorage.getItem('phone') ? localStorage.getItem('phone') : ''
  )
  const [isError, setIsError] = useState(false)
  const [isError1, setIsError1] = useState(false)
  const [loader, setLoader] = useState(false)
  const dispatch = useDispatch()

   const handleSubmit = () => {
     setLoader(true)
     const error = validateMobileNumber(mobileNumber, true)
     if (error?.length > 0) {
       setIsError(true)
       setLoader(false)
       return
     }
     dispatch(checkExistingUser({ phone: mobileNumber })).then((response) => {
       console.log('sssknn', response)
       if (response.payload == undefined) {
         console.log('huhuuihhk')
         setIsError1(true)
         setLoader(false)
         return
       }
       if (response.payload) {
         if (response.payload.status === 200) {
           if (
             response.payload.data.na === true &&
             response.payload.data.sr === false
           ) {
             setLoader(false)
             console.log('Existing User')
             onContinue({ page: 'password', phone: mobileNumber })
           } else {
             setLoader(false)
             console.log('New User')
             if (response.payload.data.na === false) {
               setUserType('new-user')
             } else {
               setUserType('allready-guest-user')
             }
             getOtp()
           }
         }
       } else {
         setLoader(false)
       }
     })
   }

   const configureCaptcha = () => {
     window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
       'sign-in-button',
       {
         size: 'invisible',
         callback: (response) => {
           // reCAPTCHA solved, allow signInWithPhoneNumber.
           getOtp()
           console.log('Recaptca varified')
         },
         defaultCountry: 'IN',
       }
     )
   }

   const getOtp = () => {
     configureCaptcha()
     let phone = '+91' + mobileNumber.replace('-', '')
     phone = phone.replace(' ', '')
     console.log('abc', phone)
     const appVerifier = window.recaptchaVerifier

     firebase
       .auth()
       .signInWithPhoneNumber(phone, appVerifier)
       .then((confirmationResult) => {
         console.log('OTP has been sent', confirmationResult)
         // SMS sent. Prompt user to type the code from the message, then sign the
         // user in with confirmationResult.confirm(code).
         window.confirmationResult = confirmationResult
         console.log('OTP has been sent', confirmationResult)
         onContinue({ page: 'otp', phone: mobileNumber })
         // ...
       })
       .catch((error) => {})
   }
  return (
    <Box sx={{ p: '12px 18px' }}>
      <Typography
        variant='h5'
        component='h3'
        color={headingColor}
        fontSize={{ xs: 15 }}
        textAlign='center'
        lineHeight={'28px'}
      >
        Enter your mobile number
      </Typography>
      <div id='sign-in-button'></div>
      <NumberInput
        value={mobileNumber}
        onChange={(e) => {
          if (isError) setIsError(false)
          const value = e.target.value
            .replace(/[^0-9.]/g, '')
            .replace(/(\..*)\./g, '$1')
          //   values.phone = e.target.value
          setMobileNumber(value)
        }}
        //   onBlur={() => {
        //     handleInputChange(values.phone, 'phone')
        //   }}
        isError={isError}
        errorMsg='Please enter a valid mobile number'
        autoFocus={true}
        icon={<PhoneIphoneIcon sx={{ fontSize: '20px', color: '#737373' }} />}
        showAutoComplete
      />
      <Button
        sx={{
          ...styles.actionBtn(),
          border: loader ? 'none' : `1px solid ${blackColor}`,
        }}
        variant='contained'
        fullWidth
        disableElevation
        size='small'
        type='button'
        onClick={handleSubmit}
        disabled={loader}
      >
        Continue
      </Button>
    </Box>
  )
}

export default MobileNumberScreen

const styles = {
  actionBtn: (outline) => ({
    fontSize: "15px",
    fontWeight: 600,
    color: outline ? blackColor : whiteColor,
    p: "3px 20px",
    mt: "18px !important",
    border: "1px solid " + blackColor,
    backgroundColor: outline ? whiteColor : blackColor,
    borderRadius: "5px",
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: outline ? blackColor : whiteColor,
      color: outline ? whiteColor : blackColor,
    },
    letterSpacing: "0.5px",
  }),
};
