import { Box, Container, Typography, useMediaQuery } from '@mui/material'
import FooterMui from 'components/FooterMui'
import React from 'react'
import ParagraphWithHeader from './TermsAndConditions/ParagraphWithHeader'
import { backgroundColor01, bgColor, blackColor, greyShade13, greyShade66 } from '../configs/styles/muiThemes'
import TopComponent from './TopComponent'
import BreadcrumbsSection from 'components/Breadcrumbs'

function ReturnPolicy() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box backgroundColor={backgroundColor01}>
      <TopComponent value="Return-Policy" bannerHide disableBanners />
      <Container
        sx={{ maxWidth: "1380px !important", mt: 5, mb: { md: 0, xs: 6 } }}
      >
        <Box>
          <BreadcrumbsSection
            links={[{ label: "Return Policy", to: `/return-policy` }]}
          />
        </Box>

        <Box sx={{ py: 2, px: 0 }}>
          <Typography
            fontSize={{ md: 24, xs: 18 }}
            fontWeight={600}
            lineHeight={1.5}
            color={blackColor}
            pb={{ md: 1.5, xs: 0.7 }}
            mt={{md:0,xs:1}}
          >
            Return Policy
          </Typography>
          <Typography
            color={greyShade66}
            fontSize={13.5}
            fontWeight={400}
            pb={{ md: 2, xs: 1 }}
            lineHeight="25px"
            textAlign="justify"
            width={"100%"}
          >
            costBo is an e-commerce enabler for brands and manufacturers. We
            power the brand discovery by retailers, bulk buyers & End Consumers
            across PAN India by various means of SEO & through enrolling into
            ONDC.
          </Typography>
          <Typography
            color={greyShade66}
            fontSize={13.5}
            fontWeight={400}
            pb={{ md: 2, xs: 1 }}
            lineHeight="25px"
            textAlign="justify"
            width={"100%"}
          >
            Brands may choose from one of the available return options below as
            formulated by costBo team. Please see the individual brand webstore
            for the specific return policy. Chat with the business directly
            before Returns.
          </Typography>
          <NumberList
            data={[
              "No Returns",
              "Returns accepted for 14 working days from the date of invoice",
              "Returns accepted for 30 days from the date of invoice",
            ]}
          />
          <Typography
            color={greyShade66}
            fontSize={13.5}
            fontWeight={400}
            pb={{ md: 2, xs: 1 }}
            lineHeight="25px"
            textAlign="justify"
            width={"100%"}
          >
            On arbitration between buyers & brands
          </Typography>
          <NumberList
            data={[
              "Picture of packaging - before shipment will be collected from brands",
              "Picture of products received will be collected from buyers",
              "Will help arbitrate returns meeting the above criteria chosen by brands",
            ]}
          />
          <Typography
            color={greyShade66}
            fontSize={13.5}
            fontWeight={400}
            pb={{ md: 2, xs: 1 }}
            lineHeight="25px"
            textAlign="justify"
            width={"100%"}
          >
            Once a return is approved by the brand, customer will receive
          </Typography>
          <BulletList
            data={[
              "100% refund of invoice amount  - for any defective/damaged products",
              "80% refund of invoice amount -  if the return is for any other reason",
            ]}
          />
          {/* <ParagraphWithHeader
            title='Cancellation Policy'
            paragraph={[
              'All E-commerce transactions are final on completing the purchase. Buyers / Brands will not be able to initiate a cancellation.',
              'All cancellations if necessary will be facilitated by costBo’s centralized Support.',
              'For cancellation requests email to ecommerce@costbo.com or Whatsapp Chat to 8088422611',
            ]}
          /> */}
          <Box id="cancellationPolicy">
            <Typography
              fontSize={{ md: 24, xs: 19 }}
              fontWeight={600}
              lineHeight={1.5}
              color={blackColor}
              pb={{ md: 1.5, xs: 0.7 }}
            >
              Cancellation Policy
            </Typography>
            <Typography
              color={greyShade66}
              fontSize={13.5}
              fontWeight={400}
              pb={{ md: 2, xs: 1 }}
              lineHeight="25px"
              textAlign="justify"
              width={"100%"}
            >
              All E-commerce transactions are final on completing the purchase.
              Buyers / Brands will not be able to initiate a cancellation.
            </Typography>
            <Typography
              color={greyShade66}
              fontSize={13.5}
              fontWeight={400}
              pb={{ md: 2, xs: 1 }}
              lineHeight="25px"
              textAlign="justify"
              width={"100%"}
            >
              All cancellations if necessary will be facilitated by costBo’s
              centralized Support.
            </Typography>
            <Typography
              color={greyShade66}
              fontSize={13.5}
              fontWeight={400}
              pb={{ md: 2, xs: 1 }}
              lineHeight="25px"
              textAlign="justify"
              width={"100%"}
            >
              For cancellation requests email to ecommerce@costbo.com or
              Whatsapp Chat to 8088422611.
            </Typography>
          </Box>
        </Box>
      </Container>
      {matches && <FooterMui />}
    </Box>
  );
}

export default ReturnPolicy

const NumberList = ({ data }) => {
  return (
    <Box pb={2}>
      {data?.map((item, i) => (
        <Typography
          color={greyShade13}
          fontSize={{ md: 14, xs: 13 }}
          fontWeight={400}
          lineHeight={2}
          textAlign='justify'
          key={i}
        >
          {i + 1}. {item}
        </Typography>
      ))}
    </Box>
  )
}
const BulletList = ({ data }) => {
  return (
    <Box pb={2}>
      {data?.map((item, i) => (
        <Typography
          color={greyShade66}
          fontSize={13.5}
          fontWeight={400}
          lineHeight={2}
          textAlign='justify'
          key={i}
        >
          &#x2022; {item}
        </Typography>
      ))}
    </Box>
  )
}
