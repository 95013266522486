import { Box, Container, useMediaQuery } from "@mui/material";
import FullHeightLoader from "components/FullHeightLoader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCartDetails } from "redux/addToCart/AddToCartSlice";
import { getCoupons } from "redux/dealsCoupons/dealsCouponsSlice";
import { getCommercialDetails } from "redux/productDescription/ProductDescriptionSlice";
import { getBusinessIdDetails } from "redux/rootSlice/RootSlice";
import {
  getCategoryDataFinder,
  getDataForSideBar,
} from "redux/subCatergory/subCategorySlice";
import { getUserName, removeSessionInfo } from "utils/authHelpers";
import FooterMui from "../components/FooterMui";
import { getOverviewDetails } from "../redux/overview/overviewSlice";
import OverviewContentNew from "./Overview/OverviewContentNew";
import TopComponent from "./TopComponent";
import { backgroundColor01, whiteColor } from "configs/styles/muiThemes";
import BreadcrumbsSection from "components/Breadcrumbs";
import Loader from "components/Loader";
import HeaderLoader from "components/skeletonLoaders/HeaderLoader";
import OverviewLoader from "components/skeletonLoaders/overview/OverviewLoader";
import OverviewMobileLoader from "components/skeletonLoaders/overview/OverviewMobileLoader";

function Overview() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const { businessName } = useParams();
  const dispatch = useDispatch();
  const { businessDetails } = useSelector((state) => state.header);
  const { overviewDetails, loading } = useSelector((state) => state.overview);
  const businessIdData = sessionStorage.getItem("businessId");

  const { businessId } = useSelector((state) => state.rootSlice);
  const [isLoggedIn, setIsLoggedIn] = useState(
    getUserName().length === 0 ? false : true
  );
  const { commercialDetails } = useSelector((state) => state.product);
  const { cartDetails } = useSelector((state) => state.cart);
  const { allProducts, productsByCategory } = useSelector(
    (state) => state.subCategory
  );

  const getBusinessData = () => {
    const localBusinessName = sessionStorage.getItem("businessName");
    if (businessName !== localBusinessName) {
      console.log("BUSINESS_NAME_CHANGED");
      removeSessionInfo();
      // sessionStorage.setItem('cartData', 'null')
    }

    dispatch(getBusinessIdDetails(businessName));
    sessionStorage.setItem("businessName", businessName);
  };

  useEffect(() => {
    getBusinessData();
  }, [businessName]);

  useEffect(() => {
    if (businessIdData !== null) {
      dispatch(getDataForSideBar());
      dispatch(getCategoryDataFinder());
      dispatch(getOverviewDetails());
      if (!commercialDetails) {
        dispatch(getCommercialDetails());
      }
      if (isLoggedIn) {
        if (!cartDetails) {
          dispatch(getCartDetails(businessId));
        }
      }
    }

    return () => {
      // dispatch(clearSearchData())
    };
  }, [businessId]);

  // useEffect(() => {
  //   if (businessId?.validate) {
  //     console.log("Valid_BusinessID");
  //     dispatch(getCoupons());
  //   }
  // }, [businessId]);
  return (
    <Box
      backgroundColor={backgroundColor01}
      minHeight={{ xs: "auto", md: "800px" }}
    >
      {/* businessId : {JSON.stringify(businessId !== null)} <br />
      overviewDetails : {JSON.stringify(overviewDetails !== null)} <br />
      businessIdData : {JSON.stringify(businessIdData !== null)} <br /> */}

      {/* <Box sx={{height:'80px'}}></Box> */}
      {businessId !== null && businessIdData !== null ? (
        <>
          <Box
            sx={{
              position: "sticky",
              top: { md: 0, xs: "0px" },
              width: "100%",
              zIndex: 100,
              backgroundColor: { xs: whiteColor, md: backgroundColor01 },
            }}
          >
            <Box minHeight={{ xs: "auto", md: "126.7px" }} bgcolor={whiteColor}>
              {commercialDetails !== null ? (
                // &&
                // productsByCategory?.length > 0 &&
                // allProducts !== null
                <>
                  {matches && (
                    <TopComponent
                      value={"overview"}
                      bannerHide
                      disableBanners
                    />
                  )}
                </>
              ) : (
                <HeaderLoader hideBanners={!matches} />
              )}
            </Box>

            <Container
              sx={{
                display: { md: "block", xs: "none" },
                maxWidth: "1360px !important",
                p: "20px 15px 0px 15px !important",
                // mt: { xs: 8, md: 2.5 },
              }}
              backgroundColor={whiteColor}
            >
              <BreadcrumbsSection
                links={[
                  // { label: "Home", to: `/` },
                  { label: "Overview", to: `/overview` },
                ]}
              />
            </Container>
          </Box>

          {overviewDetails && commercialDetails !== null ? (
            // &&
            // productsByCategory?.length > 0 &&
            // allProducts !== null
            <>
              {!matches && <TopComponent value={"overview"} bannerHide />}
              {businessDetails && (
                <OverviewContentNew data={overviewDetails} loading={loading} />
              )}
              {matches && businessDetails && <FooterMui />}
            </>
          ) : matches ? (
            <OverviewLoader />
          ) : (
            !businessDetails && <OverviewMobileLoader />
          )}
        </>
      ) : (
        <FullHeightLoader />
      )}
    </Box>
  );
}
export default Overview;
