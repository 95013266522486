import { Box, Skeleton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import {
  dashboardDescriptionColor,
  headingColor,
  stepperInactiveColor,
} from "../../configs/styles/muiThemes";

function DashboardCard({ icon, title, value, linkPath }) {
  let businessFullName = sessionStorage.getItem("businessFullName");

  return (
    <Box
      display={"flex"}
      sx={{
        background: "#f3f3f8",
        padding: "10px 10px",
        borderRadius: "8px",
        border: "1px solid #e9e9f1",
        marginBottom: "14px",
        // width: '100%',
        gap: 1,
      }}
      component={Link}
      to={linkPath ? linkPath : "#"}
    >
      <Box
        component="img"
        maxHeight={{ xs: "40px", md: "40px" }}
        borderRadius={"8px"}
        src={icon}
        alt="CostBo"
      />
      <Box display={"flex"} flexDirection={"column"} ml={1}>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 400,
            color: "#000000",
            lineHeight: "14px",
            marginBottom: "2px",
          }}
        >
          {title}
        </Typography>
        {value || value == 0 ? (
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: 600,
              color: "#000000",
              lineHeight: "14px",
              mt: 1,
            }}
          >
            {value}
          </Typography>
        ) : (
          <Skeleton
            variant="rounded"
            width="20px"
            height="12px"
            sx={{ mt: "8px !important" }}
          />
        )}
      </Box>
    </Box>
    // <Box
    //   p={1.25}
    //   borderRadius={1}
    //   sx={{ backgroundColor: stepperInactiveColor, borderRadius: '6px' }}
    //   display='flex'
    //   alignItems='center'
    //   component={Link}
    //   to={linkPath ? linkPath : '#'}

    // >
    //   <Box
    //     src={icon}
    //     component='img'
    //     alt={businessFullName + ' CostBo'}
    //     sx={{
    //       width: { xs: '26px', md: '26px' },
    //       height: { xs: '26px', md: '26px' },
    //     }}
    //     textAlign='center'
    //   />
    //   <Box ml={1.875}>
    //     <Typography
    //       variant='h6'
    //       fontSize={{ xs: 13, md: 14 }}
    //       fontWeight={400}
    //       color={dashboardDescriptionColor}
    //     >
    //       {title}
    //     </Typography>
    //     <Typography
    //       variant='h5'
    //       fontSize={{ xs: 14, md: 16 }}
    //       color={headingColor}
    //     >
    //       {value}
    //     </Typography>
    //   </Box>
    // </Box>
  );
}

export default DashboardCard;
