import React from 'react'
import styled from '@emotion/styled'
import { Box, InputAdornment, TextField, Typography } from '@mui/material'
import {
  backgroundColor01,
  blackColor,
  blackShade17,
  borderInputTextbox,
  errorTextColor,
  greyShade51,
  greyShade54,
  redColor,
} from '../../configs/styles/muiThemes'
import { allowOnlyEnglish } from 'utils/utilities';

function StyledTextInput({
  label,
  placeholder,
  name,
  value,
  required,
  onChange,
  startIcon,
  endIcon,
  maxLength,
  inputType,
  disabled,
  isError,
  errorMsg,
  isReadOnly,
}) {

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const sanitizedValue = allowOnlyEnglish(inputValue)

    if(onChange){
      onChange({ target: { value: sanitizedValue } });
    }
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Typography
        variant='body2'
        fontSize={isReadOnly ? 12.5 : 13}
        fontWeight={400}
        color={greyShade54}
        mb='4px !important'
      >
        {label}
        {!isReadOnly
          ? required && <span style={{ color: redColor }}>*</span>
          : ''}
      </Typography>

      {!isReadOnly ? (
        <>
          <StyledTextField
            variant='standard'
            required={required}
            fullWidth
            name={name}
            disabled={disabled}
            value={value}
            onChange={handleInputChange}
            placeholder={placeholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position='end' sx={{ cursor: 'pointer' }}>
                  {startIcon}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='start' sx={{ cursor: 'pointer' }}>
                  {endIcon}
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
            inputProps={{ maxLength: maxLength}}
            type={inputType || 'text'}
            maxLength={maxLength}
          />

          <Typography
            variant='body2'
            fontSize={12}
            fontWeight={500}
            color={errorTextColor}
            mt='10px !important'
          >
            {errorMsg}
          </Typography>
        </>
      ) : (
        <Typography
          variant='body2'
          fontSize={14}
          fontWeight={600}
          color={blackColor}
          mt='4px !important'
        >
          {value}
        </Typography>
      )}
    </Box>
  )
}

export default StyledTextInput

const StyledTextField = styled(TextField)(({ disabled, isReadOnly }) => ({
  border: `1px solid ${greyShade51}`,
  borderRadius: '5px',
  boxShadow: '0 2px 3px rgb(239 239 239)',
  backgroundColor: backgroundColor01,
  '& .MuiInputBase-input': {
    color: blackColor,
    fontSize: 14,
    fontWeight: 500,
    padding: '8px 10px',
  },

  '& .Mui-disabled': {
    '-webkit-text-fill-color': 'rgba(0, 0, 0, 1) !important',
  },
}))
