import { getProfileId } from "utils/authHelpers";
import { getApiInstance } from "../../ApiInstance";
import {
  ONDC_FULL_SPECIFICATION,
  ONDC_SPECIFICATION,
  SIMILAR_PRODUCTS,
} from "services/constant";
import { getVariantCountAPI } from "../subCategory/SubCategoryApis";

export const getProductData = async (data) => {
  const businessId = sessionStorage.getItem("businessId");
  try {
    const instance = getApiInstance({
      header: { businessId: businessId },
    });

    const response = await instance.get(
      `product-query-side/api/v1/product/viewActiveProduct/${data}`
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
export const getSimilarProductData = async (data) => {
  try {
    const instance = getApiInstance({
      header: { businessId: data?.businessId },
    });

    const response = await instance.get(
      SIMILAR_PRODUCTS,
      // `product-query-side/api/v1/product/getSimilarProducts`,
      { params: { pageNo: 1, subCatCode: data?.subCat } }
    );

    if (response?.data?.details?.length > 0) {
      const array = [...response?.data?.details];
      const masterProductVariantIds = array
        .filter((item) => item?.variantInfo?.masterProductVariantId) // Filter only those with a master property
        .map((item) => item?.variantInfo?.masterProductVariantId); // Map to the master.id property
      const countRes = await getVariantCountAPI(
        data?.businessId,
        masterProductVariantIds
      );

      array.forEach((item) => {
        // Check if the current item has a master property
        if (item.variantInfo?.masterProductVariantId) {
          // Find a matching item in the second array
          const match = countRes?.productVariantCount?.find(
            (e) =>
              e.masterProductVariantId ===
              item.variantInfo?.masterProductVariantId
          );

          // If a match is found, add the count to the first array's object
          if (match) {
            item.count = match.count;
          }
        }
      });

      console.log("SIMILAR__VARIANT_PRODUCTS:", array);

      return { details: array };
    }

    return response?.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
export const getCommercialData = async () => {
  // const businessId = 'f7b2fa9797ce4e8f858bd372693d0980'
  const businessId = sessionStorage.getItem("businessId");
  try {
    const instance = getApiInstance({
      header: { businessId: businessId },
    });

    const response = await instance.get(
      `business-query-side/api/v1/business/getCommerceInfo`
    );
    sessionStorage.setItem("commerceInfo", JSON.stringify(response?.data));
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getOfferOrder = async (productId) => {
  const businessId = sessionStorage.getItem("businessId");
  const profileId = getProfileId();

  try {
    const instance = getApiInstance({
      header: { businessId: businessId, profileId: profileId },
    });

    const response = await instance.get(
      `cart-query-side/api/v2/cart/consumer/offerOrder/${productId}`
    );
    console.log("OFFER_ORDER_DATA", response.data);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getOndcSpecificationAPI = async (params) => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(ONDC_SPECIFICATION, {
      params,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getOndcFullSpecificationAPI = async (params) => {
  try {
    const instance = getApiInstance();
    const response = await instance.get(ONDC_FULL_SPECIFICATION, {
      params,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
