import { Box, Divider, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import {
  blackColor,
  blackShade20,
  blackShade21,
  greyShade42,
  orangeColor01,
  whiteColor,
} from "../../configs/styles/muiThemes";

import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'

import VerticalTimeline from './TrackingPopup/VerticalTimeline'
import Loader from 'components/Loader'
import { addThousandsSeparator } from 'utils/utilities'
import {
  getPayCartDelhiveryStatusDetails,
  getPayCartTelyportStatusDetails,
} from 'services/api_calls/orderdetails_api/OrderDetailsApi'

const TrackingPopupContent = ({ cartData, paycartId, businessId }) => {
  const dispatch = useDispatch()
  const [deliveryType, setDeliveryType] = useState('')
  const [cartInfo, setCartInfo] = useState(null)

  //   const { dehiveryStatusData, telyportStatusData, statusLoader } = useSelector(
  //     (state) => state.orderdetails
  //   )

  const [dehiveryStatusData, setDehiveryStatusData] = useState(null)
  const [telyportStatusData, setTelyportStatusData] = useState(null)

  const [statusLoader, setStatusLoader] = useState(false)

  //   const fetchCartInfo = async () => {

  //     const response = await getPayCartApi({
  //       cartId: paycartId,
  //       businessId: businessId,
  //     })

  //     if (response?.cart) {
  //       setCartInfo(response?.cart || null)
  //     }
  //   }

  const getDelhiveryStatusInfo = async (carrierTrackId) => {
    setStatusLoader(true)
    const statusRes = await getPayCartDelhiveryStatusDetails(carrierTrackId)

    if (statusRes) {
      setDehiveryStatusData(statusRes)
    }
    setStatusLoader(false)
  }

  const getTelyportStatusInfo = async (carrierTrackId) => {
    setStatusLoader(true)
    const statusRes = await getPayCartTelyportStatusDetails(carrierTrackId)

    if (statusRes) {
      setTelyportStatusData(statusRes)
    }
    setStatusLoader(false)
  }

  useEffect(() => {
    if (cartData) {
      //   fetchCartInfo(cartData)

      const orderDeliveryType =
        cartData?.deliveryType?.type?.toLowerCase() || ''

      if (
        orderDeliveryType == 'delhivery' ||
        orderDeliveryType == 'delhiveryb2b'
      ) {
        if (cartData?.shipmentInfo?.carrierTrackId) {
          getDelhiveryStatusInfo(cartData?.shipmentInfo?.carrierTrackId)
        }
      }

      if (orderDeliveryType.toLowerCase() == 'telyport') {
        if (cartData?.shipmentInfo?.carrierTrackId) {
          getTelyportStatusInfo(cartData?.shipmentInfo?.carrierTrackId)
        }
      }

      setDeliveryType(cartData?.deliveryType?.type || '')
    }

    return () => {
      //   dispatch(clearDeliveryStatusInfo())
    }
  }, [cartData])

  return (
    <Box sx={styles.card}>
      <Box sx={styles.cardHeader}>
        <Typography
          variant="h5"
          fontSize={16}
          fontWeight={500}
          color={whiteColor}
          mb="14px"
        >
          Order Id : #{cartData?.orderId}
        </Typography>

        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography
              variant="h6"
              fontSize={13}
              fontWeight={500}
              color={whiteColor}
              lineHeight="22px"
            >
              Tracking Id :{" "}
              {deliveryType?.toLowerCase() == "delhivery" ||
              deliveryType?.toLowerCase() == "delhiveryb2b"
                ? dehiveryStatusData?.ShipmentData
                  ? dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.AWB
                  : cartData?.shipmentInfo?.carrierTrackId
                : null}
              {deliveryType == "business" || deliveryType == "Business"
                ? cartData?.shipmentInfo?.carrierTrackId || "Not Available"
                : null}
              {deliveryType?.toLowerCase() == "telyport"
                ? cartData?.shipmentInfo?.carrierTrackId || "Not Available"
                : null}
            </Typography>

            <Typography
              variant="body1"
              fontSize={12}
              fontWeight={400}
              color={whiteColor}
              lineHeight="20px"
            >
              {cartData?.totalQuantity} Items | Rs{" "}
              {cartData?.orderTotalCost
                ? addThousandsSeparator(cartData?.orderTotalCost)
                : 0}
            </Typography>
          </Box>

          <Box sx={styles.statusTag}>
            {dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.Status?.Status ||
            deliveryType == "business" ||
            deliveryType == "Business" ? (
              <Typography
                variant="body1"
                fontSize={12}
                fontWeight={500}
                color={blackShade21}
                lineHeight="20px"
              >
                {deliveryType == "delhivery" || deliveryType == "delhiveryb2b"
                  ? dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.Status
                      ?.Status
                  : null}

                {deliveryType == "business" || deliveryType == "Business"
                  ? cartData?.orderStatus
                  : null}

                {deliveryType?.toLowerCase() == "telyport"
                  ? telyportStatusData?.data?.orderStatus
                  : null}
              </Typography>
            ) : null}
          </Box>
        </Box>

        <Box
          sx={{ my: 2, fontWeight: 400, borderTop: `1px dashed #ffffff4a` }}
        ></Box>
        <Box>
          <Box display="flex" alignItems="center" gap="10px" mb={1}>
            <RadioButtonUncheckedOutlinedIcon
              sx={{ color: whiteColor, fontSize: "20px" }}
            />
            <Typography
              variant="h6"
              color={whiteColor}
              fontSize={12}
              fontWeight={400}
            >
              {cartData?.businessAddress?.addressLine1}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" gap="10px">
            <FmdGoodOutlinedIcon sx={{ color: whiteColor, fontSize: "20px" }} />

            <Typography
              variant="h6"
              color={whiteColor}
              fontSize={12}
              fontWeight={400}
            >
              {cartData?.customerAddress?.addressLine1}
            </Typography>
          </Box>
        </Box>
      </Box>

      {statusLoader && (
        <Box height="100px">
          <Loader />
        </Box>
      )}

      {(cartData?.shipmentInfo == null || dehiveryStatusData?.Error) && (
        <Box
          sx={{ background: whiteColor, p: 3 }}
          justifyContent="center"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box sx={styles.iconContainer}>
            <LocalShippingOutlinedIcon sx={{ fontSize: "24px" }} />{" "}
          </Box>

          <Typography color={blackColor} fontWeight={500}>
            {dehiveryStatusData?.Error
              ? "Tracking information not available for this order"
              : "Tracking Information not available yet"}
          </Typography>
        </Box>
      )}

      {cartData?.shipmentInfo != null &&
      (deliveryType == "delhivery" || deliveryType == "delhiveryb2b") &&
      dehiveryStatusData?.ShipmentData ? (
        <Box
          sx={{
            background: whiteColor,
            height: "400px",
            overflowY: "auto",
            p: "15px",
          }}
        >
          <Typography
            variant="h4"
            fontSize={17}
            fontWeight={600}
            lineHeight="38px"
            color={blackShade20}
          >
            History
          </Typography>

          <VerticalTimeline
            data={dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.Scans}
          />

          {dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.Status?.Status ==
          "Delivered" ? (
            <Box>
              <Divider />
              <Box my={1}>
                <Typography variant="body1" sx={styles.infoText}>
                  {
                    dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.Status
                      ?.Status
                  }{" "}
                  on{" "}
                  {moment(
                    dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.Status
                      ?.StatusDateTime
                  ).format("MMM DD, YYYY")}
                </Typography>

                <Typography variant="body1" sx={styles.infoText}>
                  {
                    dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.Status
                      ?.Instructions
                  }
                </Typography>

                <Typography variant="body1" sx={styles.infoText}>
                  {
                    dehiveryStatusData?.ShipmentData?.[0]?.Shipment?.Status
                      ?.StatusLocation
                  }
                </Typography>
              </Box>
            </Box>
          ) : null}
        </Box>
      ) : null}

      {cartData?.shipmentInfo != null &&
      (deliveryType == "business" || deliveryType == "Business") ? (
        <Box
          sx={{
            background: whiteColor,
            p: 2,
            minHeight: "75px",
            overflowY: "auto",
          }}
        >
          {cartData?.shipmentInfo?.carrierName ? (
            <Box mb={1}>
              <Typography
                fontSize={14.5}
                fontWeight={600}
                color={blackShade21}
                display="inline"
              >
                Carrier Name :{" "}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                color={blackShade21}
                display="inline"
              >
                {cartData?.shipmentInfo?.carrierName}
              </Typography>
            </Box>
          ) : null}

          {cartData?.shipmentInfo?.carrierURL ? (
            <Box mb={1}>
              <Typography
                fontSize={14.5}
                fontWeight={600}
                color={blackShade21}
                display="inline"
              >
                Carrier Tracking :{" "}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                color={blackShade21}
                display="inline"
              >
                {cartData?.shipmentInfo?.carrierURL}
              </Typography>
            </Box>
          ) : null}

          {cartData?.shipmentInfo?.deliveryMode ? (
            <Box mb={1}>
              <Typography
                fontSize={12}
                fontWeight={600}
                color={greyShade42}
                display="inline"
              >
                Delivery Mode :{" "}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                color={blackShade21}
                display="inline"
              >
                {cartData?.shipmentInfo?.deliveryMode}
              </Typography>
            </Box>
          ) : null}

          {cartData?.shipmentInfo?.deliveryPersonName ? (
            <Box mb={1}>
              <Typography
                fontSize={14.5}
                fontWeight={600}
                color={blackShade21}
                display="inline"
              >
                Delivery Person Name :{" "}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                color={blackShade21}
                display="inline"
              >
                {cartData?.shipmentInfo?.deliveryPersonName}
              </Typography>
            </Box>
          ) : null}

          {cartData?.shipmentInfo?.deliveryPersonPhone ? (
            <Box>
              <Typography
                fontSize={14.5}
                fontWeight={600}
                color={blackShade21}
                display="inline"
              >
                Delivery Person Phone number :{" "}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                color={blackShade21}
                display="inline"
              >
                {cartData?.shipmentInfo?.deliveryPersonPhone}
              </Typography>
            </Box>
          ) : null}
        </Box>
      ) : null}

      {cartData?.shipmentInfo != null &&
      deliveryType?.toLowerCase() == "telyport" ? (
        <Box
          sx={{
            background: whiteColor,
            p: 2,
            minHeight: "75px",
            overflowY: "auto",
          }}
        >
          {telyportStatusData?.data?.orderStatus ? (
            <>
              <Box mb={1}>
                <Typography sx={styles.labelText}>Order Status : </Typography>
                <Typography sx={styles.valueText}>
                  {telyportStatusData?.data?.orderStatus}
                </Typography>
              </Box>

              <Box mb={1}>
                <Typography sx={styles.labelText}>
                  Tracking Number :{" "}
                </Typography>
                <Typography sx={styles.valueText}>
                  {telyportStatusData?.data?.trackableID}
                </Typography>
              </Box>

              <Box
                mb={1}
                onClick={() => {
                  if (telyportStatusData?.data?.trackingLink) {
                    window.open(
                      telyportStatusData?.data?.trackingLink,
                      "_blank"
                    );
                  }
                }}
              >
                <Typography
                  fontSize={14.5}
                  fontWeight={600}
                  color={blackShade21}
                  display="inline"
                >
                  Tracking Link :{" "}
                </Typography>
                <Typography
                  fontSize={12}
                  fontWeight={500}
                  color={orangeColor01}
                  display="inline"
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  {telyportStatusData?.data?.trackingLink}
                </Typography>
              </Box>

              <Box mb={1}>
                <Typography sx={styles.labelText}>Agent Name: </Typography>
                <Typography sx={styles.valueText}>
                  {telyportStatusData?.data?.agentDetails?.agent_info?.name}
                </Typography>
              </Box>

              <Box mb={1}>
                <Typography sx={styles.labelText}>Phone Number: </Typography>
                <Typography sx={styles.valueText}>
                  +91{" "}
                  {telyportStatusData?.data?.agentDetails?.agent_info?.phone}
                </Typography>
              </Box>
            </>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
}

export default TrackingPopupContent

const styles = {
  card: {
    backgroundColor: whiteColor,
    borderRadius: '12px',
    overflow: 'hidden',
  },

  cardHeader: {
    backgroundColor: blackShade21,
    p: '15px',
  },

  statusTag: {
    backgroundColor: whiteColor,
    borderRadius: '5px',
    p: '0px 14px',
  },

  iconContainer: {
    width: '50px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    background: 'rgb(5 60 81 / 7%)',
    padding: '12px',
    mb: '10px',
  },

  infoText: {
    fontSize: 12,
    fontWeight: 400,
    color: greyShade42,
    lineHeight: '20px',
  },

  labelText: {
    fontSize: 14.5,
    fontWeight: 600,
    color: blackShade21,
    display: 'inline',
  },

  valueText: {
    fontSize: 14,
    fontWeight: 500,
    color: blackShade21,
    display: 'inline',
  },
}
