import { Box, Typography } from '@mui/material'
import { blackColor, greyShade66, headingColor, tncColor } from 'configs/styles/muiThemes'
import React from 'react'

export default function ParagraphWithHeader({ title, paragraph }) {
  return (
    <Box pb={1}>
      <Typography
        color={blackColor}
        variant='h5'
        fontSize={17}
        fontWeight={600}
        component='h4'
        pb={1.6}
      >
        {title}
      </Typography>
      {paragraph?.map((item, i) => {
        return (
          <Typography
            color={greyShade66}
            fontSize={13.5}
            fontWeight={400}
            pb={2}
            lineHeight='25px'
            textAlign='justify'
          >
            {item}
          </Typography>
        )
      })}
    </Box>
  )
}
