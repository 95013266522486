import {
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  backgroundColor01,
  blackColor,
  borderCart,
  borderLineColor,
  filterMobileColor,
  greyShade46,
  headingColor,
  orangeColor01,
  secondaryColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

function CatalogHeader({
  onFilterClick,
  itemCount,
  totalPrice,
  category,
  products,
  viewByFilter,
  handleViewByCategoryClick,
  handleViewAllProducts,

  // All Products Filter props
  displaySelection,
  totalProduct,
  onSort,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const businessName = sessionStorage.getItem("businessName");

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(displaySelection);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    console.log("DATA : ", event.target.innerText);
    setSelectedOption(event.target.innerText);
    onSort(event.target.innerText);
  };

  return matches ? (
    <Box
      backgroundColor={backgroundColor01}
      borderBottom={"1px solid " + borderLineColor}
      sx={{ minHeight: "58px", display: "flex", alignItems: "center" }}
      mt={{ xs: 0, md: 1 }}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        py={{ md: 1.5, xs: 0.5 }}
      >
        <Grid item>
          <Typography
            variant="h2"
            fontSize={{ md: "16px", xs: "16px" }}
            color={headingColor}
          >
            Catalog
          </Typography>
        </Grid>

        <Grid item display={{ xs: "block", md: "none" }}>
          <Button
            startIcon={<FilterAltOutlinedIcon />}
            sx={styles.btnHover}
            onClick={() => {
              onFilterClick();
            }}
          >
            Filter
          </Button>
        </Grid>

        <Grid item xs={9.15}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" gap={1.6}>
              <Button
                sx={{
                  ...styles.actionBtn,
                  textTransform: "uppercase",
                  backgroundColor:
                    viewByFilter === "category" ||
                    (category?.length === 1 &&
                      category?.[0]?.subCat?.length === 1 &&
                      viewByFilter === "filtered-products")
                      ? blackColor
                      : whiteColor,
                  color:
                    viewByFilter === "category" ||
                    (category?.length === 1 &&
                      category?.[0]?.subCat?.length === 1 &&
                      viewByFilter === "filtered-products")
                      ? whiteColor
                      : blackColor,

                  "&:hover": {
                    backgroundColor:
                      viewByFilter === "category" ||
                      (category?.length === 1 &&
                        category?.[0]?.subCat?.length === 1 &&
                        viewByFilter === "filtered-products")
                        ? blackColor
                        : whiteColor,
                    color:
                      viewByFilter === "category" ||
                      (category?.length === 1 &&
                        category?.[0]?.subCat?.length === 1 &&
                        viewByFilter === "filtered-products")
                        ? whiteColor
                        : blackColor,
                  },
                }}
                onClick={handleViewByCategoryClick}
              >
                View By Category
              </Button>

              <Button
                sx={{
                  ...styles.actionBtn,
                  backgroundColor:
                    viewByFilter === "all-products" ? blackColor : whiteColor,
                  color:
                    viewByFilter === "all-products" ? whiteColor : blackColor,
                  textTransform: "uppercase",

                  "&:hover": {
                    backgroundColor:
                      viewByFilter === "all-products" ? blackColor : whiteColor,
                    color:
                      viewByFilter === "all-products" ? whiteColor : blackColor,
                  },
                }}
                onClick={handleViewAllProducts}
              >
                View All Products
              </Button>
            </Box>

            {viewByFilter == "all-products" && (
              <Box>
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  sx={styles.dropdownBtn}
                  endIcon={
                    <KeyboardArrowDownOutlinedIcon
                      sx={{ fontSize: "17px !important" }}
                    />
                  }
                >
                  {selectedOption ? selectedOption : "Discount - High To Low"}
                </Button>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  variant="selectedMenu"
                >
                  <MenuItem onClick={handleClose}>Newest First</MenuItem>
                  <MenuItem onClick={handleClose}>
                    Discount - High To Low
                  </MenuItem>
                  <MenuItem onClick={handleClose}>Price - Low To High</MenuItem>
                  <MenuItem onClick={handleClose}>Price - High To Low</MenuItem>
                </Menu>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <Box
      sx={{
        // marginTop: '12px',
        marginBottom: "4px",
        backgroundColor: "#F3F3F8",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px 15px",
      }}
    >
      <Typography
        variant="h2"
        fontSize={{ md: "19px", xs: "16px" }}
        color={blackColor}
      >
        Catalog
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        gap="12px"
        mr="5px"
      >
        <Box
          component="img"
          src={`https://storage.googleapis.com/bodefaults/shopweb/home/list.svg`}
          alt={`list`}
          sx={{
            width: "20x",
            height: "20px",
            lineHeight: 1.5,
          }}
          onClick={() => category()}
        />
        <Box
          component="img"
          src={`https://storage.googleapis.com/bodefaults/shopweb/home/gird.svg`}
          alt={`list`}
          sx={{
            width: "20x",
            height: "20px",
            lineHeight: 1.5,
          }}
          onClick={() => products()}
        />
        <Box
          component="img"
          src={`https://storage.googleapis.com/bodefaults/shopweb/home/filter.svg`}
          alt={`list`}
          sx={{
            width: "28x",
            height: "28px",
          }}
          onClick={() => {
            onFilterClick();
          }}
        />
      </Box>
    </Box>
  );
}

export default CatalogHeader;

const styles = {
  gotoCardBtn: {
    fontSize: "13px",
    fontWeight: 600,
    color: whiteColor,
    p: "2px 15px",
    border: "2px solid " + orangeColor01,
    textTransform: "uppercase",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: whiteColor,
      color: orangeColor01,
    },
  },

  btnHover: {
    color: filterMobileColor,
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  actionBtn: {
    fontSize: 11,
    fontWeight: 600,
    color: whiteColor,
    bgcolor: blackColor,
    textTransform: "uppercase",
    lineHeight: "18px",
    p: "5px 15px",
    border: `1px solid ${blackColor}`,
    borderRadius: "6px",
  },

  dropdownBtn: {
    width: "200px",
    justifyContent: "space-between",
    fontSize: 12.5,
    fontWeight: 500,
    color: blackColor,
    textTransform: "none",
  },
};
