import {
  Box,
  Button,
  colors,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ModalCard from "components/ModalCard";
import React, { useEffect, useState } from "react";
import BranchCard from "./BranchCard";
import {
  backgroundColor01,
  blackColor,
  greenColor04,
  greenColor05,
  greyShade71,
  lightGreen01,
  whiteColor,
} from "configs/styles/muiThemes";
import Loader from "components/Loader";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useLocation, useNavigate } from "react-router-dom";
import SelectedBranchCardMinimal from "./SelectedBranchCardMinimal";

const mapContainerStyle = {
  width: "100%",
  height: "400px",
};

const defaultCenter = { lat: 28.7041, lng: 77.1025 };

const haversineDistance = (coord1, coord2) => {
  if (!coord1 || !coord2) return 0;

  const toRadians = (deg) => (deg * Math.PI) / 180;
  const R = 6371; // Radius of Earth in kilometers
  const lat1 = toRadians(coord1?.lat || 0);
  const lat2 = toRadians(coord2?.lat);
  const dLat = lat2 - lat1;
  const dLng = toRadians(coord2.lng - (coord1?.lng || 0));

  const a =
    Math.sin(dLat / 2) ** 2 +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLng / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in kilometers
};

const MultiBranchSelectionPopup = ({
  isModelOpen,
  setModelOpen,
  loader,
  branchInfo,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAzzu692ouH2AXFzesPIVLfHeO8RmhN44Q", // Replace with your API key
  });

  const currentBusinessId = sessionStorage.getItem("businessId");

  const [userLocation, setUserLocation] = useState(null);
  const [distances, setDistances] = useState([]);

  const [hoveredBranchId, setHoveredBranchId] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);
  const [currentZoom, setCurrentZoom] = useState(4.83); // Dynamic zoom
  const [dynamicZoom, setDynamicZoom] = useState(4.83);
  const [bounds, setBounds] = useState(null); // Map bounds for all markers

  const [isListView, setIsListView] = useState(true);

  // function to calculate zoom dynamically
  const calculateZoom = (bounds) => {
    const WORLD_DIM = { width: 256, height: 256 };
    const ZOOM_MAX = 21;

    const latDiff = bounds.getNorthEast().lat() - bounds.getSouthWest().lat();
    const lngDiff = bounds.getNorthEast().lng() - bounds.getSouthWest().lng();

    const latZoom = Math.log2(WORLD_DIM.height / latDiff);
    const lngZoom = Math.log2(WORLD_DIM.width / lngDiff);

    return Math.min(latZoom, lngZoom, ZOOM_MAX);
  };

  // Calculate bounds dynamically based on markers
  useEffect(() => {
    if (userLocation && branchInfo?.branchInfo?.length > 0 && isLoaded) {
      const calculatedDistances = branchInfo.branchInfo.map((branch) => {
        const branchCoords = {
          lat: branch.loc.coordinates[1],
          lng: branch.loc.coordinates[0],
        };
        const distanceValue =
          userLocation?.lat == 0
            ? 0
            : haversineDistance(userLocation, branchCoords).toFixed(2);
        return distanceValue;
      });
      setDistances(calculatedDistances);

      const bounds = new window.google.maps.LatLngBounds();
      branchInfo.branchInfo.forEach((branch) => {
        bounds.extend({
          lat: branch.loc.coordinates[1],
          lng: branch.loc.coordinates[0],
        });
      });

      // Calculate dynamic zoom based on bounds
      setMapCenter(bounds.getCenter().toJSON());
      const zoom = calculateZoom(bounds);
      console.log("ZOOM____", {
        zoom,
        distances,
        userLocation,
        branchInfo,
        isLoaded,
      });
      setDynamicZoom(zoom > 20 ? 10 : zoom + 0.5);
      setTimeout(() => {
        setCurrentZoom(zoom > 20 ? 10 : zoom + 0.501);
      }, 800);
      setBounds(bounds);
      setHoveredBranchId(null);
    }
  }, [userLocation, branchInfo, isLoaded]);

  // Get user's location
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          setUserLocation({
            lat: 0,
            lng: 0,
          });
          console.error("Error fetching location:", error);
        }
      );
    }
  }, []);

  const handleMouseEnter = (branch) => {
    setTimeout(() => {
      setHoveredBranchId(branch.branchId);
      // setMapCenter({
      //   lat: branch?.loc?.coordinates?.[1],
      //   lng: branch?.loc?.coordinates?.[0],
      // });
      setCurrentZoom(dynamicZoom);
    }, 100);
  };

  const handleMouseLeave = () => {
    setHoveredBranchId(null);
    // setCurrentZoom(dynamicZoom || 4.83);
    // if (bounds) {
    //   setMapCenter(bounds.getCenter().toJSON()); // Reset center dynamically
    // }
  };

  const handleBranchSelect = (branchData) => {
    if (currentBusinessId == branchData?.branchId) return;

    if (branchData?.costBoWebUrl) {
      sessionStorage.setItem("branchSelected", "true");
      const { protocol, host } = window.location;
      const newUrl = new URL(`${protocol}//${host}/${branchData.costBoWebUrl}`);
      newUrl.searchParams.set("branchSelected", "true");
      window.location.replace(newUrl.toString());
    }
  };

  // function to prepare address
  const getAddress = (businessArea) => {
    // Convert the array to a key-value pair object for easy lookup
    const keyValueData = businessArea.reduce((acc, item) => {
      acc[item.code] = item.value;
      return acc;
    }, {});

    // Extract the required fields
    const city = keyValueData["City"] || "";
    const state = keyValueData["State"] || "";
    const pincode = keyValueData["pincode"] || "";

    // Return the formatted string
    return `${city}, ${state} - ${pincode}`;
  };

  return (
    <ModalCard
      open={isModelOpen}
      handleClose={() => {
        sessionStorage.setItem("branchSelected", "true");
        setModelOpen(false);
      }}
      width="60%"
      mt={4}
    >
      <Box sx={{ bgcolor: whiteColor, borderRadius: "12px" }}>
        <Box
          sx={{
            bgcolor: backgroundColor01,
            borderRadius: "15px 15px 0px 0px",
            p: { xs: "15px 15px", md: "15px 20px" },
          }}
        >
          {/* <Grid container spacing="30px">
            <Grid item md={6.1}> */}
          <Box>
            <Typography
              fontSize={14}
              fontWeight={600}
              color={blackColor}
              lineHeight="28px"
              textAlign="center"
            >
              Currently you are ordering form
            </Typography>

            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              alignItems="center"
              justifyContent="center"
              gap="10px"
              mt={0.7}
            >
              <SelectedBranchCardMinimal
                branchInfo={branchInfo?.branchInfo || []}
                getAddress={getAddress}
                distances={distances}
                logoUrl={branchInfo?.branchInfo[0]?.logo}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
              />

              <Button
                sx={styles.continueBtn}
                onClick={() => {
                  sessionStorage.setItem("branchSelected", "true");
                  setModelOpen(false);
                }}
              >
                Continue
              </Button>
            </Box>
          </Box>
          {/* </Grid>

            <Grid item md={5.9}>
              <Box
                display="flex"
                // justifyContent='flex-end'
                mt={5.3}
              ></Box>
            </Grid>
          </Grid> */}
        </Box>

        <Box p={{ xs: "0", md: "20px" }} minHeight="400px">
          {loader ? (
            <Loader />
          ) : (
            <Grid container spacing="45px">
              <Grid item md={6}>
                <Box p={{ xs: "14px 14px 0 14px", md: "0" }}>
                  <Typography
                    fontSize={{ xs: 14, md: 14.5 }}
                    fontWeight={600}
                    color={blackColor}
                    lineHeight="28px"
                    mt={-1}
                  >
                    Choose the location nearest to you
                  </Typography>

                  <Typography
                    fontSize={12}
                    fontWeight={400}
                    color={blackColor}
                    lineHeight={{ xs: "19px", md: "20px" }}
                    m={{
                      xs: "-2px 0 5px 0 !important",
                      md: "-2px 0 15px 0 !important",
                    }}
                  >
                    For faster shipping and better prices{" "}
                    <Typography
                      component="span"
                      fontSize={12}
                      fontWeight={500}
                      color={greenColor05}
                      lineHeight={{ xs: "19px", md: "20px" }}
                    >
                      ({" "}
                      {branchInfo?.branchInfo?.length > 0
                        ? branchInfo?.branchInfo?.length - 1
                        : "-"}{" "}
                      Branches Available )
                    </Typography>
                  </Typography>
                </Box>

                {!matches && (
                  <Box
                    sx={{ display: "flex", bgcolor: backgroundColor01, mb: 2 }}
                  >
                    <Box
                      sx={styles.tabStyle(isListView)}
                      onClick={() => {
                        setIsListView(!isListView);
                      }}
                    >
                      <Typography fontSize={12.5} fontWeight={500}>
                        List View
                      </Typography>
                    </Box>
                    <Box
                      sx={styles.tabStyle(!isListView)}
                      onClick={() => {
                        setIsListView(!isListView);
                        setCurrentZoom(dynamicZoom - 0.15);
                      }}
                    >
                      <Typography fontSize={12.5} fontWeight={500}>
                        Map View
                      </Typography>
                    </Box>
                  </Box>
                )}

                {isListView ? (
                  <Box
                    sx={styles.branchInfoContainer}
                    p={{ xs: "0px 14px", md: "0" }}
                  >
                    {branchInfo?.branchInfo?.map((item, index) => {
                      if (
                        item?.status != "active" ||
                        item?.branchId == currentBusinessId
                      )
                        return;
                      return (
                        <Box
                          key={`${item?.branchId}-${index}`}
                          onMouseEnter={() => handleMouseEnter(item)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <BranchCard
                            logoUrl={item?.logo}
                            branchName={item?.businessName}
                            // address={item?.address?.slice(-25)}
                            address={getAddress(item?.businessArea || [])}
                            distance={
                              distances?.[index] > 0
                                ? `${distances[index]} KM`
                                : "- KM"
                            }
                            branchId={item?.branchId}
                            onClick={() => handleBranchSelect(item)}
                          />
                        </Box>
                      );
                    })}
                  </Box>
                ) : (
                  <Box p={{ xs: "0px 14px 14px 14px", md: "0" }}>
                    {isLoaded && distances?.length > 0 && userLocation ? (
                      <GoogleMapView
                        mapContainerStyle={{
                          width: "100%",
                          height: "300px",
                        }}
                        currentZoom={currentZoom}
                        mapCenter={mapCenter}
                        branchInfo={branchInfo}
                        hoveredBranchId={hoveredBranchId}
                        onMarkerClick={(branch) => {
                          console.log("MARKER_CLICK", branch);
                          handleBranchSelect(branch);
                        }}
                      />
                    ) : (
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          bgcolor: greyShade71,
                        }}
                      >
                        Loading Map...
                      </Box>
                    )}
                  </Box>
                )}
              </Grid>

              {matches && (
                <Grid item md={6}>
                  {isLoaded && distances?.length > 0 && userLocation ? (
                    <GoogleMapView
                      mapContainerStyle={mapContainerStyle}
                      currentZoom={currentZoom}
                      mapCenter={mapCenter}
                      branchInfo={branchInfo}
                      hoveredBranchId={hoveredBranchId}
                      onMarkerClick={() => {}}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: greyShade71,
                      }}
                    >
                      Loading Map...
                    </Box>
                  )}
                </Grid>
              )}
            </Grid>
          )}
        </Box>
      </Box>
    </ModalCard>
  );
};

export default MultiBranchSelectionPopup;

const GoogleMapView = ({
  mapContainerStyle,
  currentZoom,
  mapCenter,
  branchInfo,
  hoveredBranchId,
  onMarkerClick,
}) => {
  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={currentZoom}
      center={mapCenter}
      options={{
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
      }}
    >
      {branchInfo?.branchInfo?.map((branch) => {
        if (branch?.status != "active") return;

        return (
          <Marker
            onClick={() => onMarkerClick(branch)}
            key={branch.branchId}
            position={{
              lat: branch?.loc?.coordinates[1],
              lng: branch?.loc?.coordinates[0],
            }}
            title={branch?.businessName}
            icon={
              hoveredBranchId === branch.branchId
                ? {
                    url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
                    scaledSize: new window.google.maps.Size(40, 40),
                  }
                : {
                    url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                    scaledSize: new window.google.maps.Size(30, 30),
                  }
            }
          />
        );
      })}
    </GoogleMap>
  );
};

const styles = {
  branchInfoContainer: {
    height: { xs: "300px", md: "350px" },
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
      width: "4px",
      height: "4px",
      borderRadius: "50px !important",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(0,0,0,0.1)",
    },
    "&::-webkit-scrollbar-track": {
      background: "rgba(0,0,0,0.05)",
    },
  },

  continueBtn: {
    fontSize: 13,
    fontWeight: 500,
    color: whiteColor,
    bgcolor: greenColor05,
    p: { xs: "4px 25px", md: "6px 25px" },
    border: `1px solid ${greenColor05}`,
    borderRadius: { xs: "8px", md: "8px" },
    "&:hover": {
      bgcolor: whiteColor,
      color: greenColor05,
      border: `1px solid ${greenColor05}`,
    },
  },

  tabStyle: (isActive) => ({
    flex: 1,
    color: isActive ? greenColor05 : blackColor,
    borderBottom: `1.5px solid ${isActive ? greenColor05 : "transparent"}`,
    p: 0.5,
    textAlign: "center",
  }),
};
