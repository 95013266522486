import { Box, Skeleton } from '@mui/material'
import { whiteColor } from 'configs/styles/muiThemes'
import React from 'react'

const MobileContentLoader = () => {
  return (
    <Box width="100vw" bgcolor={whiteColor} overflow='hidden'>
        <Skeleton variant="text" width="130px" sx={{ m:"4px 0 12px 16px !important" }} />

        <Box display='flex' alignItems='center' gap='15px' ml="16px">
           <ProductCardOne />
           <ProductCardOne />
           <ProductCardOne />
        </Box>

        <Skeleton variant="text" width="130px" sx={{ m:"15px 0 12px 16px !important" }} />

        <Box display='flex' alignItems='center' gap='15px' ml="16px">
           <ProductCardTwo />
           <ProductCardTwo />
        </Box>
        <Box display='flex' alignItems='center' gap='15px' m="18px 0 0 16px">
           <ProductCardTwo />
           <ProductCardTwo />
        </Box>
    </Box>
  )
}

export default MobileContentLoader

const ProductCardOne = ()=>(
    <Box>
        <Skeleton variant="rounded" width="120px" height='120px' sx={{ borderRadius:'8px', m:"0 !important" }} />

        <Skeleton variant="rounded" width="120px" height='10px' sx={{ m:"10px 0 2px 0 !important" }} />
        <Skeleton variant="rounded" width="90px" height='10px' sx={{ m:"5px 0 2px 0 !important" }} />
        <Skeleton variant="rounded" width="50px" height='12px' sx={{ m:"8px 0 2px 0 !important" }} />
    </Box>
)

const ProductCardTwo = ()=>(
    <Box display='flex' alignItems='flex-start' gap="10px">
        <Skeleton variant="rounded" width="82px" height='82px' sx={{ borderRadius:'8px', m:"0 !important" }} />

        <Box>
            <Skeleton variant="rounded" width="180px" height='10px' sx={{ m:"4px 0 2px 0 !important" }} />
            <Skeleton variant="rounded" width="130px" height='10px' sx={{ m:"8px 0 2px 0 !important" }} />
            <Skeleton variant="rounded" width="50px" height='12px' sx={{ m:"12px 0 2px 0 !important" }} />
        </Box>
    </Box>
)