import { Box, Typography, useMediaQuery } from "@mui/material";
import {
  backgroundColor01,
  blackColor,
  borderDashedFreeContent,
  freeContentBgColor,
  savingTextColor,
  whiteColor,
} from "configs/styles/muiThemes";
import React from "react";

export default function FreeShippingContentCard({ amt }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return matches ? (
    <Box sx={styles.card}>
      <Box
        alignItems={"center"}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          background: "#d3f6f0",
          // border: '1px dashed #00B293',
          // borderRadius: '8px',
          padding: "5px 32px 5px 0px",
          // marginBottom: '20px',
          // width: '100%',
          borderRadius: "5px",
          border: borderDashedFreeContent,
        }}
      >
        <Box
          component="img"
          maxHeight={{ xs: "32px", md: "32px" }}
          borderRadius={"8px"}
          src={
            "https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/free-delivery.svg"
          }
          alt="CostBo"
          mr={1.5}
          ml={{ xs: -3, md: 2 }}
        />
        {amt > 0 ? (
          <Typography
            fontSize={{ md: 11, xs: 11.5 }}
            fontWeight={400}
            color={{ md: blackColor, xs: blackColor }}
            lineHeight={1.2}
            display="inline"
          >
            Add products worth{" "}
            <Typography
              fontSize={12}
              fontWeight={500}
              color={{ md: blackColor, xs: blackColor }}
              display="inline"
            >
              ₹{amt}
            </Typography>{" "}
            for{" "}
            <Typography
              fontSize={12}
              fontWeight={500}
              color={{ md: blackColor, xs: blackColor }}
              display="inline"
            >
              Free Delivery
            </Typography>
          </Typography>
        ) : (
          <Typography
            fontSize={{ md: 11, xs: 11.5 }}
            fontWeight={400}
            color={{ md: blackColor, xs: blackColor }}
            lineHeight={1.2}
            display="inline"
          >
            This order is eligible for{" "}
            <Typography
              fontSize={12}
              fontWeight={500}
              color={{ md: blackColor, xs: blackColor }}
              display="inline"
            >
              Free Delivery
            </Typography>
          </Typography>
        )}
      </Box>
    </Box>
  ) : (
    <Box padding={"0px 18px"}>
      <Box
        sx={styles.card}
        borderRadius={"5px"}
        // sx={{
        //   display: 'flex',
        //   alignItems: 'center',
        //   justifyContent: 'center',
        //   background: '#d3f6f0',
        //   border: '1px dashed #00B293',
        //   borderRadius: '8px',
        //   padding: '5px 32px 5px 13px',
        //   marginBottom: '20px',
        //   width: '100%',
        //   '&::before': {
        //     content: "''",
        //     position: 'absolute',
        //     borderRadius: '50%',
        //     top: 'calc(50% - 8px)',
        //     left: '-6px',
        //     border: borderDashedFreeContent,
        //     width: '12px',
        //     height: '12px',
        //     backgroundColor: whiteColor,
        //   },
        //   '&::after': {
        //     content: "''",
        //     position: 'absolute',
        //     borderRadius: '50%',
        //     top: 'calc(50% - 8px)',
        //     right: '-6px',
        //     border: borderDashedFreeContent,
        //     width: '12px',
        //     height: '12px',
        //     backgroundColor: whiteColor,
        //   },
        // }}
      >
        <Box
          alignItems={"center"}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#d3f6f0",
            // border: '1px dashed #00B293',
            // borderRadius: '8px',
            padding: "5px 32px 5px 0px",
            // marginBottom: '20px',
            // width: '100%',
            borderRadius: "5px",
            border: borderDashedFreeContent,
          }}
        >
          <Box
            component="img"
            maxHeight={{ xs: "32px", md: "80px" }}
            borderRadius={"8px"}
            src={
              "https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/free-delivery.svg"
            }
            alt="CostBo"
            mr={1.5}
            ml={-3}
          />
          {amt > 0 ? (
            <Typography
              fontSize={{ md: 11, xs: 11.5 }}
              fontWeight={400}
              color={{ md: savingTextColor, xs: blackColor }}
              lineHeight={1.2}
              display="inline"
            >
              Add products worth{" "}
              <Typography
                fontSize={12}
                fontWeight={500}
                color={{ md: savingTextColor, xs: blackColor }}
                display="inline"
              >
                ₹{amt}
              </Typography>{" "}
              for{" "}
              <Typography
                fontSize={12}
                fontWeight={500}
                color={{ md: savingTextColor, xs: blackColor }}
                display="inline"
              >
                Free Delivery
              </Typography>
            </Typography>
          ) : (
            <Typography
              fontSize={{ md: 11, xs: 11.5 }}
              fontWeight={400}
              color={{ md: savingTextColor, xs: blackColor }}
              lineHeight={1.2}
              display="inline"
            >
              This order is eligible for{" "}
              <Typography
                fontSize={12}
                fontWeight={500}
                color={{ md: savingTextColor, xs: blackColor }}
                display="inline"
              >
                Free Delivery
              </Typography>
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}

const styles = {
  card: {
    background: freeContentBgColor,
    position: "relative",
    overflow: "hidden",
    mt: { md: 0.3, xs: 0 },
    "&::before": {
      content: "''",
      position: "absolute",
      borderRadius: "50%",
      top: "calc(50% - 6px)",
      left: "-6px",
      border: borderDashedFreeContent,
      width: { md: "12px", xs: "10px" },
      height: { md: "12px", xs: "10px" },
      backgroundColor: backgroundColor01,
    },
    "&::after": {
      content: "''",
      position: "absolute",
      borderRadius: "50%",
      top: "calc(50% - 6px)",
      right: "-6px",
      border: borderDashedFreeContent,
      width: { md: "12px", xs: "10px" },
      height: { md: "12px", xs: "10px" },
      backgroundColor: backgroundColor01,
    },
  },
};
