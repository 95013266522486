export function getVariantArray(mainProduct, childProducts, colourObj) {
  // Initialize the result array
  const result = mainProduct?.variantInfo?.variants
    ?.filter((item) => item?.value)
    ?.map((variant) => ({
      type: variant.value, // variant type like 'size', 'colour'
      values: [],
    }));

  // Iterate over each child product and gather variant values
  childProducts?.forEach((child) => {
    const attributeList = child?.ondc?.attributeList;

    // Iterate over each variant type (size, colour)
    result?.forEach((variant) => {
      // Find the corresponding value in childProduct's attributeList
      const attribute = attributeList?.find(
        (attr) => attr.code === variant.type
      );

      // If the attribute exists and the value is not already in the array, add it

      if (attribute) {
        if (variant.type === "colour") {
          const colorObj = getColourValue(attribute.value, colourObj?.values); // Find the color object
          // Check if the color object already exists in the array
          if (
            !variant.values.some((color) => color.value === colorObj?.value)
          ) {
            variant.values.push(colorObj); // Add unique color objects
          }
        } else {
          if (!variant.values.includes(attribute.value)) {
            variant.values.push(attribute.value);
          }
        }
      }
    });
  });

  return result;
}

export function getVariantObject(mainProduct, childProducts) {
  // Initialize the result object
  const result = {};

  // Iterate over each variant and create an entry in the result object
  mainProduct?.variantInfo?.variants
    ?.filter((item) => item?.value)
    ?.forEach((variant, index) => {
      result[`variant${index + 1}`] = {
        type: variant.value, // variant type like 'size', 'colour'
        values: [], // initialize the array for each variant type
      };
    });

  // Iterate over each child product and gather variant values
  childProducts?.forEach((child) => {
    const attributeList = child.ondc.attributeList;

    // Iterate over each variant in the result object
    Object?.keys(result)?.forEach((variantKey) => {
      const variant = result[variantKey];

      // Find the corresponding value in childProduct's attributeList
      const attribute = attributeList?.find(
        (attr) => attr.code === variant.type
      );

      // If the attribute exists and the value is not already in the array, add it
      if (attribute && !variant.values.includes(attribute.value)) {
        variant?.values?.push(attribute.value);
      }
    });
  });

  return result;
}

export function getAvailableVariants(
  mainProduct,
  childProducts,
  selectedVariant
) {
  // Get the list of all variants from the main product
  const allVariants = mainProduct?.variantInfo?.variants || [];

  // Filter child products that match the selected variant
  const filteredProducts = childProducts?.filter((child) => {
    let attributeList = child?.ondc?.attributeList;

    // Check if the child product matches the selected variant
    for (let selectedKey in selectedVariant) {
      const selectedValue = selectedVariant[selectedKey];
      const attribute = attributeList?.find(
        (attr) => attr?.code === selectedKey
      );

      if (!attribute || attribute?.value !== selectedValue) {
        return false;
      }
    }

    return true;
  });

  // Initialize an object to store available variants and their values
  const availableVariants = [];
  let availableVariants1 = [];

  // Iterate over the filtered child products and collect the values for each variant type
  filteredProducts?.forEach((child) => {
    let attributeList = child?.ondc?.attributeList;

    allVariants?.forEach((variant) => {
      const variantType = variant?.value; // 'size', 'colour', etc.

      // Ignore the selected variant type
      if (selectedVariant.hasOwnProperty(variantType)) {
        return;
      }

      // Find the attribute for this variant type
      const attribute = attributeList?.find(
        (attr) => attr?.code === variantType
      );

      if (attribute) {
        // If the variant type is not already in the availableVariants object, initialize it
        if (!availableVariants[variantType]) {
          availableVariants[variantType] = [];
          availableVariants1 = [];
        }

        // Add the attribute value if it's not already in the array
        if (!availableVariants[variantType]?.includes(attribute?.value)) {
          availableVariants[variantType]?.push(attribute?.value);
          availableVariants1?.push(attribute?.value);
        }
      }
    });
  });

  return availableVariants1;
}

export function getProductByVariants(
  mainProduct,
  childProducts,
  variantCombination
) {
  // Iterate through child products to find the one that matches both variant types
  for (let child of childProducts) {
    let attributeList = child?.ondc?.attributeList;
    let isMatch = true;

    // Check if all variant types in the combination match the child product's attributes
    for (let variantKey in variantCombination) {
      let expectedValue = variantCombination[variantKey];

      // Find the corresponding attribute in childProduct
      let attribute = attributeList?.find((attr) => attr?.code === variantKey);

      // If attribute value does not match the expected value, break the loop
      if (!attribute || attribute?.value !== expectedValue) {
        isMatch = false;
        break;
      }
    }

    // If both variants match, return the child product
    if (isMatch) {
      return child;
    }
  }

  // If no matching product is found, return null
  return null;
}

export function getUniqueProductIds(data) {
  const ids = new Set(); // Use a Set to store unique values

  data?.forEach((item) => {
    item?.products?.forEach((product) => {
      if (product?.variantInfo?.masterProductVariantId)
        ids?.add(product?.variantInfo?.masterProductVariantId); // Add each product id to the set
    });
  });

  return Array?.from(ids); // Convert the set to an array
}

export function getActiveProductVariants(product, variantList) {
  const result = {};

  variantList?.forEach((variant, index) => {
    const matchingVariant = product?.ondc?.attributeList?.find(
      (attribute) => variant?.type === attribute?.code
    );

    console.log("matchingVariantmatchingVariant", matchingVariant);
    // If the variant type matches the attribute code, add it to the result
    if (matchingVariant) {
      result[`variant${index + 1}`] = matchingVariant?.value;
    }
  });

  return result;
}

export function addUOMToAttributeList(products) {
  return products?.map((product) => {
    const { unit, value } = product?.productDisplayUnitOfMeasure;

    // Construct the UOM string as "value unit"
    const uomValue = `${value} ${unit}`;

    // Add the UOM object to the attributeList
    const updatedAttributeList =
      product?.ondc?.attributeList?.length > 0
        ? [...product?.ondc?.attributeList, { code: "uom", value: uomValue }]
        : [{ code: "uom", value: uomValue }];

    // Return the updated product object with the modified attributeList
    return {
      ...product,
      ondc: {
        ...product.ondc,
        attributeList: updatedAttributeList,
      },
    };
  });
}

export const getValueByPath = (obj, path) => {
  console.log("getValueByPath", obj, path);
  // Split the path by periods
  const keys = path?.split(".");

  console.log("keys", keys);

  // Initialize result to the input object
  let result = obj;

  // Traverse the object using keys
  for (const key of keys) {
    // Check if the key contains an array index
    const match = key?.match(/(.+?)\[(\d+)\]/);

    if (match) {
      // If it's an array index, extract the array name and index
      const arrayName = match[1];
      const index = parseInt(match[2]);
      // Access the array and update the result
      result = result?.[arrayName]?.[index];
    } else {
      // Otherwise, access the property directly
      result = result[key] || "";
    }

    // If the result is undefined, break the loop
    if (result === undefined) break;
  }

  return result;
};

export const extractNameCodePairs = (data) => {
  return data.map((item) => {
    const [name, color] = JSON.parse(item);
    return [name, color];
  });
};

export const getValueByCode = (arrayOfObjects, code) => {
  console.log("getValueByCode", arrayOfObjects, code);
  for (let i = 0; i < arrayOfObjects?.length; i++) {
    if (arrayOfObjects[i].code === code) {
      return arrayOfObjects[i].value;
    }
  }
  // Return null if no match is found
  return null;
};

export const getNameByCode = (list, code) => {
  for (let i = 0; i < list.length; i++) {
    if (list[i][1] === code) {
      return list[i][0];
    }
  }
  return null; // Return null if colorCode is not found in the list
};

export const extractNames = (data) => {
  return data.map((item) => JSON.parse(item)[0]);
};

export const findLabelBySelectedValue = (inputArray) => {
  // Loop through the array
  for (let i = 0; i < inputArray?.length; i++) {
    // Check if the value of options.selected is 'yes'
    if (inputArray[i]?.options?.selected === inputArray[i]?.value) {
      // Return the label of the object
      return inputArray[i]?.label;
    }
  }
  // If no match is found, return null or handle the case accordingly
  return null;
};

export const filterMatchingCodes = (variantObj, array) => {
  // Extract all codes from the variants array in the variantObj
  const variantCodes = variantObj?.variants?.map((v) => v.code);

  // Filter the objects in the array whose code matches any code in variantCodes
  return array?.filter((item) => !variantCodes?.includes(item?.code));
};

export function formatString(input) {
  return input
    ?.replace(/_/g, " ") // Replace underscores with spaces
    ?.toLowerCase() // Convert the string to lowercase
    ?.split(" ") // Split the string into words
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    ?.join(" "); // Join the words back into a single string
}

export const getColourValue = (hexCode, colorList) => {
  for (let i = 0; i < colorList.length; i++) {
    const [name, code] = JSON.parse(colorList[i]); // Parse the string into an array
    if (code.toLowerCase() === hexCode.toLowerCase()) {
      return { name: name, value: code }; // Return as an object
    }
  }
  return null;
};
