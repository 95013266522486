import { Box, Stack, Typography } from "@mui/material";
import {
  bgColor15,
  blackColor,
  borderLineColor,
  greyColor9,
  orangeColor02,
  whiteColor,
} from "configs/styles/muiThemes";

function CancelCard({ canceledBy, cancelDate, reason, isCard }) {
  return (
    <Stack
      sx={{
        bgcolor: whiteColor,
        border: isCard ? "none" : `1px solid ${borderLineColor}`,
        borderRadius: 2.2,
        p: isCard ? { md: "8px", xs: "0 " } : "12px",
        m: isCard ? { md: "0 8px 8px", xs: "0 0 8px" } : "0px 0 24px",
      }}
      gap={{ md: 0.7, xs: 0.3 }}
    >
      <Typography
        color={blackColor}
        fontSize={{ md: 13.5, xs: 12.5 }}
        lineHeight={1.5}
        fontWeight={600}
      >
        {canceledBy}
      </Typography>
      <Typography
        color={greyColor9}
        fontSize={{ md: 11.5, xs: 11 }}
        lineHeight={1.5}
        fontWeight={500}
      >
        {cancelDate}
      </Typography>
      <Typography
        color={orangeColor02}
        fontSize={{ md: 13.5, xs: 12.5 }}
        lineHeight={1.5}
        fontWeight={500}
        mt={{ md: 0.8, xs: 0.6 }}
      >
        {reason}
      </Typography>
    </Stack>
  );
}

export default CancelCard;
