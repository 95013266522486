import { useEffect, useRef, useState } from "react";
import FooterMui from "../components/FooterMui";
import CartContent from "./Cart/CartContent";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCartDetails,
  getCartDetailsById,
  getCommercialDetails,
  getOfferOrderDetails,
} from "redux/addToCart/AddToCartSlice";
import {
  checkCartOrderProducts,
  removeProductLocalCart,
  updateCartProductPrice,
  updateCartProductQuantity,
  updateCartProducts,
  updateToLocalCart,
} from "redux/cart/CartSlice";
import { getRewardPointsDetails } from "redux/checkout/CheckoutSlice";
import AlertDialog from "../components/AlertDialog";
import TopComponent from "./TopComponent";
import { getUserName } from "utils/authHelpers";
import EmptyCard from "components/EmptyCard";
import {
  cardEmptyBtnText,
  cardEmptyDescriptionMsg,
  cardEmptyGuestBtnText,
  cardEmptyGuestDescriptionMsg,
  cardEmptyGuestImgUrl,
  cardEmptyGuestMsg,
  cardEmptyImgUrl,
  cardEmptyMsg,
  cartMergeAlert,
  orderAmtLimitErrorMsg,
  orderLimitAmount,
} from "configs/Constants";
import { getCatalogAllProducts } from "redux/subCatergory/subCategorySlice";
import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import BreadcrumbsSection from "components/Breadcrumbs";
import {
  backgroundColor01,
  cartBorderColor01,
  headingColor,
  whiteColor,
} from "configs/styles/muiThemes";
import { getBusinessIdDetails } from "redux/rootSlice/RootSlice";
import axios from "axios";
import BreadcrumbNew from "components/BreadcrumbNew";
import CatalogHeader from "./CatalogMui/CatalogHeader";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { getUnicomInventoryApi } from "services/api_calls/subCategory/SubCategoryApis";
import { getMultiProducts } from "services/api_calls/addToCart/AddToCartApi";
import HeaderLoader from "components/skeletonLoaders/HeaderLoader";
import CartLoader from "components/skeletonLoaders/cart/CartLoader";
import AlertModal from "components/AlertModal";
import CartMobileLoader from "components/skeletonLoaders/cart/CartMobileLoader";
import {
  getCouponsData,
  getInstantDiscounts,
} from "services/api_calls/dealsCoupons_api/DealCouponsApi";
import {
  checkBusinessReseller,
  firstTimeDiscouunt,
} from "services/api_calls/checkout/checkoutApi";
import moment from "moment";
function Cart() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const businessIdData = sessionStorage.getItem("businessId");

  const [multiProducts, setMultiProducts] = useState(null);

  const [totalQty, setTotalQty] = useState(0);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  };

  const { businessName } = useParams();
  const dispatch = useDispatch();
  // const businessId = sessionStorage.getItem('businessId')
  // const businessName = sessionStorage.getItem('businessName')
  const [isLoggedIn, setIsLoggedIn] = useState(
    getUserName().length === 0 ? false : true
  );
  // const cartId = "3491862fe9f546e3ad4d2c82e48fb8ff"
  const cartId = null;
  const { cartDetails, commercialDetails, loading } = useSelector(
    (state) => state.cart
  );
  const {
    updateQty,
    checkOrderProducts,
    updateCartRes,
    updatePriceRes,
    localCartData,
  } = useSelector((state) => state.cartupdateadd);

  const { allProducts } = useSelector((state) => state.subCategory);

  let navigate = useNavigate();

  const { loginDetails } = useSelector((state) => state.auth);

  const [cartProductList, setCartProductList] = useState(null);
  const [cartProductOfferCheckList, setCartProductOfferCheckList] = useState(
    []
  );

  const [cashbackData, setCashbackData] = useState(null);
  const { rewardpoints } = useSelector((state) => state.checkout);

  const [validateCart, setValidateCart] = useState(false);

  const [alertMsg, setAlertMsg] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);

  const [showLogin, setShowLogin] = useState(false);
  const errorCount = useRef(0);

  const { businessId } = useSelector((state) => state.rootSlice);

  const [unicommerceLoader, setUnicommerceLoader] = useState(false);
  const [unicomInventoryInfo, setUnicomInventoryInfo] = useState(null);

  const [showCartMergeAlert, setShowCartMergeAlert] = useState(null);

  const getBusinessData = () => {
    const localBusinessName = sessionStorage.getItem("businessName");
    if (businessName !== localBusinessName) {
      sessionStorage.setItem("cartData", "null");
    }

    dispatch(getBusinessIdDetails(businessName));
    sessionStorage.setItem("businessName", businessName);
  };

  useEffect(() => {
    getBusinessData();
  }, [businessName]);

  useEffect(() => {
    if (businessId?.validate) {
      console.log("Valid_BusinessID");
      if (cartId) {
        dispatch(getCartDetailsById(cartId));
      } else {
        if (isLoggedIn) {
          dispatch(getCartDetails(businessId));
        } else {
          getCartProductDetails(cartProductList);
        }
      }

      dispatch(getCommercialDetails());
      dispatch(getCatalogAllProducts());
    }
  }, [businessId]);

  //Offer Order Check
  const checkOfferOrder = async () => {
    let cartProductData = [];

    for (let i = 0; i < cartDetails?.productList?.length; i++) {
      const filterItem = checkOrderProducts?.filter(
        (item) => item.id == cartDetails?.productList?.[i]?.productId
      );

      if (filterItem.length > 0) {
        if (filterItem?.[0]?.offer === "promo") {
          const res = await dispatch(
            getOfferOrderDetails(cartDetails?.productList?.[i]?.productId)
          );

          cartProductData.push({
            ...cartDetails?.productList?.[i],
            offerOrderPresent: res?.payload?.present,
          });
        } else {
          cartProductData.push({
            ...cartDetails?.productList?.[i],
            offerOrderPresent: false,
          });
        }
      }
    }

    setCartProductOfferCheckList(cartProductData);
  };

  // get unicommerce inventory info
  const getUnicomInventory = async () => {
    console.log("getUnicomInventory___", { cartDetails });

    setUnicommerceLoader(true);
    const catalogPartnerInfo = commercialDetails?.info?.catalogPartnerInfo?.[0];

    if (catalogPartnerInfo?.status == "active") {
      const unicomProductList = cartDetails?.productList || [];
      const skuList = unicomProductList?.map(
        (item) => item?.catalogPartnerItemInfo?.skuCode || ""
      );

      const facilityCode = catalogPartnerInfo?.warehouse?.facilityCode;

      const inventoryRes = await getUnicomInventoryApi({
        facilityCode,
        skuList,
      });

      if (inventoryRes) {
        console.log("FETCH__Catalog_Inventory__DONE:", inventoryRes);
        setUnicomInventoryInfo(
          (prevState) => inventoryRes?.inventorySnapshots || []
        );
      }
    }

    setUnicommerceLoader(false);
  };

  useEffect(() => {
    if (
      cartDetails?.productList?.length > 0 &&
      checkOrderProducts?.length > 0
    ) {
      if (!unicomInventoryInfo && !unicommerceLoader) {
        getUnicomInventory();
      }
      checkOfferOrder();
    }
  }, [cartDetails, checkOrderProducts]);

  const handleErrorCount = () => {
    errorCount.current = errorCount.current + 1;
  };

  // useEffect(() => {
  //   if (cartId) {
  //     dispatch(getCartDetailsById(cartId))
  //   } else {
  //     if (isLoggedIn) {
  //       dispatch(getCartDetails(businessId))
  //     }
  //   }

  //   dispatch(getCommercialDetails())
  //   dispatch(getCatalogAllProducts())
  // }, [])

  useEffect(() => {
    if (businessId?.validate) {
      if (isLoggedIn) {
        dispatch(getCartDetails(businessId)).then((carRes) => {
          sessionStorage.setItem(
            "cartData",
            JSON.stringify(carRes?.payload?.detail)
          );
        });
      }
    }
  }, [updateQty, updateCartRes, updatePriceRes, businessId]);

  const getCartItemCount = () => {
    let count = 0;
    const cartProductData = JSON.parse(sessionStorage.getItem("cartData"));
    cartProductData?.productList?.forEach((item) => {
      count = count + item.currentQuantity;
    });

    console.log("cartProductData_1", cartProductData);
    // const cartProductData = JSON.parse(sessionStorage.getItem('cartData'))
    setTotalQty(count);
    setCartProductList(cartProductData);
  };

  useEffect(() => {
    if (businessId?.validate) {
      if (cartDetails) {
        dispatch(checkCartOrderProducts(cartDetails?.productList));
      }
    }
  }, [cartDetails, businessId]);

  useEffect(() => {
    getCartItemCount();
  }, [cartDetails, updateQty]);
  // }, [cartDetails, updateQty, addedProduct, createCartRes])

  useEffect(() => {
    if (businessId?.validate) {
      const cashback = JSON.parse(sessionStorage.getItem("cashback"));
      if (!cashback) {
        dispatch(getRewardPointsDetails());
      } else {
        setCashbackData(cashback);
      }
    }
  }, [rewardpoints, businessId]);

  const getCartProductDetails = async (cartData) => {
    const productIds = cartData?.productList
      ?.map((product) => product.productId)
      ?.join(", ");

    const products = await getMultiProducts({ productIds });

    setMultiProducts(products);

    console.log("cartDatacartData1", cartData, productIds, products);
  };

  useEffect(() => {
    if (localCartData) {
      setCartProductList(localCartData);
      setTotalQty(localCartData?.totalQuantity);
    }
  }, [localCartData]);
  useEffect(() => {
    if (businessId?.validate) {
      if (loginDetails) {
        dispatch(getCartDetails(businessId)).then((res) => {
          if (localCartData && res?.payload?.detail?.productList?.length > 0) {
            console.log("CART_MERGE___", {
              cart: res?.payload?.detail,
              localCartData,
            });
            setShowCartMergeAlert(true);
            setCartProductList(null);
          }
        });
        setIsLoggedIn(true);
      }
    }
  }, [loginDetails, businessId]);

  // Validate cart, Unicommerce inventory and proceed
  const vadilateCartInventory = async () => {
    if (commercialDetails?.info?.catalogPartnerInfo?.[0]?.status == "active") {
      await getUnicomInventory();
    }

    dispatch(getCartDetails(businessId));
    setValidateCart(true);

    if (errorCount.current == 0) {
      setValidateCart(false);
      console.log("hitt_CheckOut_Validation6");
      checkDiscountSection();
    }
  };

  const checkDiscountSection = async () => {
    let isDiscount = false;

    if (
      rewardpoints?.details?.[0]?.availablePointValue?.toFixed() > 0 &&
      moment().isBefore(rewardpoints?.details?.[0]?.expiryDate)
    ) {
      isDiscount = true;
    }

    const apiCalls = [
      getCouponsData(),
      firstTimeDiscouunt(),
      getInstantDiscounts(),
      checkBusinessReseller(),
    ];

    const [
      dealsCouponsDetails,
      firstTimeDiscountData,
      instantDiscounts,
      checkResellerData,
    ] = await Promise.all(apiCalls);

    console.log(
      "DISCOUNT_DATA",
      dealsCouponsDetails,
      firstTimeDiscountData,
      instantDiscounts,
      checkResellerData
    );

    if (dealsCouponsDetails?.details?.length > 0) {
      isDiscount = true;
    }

    if (
      firstTimeDiscountData?.details &&
      firstTimeDiscountData?.details?.availableAmount >
        firstTimeDiscountData?.details?.walletGiftLimit &&
      moment().isBetween(
        firstTimeDiscountData?.details?.walletStartDate,
        firstTimeDiscountData?.details?.walletEndDate
      )
    ) {
      isDiscount = true;
    }
    if (instantDiscounts?.details?.length > 0) {
      isDiscount = true;
    }

    if (checkResellerData?.isPresent > 0) {
      isDiscount = true;
    }

    console.log("hitt_CheckOut_Validation_*", isDiscount);

    navigate(`/${businessName}/DiscountDelivery`, {
      state: { currentPage: isDiscount ? "discount" : "address-page" },
    });
  };

  const validateCartItems = async () => {
    console.log("hitt_CheckOut_Validation");
    if (getUserName().length === 0) {
      // setShowLogin(!showLogin)
      console.log("hitt_CheckOut_Validation1");

      setShowLogin(showLogin + 1);
      console.log("hitt_CheckOut_Validation12");

      return;
    }

    if (commercialDetails?.info?.commerceEnable == false) {
      console.log("hitt_CheckOut_Validation2");
      return;
    }

    // Validation to check if order total is less than minOrderAmount
    if (
      cartProductList?.orderTotalProductCost <
      commercialDetails?.info?.orderInfoDetails?.[0]?.minOrderAmount
    ) {
      console.log("hitt_CheckOut_Validation3");

      setAlertMsg(
        `Order total should be more than ₹${commercialDetails?.info?.orderInfoDetails?.[0]?.minOrderAmount}`
      );
      setAlertOpen(true);
      return;
    }

    // Validation to check if order total is more than 500,000
    if (cartProductList?.orderTotalProductCost > orderLimitAmount) {
      setAlertMsg(orderAmtLimitErrorMsg);
      setAlertOpen(true);
      console.log("hitt_CheckOut_Validation4");

      return;
    }
    console.log("hitt_CheckOut_Validation5");

    dispatch(checkCartOrderProducts(cartDetails?.productList)).then(
      vadilateCartInventory
    );
  };

  return matches ? (
    <>
      <Box
        sx={{
          position: "sticky",
          top: { md: 0, xs: 0 },
          width: "100%",
          zIndex: 100,
          backgroundColor: { xs: whiteColor, md: backgroundColor01 },
          pb: 2,
        }}
      >
        <Box minHeight="126.7px" bgcolor={whiteColor}>
          {businessIdData !== null &&
          commercialDetails !== null &&
          allProducts !== null ? (
            <>
              {matches ? (
                <TopComponent
                  value={"catalog"}
                  bannerHide
                  disableBanners
                  showLoginForm={showLogin}
                />
              ) : (
                <TopComponent
                  value=""
                  disableBanners
                  showLoginForm={showLogin}
                  hideAppBar
                />
              )}
            </>
          ) : (
            <>{matches && <HeaderLoader />}</>
          )}
        </Box>

        <Container
          sx={{
            maxWidth: "1350px !important",
            p: { xs: "0px", md: "15px 15px 0px 15px !important" },
          }}
        >
          <BreadcrumbNew
            links={[
              // { label: "Home", to: `/` },
              { label: "Catalog", to: `/catalog` },
              { label: "Cart", to: `/cart` },
            ]}
            itemCount={totalQty}
            totalPrice={
              cartProductList ? cartProductList.orderTotalProductCost : 0
            }
            btn
            discount={"cart"}
            onProceed={validateCartItems}
            isDisabled={
              unicommerceLoader ||
              businessIdData == null ||
              commercialDetails == null
            }
          />

          {/* <CatalogHeader
                onFilterClick={() => {
                  setShowFilters(true)
                }}
                itemCount={totalQty}
                totalPrice={
                  cartProductList ? cartProductList.orderTotalProductCost : 0
                }
                category={() => {
                  if (
                    category?.length === 1 &&
                    category?.[0]?.subCat?.length === 1
                  ) {
                    handleProductCatClick(category?.[0]?.subCat?.[0])
                    return
                  }

                  setFilterProducts({ products: [], title: '' })
                  setFilteredSubCat([])
                  setViewByFilter('category')
                }}
                products={() => {
                  setFilterProducts({ products: [], title: '' })
                  setFilteredSubCat([])
                  setViewByFilter('all-products')
                }}
                viewByFilter={viewByFilter}
                handleViewByCategoryClick={handleViewByCategoryClick}
                handleViewAllProducts={handleViewAllProducts}
                // All Products Filter props
                displaySelection={sort}
                totalProduct={allProducts.totalProduct}
                onSort={handleAllProductSort}
              /> */}
        </Container>
      </Box>

      <AlertModal
        open={showCartMergeAlert}
        content={cartMergeAlert}
        handleConfirm={() => {
          setShowCartMergeAlert(false);
          getCartItemCount();
        }}
        handleClose={() => {
          setShowCartMergeAlert(false);
          getCartItemCount();
        }}
        hideCancel
        btnName="Ok"
      />

      {!loading &&
      cartProductList?.productList?.length > 0 &&
      // cartProductOfferCheckList.length > 0 &&
      commercialDetails &&
      checkOrderProducts ? (
        <Box
          bgcolor={{ xs: whiteColor, md: backgroundColor01 }}
          minHeight={"30vh"}
        >
          <CartContent
            cartDetails={cartProductList}
            productOfferCheckList={cartProductOfferCheckList}
            commercialDetails={commercialDetails}
            checkOrderProducts={checkOrderProducts}
            onQtyUpdate={(data) => {
              if (isLoggedIn) {
                dispatch(updateCartProductQuantity(data));
              } else {
                dispatch(updateToLocalCart(data));
              }
              errorCount.current = 0;
            }}
            cashbackData={cashbackData}
            onProceed={validateCartItems}
            validateCart={validateCart}
            onRemoveProduct={(cartData, orderProductData, selectedProduct) => {
              if (isLoggedIn) {
                dispatch(
                  updateCartProducts({
                    cartData,
                    orderProductData,
                    selectedProduct,
                  })
                );
              } else {
                console.log("selectedProduct", selectedProduct);
                dispatch(
                  removeProductLocalCart({
                    productId: selectedProduct.productId,
                  })
                );
              }

              errorCount.current = 0;
            }}
            onPriceUpdate={(cartData, orderProductData, selectedProduct) => {
              dispatch(
                updateCartProductPrice({
                  cartData,
                  orderProductData,
                  selectedProduct,
                })
              );
              errorCount.current = 0;
            }}
            commerceDisabled={commercialDetails?.info?.commerceEnable == false}
            errorCount={errorCount}
            handleErrorCount={handleErrorCount}
            // allProducts={allProducts}
            allProducts={multiProducts}
            isUnicommerce={
              commercialDetails?.info?.catalogPartnerInfo?.[0]?.status ==
              "active"
            }
            inventoryInfo={unicomInventoryInfo}
            unicommerceLoader={unicommerceLoader}
          />
        </Box>
      ) : (
        matches && !commercialDetails && <CartLoader />
      )}

      {commercialDetails &&
      (cartProductList?.productList?.length == 0 || !cartProductList) &&
      isLoggedIn ? (
        <Box
          sx={{
            backgroundColor: {
              xs: whiteColor,
              md: backgroundColor01,
              borderRadius: "10px",
            },
            borderRadius: "10px",
          }}
          pb={2}
        >
          <Container
            sx={{
              maxWidth: "1370px !important",
              borderRadius: "10px",
              pt: 1,
            }}
          >
            <EmptyCard
              message={cardEmptyMsg}
              description={cardEmptyDescriptionMsg}
              // imageUrl={cardEmptyImgUrl}
              imageUrl={
                "https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/card-empty.svg"
              }
              actionBtnText={cardEmptyBtnText}
              bgcolor={backgroundColor01}
              iconWidth={"102px"}
              iconHeignt={"98.73px"}
              imgpadding={"40px"}
            />
          </Container>
        </Box>
      ) : null}

      {commercialDetails &&
      (cartProductList?.productList?.length == 0 || !cartProductList) &&
      !isLoggedIn ? (
        <Box
          sx={{
            backgroundColor: {
              xs: whiteColor,
              md: backgroundColor01,
              borderRadius: "10px",
            },
            borderRadius: "10px",
          }}
          pb={2}
        >
          <Container
            sx={{
              maxWidth: "1370px !important",
              borderRadius: "10px",
            }}
          >
            <EmptyCard
              message={cardEmptyGuestMsg}
              description={cardEmptyGuestDescriptionMsg}
              // imageUrl={cardEmptyGuestImgUrl}
              imageUrl={
                "https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/card-empty.svg"
              }
              type={cardEmptyGuestBtnText}
              onTypeClick={() => {
                console.log("login hitt11");

                setShowLogin(!showLogin);
              }}
              bgcolor={backgroundColor01}
              iconWidth={"98px"}
              iconHeignt={"98.73px"}
              imgpadding={"20px"}
            />
          </Container>
        </Box>
      ) : null}

      {/* Cart Empty */}
      {/* Cart Empty Guest */}
      <AlertDialog
        content={alertMsg}
        isOpen={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
      />
      <Box
        component="a"
        onClick={scrollToTop}
        sx={{
          position: "fixed",
          width: "42px",
          height: "42px",
          color: "#fff",
          right: "10px",
          bottom: "30px",
          borderRadius: "80px",
          textAlign: "center",
          textDecoration: "none",
          overflow: "hidden",
          zIndex: 999,
          border: "0",
          transitionDuration: "0.2s",
          backgroundColor: "#E56B46",
          borderColor: "#E56B46",
          borderWidth: "1px",
          borderStyle: "solid",
          cursor: "pointer",
          "&:hover": {
            // backgroundColor: '#d65f3d', // Optional hover color
            backgroundColor: whiteColor,
            color: "#E56B46",
          },
        }}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <ArrowUpwardIcon
        // sx={{ color: whiteColor, fontSize: '20px', lineHeight: '40px','&:hover': {
        //   // backgroundColor: '#d65f3d', // Optional hover color
        //   backgroundColor: whiteColor,
        //   color: '#E56B46',
        // } }}
        />
      </Box>
      {businessIdData !== null &&
        commercialDetails !== null &&
        allProducts !== null &&
        cartProductList &&
        matches && <FooterMui />}
    </>
  ) : (
    <Box
      backgroundColor={{ md: whiteColor, xs: backgroundColor01 }}
      minHeight={"100vh"}
      height={"100vh"}
    >
      <Box
        sx={{
          position: "sticky",
          top: { md: 0, xs: 0 },
          zIndex: 100,
          p: "0 !imporatant",
          mb: 1.5,
        }}
      >
        <TopComponent
          value=""
          disableBanners
          showLoginForm={showLogin}
          hideAppBar
        />
        {/* <CartHeaderBreadcrums /> */}
        <MobileCartHeaderBreadcrums />
        {/* <Typography
          variant='subtitle1'
          component='h5'
          fontSize={{ xs: 15, md: 19 }}
          fontWeight={600}
          color={headingColor}
          mt={0.3}
          ml={2}
          py={0.3}
          // display={{xs:'block', md:'none'}}
        >
          Cart Details
        </Typography> */}
      </Box>
      {/* {!loading && cartProductList && commercialDetails && checkOrderProducts && ( */}

      {!loading &&
      cartProductList?.productList?.length > 0 &&
      // cartProductOfferCheckList.length > 0 &&
      commercialDetails &&
      checkOrderProducts ? (
        <CartContent
          cartDetails={cartProductList}
          productOfferCheckList={cartProductOfferCheckList}
          commercialDetails={commercialDetails}
          checkOrderProducts={checkOrderProducts}
          onQtyUpdate={(data) => {
            if (isLoggedIn) {
              dispatch(updateCartProductQuantity(data));
            } else {
              dispatch(updateToLocalCart(data));
            }
            errorCount.current = 0;
          }}
          cashbackData={cashbackData}
          onProceed={validateCartItems}
          validateCart={validateCart}
          onRemoveProduct={(cartData, orderProductData, selectedProduct) => {
            if (isLoggedIn) {
              dispatch(
                updateCartProducts({
                  cartData,
                  orderProductData,
                  selectedProduct,
                })
              );
            } else {
              console.log("selectedProduct", selectedProduct);
              dispatch(
                removeProductLocalCart({
                  productId: selectedProduct.productId,
                })
              );
            }

            errorCount.current = 0;
          }}
          onPriceUpdate={(cartData, orderProductData, selectedProduct) => {
            dispatch(
              updateCartProductPrice({
                cartData,
                orderProductData,
                selectedProduct,
              })
            );
            errorCount.current = 0;
          }}
          commerceDisabled={commercialDetails?.info?.commerceEnable == false}
          errorCount={errorCount}
          handleErrorCount={handleErrorCount}
          allProducts={multiProducts}
          // allProducts={allProducts}
          isUnicommerce={
            commercialDetails?.info?.catalogPartnerInfo?.[0]?.status == "active"
          }
          inventoryInfo={unicomInventoryInfo}
          unicommerceLoader={unicommerceLoader}
        />
      ) : (
        !commercialDetails && <CartMobileLoader />
      )}

      {(cartProductList?.productList?.length == 0 || !cartProductList) &&
      isLoggedIn ? (
        <EmptyCard
          message={cardEmptyMsg}
          description={cardEmptyDescriptionMsg}
          // imageUrl={cardEmptyImgUrl}
          imageUrl={
            "https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/card-empty.svg"
          }
          actionBtnText={cardEmptyBtnText}
          bgcolor={backgroundColor01}
          iconWidth={"98px"}
          iconHeignt={"98.73px"}
          imgpadding={"20px"}
        />
      ) : null}
      {(cartProductList?.productList?.length == 0 || !cartProductList) &&
      !isLoggedIn ? (
        <EmptyCard
          message={cardEmptyGuestMsg}
          description={cardEmptyGuestDescriptionMsg}
          // imageUrl={cardEmptyGuestImgUrl}
          imageUrl={
            "https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/card-empty.svg"
          }
          type={cardEmptyGuestBtnText}
          onTypeClick={() => {
            console.log("login hitt11");

            setShowLogin(!showLogin);
          }}
          bgcolor={backgroundColor01}
          iconWidth={"98px"}
          iconHeignt={"98.73px"}
          imgpadding={"20px"}
        />
      ) : null}
      {/* Cart Empty */}
      {/* Cart Empty Guest */}
      <AlertDialog
        content={alertMsg}
        isOpen={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
      />

      <AlertModal
        open={showCartMergeAlert}
        content={cartMergeAlert}
        handleConfirm={() => {
          setShowCartMergeAlert(false);
          getCartItemCount();
        }}
        handleClose={() => {
          setShowCartMergeAlert(false);
          getCartItemCount();
        }}
        hideCancel
        btnName="Ok"
      />

      {businessIdData !== null &&
        commercialDetails !== null &&
        allProducts !== null &&
        matches && <FooterMui />}
    </Box>
  );
}

export default Cart;

const CartHeaderBreadcrums = () => {
  return (
    <Container
      sx={{
        maxWidth: "1300px !important",
        mt: { xs: 10, md: 2.5 },
      }}
    >
      <BreadcrumbsSection
        links={[
          { label: "Catalog", to: `/catalog` },
          { label: "Cart", to: `/cart` },
        ]}
      />

      {/* <Typography
        variant='subtitle1'
        component='h5'
        fontSize={{ xs: 15, md: 19 }}
        fontWeight={600}
        color={headingColor}
        mt={0.3}
        ml={0.1}
        py={0.3}
        // display={{xs:'block', md:'none'}}
      >
        Cart Details
      </Typography> */}
    </Container>
  );
};

const MobileCartHeaderBreadcrums = () => {
  return (
    <Container
      sx={{
        maxWidth: "1440px !important",
        mt: { xs: 0, md: 2.5 },
        p: 0,
      }}
    >
      <BreadcrumbsSection
        links={[
          { label: "Catalog", to: `/catalog` },
          { label: "Cart", to: `/cart` },
        ]}
        detail={"Cart Details"}
      />
    </Container>
  );
};
