import { Box, Typography } from "@mui/material";
import {
  backgroundColor02,
  blackColor,
  whiteColor,
} from "configs/styles/muiThemes";
import React, { useState } from "react";
import Slider from "react-slick/lib/slider";
import { textOneLines } from "utils/styleUtility";
import ImageCarousel from "./ImageCarousel";

const BusinessBanners = ({
  businessBanners,
  businessDetails,
  reviewSummary,
  social,
  total,
}) => {
  const [activeSlide, setActiveSlide] = useState(0);

  const settings = {
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    autoplay: true,
    speed: 1000,
    arrows: false,
    autoplaySpeed: 3000,
    cssEase: "ease",
    pauseOnHover: false,

    beforeChange: (current, next) => {
      setActiveSlide(next);
    },
  };

  return (
    <Box sx={{ py: 3, mb: 5, position: "relative" }}>
      <Box sx={{ height: "450px", overflowX: "clip" }}>
        {/* {businessBanners?.length} */}
        <ImageCarousel
          businessBanners={
            businessBanners?.length === 3 || businessBanners?.length === 2
              ? [...businessBanners, ...businessBanners]
              : businessBanners?.length === 1
              ? [
                  ...businessBanners,
                  ...businessBanners,
                  ...businessBanners,
                  ...businessBanners,
                ]
              : businessBanners
          }
          // businessBanners={businessBanners}
        />

        {/* <Slider {...settings}>
          {businessBanners?.map((bannerItem, index) => {
            return (
              <Box
                key={`banner_${index}`}
                sx={{ bgcolor: 'transparent', p: 2 }}
              >
                <Box
                  component='img'
                  src={bannerItem?.docURL}
                  sx={{
                    width: index === activeSlide ? '550px' : '430px',

                    mt: index !== activeSlide ? 3 : 0,
                  }}
                />
              </Box>
            )
          })}
        </Slider> */}
      </Box>

      <Box sx={styles.businessInfoCard}>
        <Typography
          fontSize={14}
          color={blackColor}
          fontWeight={500}
          lineHeight="20px"
          mb="5px !important"
        >
          Welcome to
        </Typography>

        <Typography
          fontSize={22}
          color={blackColor}
          fontWeight={600}
          height="30px"
          lineHeight="30px"
          mb="8px !important"
        >
          {businessDetails?.businessName}
        </Typography>

        <Typography
          fontSize={12.5}
          color={blackColor}
          fontWeight={400}
          height="20px"
          lineHeight="20px"
          // mb="5px !important"
          sx={textOneLines}
        >
          {businessDetails?.mainProducts?.map((e) => e.productName).join(", ")}
        </Typography>

        {/* <Box
          sx={{
            bgcolor: backgroundColor02,
            p: "1px 15px",
            fontSize: "12px",
            fontWeight: 500,
            borderRadius: "30px",
            mt: "8px",
          }}
            display={"flex"}
        >
        // <Box
        //     component='img'
        //     src={`/.//assets/images/location-icon.svg`}
        //     alt='business loaction'
        //     style={{width:"15px",height:"15px"}}
        //   />
        //   {businessDetails?.address}

          {reviewSummary?.average > 0 && (
            <>
              <Box
                component="img"
                src="https://storage.googleapis.com/bodefaults/shopweb/reviews/banner-star.svg"
                sx={{ width: "16px", mr: "6px", mb: "-3px" }}
              />
              {reviewSummary?.average} -
            </>
          )}{" "}
          {reviewSummary?.totalRated || 0} Reviews -{" "}
          {social?.totalFollower || 0} Followers - {total?.totalView || 0} Views
        </Box> */}
      </Box>
    </Box>
  );
};

export default BusinessBanners;

const styles = {
  card: {
    width: "100%",
    height: "300px",
    backgroundColor: "red",
  },

  businessInfoCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "50%",
    bgcolor: whiteColor,
    p: "15px",
    borderRadius: "15px",
    position: "absolute",
    left: "calc(50% - 25vw)",
    bottom: "-10px",
    m: "0px auto",
    zIndex: 1,
    textAlign: "center",
    boxShadow: "0 1px 8px rgba(87, 87, 101, 0.07)",
  },
};
