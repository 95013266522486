import { orangeColor01 } from "configs/styles/muiThemes";
import { getProfileId, getUserDetails } from "./authHelpers";
import moment from "moment";

export const discountCouponDataHelper = (reseller, cartDetails) => {
  let couponEndDate = new Date(reseller?.codeValidityEnd).toLocaleDateString();

  let discountamount =
    (cartDetails?.orderTotalProductCost * reseller?.discountPercentage) / 100;
  let orderAmount = cartDetails?.orderTotalProductCost - discountamount;
  let orderFinalAmount = Number(orderAmount).toFixed(2);
  let finalDiscountAmount = Number(discountamount).toFixed(2);

  console.log("RESELLER_DATA", reseller);

  const data = {
    cartDetails: { orderTotalCost: orderFinalAmount },
    discountOrderTotalCost: cartDetails?.orderTotalProductCost - discountamount,
    couponDiscountAmount: finalDiscountAmount,
    discountApplyButton: "Coupon Applied",
    claimId: "",
    couponDiscountPercentage: reseller?.discountPercentage,
    claimpoint: discountamount,
    discountAmountText: "Coupon Applied",
    discountAmountValue: (Math.floor(discountamount * 100) / 100).toFixed(2),
    discountAmountBoolean: true,
    additionalDiscountBoolean: false,
    applyDiscountCheck: true,
    couponMinimumAlert: "",
    selectedDeliveryType: -1,
    delhiveryDeliveryType: 0,
    b2bDeliveryType: 0,
    weFastDeliveryType: 0,
    regularDeliveryType: 0,
    pickupDeliveryType: 0,
    shipmentDeliveryOption: false,
    shipmentPrice: 0,
    claimType:
      reseller?.profileId === null || reseller?.profileId === ""
        ? "BusinessCoupon"
        : "ResellerDiscount",
    resellerInfoDetails: {
      code: reseller?.resellerCode,
      resellerBusinessId: reseller?.resellerBusinessId,
      businessId: reseller?.businessId,
      profileId:
        reseller?.profileId === null || reseller?.profileId === ""
          ? ""
          : reseller?.profileId,
      saleMedium: "directlink",
    },
    couponExpiredDate: couponEndDate,
    affiliateLinkCode: true,
  };
  return data;
  //   console.log('COUPON_BUILT_DATA', data)
};

export const instantDiscountDataHelper = (instant, cartDetails) => {
  console.log("INSTANT_DATA", instant);
  let amount =
    instant.pointPercentage > 0
      ? (cartDetails.orderTotalProductCost * instant.pointPercentage) / 100
      : 0;
  let instantcashDiscountAmt =
    instant.pointValue > 0 ? instant.pointValue : amount;
  let orderAmount = cartDetails.orderTotalProductCost - instantcashDiscountAmt;
  let orderFinalAmount = Number(orderAmount).toFixed(2);

  let instantCashCliamId = instant?.id || "";

  const data = {
    instantcashMinimumAmt: instant.minimumAmount,
    referralInfoDetails: {
      referralProfileId: instant.originalProfileId,
      referralAmount: instant.rewardReferralAmount,
      //   referralPhoneNo: instant.phoneNo,
      //   referralName: instant.name,
      //   referralPhoto: instant.photo,
    },
    cartDetails: { orderTotalCost: orderFinalAmount },
    discountOrderTotalCost:
      cartDetails.orderTotalProductCost - instantcashDiscountAmt,
    discountApplyButton: "Instant cash Applied",
    claimId: instantCashCliamId,
    claimType: "instantcashdiscount",
    claimpoint: instantcashDiscountAmt,
    discountAmountText: "Instant cash Applied",
    discountAmountValue: (
      Math.floor(instantcashDiscountAmt * 100) / 100
    ).toFixed(2),
    discountAmountBoolean: true,
    additionalDiscountBoolean: false,
    applyDiscountCheck: true,
    selectedDeliveryType: -1,
    delhiveryDeliveryType: 0,
    b2bDeliveryType: 0,
    weFastDeliveryType: 0,
    regularDeliveryType: 0,
    pickupDeliveryType: 0,
    shipmentDeliveryOption: false,
    shipmentPrice: 0,
  };
  return data;
};

export const cashbackDiscountDataHelper = (
  cashbackAmount,
  cartDetails,
  rewardPoints
) => {
  let orderAmount = cartDetails.orderTotalProductCost - cashbackAmount;
  let orderFinalAmount = Number(orderAmount).toFixed(2);

  const data = {
    cartDetails: { orderTotalCost: orderFinalAmount },
    discountOrderTotalCost: cartDetails.orderTotalProductCost - cashbackAmount,
    discountApplyButton: "Cashback Applied",
    claimId: rewardPoints?.details?.[0]?.id || "",
    cliamRewardInfo: { claimPoint: cashbackAmount },
    claimType: "cashback",
    claimpoint: cashbackAmount,
    discountAmountText: "Cashback Applied",
    discountAmountValue: (Math.floor(cashbackAmount * 100) / 100).toFixed(2),
    discountAmountBoolean: true,
    additionalDiscountBoolean: false,
    applyDiscountCheck: true,
    selectedDeliveryType: -1,
    delhiveryDeliveryType: 0,
    b2bDeliveryType: 0,
    weFastDeliveryType: 0,
    regularDeliveryType: 0,
    pickupDeliveryType: 0,
    shipmentDeliveryOption: false,
    shipmentPrice: 0,
  };
  console.log("CASKBACK_OPTIONS", data);
  return data;
};

export const firstTimeDiscountDataHelper = (
  walletDetails,
  walletGiftAmount,
  totalClaimed,
  cartDetails
) => {
  let orderAmount = cartDetails.orderTotalProductCost - walletGiftAmount;
  let orderFinalAmount = Number(orderAmount).toFixed(2);

  const data = {
    cartDetails: { orderTotalCost: orderFinalAmount },
    discountOrderTotalCost:
      cartDetails.orderTotalProductCost - walletGiftAmount,
    discountApplyButton: "Wallet Applied",
    claimId: walletDetails?.id,
    updateWalletInfo: {
      walletId: walletDetails?.id,
      walletGiftAmount: walletGiftAmount,
      numberOfWalletsClaims: totalClaimed,
    },
    claimType: "business wallet",
    claimpoint: walletGiftAmount,
    discountAmountText: "Wallet Applied",
    discountAmountValue: (Math.floor(walletGiftAmount * 100) / 100).toFixed(2),
    discountAmountBoolean: true,
    additionalDiscountBoolean: false,
    applyDiscountCheck: true,
    selectedDeliveryType: -1,
    delhiveryDeliveryType: 0,
    b2bDeliveryType: 0,
    weFastDeliveryType: 0,
    regularDeliveryType: 0,
    pickupDeliveryType: 0,
    shipmentDeliveryOption: false,
    shipmentPrice: 0,
  };

  return data;
};

export const checkOutUpdateCartHelper = (params) => {
  const userInfo = getUserDetails();

  const gst = params?.gst || null;

  const cartDetails = params?.cartDetails;
  const seletedAddress = params?.seletedAddress;
  const businessData = params?.activeBusinessData;
  const appliedDiscountDetails = params?.appliedDiscountDetails;
  const specialInstructions = params?.consumerNotes;
  const ownerShip = params?.crmOwnershipInfo;
  const deliverMethod = params?.deliveryMethodData;
  const packageInfo = params?.packageInfo;
  const deliveryType = params?.activeDeliveryType;
  const modeOfPayment = params?.modeOfPayment;
  const totalOrderCost = params?.totalOrderCost;
  const shipmentAmount = params?.shipmentAmount;
  const shipmentEta = params?.shipmentEta;
  const deviceInfo = params?.deviceInfo;
  const selectedDiscountType = params?.selectedDiscountType;
  const shipmentTaxValue = params?.shipmentTaxValue;

  const deviceOS = deviceInfo ? deviceInfo?.os : "";
  const browserType = deviceInfo ? deviceInfo?.browser : "";

  const commercialDetails = JSON.parse(sessionStorage.getItem("commerceInfo"));

  let totalProductCost = 0;
  let totalQty = 0;

  let cartProductList = [];
  let cartOrderTotalTax = 0;

  cartDetails?.productList?.forEach((item) => {
    let productAmtWithoutGST =
      (item.productSalePrice * 100) / (100 + Number(item.productTaxRate));
    let productTaxPerUnit = item.productSalePrice - productAmtWithoutGST;
    let itemTotalTax = productTaxPerUnit * item.currentQuantity;
    cartOrderTotalTax += itemTotalTax;

    console.log("cartProductList_productSalePrice", item.productSalePrice);
    console.log("cartProductList_productAmtWithoutGST", productAmtWithoutGST);
    console.log("cartProductList_productTaxPerUnit", productTaxPerUnit);

    cartProductList.push({
      ...item,
      productTax: itemTotalTax.toFixed(2),
      originalQuantity: Number(item.currentQuantity || 1),
    });
  });

  console.log("cartProductList_GST", cartProductList);
  console.log("cartProductList_cartOrderTotalTax", cartOrderTotalTax);

  cartDetails?.productList?.forEach((item) => {
    totalProductCost =
      totalProductCost + item?.productSalePrice * item?.currentQuantity;
    totalQty = totalQty + item?.currentQuantity;
  });

  const businessAddress = businessData?.viewBusiness?.businessArea?.reduce(
    (previous, current) => {
      return { ...previous, [current.code.replaceAll(" ", "")]: current };
    },
    {}
  );

  const ownerPhoneNo = businessData?.viewBusiness?.owner?.phone;
  const addressLine1 = businessData?.viewBusiness?.address;

  const refferalInfoData = appliedDiscountDetails
    ? appliedDiscountDetails.referralInfoDetails
      ? appliedDiscountDetails.referralInfoDetails
      : null
    : null;

  const resellerInfoData = appliedDiscountDetails
    ? appliedDiscountDetails.resellerInfoDetails
      ? appliedDiscountDetails.resellerInfoDetails
      : null
    : null;

  const aliasName = businessData?.viewBusiness?.aliasName || "";

  // console.log('CHECK_OUT_DATA_USER', userInfo)
  // console.log('CHECK_OUT_DATA_CART', cartDetails)
  // console.log('CHECK_OUT_DATA_SELECTED_ADDR', seletedAddress)
  // console.log('CHECK_OUT_DATA_ACTIVE_BData', businessData)
  // console.log('CHECK_OUT_DATA_APPLIED_DISCOUNT', appliedDiscountDetails)
  // console.log('CHECK_OUT_DATA_Notes', specialInstructions)
  // console.log('CHECK_OUT_DATA_ownerShip', ownerShip)
  // console.log('CHECK_OUT_DATA_totalProductCost', totalProductCost)
  // console.log('CHECK_OUT_DATA_totalQty', totalQty)
  console.log("CHECK_OUT_DATA_businessAddress", businessAddress, ownerPhoneNo);
  console.log("CHECK_OUT_DATA_businessAddress", ownerPhoneNo);

  const data = {
    orderId: cartDetails?.orderId,
    profileId: cartDetails?.profileId,
    businessId: cartDetails?.businessId,
    profilePhoto: cartDetails?.profilePhoto,
    profileName: cartDetails?.profileName,
    businessLogo: cartDetails?.businessLogo,
    businessName: cartDetails?.businessName,
    businessCity: cartDetails?.businessCity,
    notesConsumer: specialInstructions,
    deliveryDateRange: `${deliveryType?.type} (${shipmentEta})`,
    claimId: appliedDiscountDetails
      ? appliedDiscountDetails?.claimId
        ? appliedDiscountDetails?.claimId
        : ""
      : "",
    claimType: appliedDiscountDetails ? appliedDiscountDetails?.claimType : "",
    clientType: `WEB-ORDER-REACT - ${deviceOS} - ${browserType}`,
    totalQuantity: totalQty,
    // orderTotalTax: cartDetails?.orderTotalTax?.toString(),
    orderTotalTax: cartOrderTotalTax.toFixed(2),
    orderTotalProductCost: totalProductCost,
    orderTotalCost: totalOrderCost.toString(),
    shippingCost: shipmentAmount,
    shippingTax: Number(shipmentTaxValue).toFixed(2) || 0.0,
    claimedPoint: appliedDiscountDetails
      ? appliedDiscountDetails?.claimpoint
      : "",
    // productList: cartDetails?.productList,
    productList: cartProductList,
    deliverMethod: [deliverMethod],
    modeOfPayment: [modeOfPayment],
    customerAddress: {
      ...seletedAddress,
      emails: userInfo?.account?.email,
      preferredLocation: null,
      gstNo: gst,
    },
    businessAddress: {
      addedOn: 0,
      name: businessData?.viewBusiness?.name,
      aliasName,
      addressLine1: `${businessAddress?.addresslineone?.value} ${businessAddress?.locality?.value}`,
      addressLine2: businessAddress?.addresslinetwo?.value,
      addressLine3: "",
      city: businessAddress?.City?.value,
      zip: businessAddress?.pincode?.value,
      state: businessAddress?.State?.value,
      country: businessAddress?.Country?.value,
      landMark: businessAddress?.locality?.value,
      phoneNo:
        ownerPhoneNo || businessData?.viewBusiness?.contactNos?.length > 0
          ? businessData?.viewBusiness?.contactNos[0].number
          : "",
      emails: businessData?.viewBusiness?.businessMailingAddress,
      fssaiNo: commercialDetails?.info?.fssaiInfo?.[0]?.value,
      gstNo: commercialDetails?.info?.gstNumber,
      panNo: commercialDetails?.info?.panNumber,
      category: "",
      latitude: businessData?.viewBusiness?.loc?.y,
      longitude: businessData?.viewBusiness?.loc?.x,

      //
      // gstNo: businessData?.viewBusiness?.gstnumber,
      // panNo: businessData?.viewBusiness?.pannumber,
    },

    packageInfo: packageInfo,
    deliveryType: {
      ...deliveryType,
      from:
        deliveryType.from || deliveryType.from === 0 ? deliveryType.from : null,
      to: deliveryType.to || deliveryType.to === 0 ? deliveryType.to : null,
      shipType: deliveryType.shipType || null,
    },
    ownerShipInfo: {
      ownerShip: ownerShip,
    },

    // referralInfo: appliedDiscountDetails
    //   ? appliedDiscountDetails.referralInfoDetails
    //     ? appliedDiscountDetails.referralInfoDetails
    //     : null
    //   : null,
    // resellerInfo: appliedDiscountDetails
    //   ? appliedDiscountDetails.resellerInfoDetails
    //     ? appliedDiscountDetails.resellerInfoDetails
    //     : null
    //   : null,

    referralInfo: {
      originalProfileId: refferalInfoData
        ? refferalInfoData?.referralProfileId
        : "",
      referralAmount: refferalInfoData ? refferalInfoData.referralAmount : 0,
      referralName: "",
      referralProfileId: "",
    },

    resellerInfo: {
      businessId: resellerInfoData ? resellerInfoData?.businessId : "",
      code: resellerInfoData ? resellerInfoData?.code : "",
      profileId: resellerInfoData ? resellerInfoData?.profileId : "",
      resellerBusinessId: resellerInfoData
        ? resellerInfoData?.resellerBusinessId
        : "",
      saleMedium: resellerInfoData ? resellerInfoData?.saleMedium : "",
    },
    discountDetails: selectedDiscountType != null ? [selectedDiscountType] : [],

    weFastInfo: {},
  };

  console.log("CHECK_OUT_DATA_POST", data);

  return data;
};

// Update Cart for Paycart Order
export const paycartUpdateHelper = (params) => {
  const userInfo = getUserDetails();

  const gst = params?.gst || null;

  const cartDetails = params?.cartDetails;
  const seletedAddress = params?.seletedAddress;
  const businessData = params?.activeBusinessData;
  const appliedDiscountDetails = params?.appliedDiscountDetails;
  const specialInstructions = params?.consumerNotes;
  const ownerShip = params?.crmOwnershipInfo;
  const deliverMethod = params?.deliveryMethodData;
  const packageInfo = params?.packageInfo;
  const deliveryType = params?.activeDeliveryType;
  const modeOfPayment = params?.modeOfPayment;
  const totalOrderCost = params?.totalOrderCost;
  const shipmentAmount = params?.shipmentAmount;
  const shipmentEta = params?.shipmentEta;
  const deviceInfo = params?.deviceInfo;
  const selectedDiscountType = params?.selectedDiscountType;
  const shipmentTaxValue = params?.shipmentTaxValue;

  const deviceOS = deviceInfo ? deviceInfo?.os : "";
  const browserType = deviceInfo ? deviceInfo?.browser : "";

  const commercialDetails = JSON.parse(sessionStorage.getItem("commerceInfo"));

  let totalProductCost = 0;
  let totalQty = 0;

  let cartProductList = [];
  let cartOrderTotalTax = 0;

  cartDetails?.productList?.forEach((item) => {
    let productAmtWithoutGST =
      (item.productSalePrice * 100) / (100 + Number(item.productTaxRate));
    let productTaxPerUnit = item.productSalePrice - productAmtWithoutGST;
    let itemTotalTax = productTaxPerUnit * item.currentQuantity;
    cartOrderTotalTax += itemTotalTax;

    cartProductList.push({
      ...item,
      productTax: itemTotalTax.toFixed(2),
    });
  });

  cartDetails?.productList?.forEach((item) => {
    totalProductCost =
      totalProductCost + item?.productSalePrice * item?.currentQuantity;
    totalQty = totalQty + item?.currentQuantity;
  });

  const businessAddress = businessData?.viewBusiness?.businessArea?.reduce(
    (previous, current) => {
      return { ...previous, [current.code.replaceAll(" ", "")]: current };
    },
    {}
  );

  const ownerPhoneNo = businessData?.viewBusiness?.owner?.phone;
  const addressLine1 = businessData?.viewBusiness?.address;

  const refferalInfoData = appliedDiscountDetails
    ? appliedDiscountDetails.referralInfoDetails
      ? appliedDiscountDetails.referralInfoDetails
      : null
    : null;

  const resellerInfoData = appliedDiscountDetails
    ? appliedDiscountDetails.resellerInfoDetails
      ? appliedDiscountDetails.resellerInfoDetails
      : null
    : null;

  const aliasName = businessData?.viewBusiness?.aliasName || "";

  const postData = {
    profileName: seletedAddress?.name,
    businessCity: cartDetails?.businessCity,
    orderTotalCost: totalOrderCost.toString(),
    orderTotalTax: cartOrderTotalTax.toFixed(2),

    productList: cartProductList,

    shippingCost: shipmentAmount,
    shippingTax: Number(shipmentTaxValue).toFixed(2) || 0.0,
    clientType: `WEB-ORDER-REACT - ${deviceOS} - ${browserType}`,
    deliveryDateRange: `${deliveryType?.type} (${shipmentEta})`,
    profilePhoto: cartDetails?.profilePhoto,
    customerAddress: {
      ...seletedAddress,
      emails: userInfo?.account?.email,
      preferredLocation: null,
      gstNo: gst,
    },
    businessAddress: {
      addedOn: 0,
      name: businessData?.viewBusiness?.name,
      aliasName,
      addressLine1: `${businessAddress?.addresslineone?.value} ${businessAddress?.locality?.value}`,
      addressLine2: businessAddress?.addresslinetwo?.value,
      addressLine3: "",
      city: businessAddress?.City?.value,
      zip: businessAddress?.pincode?.value,
      state: businessAddress?.State?.value,
      country: businessAddress?.Country?.value,
      landMark: businessAddress?.locality?.value,
      phoneNo:
        ownerPhoneNo || businessData?.viewBusiness?.contactNos?.length > 0
          ? businessData?.viewBusiness?.contactNos[0].number
          : "",
      emails: businessData?.viewBusiness?.businessMailingAddress,
      fssaiNo: commercialDetails?.info?.fssaiInfo?.[0]?.value,
      gstNo: commercialDetails?.info?.gstNumber,
      panNo: commercialDetails?.info?.panNumber,
      category: "",
      latitude: businessData?.viewBusiness?.loc?.y,
      longitude: businessData?.viewBusiness?.loc?.x,

      //
      // gstNo: businessData?.viewBusiness?.gstnumber,
      // panNo: businessData?.viewBusiness?.pannumber,
    },
    deliverMethod: [deliverMethod],
    modeOfPayment: [modeOfPayment],

    deliveryType: {
      ...deliveryType,
      from:
        deliveryType.from || deliveryType.from === 0 ? deliveryType.from : null,
      to: deliveryType.to || deliveryType.to === 0 ? deliveryType.to : null,
      shipType: deliveryType.shipType || null,
    },
    referralInfo: {
      originalProfileId: refferalInfoData
        ? refferalInfoData?.referralProfileId
        : "",
      referralAmount: refferalInfoData ? refferalInfoData.referralAmount : 0,
      referralName: "",
      referralProfileId: "",
    },

    resellerInfo: {
      businessId: resellerInfoData ? resellerInfoData?.businessId : "",
      code: resellerInfoData ? resellerInfoData?.code : "",
      profileId: resellerInfoData ? resellerInfoData?.profileId : "",
      resellerBusinessId: resellerInfoData
        ? resellerInfoData?.resellerBusinessId
        : "",
      saleMedium: resellerInfoData ? resellerInfoData?.saleMedium : "",
    },
    weFastInfo: {},
  };

  console.log("CHECK_OUT_DATA_POST", postData);

  return postData;
};

export const updateWebOrderPaymentHelper = (params) => {
  const profileId = getProfileId();
  const businessId = sessionStorage.getItem("businessId");

  const appliedDiscountDetails = params?.appliedDiscountDetails;
  const specialInstructions = params?.consumerNotes;
  const ownerShip = params?.crmOwnershipInfo;
  const totalOrderCost = params?.totalOrderCost;
  const shipmentAmount = params?.shipmentAmount;
  const packageInfo = params?.packageInfo;
  const selectedDiscountType = params?.selectedDiscountType;
  const shipmentTaxValue = params?.shipmentTaxValue;

  const postData = {
    profileId: profileId,
    businessId: businessId,
    currencyType: "INR",
    paymentId: "",
    claimId: appliedDiscountDetails
      ? appliedDiscountDetails?.claimId
        ? appliedDiscountDetails?.claimId
        : ""
      : "",
    claimType: appliedDiscountDetails ? appliedDiscountDetails?.claimType : "",
    claimedPoint: appliedDiscountDetails
      ? appliedDiscountDetails?.claimpoint
      : "",
    notesConsumer: specialInstructions,
    ownerShipInfo: {
      ownerShip: ownerShip,
    },
    amount: totalOrderCost * 100,
    shippingCost: shipmentAmount,
    shippingTax: Number(shipmentTaxValue).toFixed(2) || 0,

    discountDetails: selectedDiscountType != null ? [selectedDiscountType] : [],
    packageInfo: {
      ...packageInfo,
      unitWeigh: "",
      unitSize: "",
    },
  };

  console.log("CHECK_OUT_WEB_ORDER_PAYMENT_DATA", postData);

  return postData;
};

export const setRazorpayPaymentOptions = (params) => {
  const userInfo = getUserDetails();
  const cartDetails = params?.cartDetails;
  const businessInfo = JSON.parse(sessionStorage.getItem("businessInfo"));
  const businessId = sessionStorage.getItem("businessId");
  const commercialDetails = params?.commercialDetails;
  const orderTotalCost = params?.orderTotalCost;
  const paymentInfo = params?.paymentInfo;

  let RAZORPAY_OPTIONS = {
    key: "rzp_live_N0ut9UAlq8Nomg",
    amount: (orderTotalCost * 100).toString(),
    currency: "INR",
    name: businessInfo?.[0]?.businessName,
    description: businessInfo?.[0]?.businessName,
    image: businessInfo?.[0]?.logoURL,
    order_id: params?.orderId,
    handler: params?.successHandler,

    prefill: {
      name: userInfo?.account?.name,
      email: userInfo?.account?.email,
      contact: userInfo?.account?.phonenumber,
      method: "",
    },

    notes: {
      cartInfo: cartDetails?.cartId,
      paymentInfo: paymentInfo,
      businessId: businessId,
      orderId: cartDetails?.orderId,
      //Add Business ID and Order ID
    },

    method: {
      card: "false",
      netbanking: "false",
      upi: "false",
      emi: "false",
      bank_transfer: "false",
      wallet: "false",
      emandate: "false",
      paylater: "false",
      cardless_emi: "false",
    },
    modal: {},
    theme: {
      color: orangeColor01,
    },
  };

  if (commercialDetails?.info?.preferredPayments?.length > 0) {
    const preferredPayment = commercialDetails?.info?.preferredPayments?.reduce(
      (previous, current) => {
        return { ...previous, [current.code.replaceAll(" ", "")]: current };
      },
      {}
    );

    console.log("RAZORPAY_preferredPayment", preferredPayment);

    RAZORPAY_OPTIONS.method.card = preferredPayment?.card
      ? (preferredPayment?.card?.status).toString()
      : "false";

    RAZORPAY_OPTIONS.method.netbanking = preferredPayment?.netbanking
      ? (preferredPayment?.netbanking?.status).toString()
      : "false";

    RAZORPAY_OPTIONS.method.upi = preferredPayment?.upi
      ? (preferredPayment?.upi?.status).toString()
      : "false";

    RAZORPAY_OPTIONS.method.emi = preferredPayment?.emi
      ? (preferredPayment?.emi?.status).toString()
      : "false";

    RAZORPAY_OPTIONS.method.bank_transfer = preferredPayment?.bank_transfer
      ? (preferredPayment?.bank_transfer?.status).toString()
      : "false";

    RAZORPAY_OPTIONS.method.wallet = preferredPayment?.wallet
      ? (preferredPayment?.wallet?.status).toString()
      : "false";
  } else {
    RAZORPAY_OPTIONS.method = {
      card: "true",
      netbanking: "true",
      upi: "true",
      emi: "true",
      bank_transfer: "true",
      wallet: "true",
    };
  }

  console.log("CHECK_OUT_RAZORPAY_OPTIONS", RAZORPAY_OPTIONS);

  return RAZORPAY_OPTIONS;
};

export const sendOrderMessageHelper = (params) => {
  let ProductName = "";
  let ProductPicture = "";
  let ProductPrice = "";
  let CurrencySymbol = "";
  let totalQuantity = 0;

  const messageType = params?.type;
  const deliveryMethod = params?.deliveryMethod;

  const cartDetails = params?.cartDetails;
  const seletedAddress = params?.seletedAddress;
  const userInfo = getUserDetails();

  const userName = userInfo?.account?.name;
  const userPhoneNumber = userInfo?.account?.phonenumber;
  const cartId = cartDetails?.cartId;

  // console.log('MESSAGE_SELECTED_ADDR', seletedAddress)

  cartDetails?.productList?.forEach((item) => {
    ProductName = item.productName;
    ProductPicture = item.productPicURL;
    ProductPrice = item.productSalePrice;
    CurrencySymbol = item.currencySymbol;
    totalQuantity += item.currentQuantity;
  });

  let businessNameId = cartDetails?.businessName + cartDetails?.businessId;
  let fromAddress = businessNameId.replace(/\s+/g, "");
  let businessToAddress = fromAddress.toLowerCase();

  let customerAddress =
    seletedAddress?.addressLine1 +
    " " +
    seletedAddress?.city +
    ", " +
    seletedAddress?.state +
    ", " +
    seletedAddress?.zip +
    " " +
    [seletedAddress?.landMark];

  let orderTotalCost = CurrencySymbol + cartDetails?.orderTotalCost;
  let productPrice = CurrencySymbol + ProductPrice;

  var dateString = cartDetails?.createdOn ? cartDetails?.createdOn : new Date();
  dateString = new Date(dateString).toUTCString();
  dateString = dateString.split(" ").slice(0, 4).join(" ");
  let orderdate = dateString;

  const DeliveryTypeText = deliveryMethod;

  const message = {
    type: "chat",
    from:
      messageType === "toCustomer"
        ? businessToAddress + "@s.costbo.com"
        : userPhoneNumber + "@s.costbo.com",
    to:
      messageType === "toCustomer"
        ? userPhoneNumber + "@s.costbo.com"
        : businessToAddress + "@s.costbo.com",
    subject: "order Creation",
    body:
      "orderurlquery://^" +
      cartId +
      "^" +
      orderTotalCost +
      "^" +
      orderdate +
      "^" +
      "E-Payment" +
      "^" +
      DeliveryTypeText +
      "^" +
      ProductName +
      "^" +
      ProductPicture +
      "^" +
      cartDetails?.orderId +
      "^" +
      "Price :" +
      productPrice +
      "^" +
      totalQuantity +
      "^" +
      customerAddress +
      "^" +
      userName +
      "^" +
      userPhoneNumber,
  };

  console.log("SEND_ORDER_MESSAGE", message);

  return message;
};

/* Start Random MessageId code */
const randomMessageId = (length) => {
  let result = "";
  let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
/* End Random MessageId code */

export const sendOrderNotificationHelper = (params) => {
  let ProductName = "";
  let ProductPicture = "";
  let ProductPrice = "";
  let CurrencySymbol = "";
  let totalQuantity = 0;

  const cartDetails = params?.cartDetails;
  const seletedAddress = params?.seletedAddress;
  const deliveryMethod = params?.deliveryMethod;
  const userInfo = getUserDetails();

  const userName = userInfo?.account?.name;
  const userPhoneNumber = userInfo?.account?.phonenumber;
  const cartId = cartDetails?.cartId;

  cartDetails?.productList?.forEach((item) => {
    ProductName = item.productName;
    ProductPicture = item.productPicURL;
    ProductPrice = item.productSalePrice;
    CurrencySymbol = item.currencySymbol;
    totalQuantity += item.currentQuantity;
  });

  let businessNameId = cartDetails?.businessName + cartDetails?.businessId;
  let fromAddress = businessNameId.replace(/\s+/g, "");

  let customerAddress =
    seletedAddress?.addressLine1 +
    " " +
    seletedAddress?.city +
    ", " +
    seletedAddress?.state +
    ", " +
    seletedAddress?.zip +
    " " +
    [seletedAddress?.landMark];

  let orderTotalCost = CurrencySymbol + cartDetails?.orderTotalCost;
  let productPrice = CurrencySymbol + ProductPrice;

  var dateString = cartDetails?.createdOn ? cartDetails?.createdOn : new Date();
  dateString = new Date(dateString).toUTCString();
  dateString = dateString.split(" ").slice(0, 4).join(" ");
  let orderdate = dateString;

  const DeliveryTypeText = deliveryMethod;

  const businessInfo = JSON.parse(sessionStorage.getItem("businessInfo"));
  const codDelegatesNos = businessInfo?.[0]?.delegates?.map(
    (item) => item.phone
  );
  const bussContactNo = businessInfo?.[0]?.contactNos?.map(
    (item) => item.number
  );

  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var dateTime = date + " " + time;
  let MessageId =
    randomMessageId(8) +
    "-" +
    randomMessageId(4) +
    "-" +
    randomMessageId(4) +
    "-" +
    randomMessageId(4) +
    "-" +
    randomMessageId(12);

  const sendOrderPostData = {
    receiver: "consumer",
    businessId: cartDetails?.businessId,
    businessLogo: cartDetails?.businessLogo,
    businessName: cartDetails?.businessName,
    notificationType: "You have received New Order",
    data: {
      title: cartDetails?.businessName,
      body: "New Order Received",
      key: "test value",
      sound: "default",
      badge: "1",
      image: "",
      content_available: "1",
    },
    additionalData: {
      jid: userPhoneNumber + "@s.costbo.com",
      to: fromAddress + "@s.costbo.com",
      messageId: MessageId,
      date: dateTime,
      nick: userName,
      roomJid: "FTE",
      topicId: "test",
      productId: "test",
      orderId: cartDetails?.orderId,
      businessId: cartDetails?.businessId,
      businessName: cartDetails?.businessName,
      expiryDays: "",
      businessJid: "",
      body:
        "orderurlquery://^" +
        cartId +
        "^" +
        orderTotalCost +
        "^" +
        orderdate +
        "^" +
        "E-Payment" +
        "^" +
        DeliveryTypeText +
        "^" +
        ProductName +
        "^" +
        ProductPicture +
        "^" +
        cartDetails?.orderId +
        "^" +
        "Price :" +
        productPrice +
        "^" +
        totalQuantity +
        "^" +
        customerAddress +
        "^" +
        userName +
        "^" +
        userPhoneNumber,
    },
    contacts: [...codDelegatesNos, ...bussContactNo],
  };

  console.log("SEND_ORDER_NOTIFICATION", sendOrderPostData);

  return sendOrderPostData;
};

export const addPaymentProcessHelper = (params) => {
  const businessInfo = JSON.parse(sessionStorage.getItem("businessInfo"));
  const businessId = sessionStorage.getItem("businessId");
  const userInfo = getUserDetails();
  const profileId = getProfileId();

  const cartId = params?.cartId;

  console.log("USER_INFO", userInfo);

  const postData = {
    businessId: businessId,
    businessName: businessInfo?.[0]?.businessName,
    profileId: profileId,
    cartId: cartId,
    contactName: userInfo?.account?.name,
    razorPayId: " ",
    contactEmail: userInfo?.account?.email,
    contactPhone: userInfo?.account?.phonenumber,
    country: "India",
    paymentType: "",
    paymentGatewayCode: "",
    paymentGatewayText: "",
    currencyType: "INR",
    planOrigin: "",
    status: "",
    paymentOrderId: "",
    paymentDescription: "Purchase",
    fee: "",
    tax: "",
    errorCode: "",
    errorDescription: "",
    bank: "",
    wallet: "",
    vpa: "",
    paymentMethod: "",
    notes: "",
    paymentCaptured: "",
    paymentApproved: "",
    paymentDeclined: "",
    // paymentAmount: paymentAmount,
    paymentAmount: "",
    paymentSubmittedOn: "",
  };

  console.log("ADD_PAYMENT_PROCRESS", postData);

  return postData;
};

export const orderCODCartDataHelper = (data) => {
  const appliedDiscountDetails = data?.appliedDiscountDetails;

  const postData = {
    businessId: data?.cartDetails?.businessId,
    cartId: data?.cartId,
    claimedPoint: appliedDiscountDetails
      ? appliedDiscountDetails?.claimpoint
      : 0,
    codCost: {
      chargeTo: data?.chargeTo,
      codCharges: data?.codCharges,
      method: "codFlatCharge",
      settlementDays: 7,
      type: "delhivery",
    },
    paymentId: data?.paymentId,
    paymentInfo: {
      cardDetail: {},
      paymentDetail: {
        bank: "",
        businessId: data?.cartDetails?.businessId,
        businessName: "",
        cartId: data?.cartId,
        contactEmail: "",
        contactName: "",
        contactPhone: "",
        country: "",
        currencyType: "",
        errorCode: "",
        errorDescription: "",
        fee: "",
        notes: "",
        paymentAmount: "",
        paymentApproved: "",
        paymentCaptured: "",
        paymentDeclined: "",
        paymentDescription: "",
        paymentGatewayCode: "",
        paymentGatewayText: "",
        paymentMethod: "cod",
        paymentOrderId: "",
        paymentSubmittedOn: "",
        paymentType: "cod-delhivery",
        planOrigin: "",
        profileId: data?.cartDetails?.profileId,
        razorPayId: "",
        status: "",
        tax: "",
        vpa: "",
        wallet: "",
      },
    },
    profileId: data?.cartDetails?.profileId,
    shippingCost: data?.shipmentAmount,
  };

  return postData;
};

export const prepareGenerateLinkPostData = (data) => {
  const {
    cartDetails,
    paymentId,
    paymentAmount,
    seletedAddress,
    businessName,
  } = data;
  const userInfo = getUserDetails();

  console.log("generate_link___cartDetails:", cartDetails);
  console.log("generate_link___seletedAddress:", seletedAddress);
  console.log("generate_link___paymentId:", paymentId);
  console.log("generate_link___paymentAmount:", paymentAmount);

  const expiryDate = moment().add(30, "days").valueOf();

  const postData = {
    amount: Number(paymentAmount) * 100,
    currency: "INR",
    accept_partial: false,
    first_min_partial_amount: 0,
    expire_by: expiryDate,
    reference_id: cartDetails?.orderId,
    description: `${cartDetails?.businessName} sent you a Easy Cart payment link for order #${cartDetails?.orderId}. Click here to view the cart https://shop.costbo.com/${businessName}/easycart/${cartDetails?.cartId}`,
    customer: {
      name: userInfo?.account?.name,
      contact: userInfo?.account?.phonenumber,
      email: userInfo?.account?.email,

      // name: seletedAddress?.name,
      // contact: seletedAddress?.alternatePhoneNo ? `+91${seletedAddress?.alternatePhoneNo?.replace('+91', '')}` : seletedAddress?.phoneNo,
      // email: userInfo?.account?.email,
    },
    notify: {
      sms: false,
      email: false,
    },
    reminder_enable: true,
    notes: {
      paymentInfo: paymentId,
      cartInfo: cartDetails?.cartId,
      businessInfo: cartDetails?.businessId,
    },
    callback_url: `https://shop.costbo.com/${businessName}/easycart/${cartDetails?.cartId}`,
    callback_method: "get",
  };

  console.log("generate_link___postData:", postData);

  return postData;
};

export const prepareOndcPricePayloadData = (
  cartDetails,
  seletedAddress,
  businessAddress,
  totalCartWeightGm,
  immediateDiscount
) => {
  console.log(
    "prepareOndcPricePayloadData",
    cartDetails,
    seletedAddress,
    businessAddress,
    totalCartWeightGm
  );
  const origin = {
    lat: businessAddress?.loc?.y,
    lon: businessAddress?.loc?.x,
    pin: extractValue(businessAddress?.businessArea, "pincode"),
    city: extractValue(businessAddress?.businessArea, "City"),
    state: extractValue(businessAddress?.businessArea, "State"),
  };
  const destination = {
    lat: seletedAddress?.latitude,
    lon: seletedAddress?.longitude,
    pin: seletedAddress?.zip,
    city: seletedAddress?.city,
    state: seletedAddress?.state,
  };
  // return {
  //   isProd: true,
  //   bid: cartDetails?.businessId,
  //   ondcSellerTransactionId: "XXXXX",
  //   items: cartDetails?.productList,
  //   immediateDiscount: 0,
  //   orderTotalProductCost: cartDetails?.orderTotalProductCost,
  //   type: ["ondc"],
  //   dimensions: {
  //     weight: 500.0,
  //     type: "grams",
  //   },
  //   origin,
  //   destination,
  //   shipmentCost: [],
  // };
  return {
    // isProd: true,
    prodService: true,
    bid: cartDetails?.businessId,
    ondcSellerTransactionId: cartDetails?.cartId,
    items: cartDetails?.productList,
    immediateDiscount: immediateDiscount || 0,
    orderTotalProductCost: cartDetails?.orderTotalProductCost,
    type: ["ondc"],
    dimensions: {
      weight: totalCartWeightGm,
      type: "grams",
    },

    origin,

    // destination: {
    //   lat: seletedAddress?.latitude,
    //   lon: 77.651431,
    //   pin: seletedAddress?.zip,
    //   city: seletedAddress?.city,
    //   state: seletedAddress?.state,
    // },
    destination,

    shipmentCost: [],
  };
};

const extractValue = (array, key) => {
  const item = array.find((obj) => obj.code === key);
  return item ? item.value : null; // Return value or null if not found
};

// Utility function to convert total minutes to "Xhr Ymins" format
const convertMinutesToHrMin = (totalMinutes) => {
  const duration = moment.duration(totalMinutes, "minutes");
  const hours = Math.floor(duration.asHours()); // Get whole hours
  const minutes = duration.minutes(); // Get remaining minutes

  console.log(
    "getFormattedDeliveryTime_1",
    totalMinutes,
    duration,
    hours,
    minutes
  );

  return `${hours}hr ${minutes}mins`;
};

const convertMinutesToDayHrMin = (totalMinutes) => {
  const duration = moment.duration(totalMinutes, "minutes");

  const days = Math.floor(duration.asDays()); // Get whole days
  const hours = Math.floor(duration.asHours()) % 24; // Get remaining hours after full days
  const minutes = duration.minutes(); // Get remaining minutes

  let formattedTime = "";

  if (days > 0) {
    formattedTime += `${days} D `;
  }
  if (hours > 0) {
    formattedTime += `${hours} hr `;
  }
  if (minutes > 0) {
    formattedTime += `${minutes} mins`;
  }

  return formattedTime.trim(); // Remove trailing spaces
};

// Main logic
export const getFormattedDeliveryTime = (
  timeToShip,
  estimateDeliveryTimeInMin,
  storeTime
) => {
  // Parse timeToShip to minutes, default to 0 if not provided
  const productTime = timeToShip ? moment.duration(timeToShip).asMinutes() : 0;

  // Calculate the total delivery time in minutes
  const totalTimeInMinutes =
    Number(productTime) +
    (Number(estimateDeliveryTimeInMin) || 0) +
    Number(storeTime);

  console.log(
    "getFormattedDeliveryTime",
    totalTimeInMinutes,
    estimateDeliveryTimeInMin,
    timeToShip,
    productTime,
    storeTime
  );

  // Convert total minutes to "ZD Xhr Ymins" format
  return convertMinutesToDayHrMin(totalTimeInMinutes);
  // return convertMinutesToHrMin(totalTimeInMinutes);
};

// export const getTimeUntilOpen = (businessTiming) => {
//   // const ms = 1734577379966;
//   // const ms = 1734567750940;
//   const minutes = moment().minute();
//   const hours = moment().hour();
//   const day = moment().day(); // 0 = Sunday, 1 = Monday, etc.
//   const currentTimeInMinutes = hours * 60 + minutes; // Current time in minutes since midnight

//   console.log(
//     "storeTimestoreTime_1",
//     minutes,
//     hours,
//     day,
//     currentTimeInMinutes,
//     businessTiming
//   );

//   // Helper to find the next available schedule
//   function findNextSchedule(currentDay) {
//     for (let i = 1; i <= 7; i++) {
//       const nextDay = (currentDay + i) % 7; // Wrap around to the next week
//       const nextDaySchedule = businessTiming.find(
//         (item) => item.dayOfWeek === (nextDay === 0 ? 7 : nextDay)
//       );
//       if (nextDaySchedule) return { schedule: nextDaySchedule, daysUntil: i };
//     }
//     return null; // No schedule found
//   }

//   // Check if there's a schedule today
//   const todaySchedule = businessTiming.find(
//     (item) => item.dayOfWeek === day + 1
//   );

//   // Case 1: Check if the business is open today
//   if (todaySchedule) {
//     if (
//       currentTimeInMinutes >= todaySchedule.from &&
//       currentTimeInMinutes <= todaySchedule.to
//     ) {
//       console.log("storeTimestoreTime_2");
//       return 0;
//     }

//     // Case 2: Business is closed today but will open later
//     if (currentTimeInMinutes < todaySchedule.from) {
//       const minutesUntilOpen = todaySchedule.from - currentTimeInMinutes;
//       console.log("storeTimestoreTime_3", minutesUntilOpen);
//       return minutesUntilOpen;
//     }
//   }

//   // Case 3: Find the next available day
//   const nextSchedule = findNextSchedule(day);
//   if (nextSchedule) {
//     console.log("storeTimestoreTime_4");
//     const { schedule, daysUntil } = nextSchedule;

//     // Calculate the time until the next opening
//     const minutesUntilMidnight = 1440 - currentTimeInMinutes;
//     const minutesInFutureDays = (daysUntil - 1) * 1440; // Full days until the next schedule
//     const totalMinutesUntilOpen =
//       minutesUntilMidnight + minutesInFutureDays + schedule.from;

//     return totalMinutesUntilOpen;
//   }

//   return;
// };

export const getTimeUntilOpen = (openDays, operatingHours) => {
  const minutes = moment().minute();
  const hours = moment().hour();
  const day = moment().day(); // 0 = Sunday, 1 = Monday, etc.
  const currentTimeInMinutes = hours * 60 + minutes; // Current time in minutes since midnight

  // Parse openDays into an array of numbers
  const openDaysList = openDays.split(",").map(Number);

  // Parse operating hours into minutes
  const startTime =
    parseInt(operatingHours[0].substring(0, 2)) * 60 +
    parseInt(operatingHours[0].substring(2));
  const endTime =
    parseInt(operatingHours[1].substring(0, 2)) * 60 +
    parseInt(operatingHours[1].substring(2));

  console.log(
    "Current Day:",
    day,
    "Current Time (minutes):",
    currentTimeInMinutes
  );
  console.log("Open Days List:", openDaysList, "Operating Hours:", {
    startTime,
    endTime,
  });

  // Helper to find the next available schedule
  function findNextSchedule(currentDay) {
    for (let i = 1; i <= 7; i++) {
      const nextDay = (currentDay + i) % 7; // Wrap around to the next week
      if (openDaysList.includes(nextDay === 0 ? 7 : nextDay)) {
        return { nextDay: nextDay === 0 ? 7 : nextDay, daysUntil: i };
      }
    }
    return null; // No schedule found
  }

  // Case 1: Check if the business is open today
  if (openDaysList.includes(day + 1)) {
    if (currentTimeInMinutes >= startTime && currentTimeInMinutes <= endTime) {
      console.log("Business is open now.");
      return 0; // Business is open now
    }

    // Case 2: Business is closed today but will open later
    if (currentTimeInMinutes < startTime) {
      const minutesUntilOpen = startTime - currentTimeInMinutes;
      console.log(
        "Business opens later today in:",
        minutesUntilOpen,
        "minutes"
      );
      return minutesUntilOpen;
    }
  }

  // Case 3: Find the next available day
  const nextSchedule = findNextSchedule(day);
  if (nextSchedule) {
    const { daysUntil } = nextSchedule;

    // Calculate the time until the next opening
    const minutesUntilMidnight = 1440 - currentTimeInMinutes;
    const minutesInFutureDays = (daysUntil - 1) * 1440; // Full days until the next schedule
    const totalMinutesUntilOpen =
      minutesUntilMidnight + minutesInFutureDays + startTime;

    console.log("Next opening is in:", totalMinutesUntilOpen, "minutes");
    return totalMinutesUntilOpen;
  }

  console.log("No upcoming openings found.");
  return null; // No openings found
};

export const getTimeToShip = (allProducts, cartDetails) => {
  console.log("getTimeToShip", allProducts, cartDetails);
  const productId = cartDetails?.productList?.[0]?.productId;
  const ondcProduct = allProducts?.productList?.find(
    (item) => item?.id === productId
  );
  return ondcProduct?.ondc?.timeToShip || 0;
};
